import {
  Activity, Profile, ShopnikeApp, Wallet, Upload
} from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';
import './NavBar.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useTranslate from '../hooks/useTranslate';
import { getIsPartnerLogin, getIsMarkdownEnabled, getIsCsvUploadEnabled } from '../redux/selectors';
import { isBohApp } from '../utils/uploadUtils';

const pages = [
  {
    Icon: Activity, isEnabledForPartner: true, name: 'RL_Promotions', url: '/promotions'
  },
  {
    defaultTitle: 'Styles Upload', Icon: Upload, isEnabledForPartner: false, name: 'RL_StyleUpload1', url: '/upload'
  },
  {
    Icon: Wallet, isEnabledForPartner: false, name: 'RL_Markdowns', url: '/markdowns'
  },
  {
    Icon: ShopnikeApp, isEnabledForPartner: false, name: 'RL_StockOnHand', url: '/stock-on-hand'
  },
  {
    Icon: Profile, isEnabledForPartner: true, name: 'RL_Profile', url: '/profile'
  },
];

const NavBar = ({ location: { pathname } }) => {
  const isPartnerLogin = useSelector(getIsPartnerLogin);
  const isMarkdownEnabled = useSelector(getIsMarkdownEnabled);
  const isCsvUploadEnabled = useSelector(getIsCsvUploadEnabled) && isBohApp();

  // if the user is a partner, only show the promotions and profile pages
  // else check if the markdowns page is enabled
  const TABS_ALWAYS_ENABLED = ['RL_Promotions', 'RL_Profile'];
  const isTabEnabled = {
    ...TABS_ALWAYS_ENABLED.reduce((acc, tab) => ({ ...acc, [tab]: true }), {}),
    RL_Markdowns: isPartnerLogin ? false : isMarkdownEnabled,
    RL_StockOnHand: !isPartnerLogin,
    RL_StyleUpload1: isPartnerLogin ? false : isCsvUploadEnabled
  };

  const translate = useTranslate();
  if (pathname === '/login' || pathname === '/logged-out' || pathname === '/unauthorized') return '';

  return (
    <nav className="navbar u-full-width ta-sm-c">
      {pages
        .filter(
          (item) => isTabEnabled[item.name]
        )
        .map(({
          Icon, name, url, defaultTitle
        }) => (
          <Link
            key={url}
            style={{ color: pathname.includes(url) && '#FA5400', width: '100px' }}
            to={url}
          >
            <Icon title={name} />
            <p className="body-4 mt-3-sm">{translate(name) || defaultTitle}</p>
          </Link>
        ))}
    </nav>
  );
};

NavBar.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default NavBar;
