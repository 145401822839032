/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { getIsCreatePromotionEnabled } from '../../redux/selectors';
import { getPromotions } from '../../service-calls';
import { formatPromotions, handleErrorMessage, handleResponse } from '../../utils/formatting';
import { ContentLoads, Messages } from '../reusable';

import PromotionDetail from './PromotionDetail';
import PromotionHome from './PromotionHome';

const Promotions = () => {
  const [activePromotion, setActivePromotion] = useState('');
  const [clearError, setClearError] = useState('');
  const [clearSuccess, setClearSuccess] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [error, setError] = useState('');

  const location = useLocation();
  const translate = useTranslate();

  // check if create promotion is enabled
  const isCreatePromotionEnabled = useSelector(getIsCreatePromotionEnabled);

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      (async () => Auth.reAuthorize()
        .then(() => true)
        .catch(() => false))();
    }

    // getPromotions (refresh the promotions) every time we set isFetching to true or we come back to the home page
    if (isFetching || !location.pathname.startsWith('/promotions/')) {
      (async () => getPromotions(location.state?.storeId)
        .then(({ objects }) => (!objects.length
          ? handleResponse(setPromotions, [], setError, translate('RL_PromotionValidation1'))
          : handleResponse(setPromotions, formatPromotions(objects), setError, '')))
        .catch((err) => handleResponse(setPromotions, [], setError, handleErrorMessage(err)))
        .finally(() => setIsFetching(false)))();
    }
  }, [isFetching, location, translate]);

  return (
    <>
      <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_Promotions')}</h1>
      <><Messages className="pb2-sm" error={clearError} success={clearSuccess} />
        <ContentLoads error={error} isLoading={isFetching}>
          {location.pathname.startsWith('/promotions/')
            ? (
              <PromotionDetail
                canAdd={Auth.requiresRole20(Auth.getAuth())}
                canEdit={Auth.requiresRole40(Auth.getAuth())}
                promotion={promotions.find((p) => p.id === activePromotion)}
              />
            )
            : (
              <PromotionHome
                canCreate={Auth.requiresRole40(Auth.getAuth()) && isCreatePromotionEnabled}
                promotions={promotions}
                refreshPromotions={() => setIsFetching(true)}
                setActivePromotion={setActivePromotion}
                setClearError={setClearError}
                setClearSuccess={setClearSuccess}
              />
            )}
        </ContentLoads>
      </>
    </>
  );
};

export default Promotions;
