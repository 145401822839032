import { ButtonStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

import ContentLoads from './ContentLoads';
import Messages from './Messages';

const ButtonSubmit = ({
  buttonClassName,
  isDisabled,
  isSubmitting,
  label,
  messageError,
  messageSuccess,
  onSubmit,
  sectionClassName,
}) => {
  const translate = useTranslate();

  return (
    <ContentLoads isLoading={isSubmitting}>
      <section className={sectionClassName}>
        <ButtonStyled
          ariaLabel={label}
          className={buttonClassName}
          disabled={isDisabled}
          size="large"
          type="button"
          onClick={onSubmit}
        >
          {label || translate('RL_Submit')}
        </ButtonStyled>
        <Messages
          error={messageError}
          success={messageSuccess}
        />
      </section>
    </ContentLoads>
  );
};

ButtonSubmit.defaultProps = {
  buttonClassName: 'mb2-sm',
  isDisabled: false,
  isSubmitting: false,
  label: null,
  messageError: '',
  messageSuccess: '',
  sectionClassName: 'ncss-col-sm-12 full',
};

ButtonSubmit.propTypes = {
  buttonClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  messageError: PropTypes.string,
  messageSuccess: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  sectionClassName: PropTypes.string,
};

export default ButtonSubmit;
