import { Close } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useTranslate from '../../hooks/useTranslate';
import { patchPromotion } from '../../service-calls';
import {
  formatPromotionPatchDeleteItems, formatStoreInfo, handleErrorMessage, handleResponse
} from '../../utils/formatting';
import MarkdownStatus from '../markdowns/MarkdownStatus';

import ButtonPopup from './ButtonPopup';
import ContentLoads from './ContentLoads';
import Input from './Input';
import Messages from './Messages';
import './SlideDisplay.css';

const ProductsDisplay = ({
  canDelete,
  isMarkdowns,
  isStockOnHand,
  onStockOnHandDelete,
  popupSubtitle,
  products,
  promotion,
  setProducts,
  title,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const translate = useTranslate();

  const onDelete = (product) => {
    setIsDeleting(true);
    const newProducts = products.filter((item) => item.styleColor !== product.styleColor);
    (async () => patchPromotion(promotion.id, { updates: formatPromotionPatchDeleteItems([product]), ...formatStoreInfo() })
      .then((res) => (res.errors.length
        ? handleResponse(setProducts, products, setError, handleErrorMessage(res.errors[0]))
        : handleResponse(setProducts, newProducts, setError, '')
      ))
      .catch((err) => handleResponse(setProducts, products, setError, handleErrorMessage(err)))
      .finally(() => setIsDeleting(false)))();
  };

  const filterProducts = (product) => (product.styleColor.indexOf(searchValue.toUpperCase()) !== -1 || product.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1);

  return (
    <ContentLoads isLoading={isDeleting}>
      {products.length
        ? (
          <>
            {!isStockOnHand && (
              <Input
                id="Search Markdowns"
                label={isMarkdowns ? translate('RL_SearchMarkdowns') : translate('RL_SearchProductOrStyleColor')}
                placeholder={translate('RL_ProductNameOrStyleColor')}
                trailingIconContent={<Close />}
                value={searchValue}
                onChange={setSearchValue}
                onIconClick={() => setSearchValue('')}
              />
            )}
            <p className="body-4 ta-sm-l text-color-secondary">{title}</p>
            {products
              .filter((p) => filterProducts(p))
              .map((p) => (
                <article key={p.productId} className="va-sm-t ta-sm-l p2-sm border">
                  <section className="slide-display">
                    <section className="d-sm-flx flx-ai-sm-c slide-display-info">
                      <img
                        alt={p.name}
                        className="u-rounded flx-as-sm-c flx-gro-sm-0"
                        src={`${p.imageUrl ? p.imageUrl : 'https://static.nike.com/a/images/c_limit,h_500,w_500,f_jpg,q_auto:eco/notfound/image.jpg'}`}
                        style={{ height: '90px', width: '90px' }}
                      />
                      <section className="flx-gro-sm-1 va-sm-t ml4-sm">
                        <p className="headline-4 d-sm-flx flx-dir-sm-r flx-ai-sm-c">
                          {p.name}
                          {p.productShipmentCount && (
                            <span className="text-color-success ml3-sm body-3">
                              - {translate('RL_InTransit')} ({translate('RL_Quantity')} - {p.productShipmentCount})
                            </span>
                          )}
                        </p>
                        <p className="body-2">
                          {isMarkdowns && (p.markdownPrice || p?.upcomingMarkdown?.markdownPrice)}
                          {isStockOnHand && `${p.stockOnHand}x`}
                          <span className="text-color-secondary">
                            {p.styleColor}
                          </span>
                        </p>
                        {isMarkdowns && (
                          <MarkdownStatus
                            markdown={p}
                          />
                        )}
                      </section>
                    </section>
                    {((canDelete && promotion) || (canDelete && isStockOnHand && onStockOnHandDelete)) && (
                      <ButtonPopup
                        className="bg-error u-rounded text-color-primary-light flx-jc-sm-c flx-ai-sm-c ml2-sm slide-display-button"
                        isSubmitting={isDeleting}
                        label={translate('RL_Delete')}
                        style={{ height: '90px', width: '90px' }}
                        subtitle={popupSubtitle || translate('RL_RemoveConfirmation')}
                        onSubmit={() => (isStockOnHand ? onStockOnHandDelete(p) : onDelete(p))}
                      />
                    )}
                  </section>
                  <Messages className={error ? 'pt2-sm' : ''} error={error} />
                </article>
              ))}
          </>
        )
        : translate('RL_NoAssociatedProducts')}
    </ContentLoads>
  );
};

ProductsDisplay.defaultProps = {
  canDelete: false,
  isMarkdowns: false,
  isStockOnHand: false,
  onStockOnHandDelete: undefined,
  popupSubtitle: null,
  promotion: undefined,
  setProducts: () => { },
  title: '',
};

ProductsDisplay.propTypes = {
  canDelete: PropTypes.bool,
  isMarkdowns: PropTypes.bool,
  isStockOnHand: PropTypes.bool,
  onStockOnHandDelete: PropTypes.func,
  popupSubtitle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({
    markdownPrice: PropTypes.string,
    name: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    stockOnHand: PropTypes.number,
    styleColor: PropTypes.string.isRequired,
  })).isRequired,
  promotion: PropTypes.shape({
    discountPercentage: PropTypes.number.isRequired,
    divisions: PropTypes.shape().isRequired,
    endDate: PropTypes.string.isRequired,
    genders: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      gtin: PropTypes.string,
      name: PropTypes.string,
      productId: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  }),
  setProducts: PropTypes.func,
  title: PropTypes.string,
};

export default ProductsDisplay;
