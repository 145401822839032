import countries from 'i18n-iso-countries';

import nikeStoreCountries from './static/countriesWithNikeStores';

const PARTNER_LOGIN = 'PARTNER_LOGIN';
const REGULAR_LOGIN = 'REGULAR_LOGIN';
const PARTNER_LOGIN_HOST = 'promote-partner-ui';
const LOCAL_PARTNER_LOGIN_PORT = '3000';
const LOCAL_LOGIN_HOSTS = ['localhost', '127.0.0.1'];

const getUserLoginType = () => {
  const { hostname, port } = window.location;
  const isLocal = LOCAL_LOGIN_HOSTS.includes(hostname);
  return (isLocal && port === LOCAL_PARTNER_LOGIN_PORT) || hostname.includes(PARTNER_LOGIN_HOST) ? PARTNER_LOGIN : REGULAR_LOGIN;
};

const checkIsPartnerLogin = () => getUserLoginType() === 'PARTNER_LOGIN'; // here

const processCSVText = (values) => {
  const processedValues = [];
  if (values && values.length) {
    values.forEach((text) => {
      const isCSVText = text?.value?.indexOf(',') > -1;
      // returning the text as is if not a csv text
      // eslint-disable-next-line fp/no-mutating-methods
      if (!isCSVText) processedValues.push(text);
      else {
        const csvArray = text?.value?.split(',')?.filter((item) => item && item?.trim());

        csvArray.forEach((x) => {
          // eslint-disable-next-line fp/no-mutating-methods
          processedValues.push({ label: x?.trim(), value: x?.trim() });
        }); // end-for-each csvArray
      }
    }); // end-for-each values
  }

  return processedValues;
};
const countryToFlag = (isoCode) => (typeof String.fromCodePoint !== 'undefined'
// eslint-disable-next-line no-magic-numbers
  ? countries.alpha3ToAlpha2(isoCode).replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
  : isoCode);

const convertCountryCodes = (nikeCountries) => nikeCountries.map((country) => ({ label: `${country.label} ${countryToFlag(country.value)} `, value: country.value }));

const getCountriesWherePartnerPresent = (countryList) => {
  const partnerCountries = nikeStoreCountries.filter((o) => countryList.includes(o.value));
  return convertCountryCodes(partnerCountries);
};

module.exports = {
  checkIsPartnerLogin,
  countryToFlag,
  getCountriesWherePartnerPresent,
  getUserLoginType,
  processCSVText
};
