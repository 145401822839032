import { Close } from '@nike/nike-design-system-icons';
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/library';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import useTranslate from '../../hooks/useTranslate';

import Button from './Button';
import LoadingIndicator from './LoadingIndicator';
import Messages from './Messages';

import './Scanner.css';

const codeReader = new BrowserMultiFormatReader(
  null,
  [
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ]
);

const Scanner = ({ children, onClose, onDetected }) => {
  const videoRef = useRef(null);
  const translate = useTranslate();

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
          codeReader.listVideoInputDevices()
            .then((videoInputDevices) => {
              const selectedDeviceId = videoInputDevices[0].deviceId;
              return selectedDeviceId;
            })
            .then(() => {
              codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result, err) => {
                if (result) {
                  onDetected(result.text);
                  return result.text;
                }
                if (err) {
                  // console.error(err);
                }
                return true;
              });
              return null;
            })
            .catch((err) => (err));
          return stream;
        })
        .catch((err) => (err));
    }

    return () => {
      codeReader.reset();
    };
  }, [onDetected]);
  const ScannerLens = () => <div className="scanner-lens" />;

  return (
    <article className="scanner-container">
      <ScannerLens />
      <LoadingIndicator />
      <Button
        icon={<Close style={{ margin: '0.32rem -1rem' }} title="Cancel" />}
        sectionClassName="scanner-close"
        onClick={onClose}
      />
      <section className="scanner-target">
        <video ref={videoRef} muted playsInline className="scanner-target" id="video">
          <track kind="captions" />
        </video>
      </section>
      <section className="ncss-col-sm-12 scanner-button">
        {children || <Messages success={translate('RL_StartScanning')} />}
      </section>
    </article>
  );
};

Scanner.defaultProps = {
  children: null,
};

Scanner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  onClose: PropTypes.func.isRequired,
  onDetected: PropTypes.func.isRequired,
};

export default Scanner;
