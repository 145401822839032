import { CaretLeft } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';

const Back = ({ label, link }) => (
  <article className="ta-sm-l ncss-row">
    <Link className="ncss-cta-primary-dark" to={link} onClick={() => <Redirect to={link} />}>
      <CaretLeft aria-hidden />
      <span className="flx-as-sm-c">{label}</span>
    </Link>
  </article>
);

Back.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Back;
