import { SortAscending, SortDescending } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { Delete } from '../../icons';
import { patchPromotion } from '../../service-calls';
import { formatPromotionPatchDeleteItems, formatStoreInfo, groupSOHByPromotionId } from '../../utils/formatting';
import {
  ButtonPopup, ContentLoads, Messages, ProductsDisplay, Button
} from '../reusable';

const SOHHome = ({
  deleteError,
  deleteSuccess,
  handleDelete,
  isDeleting,
  quantityFilter,
  setIsDeleting,
  soh,
  sohSortDirection,
  toggleSOHSortDirection,
}) => {
  const translate = useTranslate();

  const deleteErrors = new Set();
  const deleteSuccesses = new Set();
  const sohError = soh.filter(({ error }) => error).map((badItem) => `${badItem.styleColor} - ${badItem.error}`).join(' ** ');
  const products = soh.filter(({ error }) => !error);

  const canDelete = Auth.requiresRole40(Auth.getAuth());

  const deleteProductFromPromotion = (promotionId, items) => (() => patchPromotion(promotionId, { updates: formatPromotionPatchDeleteItems(items), ...formatStoreInfo() })
    .then((res) => {
      if (res?.errors?.length) {
        // eslint-disable-next-line fp/no-throw
        throw Error(res.errors);
      }
      return deleteSuccesses.add(promotionId);
    })
    .catch((err) => deleteErrors.add(`${promotionId} ${err.message}`)))();

  const deleteProductsFromPromotions = async (sohProducts) => {
    setIsDeleting(true);
    deleteErrors.clear();
    deleteSuccesses.clear();
    // { promotionId: [{ gtin, styleColor, productId }] }
    const promotionGroups = Object.entries(groupSOHByPromotionId(sohProducts));
    const promises = promotionGroups.map(([promotionId, items]) => deleteProductFromPromotion(promotionId, items));
    await Promise.all(promises)
      // handleDelete sets error/success messages, fetches the sohProducts, and sets them as the products for ProductsDisplay
      .then(() => handleDelete(deleteErrors, deleteSuccesses, promotionGroups.length, sohProducts.length));
    setIsDeleting(false);
  };

  const SortIcon = useMemo(
    () => (sohSortDirection === 'desc' ? SortDescending : SortAscending),
    [sohSortDirection],
  );

  return (
    <ContentLoads isLoading={isDeleting}>
      <Messages error={deleteError || sohError} success={deleteSuccess} />
      {products.length > 0 && (
        <section className="d-sm-flx flx-jc-sm-fe">
          <Button
            icon={(<SortIcon style={{ margin: '0.27rem -1.5rem' }} title="Sort SOH" />)}
            sectionClassName="pr1-sm"
            onClick={toggleSOHSortDirection}
          />
          {canDelete && (
          <section className="ta-sm-l d-sm-flx flx-jc-sm-fe">
            <ButtonPopup
              className="mb2-sm ncss-btn-secondary-dark"
              isSubmitting={isDeleting}
              label={<Delete style={{ margin: '0.1rem -0.5rem' }} />}
              subtitle={translate(
                quantityFilter.filter === 'minimumQuantity' ? 'RL_ProductDeleteConfirm2' : 'RL_ProductDeleteConfirm1',
                quantityFilter.quantity
              )}
              onSubmit={() => deleteProductsFromPromotions(products)}
            />
          </section>
          )}
        </section>
      )}
      <ProductsDisplay
        isStockOnHand
        canDelete={canDelete}
        popupSubtitle={translate('RL_ProductDeleteConfirm3')}
        products={products}
        title={`${translate('RL_PromotionalProductsWith')} ${quantityFilter.filter === 'minimumQuantity'
          ? '>='
          : '<='} ${quantityFilter.quantity} ${translate('RL_Units')}`}
        onStockOnHandDelete={(product) => deleteProductsFromPromotions([product])}
      />
    </ContentLoads>
  );
};

SOHHome.propTypes = {
  deleteError: PropTypes.string.isRequired,
  deleteSuccess: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  quantityFilter: PropTypes.shape({
    filter: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  }).isRequired,
  setIsDeleting: PropTypes.func.isRequired,
  soh: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    promotionIds: PropTypes.arrayOf(PropTypes.string),
    stockOnHand: PropTypes.number,
  })).isRequired,
  sohSortDirection: PropTypes.string.isRequired,
  toggleSOHSortDirection: PropTypes.func.isRequired
};

export default SOHHome;
