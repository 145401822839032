import { Close } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { defaultPromotions } from '../../constants';
import useTranslate from '../../hooks/useTranslate';
import { patchPromotion } from '../../service-calls';
import {
  formatPromotionPatchDeleteItems,
  formatStoreInfo,
  formatUniqueString,
  handleErrorMessage,
  handleResponse
} from '../../utils/formatting';
import { translateSets } from '../../utils/translationsUtils';
import {
  Input, CustomPanel, ButtonPopup
} from '../reusable';

import '../reusable/SlideDisplay.css';

// Update the magic 6 if we ever have more divisions (there are 6 groupings, so 'All' is all 6)
const formatDivisions = (p, translate) => (p.divisions.size === 6 ? translate('RL_AllDivisions') : formatUniqueString(translateSets(p.divisions, translate)));
// Update this magic 4 if we ever have more genders (there are 4 groupings, so 'All' is all 4)

const formatGenders = (p, translate) => `${p.genders.size > 1 ? translate('RL_Genders') : translate('RL_Gender')}: ${p.genders.size === 4 ? translate('RL_All') : formatUniqueString(translateSets(p.genders, translate))}`;
const formatSublabel = (p, translate) => translate(p.itemDetails?.length !== 1 ? 'RL_OffCodes' : 'RL_OffCode', p.discountPercentage, p.itemDetails?.length || 0);

const PromotionPreview = ({
  canDelete, promotions, refreshPromotions, setActivePromotion, setClearError, setClearSuccess,
}) => {
  const [isClearing, setIsClearing] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const translate = useTranslate();

  const onDeletePromotionItems = async (promotion) => {
    setIsClearing(true);
    return patchPromotion(promotion.id, { updates: formatPromotionPatchDeleteItems(promotion.itemDetails), ...formatStoreInfo() })
      .then((res) => (res.errors.length
        ? handleResponse(setClearSuccess, '', setClearError, translate('RL_ItemDeleteFail'))
        : handleResponse(setClearSuccess, translate('RL_ClearItemSuccess', promotion.name), setClearError, '')
      ))
      .catch((err) => handleResponse(setClearSuccess, '', setClearError, handleErrorMessage(err)))
      .finally(() => {
        setIsClearing(false);
        refreshPromotions();
      });
  };

  const onArrowClick = (promotionId) => {
    handleResponse(setClearSuccess, '', setClearError, '');
    return setActivePromotion(promotionId);
  };

  const filterPromotions = (promotion) => (promotion.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 || promotion.itemDetails.some((i) => i.styleColor.indexOf(searchValue.toUpperCase()) !== -1));

  return (
    <>
      <Input
        id="Search Promotions"
        label={translate('RL_SearchPromotions')}
        placeholder={translate('RL_PromotionNameorStyleColor')}
        trailingIconContent={<Close />}
        value={searchValue}
        onChange={setSearchValue}
        onIconClick={() => setSearchValue('')}
      />
      {
      promotions
        .filter((p) => filterPromotions(p))
        .map((p) => (
          <article key={p.id} className="va-sm-t ta-sm-l">
            <section className="slide-display">
              <section className="slide-display-info">
                <CustomPanel
                  key={p.id}
                  button={{ onClick: () => onArrowClick(p.id), to: `/promotions/${p.id}` }}
                  label={p.name}
                  sublabel={formatSublabel(p, translate)}
                >
                  <>
                    <p className="body-2">{formatGenders(p, translate)}</p>
                    <p className="body-2">{formatDivisions(p, translate)}</p>
                    <p className="body-2">{!defaultPromotions.includes(p.name) ? `${p.startDate} - ${p.endDate}` : ''}</p>
                  </>
                </CustomPanel>
              </section>
              {canDelete && (
              <ButtonPopup
                className="bg-error u-rounded text-color-primary-light flx-jc-sm-c flx-ai-sm-c ml1-sm mb2-sm pr1-sm slide-display-button"
                isDisabled={isClearing || !p.itemDetails.length}
                label={translate('RL_Clear')}
                style={{ height: 'auto', width: '65px' }}
                subtitle={translate('RL_ProductDeleteConfirm4', p.itemDetails.length, p.name)}
                onSubmit={async () => onDeletePromotionItems(p)}
              />
              )}
            </section>
          </article>
        ))
    }
    </>
  );
};

PromotionPreview.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({
    discountPercentage: PropTypes.number.isRequired,
    divisions: PropTypes.shape().isRequired,
    endDate: PropTypes.string.isRequired,
    genders: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      gtin: PropTypes.string,
      name: PropTypes.string,
      productId: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  })).isRequired,
  refreshPromotions: PropTypes.func.isRequired,
  setActivePromotion: PropTypes.func.isRequired,
  setClearError: PropTypes.func.isRequired,
  setClearSuccess: PropTypes.func.isRequired,
};

export default PromotionPreview;
