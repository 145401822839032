const {
  oktaAegisHost,
  oktaAuthorizeResource,
  oktaClientID,
  oktaRedirectURI,
} = require('../../config')();

const getRandomString = (length) => {
  const a = crypto.getRandomValues(new Uint8Array(Math.ceil(length / 2)));

  const str = Array.from(a, (dec) => (`0${dec.toString(16)}`).substr(-2)).join('');
  return str.slice(0, length);
};

const makeVerifier = () => {
  const minLength = 43;
  const maxLength = 128;

  const verifier = `verifier${getRandomString(minLength)}`;

  return encodeURIComponent(verifier).slice(0, maxLength);
};

const base64URLEncode = (input) => btoa(input)
  .replace(/\+/g, '-')
  .replace(/\//g, '_')
  .replace(/=+$/, '');

// Note that this function is async, because of the asynchronous nature of web crypto's digest method
const makeChallenge = async (str) => {
  const buffer = new TextEncoder().encode(str);
  const arrayBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hash = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  return base64URLEncode(hash);
};

const redirectToOkta = (codeChallenge) => {
  const params = new URLSearchParams();

  params.set('client_id', oktaClientID);
  params.set('redirect_uri', oktaRedirectURI);
  params.set('response_type', 'code');
  params.set('state', '/');
  params.set('code_challenge', codeChallenge);
  params.set('code_challenge_method', 'S256');
  params.set('scope', 'openid profile email');
  // eslint-disable-next-line fp/no-mutation
  window.location.href = `${oktaAegisHost}${oktaAuthorizeResource}?${params.toString()}`;
};

const initializePKCEFlow = async () => {
  const codeVerifier = await makeVerifier();

  await localStorage.setItem('code_verifier', codeVerifier);

  const codeChallenge = await makeChallenge(codeVerifier);

  return redirectToOkta(codeChallenge);
};

export default initializePKCEFlow;
