import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { Messages } from '../reusable';

import PromotionDetail from './PromotionDetail';
import PromotionHome from './PromotionHome';

const PartnerPromotions = () => {
  const [activePromotion, setActivePromotion] = useState('');
  const [clearError, setClearError] = useState('');
  const [clearSuccess, setClearSuccess] = useState('');
  const [promotions, setPromotions] = useState([]);
  const [partnerError, setPartnerError] = useState(false);
  const [partnerName, setPartnerName] = useState(null);

  const translate = useTranslate();

  const location = useLocation();

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      (async () => Auth.reAuthorize()
        .then(() => true)
        .catch(() => false))();
    }
  }, []);
  useEffect(() => {
    try {
      const pName = Auth.getPartnerName(Auth.getAuth());
      setPartnerName(pName);
    } catch (err) {
      setPartnerError(true);
      setClearError(err);
    }
  }, []);
  return (
    <>
      <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_Promotions')}</h1>
      <>
        <Messages className="pb2-sm" error={clearError} success={clearSuccess} />
        {partnerName && (!partnerError && location.pathname.startsWith('/promotions/')
          ? (
            <PromotionDetail
              canAdd
              canEdit
              promotion={promotions.find((p) => p.promotionId === activePromotion)}
            />
          )
          : (
            <PromotionHome
              canCreate
              partnerName={partnerName}
              promotions={promotions}
              setActivePromotion={setActivePromotion}
              setClearError={setClearError}
              setClearSuccess={setClearSuccess}
              setPromotions={setPromotions}
            />
          ))}
      </>
    </>
  );
};

export default PartnerPromotions;
