const {
  group,
  match,
  sortBy,
  sort,
  Default: _
} = require('@nike/rcf-fp');
const orderBy = require('lodash/orderBy');
const moment = require('moment');
const numeral = require('numeral');
const { promotionTranslate } = require('rcffus-sps-translations');

const { getLocaleForTranslation } = require('../redux/selectors');
const store = require('../redux/store');

const sortPromotions = require('./sortPromotions');
const { default: countryInfo } = require('./static/country-info');
const { divisionIDs, genderIDs } = require('./static/id-mappings');

const reverseComparator = (left, right) => match()(
  [() => left < right, 1],
  [() => left > right, -1],
  [_, 0]
);

const formatCurrency = (number, priceFormatOptions) => {
  // priceFormatOptions should look like the below object for any of the below fields that you want to override
  const defaultCurrency = {
    decimal: '.', digitsAfterDecimal: 2, thousands: ','
  };
  const { thousands, decimal, digitsAfterDecimal } = { ...defaultCurrency, ...priceFormatOptions };
  const stringfied = number.toString();
  const dollars = stringfied.length > digitsAfterDecimal ? stringfied.slice(0, stringfied.length - digitsAfterDecimal) : '0';
  const dollarsWithSeparators = dollars.length > 3 ? dollars.replace(/\B(?=(\d{3})+(?!\d))/g, thousands) : dollars;
  if (digitsAfterDecimal) {
    const cents = stringfied.length > digitsAfterDecimal ? stringfied.slice(stringfied.length - digitsAfterDecimal) : stringfied.padStart(digitsAfterDecimal, '0');
    return `${dollarsWithSeparators}${decimal}${cents}`;
  } else {
    return dollarsWithSeparators;
  }
};

const formatDate = (date, format = 'YYYY-MM-DD') => moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format(format);

const formatPromotionAddBody = ({
  discountPercentage, divisions, endDate, genders, itemDetails, name, startDate,
}) => ({
  discountPercentage,
  divisionIds: [...(new Set(...divisions.map(({ value }) => value)))],
  endDateTime: moment(endDate).endOf('day').toISOString(true),
  genderIds: [...(new Set(...genders.map(({ value }) => value)))],
  itemDetails,
  name,
  startDateTime: moment(startDate).startOf('day').toISOString(true),
});

const formatStoreInfo = () => ({
  storeInfo: {
    id: localStorage.getItem('storeId'),
    locale: localStorage.getItem('storeLocale'),
    region: localStorage.getItem('storeRegion'),
    storeNumber: localStorage.getItem('storeNumber'),
    timezone: localStorage.getItem('storeTimezone')
  }
});

const formatPartnerPromotionAddBody = ({
  discountPercentage, divisions, endDate, genders, itemDetails, name, startDate, country, storesList, partnerName
}) => ({
  country,
  discountPercentage,
  divisionIds: [...(new Set(...divisions.map(({ value }) => value)))],
  endDateTime: moment(endDate).endOf('day').toISOString(true),
  genderIds: [...(new Set(...genders.map(({ value }) => value)))],
  itemDetails,
  name,
  partnerName,
  startDateTime: moment(startDate).startOf('day').toISOString(true),
  storesList
});

const formatPromotionPatchAddItems = (items) => items.map((value) => ({ op: 'add', path: '/itemDetails', value }));
const formatPromotionPatchDeleteItems = (items) => items.map(({ productId, ...rest }) => ({ op: 'remove', path: '/itemDetails', value: { ...rest, id: productId } }));
const formatPartnerPromotionPatchDeleteItems = (items) => items.map(({ styleColor, ...rest }) => ({ op: 'remove', path: '/itemDetails', value: { ...rest, styleColor } }));
const formatPromotionPatchUpdate = (path, value) => ({ op: 'replace', path: `/${path}`, value });

const setKeyToStyleColor = ({ styleColor }) => styleColor;
const setValueToFullItem = (i) => i;
// startDateTime will exist for markdowns, but not for promotions, which is okay
const takeNewestStyleColorItem = (styleColorArray) => sortBy(styleColorArray, ({ startDateTime }) => startDateTime)[styleColorArray.length - 1];
const getUniqueItems = (items) => Object.values(group(items, setKeyToStyleColor, setValueToFullItem, takeNewestStyleColorItem));

const formatPromotions = (promotions) => sortPromotions(promotions).map((p) => ({
  ...p,
  divisions: new Set([...p.divisionIds].map((id) => divisionIDs[id])),
  endDate: formatDate(moment(p.endDateTime), 'l'),
  genders: new Set([...p.genderIds].map((id) => genderIDs[id])),
  itemDetails: sortBy(getUniqueItems(p.itemDetails || []), ({ createDateTime }) => createDateTime, reverseComparator),
  startDate: formatDate(moment(p.startDateTime), 'l'),
}));

const sortStockOnHand = (soh, sohSortDirection) => orderBy(soh, ['stockOnHand', 'name'], [sohSortDirection, 'asc']);

const formatStockOnHand = (soh, sohSortDirection) => sortStockOnHand(
  soh.map((s) => (s.error
    ? ({
      ...s.itemDetails,
      error: s.error
    })
    : ({
      ...s.itemDetails,
      promotionIds: s.promotionIds,
      stockOnHand: s.stockOnHand,
    }))),
  sohSortDirection
);

const groupSOHByPromotionId = (sohProducts) => (
  sohProducts.reduce((map, {
    gtin, productId, promotionIds, styleColor
  }) => (
    { // result looks like { [promotionId]: [product1, product2]} where a product contains ONLY { gtin, id, styleColor }
      ...map,
      ...(promotionIds?.reduce((mapSegment, promotionId) => (
        {
          ...mapSegment,
          // note: gtin will only be defined if the item was scanned and not added manually
          [promotionId]: [...(map?.[promotionId] || []), { gtin, productId, styleColor }]
        }
      ), map))
    }
  ), {})
);

const formatMarkdownAddBody = ({
  price, startDate, styleColor, gtin
}) => ({
  itemDetails: {
    gtin,
    markdownPrice: price,
    startDateTime: moment(startDate).startOf('day').toISOString(),
    styleColor,
  }
});

// Sort markdown list by start date, break ties by create date
const sortByStartDate = (markdownA, markdownB) => {
  const markdownAStart = markdownA.startDateTime || markdownA.upcomingMarkdown.startDateTime;
  const markdownBStart = markdownB.startDateTime || markdownB.upcomingMarkdown.startDateTime;
  if (moment(markdownAStart) > moment(markdownBStart)) return 1;
  else if (moment(markdownAStart) < moment(markdownBStart)) return -1;
  else {
    const markdownACreated = markdownA.createDateTime || markdownA.upcomingMarkdown.createDateTime;
    const markdownBCreated = markdownB.createDateTime || markdownB.upcomingMarkdown.createDateTime;
    return moment(markdownACreated) >= moment(markdownBCreated) ? 1 : -1;
  }
};

const formatActiveMarkdown = (markdown, priceFormat) => ({
  ...markdown.itemDetails,
  id: markdown.id,
  markdownPrice: numeral(markdown.itemDetails.markdownPrice).format(priceFormat),
  startDate: formatDate(moment(markdown.itemDetails.startDateTime), 'l'),
});

const formatUpcomingMarkdown = (futureMarkdown, activeMarkdown, priceFormat) => {
  const {
    name, productId, styleColor, startDateTime, createDateTime, markdownPrice
  } = futureMarkdown.itemDetails;
  return {
    ...activeMarkdown,
    name,
    productId,
    styleColor,
    upcomingMarkdown: {
      createDateTime,
      id: futureMarkdown.id,
      markdownPrice: numeral(markdownPrice).format(priceFormat),
      startDate: formatDate(moment(startDateTime), 'l'),
      startDateTime,
    }
  };
};

/*
 * Get the nearest past and future markdown to today:
 * Past <---x----A-- Present --U----x---> Future
 * A = Active and U = Upcoming
 * x = Ended or future markdowns (don't display info about these)
 *
 * Because the incoming markdowns are in sorted order, we discard any markdowns from the past
 * that are duplicates (replace with more recent 'Active' markdown). The first future markdown
 * with the same style color is saved as the upcoming markdown.
*/
const groupActiveAndFutureMarkdowns = (markdowns, priceFormat) => {
  const sortedMarkdowns = sort(markdowns, (a, b) => sortByStartDate(a.itemDetails, b.itemDetails));

  return sortedMarkdowns.reduce((markdownsByStyleColor, markdown) => {
    const { styleColor, startDateTime } = markdown.itemDetails;

    if (moment(startDateTime) <= moment()) {
      // Markdown is in the past:
      return { ...markdownsByStyleColor, [styleColor]: formatActiveMarkdown(markdown, priceFormat) };
    } else if (!markdownsByStyleColor[styleColor] || (markdownsByStyleColor[styleColor] && !markdownsByStyleColor[styleColor].upcomingMarkdown)) {
      // Markdown is in the future:
      return {
        ...markdownsByStyleColor,
        [styleColor]: formatUpcomingMarkdown(markdown, markdownsByStyleColor[styleColor], priceFormat)
      };
      // Markdown is in the future, but not the soonest upcoming markdown (ignore it):
    } else return markdownsByStyleColor;
  }, {});
};

const formatMarkdowns = (markdowns, country) => {
  const currency = countryInfo[country]?.currency;
  const defaultCurrency = {
    decimal: '.', digitsAfterDecimal: 2, postfix: '', prefix: '', thousands: ','
  };
  const {
    thousands, decimal, digitsAfterDecimal, prefix, postfix
  } = { ...defaultCurrency, ...currency };
  const customLocaleName = `custom-${country}`;
  try {
    numeral.register('locale', customLocaleName, {
      currency: {
        symbol: prefix || postfix
      },
      delimiters: {
        decimal,
        thousands,
      },
    });
  } catch {
    // do nothing, we've already registered the locale
  }
  numeral.locale(customLocaleName);
  const priceFormat = `${prefix ? '$ ' : ''}0,0${digitsAfterDecimal ? '.' : '[.]'}${'0'.repeat(digitsAfterDecimal)}${postfix ? ' $' : ''}`;

  const activeAndFutureMarkdowns = groupActiveAndFutureMarkdowns(markdowns, priceFormat);

  return sort(Object.values(activeAndFutureMarkdowns), (a, b) => sortByStartDate(a, b));
};

const formatUniqueString = (iterable) => [...new Set(iterable)].join(', ');

const handleErrorMessage = (error, submittedItems) => {
  const locale = getLocaleForTranslation(store.default.getState());

  if (submittedItems) {
    const setSubmittedItems = new Set(submittedItems);
    const successfulNewItems = (error.itemDetails || [])
      .map(({ gtin, styleColor }) => gtin || styleColor)
      .filter((item) => setSubmittedItems.has(item));
    const successMessage = successfulNewItems.length
      ? promotionTranslate(successfulNewItems.length === 1 ? 'RL_ProductAddSuccess3' : 'RL_ProductAddSuccess4', locale, successfulNewItems.length)
      : '';
    const errorMessage = error.errors.length === 1
      ? promotionTranslate('RL_ProductAddError1', locale, formatUniqueString(error.errors.map(({ gtin, message, styleColor }) => ` ** ${styleColor || gtin} (${message})`)))
      : promotionTranslate('RL_ProductAddError2', locale, error.errors.length, formatUniqueString(error.errors.map(({ gtin, message, styleColor }) => ` ** ${styleColor || gtin} (${message})`)));
    return { errorMessage, successMessage };
  }
  const errorMessage = (error.message === 'Forbidden' ? promotionTranslate('RL_ProductAddError3', locale) : error.message);
  return (error.message !== 'Bad Request' && error.message !== 'Forbidden')
    ? promotionTranslate('RL_ProductAddError4', locale, errorMessage)
    : promotionTranslate('RL_ProductAddError5', locale, errorMessage, formatUniqueString(error.errors && error.errors.map(({ message }) => message)));
};

const handleResponse = (setSuccess, successValue, setError, errorValue) => {
  setSuccess(successValue);
  return setError(errorValue);
};

const isGTIN = (value) => (/^[0-9]{14}$/.test(value));

const isNumber = (value) => (/^[0-9]*$/.test(value));

const isStyleColor = (value) => (/^[A-Za-z0-9]{5,6}-[A-Za-z0-9]{3}$/.test(value));

module.exports = {
  formatCurrency,
  formatDate,
  formatMarkdownAddBody,
  formatMarkdowns,
  formatPartnerPromotionAddBody,
  formatPartnerPromotionPatchDeleteItems,
  formatPromotionAddBody,
  formatPromotionPatchAddItems,
  formatPromotionPatchDeleteItems,
  formatPromotionPatchUpdate,
  formatPromotions,
  formatStockOnHand,
  formatStoreInfo,
  formatUniqueString,
  groupSOHByPromotionId,
  handleErrorMessage,
  handleResponse,
  isGTIN,
  isNumber,
  isStyleColor,
  sortStockOnHand
};
