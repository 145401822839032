import PropTypes from 'prop-types';
import React from 'react';

import LoadingIndicator from './LoadingIndicator';
// eslint-disable-next-line no-nested-ternary
const ContentLoads = ({ children, error, isLoading }) => (isLoading
  ? <LoadingIndicator />
  : error
    ? <h5 className="body-4 text-color-error">{error}</h5>
    : children);

ContentLoads.defaultProps = {
  error: '',
};

ContentLoads.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

export default ContentLoads;
