import { ButtonStyled, DialogStyled, DialogActionsStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

const Popup = ({
  isOpen,
  onSubmit,
  setIsOpen,
  subtitle,
  textConfirm,
  title,
}) => {
  const closeModal = () => setIsOpen(false);
  const translate = useTranslate();

  return (
    <>
      <span id="modal-root" />
      <DialogStyled
        className="ta-sm-l"
        closeModal={closeModal}
        footerContent={(
          <DialogActionsStyled>
            <ButtonStyled
              appearance="primary"
              ariaLabel="Submit"
              role="alertdialog"
              onClick={() => {
                closeModal();
                onSubmit();
              }}
            >
              {textConfirm || translate('RL_Yes')}
            </ButtonStyled>
          </DialogActionsStyled>
        )}
        isOpen={isOpen}
        title={title || translate('RL_Confirm')}
      >
        {subtitle}
      </DialogStyled>
    </>
  );
};

Popup.defaultProps = {
  isOpen: false,
  subtitle: '',
  textConfirm: null,
  title: null,
};

Popup.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  textConfirm: PropTypes.string,
  title: PropTypes.string,
};

export default Popup;
