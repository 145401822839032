import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Auth from '../Auth';

import initializePKCEFlow from './PKCEFlow';

const OktaAuth = () => {
  const isValidRefresh = Auth.validRefresh();

  useEffect(() => {
    !isValidRefresh && initializePKCEFlow();
  }, [isValidRefresh]);

  if (isValidRefresh) return <Redirect to="/promotions" />;

  return (
    (
      <main className="ncss-col-sm-12 mt12-sm pt2-sm ta-sm-c">
        <p className="headline-4">You are being authorized.</p>
      </main>
    )
  );
};

export default OktaAuth;
