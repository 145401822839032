import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';

import useTranslate from '../../hooks/useTranslate';
import { postPromotion } from '../../service-calls';
import { formatDate, handleErrorMessage, handleResponse } from '../../utils/formatting';
import { divisionValues, genderValues, translationMappings } from '../../utils/static/id-mappings';
import {
  ButtonSubmit,
  DiscountPercentages,
  Input,
  InputDate,
  Select
} from '../reusable';

const PromotionAdd = () => {
  const [name, setName] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [nameError, setNameError] = useState('');
  const [divisions, setDivisions] = useState([]);
  const [genders, setGenders] = useState([]);
  const [startDate, setStartDate] = useState(formatDate(moment().add(1, 'd')));
  const [endDate, setEndDate] = useState(formatDate(moment().add(1, 'd').add(1, 'w')));
  const [dateError, setDateError] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const translate = useTranslate();

  useEffect(() => {
    const isFormComplete = (name && (discountPercentage || discountPercentage === 0) && divisions.length && genders.length && startDate && endDate);
    const areDatesValid = startDate < endDate;
    const isNameValid = /^[a-zA-Z0-9 ]*$/.test(name);
    const isPromotionNameValid = !/\s{2,}/.test(name);

    setNameError((!isNameValid || !isPromotionNameValid) ? translate('RL_PromotionNameValidation2') : '');
    setDateError(areDatesValid ? '' : translate('RL_EndDateValidation2'));
    setError('');
    setSubmitDisabled(!(isFormComplete && areDatesValid && isNameValid && isPromotionNameValid));
    setSuccess('');
  }, [discountPercentage, divisions, endDate, genders, name, startDate, translate]);

  const onSubmit = () => {
    setIsSubmitting(true);
    (async () => postPromotion({
      discountPercentage,
      divisions,
      endDate,
      genders,
      name: name?.trim(),
      startDate
    })
      .then(() => handleResponse(setSuccess, translate('RL_ProductAddSuccess1', name), setError, ''))
      .catch((err) => handleResponse(setSuccess, '', setError, handleErrorMessage(err)))
      .finally(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        setIsSubmitting(false);
      }))();
  };

  const translateLabel = useCallback(
    ({ label }) => translate(translationMappings[label]) || label,
    [translate]
  );
  const onChangeName = (val) => {
    const trimmedValue = val?.trim();
    setName(trimmedValue);
  };
  return (
    <>
      <section className="ncss-col-sm-10 ta-sm-l mt3-sm mb3-sm">
        <Input
          isRequired
          showCharacterCount
          error={!name || !!nameError}
          errorMessage={nameError}
          id="name"
          label={translate('RL_PromotionName')}
          maxLength={21}
          minLength={1}
          value={name}
          onChange={onChangeName}
        />
        <DiscountPercentages
          isRequired
          discountPercentage={discountPercentage}
          onChange={setDiscountPercentage}
        />
        <Select
          isMulti
          isRequired
          getOptionLabel={translateLabel}
          id="divisions"
          label={translate('RL_Divisions')}
          options={divisionValues}
          values={divisions}
          zIndex={2}
          onChange={setDivisions}
        />
        <Select
          isMulti
          isRequired
          getOptionLabel={translateLabel}
          id="genders"
          label={translate('RL_Genders')}
          options={genderValues}
          values={genders}
          zIndex={1}
          onChange={setGenders}
        />
        <InputDate
          isRequired
          id="startDate"
          label={translate('RL_StartDate')}
          minDate={formatDate(moment().add(1, 'd').startOf('day'))}
          value={startDate}
          onChange={setStartDate}
        />
        <InputDate
          isRequired
          errorMessage={dateError}
          id="endDate"
          label={translate('RL_EndDate')}
          minDate={formatDate(moment(startDate).add(1, 'd').startOf('day'))}
          value={endDate}
          onChange={setEndDate}
        />
      </section>
      <ButtonSubmit
        isDisabled={submitDisabled}
        isSubmitting={isSubmitting}
        label={translate('RL_AddPromotion')}
        messageError={error}
        messageSuccess={success}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default PromotionAdd;
