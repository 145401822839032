import React from 'react';

const Unauthorized = () => (
  <main className="ncss-col-sm-12 ta-sm-c">
    <h1 className="display-3 mt10-sm mb4-sm text-color-error">Unauthorized</h1>
    <p className="body-1">You do not have permission to access this application.</p>
  </main>
);

export default Unauthorized;
