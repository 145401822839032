import { DropDownStyled } from '@nike/nike-design-system-components';
import { sort } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

const DEFAULT_BUTTON_PERCENTAGES = [0, 20, 30, 50, 70];
const SOUTH_AFRICA_PERCENTAGE = [40];
const DEFAULT_DROPDOWN_PERCENTAGES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70];

/* Handles which region recieves the button or dropdown component */
const DiscountPercentages = ({
  additionalOptions,
  discountPercentage,
  isDisabled,
  isRequired,
  onChange,
}) => {
  const translate = useTranslate();
  return (
    <section className="ta-sm-l mb4-sm mt4-sm">
      {localStorage.getItem('storeRegion') === 'EUROPE_MIDDLE_EAST_AFRICA'
        ? (
          <>
            <p className="body-4 text-color-secondary">Discount Percentage{isRequired ? '*' : ''}</p>
            {sort([...DEFAULT_BUTTON_PERCENTAGES, ...(localStorage.getItem('country') === 'ZAF' ? SOUTH_AFRICA_PERCENTAGE : []), ...additionalOptions]).map((p) => (
              <button
                key={p}
                className={`ncss-col-sm-4 ncss-col-md-2 ncss-btn-${discountPercentage === p ? 'primary' : 'secondary'}-dark`}
                disabled={isDisabled}
                type="button"
                onClick={() => onChange(p)}
              >
                {p}%
              </button>
            ))}
          </>
        )
        : (
          <DropDownStyled
            disabled={isDisabled}
            id="discountPercentage"
            label={translate('RL_DiscountPercentage')}
            name="Discount Percentage"
            options={sort([...DEFAULT_DROPDOWN_PERCENTAGES, ...additionalOptions])
              .map((option) => ({ label: `${option}%`, value: option.toString() }))}
            placeholder={translate('RL_DiscountPercentage')}
            requiredIndicator={isRequired ? '*' : ''}
            value={discountPercentage.toString()} // required so placeholder doesn't display over falsey value
            onChange={({ target: { value } }) => onChange(parseInt(value, 10))}
          />
        )}
    </section>
  );
};

DiscountPercentages.defaultProps = {
  additionalOptions: [],
  discountPercentage: 0,
  isDisabled: false,
  isRequired: false,
  onChange: () => {},
};

DiscountPercentages.propTypes = {
  additionalOptions: PropTypes.arrayOf(PropTypes.number),
  discountPercentage: PropTypes.number,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DiscountPercentages;
