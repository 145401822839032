/* eslint-disable fp/no-mutating-methods */
import { match } from '@nike/rcf-fp';

import {
  PERCENT_90,
  PARTNER_MULTI_ACC_ACCESS_ERR,
  PARTNER_MISSING_ACC_ACCESS_ERR,
  LEVEL_40,
  LEVEL_20,
} from '../constants';
// eslint-disable-next-line import/no-cycle
import { authUser, reAuthUser } from '../service-calls';

export default class Auth {
  // Checks to see if the current JWT will expire in the next 90 seconds. If so, it tries to retrieve a updated JWT
  static confirmAuth = async () => {
    if (!this.isAuthenticated()) {
      await this.reAuthorize();
    }
    return this.getAuth();
  };

  static getAuth = () => localStorage.getItem('accessToken');

  static getPartnerName = (auth) => {
    const { groups } = JSON.parse((Buffer.from(auth.split('.')[1], 'base64')).toString('utf-8'));
    const partnerName = [];
    groups.forEach((element) => {
      const partner = match(element)(
        ['App.Promote.Partner.FOX.Users', 'Fox'],
        ['App.Promote.Partner.BELEP.Users', 'BELEP'],
      );
      partnerName.push(partner);
    });
    if (partnerName.length > 1) {
      // eslint-disable-next-line fp/no-throw
      throw PARTNER_MULTI_ACC_ACCESS_ERR;
    } else if (partnerName.length < 1) {
      // eslint-disable-next-line fp/no-throw
      throw PARTNER_MISSING_ACC_ACCESS_ERR;
    } else {
      return partnerName[0];
    }
  }

  static getStoreId = () => localStorage.getItem('storeId');

  static isAuthenticated = () => new Date().getTime() < JSON.parse(localStorage.getItem('expiresAt'));

  static login = async (loginInformation) => {
    try {
      const auth = await authUser(loginInformation);
      if (!JSON.parse((Buffer.from(auth.access_token.split('.')[1], 'base64')).toString('utf-8')).nike_storeId) {
        // eslint-disable-next-line fp/no-throw
        throw new Error(`BERM didn't send us a store id. Try again.`); // unseen error
      }
      this.setLoginSession(auth);
      return true;
    } catch (err) {
      // eslint-disable-next-line fp/no-throw
      throw Error('Authorization Failed');
    }
  };

  static logout = () => {
    localStorage.clear();
  };

  static reAuthorize = async () => {
    try {
      const reauth = await reAuthUser(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken'));
      this.setLoginSession(reauth);
      return true;
    } catch (err) {
      return false;
    }
  };

  static requiresRole = (auth, roleList) => !!JSON.parse((Buffer.from(auth.split('.')[1], 'base64')).toString('utf-8')).nike_storeRoles.map((role) => roleList.includes(role)).filter((exists) => exists).length;

  static requiresRole20 = (auth) => this.requiresRole(auth, LEVEL_20);

  static requiresRole40 = (auth) => this.requiresRole(auth, LEVEL_40);

  static setLoginSession = (auth) => {
    const expiresAt = (auth.expires_in * PERCENT_90) + new Date().getTime();
    const refreshToken = auth.refresh_token.split('.')[1];
    // Subtract 10 minutes (600,000 milliseconds) to retrieve a new refresh token if expiration is within 10 minutes
    const refreshExpiresAt = (JSON.parse(Buffer.from(refreshToken, 'base64').toString('utf-8')).exp * 1000) - 600000;
    localStorage.setItem('expiresAt', expiresAt);
    localStorage.setItem('accessToken', auth.access_token);
    localStorage.setItem('refreshToken', auth.refresh_token);
    localStorage.setItem('refreshExpiresAt', refreshExpiresAt);
  };

  static validRefresh = () => {
    const refreshExpires = JSON.parse(localStorage.getItem('refreshExpiresAt'));
    const currentTime = new Date().getTime();
    return currentTime < refreshExpires;
  }
}
