{
    "RL_Active": {
        "key": "RL_Active",
        "en": "Active",
        "ca-ES": "Actiu",
        "ca": "Actiu",
        "cs-CZ": "Aktivní",
        "cs": "Aktivní",
        "da-DK": "Aktiv",
        "da": "Aktiv",
        "de-DE": "Aktiv",
        "de": "Aktiv",
        "el-GR": "Ενεργό",
        "el": "Ενεργό",
        "en-GB": "Active",
        "es-ES": "Activo(s)",
        "es": "Activo(s)",
        "fi-FI": "Aktiivinen",
        "fr-FR": "Actif",
        "fr": "Actif",
        "he-IL": "פעיל",
        "hr-HR": "Aktivno",
        "hu-HU": "Aktív",
        "hu": "Aktív",
        "it-IT": "Attivo",
        "it": "Attivo",
        "ja-JP": "アクティブ",
        "ja": "アクティブ",
        "ko-KR": "활성",
        "ko": "활성",
        "nl-NL": "Actief",
        "nl": "Actief",
        "no-NO": "Aktive",
        "pl-PL": "Aktywny",
        "pl": "Aktywny",
        "pt-BR": "Ativar",
        "pt-PT": "Ativo",
        "ru-RU": "Активно",
        "ru": "Активно",
        "sv-SE": "Aktiv",
        "sv": "Aktiv",
        "th-TH": "ใช้งานอยู่",
        "th": "ใช้งานอยู่",
        "tr-TR": "Etkin",
        "tr": "Etkin"
    },
    "RL_Add": {
        "key": "RL_Add",
        "en": "Add",
        "ca-ES": "Afegeix",
        "ca": "Afegeix",
        "cs-CZ": "Přidat",
        "cs": "Přidat",
        "da-DK": "Tilføj",
        "da": "Tilføj",
        "de-DE": "Hinzufügen",
        "de": "Hinzufügen",
        "el-GR": "Προσθήκη",
        "el": "Προσθήκη",
        "en-GB": "Add",
        "es-ES": "Añadir",
        "es": "Añadir",
        "fi-FI": "Lisää",
        "fr-FR": "Ajouter",
        "fr": "Ajouter",
        "he-IL": "הוספה",
        "hr-HR": "Dodaj",
        "hu-HU": "Hozzáadás",
        "hu": "Hozzáadás",
        "it-IT": "Aggiungi",
        "it": "Aggiungi",
        "ja-JP": "追加",
        "ja": "追加",
        "ko-KR": "추가",
        "ko": "추가",
        "nl-NL": "Voeg toe",
        "nl": "Voeg toe",
        "no-NO": "Legg til",
        "pl-PL": "Dodaj",
        "pl": "Dodaj",
        "pt-BR": "Adicionar",
        "pt-PT": "Adicionar",
        "ru-RU": "Добавить",
        "ru": "Добавить",
        "sv-SE": "Lägg till",
        "sv": "Lägg till",
        "th-TH": "เพิ่ม",
        "th": "เพิ่ม",
        "tr-TR": "Ekle",
        "tr": "Ekle"
    },
    "RL_AddProduct": {
        "key": "RL_AddProduct",
        "en": "Add Product",
        "ca-ES": "Afegeix producte",
        "ca": "Afegeix producte",
        "cs-CZ": "Přidat  produkt",
        "cs": "Přidat  produkt",
        "da-DK": "Tilføj produkt",
        "da": "Tilføj produkt",
        "de-DE": "Ein Produkt hinzufügen",
        "de": "Ein Produkt hinzufügen",
        "el-GR": "Προσθήκη ενός προϊόντος",
        "el": "Προσθήκη ενός προϊόντος",
        "en-GB": "Add Product",
        "es-ES": "Añadir producto",
        "es": "Añadir producto",
        "fi-FI": "Lisää tuote",
        "fr-FR": "Ajouter  produit",
        "fr": "Ajouter  produit",
        "he-IL": "הוסיפו מוצר אחד",
        "hr-HR": "Dodaj proizvod",
        "hu-HU": "termék hozzáadása",
        "hu": "termék hozzáadása",
        "it-IT": "Aggiungi prodotto",
        "it": "Aggiungi prodotto",
        "ja-JP": "件の商品を追加",
        "ja": "件の商品を追加",
        "ko-KR": "개 제품 추가",
        "ko": "개 제품 추가",
        "nl-NL": "Voeg product toe",
        "nl": "Voeg product toe",
        "no-NO": "Legg til ett produkt",
        "pl-PL": "Dodaj produkt",
        "pl": "Dodaj produkt",
        "pt-BR": "Adicionar produto",
        "pt-PT": "Adicionar produto",
        "ru-RU": "Добавить товар",
        "ru": "Добавить товар",
        "sv-SE": "Lägg till produkt",
        "sv": "Lägg till produkt",
        "th-TH": "เพิ่มสินค้า รายการ",
        "th": "เพิ่มสินค้า รายการ",
        "tr-TR": "Ürün Ekle",
        "tr": "Ürün Ekle"
    },
    "RL_Add1Product": {
        "key": "RL_Add1Product",
        "en": "Add 1 Product",
        "ca-ES": "Afegeix 1 producte",
        "ca": "Afegeix 1 producte",
        "cs-CZ": "Přidat 1 produkt",
        "cs": "Přidat 1 produkt",
        "da-DK": "Tilføj 1 produkt",
        "da": "Tilføj 1 produkt",
        "de-DE": "Ein Produkt hinzufügen",
        "de": "Ein Produkt hinzufügen",
        "el-GR": "Προσθήκη ενός προϊόντος",
        "el": "Προσθήκη ενός προϊόντος",
        "en-GB": "Add 1 Product",
        "es-ES": "Añadir 1 producto",
        "es": "Añadir 1 producto",
        "fi-FI": "Lisää 1 tuote",
        "fr-FR": "Ajouter 1 produit",
        "fr": "Ajouter 1 produit",
        "he-IL": "הוסיפו מוצר אחד",
        "hr-HR": "Dodaj 1 proizvod",
        "hu-HU": "1 termék hozzáadása",
        "hu": "1 termék hozzáadása",
        "it-IT": "Aggiungi 1 prodotto",
        "it": "Aggiungi 1 prodotto",
        "ja-JP": "1件の商品を追加",
        "ja": "1件の商品を追加",
        "ko-KR": "1개 제품 추가",
        "ko": "1개 제품 추가",
        "nl-NL": "Voeg 1 product toe",
        "nl": "Voeg 1 product toe",
        "no-NO": "Legg til ett produkt",
        "pl-PL": "Dodaj 1 produkt",
        "pl": "Dodaj 1 produkt",
        "pt-BR": "Adicionar 1 produto",
        "pt-PT": "Adicionar 1 produto",
        "ru-RU": "Добавить 1 товар",
        "ru": "Добавить 1 товар",
        "sv-SE": "Lägg till 1 produkt",
        "sv": "Lägg till 1 produkt",
        "th-TH": "เพิ่มสินค้า 1 รายการ",
        "th": "เพิ่มสินค้า 1 รายการ",
        "tr-TR": "1 Ürün Ekle",
        "tr": "1 Ürün Ekle"
    },
    "RL_Add2Products": {
        "key": "RL_Add2Products",
        "en": "Add {{1}} Products",
        "ca-ES": "Afegeix {{1}} productes",
        "ca": "Afegeix {{1}} productes",
        "cs-CZ": "Přidat {{1}} produkty",
        "cs": "Přidat {{1}} produkty",
        "da-DK": "Tilføj {{1}} produkter",
        "da": "Tilføj {{1}} produkter",
        "de-DE": "{{1}} Produkte hinzufügen",
        "de": "{{1}} Produkte hinzufügen",
        "el-GR": "Προσθήκη {{1}} προϊόντων",
        "el": "Προσθήκη {{1}} προϊόντων",
        "en-GB": "Add {{1}} Products",
        "es-ES": "Añadir {{1}} productos",
        "es": "Añadir {{1}} productos",
        "fi-FI": "Lisää {{1}} tuote(tta)",
        "fr-FR": "Ajouter {{1}} produits",
        "fr": "Ajouter {{1}} produits",
        "he-IL": "הוסיפו {{1}} מוצרים",
        "hr-HR": "Dodaj proizvode (njih {{1}})",
        "hu-HU": "{{1}} termék hozzáadása",
        "hu": "{{1}} termék hozzáadása",
        "it-IT": "Aggiungi {{1}} prodotti",
        "it": "Aggiungi {{1}} prodotti",
        "ja-JP": "{{1}}件の商品を追加",
        "ja": "{{1}}件の商品を追加",
        "ko-KR": "{{1}}개 제품 추가",
        "ko": "{{1}}개 제품 추가",
        "nl-NL": "Voeg {{1}} producten toe",
        "nl": "Voeg {{1}} producten toe",
        "no-NO": "Legg til {{1}} produkter",
        "pl-PL": "Dodaj produkty: {{1}}",
        "pl": "Dodaj produkty: {{1}}",
        "pt-BR": "Adicionar {{1}} produtos",
        "pt-PT": "Adicionar {{1}} produtos",
        "ru-RU": "Добавить товары: {{1}}",
        "ru": "Добавить товары: {{1}}",
        "sv-SE": "Lägg till {{1}} produkter",
        "sv": "Lägg till {{1}} produkter",
        "th-TH": "เพิ่มสินค้า {{1}} รายการ",
        "th": "เพิ่มสินค้า {{1}} รายการ",
        "tr-TR": "{{1}} Ürün Ekle",
        "tr": "{{1}} Ürün Ekle"
    },
    "RL_AddMarkdown": {
        "key": "RL_AddMarkdown",
        "en": "Add Markdown",
        "ca-ES": "Afegeix un descompte",
        "ca": "Afegeix un descompte",
        "cs-CZ": "Přidat slevu",
        "cs": "Přidat slevu",
        "da-DK": "Tilføj prisnedsættelse",
        "da": "Tilføj prisnedsættelse",
        "de-DE": "Preisabschlag hinzufügen",
        "de": "Preisabschlag hinzufügen",
        "el-GR": "Προσθήκη μείωσης τιμής",
        "el": "Προσθήκη μείωσης τιμής",
        "en-GB": "Add Markdown",
        "es-ES": "Añadir rebaja",
        "es": "Añadir rebaja",
        "fi-FI": "Lisää alennus",
        "fr-FR": "Ajouter une démarque",
        "fr": "Ajouter une démarque",
        "he-IL": "הוסיפו הנחה",
        "hr-HR": "Dodaj sniženje",
        "hu-HU": "Leértékelés hozzáadása",
        "hu": "Leértékelés hozzáadása",
        "it-IT": "Aggiungi ribasso",
        "it": "Aggiungi ribasso",
        "ja-JP": "値下げを追加",
        "ja": "値下げを追加",
        "ko-KR": "가격 인하 추가",
        "ko": "가격 인하 추가",
        "nl-NL": "Voeg korting toe",
        "nl": "Voeg korting toe",
        "no-NO": "Legg til tilbud",
        "pl-PL": "Dodaj obniżkę",
        "pl": "Dodaj obniżkę",
        "pt-BR": "Adicionar redução de preço",
        "pt-PT": "Adicionar desconto",
        "ru-RU": "Добавить уценку",
        "ru": "Добавить уценку",
        "sv-SE": "Lägg till prissänkning",
        "sv": "Lägg till prissänkning",
        "th-TH": "เพิ่มรายการลดราคา",
        "th": "เพิ่มรายการลดราคา",
        "tr-TR": "İndirim Ekle",
        "tr": "İndirim Ekle"
    },
    "RL_AddPromotion": {
        "key": "RL_AddPromotion",
        "en": "Add Promotion",
        "ca-ES": "Afegeix una promoció",
        "ca": "Afegeix una promoció",
        "cs-CZ": "Přidat promoakci",
        "cs": "Přidat promoakci",
        "da-DK": "Tilføj kampagne",
        "da": "Tilføj kampagne",
        "de-DE": "Angebot hinzufügen",
        "de": "Angebot hinzufügen",
        "el-GR": "Προσθήκη προσφοράς",
        "el": "Προσθήκη προσφοράς",
        "en-GB": "Add Promotion",
        "es-ES": "Añadir promoción",
        "es": "Añadir promoción",
        "fi-FI": "Lisää tarjouskampanja",
        "fr-FR": "Ajouter une promotion",
        "fr": "Ajouter une promotion",
        "he-IL": "הוסיפו מבצע",
        "hr-HR": "Dodaj promociju",
        "hu-HU": "Promóció hozzáadása",
        "hu": "Promóció hozzáadása",
        "it-IT": "Aggiungi promozione",
        "it": "Aggiungi promozione",
        "ja-JP": "プロモーションを追加",
        "ja": "プロモーションを追加",
        "ko-KR": "프로모션 추가",
        "ko": "프로모션 추가",
        "nl-NL": "Voeg promotie toe",
        "nl": "Voeg promotie toe",
        "no-NO": "Legg til kampanje",
        "pl-PL": "Dodaj promocję",
        "pl": "Dodaj promocję",
        "pt-BR": "Adicionar promoção",
        "pt-PT": "Adicionar promoção",
        "ru-RU": "Добавить акцию",
        "ru": "Добавить акцию",
        "sv-SE": "Lägg till kampanj",
        "sv": "Lägg till kampanj",
        "th-TH": "เพิ่มโปรโมชัน",
        "th": "เพิ่มโปรโมชัน",
        "tr-TR": "Promosyon Ekle",
        "tr": "Promosyon Ekle"
    },
    "RL_AdultUnisex": {
        "key": "RL_AdultUnisex",
        "en": "Adult Unisex",
        "ca-ES": "Models unisex per a adults",
        "ca": "Models unisex per a adults",
        "cs-CZ": "Dospělí (unisex)",
        "cs": "Dospělí (unisex)",
        "da-DK": "Voksen unisex",
        "da": "Voksen unisex",
        "de-DE": "Erwachsene (Unisex)",
        "de": "Erwachsene (Unisex)",
        "el-GR": "Unisex για ενήλικες",
        "el": "Unisex για ενήλικες",
        "en-GB": "Adult Unisex",
        "es-ES": "Modelos unisex para adultos",
        "es": "Modelos unisex para adultos",
        "fi-FI": "Aikuiset unisex",
        "fr-FR": "Adulte mixte",
        "fr": "Adulte mixte",
        "he-IL": "יוניסקס – מבוגרים",
        "hr-HR": "Odrasli – uniseks",
        "hu-HU": "Felnőtt uniszex",
        "hu": "Felnőtt uniszex",
        "it-IT": "Adulti – Unisex",
        "it": "Adulti – Unisex",
        "ja-JP": "大人 ユニセックス",
        "ja": "大人 ユニセックス",
        "ko-KR": "성인공용",
        "ko": "성인공용",
        "nl-NL": "Unisex voor volwassenen",
        "nl": "Unisex voor volwassenen",
        "no-NO": "Unisex til voksen",
        "pl-PL": "Dla dorosłych uniseks",
        "pl": "Dla dorosłych uniseks",
        "pt-BR": "Adulto unissex",
        "pt-PT": "Adulto (unissexo)",
        "ru-RU": "Унисекс, взрослые",
        "ru": "Унисекс, взрослые",
        "sv-SE": "Vuxen unisex",
        "sv": "Vuxen unisex",
        "th-TH": "Unisex ผู้ใหญ่",
        "th": "Unisex ผู้ใหญ่",
        "tr-TR": "Unisex Yetişkin Ürünleri",
        "tr": "Unisex Yetişkin Ürünleri"
    },
    "RL_All": {
        "key": "RL_All",
        "en": "All",
        "ca-ES": "Tot",
        "ca": "Tot",
        "cs-CZ": "Všechno",
        "cs": "Všechno",
        "da-DK": "Alle",
        "da": "Alle",
        "de-DE": "Alle",
        "de": "Alle",
        "el-GR": "Όλα",
        "el": "Όλα",
        "en-GB": "All",
        "es-ES": "Todo",
        "es": "Todo",
        "fi-FI": "Kaikki",
        "fr-FR": "Tout afficher",
        "fr": "Tout afficher",
        "he-IL": "הכול",
        "hr-HR": "Sve",
        "hu-HU": "Összes",
        "hu": "Összes",
        "it-IT": "Tutto",
        "it": "Tutto",
        "ja-JP": "すべて",
        "ja": "すべて",
        "ko-KR": "전체",
        "ko": "전체",
        "nl-NL": "Alle",
        "nl": "Alle",
        "no-NO": "Alle",
        "pl-PL": "Wszystkie",
        "pl": "Wszystkie",
        "pt-BR": "Tudo",
        "pt-PT": "Tudo",
        "ru-RU": "Все",
        "ru": "Все",
        "sv-SE": "Alla",
        "sv": "Alla",
        "th-TH": "ทั้งหมด",
        "th": "ทั้งหมด",
        "tr-TR": "Tümü",
        "tr": "Tümü"
    },
    "RL_AllDivisions": {
        "key": "RL_AllDivisions",
        "en": "All Divisions",
        "ca-ES": "Totes les categories",
        "ca": "Totes les categories",
        "cs-CZ": "Všechny sekce",
        "cs": "Všechny sekce",
        "da-DK": "Alle divisioner",
        "da": "Alle divisioner",
        "de-DE": "Alle Divisions",
        "de": "Alle Divisions",
        "el-GR": "Όλα τα τμήματα",
        "el": "Όλα τα τμήματα",
        "en-GB": "All Divisions",
        "es-ES": "Todas las categorías",
        "es": "Todas las categorías",
        "fi-FI": "Kaikki kategoriat",
        "fr-FR": "Toutes les divisions",
        "fr": "Toutes les divisions",
        "he-IL": "כל המחלקות",
        "hr-HR": "Sve kategorije",
        "hu-HU": "Minden részleg",
        "hu": "Minden részleg",
        "it-IT": "Tutti i reparti",
        "it": "Tutti i reparti",
        "ja-JP": "すべての部門",
        "ja": "すべての部門",
        "ko-KR": "모든 부서",
        "ko": "모든 부서",
        "nl-NL": "Alle divisies",
        "nl": "Alle divisies",
        "no-NO": "Alle avdelinger",
        "pl-PL": "Wszystkie działy",
        "pl": "Wszystkie działy",
        "pt-BR": "Todas as divisões",
        "pt-PT": "Todas as divisões",
        "ru-RU": "Все отделы",
        "ru": "Все отделы",
        "sv-SE": "Alla avdelningar",
        "sv": "Alla avdelningar",
        "th-TH": "ทุกหมวด",
        "th": "ทุกหมวด",
        "tr-TR": "Tüm Bölümler",
        "tr": "Tüm Bölümler"
    },
    "RL_Apparel": {
        "key": "RL_Apparel",
        "en": "Apparel",
        "ca-ES": "Roba",
        "ca": "Roba",
        "cs-CZ": "Oblečení",
        "cs": "Oblečení",
        "da-DK": "Tøj",
        "da": "Tøj",
        "de-DE": "Bekleidung",
        "de": "Bekleidung",
        "el-GR": "Ρούχα",
        "el": "Ρούχα",
        "en-GB": "Apparel",
        "es-ES": "Ropa",
        "es": "Ropa",
        "fi-FI": "Vaatteet",
        "fr-FR": "Vêtements",
        "fr": "Vêtements",
        "he-IL": "הלבשה",
        "hr-HR": "Odjeća",
        "hu-HU": "Ruházat",
        "hu": "Ruházat",
        "it-IT": "Abbigliamento",
        "it": "Abbigliamento",
        "ja-JP": "アパレル",
        "ja": "アパレル",
        "ko-KR": "의류",
        "ko": "의류",
        "nl-NL": "Kleding",
        "nl": "Kleding",
        "no-NO": "Klær",
        "pl-PL": "Odzież",
        "pl": "Odzież",
        "pt-BR": "Vestuário",
        "pt-PT": "Vestuário",
        "ru-RU": "Одежда",
        "ru": "Одежда",
        "sv-SE": "Kläder",
        "sv": "Kläder",
        "th-TH": "เครื่องแต่งกาย",
        "th": "เครื่องแต่งกาย",
        "tr-TR": "Giysi",
        "tr": "Giysi"
    },
    "RL_ApparelDivision": {
        "key": "RL_ApparelDivision",
        "en": "Apparel Division",
        "ca-ES": "Categoria de roba",
        "ca": "Categoria de roba",
        "cs-CZ": "Sekce Oblečení",
        "cs": "Sekce Oblečení",
        "da-DK": "Tøj, division",
        "da": "Tøj, division",
        "de-DE": "Apparel Division",
        "de": "Apparel Division",
        "el-GR": "Τμήμα ρούχων",
        "el": "Τμήμα ρούχων",
        "en-GB": "Apparel Division",
        "es-ES": "Categoría de ropa",
        "es": "Categoría de ropa",
        "fi-FI": "Vaatekategoria",
        "fr-FR": "Division vêtements",
        "fr": "Division vêtements",
        "he-IL": "חטיבת ההלבשה",
        "hr-HR": "Kategorija odjeće",
        "hu-HU": "Ruházati részleg",
        "hu": "Ruházati részleg",
        "it-IT": "Reparto abbigliamento",
        "it": "Reparto abbigliamento",
        "ja-JP": "アパレル部門",
        "ja": "アパレル部門",
        "ko-KR": "의류 부서",
        "ko": "의류 부서",
        "nl-NL": "Kledingdivisie",
        "nl": "Kledingdivisie",
        "no-NO": "Klesavdeling",
        "pl-PL": "Dział Odzież",
        "pl": "Dział Odzież",
        "pt-BR": "Divisão de vestuário",
        "pt-PT": "Divisão de vestuário",
        "ru-RU": "Отдел одежды",
        "ru": "Отдел одежды",
        "sv-SE": "Klädavdelning",
        "sv": "Klädavdelning",
        "th-TH": "หมวดเครื่องแต่งกาย",
        "th": "หมวดเครื่องแต่งกาย",
        "tr-TR": "Giysi Bölümü",
        "tr": "Giysi Bölümü"
    },
    "RL_Athlete": {
        "key": "RL_Athlete",
        "en": "Athlete",
        "ca-ES": "Atleta",
        "ca": "Atleta",
        "cs-CZ": "Zaměstnanec",
        "cs": "Zaměstnanec",
        "da-DK": "Atlet",
        "da": "Atlet",
        "de-DE": "Athlete",
        "de": "Athlete",
        "el-GR": "Athlete",
        "el": "Athlete",
        "en-GB": "Athlete",
        "es-ES": "Athlete",
        "es": "Athlete",
        "fi-FI": "Urheiluvaatteet",
        "fr-FR": "Athlete",
        "fr": "Athlete",
        "he-IL": "ספורטאי",
        "hr-HR": "Athlete",
        "hu-HU": "Athlete",
        "hu": "Athlete",
        "it-IT": "Athlete",
        "it": "Athlete",
        "ja-JP": "Athlete",
        "ja": "Athlete",
        "ko-KR": "Athlete",
        "ko": "Athlete",
        "nl-NL": "Athlete",
        "nl": "Athlete",
        "no-NO": "Atlet",
        "pl-PL": "Athlete",
        "pl": "Athlete",
        "pt-BR": "Athlete",
        "pt-PT": "Athlete",
        "ru-RU": "Атлет",
        "ru": "Атлет",
        "sv-SE": "Atlet",
        "sv": "Atlet",
        "th-TH": "Athlete",
        "th": "Athlete",
        "tr-TR": "Athlete",
        "tr": "Athlete"
    },
    "RL_AthleteRoles": {
        "key": "RL_AthleteRoles",
        "en": "Athlete Roles",
        "ca-ES": "Funcions de l'athlete",
        "ca": "Funcions de l'athlete",
        "cs-CZ": "Role zaměstnance na pozici Athlete",
        "cs": "Role zaměstnance na pozici Athlete",
        "da-DK": "Athlete-stillinger",
        "da": "Athlete-stillinger",
        "de-DE": "Rolle des Athlete",
        "de": "Rolle des Athlete",
        "el-GR": "Ρόλοι Athlete",
        "el": "Ρόλοι Athlete",
        "en-GB": "Athlete Roles",
        "es-ES": "Funciones de athlete",
        "es": "Funciones de athlete",
        "fi-FI": "Urheilijaroolit",
        "fr-FR": "Rôles de l'Athlete",
        "fr": "Rôles de l'Athlete",
        "he-IL": "תפקידי Athletes",
        "hr-HR": "Uloge athletea",
        "hu-HU": "Athlete-szerepkörök",
        "hu": "Athlete-szerepkörök",
        "it-IT": "Ruoli Athlete",
        "it": "Ruoli Athlete",
        "ja-JP": "Athleteの業務",
        "ja": "Athleteの業務",
        "ko-KR": "Athlete 역할",
        "ko": "Athlete 역할",
        "nl-NL": "Atleetrollen",
        "nl": "Atleetrollen",
        "no-NO": "Atletroller",
        "pl-PL": "Role Athletes",
        "pl": "Role Athletes",
        "pt-BR": "Funções de Athlete",
        "pt-PT": "Funções dos Athletes",
        "ru-RU": "Задачи атлета",
        "ru": "Задачи атлета",
        "sv-SE": "Athlete-roller",
        "sv": "Athlete-roller",
        "th-TH": "บทบาทของ Athlete",
        "th": "บทบาทของ Athlete",
        "tr-TR": "Athlete Rolleri",
        "tr": "Athlete Rolleri"
    },
    "RL_BackToPromotions": {
        "key": "RL_BackToPromotions",
        "en": "Back to Promotions",
        "ca-ES": "Torna a les promocions",
        "ca": "Torna a les promocions",
        "cs-CZ": "Zpátky k promoakcím",
        "cs": "Zpátky k promoakcím",
        "da-DK": "Tilbage til kampagner",
        "da": "Tilbage til kampagner",
        "de-DE": "Zurück zu Angeboten",
        "de": "Zurück zu Angeboten",
        "el-GR": "Επιστροφή στις προσφορές",
        "el": "Επιστροφή στις προσφορές",
        "en-GB": "Back to Promotions",
        "es-ES": "Volver a Promociones",
        "es": "Volver a Promociones",
        "fi-FI": "Takaisin kampanjoihin",
        "fr-FR": "Retour aux promotions",
        "fr": "Retour aux promotions",
        "he-IL": "חזרה למבצעים",
        "hr-HR": "Natrag na promocije",
        "hu-HU": "Vissza a promóciókhoz",
        "hu": "Vissza a promóciókhoz",
        "it-IT": "Torna alle promozioni",
        "it": "Torna alle promozioni",
        "ja-JP": "プロモーションに戻る",
        "ja": "プロモーションに戻る",
        "ko-KR": "프로모션으로 돌아가기",
        "ko": "프로모션으로 돌아가기",
        "nl-NL": "Terug naar promoties",
        "nl": "Terug naar promoties",
        "no-NO": "Tilbake til kampanjer",
        "pl-PL": "Powrót do promocji",
        "pl": "Powrót do promocji",
        "pt-BR": "Voltar para Promoções",
        "pt-PT": "Regressar às Promoções",
        "ru-RU": "Назад к промоакциям",
        "ru": "Назад к промоакциям",
        "sv-SE": "Tillbaka till kampanjer",
        "sv": "Tillbaka till kampanjer",
        "th-TH": "กลับไปที่โปรโมชัน",
        "th": "กลับไปที่โปรโมชัน",
        "tr-TR": "Promosyonlara Dön",
        "tr": "Promosyonlara Dön"
    },
    "RL_Browser": {
        "key": "RL_Browser",
        "en": "browser",
        "ca-ES": "navegador",
        "ca": "navegador",
        "cs-CZ": "prohlížeč",
        "cs": "prohlížeč",
        "da-DK": "browser",
        "da": "browser",
        "de-DE": "Browser",
        "de": "Browser",
        "el-GR": "πρόγραμμα περιήγησης",
        "el": "πρόγραμμα περιήγησης",
        "en-GB": "browser",
        "es-ES": "navegador",
        "es": "navegador",
        "fi-FI": "selain",
        "fr-FR": "navigateur",
        "fr": "navigateur",
        "he-IL": "דפדפן",
        "hr-HR": "preglednik",
        "hu-HU": "böngésző",
        "hu": "böngésző",
        "it-IT": "browser",
        "it": "browser",
        "ja-JP": "ブラウザ",
        "ja": "ブラウザ",
        "ko-KR": "브라우저",
        "ko": "브라우저",
        "nl-NL": "browser",
        "nl": "browser",
        "no-NO": "nettleser",
        "pl-PL": "przeglądarka",
        "pl": "przeglądarka",
        "pt-BR": "navegador",
        "pt-PT": "navegador",
        "ru-RU": "браузер",
        "ru": "браузер",
        "sv-SE": "webbläsare",
        "sv": "webbläsare",
        "th-TH": "เบราว์เซอร์",
        "th": "เบราว์เซอร์",
        "tr-TR": "tarayıcı",
        "tr": "tarayıcı"
    },
    "RL_Clear": {
        "key": "RL_Clear",
        "en": "Clear",
        "ca-ES": "Esborra",
        "ca": "Esborra",
        "cs-CZ": "Vymazat",
        "cs": "Vymazat",
        "da-DK": "Ryd",
        "da": "Ryd",
        "de-DE": "Löschen",
        "de": "Löschen",
        "el-GR": "Απαλοιφή",
        "el": "Απαλοιφή",
        "en-GB": "Clear",
        "es-ES": "Borrar",
        "es": "Borrar",
        "fi-FI": "Tyhjennä",
        "fr-FR": "Effacer",
        "fr": "Effacer",
        "he-IL": "ניקוי",
        "hr-HR": "Očisti",
        "hu-HU": "Törlés",
        "hu": "Törlés",
        "it-IT": "Cancella",
        "it": "Cancella",
        "ja-JP": "クリア",
        "ja": "クリア",
        "ko-KR": "지우기",
        "ko": "지우기",
        "nl-NL": "Wis",
        "nl": "Wis",
        "no-NO": "Fjern",
        "pl-PL": "Wyczyść",
        "pl": "Wyczyść",
        "pt-BR": "Limpar",
        "pt-PT": "Limpar",
        "ru-RU": "Очистить",
        "ru": "Очистить",
        "sv-SE": "Rensa",
        "sv": "Rensa",
        "th-TH": "ล้าง",
        "th": "ล้าง",
        "tr-TR": "Temizle",
        "tr": "Temizle"
    },
    "RL_Code": {
        "key": "RL_Code",
        "en": "code",
        "ca-ES": "codi",
        "ca": "codi",
        "cs-CZ": "kód",
        "cs": "kód",
        "da-DK": "kode",
        "da": "kode",
        "de-DE": "Code",
        "de": "Code",
        "el-GR": "κωδικός",
        "el": "κωδικός",
        "en-GB": "code",
        "es-ES": "código",
        "es": "código",
        "fi-FI": "koodi",
        "fr-FR": "code",
        "fr": "code",
        "he-IL": "קוד",
        "hr-HR": "šifra",
        "hu-HU": "kód",
        "hu": "kód",
        "it-IT": "codice",
        "it": "codice",
        "ja-JP": "コード",
        "ja": "コード",
        "ko-KR": "코드",
        "ko": "코드",
        "nl-NL": "code",
        "nl": "code",
        "no-NO": "kode",
        "pl-PL": "kod",
        "pl": "kod",
        "pt-BR": "código",
        "pt-PT": "código",
        "ru-RU": "кода",
        "ru": "кода",
        "sv-SE": "kod",
        "sv": "kod",
        "th-TH": "รหัส",
        "th": "รหัส",
        "tr-TR": "kod",
        "tr": "kod"
    },
    "RL_Codes": {
        "key": "RL_Codes",
        "en": "codes",
        "ca-ES": "codis",
        "ca": "codis",
        "cs-CZ": "kódy",
        "cs": "kódy",
        "da-DK": "koder",
        "da": "koder",
        "de-DE": "Codes",
        "de": "Codes",
        "el-GR": "κωδικοί",
        "el": "κωδικοί",
        "en-GB": "codes",
        "es-ES": "códigos",
        "es": "códigos",
        "fi-FI": "koodit",
        "fr-FR": "codes",
        "fr": "codes",
        "he-IL": "קודים",
        "hr-HR": "šifre",
        "hu-HU": "kód",
        "hu": "kód",
        "it-IT": "codici",
        "it": "codici",
        "ja-JP": "コード",
        "ja": "コード",
        "ko-KR": "코드",
        "ko": "코드",
        "nl-NL": "codes",
        "nl": "codes",
        "no-NO": "koder",
        "pl-PL": "kody",
        "pl": "kody",
        "pt-BR": "códigos",
        "pt-PT": "códigos",
        "ru-RU": "кодов",
        "ru": "кодов",
        "sv-SE": "koder",
        "sv": "koder",
        "th-TH": "รหัส",
        "th": "รหัส",
        "tr-TR": "kodlar",
        "tr": "kodlar"
    },
    "RL_Confirm": {
        "key": "RL_Confirm",
        "en": "Are you sure?",
        "ca-ES": "Ho confirmes?",
        "ca": "Ho confirmes?",
        "cs-CZ": "Určitě?",
        "cs": "Určitě?",
        "da-DK": "Er du sikker?",
        "da": "Er du sikker?",
        "de-DE": "Bist du sicher?",
        "de": "Bist du sicher?",
        "el-GR": "Είστε βέβαιοι;",
        "el": "Είστε βέβαιοι;",
        "en-GB": "Are you sure?",
        "es-ES": "¿Seguro?",
        "es": "¿Seguro?",
        "fi-FI": "Oletko varma?",
        "fr-FR": "Êtes-vous sûr(e) ?",
        "fr": "Êtes-vous sûr(e) ?",
        "he-IL": "האם אתם בטוחים?",
        "hr-HR": "Jeste li sigurni?",
        "hu-HU": "Biztos vagy benne?",
        "hu": "Biztos vagy benne?",
        "it-IT": "Confermi?",
        "it": "Confermi?",
        "ja-JP": "続行してもよろしいですか？",
        "ja": "続行してもよろしいですか？",
        "ko-KR": "계속 진행하시겠어요?",
        "ko": "계속 진행하시겠어요?",
        "nl-NL": "Weet je het zeker?",
        "nl": "Weet je het zeker?",
        "no-NO": "Er du sikker?",
        "pl-PL": "Na pewno?",
        "pl": "Na pewno?",
        "pt-BR": "Tem certeza?",
        "pt-PT": "Tem a certeza?",
        "ru-RU": "Точно?",
        "ru": "Точно?",
        "sv-SE": "Är du säker?",
        "sv": "Är du säker?",
        "th-TH": "คุณแน่ใจหรือไม่",
        "th": "คุณแน่ใจหรือไม่",
        "tr-TR": "Emin misin?",
        "tr": "Emin misin?"
    },
    "RL_CrossDivision": {
        "key": "RL_CrossDivision",
        "en": "Cross Division",
        "ca-ES": "Categoria transversal",
        "ca": "Categoria transversal",
        "cs-CZ": "Sekce Cross Division",
        "cs": "Sekce Cross Division",
        "da-DK": "Krydsdivision",
        "da": "Krydsdivision",
        "de-DE": "Cross Division",
        "de": "Cross Division",
        "el-GR": "Σε όλα τα τμήματα",
        "el": "Σε όλα τα τμήματα",
        "en-GB": "Cross Division",
        "es-ES": "Categoría transversal",
        "es": "Categoría transversal",
        "fi-FI": "Useampi kategoria",
        "fr-FR": "Divisions multiples",
        "fr": "Divisions multiples",
        "he-IL": "רב-מחלקתי",
        "hr-HR": "Višekategorijsko",
        "hu-HU": "Keresztrészleg",
        "hu": "Keresztrészleg",
        "it-IT": "Cross Division",
        "it": "Cross Division",
        "ja-JP": "クロス部門",
        "ja": "クロス部門",
        "ko-KR": "교차 부서",
        "ko": "교차 부서",
        "nl-NL": "Divisie overstijgend",
        "nl": "Divisie overstijgend",
        "no-NO": "Blandet avdeling",
        "pl-PL": "Cross Division",
        "pl": "Cross Division",
        "pt-BR": "Divisão mista",
        "pt-PT": "Interdivisional",
        "ru-RU": "Для всех отделов",
        "ru": "Для всех отделов",
        "sv-SE": "Flera avdelningar",
        "sv": "Flera avdelningar",
        "th-TH": "ข้ามหมวด",
        "th": "ข้ามหมวด",
        "tr-TR": "Bölümler Arası",
        "tr": "Bölümler Arası"
    },
    "RL_DefaultPromotion": {
        "key": "RL_DefaultPromotion",
        "en": "This is a default promotion. Only the discount percentage can be edited.",
        "cs-CZ": "Jde o výchozí promoakci. Editovat se dá jenom procentuální vyjádření slevy.",
        "cs": "Jde o výchozí promoakci. Editovat se dá jenom procentuální vyjádření slevy.",
        "da-DK": "Dette er en standardkampagne. Kun rabatprocenten kan redigeres.",
        "da": "Dette er en standardkampagne. Kun rabatprocenten kan redigeres.",
        "de-DE": "Hierbei handelt es sich um ein Standardangebot. Es kann nur der Rabattprozentsatz bearbeitet werden.",
        "de": "Hierbei handelt es sich um ein Standardangebot. Es kann nur der Rabattprozentsatz bearbeitet werden.",
        "el-GR": "Πρόκειται για προεπιλεγμένη προσφορά. Παρέχεται η δυνατότητα επεξεργασίας μόνο του ποσοστού έκπτωσης.",
        "el": "Πρόκειται για προεπιλεγμένη προσφορά. Παρέχεται η δυνατότητα επεξεργασίας μόνο του ποσοστού έκπτωσης.",
        "en-GB": "This is a default promotion. Only the discount percentage can be edited.",
        "es-ES": "Esta es una promoción por defecto. Solo se puede editar el porcentaje del descuento.",
        "es": "Esta es una promoción por defecto. Solo se puede editar el porcentaje del descuento.",
        "fi-FI": "Tämä on oletuskampanja. Vain alennusprosenttia voi muokata.",
        "fr-FR": "Il s'agit d'une promotion par défaut. Seul le pourcentage de réduction peut être modifié.",
        "fr": "Il s'agit d'une promotion par défaut. Seul le pourcentage de réduction peut être modifié.",
        "he-IL": "זהו מבצע ברירת מחדל. ניתן לערוך רק את אחוז ההנחה.",
        "hr-HR": "Ovo je zadana promocija. Urediti se može samo postotak popusta.",
        "hu-HU": "Ez alapértelmezett promóció. Csak a kedvezmény százalékos aránya szerkeszthető.",
        "hu": "Ez alapértelmezett promóció. Csak a kedvezmény százalékos aránya szerkeszthető.",
        "it-IT": "Questa è una promozione predefinita. Solo la percentuale di sconto può essere modificata.",
        "it": "Questa è una promozione predefinita. Solo la percentuale di sconto può essere modificata.",
        "ja-JP": "これはデフォルトのプロモーションです。 割引率のみ編集できます。",
        "ja": "これはデフォルトのプロモーションです。 割引率のみ編集できます。",
        "ko-KR": "기본 프로모션입니다. 할인 비율만 수정할 수 있습니다.",
        "ko": "기본 프로모션입니다. 할인 비율만 수정할 수 있습니다.",
        "nl-NL": "Dit is een standaard promotie. Alleen het kortingspercentage kan worden bewerkt.",
        "nl": "Dit is een standaard promotie. Alleen het kortingspercentage kan worden bewerkt.",
        "no-NO": "Dette er en standardkampanje. Det er bare rabattprosenten som kan redigeres.",
        "pl-PL": "To jest domyślna promocja. Można zmienić tylko odsetek rabatu.",
        "pl": "To jest domyślna promocja. Można zmienić tylko odsetek rabatu.",
        "pt-BR": "Esta é uma promoção padrão. Somente a porcentagem de desconto pode ser editada.",
        "pt-PT": "Esta é uma promoção predefinida. Só é possível editar a percentagem de desconto.",
        "ru-RU": "Это промоакция по умолчанию. Можно изменить только процент скидки.",
        "ru": "Это промоакция по умолчанию. Можно изменить только процент скидки.",
        "sv-SE": "Det här är en standardkampanj. Du kan bara redigera rabattens procentandel.",
        "sv": "Det här är en standardkampanj. Du kan bara redigera rabattens procentandel.",
        "th-TH": "นี่คือโปรโมชันเริ่มต้น แก้ไขได้เฉพาะเปอร์เซ็นต์ส่วนลดเท่านั้น",
        "th": "นี่คือโปรโมชันเริ่มต้น แก้ไขได้เฉพาะเปอร์เซ็นต์ส่วนลดเท่านั้น",
        "tr-TR": "Bu, varsayılan bir promosyondur. Yalnızca indirim oranı düzenlenebilir.",
        "tr": "Bu, varsayılan bir promosyondur. Yalnızca indirim oranı düzenlenebilir."
    },
    "RL_Delete": {
        "key": "RL_Delete",
        "en": "Delete",
        "ca-ES": "Elimina",
        "ca": "Elimina",
        "cs-CZ": "Odstranit",
        "cs": "Odstranit",
        "da-DK": "Slet",
        "da": "Slet",
        "de-DE": "Löschen",
        "de": "Löschen",
        "el-GR": "Διαγραφή",
        "el": "Διαγραφή",
        "en-GB": "Delete",
        "es-ES": "Eliminar",
        "es": "Eliminar",
        "fi-FI": "Poista",
        "fr-FR": "Supprimer",
        "fr": "Supprimer",
        "he-IL": "מחיקה",
        "hr-HR": "Izbriši",
        "hu-HU": "Törlés",
        "hu": "Törlés",
        "it-IT": "Elimina",
        "it": "Elimina",
        "ja-JP": "削除",
        "ja": "削除",
        "ko-KR": "삭제",
        "ko": "삭제",
        "nl-NL": "Verwijder",
        "nl": "Verwijder",
        "no-NO": "Slett",
        "pl-PL": "Usuń",
        "pl": "Usuń",
        "pt-BR": "Excluir",
        "pt-PT": "Eliminar",
        "ru-RU": "Удалить",
        "ru": "Удалить",
        "sv-SE": "Ta bort",
        "sv": "Ta bort",
        "th-TH": "ลบ",
        "th": "ลบ",
        "tr-TR": "Sil",
        "tr": "Sil"
    },
    "RL_Discount": {
        "key": "RL_Discount",
        "en": "Discount",
        "ca-ES": "Descompte",
        "ca": "Descompte",
        "cs-CZ": "Sleva",
        "cs": "Sleva",
        "da-DK": "Rabat",
        "da": "Rabat",
        "de-DE": "Rabatt",
        "de": "Rabatt",
        "el-GR": "Έκπτωση",
        "el": "Έκπτωση",
        "en-GB": "Discount",
        "es-ES": "Descuento",
        "es": "Descuento",
        "fi-FI": "Alennus",
        "fr-FR": "Réduction",
        "fr": "Réduction",
        "he-IL": "הנחה",
        "hr-HR": "Popust",
        "hu-HU": "Kedvezmény",
        "hu": "Kedvezmény",
        "it-IT": "Sconto",
        "it": "Sconto",
        "ja-JP": "割引",
        "ja": "割引",
        "ko-KR": "할인",
        "ko": "할인",
        "nl-NL": "Korting",
        "nl": "Korting",
        "no-NO": "Rabatt",
        "pl-PL": "Rabat",
        "pl": "Rabat",
        "pt-BR": "Desconto",
        "pt-PT": "Desconto",
        "ru-RU": "Скидка",
        "ru": "Скидка",
        "sv-SE": "Rabatt",
        "sv": "Rabatt",
        "th-TH": "ส่วนลด",
        "th": "ส่วนลด",
        "tr-TR": "İndirim",
        "tr": "İndirim"
    },
    "RL_DiscountPercentage": {
        "key": "RL_DiscountPercentage",
        "en": "Discount Percentage",
        "ca-ES": "Percentatge del descompte",
        "ca": "Percentatge del descompte",
        "cs-CZ": "Procentuální vyjádření slevy",
        "cs": "Procentuální vyjádření slevy",
        "da-DK": "Rabatprocent",
        "da": "Rabatprocent",
        "de-DE": "Rabattprozentsatz",
        "de": "Rabattprozentsatz",
        "el-GR": "Ποσοστό έκπτωσης",
        "el": "Ποσοστό έκπτωσης",
        "en-GB": "Discount Percentage",
        "es-ES": "Porcentaje de descuento",
        "es": "Porcentaje de descuento",
        "fi-FI": "Alennusprosentti",
        "fr-FR": "Pourcentage de réduction",
        "fr": "Pourcentage de réduction",
        "he-IL": "אחוז הנחה",
        "hr-HR": "Postotak popusta",
        "hu-HU": "Kedvezmény százalékos aránya",
        "hu": "Kedvezmény százalékos aránya",
        "it-IT": "Percentuale sconto",
        "it": "Percentuale sconto",
        "ja-JP": "割引率",
        "ja": "割引率",
        "ko-KR": "할인 비율",
        "ko": "할인 비율",
        "nl-NL": "Kortingspercentage",
        "nl": "Kortingspercentage",
        "no-NO": "Rabattprosent",
        "pl-PL": "Odsetek rabatu",
        "pl": "Odsetek rabatu",
        "pt-BR": "Porcentagem de desconto",
        "pt-PT": "Percentagem de desconto",
        "ru-RU": "Процент скидки",
        "ru": "Процент скидки",
        "sv-SE": "Rabatt i procent",
        "sv": "Rabatt i procent",
        "th-TH": "เปอร์เซ็นต์ส่วนลด",
        "th": "เปอร์เซ็นต์ส่วนลด",
        "tr-TR": "İndirim Oranı",
        "tr": "İndirim Oranı"
    },
    "RL_Divisions": {
        "key": "RL_Divisions",
        "en": "Divisions",
        "ca-ES": "Categories",
        "ca": "Categories",
        "cs-CZ": "Sekce",
        "cs": "Sekce",
        "da-DK": "Divisioner",
        "da": "Divisioner",
        "de-DE": "Divisions",
        "de": "Divisions",
        "el-GR": "Τμήματα",
        "el": "Τμήματα",
        "en-GB": "Divisions",
        "es-ES": "Categorías",
        "es": "Categorías",
        "fi-FI": "Kategoriat",
        "fr-FR": "Divisions",
        "fr": "Divisions",
        "he-IL": "מחלקות",
        "hr-HR": "Kategorije",
        "hu-HU": "Részlegek",
        "hu": "Részlegek",
        "it-IT": "Reparti",
        "it": "Reparti",
        "ja-JP": "部門",
        "ja": "部門",
        "ko-KR": "부서",
        "ko": "부서",
        "nl-NL": "Divisies",
        "nl": "Divisies",
        "no-NO": "Avdelinger",
        "pl-PL": "Działy",
        "pl": "Działy",
        "pt-BR": "Divisões",
        "pt-PT": "Divisões",
        "ru-RU": "Отделы",
        "ru": "Отделы",
        "sv-SE": "Avdelningar",
        "sv": "Avdelningar",
        "th-TH": "หมวด",
        "th": "หมวด",
        "tr-TR": "Bölümler",
        "tr": "Bölümler"
    },
    "RL_EditPromotion": {
        "key": "RL_EditPromotion",
        "en": "Edit Promotion",
        "ca-ES": "Edita la promoció",
        "ca": "Edita la promoció",
        "cs-CZ": "Upravit promoakci",
        "cs": "Upravit promoakci",
        "da-DK": "Rediger kampagne",
        "da": "Rediger kampagne",
        "de-DE": "Angebot bearbeiten",
        "de": "Angebot bearbeiten",
        "el-GR": "Επεξεργασία προσφοράς",
        "el": "Επεξεργασία προσφοράς",
        "en-GB": "Edit Promotion",
        "es-ES": "Editar promoción",
        "es": "Editar promoción",
        "fi-FI": "Muokkaa tarjouskampanjaa",
        "fr-FR": "Modifier la promotion",
        "fr": "Modifier la promotion",
        "he-IL": "ערכו את המבצע",
        "hr-HR": "Uredi promociju",
        "hu-HU": "Promóció szerkesztése",
        "hu": "Promóció szerkesztése",
        "it-IT": "Modifica promozione",
        "it": "Modifica promozione",
        "ja-JP": "プロモーションを編集",
        "ja": "プロモーションを編集",
        "ko-KR": "프로모션 편집",
        "ko": "프로모션 편집",
        "nl-NL": "Bewerk promotie",
        "nl": "Bewerk promotie",
        "no-NO": "Endre kampanje",
        "pl-PL": "Edytuj promocję",
        "pl": "Edytuj promocję",
        "pt-BR": "Editar promoção",
        "pt-PT": "Editar promoção",
        "ru-RU": "Изменить акцию",
        "ru": "Изменить акцию",
        "sv-SE": "Redigera kampanj",
        "sv": "Redigera kampanj",
        "th-TH": "แก้ไขโปรโมชัน",
        "th": "แก้ไขโปรโมชัน",
        "tr-TR": "Promosyonu Düzenle",
        "tr": "Promosyonu Düzenle"
    },
    "RL_EndDate": {
        "key": "RL_EndDate",
        "en": "End Date",
        "ca-ES": "Data de finalització",
        "ca": "Data de finalització",
        "cs-CZ": "Koncové datum",
        "cs": "Koncové datum",
        "da-DK": "Slutdato",
        "da": "Slutdato",
        "de-DE": "Enddatum",
        "de": "Enddatum",
        "el-GR": "Ημερομηνία λήξης",
        "el": "Ημερομηνία λήξης",
        "en-GB": "End Date",
        "es-ES": "Fecha de finalización",
        "es": "Fecha de finalización",
        "fi-FI": "Päättymispäivä",
        "fr-FR": "Date de fin",
        "fr": "Date de fin",
        "he-IL": "תאריך סיום",
        "hr-HR": "Datum završetka",
        "hu-HU": "Befejezés dátuma",
        "hu": "Befejezés dátuma",
        "it-IT": "Data di fine",
        "it": "Data di fine",
        "ja-JP": "終了日",
        "ja": "終了日",
        "ko-KR": "종료일",
        "ko": "종료일",
        "nl-NL": "Einddatum",
        "nl": "Einddatum",
        "no-NO": "Sluttdato",
        "pl-PL": "Data końcowa",
        "pl": "Data końcowa",
        "pt-BR": "Data de término",
        "pt-PT": "Data de fim",
        "ru-RU": "Дата окончания",
        "ru": "Дата окончания",
        "sv-SE": "Slutdatum",
        "sv": "Slutdatum",
        "th-TH": "วันที่สิ้นสุด",
        "th": "วันที่สิ้นสุด",
        "tr-TR": "Bitiş Tarihi",
        "tr": "Bitiş Tarihi"
    },
    "RL_EndDateValidation1": {
        "key": "RL_EndDateValidation1",
        "en": "New End Date must be at least 2 days in the future.",
        "ca-ES": "La nova data de finalització s'ha de definir, com a mínim, dos dies després de la data actual.",
        "ca": "La nova data de finalització s'ha de definir, com a mínim, dos dies després de la data actual.",
        "cs-CZ": "Nové datum ukončení musí být alespoň po dvou dnech.",
        "cs": "Nové datum ukončení musí být alespoň po dvou dnech.",
        "da-DK": "Den nye slutdato skal være mindst to dage ude i fremtiden.",
        "da": "Den nye slutdato skal være mindst to dage ude i fremtiden.",
        "de-DE": "Das neue Enddatum muss mindestens zwei Tage in der Zukunft liegen.",
        "de": "Das neue Enddatum muss mindestens zwei Tage in der Zukunft liegen.",
        "el-GR": "Η νέα ημερομηνία λήξης πρέπει να είναι μελλοντική (τουλάχιστον δύο μέρες μετά).",
        "el": "Η νέα ημερομηνία λήξης πρέπει να είναι μελλοντική (τουλάχιστον δύο μέρες μετά).",
        "en-GB": "New end date must be at least 2 days in the future.",
        "es-ES": "La nueva fecha de finalización debe fijarse al menos dentro de 2 días.",
        "es": "La nueva fecha de finalización debe fijarse al menos dentro de 2 días.",
        "fi-FI": "Uuden päättymispäivän on oltava vähintään kaksi päivää tulevaisuudessa.",
        "fr-FR": "La nouvelle date de fin doit être postérieure d'au moins 2 jours à la date actuelle.",
        "fr": "La nouvelle date de fin doit être postérieure d'au moins 2 jours à la date actuelle.",
        "he-IL": "תאריך הסיום החדש חייב להתקיים לפחות יומיים מהיום.",
        "hr-HR": "Novi datum završetka mora biti barem dva dana u budućnosti.",
        "hu-HU": "Az új befejezési dátumnak legalább két nap múlva kell lennie.",
        "hu": "Az új befejezési dátumnak legalább két nap múlva kell lennie.",
        "it-IT": "La nuova data di fine deve essere stabilita ad almeno 2 giorni di distanza.",
        "it": "La nuova data di fine deve essere stabilita ad almeno 2 giorni di distanza.",
        "ja-JP": "新しい終了日は2日後以降にしてください。",
        "ja": "新しい終了日は2日後以降にしてください。",
        "ko-KR": "새 종료일은 현재로부터 최소 2일 후여야 합니다.",
        "ko": "새 종료일은 현재로부터 최소 2일 후여야 합니다.",
        "nl-NL": "De nieuwe einddatum moet minimaal twee dagen in de toekomst liggen.",
        "nl": "De nieuwe einddatum moet minimaal twee dagen in de toekomst liggen.",
        "no-NO": "Ny sluttdato må være minst to dager i fremtiden.",
        "pl-PL": "Nowa data końcowa musi przypadać najwcześniej za 2 dni.",
        "pl": "Nowa data końcowa musi przypadać najwcześniej za 2 dni.",
        "pt-BR": "A nova data de término deve ser pelo menos dois dias no futuro.",
        "pt-PT": "A nova Data de fim tem de se situar, no mínimo, 2 dias no futuro.",
        "ru-RU": "Новая дата завершения должна быть не позднее, чем через 2 дня.",
        "ru": "Новая дата завершения должна быть не позднее, чем через 2 дня.",
        "sv-SE": "Nytt slutdatum måste vara minst 2 dagar framåt.",
        "sv": "Nytt slutdatum måste vara minst 2 dagar framåt.",
        "th-TH": "วันสิ้นสุดใหม่ต้องเป็นอีกอย่างน้อย 2 วันข้างหน้า",
        "th": "วันสิ้นสุดใหม่ต้องเป็นอีกอย่างน้อย 2 วันข้างหน้า",
        "tr-TR": "Yeni Bitiş Tarihi, en az 2 gün sonra olmalıdır.",
        "tr": "Yeni Bitiş Tarihi, en az 2 gün sonra olmalıdır."
    },
    "RL_EndDateValidation2": {
        "key": "RL_EndDateValidation2",
        "en": "End Date must be after Start Date.",
        "ca-ES": "La data de finalització ha de ser posterior a la data d'inici.",
        "ca": "La data de finalització ha de ser posterior a la data d'inici.",
        "cs-CZ": "Datum ukončení musí být po datu zahájení.",
        "cs": "Datum ukončení musí být po datu zahájení.",
        "da-DK": "Slutdatoen skal ligge efter startdatoen.",
        "da": "Slutdatoen skal ligge efter startdatoen.",
        "de-DE": "Das Enddatum muss nach dem Startdatum liegen.",
        "de": "Das Enddatum muss nach dem Startdatum liegen.",
        "el-GR": "Η ημερομηνία λήξης πρέπει να είναι μεταγενέστερη της ημερομηνίας έναρξης.",
        "el": "Η ημερομηνία λήξης πρέπει να είναι μεταγενέστερη της ημερομηνίας έναρξης.",
        "en-GB": "End date must be after start date.",
        "es-ES": "La fecha de finalización debe ser posterior a la fecha de inicio.",
        "es": "La fecha de finalización debe ser posterior a la fecha de inicio.",
        "fi-FI": "Päättymispäivän on oltava alkamispäivän jälkeen.",
        "fr-FR": "La date de fin doit être postérieure à la date de début.",
        "fr": "La date de fin doit être postérieure à la date de début.",
        "he-IL": "תאריך הסיום חייב להתקיים אחרי תאריך ההתחלה.",
        "hr-HR": "Datum završetka mora biti nakon datuma početka.",
        "hu-HU": "A befejezési dátumnak a kezdő dátumnál későbbi időpontnak kell lennie.",
        "hu": "A befejezési dátumnak a kezdő dátumnál későbbi időpontnak kell lennie.",
        "it-IT": "La data di fine deve essere successiva alla data d'inizio.",
        "it": "La data di fine deve essere successiva alla data d'inizio.",
        "ja-JP": "終了日は開始日よりも後にする必要があります。",
        "ja": "終了日は開始日よりも後にする必要があります。",
        "ko-KR": "종료일은 시작일 이후여야 합니다.",
        "ko": "종료일은 시작일 이후여야 합니다.",
        "nl-NL": "De einddatum moet na de begindatum zijn.",
        "nl": "De einddatum moet na de begindatum zijn.",
        "no-NO": "Sluttdato må være etter startdato.",
        "pl-PL": "Data końcowa musi przypadać po dacie rozpoczęcia.",
        "pl": "Data końcowa musi przypadać po dacie rozpoczęcia.",
        "pt-BR": "A data de término deve ser posterior à data de início.",
        "pt-PT": "A Data de fim tem de ser posterior à Data de início.",
        "ru-RU": "Дата завершения должна быть позже даты начала.",
        "ru": "Дата завершения должна быть позже даты начала.",
        "sv-SE": "Slutdatumet måste vara efter startdatumet.",
        "sv": "Slutdatumet måste vara efter startdatumet.",
        "th-TH": "วันสิ้นสุดต้องมาหลังจากวันเริ่มต้น",
        "th": "วันสิ้นสุดต้องมาหลังจากวันเริ่มต้น",
        "tr-TR": "Bitiş Tarihi, Başlangıç Tarihinden sonra olmalıdır.",
        "tr": "Bitiş Tarihi, Başlangıç Tarihinden sonra olmalıdır."
    },
    "RL_Ends": {
        "key": "RL_Ends",
        "en": "Ends",
        "ca-ES": "Finalitza",
        "ca": "Finalitza",
        "cs-CZ": "Končí",
        "cs": "Končí",
        "da-DK": "Slutter",
        "da": "Slutter",
        "de-DE": "Endet",
        "de": "Endet",
        "el-GR": "Λήγει:",
        "el": "Λήγει:",
        "en-GB": "Ends",
        "es-ES": "Finaliza",
        "es": "Finaliza",
        "fi-FI": "Päättyy",
        "fr-FR": "Prend fin",
        "fr": "Prend fin",
        "he-IL": "מסתיים",
        "hr-HR": "Završava",
        "hu-HU": "Befejezés:",
        "hu": "Befejezés:",
        "it-IT": "Termine:",
        "it": "Termine:",
        "ja-JP": "終了",
        "ja": "終了",
        "ko-KR": "종료",
        "ko": "종료",
        "nl-NL": "Eindigt",
        "nl": "Eindigt",
        "no-NO": "Slutter",
        "pl-PL": "Ważna do:",
        "pl": "Ważna do:",
        "pt-BR": "Concluir",
        "pt-PT": "Termina a",
        "ru-RU": "Завершается",
        "ru": "Завершается",
        "sv-SE": "Slutar",
        "sv": "Slutar",
        "th-TH": "สิ้นสุด",
        "th": "สิ้นสุด",
        "tr-TR": "Sona Erdiği Tarih:",
        "tr": "Sona Erdiği Tarih:"
    },
    "RL_Equipment": {
        "key": "RL_Equipment",
        "en": "Equipment",
        "ca-ES": "Equipament",
        "ca": "Equipament",
        "cs-CZ": "Sportovní potřeby",
        "cs": "Sportovní potřeby",
        "da-DK": "Udstyr",
        "da": "Udstyr",
        "de-DE": "Equipment",
        "de": "Equipment",
        "el-GR": "Εξοπλισμός",
        "el": "Εξοπλισμός",
        "en-GB": "Equipment",
        "es-ES": "Equipamiento",
        "es": "Equipamiento",
        "fi-FI": "Varusteet",
        "fr-FR": "Équipement",
        "fr": "Équipement",
        "he-IL": "ציוד",
        "hr-HR": "Oprema",
        "hu-HU": "Kellékek",
        "hu": "Kellékek",
        "it-IT": "Accessori",
        "it": "Accessori",
        "ja-JP": "器具",
        "ja": "器具",
        "ko-KR": "기구",
        "ko": "기구",
        "nl-NL": "Materialen",
        "nl": "Materialen",
        "no-NO": "Utstyr",
        "pl-PL": "Sprzęt",
        "pl": "Sprzęt",
        "pt-BR": "Equipamento",
        "pt-PT": "Equipamento",
        "ru-RU": "Снаряжение",
        "ru": "Снаряжение",
        "sv-SE": "Utrustning",
        "sv": "Utrustning",
        "th-TH": "อุปกรณ์",
        "th": "อุปกรณ์",
        "tr-TR": "Ekipman",
        "tr": "Ekipman"
    },
    "RL_EquipmentDivision": {
        "key": "RL_EquipmentDivision",
        "en": "Equipment Division",
        "ca-ES": "Categoria d'equipament",
        "ca": "Categoria d'equipament",
        "cs-CZ": "Sekce Sportovní potřeby",
        "cs": "Sekce Sportovní potřeby",
        "da-DK": "Udstyrsdivision",
        "da": "Udstyrsdivision",
        "de-DE": "Equipment Division",
        "de": "Equipment Division",
        "el-GR": "Τμήμα εξοπλισμού",
        "el": "Τμήμα εξοπλισμού",
        "en-GB": "Equipment Division",
        "es-ES": "Categoría de equipamiento",
        "es": "Categoría de equipamiento",
        "fi-FI": "Varustekategoria",
        "fr-FR": "Division équipement",
        "fr": "Division équipement",
        "he-IL": "מחלקת ציוד",
        "hr-HR": "Kategorija opreme",
        "hu-HU": "Kellékek részleg",
        "hu": "Kellékek részleg",
        "it-IT": "Reparto accessori",
        "it": "Reparto accessori",
        "ja-JP": "器具部門",
        "ja": "器具部門",
        "ko-KR": "기구 부서",
        "ko": "기구 부서",
        "nl-NL": "Divisie materialen",
        "nl": "Divisie materialen",
        "no-NO": "Utstyrsavdeling",
        "pl-PL": "Dział Sprzęt",
        "pl": "Dział Sprzęt",
        "pt-BR": "Divisão de equipamentos",
        "pt-PT": "Divisão de equipamento",
        "ru-RU": "Отдел снаряжения",
        "ru": "Отдел снаряжения",
        "sv-SE": "Utrustningsavdelning",
        "sv": "Utrustningsavdelning",
        "th-TH": "หมวดอุปกรณ์",
        "th": "หมวดอุปกรณ์",
        "tr-TR": "Ekipman Departmanı",
        "tr": "Ekipman Departmanı"
    },
    "RL_Footwear": {
        "key": "RL_Footwear",
        "en": "Footwear",
        "ca-ES": "Sabatilles",
        "ca": "Sabatilles",
        "cs-CZ": "Boty",
        "cs": "Boty",
        "da-DK": "Fodtøj",
        "da": "Fodtøj",
        "de-DE": "Schuhe",
        "de": "Schuhe",
        "el-GR": "Παπούτσια",
        "el": "Παπούτσια",
        "en-GB": "Footwear",
        "es-ES": "Zapatillas",
        "es": "Zapatillas",
        "fi-FI": "Kengät",
        "fr-FR": "Chaussures",
        "fr": "Chaussures",
        "he-IL": "הנעלה",
        "hr-HR": "Obuća",
        "hu-HU": "Cipők",
        "hu": "Cipők",
        "it-IT": "Scarpe",
        "it": "Scarpe",
        "ja-JP": "フットウェア",
        "ja": "フットウェア",
        "ko-KR": "신발",
        "ko": "신발",
        "nl-NL": "Schoenen",
        "nl": "Schoenen",
        "no-NO": "Sko",
        "pl-PL": "Buty",
        "pl": "Buty",
        "pt-BR": "Calçados",
        "pt-PT": "Calçado",
        "ru-RU": "Обувь",
        "ru": "Обувь",
        "sv-SE": "Skor",
        "sv": "Skor",
        "th-TH": "รองเท้า",
        "th": "รองเท้า",
        "tr-TR": "Ayakkabı",
        "tr": "Ayakkabı"
    },
    "RL_For": {
        "key": "RL_For",
        "en": "for",
        "ca-ES": "per a",
        "ca": "per a",
        "cs-CZ": "pro",
        "cs": "pro",
        "da-DK": "til",
        "da": "til",
        "de-DE": "für",
        "de": "für",
        "el-GR": "για",
        "el": "για",
        "en-GB": "for",
        "es-ES": "para",
        "es": "para",
        "fi-FI": "ryhmälle",
        "fr-FR": "pour",
        "fr": "pour",
        "he-IL": "עבור",
        "hr-HR": "za",
        "hu-HU": "célközönség:",
        "hu": "célközönség:",
        "it-IT": "per",
        "it": "per",
        "ja-JP": "対象：",
        "ja": "対象：",
        "ko-KR": "용도",
        "ko": "용도",
        "nl-NL": "voor",
        "nl": "voor",
        "no-NO": "for",
        "pl-PL": "dla",
        "pl": "dla",
        "pt-BR": "para",
        "pt-PT": "para",
        "ru-RU": "для",
        "ru": "для",
        "sv-SE": "för",
        "sv": "för",
        "th-TH": "สำหรับ",
        "th": "สำหรับ",
        "tr-TR": "şunun için:",
        "tr": "şunun için:"
    },
    "RL_Gender": {
        "key": "RL_Gender",
        "en": "Gender",
        "ca-ES": "Sexe",
        "ca": "Sexe",
        "cs-CZ": "Pohlaví",
        "cs": "Pohlaví",
        "da-DK": "Køn",
        "da": "Køn",
        "de-DE": "Geschlecht",
        "de": "Geschlecht",
        "el-GR": "Φύλο",
        "el": "Φύλο",
        "en-GB": "Gender",
        "es-ES": "Sexo",
        "es": "Sexo",
        "fi-FI": "Sukupuoli",
        "fr-FR": "Sexe",
        "fr": "Sexe",
        "he-IL": "מגדר",
        "hr-HR": "Spol",
        "hu-HU": "Nem",
        "hu": "Nem",
        "it-IT": "Genere",
        "it": "Genere",
        "ja-JP": "性別",
        "ja": "性別",
        "ko-KR": "성별",
        "ko": "성별",
        "nl-NL": "Geslacht",
        "nl": "Geslacht",
        "no-NO": "Kjønn",
        "pl-PL": "Płeć",
        "pl": "Płeć",
        "pt-BR": "Sexo",
        "pt-PT": "Sexo",
        "ru-RU": "Пол",
        "ru": "Пол",
        "sv-SE": "Kön",
        "sv": "Kön",
        "th-TH": "เพศ",
        "th": "เพศ",
        "tr-TR": "Cinsiyet",
        "tr": "Cinsiyet"
    },
    "RL_Genders": {
        "key": "RL_Genders",
        "en": "Genders",
        "ca-ES": "Sexes",
        "ca": "Sexes",
        "cs-CZ": "Pohlaví",
        "cs": "Pohlaví",
        "da-DK": "Køn",
        "da": "Køn",
        "de-DE": "Geschlechter",
        "de": "Geschlechter",
        "el-GR": "Φύλα",
        "el": "Φύλα",
        "en-GB": "Genders",
        "es-ES": "Sexos",
        "es": "Sexos",
        "fi-FI": "Sukupuolet",
        "fr-FR": "Sexes",
        "fr": "Sexes",
        "he-IL": "מגדרים",
        "hr-HR": "Spolovi",
        "hu-HU": "Nemek",
        "hu": "Nemek",
        "it-IT": "Generi",
        "it": "Generi",
        "ja-JP": "性別",
        "ja": "性別",
        "ko-KR": "성별",
        "ko": "성별",
        "nl-NL": "Geslachten",
        "nl": "Geslachten",
        "no-NO": "Kjønn",
        "pl-PL": "Płcie",
        "pl": "Płcie",
        "pt-BR": "Gêneros",
        "pt-PT": "Sexos",
        "ru-RU": "Пол",
        "ru": "Пол",
        "sv-SE": "Kön",
        "sv": "Kön",
        "th-TH": "เพศ",
        "th": "เพศ",
        "tr-TR": "Cinsiyetler",
        "tr": "Cinsiyetler"
    },
    "RL_InTransit": {
        "key": "RL_InTransit",
        "en": "In Transit",
        "ca-ES": "En trànsit",
        "ca": "En trànsit",
        "cs-CZ": "Přepravuje se",
        "cs": "Přepravuje se",
        "da-DK": "På vej",
        "da": "På vej",
        "de-DE": "Auf dem Weg",
        "de": "Auf dem Weg",
        "el-GR": "Σε μεταφορά",
        "el": "Σε μεταφορά",
        "en-GB": "In Transit",
        "es-ES": "En camino",
        "es": "En camino",
        "fi-FI": "Kuljetuksessa",
        "fr-FR": "En transit",
        "fr": "En transit",
        "he-IL": "בדרך",
        "hr-HR": "U prijenosu",
        "hu-HU": "Szállítás folyamatban",
        "hu": "Szállítás folyamatban",
        "it-IT": "In transito",
        "it": "In transito",
        "ja-JP": "配送中",
        "ja": "配送中",
        "ko-KR": "이동 중",
        "ko": "이동 중",
        "nl-NL": "In behandeling",
        "nl": "In behandeling",
        "no-NO": "Under transport",
        "pl-PL": "W transporcie",
        "pl": "W transporcie",
        "pt-BR": "Em trânsito",
        "pt-PT": "Em trânsito",
        "ru-RU": "В пути",
        "ru": "В пути",
        "sv-SE": "På väg",
        "sv": "På väg",
        "th-TH": "อยู่ระหว่างขนส่ง",
        "th": "อยู่ระหว่างขนส่ง",
        "tr-TR": "Taşımada",
        "tr": "Taşımada"
    },
    "RL_ItemDeleteFail": {
        "key": "RL_ItemDeleteFail",
        "en": "Some items failed to delete. ** Please try again.",
        "ca-ES": "undefined",
        "ca": "undefined",
        "cs-CZ": "Některé položky se nepodařilo smazat. ** Zkuste to znova.",
        "cs": "Některé položky se nepodařilo smazat. ** Zkuste to znova.",
        "da-DK": "Nogle varer blev ikke slettet. ** Prøv igen.",
        "da": "Nogle varer blev ikke slettet. ** Prøv igen.",
        "de-DE": "Einige Artikel konnten nicht gelöscht werden. ** Bitte versuche es erneut.",
        "de": "Einige Artikel konnten nicht gelöscht werden. ** Bitte versuche es erneut.",
        "el-GR": "Η διαγραφή ορισμένων ειδών απέτυχε. **Δοκιμάστε ξανά.",
        "el": "Η διαγραφή ορισμένων ειδών απέτυχε. **Δοκιμάστε ξανά.",
        "en-GB": "Failed to delete some items. ** Please try again.",
        "es-ES": "Algunos productos no se han borrado correctamente. ** Vuelve a intentarlo.",
        "es": "Algunos productos no se han borrado correctamente. ** Vuelve a intentarlo.",
        "fi-FI": "Joitain tuotteita ei voitu poistaa. ** Yritä uudelleen.",
        "fr-FR": "Certains articles n'ont pas pu être supprimés. ** Veuillez réessayer.",
        "fr": "Certains articles n'ont pas pu être supprimés. ** Veuillez réessayer.",
        "he-IL": "נכשלה המחיקה של חלק מהפריטים. ** נסו שוב.",
        "hr-HR": "Brisanje određenih stavki nije uspjelo. ** Pokušajte ponovno.",
        "hu-HU": "Egyes cikkek törlése nem sikerült. ** Próbáld újra.",
        "hu": "Egyes cikkek törlése nem sikerült. ** Próbáld újra.",
        "it-IT": "Alcuni articoli non sono stati eliminati. ** Riprova.",
        "it": "Alcuni articoli non sono stati eliminati. ** Riprova.",
        "ja-JP": "いくつかの商品を削除できませんでした。 ** もう一度お試しください。",
        "ja": "いくつかの商品を削除できませんでした。 ** もう一度お試しください。",
        "ko-KR": "일부 항목을 삭제하는 데 실패했습니다. ** 다시 시도해 주세요.",
        "ko": "일부 항목을 삭제하는 데 실패했습니다. ** 다시 시도해 주세요.",
        "nl-NL": "Sommige artikelen konden niet worden verwijderd. ** Probeer het opnieuw.",
        "nl": "Sommige artikelen konden niet worden verwijderd. ** Probeer het opnieuw.",
        "no-NO": "Noen varer kunne ikke slettes. ** Prøv på nytt.",
        "pl-PL": "Nie udało się usunąć niektórych produktów. ** Spróbuj ponownie.",
        "pl": "Nie udało się usunąć niektórych produktów. ** Spróbuj ponownie.",
        "pt-BR": "Falha ao remover alguns itens. ** Tente novamente.",
        "pt-PT": "Falha ao eliminar alguns artigos. ** Tente novamente.",
        "ru-RU": "Не удалось удалить некоторые товары. ** Повторите попытку.",
        "ru": "Не удалось удалить некоторые товары. ** Повторите попытку.",
        "sv-SE": "Vissa produkter uppdaterades inte. ** Försök igen.",
        "sv": "Vissa produkter uppdaterades inte. ** Försök igen.",
        "th-TH": "การลบบางรายการล้มเหลว ** โปรดลองอีกครั้ง",
        "th": "การลบบางรายการล้มเหลว ** โปรดลองอีกครั้ง",
        "tr-TR": "Bazı ürünler silinemedi. ** Lütfen tekrar dene.",
        "tr": "Bazı ürünler silinemedi. ** Lütfen tekrar dene."
    },
    "RL_Kids": {
        "key": "RL_Kids",
        "en": "Kids",
        "ca-ES": "Nen/a",
        "ca": "Nen/a",
        "cs-CZ": "Děti",
        "cs": "Děti",
        "da-DK": "Børn",
        "da": "Børn",
        "de-DE": "Kinder",
        "de": "Kinder",
        "el-GR": "Παιδιά",
        "el": "Παιδιά",
        "en-GB": "Kids",
        "es-ES": "Niño/a",
        "es": "Niño/a",
        "fi-FI": "Lapset",
        "fr-FR": "Enfant",
        "fr": "Enfant",
        "he-IL": "ילדים",
        "hr-HR": "Djeca",
        "hu-HU": "Gyerek",
        "hu": "Gyerek",
        "it-IT": "Bambini",
        "it": "Bambini",
        "ja-JP": "キッズ",
        "ja": "キッズ",
        "ko-KR": "키즈",
        "ko": "키즈",
        "nl-NL": "Kids",
        "nl": "Kids",
        "no-NO": "Barn",
        "pl-PL": "Dzieci",
        "pl": "Dzieci",
        "pt-BR": "Infantil",
        "pt-PT": "Criança",
        "ru-RU": "Дети",
        "ru": "Дети",
        "sv-SE": "Barn",
        "sv": "Barn",
        "th-TH": "เด็ก",
        "th": "เด็ก",
        "tr-TR": "Çocuk",
        "tr": "Çocuk"
    },
    "RL_LogOut": {
        "key": "RL_LogOut",
        "en": "Log Out",
        "ca-ES": "Tanca la sessió",
        "ca": "Tanca la sessió",
        "cs-CZ": "Odhlásit se",
        "cs": "Odhlásit se",
        "da-DK": "Log af",
        "da": "Log af",
        "de-DE": "Abmelden",
        "de": "Abmelden",
        "el-GR": "Αποσύνδεση",
        "el": "Αποσύνδεση",
        "en-GB": "Log Out",
        "es-ES": "Cerrar sesión",
        "es": "Cerrar sesión",
        "fi-FI": "Kirjaudu ulos",
        "fr-FR": "Déconnexion",
        "fr": "Déconnexion",
        "he-IL": "יציאה",
        "hr-HR": "Odjava",
        "hu-HU": "Kijelentkezés",
        "hu": "Kijelentkezés",
        "it-IT": "Esci",
        "it": "Esci",
        "ja-JP": "ログアウト",
        "ja": "ログアウト",
        "ko-KR": "로그아웃",
        "ko": "로그아웃",
        "nl-NL": "Log uit",
        "nl": "Log uit",
        "no-NO": "Logg av",
        "pl-PL": "Wyloguj się",
        "pl": "Wyloguj się",
        "pt-BR": "Sair",
        "pt-PT": "Terminar sessão",
        "ru-RU": "Выйти",
        "ru": "Выйти",
        "sv-SE": "Logga ut",
        "sv": "Logga ut",
        "th-TH": "ล็อกเอาท์",
        "th": "ล็อกเอาท์",
        "tr-TR": "Oturumu Kapat",
        "tr": "Oturumu Kapat"
    },
    "RL_MarkdownAddSuccess": {
        "key": "RL_MarkdownAddSuccess",
        "en": "Markdown added! Please click the 'X' button to close this form.",
        "ca-ES": "undefined",
        "ca": "undefined",
        "cs-CZ": "Sleva přidána. Tenhle formulář zavřete klepnutím na symbol „X“.",
        "cs": "Sleva přidána. Tenhle formulář zavřete klepnutím na symbol „X“.",
        "da-DK": "Prisnedsættelsen blev tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "da": "Prisnedsættelsen blev tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "de-DE": "Preisabschlag wurde hinzugefügt. Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "de": "Preisabschlag wurde hinzugefügt. Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "el-GR": "Η μείωση τιμής προστέθηκε! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "el": "Η μείωση τιμής προστέθηκε! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "en-GB": "Markdown added! Please click the \"X\" button to close this form.",
        "es-ES": "Rebaja añadida. Haz clic en el botón \"X\" para cerrar este formulario.",
        "es": "Rebaja añadida. Haz clic en el botón \"X\" para cerrar este formulario.",
        "fi-FI": "Alennus lisätty! Valitse 'X', kun haluat sulkea lomakkeen.",
        "fr-FR": "Démarque ajoutée ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "fr": "Démarque ajoutée ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "he-IL": "ההנחה נוספה! לחצו על הלחצן 'X' כדי לסגור את הטופס.",
        "hr-HR": "Dodano je sniženje! Kliknite na gumb „X” za zatvaranje ovog obrasca.",
        "hu-HU": "Leértékelés hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "hu": "Leértékelés hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "it-IT": "Ribasso aggiunto! Clicca sulla \"X\" per chiudere questo modulo.",
        "it": "Ribasso aggiunto! Clicca sulla \"X\" per chiudere questo modulo.",
        "ja-JP": "値下げを追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ja": "値下げを追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ko-KR": "가격 인하가 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "ko": "가격 인하가 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "nl-NL": "Korting toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "nl": "Korting toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "no-NO": "Tilbud lagt til. Klikk på «X»-knappen for å lukke dette skjemaet.",
        "pl-PL": "Dodano obniżkę! Kliknij przycisk „X”, by zamknąć ten formularz.",
        "pl": "Dodano obniżkę! Kliknij przycisk „X”, by zamknąć ten formularz.",
        "pt-BR": "Redução de preço adicionada! Clique no botão “X” para fechar este formulário.",
        "pt-PT": "Desconto adicionado! Clique no botão \"X\" para fechar este formulário.",
        "ru-RU": "Добавлена уценка. Нажмите кнопку X, чтобы закрыть эту форму.",
        "ru": "Добавлена уценка. Нажмите кнопку X, чтобы закрыть эту форму.",
        "sv-SE": "Prissänkning har lagts till. Klicka på knappen X för att stänga formuläret.",
        "sv": "Prissänkning har lagts till. Klicka på knappen X för att stänga formuläret.",
        "th-TH": "เพิ่มรายการลดราคาแล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "th": "เพิ่มรายการลดราคาแล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "tr-TR": "İndirim eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla.",
        "tr": "İndirim eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla."
    },
    "RL_Markdowns": {
        "key": "RL_Markdowns",
        "en": "Markdowns",
        "ca-ES": "Descomptes",
        "ca": "Descomptes",
        "cs-CZ": "Slevy",
        "cs": "Slevy",
        "da-DK": "Prisnedsættelser",
        "da": "Prisnedsættelser",
        "de-DE": "Reduzierungen",
        "de": "Reduzierungen",
        "el-GR": "Μειώσεις τιμών",
        "el": "Μειώσεις τιμών",
        "en-GB": "Markdowns",
        "es-ES": "Descuentos",
        "es": "Descuentos",
        "fi-FI": "Alennukset",
        "fr-FR": "Démarques",
        "fr": "Démarques",
        "he-IL": "הנחות",
        "hr-HR": "Sniženja",
        "hu-HU": "Leértékelések",
        "hu": "Leértékelések",
        "it-IT": "Ribassi",
        "it": "Ribassi",
        "ja-JP": "値下げ",
        "ja": "値下げ",
        "ko-KR": "가격 인하",
        "ko": "가격 인하",
        "nl-NL": "Prijsverlagingen",
        "nl": "Prijsverlagingen",
        "no-NO": "Tilbud",
        "pl-PL": "Obniżki",
        "pl": "Obniżki",
        "pt-BR": "Reduções de preço",
        "pt-PT": "Preço reduzido",
        "ru-RU": "Уценка",
        "ru": "Уценка",
        "sv-SE": "Prissänkningar",
        "sv": "Prissänkningar",
        "th-TH": "ลดราคา",
        "th": "ลดราคา",
        "tr-TR": "İndirimler",
        "tr": "İndirimler"
    },
    "RL_Men": {
        "key": "RL_Men",
        "en": "Men",
        "ca-ES": "Home",
        "ca": "Home",
        "cs-CZ": "Muži",
        "cs": "Muži",
        "da-DK": "Mænd",
        "da": "Mænd",
        "de-DE": "Herren",
        "de": "Herren",
        "el-GR": "Άνδρες",
        "el": "Άνδρες",
        "en-GB": "Men",
        "es-ES": "Hombre",
        "es": "Hombre",
        "fi-FI": "Miehet",
        "fr-FR": "Homme",
        "fr": "Homme",
        "he-IL": "גברים",
        "hr-HR": "Muškarci",
        "hu-HU": "Férfi",
        "hu": "Férfi",
        "it-IT": "Uomo",
        "it": "Uomo",
        "ja-JP": "メンズ",
        "ja": "メンズ",
        "ko-KR": "남성",
        "ko": "남성",
        "nl-NL": "Heren",
        "nl": "Heren",
        "no-NO": "Herre",
        "pl-PL": "Mężczyźni",
        "pl": "Mężczyźni",
        "pt-BR": "Masculino",
        "pt-PT": "Homem",
        "ru-RU": "Мужчины",
        "ru": "Мужчины",
        "sv-SE": "Män",
        "sv": "Män",
        "th-TH": "ผู้ชาย",
        "th": "ผู้ชาย",
        "tr-TR": "Erkek",
        "tr": "Erkek"
    },
    "RL_NewPrice": {
        "key": "RL_NewPrice",
        "en": "New Price",
        "ca-ES": "Preu nou",
        "ca": "Preu nou",
        "cs-CZ": "Nová cena",
        "cs": "Nová cena",
        "da-DK": "Ny pris",
        "da": "Ny pris",
        "de-DE": "Neuer Preis",
        "de": "Neuer Preis",
        "el-GR": "Νέα τιμή",
        "el": "Νέα τιμή",
        "en-GB": "New Price",
        "es-ES": "Precio nuevo",
        "es": "Precio nuevo",
        "fi-FI": "Uusi hinta",
        "fr-FR": "Nouveau prix",
        "fr": "Nouveau prix",
        "he-IL": "מחיר חדש",
        "hr-HR": "Nova cijena",
        "hu-HU": "Új ár",
        "hu": "Új ár",
        "it-IT": "Nuovo prezzo",
        "it": "Nuovo prezzo",
        "ja-JP": "新価格",
        "ja": "新価格",
        "ko-KR": "새 가격",
        "ko": "새 가격",
        "nl-NL": "Nieuwe prijs",
        "nl": "Nieuwe prijs",
        "no-NO": "Ny pris",
        "pl-PL": "Nowa cena",
        "pl": "Nowa cena",
        "pt-BR": "Novo preço",
        "pt-PT": "Novo preço",
        "ru-RU": "Новая цена",
        "ru": "Новая цена",
        "sv-SE": "Nytt pris",
        "sv": "Nytt pris",
        "th-TH": "ราคาใหม่",
        "th": "ราคาใหม่",
        "tr-TR": "Yeni Fiyat",
        "tr": "Yeni Fiyat"
    },
    "RL_NoAssociatedProducts": {
        "key": "RL_NoAssociatedProducts",
        "en": "There are no associated products.",
        "ca-ES": "No hi ha productes associats.",
        "ca": "No hi ha productes associats.",
        "cs-CZ": "Nejsou žádné přidružené produkty.",
        "cs": "Nejsou žádné přidružené produkty.",
        "da-DK": "Der er ikke tilknyttet nogen produkter.",
        "da": "Der er ikke tilknyttet nogen produkter.",
        "de-DE": "Keine zugeordneten Produkte vorhanden.",
        "de": "Keine zugeordneten Produkte vorhanden.",
        "el-GR": "Δεν υπάρχουν συσχετισμένα προϊόντα.",
        "el": "Δεν υπάρχουν συσχετισμένα προϊόντα.",
        "en-GB": "There are no associated products.",
        "es-ES": "No hay productos asociados.",
        "es": "No hay productos asociados.",
        "fi-FI": "Tuotteita ei löydy.",
        "fr-FR": "Il n'existe aucun produit associé.",
        "fr": "Il n'existe aucun produit associé.",
        "he-IL": "אין מוצרים משויכים.",
        "hr-HR": "Nema povezanih proizvoda.",
        "hu-HU": "Nincs kapcsolódó termék.",
        "hu": "Nincs kapcsolódó termék.",
        "it-IT": "Nessun prodotto associato.",
        "it": "Nessun prodotto associato.",
        "ja-JP": "関連付けられた商品はありません。",
        "ja": "関連付けられた商品はありません。",
        "ko-KR": "연결된 상품이 없습니다.",
        "ko": "연결된 상품이 없습니다.",
        "nl-NL": "Er zijn geen gekoppelde producten.",
        "nl": "Er zijn geen gekoppelde producten.",
        "no-NO": "Det er ingen tilknyttede produkter.",
        "pl-PL": "Brak powiązanych produktów",
        "pl": "Brak powiązanych produktów",
        "pt-BR": "Não há produtos associados.",
        "pt-PT": "Não existem produtos associados.",
        "ru-RU": "Нет связанных товаров.",
        "ru": "Нет связанных товаров.",
        "sv-SE": "Det finns inga associerade produkter.",
        "sv": "Det finns inga associerade produkter.",
        "th-TH": "ไม่มีสินค้าที่เกี่ยวข้อง",
        "th": "ไม่มีสินค้าที่เกี่ยวข้อง",
        "tr-TR": "İlgili bir ürün bulunmuyor.",
        "tr": "İlgili bir ürün bulunmuyor."
    },
    "RL_Off": {
        "key": "RL_Off",
        "en": "off",
        "ca-ES": "de descompte",
        "ca": "de descompte",
        "cs-CZ": "vyp.",
        "cs": "vyp.",
        "da-DK": "rabat",
        "da": "rabat",
        "de-DE": "Rabatt",
        "de": "Rabatt",
        "el-GR": "έκπτωση",
        "el": "έκπτωση",
        "en-GB": "off",
        "es-ES": "de descuento",
        "es": "de descuento",
        "fi-FI": "alennusta",
        "fr-FR": "de réduction",
        "fr": "de réduction",
        "he-IL": "הנחה",
        "hr-HR": "popust",
        "hu-HU": "kedvezmény",
        "hu": "kedvezmény",
        "it-IT": "di sconto",
        "it": "di sconto",
        "ja-JP": "オフ",
        "ja": "オフ",
        "ko-KR": "할인",
        "ko": "할인",
        "nl-NL": "korting",
        "nl": "korting",
        "no-NO": "rabatt",
        "pl-PL": "wył.",
        "pl": "wył.",
        "pt-BR": "desligado",
        "pt-PT": "de desconto",
        "ru-RU": "скидка",
        "ru": "скидка",
        "sv-SE": "rabatt",
        "sv": "rabatt",
        "th-TH": "ส่วนลด",
        "th": "ส่วนลด",
        "tr-TR": "indirim",
        "tr": "indirim"
    },
    "RL_OffCode": {
        "key": "RL_OffCode",
        "en": "{{1}}% off for {{2}} code",
        "ca-ES": "{{1}} % de descompte en {{2}} codi",
        "ca": "{{1}} % de descompte en {{2}} codi",
        "cs-CZ": "{{1}}% sleva za {{2}} kód",
        "cs": "{{1}}% sleva za {{2}} kód",
        "da-DK": "{{1}}% rabat for {{2}} kode",
        "da": "{{1}}% rabat for {{2}} kode",
        "de-DE": "{{1}} % Rabatt für {{2}} Codes",
        "de": "{{1}} % Rabatt für {{2}} Codes",
        "el-GR": "-{{1}}% για {{2}} κωδικό",
        "el": "-{{1}}% για {{2}} κωδικό",
        "en-GB": "{{1}}% off for {{2}} code",
        "es-ES": "{{1}}% de descuento para {{2}} código",
        "es": "{{1}}% de descuento para {{2}} código",
        "fi-FI": "{{1}} % alennusta {{2}} koodilla",
        "fr-FR": "{{1}} % de réduction pour {{2}} code",
        "fr": "{{1}} % de réduction pour {{2}} code",
        "he-IL": "{{1}}% הנחה עבור קוד אחד ({{2}})",
        "hr-HR": "{{1}}% popusta za {{2}} šifru",
        "hu-HU": "{{1}}% kedvezmény {{2}} kódhoz",
        "hu": "{{1}}% kedvezmény {{2}} kódhoz",
        "it-IT": "{{1}}% di sconto per {{2}} codice",
        "it": "{{1}}% di sconto per {{2}} codice",
        "ja-JP": "{{1}}％オフのコード{{2}}件",
        "ja": "{{1}}％オフのコード{{2}}件",
        "ko-KR": "{{2}}개 코드에 대해 {{1}}% 할인",
        "ko": "{{2}}개 코드에 대해 {{1}}% 할인",
        "nl-NL": "{{1}}% korting voor {{2}} code",
        "nl": "{{1}}% korting voor {{2}} code",
        "no-NO": "{{1}} % rabatt for {{2}} kode",
        "pl-PL": "{{1}}% zniżki za kod: {{2}}",
        "pl": "{{1}}% zniżki za kod: {{2}}",
        "pt-BR": "{{1}}% de desc. para {{2}} código",
        "pt-PT": "{{1}}% de desconto para {{2}} código",
        "ru-RU": "Скидка {{1}}% на код {{2}}",
        "ru": "Скидка {{1}}% на код {{2}}",
        "sv-SE": "{{1}}% rabatt med {{2}} kod",
        "sv": "{{1}}% rabatt med {{2}} kod",
        "th-TH": "ลด {{1}}% สำหรับ {{2}} รหัส",
        "th": "ลด {{1}}% สำหรับ {{2}} รหัส",
        "tr-TR": "{{2}} kod için %{{1}} indirim",
        "tr": "{{2}} kod için %{{1}} indirim"
    },
    "RL_OffCodes": {
        "key": "RL_OffCodes",
        "en": "{{1}}% off for {{2}} codes",
        "ca-ES": "{{1}} % de descompte en {{2}} codis",
        "ca": "{{1}} % de descompte en {{2}} codis",
        "cs-CZ": "{{1}}% sleva za {{2}} kódy",
        "cs": "{{1}}% sleva za {{2}} kódy",
        "da-DK": "{{1}}% rabat for {{2}} koder",
        "da": "{{1}}% rabat for {{2}} koder",
        "de-DE": "{{1}} % Rabatt für {{2}} Codes",
        "de": "{{1}} % Rabatt für {{2}} Codes",
        "el-GR": "-{{1}}% για {{2}} κωδικούς",
        "el": "-{{1}}% για {{2}} κωδικούς",
        "en-GB": "{{1}}% off for {{2}} codes",
        "es-ES": "{{1}}% de descuento para {{2}} códigos",
        "es": "{{1}}% de descuento para {{2}} códigos",
        "fi-FI": "{{1}} % alennusta {{2}} koodilla",
        "fr-FR": "{{1}} % de réduction pour {{2}} codes",
        "fr": "{{1}} % de réduction pour {{2}} codes",
        "he-IL": "{{1}}% הנחה עבור {{2}} קודים",
        "hr-HR": "{{1}}% popusta za šifre (njih {{2}})",
        "hu-HU": "{{1}}% kedvezmény {{2}} kódhoz",
        "hu": "{{1}}% kedvezmény {{2}} kódhoz",
        "it-IT": "{{1}}% di sconto per {{2}} codici",
        "it": "{{1}}% di sconto per {{2}} codici",
        "ja-JP": "{{1}}％オフのコード{{2}}件",
        "ja": "{{1}}％オフのコード{{2}}件",
        "ko-KR": "{{2}}개 코드에 대해 {{1}}% 할인",
        "ko": "{{2}}개 코드에 대해 {{1}}% 할인",
        "nl-NL": "{{1}}% korting voor {{2}} codes",
        "nl": "{{1}}% korting voor {{2}} codes",
        "no-NO": "{{1}} % rabatt for {{2}} koder",
        "pl-PL": "{{1}}% zniżki za kody: {{2}}",
        "pl": "{{1}}% zniżki za kody: {{2}}",
        "pt-BR": "{{1}}% de desc. para {{2}} códigos",
        "pt-PT": "{{1}}% de desconto para {{2}} códigos",
        "ru-RU": "Скидка {{1}}% на коды {{2}}",
        "ru": "Скидка {{1}}% на коды {{2}}",
        "sv-SE": "{{1}}% rabatt med {{2}} koder",
        "sv": "{{1}}% rabatt med {{2}} koder",
        "th-TH": "ลด {{1}}% สำหรับ {{2}} รหัส",
        "th": "ลด {{1}}% สำหรับ {{2}} รหัส",
        "tr-TR": "{{2}} kod için %{{1}} indirim",
        "tr": "{{2}} kod için %{{1}} indirim"
    },
    "RL_PausedPromotion": {
        "key": "RL_PausedPromotion",
        "en": "Paused Promotion",
        "ca-ES": "Promoció pausada",
        "ca": "Promoció pausada",
        "cs-CZ": "Promoakce pozastavená",
        "cs": "Promoakce pozastavená",
        "da-DK": "Kampagnen er sat på pause",
        "da": "Kampagnen er sat på pause",
        "de-DE": "Angebot ausgesetzt",
        "de": "Angebot ausgesetzt",
        "el-GR": "Διακοπή προσφοράς",
        "el": "Διακοπή προσφοράς",
        "en-GB": "Paused Promotion",
        "es-ES": "Promoción pausada",
        "es": "Promoción pausada",
        "fi-FI": "Keskeytetty tarjouskampanja",
        "fr-FR": "Promotion en pause",
        "fr": "Promotion en pause",
        "he-IL": "המבצע הושהה",
        "hr-HR": "Pauzirana promocija",
        "hu-HU": "Szüneteltetett promóció",
        "hu": "Szüneteltetett promóció",
        "it-IT": "Promozione sospesa",
        "it": "Promozione sospesa",
        "ja-JP": "一時停止したプロモーション",
        "ja": "一時停止したプロモーション",
        "ko-KR": "일시 중지된 프로모션",
        "ko": "일시 중지된 프로모션",
        "nl-NL": "Promotie gepauzeerd",
        "nl": "Promotie gepauzeerd",
        "no-NO": "Kampanje satt på pause",
        "pl-PL": "Wstrzymana promocja",
        "pl": "Wstrzymana promocja",
        "pt-BR": "Promoção em pausa",
        "pt-PT": "Promoção em pausa",
        "ru-RU": "Приостановленная акция",
        "ru": "Приостановленная акция",
        "sv-SE": "Kampanj har pausats",
        "sv": "Kampanj har pausats",
        "th-TH": "โปรโมชันที่พักไว้",
        "th": "โปรโมชันที่พักไว้",
        "tr-TR": "Promosyon Duraklatıldı",
        "tr": "Promosyon Duraklatıldı"
    },
    "RL_PendingSync": {
        "key": "RL_PendingSync",
        "en": "Pending Sync",
        "ca-ES": "Sincronització pendent",
        "ca": "Sincronització pendent",
        "cs-CZ": "Čeká se na synchronizaci",
        "cs": "Čeká se na synchronizaci",
        "da-DK": "Afventer synkronisering",
        "da": "Afventer synkronisering",
        "de-DE": "Synchronisierung steht aus",
        "de": "Synchronisierung steht aus",
        "el-GR": "Συγχρονισμός σε εκκρεμότητα",
        "el": "Συγχρονισμός σε εκκρεμότητα",
        "en-GB": "Pending Sync",
        "es-ES": "Sincronización pendiente",
        "es": "Sincronización pendiente",
        "fi-FI": "Odottaa synkronointia",
        "fr-FR": "Synchronisation en cours",
        "fr": "Synchronisation en cours",
        "he-IL": "בהמתנה לסנכרון",
        "hr-HR": "Sinkronizacija na čekanju",
        "hu-HU": "Szinkronizálás függőben",
        "hu": "Szinkronizálás függőben",
        "it-IT": "Sincronizzazione in sospeso",
        "it": "Sincronizzazione in sospeso",
        "ja-JP": "同期の保留中",
        "ja": "同期の保留中",
        "ko-KR": "동기화 대기 중",
        "ko": "동기화 대기 중",
        "nl-NL": "In afwachting van synchronisatie",
        "nl": "In afwachting van synchronisatie",
        "no-NO": "Synkronisering venter",
        "pl-PL": "Synchronizacja w toku",
        "pl": "Synchronizacja w toku",
        "pt-BR": "Sincronização pendente",
        "pt-PT": "Sincronização pendente",
        "ru-RU": "Ожидание синхронизации",
        "ru": "Ожидание синхронизации",
        "sv-SE": "Väntar på synk",
        "sv": "Väntar på synk",
        "th-TH": "การซิงค์ที่รอดำเนินการ",
        "th": "การซิงค์ที่รอดำเนินการ",
        "tr-TR": "Senkronizasyon Beklemede",
        "tr": "Senkronizasyon Beklemede"
    },
    "RL_PriceValidation": {
        "key": "RL_PriceValidation",
        "en": "Price must be entered in the form XX.XX'",
        "ca-ES": "El preu s'ha d'introduir en el format XX.XX'",
        "ca": "El preu s'ha d'introduir en el format XX.XX'",
        "cs-CZ": "Cenu musíte zadat ve formátu XX.XX'",
        "cs": "Cenu musíte zadat ve formátu XX.XX'",
        "da-DK": "Prisen skal indtastes i formen XX,XX",
        "da": "Prisen skal indtastes i formen XX,XX",
        "de-DE": "Der Preis muss folgendermaßen eingegeben werden: XX,XX",
        "de": "Der Preis muss folgendermaßen eingegeben werden: XX,XX",
        "el-GR": "Η τιμή πρέπει να έχει την εξής μορφή: XX.XX'",
        "el": "Η τιμή πρέπει να έχει την εξής μορφή: XX.XX'",
        "en-GB": "Price must be entered in the form \"XX.XX\"",
        "es-ES": "El precio debe introducirse en el formato XX,XX",
        "es": "El precio debe introducirse en el formato XX,XX",
        "fi-FI": "Hinta on asetettava seuraavassa muodossa: XX,XX",
        "fr-FR": "Le prix doit être saisi au format « XX,XX »",
        "fr": "Le prix doit être saisi au format « XX,XX »",
        "he-IL": "יש להזין את המחיר בתבנית XX.XX",
        "hr-HR": "Cijena se unosi u obliku XX.XX'",
        "hu-HU": "Az árat XX.XX formátumban kell megadni.",
        "hu": "Az árat XX.XX formátumban kell megadni.",
        "it-IT": "Il prezzo deve essere inserito nel formato XX,XX",
        "it": "Il prezzo deve essere inserito nel formato XX,XX",
        "ja-JP": "価格はXX.XX'の形式で入力してください",
        "ja": "価格はXX.XX'の形式で入力してください",
        "ko-KR": "가격은 반드시 XX.XX 형태로 입력해야 합니다.",
        "ko": "가격은 반드시 XX.XX 형태로 입력해야 합니다.",
        "nl-NL": "De prijs moet de indeling XX.XX hebben",
        "nl": "De prijs moet de indeling XX.XX hebben",
        "no-NO": "Pris må legges inn i formen XX.XX'",
        "pl-PL": "Cenę należy wprowadzić w formacie XX.XX",
        "pl": "Cenę należy wprowadzić w formacie XX.XX",
        "pt-BR": "O preço deve ser inserido no formato XX.XX'",
        "pt-PT": "O preço dever ser introduzido no formato XX,XX'",
        "ru-RU": "Цена должна быть введена в формате XX,XX'",
        "ru": "Цена должна быть введена в формате XX,XX'",
        "sv-SE": "Pris måste anges i formatet XX.XX",
        "sv": "Pris måste anges i formatet XX.XX",
        "th-TH": "ต้องป้อนราคาในแบบฟอร์ม XX.XX'",
        "th": "ต้องป้อนราคาในแบบฟอร์ม XX.XX'",
        "tr-TR": "Fiyat XX,XX şeklinde girilmelidir",
        "tr": "Fiyat XX,XX şeklinde girilmelidir"
    },
    "RL_ProductDeleteConfirm1": {
        "key": "RL_ProductDeleteConfirm1",
        "en": "Do you want to remove all products with less than or equal to {{1}} units from all promotions in the store?",
        "ca-ES": "Vols eliminar tots els productes amb {{1}} o menys unitats de totes les promocions de la botiga?",
        "ca": "Vols eliminar tots els productes amb {{1}} o menys unitats de totes les promocions de la botiga?",
        "cs-CZ": "Chcete odstranit všechny produkty v počtu {{1}} jednotek nebo menším ze všech promoakcí na prodejně?",
        "cs": "Chcete odstranit všechny produkty v počtu {{1}} jednotek nebo menším ze všech promoakcí na prodejně?",
        "da-DK": "Vil du fjerne alle produkter med mindre end eller præcis {{1}} enheder fra alle kampagner i butikken?",
        "da": "Vil du fjerne alle produkter med mindre end eller præcis {{1}} enheder fra alle kampagner i butikken?",
        "de-DE": "Möchtest du alle Produkte, deren Bestand {{1}} Einheiten nicht übersteigt, aus allen Angeboten des Stores entfernen?",
        "de": "Möchtest du alle Produkte, deren Bestand {{1}} Einheiten nicht übersteigt, aus allen Angeboten des Stores entfernen?",
        "el-GR": "Θέλετε να καταργήσετε όλα τα προϊόντα για τα οποία υπάρχουν έως {1}} μονάδες από όλες τις προσφορές στο κατάστημα;",
        "el": "Θέλετε να καταργήσετε όλα τα προϊόντα για τα οποία υπάρχουν έως {1}} μονάδες από όλες τις προσφορές στο κατάστημα;",
        "en-GB": "Do you want to remove all products with less than or equal to {{1}} units from all promotions in the store?",
        "es-ES": "¿Quieres eliminar todos los productos con {{1}} unidades o menos de todas las promociones de la tienda?",
        "es": "¿Quieres eliminar todos los productos con {{1}} unidades o menos de todas las promociones de la tienda?",
        "fi-FI": "Haluatko poistaa kaikista tarjouskampanjoista kaikki tuotteet, joita on {{1}} tai vähemmän?",
        "fr-FR": "Souhaitez-vous supprimer tous les produits comptant {{1}} unités ou moins de toutes les promotions du magasin ?",
        "fr": "Souhaitez-vous supprimer tous les produits comptant {{1}} unités ou moins de toutes les promotions du magasin ?",
        "he-IL": "האם ברצונכם להסיר את כל המוצרים שיש מהם {{1}} יחידות או פחות מכל המבצעים בחנות?",
        "hr-HR": "Želite li ukloniti sve proizvode s {{1}} ili manje jedinica sa svih promocija na prodajnom mjestu?",
        "hu-HU": "Kizárod a(z) {{1}} vagy kevesebb egységből álló termékeket az üzlet összes promóciójából?",
        "hu": "Kizárod a(z) {{1}} vagy kevesebb egységből álló termékeket az üzlet összes promóciójából?",
        "it-IT": "Vuoi rimuovere tutti i prodotti con un massimo di {{1}} unità da tutte le promozioni dello store?",
        "it": "Vuoi rimuovere tutti i prodotti con un massimo di {{1}} unità da tutte le promozioni dello store?",
        "ja-JP": "{{1}}個以下の全商品を店舗内のすべてのプロモーションから削除しますか？",
        "ja": "{{1}}個以下の全商品を店舗内のすべてのプロモーションから削除しますか？",
        "ko-KR": "매장의 모든 프로모션에서 {{1}}개 유닛 이하의 제품을 전부 제거하시겠어요?",
        "ko": "매장의 모든 프로모션에서 {{1}}개 유닛 이하의 제품을 전부 제거하시겠어요?",
        "nl-NL": "Wil je alle producten met {{1}} of minder eenheden verwijderen uit alle promoties in de store?",
        "nl": "Wil je alle producten met {{1}} of minder eenheden verwijderen uit alle promoties in de store?",
        "no-NO": "Vil du fjerne alle produkter med mindre enn eller lik {{1}} enheter fra alle kampanjer i butikken?",
        "pl-PL": "Czy chcesz usunąć wszystkie produkty, które są dostępne w liczbie {{1}} sztuk lub mniej, ze wszystkich promocji w sklepie?",
        "pl": "Czy chcesz usunąć wszystkie produkty, które są dostępne w liczbie {{1}} sztuk lub mniej, ze wszystkich promocji w sklepie?",
        "pt-BR": "Deseja remover todos os produtos com {{1}} unidades ou menos de todas as promoções da loja?",
        "pt-PT": "Pretende remover todos os produtos com {{1}} ou menos unidades de todas as promoções na loja?",
        "ru-RU": "Вы хотите удалить все товары с {{1}} или менее единицами из всех акций в магазине?",
        "ru": "Вы хотите удалить все товары с {{1}} или менее единицами из всех акций в магазине?",
        "sv-SE": "Vill du ta bort alla produkter med {{1}} eller färre enheter från alla kampanjer i butiken?",
        "sv": "Vill du ta bort alla produkter med {{1}} eller färre enheter från alla kampanjer i butiken?",
        "th-TH": "คุณต้องการนำสินค้าทั้งหมดที่มีน้อยกว่าหรือเท่ากับ {{1}} ชิ้นออกจากทุกโปรโมชันใน Store หรือไม่",
        "th": "คุณต้องการนำสินค้าทั้งหมดที่มีน้อยกว่าหรือเท่ากับ {{1}} ชิ้นออกจากทุกโปรโมชันใน Store หรือไม่",
        "tr-TR": "{{1}} birim veya daha az olan tüm ürünleri mağazadaki tüm promosyonlardan kaldırmak istiyor musun?",
        "tr": "{{1}} birim veya daha az olan tüm ürünleri mağazadaki tüm promosyonlardan kaldırmak istiyor musun?"
    },
    "RL_ProductDeleteConfirm2": {
        "key": "RL_ProductDeleteConfirm2",
        "en": "Do you want to remove all products with greater than or equal to {{1}} units from all promotions in the store?",
        "ca-ES": "Vols eliminar tots els productes amb {{1}} o més unitats de totes les promocions de la botiga?",
        "ca": "Vols eliminar tots els productes amb {{1}} o més unitats de totes les promocions de la botiga?",
        "cs-CZ": "Chcete odstranit všechny produkty v počtu {{1}} jednotek nebo vyšším ze všech promoakcí na prodejně?",
        "cs": "Chcete odstranit všechny produkty v počtu {{1}} jednotek nebo vyšším ze všech promoakcí na prodejně?",
        "da-DK": "Vil du fjerne alle produkter med mere end eller præcis {{1}} enheder fra alle kampagner i butikken?",
        "da": "Vil du fjerne alle produkter med mere end eller præcis {{1}} enheder fra alle kampagner i butikken?",
        "de-DE": "Möchtest du alle Produkte, deren Bestand {{1}} Einheiten oder mehr beträgt, aus allen Angeboten des Stores entfernen?",
        "de": "Möchtest du alle Produkte, deren Bestand {{1}} Einheiten oder mehr beträgt, aus allen Angeboten des Stores entfernen?",
        "el-GR": "Θέλετε να καταργήσετε όλα τα προϊόντα για τα οποία υπάρχουν {{1}} μονάδες και άνω από όλες τις προσφορές στο κατάστημα;",
        "el": "Θέλετε να καταργήσετε όλα τα προϊόντα για τα οποία υπάρχουν {{1}} μονάδες και άνω από όλες τις προσφορές στο κατάστημα;",
        "en-GB": "Do you want to remove all products with greater than or equal to {{1}} units from all promotions in the store?",
        "es-ES": "¿Quieres eliminar todos los productos con {{1}} unidades o más de todas las promociones de la tienda?",
        "es": "¿Quieres eliminar todos los productos con {{1}} unidades o más de todas las promociones de la tienda?",
        "fi-FI": "Haluatko poistaa kaikista tarjouskampanjoista kaikki tuotteet, joita on {{1}} tai enemmän?",
        "fr-FR": "Souhaitez-vous supprimer tous les produits comptant {{1}} unités ou plus de toutes les promotions du magasin ?",
        "fr": "Souhaitez-vous supprimer tous les produits comptant {{1}} unités ou plus de toutes les promotions du magasin ?",
        "he-IL": "האם ברצונכם להסיר את כל המוצרים שיש מהם {{1}} יחידות או יותר מכל המבצעים בחנות?",
        "hr-HR": "Želite li ukloniti sve proizvode s {{1}} ili više jedinica sa svih promocija na prodajnom mjestu?",
        "hu-HU": "Kizárod a(z) {{1}} vagy több egységből álló termékeket az üzlet összes promóciójából?",
        "hu": "Kizárod a(z) {{1}} vagy több egységből álló termékeket az üzlet összes promóciójából?",
        "it-IT": "Vuoi rimuovere tutti i prodotti con un minimo di {{1}} unità da tutte le promozioni dello store?",
        "it": "Vuoi rimuovere tutti i prodotti con un minimo di {{1}} unità da tutte le promozioni dello store?",
        "ja-JP": "{{1}}個以上の全商品を店舗内のすべてのプロモーションから削除しますか？",
        "ja": "{{1}}個以上の全商品を店舗内のすべてのプロモーションから削除しますか？",
        "ko-KR": "매장의 모든 프로모션에서 {{1}}개 유닛 이상의 제품을 전부 제거하시겠어요?",
        "ko": "매장의 모든 프로모션에서 {{1}}개 유닛 이상의 제품을 전부 제거하시겠어요?",
        "nl-NL": "Wil je alle producten met {{1}} of meer eenheden verwijderen uit alle promoties in de store?",
        "nl": "Wil je alle producten met {{1}} of meer eenheden verwijderen uit alle promoties in de store?",
        "no-NO": "Vil du fjerne alle produkter med flere enn eller lik {{1}} enheter fra alle kampanjer i butikken?",
        "pl-PL": "Czy chcesz usunąć wszystkie produkty, które są dostępne w liczbie {{1}} sztuk lub więcej, ze wszystkich promocji w sklepie?",
        "pl": "Czy chcesz usunąć wszystkie produkty, które są dostępne w liczbie {{1}} sztuk lub więcej, ze wszystkich promocji w sklepie?",
        "pt-BR": "Deseja remover todos os produtos com {{1}} unidades ou mais de todas as promoções da loja?",
        "pt-PT": "Pretende remover todos os produtos com {{1}} ou mais unidades de todas as promoções na loja?",
        "ru-RU": "Вы хотите удалить все товары с {{1}} или более единицами из всех акций в магазине?",
        "ru": "Вы хотите удалить все товары с {{1}} или более единицами из всех акций в магазине?",
        "sv-SE": "Vill du ta bort alla produkter med {{1}} eller fler enheter från alla kampanjer i butiken?",
        "sv": "Vill du ta bort alla produkter med {{1}} eller fler enheter från alla kampanjer i butiken?",
        "th-TH": "คุณต้องการนำสินค้าทั้งหมดที่มีมากกว่าหรือเท่ากับ {{1}} ชิ้นออกจากทุกโปรโมชันใน Store หรือไม่",
        "th": "คุณต้องการนำสินค้าทั้งหมดที่มีมากกว่าหรือเท่ากับ {{1}} ชิ้นออกจากทุกโปรโมชันใน Store หรือไม่",
        "tr-TR": "{{1}} birim veya daha fazla olan tüm ürünleri mağazadaki tüm promosyonlardan kaldırmak istiyor musun?",
        "tr": "{{1}} birim veya daha fazla olan tüm ürünleri mağazadaki tüm promosyonlardan kaldırmak istiyor musun?"
    },
    "RL_ProductDeleteConfirm3": {
        "key": "RL_ProductDeleteConfirm3",
        "en": "Do you want to remove this product from all promotions in the store?",
        "ca-ES": "Vols eliminar aquest producte de totes les promocions de la botiga?",
        "ca": "Vols eliminar aquest producte de totes les promocions de la botiga?",
        "cs-CZ": "Chcete odstranit tenhle produkt ze všech promoakcí na prodejně?",
        "cs": "Chcete odstranit tenhle produkt ze všech promoakcí na prodejně?",
        "da-DK": "Vil du fjerne dette produkt fra alle kampagner i butikken?",
        "da": "Vil du fjerne dette produkt fra alle kampagner i butikken?",
        "de-DE": "Möchtest du dieses Produkt aus allen Angeboten im Store entfernen?",
        "de": "Möchtest du dieses Produkt aus allen Angeboten im Store entfernen?",
        "el-GR": "Θέλετε να καταργήσετε αυτό το προϊόν από όλες τις προσφορές στο κατάστημα;",
        "el": "Θέλετε να καταργήσετε αυτό το προϊόν από όλες τις προσφορές στο κατάστημα;",
        "en-GB": "Do you want to remove this product from all promotions in the store?",
        "es-ES": "¿Quieres eliminar este producto de todas las promociones de la tienda?",
        "es": "¿Quieres eliminar este producto de todas las promociones de la tienda?",
        "fi-FI": "Haluatko poistaa tämän tuotteen kaikista tarjouskampanjoista?",
        "fr-FR": "Souhaitez-vous supprimer ce produit de toutes les promotions du magasin ?",
        "fr": "Souhaitez-vous supprimer ce produit de toutes les promotions du magasin ?",
        "he-IL": "האם ברצונכם למחוק את המוצר הזה מכל המבצעים בחנות?",
        "hr-HR": "Želite li ukloniti ovaj proizvod sa svih promocija na prodajnom mjestu?",
        "hu-HU": "Kizárod ezt a terméket az üzlet összes promóciójából?",
        "hu": "Kizárod ezt a terméket az üzlet összes promóciójából?",
        "it-IT": "Vuoi rimuovere questo prodotto da tutte le promozioni dello store?",
        "it": "Vuoi rimuovere questo prodotto da tutte le promozioni dello store?",
        "ja-JP": "この商品を店舗内のすべてのプロモーションから削除しますか？",
        "ja": "この商品を店舗内のすべてのプロモーションから削除しますか？",
        "ko-KR": "매장의 모든 프로모션에서 이 제품을 제거하시겠어요?",
        "ko": "매장의 모든 프로모션에서 이 제품을 제거하시겠어요?",
        "nl-NL": "Wil je dit product verwijderen van alle promoties in de store?",
        "nl": "Wil je dit product verwijderen van alle promoties in de store?",
        "no-NO": "Vil du fjerne dette produktet fra alle kampanjer i butikken?",
        "pl-PL": "Czy chcesz usunąć ten produkt ze wszystkich promocji w sklepie?",
        "pl": "Czy chcesz usunąć ten produkt ze wszystkich promocji w sklepie?",
        "pt-BR": "Deseja remover este produto de todas as promoções da loja?",
        "pt-PT": "Pretende remover este produto de todas as promoções na loja?",
        "ru-RU": "Удалить этот товар из всех акций в магазине?",
        "ru": "Удалить этот товар из всех акций в магазине?",
        "sv-SE": "Vill du ta bort den här produkten från alla kampanjer i butiken?",
        "sv": "Vill du ta bort den här produkten från alla kampanjer i butiken?",
        "th-TH": "คุณต้องการนำสินค้านี้ออกจากทุกโปรโมชันใน Store หรือไม่",
        "th": "คุณต้องการนำสินค้านี้ออกจากทุกโปรโมชันใน Store หรือไม่",
        "tr-TR": "Bu ürünü mağazadaki tüm promosyonlardan kaldırmak istiyor musun?",
        "tr": "Bu ürünü mağazadaki tüm promosyonlardan kaldırmak istiyor musun?"
    },
    "RL_ProductDivision": {
        "key": "RL_ProductDivision",
        "en": "Product Division",
        "ca-ES": "Categoria del producte",
        "ca": "Categoria del producte",
        "cs-CZ": "Produktová sekce",
        "cs": "Produktová sekce",
        "da-DK": "Produktdivision",
        "da": "Produktdivision",
        "de-DE": "Product Division",
        "de": "Product Division",
        "el-GR": "Τμήμα προϊόντων",
        "el": "Τμήμα προϊόντων",
        "en-GB": "Product Division",
        "es-ES": "Categoría del producto",
        "es": "Categoría del producto",
        "fi-FI": "Tuotekategoria",
        "fr-FR": "Division produit",
        "fr": "Division produit",
        "he-IL": "מחלקת מוצרים",
        "hr-HR": "Kategorija proizvoda",
        "hu-HU": "Termékrészleg",
        "hu": "Termékrészleg",
        "it-IT": "Reparto prodotto",
        "it": "Reparto prodotto",
        "ja-JP": "商品の部門",
        "ja": "商品の部門",
        "ko-KR": "제품 부서",
        "ko": "제품 부서",
        "nl-NL": "Productdivisie",
        "nl": "Productdivisie",
        "no-NO": "Produktavdeling",
        "pl-PL": "Dział Produkty",
        "pl": "Dział Produkty",
        "pt-BR": "Divisão do produto",
        "pt-PT": "Divisão do produto",
        "ru-RU": "Отдел товара",
        "ru": "Отдел товара",
        "sv-SE": "Produktavdelning",
        "sv": "Produktavdelning",
        "th-TH": "หมวดสินค้า",
        "th": "หมวดสินค้า",
        "tr-TR": "Ürün Bölümü",
        "tr": "Ürün Bölümü"
    },
    "RL_ProductNameOrStyleColor": {
        "key": "RL_ProductNameOrStyleColor",
        "en": "Product Name or Style Color",
        "ca-ES": "Nom del producte o color del model",
        "ca": "Nom del producte o color del model",
        "cs-CZ": "Název produktu nebo barva modelu",
        "cs": "Název produktu nebo barva modelu",
        "da-DK": "Produktnavn eller modelfarve",
        "da": "Produktnavn eller modelfarve",
        "de-DE": "Produktname oder Style-Farbe",
        "de": "Produktname oder Style-Farbe",
        "el-GR": "Όνομα προϊόντος ή χρώμα στιλ",
        "el": "Όνομα προϊόντος ή χρώμα στιλ",
        "en-GB": "Product Name or Style Colour",
        "es-ES": "Nombre de producto o color del modelo",
        "es": "Nombre de producto o color del modelo",
        "fi-FI": "Tuotenimi tai väri",
        "fr-FR": "Nom du produit ou couleur de modèle",
        "fr": "Nom du produit ou couleur de modèle",
        "he-IL": "שם המוצר או סגנון-צבע",
        "hr-HR": "Naziv proizvoda ili model/boja",
        "hu-HU": "Termék neve vagy Style Color",
        "hu": "Termék neve vagy Style Color",
        "it-IT": "Nome del prodotto o colore del modello",
        "it": "Nome del prodotto o colore del modello",
        "ja-JP": "商品名またはスタイル カラー",
        "ja": "商品名またはスタイル カラー",
        "ko-KR": "제품 이름 또는 스타일 컬러",
        "ko": "제품 이름 또는 스타일 컬러",
        "nl-NL": "Productnaam of stijlkleur",
        "nl": "Productnaam of stijlkleur",
        "no-NO": "Produktnavn eller produktfarge",
        "pl-PL": "Nazwa produktu lub model-kolor",
        "pl": "Nazwa produktu lub model-kolor",
        "pt-BR": "Nome do produto ou Cor do estilo",
        "pt-PT": "Nome do produto ou estilo-cor",
        "ru-RU": "Название товара или модель/расцветка",
        "ru": "Название товара или модель/расцветка",
        "sv-SE": "Produktnamn eller modellfärg",
        "sv": "Produktnamn eller modellfärg",
        "th-TH": "ชื่อสินค้าหรือสีสไตล์",
        "th": "ชื่อสินค้าหรือสีสไตล์",
        "tr-TR": "Ürün Adı veya Stil Rengi",
        "tr": "Ürün Adı veya Stil Rengi"
    },
    "RL_ProductType": {
        "key": "RL_ProductType",
        "en": "Product Type",
        "ca-ES": "Tipus de producte",
        "ca": "Tipus de producte",
        "cs-CZ": "Typ produktu",
        "cs": "Typ produktu",
        "da-DK": "Produkttype",
        "da": "Produkttype",
        "de-DE": "Produkttyp",
        "de": "Produkttyp",
        "el-GR": "Τύπος προϊόντος",
        "el": "Τύπος προϊόντος",
        "en-GB": "Product Type",
        "es-ES": "Tipo de producto",
        "es": "Tipo de producto",
        "fi-FI": "Tuotetyyppi",
        "fr-FR": "Type de produit",
        "fr": "Type de produit",
        "he-IL": "סוג מוצר",
        "hr-HR": "Vrsta proizvoda",
        "hu-HU": "Terméktípus",
        "hu": "Terméktípus",
        "it-IT": "Tipo di prodotto",
        "it": "Tipo di prodotto",
        "ja-JP": "商品の種類",
        "ja": "商品の種類",
        "ko-KR": "제품 유형",
        "ko": "제품 유형",
        "nl-NL": "Producttype",
        "nl": "Producttype",
        "no-NO": "Produkttype",
        "pl-PL": "Rodzaj produktu",
        "pl": "Rodzaj produktu",
        "pt-BR": "Tipo de produto",
        "pt-PT": "Tipo de produto",
        "ru-RU": "Тип товара",
        "ru": "Тип товара",
        "sv-SE": "Produkttyp",
        "sv": "Produkttyp",
        "th-TH": "ประเภทสินค้า",
        "th": "ประเภทสินค้า",
        "tr-TR": "Ürün Türü",
        "tr": "Ürün Türü"
    },
    "RL_Products": {
        "key": "RL_Products",
        "en": "Products",
        "ca-ES": "Productes",
        "ca": "Productes",
        "cs-CZ": "Produkty",
        "cs": "Produkty",
        "da-DK": "Produkter",
        "da": "Produkter",
        "de-DE": "Produkte",
        "de": "Produkte",
        "el-GR": "Προϊόντα",
        "el": "Προϊόντα",
        "en-GB": "Products",
        "es-ES": "Productos",
        "es": "Productos",
        "fi-FI": "Tuotteet",
        "fr-FR": "Produits",
        "fr": "Produits",
        "he-IL": "מוצרים",
        "hr-HR": "Proizvodi",
        "hu-HU": "Termékek",
        "hu": "Termékek",
        "it-IT": "Prodotti",
        "it": "Prodotti",
        "ja-JP": "商品",
        "ja": "商品",
        "ko-KR": "제품",
        "ko": "제품",
        "nl-NL": "Producten",
        "nl": "Producten",
        "no-NO": "Produkter",
        "pl-PL": "Produkty",
        "pl": "Produkty",
        "pt-BR": "Produtos",
        "pt-PT": "Produtos",
        "ru-RU": "Товары",
        "ru": "Товары",
        "sv-SE": "Produkter",
        "sv": "Produkter",
        "th-TH": "สินค้า",
        "th": "สินค้า",
        "tr-TR": "Ürünler",
        "tr": "Ürünler"
    },
    "RL_ProductsAddSuccess": {
        "key": "RL_ProductsAddSuccess",
        "en": "All products added! Please click the 'X' button to close this form.",
        "ca-ES": "undefined",
        "ca": "undefined",
        "cs-CZ": "Všechny produkty přidány! Tenhle formulář zavřete klepnutím na symbol „X“.",
        "cs": "Všechny produkty přidány! Tenhle formulář zavřete klepnutím na symbol „X“.",
        "da-DK": "Alle produkter er tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "da": "Alle produkter er tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "de-DE": "Alle Produkte wurden hinzugefügt. Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "de": "Alle Produkte wurden hinzugefügt. Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "el-GR": "Προστέθηκαν όλα τα προϊόντα! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "el": "Προστέθηκαν όλα τα προϊόντα! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "en-GB": "All products added! Please click the \"X\" button to close this form.",
        "es-ES": "Todos los productos se han añadido Haz clic en el botón \"X\" para cerrar este formulario.",
        "es": "Todos los productos se han añadido Haz clic en el botón \"X\" para cerrar este formulario.",
        "fi-FI": "Kaikki tuotteet lisätty! Valitse 'X', kun haluat sulkea lomakkeen.",
        "fr-FR": "Tous les produits ont été ajoutés ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "fr": "Tous les produits ont été ajoutés ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "he-IL": "כל המוצרים נוספו! לחצו על הלחצן 'X' כדי לסגור את הטופס.",
        "hr-HR": "Dodani su svi proizvodi! Kliknite na gumb „X” za zatvaranje ovog obrasca.",
        "hu-HU": "Összes termék hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "hu": "Összes termék hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "it-IT": "Tutti i prodotti sono stati aggiunti! Clicca sulla \"X\" per chiudere questo modulo.",
        "it": "Tutti i prodotti sono stati aggiunti! Clicca sulla \"X\" per chiudere questo modulo.",
        "ja-JP": "すべての商品を追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ja": "すべての商品を追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ko-KR": "모든 제품이 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "ko": "모든 제품이 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "nl-NL": "Alle producten zijn toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "nl": "Alle producten zijn toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "no-NO": "Alle produkter er lagt til. Klikk på «X»-knappen for å lukke dette skjemaet.",
        "pl-PL": "Dodano wszystkie produkty! Kliknij przycisk „X”, by zamknąć ten formularz.",
        "pl": "Dodano wszystkie produkty! Kliknij przycisk „X”, by zamknąć ten formularz.",
        "pt-BR": "Todos os produtos foram adicionados! Clique no botão “X” para fechar este formulário.",
        "pt-PT": "Todos os produtos foram adicionados! Clique no botão \"X\" para fechar este formulário.",
        "ru-RU": "Все товары добавлены! Нажмите кнопку X, чтобы закрыть эту форму.",
        "ru": "Все товары добавлены! Нажмите кнопку X, чтобы закрыть эту форму.",
        "sv-SE": "Alla produkter har lagts till. Klicka på knappen X för att stänga formuläret.",
        "sv": "Alla produkter har lagts till. Klicka på knappen X för att stänga formuläret.",
        "th-TH": "เพิ่มสินค้าทั้งหมดแล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "th": "เพิ่มสินค้าทั้งหมดแล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "tr-TR": "Tüm ürünler eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla.",
        "tr": "Tüm ürünler eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla."
    },
    "RL_Profile": {
        "key": "RL_Profile",
        "en": "Profile",
        "ca-ES": "Perfil",
        "ca": "Perfil",
        "cs-CZ": "Profil",
        "cs": "Profil",
        "da-DK": "Profil",
        "da": "Profil",
        "de-DE": "Profil",
        "de": "Profil",
        "el-GR": "Προφίλ",
        "el": "Προφίλ",
        "en-GB": "Profile",
        "es-ES": "Perfil",
        "es": "Perfil",
        "fi-FI": "Profiili",
        "fr-FR": "Profil",
        "fr": "Profil",
        "he-IL": "פרופיל",
        "hr-HR": "Profil",
        "hu-HU": "Profil",
        "hu": "Profil",
        "it-IT": "Profilo",
        "it": "Profilo",
        "ja-JP": "プロフィール",
        "ja": "プロフィール",
        "ko-KR": "프로필",
        "ko": "프로필",
        "nl-NL": "Profiel",
        "nl": "Profiel",
        "no-NO": "Profil",
        "pl-PL": "Profil",
        "pl": "Profil",
        "pt-BR": "Perfil",
        "pt-PT": "Perfil",
        "ru-RU": "Профиль",
        "ru": "Профиль",
        "sv-SE": "Profil",
        "sv": "Profil",
        "th-TH": "โปรไฟล์",
        "th": "โปรไฟล์",
        "tr-TR": "Profil",
        "tr": "Profil"
    },
    "RL_PromotionDeleteSuccess1": {
        "key": "RL_PromotionDeleteSuccess1",
        "en": "Successfully deleted the item from all promotions",
        "ca-ES": "S'ha eliminat el producte correctament de totes les promocions.",
        "ca": "S'ha eliminat el producte correctament de totes les promocions.",
        "cs-CZ": "Položka byla úspěšně smazaná ze všech promoakcí",
        "cs": "Položka byla úspěšně smazaná ze všech promoakcí",
        "da-DK": "Varen er fjernet fra alle kampagner",
        "da": "Varen er fjernet fra alle kampagner",
        "de-DE": "Der Artikel wurde erfolgreich aus allen Angeboten gelöscht.",
        "de": "Der Artikel wurde erfolgreich aus allen Angeboten gelöscht.",
        "el-GR": "Το είδος διαγράφηκε με επιτυχία από όλες τις προσφορές",
        "el": "Το είδος διαγράφηκε με επιτυχία από όλες τις προσφορές",
        "en-GB": "Item successfully deleted from all promotions",
        "es-ES": "El producto se ha borrado correctamente de todas las promociones",
        "es": "El producto se ha borrado correctamente de todas las promociones",
        "fi-FI": "Tuote on poistettu kaikista tarjouskampanjoista",
        "fr-FR": "Article supprimé de toutes les promotions",
        "fr": "Article supprimé de toutes les promotions",
        "he-IL": "הפריט נמחק בהצלחה מכל המבצעים",
        "hr-HR": "Stavka je uspješno izbrisana iz svih promocija",
        "hu-HU": "A cikket sikeresen töröltük az összes promócióból",
        "hu": "A cikket sikeresen töröltük az összes promócióból",
        "it-IT": "L'articolo è stato eliminato correttamente da tutte le promozioni",
        "it": "L'articolo è stato eliminato correttamente da tutte le promozioni",
        "ja-JP": "商品をすべてのプロモーションから削除しました",
        "ja": "商品をすべてのプロモーションから削除しました",
        "ko-KR": "모든 프로모션에서 해당 제품을 삭제했습니다.",
        "ko": "모든 프로모션에서 해당 제품을 삭제했습니다.",
        "nl-NL": "Het item is verwijderd uit alle promoties",
        "nl": "Het item is verwijderd uit alle promoties",
        "no-NO": "Varen er slettet fra alle kampanjer",
        "pl-PL": "Produkt został usunięty ze wszystkich promocji",
        "pl": "Produkt został usunięty ze wszystkich promocji",
        "pt-BR": "O item foi removido de todas as promoções",
        "pt-PT": "O artigo foi eliminado com sucesso de todas as promoções",
        "ru-RU": "Товар успешно удален из всех акций",
        "ru": "Товар успешно удален из всех акций",
        "sv-SE": "Produkten har tagits bort från alla kampanjer",
        "sv": "Produkten har tagits bort från alla kampanjer",
        "th-TH": "ลบรายการออกจากทุกโปรโมชันเรียบร้อยแล้ว",
        "th": "ลบรายการออกจากทุกโปรโมชันเรียบร้อยแล้ว",
        "tr-TR": "Ürün tüm promosyonlardan başarıyla silindi",
        "tr": "Ürün tüm promosyonlardan başarıyla silindi"
    },
    "RL_PromotionDeleteSuccess2": {
        "key": "RL_PromotionDeleteSuccess2",
        "en": "Successfully deleted all selected items from all promotions.",
        "ca-ES": "S'han eliminat els productes correctament de totes les promocions.",
        "ca": "S'han eliminat els productes correctament de totes les promocions.",
        "cs-CZ": "Všechny vybrané položky byly úspěšně smazané ze všech promoakcí",
        "cs": "Všechny vybrané položky byly úspěšně smazané ze všech promoakcí",
        "da-DK": "Alle valgte varer er fjernet fra alle kampagner.",
        "da": "Alle valgte varer er fjernet fra alle kampagner.",
        "de-DE": "Die ausgewählten Artikel wurden erfolgreich aus allen Angeboten gelöscht.",
        "de": "Die ausgewählten Artikel wurden erfolgreich aus allen Angeboten gelöscht.",
        "el-GR": "Όλα τα επιλεγμένα είδη διαγράφηκαν με επιτυχία από όλες τις προσφορές.",
        "el": "Όλα τα επιλεγμένα είδη διαγράφηκαν με επιτυχία από όλες τις προσφορές.",
        "en-GB": "All selected items successfully deleted from all promotions.",
        "es-ES": "Todos los productos seleccionados se han borrado correctamente de todas las promociones.",
        "es": "Todos los productos seleccionados se han borrado correctamente de todas las promociones.",
        "fi-FI": "Kaikki valitut tuotteet on poistettu kaikista tarjouskampanjoista.",
        "fr-FR": "Tous les articles sélectionnés ont été supprimés de toutes les promotions.",
        "fr": "Tous les articles sélectionnés ont été supprimés de toutes les promotions.",
        "he-IL": "כל הפריטים שנבחרו נמחקו בהצלחה מכל המבצעים.",
        "hr-HR": "Sve odabrane stavke uspješno su izbrisane iz svih promocija.",
        "hu-HU": "Az összes kiválasztott cikket sikeresen töröltük az összes promócióból",
        "hu": "Az összes kiválasztott cikket sikeresen töröltük az összes promócióból",
        "it-IT": "Tutti gli articoli selezionati sono stati eliminati correttamente da tutte le promozioni.",
        "it": "Tutti gli articoli selezionati sono stati eliminati correttamente da tutte le promozioni.",
        "ja-JP": "選択した全商品をすべてのプロモーションから削除しました。",
        "ja": "選択した全商品をすべてのプロモーションから削除しました。",
        "ko-KR": "모든 프로모션에서 선택한 제품을 전부 삭제했습니다.",
        "ko": "모든 프로모션에서 선택한 제품을 전부 삭제했습니다.",
        "nl-NL": "Alle geselecteerde items zijn verwijderd uit alle promoties.",
        "nl": "Alle geselecteerde items zijn verwijderd uit alle promoties.",
        "no-NO": "Alle valgte varer er slettet fra alle kampanjer",
        "pl-PL": "Wszystkie wybrane produkty zostały usunięte ze wszystkich promocji",
        "pl": "Wszystkie wybrane produkty zostały usunięte ze wszystkich promocji",
        "pt-BR": "Todos os itens selecionados foram removidos de todas as promoções.",
        "pt-PT": "Os artigos selecionados foram eliminados com sucesso de todas as promoções.",
        "ru-RU": "Все выбранные товары успешно удалены из всех акций.",
        "ru": "Все выбранные товары успешно удалены из всех акций.",
        "sv-SE": "De valda produkterna har tagits bort från alla kampanjer.",
        "sv": "De valda produkterna har tagits bort från alla kampanjer.",
        "th-TH": "ลบรายการทั้งหมดที่เลือกไว้ออกจากทุกโปรโมชันเรียบร้อยแล้ว",
        "th": "ลบรายการทั้งหมดที่เลือกไว้ออกจากทุกโปรโมชันเรียบร้อยแล้ว",
        "tr-TR": "Seçilen tüm ürünler tüm promosyonlardan başarıyla silindi.",
        "tr": "Seçilen tüm ürünler tüm promosyonlardan başarıyla silindi."
    },
    "RL_PromotionID": {
        "key": "RL_PromotionID",
        "en": "Promotion ID",
        "ca-ES": "ID de la promoció",
        "ca": "ID de la promoció",
        "cs-CZ": "ID promoakce",
        "cs": "ID promoakce",
        "da-DK": "Kampagne-ID",
        "da": "Kampagne-ID",
        "de-DE": "Angebots-ID",
        "de": "Angebots-ID",
        "el-GR": "Αναγνωριστικό προσφοράς",
        "el": "Αναγνωριστικό προσφοράς",
        "en-GB": "Promotion ID",
        "es-ES": "ID de la promoción",
        "es": "ID de la promoción",
        "fi-FI": "Tarjouskampanjan tunnus",
        "fr-FR": "ID de la promotion",
        "fr": "ID de la promotion",
        "he-IL": "מזהה מבצע",
        "hr-HR": "ID promocije",
        "hu-HU": "Promóciós azonosító",
        "hu": "Promóciós azonosító",
        "it-IT": "ID Promozione",
        "it": "ID Promozione",
        "ja-JP": "プロモーションID",
        "ja": "プロモーションID",
        "ko-KR": "프로모션 ID",
        "ko": "프로모션 ID",
        "nl-NL": "Promotie-id",
        "nl": "Promotie-id",
        "no-NO": "Kampanje-ID",
        "pl-PL": "Identyfikator promocji",
        "pl": "Identyfikator promocji",
        "pt-BR": "ID da promoção",
        "pt-PT": "Identificação da promoção",
        "ru-RU": "ID акции",
        "ru": "ID акции",
        "sv-SE": "Kampanj-id",
        "sv": "Kampanj-id",
        "th-TH": "ID โปรโมชัน",
        "th": "ID โปรโมชัน",
        "tr-TR": "Promosyon kimliği",
        "tr": "Promosyon kimliği"
    },
    "RL_PromotionName": {
        "key": "RL_PromotionName",
        "en": "Promotion Name",
        "ca-ES": "Nom de la promoció",
        "ca": "Nom de la promoció",
        "cs-CZ": "Název promoakce",
        "cs": "Název promoakce",
        "da-DK": "Kampagnenavn",
        "da": "Kampagnenavn",
        "de-DE": "Angebotsname",
        "de": "Angebotsname",
        "el-GR": "Όνομα προσφοράς",
        "el": "Όνομα προσφοράς",
        "en-GB": "Promotion Name",
        "es-ES": "Nombre de promoción",
        "es": "Nombre de promoción",
        "fi-FI": "Kampanjan nimi",
        "fr-FR": "Nom de la promotion",
        "fr": "Nom de la promotion",
        "he-IL": "שם מבצע",
        "hr-HR": "Naziv promocije",
        "hu-HU": "Promóciós név",
        "hu": "Promóciós név",
        "it-IT": "Nome promozione",
        "it": "Nome promozione",
        "ja-JP": "プロモーション名",
        "ja": "プロモーション名",
        "ko-KR": "프로모션 이름",
        "ko": "프로모션 이름",
        "nl-NL": "Naam promotie",
        "nl": "Naam promotie",
        "no-NO": "Kampanjenavn",
        "pl-PL": "Nazwa promocji",
        "pl": "Nazwa promocji",
        "pt-BR": "Nome da promoção",
        "pt-PT": "Nome da promoção",
        "ru-RU": "Название акции",
        "ru": "Название акции",
        "sv-SE": "Kampanjnamn",
        "sv": "Kampanjnamn",
        "th-TH": "ชื่อโปรโมชัน",
        "th": "ชื่อโปรโมชัน",
        "tr-TR": "Promosyon Adı",
        "tr": "Promosyon Adı"
    },
    "RL_PromotionNameValidation1": {
        "key": "RL_PromotionNameValidation1",
        "en": "Promotion Name must be alphanumeric characters only.",
        "ca-ES": "El nom de la promoció només pot tenir caràcters alfanumèrics.",
        "ca": "El nom de la promoció només pot tenir caràcters alfanumèrics.",
        "cs-CZ": "Název promoakce můžou tvořit jenom alfanumerické znaky.",
        "cs": "Název promoakce můžou tvořit jenom alfanumerické znaky.",
        "da-DK": "Kampagnenavnet må kun bestå af alfanumeriske tegn.",
        "da": "Kampagnenavnet må kun bestå af alfanumeriske tegn.",
        "de-DE": "Der Angebotsname darf nur aus alphanumerischen Zeichen bestehen.",
        "de": "Der Angebotsname darf nur aus alphanumerischen Zeichen bestehen.",
        "el-GR": "Το όνομα της προσφοράς πρέπει να αποτελείται μόνο από αλφαριθμητικούς χαρακτήρες.",
        "el": "Το όνομα της προσφοράς πρέπει να αποτελείται μόνο από αλφαριθμητικούς χαρακτήρες.",
        "en-GB": "Promotion name must be alphanumeric characters only.",
        "es-ES": "El nombre de la promoción debe contener solo caracteres alfanuméricos.",
        "es": "El nombre de la promoción debe contener solo caracteres alfanuméricos.",
        "fi-FI": "Tarjouskampanjan nimessä voi olla ainostaan kirjaimia ja numeroita.",
        "fr-FR": "Le nom de la promotion doit être composé uniquement de caractères alphanumériques.",
        "fr": "Le nom de la promotion doit être composé uniquement de caractères alphanumériques.",
        "he-IL": "שם המבצע חייב להכיל תווים אלפאנומריים בלבד.",
        "hr-HR": "Naziv promocije smije imati samo alfanumeričke znakove.",
        "hu-HU": "A promóciós névben csakis alfanumerikus karakterek szerepelhetnek.",
        "hu": "A promóciós névben csakis alfanumerikus karakterek szerepelhetnek.",
        "it-IT": "Il nome del prodotto deve contenere solo caratteri alfanumerici.",
        "it": "Il nome del prodotto deve contenere solo caratteri alfanumerici.",
        "ja-JP": "プロモーション名に使用できるのは英数字のみです。",
        "ja": "プロモーション名に使用できるのは英数字のみです。",
        "ko-KR": "프로모션 이름은 반드시 알파벳과 숫자로만 이루어져야 합니다.",
        "ko": "프로모션 이름은 반드시 알파벳과 숫자로만 이루어져야 합니다.",
        "nl-NL": "De promotienaam mag alleen bestaan uit alfanumerieke tekens.",
        "nl": "De promotienaam mag alleen bestaan uit alfanumerieke tekens.",
        "no-NO": "Kampanjenavn kan bare bestå av alfanumeriske tegn.",
        "pl-PL": "Nazwa promocji może zawierać wyłącznie znaki alfanumeryczne.",
        "pl": "Nazwa promocji może zawierać wyłącznie znaki alfanumeryczne.",
        "pt-BR": "O nome da promoção deve conter apenas caracteres alfanuméricos.",
        "pt-PT": "O Nome da promoção dever conter apenas carateres alfanuméricos.",
        "ru-RU": "Название акции должно содержать только буквы и цифры.",
        "ru": "Название акции должно содержать только буквы и цифры.",
        "sv-SE": "Kampanjnamnet får endast innehålla alfanumeriska tecken.",
        "sv": "Kampanjnamnet får endast innehålla alfanumeriska tecken.",
        "th-TH": "ชื่อโปรโมชันต้องประกอบด้วยตัวเลขและตัวอักษรเท่านั้น",
        "th": "ชื่อโปรโมชันต้องประกอบด้วยตัวเลขและตัวอักษรเท่านั้น",
        "tr-TR": "Promosyon Adı yalnızca alfabetik karakterler içermelidir.",
        "tr": "Promosyon Adı yalnızca alfabetik karakterler içermelidir."
    },
    "RL_PromotionNameValidation2": {
        "key": "RL_PromotionNameValidation2",
        "en": "Promotion Name must be alphanumeric characters only.",
        "ca-ES": "El nom de la promoció només pot tenir caràcters alfanumèrics.",
        "ca": "El nom de la promoció només pot tenir caràcters alfanumèrics.",
        "cs-CZ": "Název promoakce můžou tvořit jenom alfanumerické znaky.",
        "cs": "Název promoakce můžou tvořit jenom alfanumerické znaky.",
        "da-DK": "Kampagnenavnet må kun bestå af alfanumeriske tegn.",
        "da": "Kampagnenavnet må kun bestå af alfanumeriske tegn.",
        "de-DE": "Der Angebotsname darf nur aus alphanumerischen Zeichen bestehen.",
        "de": "Der Angebotsname darf nur aus alphanumerischen Zeichen bestehen.",
        "el-GR": "Το όνομα της προσφοράς πρέπει να αποτελείται μόνο από αλφαριθμητικούς χαρακτήρες.",
        "el": "Το όνομα της προσφοράς πρέπει να αποτελείται μόνο από αλφαριθμητικούς χαρακτήρες.",
        "en-GB": "Promotion name must be alphanumeric characters only.",
        "es-ES": "El nombre de la promoción debe contener solo caracteres alfanuméricos.",
        "es": "El nombre de la promoción debe contener solo caracteres alfanuméricos.",
        "fi-FI": "Tarjouskampanjan nimessä voi olla ainostaan kirjaimia ja numeroita.",
        "fr-FR": "Le nom de la promotion doit être composé uniquement de caractères alphanumériques.",
        "fr": "Le nom de la promotion doit être composé uniquement de caractères alphanumériques.",
        "he-IL": "שם המבצע חייב להכיל תווים אלפאנומריים בלבד.",
        "hr-HR": "Naziv promocije smije imati samo alfanumeričke znakove.",
        "hu-HU": "A promóciós névben csakis alfanumerikus karakterek szerepelhetnek.",
        "hu": "A promóciós névben csakis alfanumerikus karakterek szerepelhetnek.",
        "it-IT": "Il nome del prodotto deve contenere solo caratteri alfanumerici.",
        "it": "Il nome del prodotto deve contenere solo caratteri alfanumerici.",
        "ja-JP": "プロモーション名に使用できるのは英数字のみです。",
        "ja": "プロモーション名に使用できるのは英数字のみです。",
        "ko-KR": "프로모션 이름은 반드시 알파벳과 숫자로만 이루어져야 합니다.",
        "ko": "프로모션 이름은 반드시 알파벳과 숫자로만 이루어져야 합니다.",
        "nl-NL": "De promotienaam mag alleen bestaan uit alfanumerieke tekens.",
        "nl": "De promotienaam mag alleen bestaan uit alfanumerieke tekens.",
        "no-NO": "Kampanjenavn kan bare bestå av alfanumeriske tegn.",
        "pl-PL": "Nazwa promocji może zawierać wyłącznie znaki alfanumeryczne.",
        "pl": "Nazwa promocji może zawierać wyłącznie znaki alfanumeryczne.",
        "pt-BR": "O nome da promoção deve conter apenas caracteres alfanuméricos.",
        "pt-PT": "O Nome da promoção dever conter apenas carateres alfanuméricos.",
        "ru-RU": "Название акции должно содержать только буквы и цифры.",
        "ru": "Название акции должно содержать только буквы и цифры.",
        "sv-SE": "Kampanjnamnet får endast innehålla alfanumeriska tecken.",
        "sv": "Kampanjnamnet får endast innehålla alfanumeriska tecken.",
        "th-TH": "ชื่อโปรโมชันต้องประกอบด้วยตัวเลขและตัวอักษรเท่านั้น",
        "th": "ชื่อโปรโมชันต้องประกอบด้วยตัวเลขและตัวอักษรเท่านั้น",
        "tr-TR": "Promosyon Adı yalnızca alfabetik karakterler içermelidir.",
        "tr": "Promosyon Adı yalnızca alfabetik karakterler içermelidir."
    },
    "RL_PromotionUpdateSuccess": {
        "key": "RL_PromotionUpdateSuccess",
        "en": "Promotion updated successfully!",
        "ca-ES": "La promoció s'ha actualitzat correctament.",
        "ca": "La promoció s'ha actualitzat correctament.",
        "cs-CZ": "Promoakce byla úspěšně upravená.",
        "cs": "Promoakce byla úspěšně upravená.",
        "da-DK": "Kampagne blev opdateret.",
        "da": "Kampagne blev opdateret.",
        "de-DE": "Das Angebot wurde erfolgreich aktualisiert.",
        "de": "Das Angebot wurde erfolgreich aktualisiert.",
        "el-GR": "Η προσφορά ενημερώθηκε με επιτυχία!",
        "el": "Η προσφορά ενημερώθηκε με επιτυχία!",
        "en-GB": "Promotion updated successfully!",
        "es-ES": "Promoción actualizada correctamente.",
        "es": "Promoción actualizada correctamente.",
        "fi-FI": "Tarjouskampanja on päivitetty!",
        "fr-FR": "Promotion mise à jour avec succès !",
        "fr": "Promotion mise à jour avec succès !",
        "he-IL": "המבצע עודכן בהצלחה!",
        "hr-HR": "Uspješno je ažurirana promocija!",
        "hu-HU": "A promóciót sikeresen frissítettük!",
        "hu": "A promóciót sikeresen frissítettük!",
        "it-IT": "Promozione aggiornata correttamente!",
        "it": "Promozione aggiornata correttamente!",
        "ja-JP": "プロモーションが更新されました。",
        "ja": "プロモーションが更新されました。",
        "ko-KR": "프로모션이 업데이트되었습니다!",
        "ko": "프로모션이 업데이트되었습니다!",
        "nl-NL": "De promotie is bijgewerkt.",
        "nl": "De promotie is bijgewerkt.",
        "no-NO": "Kampanjen er oppdatert.",
        "pl-PL": "Promocja zaktualizowana!",
        "pl": "Promocja zaktualizowana!",
        "pt-BR": "Promoção atualizada!",
        "pt-PT": "Promoção atualizada com sucesso!",
        "ru-RU": "Акция успешно обновлена!",
        "ru": "Акция успешно обновлена!",
        "sv-SE": "Kampanjen har uppdaterats.",
        "sv": "Kampanjen har uppdaterats.",
        "th-TH": "อัพเดทโปรโมชันเรียบร้อยแล้ว",
        "th": "อัพเดทโปรโมชันเรียบร้อยแล้ว",
        "tr-TR": "Promosyon başarıyla güncellendi!",
        "tr": "Promosyon başarıyla güncellendi!"
    },
    "RL_PromotionalProductsWith": {
        "key": "RL_PromotionalProductsWith",
        "en": "Promotional Products with",
        "ca-ES": "Productes promocionals amb",
        "ca": "Productes promocionals amb",
        "cs-CZ": "Propagační produkty s",
        "cs": "Propagační produkty s",
        "da-DK": "Kampagneprodukter med",
        "da": "Kampagneprodukter med",
        "de-DE": "Werbeprodukte mit",
        "de": "Werbeprodukte mit",
        "el-GR": "Προωθητικά προϊόντα με",
        "el": "Προωθητικά προϊόντα με",
        "en-GB": "Promotional Products with",
        "es-ES": "Productos promocionales con",
        "es": "Productos promocionales con",
        "fi-FI": "Kampanjatuotteet",
        "fr-FR": "Produit promotionnels avec",
        "fr": "Produit promotionnels avec",
        "he-IL": "מוצרים במבצע עם",
        "hr-HR": "Promotivni proizvodi s",
        "hu-HU": "Promóciós termékek:",
        "hu": "Promóciós termékek:",
        "it-IT": "Prodotti promozionali con",
        "it": "Prodotti promozionali con",
        "ja-JP": "次の店内用製品：",
        "ja": "次の店内用製品：",
        "ko-KR": "판촉용 제품 +",
        "ko": "판촉용 제품 +",
        "nl-NL": "Promotieproducten met",
        "nl": "Promotieproducten met",
        "no-NO": "Kampanjeprodukter med",
        "pl-PL": "Produkty promocyjne z",
        "pl": "Produkty promocyjne z",
        "pt-BR": "Produtos promocionais com",
        "pt-PT": "Produtos promocionais com",
        "ru-RU": "Рекламные товары с",
        "ru": "Рекламные товары с",
        "sv-SE": "Kampanjprodukter med",
        "sv": "Kampanjprodukter med",
        "th-TH": "สินค้าที่มีการส่งเสริมการขายร่วมกับ",
        "th": "สินค้าที่มีการส่งเสริมการขายร่วมกับ",
        "tr-TR": "Promosyon Ürünleri:",
        "tr": "Promosyon Ürünleri:"
    },
    "RL_Promotions": {
        "key": "RL_Promotions",
        "en": "Promotions",
        "ca-ES": "Promocions",
        "ca": "Promocions",
        "cs-CZ": "Promoakce",
        "cs": "Promoakce",
        "da-DK": "Kampagner",
        "da": "Kampagner",
        "de-DE": "Angebote",
        "de": "Angebote",
        "el-GR": "Προσφορές",
        "el": "Προσφορές",
        "en-GB": "Promotions",
        "es-ES": "Promociones",
        "es": "Promociones",
        "fi-FI": "Kampanjat",
        "fr-FR": "Promotions",
        "fr": "Promotions",
        "he-IL": "מבצעים",
        "hr-HR": "Promocije",
        "hu-HU": "Promóciók",
        "hu": "Promóciók",
        "it-IT": "Promozioni",
        "it": "Promozioni",
        "ja-JP": "プロモーション",
        "ja": "プロモーション",
        "ko-KR": "프로모션",
        "ko": "프로모션",
        "nl-NL": "Promoties",
        "nl": "Promoties",
        "no-NO": "Kampanjer",
        "pl-PL": "Promocje",
        "pl": "Promocje",
        "pt-BR": "Promoções",
        "pt-PT": "Promoções",
        "ru-RU": "Промоакции",
        "ru": "Промоакции",
        "sv-SE": "Kampanjer",
        "sv": "Kampanjer",
        "th-TH": "โปรโมชัน",
        "th": "โปรโมชัน",
        "tr-TR": "Promosyonlar",
        "tr": "Promosyonlar"
    },
    "RL_Quantity": {
        "key": "RL_Quantity",
        "en": "Quantity",
        "ca-ES": "Quantitat",
        "ca": "Quantitat",
        "cs-CZ": "Počet",
        "cs": "Počet",
        "da-DK": "Antal",
        "da": "Antal",
        "de-DE": "Menge",
        "de": "Menge",
        "el-GR": "Ποσότητα",
        "el": "Ποσότητα",
        "en-GB": "Quantity",
        "es-ES": "Cantidad",
        "es": "Cantidad",
        "fi-FI": "Määrä",
        "fr-FR": "Quantité",
        "fr": "Quantité",
        "he-IL": "כמות",
        "hr-HR": "Količina",
        "hu-HU": "Mennyiség",
        "hu": "Mennyiség",
        "it-IT": "Quantità",
        "it": "Quantità",
        "ja-JP": "数量",
        "ja": "数量",
        "ko-KR": "수량",
        "ko": "수량",
        "nl-NL": "Aantal",
        "nl": "Aantal",
        "no-NO": "Antall",
        "pl-PL": "Ilość",
        "pl": "Ilość",
        "pt-BR": "Quantidade",
        "pt-PT": "Quantidade",
        "ru-RU": "Количество",
        "ru": "Количество",
        "sv-SE": "Antal",
        "sv": "Antal",
        "th-TH": "จำนวน",
        "th": "จำนวน",
        "tr-TR": "Adet",
        "tr": "Adet"
    },
    "RL_RemoveConfirmation": {
        "key": "RL_RemoveConfirmation",
        "en": "Do you want to remove this product?",
        "ca-ES": "Vols eliminar aquest producte?",
        "ca": "Vols eliminar aquest producte?",
        "cs-CZ": "Chcete tenhle produkt odebrat?",
        "cs": "Chcete tenhle produkt odebrat?",
        "da-DK": "Vil du fjerne dette produkt?",
        "da": "Vil du fjerne dette produkt?",
        "de-DE": "Möchtest du dieses Produkt entfernen?",
        "de": "Möchtest du dieses Produkt entfernen?",
        "el-GR": "Θέλετε να καταργήσετε αυτό το προϊόν;",
        "el": "Θέλετε να καταργήσετε αυτό το προϊόν;",
        "en-GB": "Do you want to remove this product?",
        "es-ES": "¿Quieres eliminar este producto?",
        "es": "¿Quieres eliminar este producto?",
        "fi-FI": "Haluatko poistaa tämän tuotteen?",
        "fr-FR": "Souhaitez-vous supprimer ce produit ?",
        "fr": "Souhaitez-vous supprimer ce produit ?",
        "he-IL": "האם ברצונכם להסיר את המוצר הזה?",
        "hr-HR": "Želite li ukloniti ovaj proizvod?",
        "hu-HU": "Eltávolítod ezt a terméket?",
        "hu": "Eltávolítod ezt a terméket?",
        "it-IT": "Vuoi rimuovere questo prodotto?",
        "it": "Vuoi rimuovere questo prodotto?",
        "ja-JP": "この商品を削除しますか？",
        "ja": "この商品を削除しますか？",
        "ko-KR": "이 제품을 삭제하시겠어요?",
        "ko": "이 제품을 삭제하시겠어요?",
        "nl-NL": "Wil je dit product verwijderen?",
        "nl": "Wil je dit product verwijderen?",
        "no-NO": "Vil du fjerne dette produktet?",
        "pl-PL": "Czy chcesz usunąć ten produkt?",
        "pl": "Czy chcesz usunąć ten produkt?",
        "pt-BR": "Deseja remover este produto?",
        "pt-PT": "Pretende remover este produto?",
        "ru-RU": "Вы хотите удалить этот товар?",
        "ru": "Вы хотите удалить этот товар?",
        "sv-SE": "Vill du ta bort den här produkten?",
        "sv": "Vill du ta bort den här produkten?",
        "th-TH": "คุณต้องการนำสินค้านี้ออกหรือไม่",
        "th": "คุณต้องการนำสินค้านี้ออกหรือไม่",
        "tr-TR": "Bu ürünü kaldırmak istiyor musunuz?",
        "tr": "Bu ürünü kaldırmak istiyor musunuz?"
    },
    "RL_Roles": {
        "key": "RL_Roles",
        "en": "Roles",
        "ca-ES": "Funcions",
        "ca": "Funcions",
        "cs-CZ": "Role",
        "cs": "Role",
        "da-DK": "Roller",
        "da": "Roller",
        "de-DE": "Rollen",
        "de": "Rollen",
        "el-GR": "Ρόλοι",
        "el": "Ρόλοι",
        "en-GB": "Roles",
        "es-ES": "Funciones",
        "es": "Funciones",
        "fi-FI": "Roolit",
        "fr-FR": "Rôles",
        "fr": "Rôles",
        "he-IL": "תפקידים",
        "hr-HR": "Uloge",
        "hu-HU": "Szerepek",
        "hu": "Szerepek",
        "it-IT": "Ruoli",
        "it": "Ruoli",
        "ja-JP": "役割",
        "ja": "役割",
        "ko-KR": "역할",
        "ko": "역할",
        "nl-NL": "Rollen",
        "nl": "Rollen",
        "no-NO": "Roller",
        "pl-PL": "Stanowiska",
        "pl": "Stanowiska",
        "pt-BR": "Funções",
        "pt-PT": "Funções",
        "ru-RU": "Роли",
        "ru": "Роли",
        "sv-SE": "Roller",
        "sv": "Roller",
        "th-TH": "บทบาท",
        "th": "บทบาท",
        "tr-TR": "Roller",
        "tr": "Roller"
    },
    "RL_STOCK_ON_HANDS": {
        "key": "RL_STOCK_ON_HANDS",
        "en": "STOCK ON HANDS",
        "ca-ES": "EXISTÈNCIES ACTUALS",
        "ca": "EXISTÈNCIES ACTUALS",
        "cs-CZ": "Existující zásoby",
        "cs": "Existující zásoby",
        "da-DK": "SOH'er",
        "da": "SOH'er",
        "de-DE": "LAGERBESTAND",
        "de": "LAGERBESTAND",
        "el-GR": "ΔΙΑΘΕΣΙΜΟ ΑΠΟΘΕΜΑ",
        "el": "ΔΙΑΘΕΣΙΜΟ ΑΠΟΘΕΜΑ",
        "en-GB": "STOCK ON HANDS",
        "es-ES": "EXISTENCIAS ACTUALES",
        "es": "EXISTENCIAS ACTUALES",
        "fi-FI": "VARASTOSSA OLEVAT TUOTTEET",
        "fr-FR": "STOCK DISPONIBLE",
        "fr": "STOCK DISPONIBLE",
        "he-IL": "סחורה זמינה",
        "hr-HR": "RASPOLOŽIVE ZALIHE",
        "hu-HU": "RENDELKEZÉSRE ÁLLÓ RAKTÁRKÉSZLET",
        "hu": "RENDELKEZÉSRE ÁLLÓ RAKTÁRKÉSZLET",
        "it-IT": "SCORTE DISPONIBILI",
        "it": "SCORTE DISPONIBILI",
        "ja-JP": "在庫",
        "ja": "在庫",
        "ko-KR": "현재 재고 수준",
        "ko": "현재 재고 수준",
        "nl-NL": "BESTAANDE VOORRAAD",
        "nl": "BESTAANDE VOORRAAD",
        "no-NO": "PÅ LAGER",
        "pl-PL": "AKTUALNE ZAPASY",
        "pl": "AKTUALNE ZAPASY",
        "pt-BR": "ESTOQUE DISPONÍVEL",
        "pt-PT": "STOCK DISPONÍVEL",
        "ru-RU": "ТОВАРЫ В НАЛИЧИИ",
        "ru": "ТОВАРЫ В НАЛИЧИИ",
        "sv-SE": "FINNS I LAGER",
        "sv": "FINNS I LAGER",
        "th-TH": "สินค้าที่มีในสต็อก",
        "th": "สินค้าที่มีในสต็อก",
        "tr-TR": "ELDE BULUNAN STOK",
        "tr": "ELDE BULUNAN STOK"
    },
    "RL_SaveChanges": {
        "key": "RL_SaveChanges",
        "en": "Save Changes",
        "ca-ES": "Desa els canvis",
        "ca": "Desa els canvis",
        "cs-CZ": "Uložit změny",
        "cs": "Uložit změny",
        "da-DK": "Gem ændringer",
        "da": "Gem ændringer",
        "de-DE": "Änderungen speichern",
        "de": "Änderungen speichern",
        "el-GR": "Αποθήκευση αλλαγών",
        "el": "Αποθήκευση αλλαγών",
        "en-GB": "Save Changes",
        "es-ES": "Guardar cambios",
        "es": "Guardar cambios",
        "fi-FI": "Tallenna muutokset",
        "fr-FR": "Enregistrer les modifications",
        "fr": "Enregistrer les modifications",
        "he-IL": "שמור שינויים",
        "hr-HR": "Spremi promjene",
        "hu-HU": "Változtatások mentése",
        "hu": "Változtatások mentése",
        "it-IT": "Salva modifiche",
        "it": "Salva modifiche",
        "ja-JP": "変更を保存",
        "ja": "変更を保存",
        "ko-KR": "변경 사항 저장",
        "ko": "변경 사항 저장",
        "nl-NL": "Sla wijzigingen op",
        "nl": "Sla wijzigingen op",
        "no-NO": "Lagre endringene",
        "pl-PL": "Zapisz zmiany",
        "pl": "Zapisz zmiany",
        "pt-BR": "Salvar alterações",
        "pt-PT": "Guardar alterações",
        "ru-RU": "Сохранить изменения",
        "ru": "Сохранить изменения",
        "sv-SE": "Spara ändringar",
        "sv": "Spara ändringar",
        "th-TH": "บันทึกการเปลี่ยนแปลง",
        "th": "บันทึกการเปลี่ยนแปลง",
        "tr-TR": "Değişiklikleri Kaydet",
        "tr": "Değişiklikleri Kaydet"
    },
    "RL_ScanBarcode": {
        "key": "RL_ScanBarcode",
        "en": "Scan Barcode",
        "ca-ES": "Escaneja el codi de barres",
        "ca": "Escaneja el codi de barres",
        "cs-CZ": "Načtěte čárový kód",
        "cs": "Načtěte čárový kód",
        "da-DK": "Scan stregkode",
        "da": "Scan stregkode",
        "de-DE": "Barcode scannen",
        "de": "Barcode scannen",
        "el-GR": "Σάρωση γραμμωτού κώδικα",
        "el": "Σάρωση γραμμωτού κώδικα",
        "en-GB": "Scan Barcode",
        "es-ES": "Escanear código de barras",
        "es": "Escanear código de barras",
        "fi-FI": "Skannaa viivakoodi",
        "fr-FR": "Scanner un code-barres",
        "fr": "Scanner un code-barres",
        "he-IL": "סרקו ברקוד",
        "hr-HR": "Skeniraj crtični kôd",
        "hu-HU": "Vonalkód beolvasása",
        "hu": "Vonalkód beolvasása",
        "it-IT": "Scansiona codice a barre",
        "it": "Scansiona codice a barre",
        "ja-JP": "バーコードをスキャン",
        "ja": "バーコードをスキャン",
        "ko-KR": "바코드 스캔",
        "ko": "바코드 스캔",
        "nl-NL": "Scan barcode",
        "nl": "Scan barcode",
        "no-NO": "Skann strekkode",
        "pl-PL": "Zeskanuj kod kreskowy",
        "pl": "Zeskanuj kod kreskowy",
        "pt-BR": "Digitalizar código de barras",
        "pt-PT": "Ler código de barras",
        "ru-RU": "Отсканировать штрихкод",
        "ru": "Отсканировать штрихкод",
        "sv-SE": "Skanna streckkod",
        "sv": "Skanna streckkod",
        "th-TH": "สแกนบาร์โค้ด",
        "th": "สแกนบาร์โค้ด",
        "tr-TR": "Barkodu Tara",
        "tr": "Barkodu Tara"
    },
    "RL_SearchMarkdowns": {
        "key": "RL_SearchMarkdowns",
        "en": "Search Markdowns",
        "ca-ES": "Cerca descomptes",
        "ca": "Cerca descomptes",
        "cs-CZ": "Hledat ve slevách",
        "cs": "Hledat ve slevách",
        "da-DK": "Søg i prisnedsættelser",
        "da": "Søg i prisnedsættelser",
        "de-DE": "Preisabschläge suchen",
        "de": "Preisabschläge suchen",
        "el-GR": "Αναζήτηση μειώσεων τιμών",
        "el": "Αναζήτηση μειώσεων τιμών",
        "en-GB": "Search Markdowns",
        "es-ES": "Buscar rebajas",
        "es": "Buscar rebajas",
        "fi-FI": "Etsi alennuksia",
        "fr-FR": "Rechercher des démarques",
        "fr": "Rechercher des démarques",
        "he-IL": "חפשו הנחות",
        "hr-HR": "Pretraži sniženja",
        "hu-HU": "Leértékelések keresése",
        "hu": "Leértékelések keresése",
        "it-IT": "Cerca ribassi",
        "it": "Cerca ribassi",
        "ja-JP": "値下げを検索",
        "ja": "値下げを検索",
        "ko-KR": "가격 인하 검색",
        "ko": "가격 인하 검색",
        "nl-NL": "Zoek kortingen",
        "nl": "Zoek kortingen",
        "no-NO": "Søk etter tilbud",
        "pl-PL": "Szukaj obniżek",
        "pl": "Szukaj obniżek",
        "pt-BR": "Pesquisar redução de preço",
        "pt-PT": "Procurar artigos com desconto",
        "ru-RU": "Поиск уцененных товаров",
        "ru": "Поиск уцененных товаров",
        "sv-SE": "Sök efter prissänkningar",
        "sv": "Sök efter prissänkningar",
        "th-TH": "ค้นหารายการลดราคา",
        "th": "ค้นหารายการลดราคา",
        "tr-TR": "İndirimleri Ara",
        "tr": "İndirimleri Ara"
    },
    "RL_SearchProductOrStyleColor": {
        "key": "RL_SearchProductOrStyleColor",
        "en": "Search Product or Style Color",
        "ca-ES": "Cerca el producte o el color del model",
        "ca": "Cerca el producte o el color del model",
        "cs-CZ": "Hledat podle produktu nebo barvy modelu",
        "cs": "Hledat podle produktu nebo barvy modelu",
        "da-DK": "Søg efter produkt eller modelfarve",
        "da": "Søg efter produkt eller modelfarve",
        "de-DE": "Produktname oder Style-Farbe suchen",
        "de": "Produktname oder Style-Farbe suchen",
        "el-GR": "Αναζήτηση προϊόντος ή χρώματος στιλ",
        "el": "Αναζήτηση προϊόντος ή χρώματος στιλ",
        "en-GB": "Search Product or Style Colour",
        "es-ES": "Buscar producto o color de modelo",
        "es": "Buscar producto o color de modelo",
        "fi-FI": "Etsi tuotenimen tai värin perusteella",
        "fr-FR": "Rechercher un produit ou une couleur de modèle",
        "fr": "Rechercher un produit ou une couleur de modèle",
        "he-IL": "חפשו מוצר או סגנון-צבע",
        "hr-HR": "Pretraži proizvod ili model/boju",
        "hu-HU": "Termék vagy Style Color keresése",
        "hu": "Termék vagy Style Color keresése",
        "it-IT": "Cerca per prodotto o colore del modello",
        "it": "Cerca per prodotto o colore del modello",
        "ja-JP": "商品またはスタイル カラーを検索",
        "ja": "商品またはスタイル カラーを検索",
        "ko-KR": "제품 또는 스타일 컬러 검색",
        "ko": "제품 또는 스타일 컬러 검색",
        "nl-NL": "Zoek op product of stijlkleur",
        "nl": "Zoek op product of stijlkleur",
        "no-NO": "Søk etter produkt eller produktfarge",
        "pl-PL": "Szukaj produktu lub modelu-koloru",
        "pl": "Szukaj produktu lub modelu-koloru",
        "pt-BR": "Pesquisar produto ou cor do estilo",
        "pt-PT": "Procurar Produto ou estilo-cor",
        "ru-RU": "Поиск по названию товара или модели/расцветке",
        "ru": "Поиск по названию товара или модели/расцветке",
        "sv-SE": "Sök efter produkt eller modellfärg",
        "sv": "Sök efter produkt eller modellfärg",
        "th-TH": "ค้นหาสินค้าหรือสีสไตล์",
        "th": "ค้นหาสินค้าหรือสีสไตล์",
        "tr-TR": "Ürün veya Stil Rengi Ara",
        "tr": "Ürün veya Stil Rengi Ara"
    },
    "RL_Select": {
        "key": "RL_Select",
        "en": "Select",
        "ca-ES": "Selecciona",
        "ca": "Selecciona",
        "cs-CZ": "Vybrat",
        "cs": "Vybrat",
        "da-DK": "Vælg",
        "da": "Vælg",
        "de-DE": "Auswählen",
        "de": "Auswählen",
        "el-GR": "Επιλογή",
        "el": "Επιλογή",
        "en-GB": "Select",
        "es-ES": "Seleccionar",
        "es": "Seleccionar",
        "fi-FI": "Valitse",
        "fr-FR": "Sélectionner",
        "fr": "Sélectionner",
        "he-IL": "בחירה",
        "hr-HR": "Odaberi",
        "hu-HU": "Kiválasztás",
        "hu": "Kiválasztás",
        "it-IT": "Seleziona",
        "it": "Seleziona",
        "ja-JP": "選択",
        "ja": "選択",
        "ko-KR": "선택",
        "ko": "선택",
        "nl-NL": "Selecteer",
        "nl": "Selecteer",
        "no-NO": "Velg",
        "pl-PL": "Wybierz",
        "pl": "Wybierz",
        "pt-BR": "Selecionar",
        "pt-PT": "Selecionar",
        "ru-RU": "Выбрать",
        "ru": "Выбрать",
        "sv-SE": "Välj",
        "sv": "Välj",
        "th-TH": "เลือก",
        "th": "เลือก",
        "tr-TR": "Seç",
        "tr": "Seç"
    },
    "RL_SignIn": {
        "key": "RL_SignIn",
        "en": "Sign In",
        "ca-ES": "Inicia la sessió",
        "ca": "Inicia la sessió",
        "cs-CZ": "Přihlásit se",
        "cs": "Přihlásit se",
        "da-DK": "Log ind",
        "da": "Log ind",
        "de-DE": "Anmelden",
        "de": "Anmelden",
        "el-GR": "Σύνδεση",
        "el": "Σύνδεση",
        "en-GB": "Sign In",
        "es-ES": "Iniciar sesión",
        "es": "Iniciar sesión",
        "fi-FI": "Kirjaudu sisään",
        "fr-FR": "S'identifier",
        "fr": "S'identifier",
        "he-IL": "כניסה",
        "hr-HR": "Prijava",
        "hu-HU": "Belépés",
        "hu": "Belépés",
        "it-IT": "Accedi",
        "it": "Accedi",
        "ja-JP": "ログイン",
        "ja": "ログイン",
        "ko-KR": "로그인",
        "ko": "로그인",
        "nl-NL": "Log in",
        "nl": "Log in",
        "no-NO": "Logg på",
        "pl-PL": "Zaloguj się",
        "pl": "Zaloguj się",
        "pt-BR": "Entrar",
        "pt-PT": "Iniciar sessão",
        "ru-RU": "Войти",
        "ru": "Войти",
        "sv-SE": "Logga in",
        "sv": "Logga in",
        "th-TH": "ลงชื่อเข้าใช้",
        "th": "ลงชื่อเข้าใช้",
        "tr-TR": "Oturum Aç",
        "tr": "Oturum Aç"
    },
    "RL_StartDate": {
        "key": "RL_StartDate",
        "en": "Start Date",
        "ca-ES": "Data d'inici",
        "ca": "Data d'inici",
        "cs-CZ": "Počáteční datum",
        "cs": "Počáteční datum",
        "da-DK": "Startdato",
        "da": "Startdato",
        "de-DE": "Startdatum",
        "de": "Startdatum",
        "el-GR": "Ημερομηνία έναρξης",
        "el": "Ημερομηνία έναρξης",
        "en-GB": "Start Date",
        "es-ES": "Fecha de inicio",
        "es": "Fecha de inicio",
        "fi-FI": "Aloituspäivä",
        "fr-FR": "Date de début",
        "fr": "Date de début",
        "he-IL": "תאריך התחלה",
        "hr-HR": "Datum početka",
        "hu-HU": "Kezdés dátuma",
        "hu": "Kezdés dátuma",
        "it-IT": "Data di inizio",
        "it": "Data di inizio",
        "ja-JP": "開始日",
        "ja": "開始日",
        "ko-KR": "시작일",
        "ko": "시작일",
        "nl-NL": "Begindatum",
        "nl": "Begindatum",
        "no-NO": "Startdato",
        "pl-PL": "Data rozpoczęcia",
        "pl": "Data rozpoczęcia",
        "pt-BR": "Data de início",
        "pt-PT": "Data de início",
        "ru-RU": "Дата начала",
        "ru": "Дата начала",
        "sv-SE": "Startdatum",
        "sv": "Startdatum",
        "th-TH": "วันที่เริ่ม",
        "th": "วันที่เริ่ม",
        "tr-TR": "Başlangıç Tarihi",
        "tr": "Başlangıç Tarihi"
    },
    "RL_StartScanning": {
        "key": "RL_StartScanning",
        "en": "Start scanning",
        "ca-ES": "Comença a escanejar",
        "ca": "Comença a escanejar",
        "cs-CZ": "Začít skenovat",
        "cs": "Začít skenovat",
        "da-DK": "Start scanning",
        "da": "Start scanning",
        "de-DE": "Suche starten",
        "de": "Suche starten",
        "el-GR": "Έναρξη σάρωσης",
        "el": "Έναρξη σάρωσης",
        "en-GB": "Start scanning",
        "es-ES": "Empezar escaneo",
        "es": "Empezar escaneo",
        "fi-FI": "Aloita skannaaminen",
        "fr-FR": "Commencer à scanner",
        "fr": "Commencer à scanner",
        "he-IL": "התחילו לסרוק",
        "hr-HR": "Pokreni skenirane",
        "hu-HU": "Beolvasás kezdése",
        "hu": "Beolvasás kezdése",
        "it-IT": "Inizia scansione",
        "it": "Inizia scansione",
        "ja-JP": "スキャンを開始",
        "ja": "スキャンを開始",
        "ko-KR": "스캔 시작",
        "ko": "스캔 시작",
        "nl-NL": "Begin met scannen",
        "nl": "Begin met scannen",
        "no-NO": "Start skanning",
        "pl-PL": "Rozpocznij skanowanie",
        "pl": "Rozpocznij skanowanie",
        "pt-BR": "Iniciar a digitalização",
        "pt-PT": "Iniciar leitura",
        "ru-RU": "Начать сканирование",
        "ru": "Начать сканирование",
        "sv-SE": "Börja skanna",
        "sv": "Börja skanna",
        "th-TH": "เริ่มสแกน",
        "th": "เริ่มสแกน",
        "tr-TR": "Taramayı başlat",
        "tr": "Taramayı başlat"
    },
    "RL_Starts": {
        "key": "RL_Starts",
        "en": "starts",
        "ca-ES": "comença",
        "ca": "comença",
        "cs-CZ": "začátek",
        "cs": "začátek",
        "da-DK": "begynder",
        "da": "begynder",
        "de-DE": "Beginnt",
        "de": "Beginnt",
        "el-GR": "ξεκινά",
        "el": "ξεκινά",
        "en-GB": "starts",
        "es-ES": "empieza",
        "es": "empieza",
        "fi-FI": "alkaa",
        "fr-FR": "commence",
        "fr": "commence",
        "he-IL": "החל מ",
        "hr-HR": "počinje",
        "hu-HU": "kezdés",
        "hu": "kezdés",
        "it-IT": "inizia",
        "it": "inizia",
        "ja-JP": "開始",
        "ja": "開始",
        "ko-KR": "시작",
        "ko": "시작",
        "nl-NL": "begint",
        "nl": "begint",
        "no-NO": "starter",
        "pl-PL": "rozpocznie się",
        "pl": "rozpocznie się",
        "pt-BR": "começa",
        "pt-PT": "começa",
        "ru-RU": "начинается",
        "ru": "начинается",
        "sv-SE": "startar",
        "sv": "startar",
        "th-TH": "เริ่ม",
        "th": "เริ่ม",
        "tr-TR": "başlangıç",
        "tr": "başlangıç"
    },
    "RL_StockOnHand": {
        "key": "RL_StockOnHand",
        "en": "Stock on Hand",
        "ca-ES": "Existències actuals",
        "ca": "Existències actuals",
        "cs-CZ": "Existující zásoby v",
        "cs": "Existující zásoby v",
        "da-DK": "SOH",
        "da": "SOH",
        "de-DE": "Lagerbestand",
        "de": "Lagerbestand",
        "el-GR": "Διαθέσιμο απόθεμα",
        "el": "Διαθέσιμο απόθεμα",
        "en-GB": "Stock on Hand",
        "es-ES": "Existencias actuales",
        "es": "Existencias actuales",
        "fi-FI": "Varastossa olevat tuotteet",
        "fr-FR": "Stock disponible",
        "fr": "Stock disponible",
        "he-IL": "סחורה זמינה",
        "hr-HR": "Raspoložive zalihe",
        "hu-HU": "Rendelkezésre álló raktárkészlet",
        "hu": "Rendelkezésre álló raktárkészlet",
        "it-IT": "Scorte disponibili",
        "it": "Scorte disponibili",
        "ja-JP": "在庫",
        "ja": "在庫",
        "ko-KR": "현재 재고 수준",
        "ko": "현재 재고 수준",
        "nl-NL": "Bestaande voorraad",
        "nl": "Bestaande voorraad",
        "no-NO": "På lager",
        "pl-PL": "Aktualne zapasy",
        "pl": "Aktualne zapasy",
        "pt-BR": "Estoque disponível",
        "pt-PT": "Stock disponível",
        "ru-RU": "Товары в наличии",
        "ru": "Товары в наличии",
        "sv-SE": "Finns i lager",
        "sv": "Finns i lager",
        "th-TH": "สินค้าที่มีในสต็อก",
        "th": "สินค้าที่มีในสต็อก",
        "tr-TR": "Elde Bulunan Stok",
        "tr": "Elde Bulunan Stok"
    },
    "RL_StyleColor": {
        "key": "RL_StyleColor",
        "en": "Style Color",
        "ca-ES": "Color del model",
        "ca": "Color del model",
        "cs-CZ": "Barva stylu",
        "cs": "Barva stylu",
        "da-DK": "Modelfarve",
        "da": "Modelfarve",
        "de-DE": "Style-Color",
        "de": "Style-Color",
        "el-GR": "Χρώμα στιλ",
        "el": "Χρώμα στιλ",
        "en-GB": "Style Colour",
        "es-ES": "Color del modelo",
        "es": "Color del modelo",
        "fi-FI": "Väri",
        "fr-FR": "Style-coloris",
        "fr": "Style-coloris",
        "he-IL": "צבע סגנון",
        "hr-HR": "Style Color",
        "hu-HU": "Szín",
        "hu": "Szín",
        "it-IT": "Colore del modello",
        "it": "Colore del modello",
        "ja-JP": "Style Color",
        "ja": "Style Color",
        "ko-KR": "스타일 컬러",
        "ko": "스타일 컬러",
        "nl-NL": "Stijlkleur",
        "nl": "Stijlkleur",
        "no-NO": "Produktfarge",
        "pl-PL": "Model-kolor",
        "pl": "Model-kolor",
        "pt-BR": "Cor do estilo",
        "pt-PT": "Style-Color",
        "ru-RU": "Модель/расцветка",
        "ru": "Модель/расцветка",
        "sv-SE": "Modellfärg",
        "sv": "Modellfärg",
        "th-TH": "สีสไตล์",
        "th": "สีสไตล์",
        "tr-TR": "Stil Rengi",
        "tr": "Stil Rengi"
    },
    "RL_StyleColors": {
        "key": "RL_StyleColors",
        "en": "Style Colors",
        "ca-ES": "Colors del model",
        "ca": "Colors del model",
        "cs-CZ": "Styly-barvy",
        "cs": "Styly-barvy",
        "da-DK": "Modelfarver",
        "da": "Modelfarver",
        "de-DE": "Style-Colors",
        "de": "Style-Colors",
        "el-GR": "Χρώματα στλ",
        "el": "Χρώματα στλ",
        "en-GB": "Style Colours",
        "es-ES": "Colores del modelo",
        "es": "Colores del modelo",
        "fi-FI": "Värit",
        "fr-FR": "Style-coloris",
        "fr": "Style-coloris",
        "he-IL": "צבעי סגנון",
        "hr-HR": "Style Colors",
        "hu-HU": "Színek",
        "hu": "Színek",
        "it-IT": "Colori del modello",
        "it": "Colori del modello",
        "ja-JP": "Style Color",
        "ja": "Style Color",
        "ko-KR": "스타일 컬러",
        "ko": "스타일 컬러",
        "nl-NL": "Stijlkleuren",
        "nl": "Stijlkleuren",
        "no-NO": "Produktfarger",
        "pl-PL": "Modele-kolory",
        "pl": "Modele-kolory",
        "pt-BR": "Cores do estilo",
        "pt-PT": "Style-Colors",
        "ru-RU": "Модели/расцветки",
        "ru": "Модели/расцветки",
        "sv-SE": "Modellfärger",
        "sv": "Modellfärger",
        "th-TH": "สีสไตล์",
        "th": "สีสไตล์",
        "tr-TR": "Stil Renkleri",
        "tr": "Stil Renkleri"
    },
    "RL_Submit": {
        "key": "RL_Submit",
        "en": "Submit",
        "ca-ES": "Envia",
        "ca": "Envia",
        "cs-CZ": "Odeslat",
        "cs": "Odeslat",
        "da-DK": "Send",
        "da": "Send",
        "de-DE": "Senden",
        "de": "Senden",
        "el-GR": "Υποβολή",
        "el": "Υποβολή",
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "es": "Enviar",
        "fi-FI": "Lähetä",
        "fr-FR": "Envoyer",
        "fr": "Envoyer",
        "he-IL": "שליחה",
        "hr-HR": "Pošalji",
        "hu-HU": "Küldés",
        "hu": "Küldés",
        "it-IT": "Invia",
        "it": "Invia",
        "ja-JP": "送信",
        "ja": "送信",
        "ko-KR": "제출",
        "ko": "제출",
        "nl-NL": "Verzend",
        "nl": "Verzend",
        "no-NO": "Send inn",
        "pl-PL": "Wyślij",
        "pl": "Wyślij",
        "pt-BR": "Enviar",
        "pt-PT": "Enviar",
        "ru-RU": "Отправить",
        "ru": "Отправить",
        "sv-SE": "Skicka",
        "sv": "Skicka",
        "th-TH": "ส่ง",
        "th": "ส่ง",
        "tr-TR": "Gönder",
        "tr": "Gönder"
    },
    "RL_Today": {
        "key": "RL_Today",
        "en": "Today",
        "ca-ES": "Avui",
        "ca": "Avui",
        "cs-CZ": "Dnes",
        "cs": "Dnes",
        "da-DK": "I dag",
        "da": "I dag",
        "de-DE": "Heute",
        "de": "Heute",
        "el-GR": "Σήμερα",
        "el": "Σήμερα",
        "en-GB": "Today",
        "es-ES": "Hoy",
        "es": "Hoy",
        "fi-FI": "Tänään",
        "fr-FR": "Aujourd'hui",
        "fr": "Aujourd'hui",
        "he-IL": "היום",
        "hr-HR": "Danas",
        "hu-HU": "Ma",
        "hu": "Ma",
        "it-IT": "Oggi",
        "it": "Oggi",
        "ja-JP": "本日",
        "ja": "本日",
        "ko-KR": "오늘",
        "ko": "오늘",
        "nl-NL": "Vandaag",
        "nl": "Vandaag",
        "no-NO": "I dag",
        "pl-PL": "Dzisiaj",
        "pl": "Dzisiaj",
        "pt-BR": "Hoje",
        "pt-PT": "Hoje",
        "ru-RU": "Сегодня",
        "ru": "Сегодня",
        "sv-SE": "Idag",
        "sv": "Idag",
        "th-TH": "วันนี้",
        "th": "วันนี้",
        "tr-TR": "Bugün",
        "tr": "Bugün"
    },
    "RL_Units": {
        "key": "RL_Units",
        "en": "Units",
        "ca-ES": "Unitats",
        "ca": "Unitats",
        "cs-CZ": "Položky",
        "cs": "Položky",
        "da-DK": "Enheder",
        "da": "Enheder",
        "de-DE": "Einheiten",
        "de": "Einheiten",
        "el-GR": "Μονάδες",
        "el": "Μονάδες",
        "en-GB": "Units",
        "es-ES": "Unidades",
        "es": "Unidades",
        "fi-FI": "Yksikköä",
        "fr-FR": "Unités",
        "fr": "Unités",
        "he-IL": "יחידות",
        "hr-HR": "Jedinice",
        "hu-HU": "Egységek",
        "hu": "Egységek",
        "it-IT": "Unità",
        "it": "Unità",
        "ja-JP": "単位",
        "ja": "単位",
        "ko-KR": "유닛",
        "ko": "유닛",
        "nl-NL": "Eenheden",
        "nl": "Eenheden",
        "no-NO": "Enheter",
        "pl-PL": "Sztuki",
        "pl": "Sztuki",
        "pt-BR": "Unidades",
        "pt-PT": "Unidades",
        "ru-RU": "Количество единиц",
        "ru": "Количество единиц",
        "sv-SE": "Enheter",
        "sv": "Enheter",
        "th-TH": "หน่วย",
        "th": "หน่วย",
        "tr-TR": "Birimler",
        "tr": "Birimler"
    },
    "RL_Version": {
        "key": "RL_Version",
        "en": "Version",
        "ca-ES": "Versió",
        "ca": "Versió",
        "cs-CZ": "Verze",
        "cs": "Verze",
        "da-DK": "Version",
        "da": "Version",
        "de-DE": "Version",
        "de": "Version",
        "el-GR": "Έκδοση",
        "el": "Έκδοση",
        "en-GB": "Version",
        "es-ES": "Versión",
        "es": "Versión",
        "fi-FI": "Versio",
        "fr-FR": "Version",
        "fr": "Version",
        "he-IL": "גרסה",
        "hr-HR": "Verzija",
        "hu-HU": "Verzió",
        "hu": "Verzió",
        "it-IT": "Versione",
        "it": "Versione",
        "ja-JP": "バージョン",
        "ja": "バージョン",
        "ko-KR": "버전",
        "ko": "버전",
        "nl-NL": "Versie",
        "nl": "Versie",
        "no-NO": "Versjon",
        "pl-PL": "Wersja",
        "pl": "Wersja",
        "pt-BR": "versão",
        "pt-PT": "Versão",
        "ru-RU": "Версия",
        "ru": "Версия",
        "sv-SE": "Version",
        "sv": "Version",
        "th-TH": "เวอร์ชัน",
        "th": "เวอร์ชัน",
        "tr-TR": "Versiyon",
        "tr": "Versiyon"
    },
    "RL_VisualMerchandising": {
        "key": "RL_VisualMerchandising",
        "en": "Visual Merchandising",
        "ca-ES": "Marxandatge visual",
        "ca": "Marxandatge visual",
        "cs-CZ": "Vizuální podpora",
        "cs": "Vizuální podpora",
        "da-DK": "Visuel markedsføring",
        "da": "Visuel markedsføring",
        "de-DE": "Visual Merchandising",
        "de": "Visual Merchandising",
        "el-GR": "Οπτική προώθηση εμπορευμάτων",
        "el": "Οπτική προώθηση εμπορευμάτων",
        "en-GB": "Visual Merchandising",
        "es-ES": "Presentación visual",
        "es": "Presentación visual",
        "fi-FI": "Visuaalinen markkinointi",
        "fr-FR": "Merchandising visuel",
        "fr": "Merchandising visuel",
        "he-IL": "שיווק חזותי",
        "hr-HR": "Proizvodi za izlaganje",
        "hu-HU": "Vizuális áruértékesítés",
        "hu": "Vizuális áruértékesítés",
        "it-IT": "Visual Merchandising",
        "it": "Visual Merchandising",
        "ja-JP": "ビジュアル マーチャンダイジング",
        "ja": "ビジュアル マーチャンダイジング",
        "ko-KR": "비주얼 머천다이징",
        "ko": "비주얼 머천다이징",
        "nl-NL": "Visuele merchandising",
        "nl": "Visuele merchandising",
        "no-NO": "Visuell merchandising",
        "pl-PL": "Prezentacja towaru",
        "pl": "Prezentacja towaru",
        "pt-BR": "Merchandising visual",
        "pt-PT": "Apresentação visual da mercadoria",
        "ru-RU": "Визуальный мерчендайзинг",
        "ru": "Визуальный мерчендайзинг",
        "sv-SE": "Visuell marknadsföring",
        "sv": "Visuell marknadsföring",
        "th-TH": "การจัดแสดงสินค้า",
        "th": "การจัดแสดงสินค้า",
        "tr-TR": "Görsel Satış Geliştirme",
        "tr": "Görsel Satış Geliştirme"
    },
    "RL_Women": {
        "key": "RL_Women",
        "en": "Women",
        "ca-ES": "Dona",
        "ca": "Dona",
        "cs-CZ": "Ženy",
        "cs": "Ženy",
        "da-DK": "Kvinder",
        "da": "Kvinder",
        "de-DE": "Damen",
        "de": "Damen",
        "el-GR": "Γυναίκες",
        "el": "Γυναίκες",
        "en-GB": "Women",
        "es-ES": "Mujer",
        "es": "Mujer",
        "fi-FI": "Naiset",
        "fr-FR": "Femme",
        "fr": "Femme",
        "he-IL": "נשים",
        "hr-HR": "Žene",
        "hu-HU": "Női",
        "hu": "Női",
        "it-IT": "Donna",
        "it": "Donna",
        "ja-JP": "ウィメンズ",
        "ja": "ウィメンズ",
        "ko-KR": "여성",
        "ko": "여성",
        "nl-NL": "Dames",
        "nl": "Dames",
        "no-NO": "Dame",
        "pl-PL": "Kobiety",
        "pl": "Kobiety",
        "pt-BR": "Feminino",
        "pt-PT": "Mulher",
        "ru-RU": "Женщины",
        "ru": "Женщины",
        "sv-SE": "Kvinnor",
        "sv": "Kvinnor",
        "th-TH": "ผู้หญิง",
        "th": "ผู้หญิง",
        "tr-TR": "Kadın",
        "tr": "Kadın"
    },
    "RL_Yes": {
        "key": "RL_Yes",
        "en": "Yes",
        "ca-ES": "Sí",
        "ca": "Sí",
        "cs-CZ": "Ano",
        "cs": "Ano",
        "da-DK": "Ja",
        "da": "Ja",
        "de-DE": "Ja",
        "de": "Ja",
        "el-GR": "Ναι",
        "el": "Ναι",
        "en-GB": "Yes",
        "es-ES": "Sí",
        "es": "Sí",
        "fi-FI": "Kyllä",
        "fr-FR": "Oui",
        "fr": "Oui",
        "he-IL": "כן",
        "hr-HR": "Da",
        "hu-HU": "Igen",
        "hu": "Igen",
        "it-IT": "Sì",
        "it": "Sì",
        "ja-JP": "はい",
        "ja": "はい",
        "ko-KR": "예",
        "ko": "예",
        "nl-NL": "Ja",
        "nl": "Ja",
        "no-NO": "Ja",
        "pl-PL": "Tak",
        "pl": "Tak",
        "pt-BR": "Sim",
        "pt-PT": "Sim",
        "ru-RU": "Да",
        "ru": "Да",
        "sv-SE": "Ja",
        "sv": "Ja",
        "th-TH": "ใช่",
        "th": "ใช่",
        "tr-TR": "Evet",
        "tr": "Evet"
    },
    "RL_ClearItemSuccess": {
        "key": "RL_ClearItemSuccess",
        "en": "Successfully cleared items from {{1}}",
        "ca-ES": "S'han eliminat correctament els productes de la promoció {{1}}",
        "ca": "S'han eliminat correctament els productes de la promoció {{1}}",
        "cs-CZ": "Jednotky byly úspěšně odstraněné z {{1}}",
        "cs": "Jednotky byly úspěšně odstraněné z {{1}}",
        "da-DK": "Varer blev ryddet fra {{1}}",
        "da": "Varer blev ryddet fra {{1}}",
        "de-DE": "Die Artikel wurden erfolgreich aus {{1}} gelöscht.",
        "de": "Die Artikel wurden erfolgreich aus {{1}} gelöscht.",
        "el-GR": "Τα είδη διαγράφηκαν με επιτυχία από {{1}}",
        "el": "Τα είδη διαγράφηκαν με επιτυχία από {{1}}",
        "en-GB": "Successfully cleared items from {{1}}",
        "es-ES": "Se han eliminado correctamente los productos de {{1}}",
        "es": "Se han eliminado correctamente los productos de {{1}}",
        "fi-FI": "{{1}} tuotteen poisto onnistui",
        "fr-FR": "Articles retirés avec succès de {{1}}",
        "fr": "Articles retirés avec succès de {{1}}",
        "he-IL": "הפריטים נוקו בהצלחה מתוך {{1}}",
        "hr-HR": "Stavke su uspješno uklonjene iz {{1}}",
        "hu-HU": "A cikkeket sikeresen töröltük innen: {{1}}",
        "hu": "A cikkeket sikeresen töröltük innen: {{1}}",
        "it-IT": "Gli articoli sono stati rimossi correttamente da {{1}}",
        "it": "Gli articoli sono stati rimossi correttamente da {{1}}",
        "ja-JP": "{{1}}から商品を削除しました",
        "ja": "{{1}}から商品を削除しました",
        "ko-KR": "{{1}}에서 제품을 삭제했습니다",
        "ko": "{{1}}에서 제품을 삭제했습니다",
        "nl-NL": "Items zijn verwijderd uit {{1}}",
        "nl": "Items zijn verwijderd uit {{1}}",
        "no-NO": "Slettet varer fra {{1}}",
        "pl-PL": "Udało się usunąć produkty z {{1}}",
        "pl": "Udało się usunąć produkty z {{1}}",
        "pt-BR": "Itens removidos de {{1}}",
        "pt-PT": "Artigos eliminados com sucesso de {{1}}",
        "ru-RU": "Успешно удалены товары из {{1}}",
        "ru": "Успешно удалены товары из {{1}}",
        "sv-SE": "Du har rensat alla produkter från {{1}}",
        "sv": "Du har rensat alla produkter från {{1}}",
        "th-TH": "นำรายการออกจาก {{1}} เรียบร้อยแล้ว",
        "th": "นำรายการออกจาก {{1}} เรียบร้อยแล้ว",
        "tr-TR": "Ürünler {{1}} öğesinden başarıyla temizlendi",
        "tr": "Ürünler {{1}} öğesinden başarıyla temizlendi"
    },
    "RL_PromotionNameorStyleColor": {
        "key": "RL_PromotionNameorStyleColor",
        "en": "Promotion Name or Style Color",
        "ca-ES": "Nom de la promoció o color del model",
        "ca": "Nom de la promoció o color del model",
        "cs-CZ": "Název promoakce nebo barva modelu",
        "cs": "Název promoakce nebo barva modelu",
        "da-DK": "Kampagnenavn eller modelfarve",
        "da": "Kampagnenavn eller modelfarve",
        "de-DE": "Angebotsname oder Style-Farbe",
        "de": "Angebotsname oder Style-Farbe",
        "el-GR": "Όνομα προσφοράς ή χρώμα στιλ",
        "el": "Όνομα προσφοράς ή χρώμα στιλ",
        "en-GB": "Promotion Name or Style Colour",
        "es-ES": "Nombre de la promoción o color del modelo",
        "es": "Nombre de la promoción o color del modelo",
        "fi-FI": "Tarjouskampanjan nimi tai tyylin väri",
        "fr-FR": "Nom de la promotion ou Style-Coloris",
        "fr": "Nom de la promotion ou Style-Coloris",
        "he-IL": "שם המבצע או סגנון-צבע",
        "hr-HR": "Naziv promocije ili model/boja",
        "hu-HU": "Promóció neve vagy Style Color",
        "hu": "Promóció neve vagy Style Color",
        "it-IT": "Nome della promozione o colore del modello",
        "it": "Nome della promozione o colore del modello",
        "ja-JP": "プロモーション名またはスタイル カラー",
        "ja": "プロモーション名またはスタイル カラー",
        "ko-KR": "프로모션 이름 또는 스타일 컬러",
        "ko": "프로모션 이름 또는 스타일 컬러",
        "nl-NL": "Promotienaam of stijlkleur",
        "nl": "Promotienaam of stijlkleur",
        "no-NO": "Kampanjenavn eller produktfarge",
        "pl-PL": "Nazwa promocji lub model/kolor",
        "pl": "Nazwa promocji lub model/kolor",
        "pt-BR": "Nome da promoção ou Cor do estilo",
        "pt-PT": "Nome da promoção ou estilo-cor",
        "ru-RU": "Название акции или расцветка модели",
        "ru": "Название акции или расцветка модели",
        "sv-SE": "Kampanjnamn eller modellfärg",
        "sv": "Kampanjnamn eller modellfärg",
        "th-TH": "ชื่อโปรโมชันหรือสีสไตล์",
        "th": "ชื่อโปรโมชันหรือสีสไตล์",
        "tr-TR": "Promosyon Adı veya Stil/Renk",
        "tr": "Promosyon Adı veya Stil/Renk"
    },
    "RL_FootwearDivision": {
        "key": "RL_FootwearDivision",
        "en": "Footwear Division",
        "ca-ES": "Departament de calçat",
        "ca": "Departament de calçat",
        "cs-CZ": "Sekce Obuv",
        "cs": "Sekce Obuv",
        "da-DK": "Fodtøjsdivision",
        "da": "Fodtøjsdivision",
        "de-DE": "Footwear Division",
        "de": "Footwear Division",
        "el-GR": "Τμήμα παπουτσιών",
        "el": "Τμήμα παπουτσιών",
        "en-GB": "Footwear Division",
        "es-ES": "Categoría de zapatillas",
        "es": "Categoría de zapatillas",
        "fi-FI": "Jalkineosasto",
        "fr-FR": "Division chaussures",
        "fr": "Division chaussures",
        "he-IL": "מחלקת הנעלה",
        "hr-HR": "Odjel obuće",
        "hu-HU": "Cipőrészleg",
        "hu": "Cipőrészleg",
        "it-IT": "Divisione Scarpe",
        "it": "Divisione Scarpe",
        "ja-JP": "フットウェア部門",
        "ja": "フットウェア部門",
        "ko-KR": "신발 부서",
        "ko": "신발 부서",
        "nl-NL": "Schoenendivisie",
        "nl": "Schoenendivisie",
        "no-NO": "Skoavdeling",
        "pl-PL": "Dział obuwia",
        "pl": "Dział obuwia",
        "pt-BR": "Divisão de calçados",
        "pt-PT": "Secção de calçado",
        "ru-RU": "Отдел обуви",
        "ru": "Отдел обуви",
        "sv-SE": "Skoavdelning",
        "sv": "Skoavdelning",
        "th-TH": "หมวดรองเท้า",
        "th": "หมวดรองเท้า",
        "tr-TR": "Ayakkabı Departmanı",
        "tr": "Ayakkabı Departmanı"
    },
    "RL_Mens": {
        "key": "RL_Mens",
        "en": "Mens",
        "ca-ES": "Per a home",
        "ca": "Per a home",
        "cs-CZ": "Muži",
        "cs": "Muži",
        "da-DK": "Til mænd",
        "da": "Til mænd",
        "de-DE": "Herren",
        "de": "Herren",
        "el-GR": "Ανδρικά",
        "el": "Ανδρικά",
        "en-GB": "Men's",
        "es-ES": "Hombre",
        "es": "Hombre",
        "fi-FI": "Miesten",
        "fr-FR": "Homme",
        "fr": "Homme",
        "he-IL": "גברים",
        "hr-HR": "Muškarci",
        "hu-HU": "Férfi",
        "hu": "Férfi",
        "it-IT": "Uomo",
        "it": "Uomo",
        "ja-JP": "メンズ",
        "ja": "メンズ",
        "ko-KR": "남성",
        "ko": "남성",
        "nl-NL": "Heren",
        "nl": "Heren",
        "no-NO": "Herre",
        "pl-PL": "Męskie",
        "pl": "Męskie",
        "pt-BR": "Masculino",
        "pt-PT": "Homem",
        "ru-RU": "Мужчины",
        "ru": "Мужчины",
        "sv-SE": "Män",
        "sv": "Män",
        "th-TH": "ผู้ชาย",
        "th": "ผู้ชาย",
        "tr-TR": "Erkek",
        "tr": "Erkek"
    },
    "RL_Womens": {
        "key": "RL_Womens",
        "en": "Womens",
        "ca-ES": "Per a dona",
        "ca": "Per a dona",
        "cs-CZ": "Ženy",
        "cs": "Ženy",
        "da-DK": "Til kvinder",
        "da": "Til kvinder",
        "de-DE": "Damen",
        "de": "Damen",
        "el-GR": "Γυναικεία",
        "el": "Γυναικεία",
        "en-GB": "Women's",
        "es-ES": "Mujer",
        "es": "Mujer",
        "fi-FI": "Naisten",
        "fr-FR": "Femme",
        "fr": "Femme",
        "he-IL": "נשים",
        "hr-HR": "Žene",
        "hu-HU": "Női",
        "hu": "Női",
        "it-IT": "Donna",
        "it": "Donna",
        "ja-JP": "ウィメンズ",
        "ja": "ウィメンズ",
        "ko-KR": "여성",
        "ko": "여성",
        "nl-NL": "Dames",
        "nl": "Dames",
        "no-NO": "Dame",
        "pl-PL": "Damskie",
        "pl": "Damskie",
        "pt-BR": "Feminino",
        "pt-PT": "Mulher",
        "ru-RU": "Женщины",
        "ru": "Женщины",
        "sv-SE": "Kvinnor",
        "sv": "Kvinnor",
        "th-TH": "ผู้หญิง",
        "th": "ผู้หญิง",
        "tr-TR": "Kadın",
        "tr": "Kadın"
    },
    "RL_SearchPromotions": {
        "key": "RL_SearchPromotions",
        "en": "Search Promotions",
        "ca-ES": "Cerca promocions",
        "ca": "Cerca promocions",
        "cs-CZ": "Hledat promoakce",
        "cs": "Hledat promoakce",
        "da-DK": "Søg efter kampagner",
        "da": "Søg efter kampagner",
        "de-DE": "Angebote suchen",
        "de": "Angebote suchen",
        "el-GR": "Αναζήτηση προσφορών",
        "el": "Αναζήτηση προσφορών",
        "en-GB": "Search Promotions",
        "es-ES": "Buscar promociones",
        "es": "Buscar promociones",
        "fi-FI": "Etsi tarjouskampanjoita",
        "fr-FR": "Rechercher les promotions",
        "fr": "Rechercher les promotions",
        "he-IL": "חיפוש מבצעים",
        "hr-HR": "Pretraži promocije",
        "hu-HU": "Promóciók keresése",
        "hu": "Promóciók keresése",
        "it-IT": "Cerca promozioni",
        "it": "Cerca promozioni",
        "ja-JP": "プロモーションを検索",
        "ja": "プロモーションを検索",
        "ko-KR": "프로모션 검색",
        "ko": "프로모션 검색",
        "nl-NL": "Zoek promoties",
        "nl": "Zoek promoties",
        "no-NO": "Søk i kampanjer",
        "pl-PL": "Wyszukaj promocje",
        "pl": "Wyszukaj promocje",
        "pt-BR": "Pesquisar promoções",
        "pt-PT": "Procurar promoções",
        "ru-RU": "Поиск акций",
        "ru": "Поиск акций",
        "sv-SE": "Sök efter kampanj",
        "sv": "Sök efter kampanj",
        "th-TH": "ค้นหาโปรโมชัน",
        "th": "ค้นหาโปรโมชัน",
        "tr-TR": "Promosyon Ara",
        "tr": "Promosyon Ara"
    },
    "RL_ProductDeleteConfirm4": {
        "key": "RL_ProductDeleteConfirm4",
        "en": "Do you want to remove products ({{1}}) from {{2}} promotion?",
        "ca-ES": "Vols eliminar els productes ({{1}}) de la promoció {{2}}?",
        "ca": "Vols eliminar els productes ({{1}}) de la promoció {{2}}?",
        "cs-CZ": "Chcete odebrat produkty ({{1}}) z promoakce {{2}}?",
        "cs": "Chcete odebrat produkty ({{1}}) z promoakce {{2}}?",
        "da-DK": "Vil du fjerne ({{1}}) produkter fra {{2}}-kampagnen?",
        "da": "Vil du fjerne ({{1}}) produkter fra {{2}}-kampagnen?",
        "de-DE": "Möchtest du Produkte ({{1}}) aus {{2}} Angebot entfernen?",
        "de": "Möchtest du Produkte ({{1}}) aus {{2}} Angebot entfernen?",
        "el-GR": "Θέλετε να καταργήσετε προϊόντα ({{1}}) από την προσφορά {{2}};",
        "el": "Θέλετε να καταργήσετε προϊόντα ({{1}}) από την προσφορά {{2}};",
        "en-GB": "Do you want to remove products ({{1}}) from {{2}} promotion?",
        "es-ES": "¿Quieres eliminar ({{1}}) productos de la promoción {{2}}?",
        "es": "¿Quieres eliminar ({{1}}) productos de la promoción {{2}}?",
        "fi-FI": "Haluatko poistaa tuotteet ({{1}}) tarjouskampanjasta {{2}}?",
        "fr-FR": "Souhaitez-vous retirer ({{1}}) produits de la promotion {{2}} ?",
        "fr": "Souhaitez-vous retirer ({{1}}) produits de la promotion {{2}} ?",
        "he-IL": "האם ברצונכם להסיר מוצרים ({{1}}) מהמבצע {{2}}?",
        "hr-HR": "Želite li ukloniti proizvode ({{1}}) iz promocije {{2}}?",
        "hu-HU": "Eltávolítod a termékeket ({{1}}) a(z) {{2}} promócióból?",
        "hu": "Eltávolítod a termékeket ({{1}}) a(z) {{2}} promócióból?",
        "it-IT": "Vuoi rimuovere i prodotti ({{1}}) dalla promozione {{2}}?",
        "it": "Vuoi rimuovere i prodotti ({{1}}) dalla promozione {{2}}?",
        "ja-JP": "商品（{{1}}）を{{2}}のプロモーションから削除しますか？",
        "ja": "商品（{{1}}）を{{2}}のプロモーションから削除しますか？",
        "ko-KR": "{{2}} 프로모션에서 제품({{1}}개)을 삭제하시겠어요?",
        "ko": "{{2}} 프로모션에서 제품({{1}}개)을 삭제하시겠어요?",
        "nl-NL": "Wil je producten ({{1}}) verwijderen uit de promotie {{2}}?",
        "nl": "Wil je producten ({{1}}) verwijderen uit de promotie {{2}}?",
        "no-NO": "Vil du fjerne produkter ({{1}}) fra {{2}} kampanje?",
        "pl-PL": "Czy chcesz usunąć produkty ({{1}}) z promocji {{2}}?",
        "pl": "Czy chcesz usunąć produkty ({{1}}) z promocji {{2}}?",
        "pt-BR": "Deseja remover os produtos ({{1}}) de {{2}} promoção?",
        "pt-PT": "Pretende remover produtos ({{1}}) da promoção {{2}}?",
        "ru-RU": "Удалить товары ({{1}}) из акции {{2}}?",
        "ru": "Удалить товары ({{1}}) из акции {{2}}?",
        "sv-SE": "Vill du ta bort produkter ({{1}}) från {{2}} kampanj?",
        "sv": "Vill du ta bort produkter ({{1}}) från {{2}} kampanj?",
        "th-TH": "คุณต้องการนำสินค้า ({{1}}) ออกจากโปรโมชัน {{2}} หรือไม่",
        "th": "คุณต้องการนำสินค้า ({{1}}) ออกจากโปรโมชัน {{2}} หรือไม่",
        "tr-TR": "{{2}} promosyonundan ürünleri ({{1}}) kaldırmak istiyor musun?",
        "tr": "{{2}} promosyonundan ürünleri ({{1}}) kaldırmak istiyor musun?"
    },
    "RL_PromotionValidation1": {
        "key": "RL_PromotionValidation1",
        "en": "This store does not have any promotions.",
        "ca-ES": "Aquesta botiga no té cap promoció.",
        "ca": "Aquesta botiga no té cap promoció.",
        "cs-CZ": "Prodejna nemá žádné promoakce.",
        "cs": "Prodejna nemá žádné promoakce.",
        "da-DK": "Denne butik har ingen kampagner.",
        "da": "Denne butik har ingen kampagner.",
        "de-DE": "Dieser Store hat keine Angebote.",
        "de": "Dieser Store hat keine Angebote.",
        "el-GR": "Το συγκεκριμένο κατάστημα δεν διαθέτει προσφορές.",
        "el": "Το συγκεκριμένο κατάστημα δεν διαθέτει προσφορές.",
        "en-GB": "This store does not have any promotions.",
        "es-ES": "Esta tienda no tiene ninguna promoción.",
        "es": "Esta tienda no tiene ninguna promoción.",
        "fi-FI": "Tässä kaupassa ei ole tarjouskampanjoita.",
        "fr-FR": "Ce magasin ne propose aucune promotion.",
        "fr": "Ce magasin ne propose aucune promotion.",
        "he-IL": "בחנות זו אין מבצעים.",
        "hr-HR": "Na ovom prodajnom mjestu nema promocija.",
        "hu-HU": "Ebben az üzletben nincsenek promóciók.",
        "hu": "Ebben az üzletben nincsenek promóciók.",
        "it-IT": "Questo store non offre promozioni.",
        "it": "Questo store non offre promozioni.",
        "ja-JP": "この店舗のプロモーションはありません。",
        "ja": "この店舗のプロモーションはありません。",
        "ko-KR": "이 매장에는 진행 중인 프로모션이 없습니다.",
        "ko": "이 매장에는 진행 중인 프로모션이 없습니다.",
        "nl-NL": "Er zijn geen promoties in deze store.",
        "nl": "Er zijn geen promoties in deze store.",
        "no-NO": "Denne butikken har ingen kampanjer.",
        "pl-PL": "W tym sklepie nie ma żadnych promocji.",
        "pl": "W tym sklepie nie ma żadnych promocji.",
        "pt-BR": "Esta loja não tem promoções.",
        "pt-PT": "Esta loja não tem quaisquer promoções.",
        "ru-RU": "В этом магазине не проводится акций.",
        "ru": "В этом магазине не проводится акций.",
        "sv-SE": "Den här butiken har inga kampanjer.",
        "sv": "Den här butiken har inga kampanjer.",
        "th-TH": "Store นี้ไม่มีโปรโมชันใดๆ",
        "th": "Store นี้ไม่มีโปรโมชันใดๆ",
        "tr-TR": "Bu mağazada promosyon yok.",
        "tr": "Bu mağazada promosyon yok."
    },
    "RL_ProductScanSuccess": {
        "key": "RL_ProductScanSuccess",
        "en": "All scanned products have been submitted successfully! Please click the \"X\" button to close the scanner.",
        "cs-CZ": "Všechny naskenované produkty byly úspěšně odeslány. Skener zavřete klepnutím na symbol „X“.",
        "cs": "Všechny naskenované produkty byly úspěšně odeslány. Skener zavřete klepnutím na symbol „X“.",
        "da-DK": "Alle scannede produkter er blevet indsendt. Klik på \"X\"-knappen for at lukke scanneren.",
        "da": "Alle scannede produkter er blevet indsendt. Klik på \"X\"-knappen for at lukke scanneren.",
        "de-DE": "Alle gescannten Produkte wurden erfolgreich abgesendet! Klicke auf die Schaltfläche \"X\", um den Scanner zu schließen.",
        "de": "Alle gescannten Produkte wurden erfolgreich abgesendet! Klicke auf die Schaltfläche \"X\", um den Scanner zu schließen.",
        "el-GR": "Όλα τα σαρωμένα προϊόντα έχουν υποβληθεί με επιτυχία! Για να κλείσετε τον σαρωτή, κάντε κλικ στο κουμπί \"X\".",
        "el": "Όλα τα σαρωμένα προϊόντα έχουν υποβληθεί με επιτυχία! Για να κλείσετε τον σαρωτή, κάντε κλικ στο κουμπί \"X\".",
        "en-GB": "All scanned products have been submitted successfully! Please click the \"X\" button to close the scanner.",
        "es-ES": "Todos los productos escaneados se han enviado correctamente. Haz clic en el botón \"X\" para cerrar este escáner.",
        "es": "Todos los productos escaneados se han enviado correctamente. Haz clic en el botón \"X\" para cerrar este escáner.",
        "fi-FI": "Kaikki skannatut tuotteet on lisätty onnistuneesti! Valitse 'X', kun haluat sulkea skannerin.",
        "fr-FR": "Tous les produits scannés ont été soumis avec succès ! Cliquez sur le bouton « X » pour fermer le scanner.",
        "fr": "Tous les produits scannés ont été soumis avec succès ! Cliquez sur le bouton « X » pour fermer le scanner.",
        "he-IL": "כל המוצרים הסרוקים נשלחו בהצלחה! לחצו על הלחצן 'X' כדי לסגור את הסורק.",
        "hr-HR": "Svi skenirani proizvodi uspješno su poslani! Kliknite na gumb „X” za zatvaranje skenera.",
        "hu-HU": "Minden beolvasott termék beküldése sikeres volt! A beolvasó bezárásához kattints az „X” gombra.",
        "hu": "Minden beolvasott termék beküldése sikeres volt! A beolvasó bezárásához kattints az „X” gombra.",
        "it-IT": "Tutti i prodotti scansionati sono stati inviati correttamente. Clicca sulla \"X\" per chiudere lo scanner.",
        "it": "Tutti i prodotti scansionati sono stati inviati correttamente. Clicca sulla \"X\" per chiudere lo scanner.",
        "ja-JP": "スキャン済みの商品はすべて正常に送信されました。 「X」ボタンをクリックして、スキャナーを閉じてください。",
        "ja": "スキャン済みの商品はすべて正常に送信されました。 「X」ボタンをクリックして、スキャナーを閉じてください。",
        "ko-KR": "스캔한 제품이 모두 성공적으로 제출되었습니다! 스캐너를 종료하려면 'X' 버튼을 클릭하세요.",
        "ko": "스캔한 제품이 모두 성공적으로 제출되었습니다! 스캐너를 종료하려면 'X' 버튼을 클릭하세요.",
        "nl-NL": "Alle gescande producten zijn doorgestuurd. Klik op de 'X' om de scanner te sluiten.",
        "nl": "Alle gescande producten zijn doorgestuurd. Klik op de 'X' om de scanner te sluiten.",
        "no-NO": "Alle skannede produkter er sendt inn. Klikk på «X»-knappen for å lukke skanneren.",
        "pl-PL": "Wszystkie zeskanowane produkty zostały przesłane. Kliknij przycisk „X”, by zamknąć skaner.",
        "pl": "Wszystkie zeskanowane produkty zostały przesłane. Kliknij przycisk „X”, by zamknąć skaner.",
        "pt-BR": "Todos os produtos escaneados foram enviados. Clique no botão “X” para fechar o escaneamento.",
        "pt-PT": "Todos os produtos lidos foram enviados com sucesso! Clique no botão \"X\" para fechar o leitor.",
        "ru-RU": "Все отсканированные товары успешно отправлены! Нажмите кнопку X, чтобы завершить работу сканера.",
        "ru": "Все отсканированные товары успешно отправлены! Нажмите кнопку X, чтобы завершить работу сканера.",
        "sv-SE": "Alla skannade produkter har registrerats korrekt. Klicka på knappen X för att stänga skannern.",
        "sv": "Alla skannade produkter har registrerats korrekt. Klicka på knappen X för att stänga skannern.",
        "th-TH": "ส่งสินค้าทุกชิ้นที่สแกนสำเร็จแล้ว! โปรดคลิกปุ่ม \"X\" เพื่อปิดเครื่องสแกน",
        "th": "ส่งสินค้าทุกชิ้นที่สแกนสำเร็จแล้ว! โปรดคลิกปุ่ม \"X\" เพื่อปิดเครื่องสแกน",
        "tr-TR": "Taranan tüm ürünler başarıyla gönderildi! Lütfen tarayıcıyı kapatmak için \"X\" düğmesine tıkla.",
        "tr": "Taranan tüm ürünler başarıyla gönderildi! Lütfen tarayıcıyı kapatmak için \"X\" düğmesine tıkla."
    },
    "RL_ProductScanCancel1": {
        "key": "RL_ProductScanCancel1",
        "en": "There is 1 un-submitted item, do you want to cancel?",
        "ca-ES": "Hi ha un producte sense validar. Vols cancel·lar-lo?",
        "ca": "Hi ha un producte sense validar. Vols cancel·lar-lo?",
        "cs-CZ": "Máte 1 neodeslanou položku, chcete proces zrušit?",
        "cs": "Máte 1 neodeslanou položku, chcete proces zrušit?",
        "da-DK": "Der er 1 vare, der ikke er indsendt, vil du annullere?",
        "da": "Der er 1 vare, der ikke er indsendt, vil du annullere?",
        "de-DE": "Es gibt ein nicht abgesendetes Produkt, möchtest du abbrechen?",
        "de": "Es gibt ein nicht abgesendetes Produkt, möchtest du abbrechen?",
        "el-GR": "Υπάρχει 1 είδος που δεν έχει υποβληθεί, θέλετε να γίνει ακύρωση;",
        "el": "Υπάρχει 1 είδος που δεν έχει υποβληθεί, θέλετε να γίνει ακύρωση;",
        "en-GB": "There is 1 unsubmitted item, do you want to cancel?",
        "es-ES": "Hay un producto sin enviar. ¿Quieres cancelarlo?",
        "es": "Hay un producto sin enviar. ¿Quieres cancelarlo?",
        "fi-FI": "Yksi tuote on lisäämättä, haluatko peruuttaa?",
        "fr-FR": "Il reste 1 article non soumis, souhaitez-vous annuler ?",
        "fr": "Il reste 1 article non soumis, souhaitez-vous annuler ?",
        "he-IL": "יש פריט 1 שלא נשלח, האם לבטל?",
        "hr-HR": "Jedan artikl nije poslan. Želite li odustati?",
        "hu-HU": "Találtunk 1 nem beküldött cikket. Megszakítod?",
        "hu": "Találtunk 1 nem beküldött cikket. Megszakítod?",
        "it-IT": "È presente 1 articolo non inviato, vuoi annullare?",
        "it": "È presente 1 articolo non inviato, vuoi annullare?",
        "ja-JP": "未送信の商品が1件あります。キャンセルしますか？",
        "ja": "未送信の商品が1件あります。キャンセルしますか？",
        "ko-KR": "제출되지 않은 제품이 1개 있습니다. 취소를 원하시나요?",
        "ko": "제출되지 않은 제품이 1개 있습니다. 취소를 원하시나요?",
        "nl-NL": "Er is 1 item niet doorgestuurd, wil je annuleren?",
        "nl": "Er is 1 item niet doorgestuurd, wil je annuleren?",
        "no-NO": "Det er 1 vare som ikke er sendt inn. Vil du avbryte?",
        "pl-PL": "Nie przesłano 1 produktu, czy chcesz anulować?",
        "pl": "Nie przesłano 1 produktu, czy chcesz anulować?",
        "pt-BR": "Há 1 item não enviado. Deseja cancelar?",
        "pt-PT": "Um artigo não foi enviado. Pretende cancelar?",
        "ru-RU": "Не отправлен один товар. Отменить?",
        "ru": "Не отправлен один товар. Отменить?",
        "sv-SE": "1 produkt har inte registrerats. Vill du avbryta?",
        "sv": "1 produkt har inte registrerats. Vill du avbryta?",
        "th-TH": "มี 1 รายการที่ยังไม่ได้ส่ง คุณต้องการยกเลิกใช่หรือไม่",
        "th": "มี 1 รายการที่ยังไม่ได้ส่ง คุณต้องการยกเลิกใช่หรือไม่",
        "tr-TR": "Gönderilmemiş 1 öğe var, iptal etmek istiyor musun?",
        "tr": "Gönderilmemiş 1 öğe var, iptal etmek istiyor musun?"
    },
    "RL_ProductScanCancel2": {
        "key": "RL_ProductScanCancel2",
        "en": "There are {{1}} un-submitted items, do you want to cancel?",
        "ca-ES": "Hi ha {{1}} productes sense validar. Vols cancel·lar-los?",
        "ca": "Hi ha {{1}} productes sense validar. Vols cancel·lar-los?",
        "cs-CZ": "Máte {{1}} neodeslané položky, chcete proces zrušit?",
        "cs": "Máte {{1}} neodeslané položky, chcete proces zrušit?",
        "da-DK": "Der er {{1}} varer, der ikke er indsendt, vil du annullere?",
        "da": "Der er {{1}} varer, der ikke er indsendt, vil du annullere?",
        "de-DE": "Es gibt {{1}} nicht abgesendete Produkte, möchtest du abbrechen?",
        "de": "Es gibt {{1}} nicht abgesendete Produkte, möchtest du abbrechen?",
        "el-GR": "Υπάρχουν {{1}} είδη που δεν έχουν υποβληθεί, θέλετε να γίνει ακύρωση;",
        "el": "Υπάρχουν {{1}} είδη που δεν έχουν υποβληθεί, θέλετε να γίνει ακύρωση;",
        "en-GB": "There are {{1}} unsubmitted items, do you want to cancel?",
        "es-ES": "Hay {{1}} productos sin enviar. ¿Quieres cancelarlos?",
        "es": "Hay {{1}} productos sin enviar. ¿Quieres cancelarlos?",
        "fi-FI": "{{1}} tuotetta on lisäämättä, haluatko peruuttaa?",
        "fr-FR": "Il reste {{1}} articles non soumis, souhaitez-vous annuler ?",
        "fr": "Il reste {{1}} articles non soumis, souhaitez-vous annuler ?",
        "he-IL": "יש {{1}} פריטים שלא נשלחו, האם לבטל?",
        "hr-HR": "Postoji artikli (njih {{1}}) koji nisu poslani. Želite li odustati?",
        "hu-HU": "Találtunk {{1}} nem beküldött cikket. Megszakítod?",
        "hu": "Találtunk {{1}} nem beküldött cikket. Megszakítod?",
        "it-IT": "Sono presenti {{1}} articoli non inviati, vuoi annullare?",
        "it": "Sono presenti {{1}} articoli non inviati, vuoi annullare?",
        "ja-JP": "未送信の商品が{{1}}件あります。キャンセルしますか？",
        "ja": "未送信の商品が{{1}}件あります。キャンセルしますか？",
        "ko-KR": "제출되지 않은 제품이 {{1}}개 있습니다. 취소를 원하시나요?",
        "ko": "제출되지 않은 제품이 {{1}}개 있습니다. 취소를 원하시나요?",
        "nl-NL": "Er zijn {{1}} items niet doorgestuurd, wil je annuleren?",
        "nl": "Er zijn {{1}} items niet doorgestuurd, wil je annuleren?",
        "no-NO": "Det er {{1}} varer som ikke er sendt inn. Vil du avbryte?",
        "pl-PL": "Produkty: {{1}} nie zostały przesłane, czy chcesz anulować?",
        "pl": "Produkty: {{1}} nie zostały przesłane, czy chcesz anulować?",
        "pt-BR": "Há {{1}} itens não enviados. Deseja cancelar?",
        "pt-PT": "{{1}} artigos não foram enviados. Pretende cancelar?",
        "ru-RU": "Не отправлено товаров: {{1}}. Отменить?",
        "ru": "Не отправлено товаров: {{1}}. Отменить?",
        "sv-SE": "{{1}} produkter har inte registrerats. Vill du avbryta?",
        "sv": "{{1}} produkter har inte registrerats. Vill du avbryta?",
        "th-TH": "มี {{1}} รายการที่ยังไม่ได้ส่ง คุณต้องการยกเลิกใช่หรือไม่",
        "th": "มี {{1}} รายการที่ยังไม่ได้ส่ง คุณต้องการยกเลิกใช่หรือไม่",
        "tr-TR": "Gönderilmemiş {{1}} öğe var, iptal etmek istiyor musun?",
        "tr": "Gönderilmemiş {{1}} öğe var, iptal etmek istiyor musun?"
    },
    "RL_ProductAddSuccess1": {
        "key": "RL_ProductAddSuccess1",
        "en": "Promotion {{1}} added! Please click the \"X\" button to close this form.",
        "cs-CZ": "Promoakce {{1}} byla přidána. Tenhle formulář zavřete klepnutím na symbol „X“.",
        "cs": "Promoakce {{1}} byla přidána. Tenhle formulář zavřete klepnutím na symbol „X“.",
        "da-DK": "{{1}}-kampagnen er tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "da": "{{1}}-kampagnen er tilføjet. Klik på \"X\"-knappen for at lukke denne formular.",
        "de-DE": "Angebot {{1}} hinzugefügt! Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "de": "Angebot {{1}} hinzugefügt! Klicke auf die Schaltfläche \"X\", um dieses Formular zu schließen.",
        "el-GR": "Η προσφορά {{1}} προστέθηκε! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "el": "Η προσφορά {{1}} προστέθηκε! Για να κλείσετε αυτήν τη φόρμα, κάντε κλικ στο κουμπί \"X\".",
        "en-GB": "Promotion {{1}} added! Please click the \"X\" button to close this form.",
        "es-ES": "Promoción {{1}} añadida. Haz clic en el botón \"X\" para cerrar este formulario.",
        "es": "Promoción {{1}} añadida. Haz clic en el botón \"X\" para cerrar este formulario.",
        "fi-FI": "Tarjouskampanja {{1}} lisätty! Valitse 'X', kun haluat sulkea lomakkeen.",
        "fr-FR": "Promotion {{1}} ajoutée ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "fr": "Promotion {{1}} ajoutée ! Cliquez sur le bouton « X » pour fermer ce formulaire.",
        "he-IL": "הוספתם את המבצע {{1}}! לחצו על הלחצן 'X' כדי לסגור את הטופס.",
        "hr-HR": "Promocija {{1}} je dodana! Kliknite na gumb „X” za zatvaranje ovog obrasca.",
        "hu-HU": "{{1}} promóció hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "hu": "{{1}} promóció hozzáadva! Az űrlap bezárásához kattints az „X” gombra.",
        "it-IT": "Promozione {{1}} aggiunta. Clicca sulla \"X\" per chiudere questo modulo.",
        "it": "Promozione {{1}} aggiunta. Clicca sulla \"X\" per chiudere questo modulo.",
        "ja-JP": "プロモーション{{1}}を追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ja": "プロモーション{{1}}を追加しました。 「X」ボタンをクリックして、このフォームを閉じてください。",
        "ko-KR": "{{1}} 프로모션이 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "ko": "{{1}} 프로모션이 추가되었습니다! 이 양식을 닫으려면 'X' 버튼을 클릭하세요.",
        "nl-NL": "Promotie {{1}} toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "nl": "Promotie {{1}} toegevoegd. Klik op de 'X' om dit formulier te sluiten.",
        "no-NO": "Kampanje {{1}} er lagt til. Klikk på «X»-knappen for å lukke dette skjemaet.",
        "pl-PL": "Dodana promocja: {{1}}. Kliknij przycisk „X”, aby zamknąć ten formularz.",
        "pl": "Dodana promocja: {{1}}. Kliknij przycisk „X”, aby zamknąć ten formularz.",
        "pt-BR": "Promoção {{1}} adicionada! Clique no botão “X” para fechar este formulário.",
        "pt-PT": "Promoção {{1}} adicionada! Clique no botão \"X\" para fechar este formulário.",
        "ru-RU": "Акция {{1}} добавлена! Нажмите кнопку X, чтобы закрыть эту форму.",
        "ru": "Акция {{1}} добавлена! Нажмите кнопку X, чтобы закрыть эту форму.",
        "sv-SE": "Kampanj {{1}} har lagts till. Klicka på knappen X för att stänga formuläret.",
        "sv": "Kampanj {{1}} har lagts till. Klicka på knappen X för att stänga formuläret.",
        "th-TH": "เพิ่มโปรโมชัน {{1}} แล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "th": "เพิ่มโปรโมชัน {{1}} แล้ว โปรดคลิกปุ่ม \"X\" เพื่อปิดแบบฟอร์มนี้",
        "tr-TR": "{{1}} promosyonu eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla.",
        "tr": "{{1}} promosyonu eklendi! Lütfen bu formu kapatmak için \"X\" düğmesine tıkla."
    },
    "RL_CodesValidation": {
        "key": "RL_CodesValidation",
        "en": "Only {{1}} codes at a time.",
        "ca-ES": "Només es poden introduir {{1}} codis alhora.",
        "ca": "Només es poden introduir {{1}} codis alhora.",
        "cs-CZ": "Jenom {{1}} kódy najednou.",
        "cs": "Jenom {{1}} kódy najednou.",
        "da-DK": "Kun {{1}} koder ad gangen.",
        "da": "Kun {{1}} koder ad gangen.",
        "de-DE": "Nur {{1}} Codes gleichzeitig.",
        "de": "Nur {{1}} Codes gleichzeitig.",
        "el-GR": "Μόνο {{1}} κωδικοί τη φορά.",
        "el": "Μόνο {{1}} κωδικοί τη φορά.",
        "en-GB": "Only {{1}} codes at a time.",
        "es-ES": "Solo {{1}} códigos al mismo tiempo.",
        "es": "Solo {{1}} códigos al mismo tiempo.",
        "fi-FI": "Vain {{1}} koodia kerrallaan.",
        "fr-FR": "Seulement {{1}} codes à la fois.",
        "fr": "Seulement {{1}} codes à la fois.",
        "he-IL": "רק {{1}} קודים בכל פעם.",
        "hr-HR": "Broj šifri koji se može odjednom dodati: {{1}}.",
        "hu-HU": "Egyszerre csak {{1}} kód.",
        "hu": "Egyszerre csak {{1}} kód.",
        "it-IT": "Solo {{1}} codici per volta.",
        "it": "Solo {{1}} codici per volta.",
        "ja-JP": "一度に使用できるコードは{{1}}件のみです。",
        "ja": "一度に使用できるコードは{{1}}件のみです。",
        "ko-KR": "한 번에 {{1}}개 코드만 사용할 수 있습니다.",
        "ko": "한 번에 {{1}}개 코드만 사용할 수 있습니다.",
        "nl-NL": "Slechts {{1}} codes tegelijk.",
        "nl": "Slechts {{1}} codes tegelijk.",
        "no-NO": "Bare {{1}} koder om gangen.",
        "pl-PL": "Kody do wpisania jednocześnie: {{1}}.",
        "pl": "Kody do wpisania jednocześnie: {{1}}.",
        "pt-BR": "Somente {{1}} códigos por vez.",
        "pt-PT": "Apenas {{1}} códigos de cada vez.",
        "ru-RU": "Максимальное количество кодов за раз: {{1}}.",
        "ru": "Максимальное количество кодов за раз: {{1}}.",
        "sv-SE": "Endast {{1}} koder samtidigt.",
        "sv": "Endast {{1}} koder samtidigt.",
        "th-TH": "ใช้ {{1}} โค้ดต่อครั้งเท่านั้น",
        "th": "ใช้ {{1}} โค้ดต่อครั้งเท่านั้น",
        "tr-TR": "Tek seferde yalnızca {{1}} kod kullanılabilir.",
        "tr": "Tek seferde yalnızca {{1}} kod kullanılabilir."
    },
    "RL_GTINValidation": {
        "key": "RL_GTINValidation",
        "en": "GTIN must be 14 digits.",
        "ca-ES": "El GTIN ha de tenir 14 dígits.",
        "ca": "El GTIN ha de tenir 14 dígits.",
        "cs-CZ": "GTIN musí mít 14 číslic.",
        "cs": "GTIN musí mít 14 číslic.",
        "da-DK": "GTIN skal indeholde 14 cifre.",
        "da": "GTIN skal indeholde 14 cifre.",
        "de-DE": "GTIN muss 14-stellig sein.",
        "de": "GTIN muss 14-stellig sein.",
        "el-GR": "Το GTIN πρέπει να αποτελείται από 14 ψηφία.",
        "el": "Το GTIN πρέπει να αποτελείται από 14 ψηφία.",
        "en-GB": "GTIN must be 14 digits.",
        "es-ES": "El GTIN debe tener 14 dígitos.",
        "es": "El GTIN debe tener 14 dígitos.",
        "fi-FI": "GTIN:n on oltava 14 merkin pituinen.",
        "fr-FR": "Le numéro GTIN doit contenir 14 chiffres.",
        "fr": "Le numéro GTIN doit contenir 14 chiffres.",
        "he-IL": "GTIN חייב להיות באורך 14 ספרות.",
        "hr-HR": "GTIN mora sadržavati 14 znamenki.",
        "hu-HU": "A GTIN 14 számjegy hosszúságú kell, hogy legyen.",
        "hu": "A GTIN 14 számjegy hosszúságú kell, hogy legyen.",
        "it-IT": "GTIN deve contenere 14 cifre.",
        "it": "GTIN deve contenere 14 cifre.",
        "ja-JP": "GTINは14桁にしてください。",
        "ja": "GTINは14桁にしてください。",
        "ko-KR": "GTIN은 14자리 숫자로 입력해야 합니다.",
        "ko": "GTIN은 14자리 숫자로 입력해야 합니다.",
        "nl-NL": "GTIN moet 14 cijfers zijn.",
        "nl": "GTIN moet 14 cijfers zijn.",
        "no-NO": "GTIN må inneholde 14 sifre.",
        "pl-PL": "Kod GTIN musi zawierać 14 cyfr.",
        "pl": "Kod GTIN musi zawierać 14 cyfr.",
        "pt-BR": "O GTIN deve ter 14 dígitos.",
        "pt-PT": "O GTIN tem de ter 14 dígitos.",
        "ru-RU": "Номер GTIN должен состоять из 14 цифр.",
        "ru": "Номер GTIN должен состоять из 14 цифр.",
        "sv-SE": "GTIN måste innehålla 14 siffror.",
        "sv": "GTIN måste innehålla 14 siffror.",
        "th-TH": "GTIN ต้องมี 14 หลัก",
        "th": "GTIN ต้องมี 14 หลัก",
        "tr-TR": "GTIN, 14 haneli olmalıdır.",
        "tr": "GTIN, 14 haneli olmalıdır."
    },
    "RL_StyleColorValidation": {
        "key": "RL_StyleColorValidation",
        "en": "Style Color must be 5 or 6 alphanumeric values, hyphen, 3 alphanumeric values.",
        "ca-ES": "El color del model ha de tenir cinc o sis valors alfanumèrics, un guionet i tres valors alfanumèrics.",
        "ca": "El color del model ha de tenir cinc o sis valors alfanumèrics, un guionet i tres valors alfanumèrics.",
        "cs-CZ": "Barva modelu musí obsahovat 5 nebo 6 alfanumerických znaků, spojovník, 3 alfanumerické znaky.",
        "cs": "Barva modelu musí obsahovat 5 nebo 6 alfanumerických znaků, spojovník, 3 alfanumerické znaky.",
        "da-DK": "Modelfarven skal være 5 eller 6 alfanumeriske tegn, bindestreg, 3 alfanumeriske tegn.",
        "da": "Modelfarven skal være 5 eller 6 alfanumeriske tegn, bindestreg, 3 alfanumeriske tegn.",
        "de-DE": "Die Style-Farbe darf nur aus fünf oder sechs alphanumerischen Werten, Bindestrich, drei alphanumerischen Werten bestehen.",
        "de": "Die Style-Farbe darf nur aus fünf oder sechs alphanumerischen Werten, Bindestrich, drei alphanumerischen Werten bestehen.",
        "el-GR": "Το στιλ χρώματος πρέπει να αποτελείται από 5 ή 6 αλφαριθμητικές τιμές, ενωτικό, 3 αλφαριθμητικές τιμές.",
        "el": "Το στιλ χρώματος πρέπει να αποτελείται από 5 ή 6 αλφαριθμητικές τιμές, ενωτικό, 3 αλφαριθμητικές τιμές.",
        "en-GB": "Style Colour must be 5 or 6 alphanumeric characters, hyphen, 3 alphanumeric characters.",
        "es-ES": "El color del modelo debe tener 5 o 6 valores alfanuméricos, un guion y 3 valores alfanuméricos.",
        "es": "El color del modelo debe tener 5 o 6 valores alfanuméricos, un guion y 3 valores alfanuméricos.",
        "fi-FI": "Tyylin värin on oltava viisi tai kuusi aakkosnumeerista arvoa, yhdysviiva ja kolme aakkosnumeerista arvoa.",
        "fr-FR": "Le Style-Coloris se compose de 5 ou 6 caractères alphanumériques suivis d'un tiret et de 3 caractères alphanumériques.",
        "fr": "Le Style-Coloris se compose de 5 ou 6 caractères alphanumériques suivis d'un tiret et de 3 caractères alphanumériques.",
        "he-IL": "סגנון-צבע חייב להכיל 5 או 6 ערכים אלפאנומריים, מקף, 3 ערכים אלפאנומריים.",
        "hr-HR": "Model/boja mora sadržavati 5 ili 6 alfanumeričkih vrijednosti, crticu, 3 alfanumeričke vrijednosti.",
        "hu-HU": "A Style Color helyes formátuma: 5 vagy 6 alfanumerikus érték, kötőjel, 3 alfanumerikus érték.",
        "hu": "A Style Color helyes formátuma: 5 vagy 6 alfanumerikus érték, kötőjel, 3 alfanumerikus érték.",
        "it-IT": "Il colore del modello deve contenere 5 o 6 caratteri alfanumerici, trattino, 3 caratteri alfanumerici.",
        "it": "Il colore del modello deve contenere 5 o 6 caratteri alfanumerici, trattino, 3 caratteri alfanumerici.",
        "ja-JP": "スタイル カラーは、5または6つの英数字、ハイフン、3つの英数字にしてください。",
        "ja": "スタイル カラーは、5または6つの英数字、ハイフン、3つの英数字にしてください。",
        "ko-KR": "스타일 컬러는 영숫자 5~6자, 하이픈, 영숫자 3자로만 이루어져야 합니다.",
        "ko": "스타일 컬러는 영숫자 5~6자, 하이픈, 영숫자 3자로만 이루어져야 합니다.",
        "nl-NL": "Stijlkleur moet bestaan uit 5 of 6 alfanumerieke tekens, koppelteken, 3 alfanumerieke tekens.",
        "nl": "Stijlkleur moet bestaan uit 5 of 6 alfanumerieke tekens, koppelteken, 3 alfanumerieke tekens.",
        "no-NO": "Produktfarge må være 5 eller 6 alfanumeriske verdier, bindestrek, 3 alfanumeriske verdier.",
        "pl-PL": "Model/kolor musi mieć 5 lub 6 znaków alfanumerycznych, łącznik, 3 znaki alfanumeryczne.",
        "pl": "Model/kolor musi mieć 5 lub 6 znaków alfanumerycznych, łącznik, 3 znaki alfanumeryczne.",
        "pt-BR": "A cor do estilo deve ter 5 ou 6 valores alfanuméricos, hífen e 3 valores alfanuméricos.",
        "pt-PT": "O estilo-cor tem de ter cinco ou seis valores alfanuméricos, um hífen e três valores alfanuméricos.",
        "ru-RU": "Название цвета модели должно состоять из 5 или 6 букв или цифр, дефиса и 3 букв или цифр.",
        "ru": "Название цвета модели должно состоять из 5 или 6 букв или цифр, дефиса и 3 букв или цифр.",
        "sv-SE": "Modellfärgen måste ha 5 eller 6 alfanumeriska tecken, bindestreck, 3 alfanumeriska tecken.",
        "sv": "Modellfärgen måste ha 5 eller 6 alfanumeriska tecken, bindestreck, 3 alfanumeriska tecken.",
        "th-TH": "สีสไตล์ต้องประกอบด้วยตัวเลขและตัวอักษร 5 หรือ 6 อักขระ, เครื่องหมายขีด, ตัวเลขและตัวอักษร 3 อักขระ",
        "th": "สีสไตล์ต้องประกอบด้วยตัวเลขและตัวอักษร 5 หรือ 6 อักขระ, เครื่องหมายขีด, ตัวเลขและตัวอักษร 3 อักขระ",
        "tr-TR": "Stil/Renk; 5 veya 6 alfasayısal değer, tire ve 3 alfasayısal değerden oluşmalıdır.",
        "tr": "Stil/Renk; 5 veya 6 alfasayısal değer, tire ve 3 alfasayısal değerden oluşmalıdır."
    },
    "RL_MoreInformation": {
        "key": "RL_MoreInformation",
        "en": "More Information",
        "ca-ES": "Més informació",
        "ca": "Més informació",
        "cs-CZ": "Další informace",
        "cs": "Další informace",
        "da-DK": "Flere oplysninger",
        "da": "Flere oplysninger",
        "de-DE": "Weitere Informationen",
        "de": "Weitere Informationen",
        "el-GR": "Περισσότερες πληροφορίες",
        "el": "Περισσότερες πληροφορίες",
        "en-GB": "More Information",
        "es-ES": "Más información",
        "es": "Más información",
        "fi-FI": "Lisätietoja",
        "fr-FR": "Plus d'informations",
        "fr": "Plus d'informations",
        "he-IL": "מידע נוסף",
        "hr-HR": "Dodatne informacije",
        "hu-HU": "További információk",
        "hu": "További információk",
        "it-IT": "Ulteriori informazioni",
        "it": "Ulteriori informazioni",
        "ja-JP": "詳細を見る",
        "ja": "詳細を見る",
        "ko-KR": "자세히 보기",
        "ko": "자세히 보기",
        "nl-NL": "Meer info",
        "nl": "Meer info",
        "no-NO": "Mer informasjon",
        "pl-PL": "Więcej informacji",
        "pl": "Więcej informacji",
        "pt-BR": "Mais informações",
        "pt-PT": "Mais informações",
        "ru-RU": "Подробнее",
        "ru": "Подробнее",
        "sv-SE": "Mer information",
        "sv": "Mer information",
        "th-TH": "ข้อมูลเพิ่มเติม",
        "th": "ข้อมูลเพิ่มเติม",
        "tr-TR": "Daha Fazla Bilgi",
        "tr": "Daha Fazla Bilgi"
    },
    "RL_ScannerError": {
        "key": "RL_ScannerError",
        "en": "Scanner Error: {{1}}",
        "ca-ES": "Error de l'escàner: {{1}}",
        "ca": "Error de l'escàner: {{1}}",
        "cs-CZ": "Chyba skeneru: {{1}}",
        "cs": "Chyba skeneru: {{1}}",
        "da-DK": "Scannerfejl: {{1}}",
        "da": "Scannerfejl: {{1}}",
        "de-DE": "Scannerfehler: {{1}}",
        "de": "Scannerfehler: {{1}}",
        "el-GR": "Σφάλμα σαρωτή: {{1}}",
        "el": "Σφάλμα σαρωτή: {{1}}",
        "en-GB": "Scanner Error: {{1}}",
        "es-ES": "Error de escáner: {{1}}",
        "es": "Error de escáner: {{1}}",
        "fi-FI": "Skannerin virhe: {{1}}",
        "fr-FR": "Erreur scanner : {{1}}",
        "fr": "Erreur scanner : {{1}}",
        "he-IL": "שגיאת סורק: {{1}}",
        "hr-HR": "Pogreška skenera: {{1}}",
        "hu-HU": "Beolvasási hiba: {{1}}",
        "hu": "Beolvasási hiba: {{1}}",
        "it-IT": "Errore scanner: {{1}}",
        "it": "Errore scanner: {{1}}",
        "ja-JP": "スキャナーのエラー：{{1}}",
        "ja": "スキャナーのエラー：{{1}}",
        "ko-KR": "스캐너 오류: {{1}}",
        "ko": "스캐너 오류: {{1}}",
        "nl-NL": "Scannerfout: {{1}}",
        "nl": "Scannerfout: {{1}}",
        "no-NO": "Skannerfeil: {{1}}",
        "pl-PL": "Błąd skanera: {{1}}",
        "pl": "Błąd skanera: {{1}}",
        "pt-BR": "Erro do escaneamento: {{1}}",
        "pt-PT": "Erro do leitor: {{1}}",
        "ru-RU": "Ошибка сканера: {{1}}",
        "ru": "Ошибка сканера: {{1}}",
        "sv-SE": "Skannerfel: {{1}}",
        "sv": "Skannerfel: {{1}}",
        "th-TH": "เครื่องสแกนผิดพลาด: {{1}}",
        "th": "เครื่องสแกนผิดพลาด: {{1}}",
        "tr-TR": "Tarayıcı Hatası: {{1}}",
        "tr": "Tarayıcı Hatası: {{1}}"
    },
    "RL_ProductAddSuccess3": {
        "key": "RL_ProductAddSuccess3",
        "en": "1 product has been added successfully.",
        "ca-ES": "S'ha afegit un producte correctament.",
        "ca": "S'ha afegit un producte correctament.",
        "cs-CZ": "1 produkt byl úspěšně přidán.",
        "cs": "1 produkt byl úspěšně přidán.",
        "da-DK": "1 produkt er blevet tilføjet.",
        "da": "1 produkt er blevet tilføjet.",
        "de-DE": "Ein Produkt wurde erfolgreich hinzugefügt.",
        "de": "Ein Produkt wurde erfolgreich hinzugefügt.",
        "el-GR": "1 προϊόν προστέθηκε με επιτυχία.",
        "el": "1 προϊόν προστέθηκε με επιτυχία.",
        "en-GB": "1 product has been added successfully.",
        "es-ES": "Se ha añadido un producto correctamente.",
        "es": "Se ha añadido un producto correctamente.",
        "fi-FI": "Yksi tuote on lisätty.",
        "fr-FR": "1 produit a été ajouté avec succès.",
        "fr": "1 produit a été ajouté avec succès.",
        "he-IL": "מוצר 1 נוסף בהצלחה.",
        "hr-HR": "Jedan je proizvod uspješno dodan.",
        "hu-HU": "1 termék hozzáadása sikeres volt.",
        "hu": "1 termék hozzáadása sikeres volt.",
        "it-IT": "1 prodotto è stato aggiunto.",
        "it": "1 prodotto è stato aggiunto.",
        "ja-JP": "1件の商品が正常に追加されました。",
        "ja": "1件の商品が正常に追加されました。",
        "ko-KR": "1개 제품이 성공적으로 추가되었습니다.",
        "ko": "1개 제품이 성공적으로 추가되었습니다.",
        "nl-NL": "Er is 1 product toegevoegd.",
        "nl": "Er is 1 product toegevoegd.",
        "no-NO": "1 produkt er lagt til.",
        "pl-PL": "Dodano 1 produkt.",
        "pl": "Dodano 1 produkt.",
        "pt-BR": "1 produto adicionado.",
        "pt-PT": "Foi adicionado um produto com sucesso.",
        "ru-RU": "Успешно добавлен один товар.",
        "ru": "Успешно добавлен один товар.",
        "sv-SE": "1 produkt har lagts till.",
        "sv": "1 produkt har lagts till.",
        "th-TH": "เพิ่มสินค้า 1 รายการสำเร็จแล้ว",
        "th": "เพิ่มสินค้า 1 รายการสำเร็จแล้ว",
        "tr-TR": "1 ürün başarıyla eklendi.",
        "tr": "1 ürün başarıyla eklendi."
    },
    "RL_ProductAddSuccess4": {
        "key": "RL_ProductAddSuccess4",
        "en": "{{1}} products have been added successfully.",
        "ca-ES": "S'han afegit {{1}} productes correctament.",
        "ca": "S'han afegit {{1}} productes correctament.",
        "cs-CZ": "{{1}} produkty byly úspěšně přidány.",
        "cs": "{{1}} produkty byly úspěšně přidány.",
        "da-DK": "{{1}} produkter er blevet tilføjet.",
        "da": "{{1}} produkter er blevet tilføjet.",
        "de-DE": "{{1}} Produkte wurden erfolgreich hinzugefügt.",
        "de": "{{1}} Produkte wurden erfolgreich hinzugefügt.",
        "el-GR": "{{1}} προϊόντα προστέθηκαν με επιτυχία.",
        "el": "{{1}} προϊόντα προστέθηκαν με επιτυχία.",
        "en-GB": "{{1}} products have been added successfully.",
        "es-ES": "Se han añadido {{1}} productos correctamente.",
        "es": "Se han añadido {{1}} productos correctamente.",
        "fi-FI": "{{1}} tuotetta on lisätty.",
        "fr-FR": "{{1}} produits ont été ajoutés avec succès.",
        "fr": "{{1}} produits ont été ajoutés avec succès.",
        "he-IL": "{{1}} מוצרים נוספו בהצלחה.",
        "hr-HR": "Proizvodi (njih {{1}}) uspješno su dodani.",
        "hu-HU": "{{1}} termék hozzáadása sikeres volt.",
        "hu": "{{1}} termék hozzáadása sikeres volt.",
        "it-IT": "{{1}} prodotti sono stati aggiunti.",
        "it": "{{1}} prodotti sono stati aggiunti.",
        "ja-JP": "{{1}}件の商品が正常に追加されました。",
        "ja": "{{1}}件の商品が正常に追加されました。",
        "ko-KR": "{{1}}개 제품이 성공적으로 추가되었습니다.",
        "ko": "{{1}}개 제품이 성공적으로 추가되었습니다.",
        "nl-NL": "Er zijn {{1}} producten toegevoegd.",
        "nl": "Er zijn {{1}} producten toegevoegd.",
        "no-NO": "{{1}} produkter er lagt til.",
        "pl-PL": "Dodano produkty: {{1}}.",
        "pl": "Dodano produkty: {{1}}.",
        "pt-BR": "{{1}} produtos adicionados.",
        "pt-PT": "Foram adicionados {{1}} produtos com sucesso.",
        "ru-RU": "Товаров успешно добавлено: {{1}}",
        "ru": "Товаров успешно добавлено: {{1}}",
        "sv-SE": "{{1}} produkter har lagts till.",
        "sv": "{{1}} produkter har lagts till.",
        "th-TH": "เพิ่มสินค้า {{1}} รายการสำเร็จแล้ว",
        "th": "เพิ่มสินค้า {{1}} รายการสำเร็จแล้ว",
        "tr-TR": "{{1}} ürün başarıyla eklendi.",
        "tr": "{{1}} ürün başarıyla eklendi."
    },
    "RL_ProductAddError1": {
        "key": "RL_ProductAddError1",
        "en": "1 product failed to add: {{1}}",
        "ca-ES": "No s'ha pogut afegir un producte: {{1}}",
        "ca": "No s'ha pogut afegir un producte: {{1}}",
        "cs-CZ": "1 produkt se nepodařilo přidat: {{1}}",
        "cs": "1 produkt se nepodařilo přidat: {{1}}",
        "da-DK": "1 produkt blev ikke tilføjet: {{1}}",
        "da": "1 produkt blev ikke tilføjet: {{1}}",
        "de-DE": "Ein Produkt konnte nicht hinzugefügt werden: {{1}}",
        "de": "Ein Produkt konnte nicht hinzugefügt werden: {{1}}",
        "el-GR": "Δεν ήταν δυνατή η προσθήκη 1 προϊόντος: {{1}}",
        "el": "Δεν ήταν δυνατή η προσθήκη 1 προϊόντος: {{1}}",
        "en-GB": "1 product failed to add: {{1}}",
        "es-ES": "Se ha producido un error al añadir 1 producto: {{1}}",
        "es": "Se ha producido un error al añadir 1 producto: {{1}}",
        "fi-FI": "Yhtä tuotetta ei voitu lisätä: {{1}}",
        "fr-FR": "1 produit n'a pas pu être ajouté : {{1}}",
        "fr": "1 produit n'a pas pu être ajouté : {{1}}",
        "he-IL": "נכשלה הוספה של מוצר 1: {{1}}",
        "hr-HR": "Dodavanje jednog proizvoda nije uspjelo: {{1}}",
        "hu-HU": "1 termék hozzáadása nem sikerült: {{1}}",
        "hu": "1 termék hozzáadása nem sikerült: {{1}}",
        "it-IT": "Impossibile aggiungere 1 prodotto: {{1}}",
        "it": "Impossibile aggiungere 1 prodotto: {{1}}",
        "ja-JP": "1件の商品の追加に失敗しました：{{1}}",
        "ja": "1件の商品の追加に失敗しました：{{1}}",
        "ko-KR": "1개 제품 추가 실패: {{1}}",
        "ko": "1개 제품 추가 실패: {{1}}",
        "nl-NL": "1 product is niet toegevoegd: {{1}}",
        "nl": "1 product is niet toegevoegd: {{1}}",
        "no-NO": "1 produkt kunne ikke legges til: {{1}}",
        "pl-PL": "Nie udało się dodać 1 produktu: {{1}}",
        "pl": "Nie udało się dodać 1 produktu: {{1}}",
        "pt-BR": "Falha ao adicionar 1 produto: {{1}}",
        "pt-PT": "Falha ao adicionar um produto: {{1}}",
        "ru-RU": "Не удалось добавить один товар: {{1}}",
        "ru": "Не удалось добавить один товар: {{1}}",
        "sv-SE": "1 produkt har inte lagts till.: {{1}}",
        "sv": "1 produkt har inte lagts till.: {{1}}",
        "th-TH": "ล้มเหลวในการเพิ่มสินค้า 1 รายการ: {{1}}",
        "th": "ล้มเหลวในการเพิ่มสินค้า 1 รายการ: {{1}}",
        "tr-TR": "1 ürün eklenemedi: {{1}}",
        "tr": "1 ürün eklenemedi: {{1}}"
    },
    "RL_ProductAddError2": {
        "key": "RL_ProductAddError2",
        "en": "{{1}} products failed to add: {{2}}",
        "ca-ES": "No s'han pogut afegir {{1}} productes: {{2}}",
        "ca": "No s'han pogut afegir {{1}} productes: {{2}}",
        "cs-CZ": "{{1}} produkty se nepodařilo přidat: {{2}}",
        "cs": "{{1}} produkty se nepodařilo přidat: {{2}}",
        "da-DK": "{{1}} produkter blev ikke tilføjet: {{2}}",
        "da": "{{1}} produkter blev ikke tilføjet: {{2}}",
        "de-DE": "{{1}} Produkte konnten nicht hinzugefügt werden: {{2}}",
        "de": "{{1}} Produkte konnten nicht hinzugefügt werden: {{2}}",
        "el-GR": "Δεν ήταν δυνατή η προσθήκη {{1}} προϊόντων: {{2}}",
        "el": "Δεν ήταν δυνατή η προσθήκη {{1}} προϊόντων: {{2}}",
        "en-GB": "{{1}} products failed to add: {{2}}",
        "es-ES": "Se ha producido un error al añadir {{1}} productos: {{2}}",
        "es": "Se ha producido un error al añadir {{1}} productos: {{2}}",
        "fi-FI": "{{1}} tuotetta ei voitu lisätä: {{2}}",
        "fr-FR": "{{1}} produits n'ont pas pu être ajoutés : {{2}}",
        "fr": "{{1}} produits n'ont pas pu être ajoutés : {{2}}",
        "he-IL": "נכשלה הוספה של {{1}} מוצרים: {{2}}",
        "hr-HR": "Dodavanje {{1}} proizvoda nije uspjelo: {{2}}",
        "hu-HU": "{{1}} termék hozzáadása nem sikerült: {{2}}",
        "hu": "{{1}} termék hozzáadása nem sikerült: {{2}}",
        "it-IT": "Impossibile aggiungere {{1}} prodotti: {{2}}",
        "it": "Impossibile aggiungere {{1}} prodotti: {{2}}",
        "ja-JP": "{{1}}件の商品の追加に失敗しました：{{2}}",
        "ja": "{{1}}件の商品の追加に失敗しました：{{2}}",
        "ko-KR": "{{1}}개 제품 추가 실패: {{2}}",
        "ko": "{{1}}개 제품 추가 실패: {{2}}",
        "nl-NL": "{{1}} producten zijn niet toegevoegd: {{2}}",
        "nl": "{{1}} producten zijn niet toegevoegd: {{2}}",
        "no-NO": "{{1}} produkter kunne ikke legges til: {{2}}",
        "pl-PL": "Nie udało się dodać produktów: {{1}}: {{2}}",
        "pl": "Nie udało się dodać produktów: {{1}}: {{2}}",
        "pt-BR": "Falha ao adicionar {{1}} produtos: {{2}}",
        "pt-PT": "Falha ao adicionar {{1}} produtos: {{2}}",
        "ru-RU": "Не удалось добавить несколько товаров ({{1}}): {{2}}",
        "ru": "Не удалось добавить несколько товаров ({{1}}): {{2}}",
        "sv-SE": "{{1}} produkter har inte lagts till.: {{2}}",
        "sv": "{{1}} produkter har inte lagts till.: {{2}}",
        "th-TH": "ล้มเหลวในการเพิ่มสินค้า {{1}} รายการ: {{2}}",
        "th": "ล้มเหลวในการเพิ่มสินค้า {{1}} รายการ: {{2}}",
        "tr-TR": "{{1}} ürün eklenemedi: {{2}}",
        "tr": "{{1}} ürün eklenemedi: {{2}}"
    },
    "RL_ProductAddError3": {
        "key": "RL_ProductAddError3",
        "en": "You do not have permission to perform this action",
        "ca-ES": "No tens permís per dur a terme aquesta acció",
        "ca": "No tens permís per dur a terme aquesta acció",
        "cs-CZ": "K provedení téhle akce nemáte oprávnění",
        "cs": "K provedení téhle akce nemáte oprávnění",
        "da-DK": "Du har ikke tilladelse til at udføre denne handling",
        "da": "Du har ikke tilladelse til at udføre denne handling",
        "de-DE": "Du hast keine Berechtigung, diese Aktion auszuführen.",
        "de": "Du hast keine Berechtigung, diese Aktion auszuführen.",
        "el-GR": "Δεν έχετε δικαιώματα εκτέλεσης αυτής της ενέργειας.",
        "el": "Δεν έχετε δικαιώματα εκτέλεσης αυτής της ενέργειας.",
        "en-GB": "You do not have permission to perform this action",
        "es-ES": "No tienes permiso para llevar a cabo esta acción.",
        "es": "No tienes permiso para llevar a cabo esta acción.",
        "fi-FI": "Sinulla ei ole oikeutta suorittaa tätä toimintoa",
        "fr-FR": "Vous n'avez pas l'autorisation d'exécuter cette action",
        "fr": "Vous n'avez pas l'autorisation d'exécuter cette action",
        "he-IL": "אין לך הרשאה לבצע פעולה זו",
        "hr-HR": "Nemate dozvolu za izvođenje ove radnje",
        "hu-HU": "Nincs engedélyed ennek a műveletnek az elvégzésére",
        "hu": "Nincs engedélyed ennek a műveletnek az elvégzésére",
        "it-IT": "Non disponi dell'autorizzazione per eseguire questa azione",
        "it": "Non disponi dell'autorizzazione per eseguire questa azione",
        "ja-JP": "この操作を実行する権限がありません",
        "ja": "この操作を実行する権限がありません",
        "ko-KR": "이 작업을 수행할 권한이 없습니다",
        "ko": "이 작업을 수행할 권한이 없습니다",
        "nl-NL": "Je hebt geen toestemming om deze bewerking uit te voeren.",
        "nl": "Je hebt geen toestemming om deze bewerking uit te voeren.",
        "no-NO": "Du har ikke tillatelse til å utføre denne handlingen",
        "pl-PL": "Nie masz pozwolenia na wykonanie tego zadania.",
        "pl": "Nie masz pozwolenia na wykonanie tego zadania.",
        "pt-BR": "Você não tem permissão para executar esta ação",
        "pt-PT": "Não tem permissão para executar esta ação",
        "ru-RU": "У вас нет прав для выполнения этого действия",
        "ru": "У вас нет прав для выполнения этого действия",
        "sv-SE": "Du har inte behörighet att utföra den här åtgärden.",
        "sv": "Du har inte behörighet att utföra den här åtgärden.",
        "th-TH": "คุณไม่ได้รับอนุญาตให้กระทำการนี้",
        "th": "คุณไม่ได้รับอนุญาตให้กระทำการนี้",
        "tr-TR": "Bu eylemi gerçekleştirme iznine sahip değilsin",
        "tr": "Bu eylemi gerçekleştirme iznine sahip değilsin"
    },
    "RL_ProductAddError4": {
        "key": "RL_ProductAddError4",
        "en": "Request failed: \"{{1}}\" Please try again.",
        "ca-ES": "S'ha produït un error en la sol·licitud: \"{{1}}\". Torna-ho a provar.",
        "ca": "S'ha produït un error en la sol·licitud: \"{{1}}\". Torna-ho a provar.",
        "cs-CZ": "Žádost selhala: „{{1}}“ Zkuste to prosím znovu.",
        "cs": "Žádost selhala: „{{1}}“ Zkuste to prosím znovu.",
        "da-DK": "Anmodningen mislykkedes: \"{{1}}\" Prøv igen.",
        "da": "Anmodningen mislykkedes: \"{{1}}\" Prøv igen.",
        "de-DE": "Anfrage fehlgeschlagen: \"{{1}}\" Bitte versuche es erneut.",
        "de": "Anfrage fehlgeschlagen: \"{{1}}\" Bitte versuche es erneut.",
        "el-GR": "Αποτυχία αιτήματος: \"{{1}}\" Δοκιμάστε ξανά.",
        "el": "Αποτυχία αιτήματος: \"{{1}}\" Δοκιμάστε ξανά.",
        "en-GB": "Request failed: \"{{1}}\" Please try again.",
        "es-ES": "Error en la solicitud: \"{{1}}\" Vuelve a intentarlo.",
        "es": "Error en la solicitud: \"{{1}}\" Vuelve a intentarlo.",
        "fi-FI": "Pyyntö epäonnistui: \"{{1}}\" Yritä uudelleen.",
        "fr-FR": "Échec de la demande : « {{1}} » Veuillez réessayer",
        "fr": "Échec de la demande : « {{1}} » Veuillez réessayer",
        "he-IL": "הבקשה נכשלה: \"{{1}}\" נסו שוב.",
        "hr-HR": "Zahtjev nije uspio: „{{1}}”. Pokušajte ponovno.",
        "hu-HU": "Sikertelen kérés: „{{1}}” Próbáld újra.",
        "hu": "Sikertelen kérés: „{{1}}” Próbáld újra.",
        "it-IT": "Richiesta non riuscita: \"{{1}}\" Riprova.",
        "it": "Richiesta non riuscita: \"{{1}}\" Riprova.",
        "ja-JP": "リクエストの処理に失敗しました：\"{{1}}\" もう一度お試しください。",
        "ja": "リクエストの処理に失敗しました：\"{{1}}\" もう一度お試しください。",
        "ko-KR": "요청 실패: \"{{1}}\" 다시 시도해 주세요.",
        "ko": "요청 실패: \"{{1}}\" 다시 시도해 주세요.",
        "nl-NL": "Verzoek is mislukt: \"{{1}}\" Probeer het opnieuw.",
        "nl": "Verzoek is mislukt: \"{{1}}\" Probeer het opnieuw.",
        "no-NO": "Forespørsel feilet: «{{1}}» Prøv på nytt.",
        "pl-PL": "Nieudane zgłoszenie: {{1}}. Spróbuj ponownie.",
        "pl": "Nieudane zgłoszenie: {{1}}. Spróbuj ponownie.",
        "pt-BR": "Falha na solicitação: \"{{1}}\". Tente novamente",
        "pt-PT": "Falha no pedido: \"{{1}}\" Tente novamente.",
        "ru-RU": "Сбой при отправке запроса: \"{{1}}\" Повторите попытку позже.",
        "ru": "Сбой при отправке запроса: \"{{1}}\" Повторите попытку позже.",
        "sv-SE": "Begäran misslyckades: \"{{1}}\" Försök igen.",
        "sv": "Begäran misslyckades: \"{{1}}\" Försök igen.",
        "th-TH": "คำขอล้มเหลว: \"{{1}}\" โปรดลองอีกครั้ง",
        "th": "คำขอล้มเหลว: \"{{1}}\" โปรดลองอีกครั้ง",
        "tr-TR": "İstek başarısız: \"{{1}}\" Lütfen tekrar dene.",
        "tr": "İstek başarısız: \"{{1}}\" Lütfen tekrar dene."
    },
    "RL_ProductAddError5": {
        "key": "RL_ProductAddError5",
        "en": "Request failed: \"{{1}}\" {{2}}",
        "ca-ES": "S'ha produït un error en la sol·licitud: \"{{1}}\". {{2}}",
        "ca": "S'ha produït un error en la sol·licitud: \"{{1}}\". {{2}}",
        "cs-CZ": "Žádost selhala: „{{1}}“ {{2}}",
        "cs": "Žádost selhala: „{{1}}“ {{2}}",
        "da-DK": "Anmodningen mislykkedes: \"{{1}}\" {{2}}",
        "da": "Anmodningen mislykkedes: \"{{1}}\" {{2}}",
        "de-DE": "Anfrage fehlgeschlagen: \"{{1}}\" {{2}}",
        "de": "Anfrage fehlgeschlagen: \"{{1}}\" {{2}}",
        "el-GR": "Αποτυχία αιτήματος: \"{{1}}\" {{2}}",
        "el": "Αποτυχία αιτήματος: \"{{1}}\" {{2}}",
        "en-GB": "Request failed: \"{{1}}\" {{2}}",
        "es-ES": "Error en la solicitud: \"{{1}}\" {{2}}",
        "es": "Error en la solicitud: \"{{1}}\" {{2}}",
        "fi-FI": "Pyyntö epäonnistui: \"{{1}}\" {{2}}",
        "fr-FR": "Échec de la demande : « {{1}} » {{2}}",
        "fr": "Échec de la demande : « {{1}} » {{2}}",
        "he-IL": "בקשה נכשלה: \"{{1}}\" {{2}}",
        "hr-HR": "Zahtjev nije uspio: „{{1}}” {{2}}",
        "hu-HU": "Sikertelen kérés: „{{1}}” {{2}}",
        "hu": "Sikertelen kérés: „{{1}}” {{2}}",
        "it-IT": "Richiesta non riuscita: \"{{1}}\" {{2}}",
        "it": "Richiesta non riuscita: \"{{1}}\" {{2}}",
        "ja-JP": "リクエストの処理に失敗しました：\"{{1}}\" {{2}}",
        "ja": "リクエストの処理に失敗しました：\"{{1}}\" {{2}}",
        "ko-KR": "요청 실패: \"{{1}}\" {{2}}",
        "ko": "요청 실패: \"{{1}}\" {{2}}",
        "nl-NL": "Verzoek is mislukt: \"{{1}}\" {{2}}",
        "nl": "Verzoek is mislukt: \"{{1}}\" {{2}}",
        "no-NO": "Forespørsel feilet: «{{1}}» {{2}}",
        "pl-PL": "Nieudane zgłoszenie: {{1}} {{2}}",
        "pl": "Nieudane zgłoszenie: {{1}} {{2}}",
        "pt-BR": "Falha na solicitação: \"{{1}}\". {{2}}",
        "pt-PT": "Falha no pedido: \"{{1}}\" {{2}}",
        "ru-RU": "Сбой при отправке запроса: \"{{1}}\" {{2}}",
        "ru": "Сбой при отправке запроса: \"{{1}}\" {{2}}",
        "sv-SE": "Begäran misslyckades: \"{{1}}\" {{2}}",
        "sv": "Begäran misslyckades: \"{{1}}\" {{2}}",
        "th-TH": "คำขอล้มเหลว: \"{{1}}\" {{2}}",
        "th": "คำขอล้มเหลว: \"{{1}}\" {{2}}",
        "tr-TR": "İstek başarısız: \"{{1}}\" {{2}}",
        "tr": "İstek başarısız: \"{{1}}\" {{2}}"
    },
    "RL_ScanError1": {
        "key": "RL_ScanError1",
        "en": "Using the camera on this device is not supported. Please upgrade your iOS version to use the Promote App.",
        "cs-CZ": "Používání fotoaparátu na tomhle přístroji není podporováno. Aktualizujte svoji verzi iOS, abyste mohli používat aplikaci Promote.",
        "cs": "Používání fotoaparátu na tomhle přístroji není podporováno. Aktualizujte svoji verzi iOS, abyste mohli používat aplikaci Promote.",
        "da-DK": "Brug af kamera på denne enhed er ikke understøttet. Opgrader  din iOS-version for at bruge Promote App.",
        "da": "Brug af kamera på denne enhed er ikke understøttet. Opgrader  din iOS-version for at bruge Promote App.",
        "de-DE": "Die Verwendung der Kamera wird auf diesem Gerät nicht unterstützt. Bitte aktualisiere deine iOS-Version, um die Promote App zu verwenden.",
        "de": "Die Verwendung der Kamera wird auf diesem Gerät nicht unterstützt. Bitte aktualisiere deine iOS-Version, um die Promote App zu verwenden.",
        "el-GR": "Η χρήση της κάμερας σε αυτήν τη συσκευή δεν υποστηρίζεται. Για να χρησιμοποιήσετε την εφαρμογή Promote, πρέπει να αναβαθμίσετε την έκδοση iOS.",
        "el": "Η χρήση της κάμερας σε αυτήν τη συσκευή δεν υποστηρίζεται. Για να χρησιμοποιήσετε την εφαρμογή Promote, πρέπει να αναβαθμίσετε την έκδοση iOS.",
        "en-GB": "Using the camera on this device is not supported. Please upgrade your iOS version to use the Promote App.",
        "es-ES": "No se puede usar la cámara en este dispositivo. Actualiza la versión de iOS para usar Promote App.",
        "es": "No se puede usar la cámara en este dispositivo. Actualiza la versión de iOS para usar Promote App.",
        "fi-FI": "Laitteen kameraa ei tueta. Päivitä iOS-järjestelmäversiosi käyttääksesi Promote-sovellusta.",
        "fr-FR": "L'utilisation de l'appareil photo sur cet appareil n'est pas prise en charge. Veuillez mettre à jour votre version iOS pour utiliser l'application Promote.",
        "fr": "L'utilisation de l'appareil photo sur cet appareil n'est pas prise en charge. Veuillez mettre à jour votre version iOS pour utiliser l'application Promote.",
        "he-IL": "השימוש במצלמה במכשיר זה אינו נתמך. שדרגו את גרסת ה-iOS כדי להשתמש באפליקציית Promote.",
        "hr-HR": "Upotreba kamere na ovom uređaju nije podržana. Nadogradite verziju operativnog sustava iOS da biste upotrijebili aplikaciju Promote.",
        "hu-HU": "A kamera használata ezen az eszközön nem támogatott. A Promote app használatához frissítsd az iOS verziót.",
        "hu": "A kamera használata ezen az eszközön nem támogatott. A Promote app használatához frissítsd az iOS verziót.",
        "it-IT": "Questo dispositivo non supporta l'uso della fotocamera. Aggiorna la versione di iOS per utilizzare l'app Promote.",
        "it": "Questo dispositivo non supporta l'uso della fotocamera. Aggiorna la versione di iOS per utilizzare l'app Promote.",
        "ja-JP": "このデバイスのカメラの使用はサポートされていません。 Promoteアプリを使用するには、iOSのバージョンをアップグレードしてください。",
        "ja": "このデバイスのカメラの使用はサポートされていません。 Promoteアプリを使用するには、iOSのバージョンをアップグレードしてください。",
        "ko-KR": "이 기기에서는 카메라 사용이 지원되지 않습니다. Promote 앱을 사용하려면 iOS 버전을 업그레이드하세요.",
        "ko": "이 기기에서는 카메라 사용이 지원되지 않습니다. Promote 앱을 사용하려면 iOS 버전을 업그레이드하세요.",
        "nl-NL": "Het gebruik van de camera op dit apparaat wordt niet ondersteund. Werk je iOS-versie bij om de Promote app te gebruiken.",
        "nl": "Het gebruik van de camera op dit apparaat wordt niet ondersteund. Werk je iOS-versie bij om de Promote app te gebruiken.",
        "no-NO": "Bruk av kameraet på denne enheten støttes ikke. Oppgrader iOS-versjonen for å bruke kampanjeappen.",
        "pl-PL": "Na tym urządzeniu aparat nie jest obsługiwany. Uaktualnij swoją wersję iOS, aby korzystać z aplikacji Promocje.",
        "pl": "Na tym urządzeniu aparat nie jest obsługiwany. Uaktualnij swoją wersję iOS, aby korzystać z aplikacji Promocje.",
        "pt-BR": "O uso da câmera não é suportado neste dispositivo. Atualize sua versão do iOS para usar o app Promote.",
        "pt-PT": "Não é suportada a utilização da câmara neste dispositivo. Atualize a versão do iOS para utilizar a Promote App.",
        "ru-RU": "Использование камеры на этом устройстве не поддерживается. Обновите версию iOS, чтобы использовать приложение Promote.",
        "ru": "Использование камеры на этом устройстве не поддерживается. Обновите версию iOS, чтобы использовать приложение Promote.",
        "sv-SE": "Användning av kameran stöds inte på den här enheten. Uppgradera din iOS_version för att använda Promote App.",
        "sv": "Användning av kameran stöds inte på den här enheten. Uppgradera din iOS_version för att använda Promote App.",
        "th-TH": "ไม่รองรับการใช้งานกล้องบนอุปกรณ์นี้ โปรดอัพเกรดเวอร์ชัน iOS ของคุณเพื่อใช้แอพ Promote",
        "th": "ไม่รองรับการใช้งานกล้องบนอุปกรณ์นี้ โปรดอัพเกรดเวอร์ชัน iOS ของคุณเพื่อใช้แอพ Promote",
        "tr-TR": "Bu cihazda kameranın kullanılması desteklenmiyor. Promote Uygulamasını kullanmak için lütfen iOS sürümünü yükselt.",
        "tr": "Bu cihazda kameranın kullanılması desteklenmiyor. Promote Uygulamasını kullanmak için lütfen iOS sürümünü yükselt."
    },
    "RL_ScanError2": {
        "key": "RL_ScanError2",
        "en": "Your network connection is not stable or the device is busy. Please close the scanner and try again.",
        "cs-CZ": "Vaše připojení k síti není stabilní nebo je vaše zařízení zaneprázdněno. Zavřete skener a zkuste to znova.",
        "cs": "Vaše připojení k síti není stabilní nebo je vaše zařízení zaneprázdněno. Zavřete skener a zkuste to znova.",
        "da-DK": "Din netværksforbindelse er ikke stabil, eller din enhed er overbelastet. Luk scanneren, og prøv igen.",
        "da": "Din netværksforbindelse er ikke stabil, eller din enhed er overbelastet. Luk scanneren, og prøv igen.",
        "de-DE": "Deine Netzwerkverbindung ist nicht stabil oder das Gerät ist ausgelastet. Bitte schließe den Scanner und versuche es erneut.",
        "de": "Deine Netzwerkverbindung ist nicht stabil oder das Gerät ist ausgelastet. Bitte schließe den Scanner und versuche es erneut.",
        "el-GR": "Η σύνδεση δικτύου δεν είναι σταθερή ή η συσκευή είναι απασχολημένη. Κλείστε τον σαρωτή και δοκιμάστε ξανά.",
        "el": "Η σύνδεση δικτύου δεν είναι σταθερή ή η συσκευή είναι απασχολημένη. Κλείστε τον σαρωτή και δοκιμάστε ξανά.",
        "en-GB": "Your network connection is not stable or the device is busy. Please close the scanner and try again.",
        "es-ES": "Tu conexión de red es inestable o el dispositivo está ocupado. Cierra el escáner y vuelve a intentarlo.",
        "es": "Tu conexión de red es inestable o el dispositivo está ocupado. Cierra el escáner y vuelve a intentarlo.",
        "fi-FI": "Verkkoyhteytesi on epävakaa tai laite on varattu. Sulje skanneri ja yritä uudelleen.",
        "fr-FR": "Votre connexion réseau n'est pas stable ou l'appareil est occupé. Veuillez fermer le scanner et réessayer.",
        "fr": "Votre connexion réseau n'est pas stable ou l'appareil est occupé. Veuillez fermer le scanner et réessayer.",
        "he-IL": "החיבור שלכם לרשת לא יציב או שהמכשיר עמוס. סגרו את הסורק ונסו שוב.",
        "hr-HR": "Mrežna veza nije stabilna ili je uređaj zauzet. Zatvorite skener i pokušajte ponovno.",
        "hu-HU": "A hálózati kapcsolat nem stabil, vagy az eszköz nem elérhető. Zárd be a beolvasót, majd próbáld újra.",
        "hu": "A hálózati kapcsolat nem stabil, vagy az eszköz nem elérhető. Zárd be a beolvasót, majd próbáld újra.",
        "it-IT": "La connessione di rete non è stabile o il dispositivo è occupato. Chiudi lo scanner e riprova.",
        "it": "La connessione di rete non è stabile o il dispositivo è occupato. Chiudi lo scanner e riprova.",
        "ja-JP": "ネットワーク接続が不安定であるかデバイスがビジー状態です。 スキャナーを終了して、もう一度お試しください。",
        "ja": "ネットワーク接続が不安定であるかデバイスがビジー状態です。 スキャナーを終了して、もう一度お試しください。",
        "ko-KR": "네트워크 연결이 불안정하거나 기기가 사용 중입니다. 스캐너를 종료하고 다시 시도해 주세요.",
        "ko": "네트워크 연결이 불안정하거나 기기가 사용 중입니다. 스캐너를 종료하고 다시 시도해 주세요.",
        "nl-NL": "Je netwerkverbinding is niet stabiel of het apparaat is bezig. Sluit de scanner en probeer het opnieuw.",
        "nl": "Je netwerkverbinding is niet stabiel of het apparaat is bezig. Sluit de scanner en probeer het opnieuw.",
        "no-NO": "Nettverksforbindelsen din er ikke stabil eller enheten er opptatt. Lukk skanneren, og prøv på nytt.",
        "pl-PL": "Połączenie sieciowe jest niestabilne lub urządzenie jest zajęte. Zamknij skaner i spróbuj ponownie.",
        "pl": "Połączenie sieciowe jest niestabilne lub urządzenie jest zajęte. Zamknij skaner i spróbuj ponownie.",
        "pt-BR": "Sua conexão de rede não está estável ou o dispositivo está ocupado. Feche o escaneamento e tente novamente.",
        "pt-PT": "A ligação de rede não é estável ou o dispositivo está ocupado. Feche o leitor e tente novamente.",
        "ru-RU": "Сетевое подключение нестабильно или устройство занято. Завершите работу сканера и повторите попытку.",
        "ru": "Сетевое подключение нестабильно или устройство занято. Завершите работу сканера и повторите попытку.",
        "sv-SE": "Din nätverksanslutning är instabil eller så är enheten upptagen. Stäng skannern och försök igen.",
        "sv": "Din nätverksanslutning är instabil eller så är enheten upptagen. Stäng skannern och försök igen.",
        "th-TH": "การเชื่อมต่อเครือข่ายของคุณไม่เสถียรหรืออุปกรณ์ไม่พร้อมใช้งาน โปรดปิดเครื่องสแกนแล้วลองอีกครั้ง",
        "th": "การเชื่อมต่อเครือข่ายของคุณไม่เสถียรหรืออุปกรณ์ไม่พร้อมใช้งาน โปรดปิดเครื่องสแกนแล้วลองอีกครั้ง",
        "tr-TR": "Ağ bağlantın sabit değil veya cihaz meşgul. Lütfen tarayıcıyı kapat ve tekrar dene.",
        "tr": "Ağ bağlantın sabit değil veya cihaz meşgul. Lütfen tarayıcıyı kapat ve tekrar dene."
    },
    "RL_StyleUpload": {
      "key": "RL_StyleUpload",
      "en": "STYLES UPLOAD",
      "ca": "PUJADA DE PRODUCTES",
      "ca-ES": "PUJADA DE PRODUCTES",
      "cs": "NAHRÁVÁNÍ STYLŮ",
      "cs-CZ": "NAHRÁVÁNÍ STYLŮ",
      "da": "UPLOAD MODELLER",
      "da-DK": "UPLOAD MODELLER",
      "de": "STYLE-UPLOAD",
      "de-DE": "STYLE-UPLOAD",
      "el": "ΜΕΤΑΦΟΡΤΩΣΗ ΣΤΙΛ",
      "el-GR": "ΜΕΤΑΦΟΡΤΩΣΗ ΣΤΙΛ",
      "en-GB": "STYLES UPLOAD",
      "es": "CARGA DE PRODUCTOS",
      "es-ES": "CARGA DE PRODUCTOS",
      "fi-FI": "TYYLIEN LATAAMINEN",
      "fr": "IMPORTATION DE MODÈLES",
      "fr-FR": "IMPORTATION DE MODÈLES",
      "he-IL": "העלאת סגנונות",
      "hr-HR": "PRIJENOS MODELA",
      "hu": "STÍLUSOK FELTÖLTÉSE",
      "hu-HU": "STÍLUSOK FELTÖLTÉSE",
      "it": "UPLOAD MODELLI",
      "it-IT": "UPLOAD MODELLI",
      "ja": "スタイルのアップロード",
      "ja-JP": "スタイルのアップロード",
      "ko": "스타일 업로드",
      "ko-KR": "스타일 업로드",
      "nl": "STIJLEN UPLOADEN",
      "nl-NL": "STIJLEN UPLOADEN",
      "no-NO": "LAST OPP PRODUKTER",
      "pl": "PRZESYŁANIE MODELI",
      "pl-PL": "PRZESYŁANIE MODELI",
      "pt-BR": "UPLOAD DE ESTILOS",
      "pt-PT": "CARREGAMENTO DE ESTILOS",
      "ru": "ЗАГРУЗКА МОДЕЛЕЙ",
      "ru-RU": "ЗАГРУЗКА МОДЕЛЕЙ",
      "sv": "LADDA UPP MODELLER",
      "sv-SE": "LADDA UPP MODELLER",
      "th": "อัพโหลดสไตล์",
      "th-TH": "อัพโหลดสไตล์",
      "tr": "STİL YÜKLEME",
      "tr-TR": "STİL YÜKLEME"
    },
    "RL_1UploaStyle": {
      "key": "RL_1UploaStyle",
      "en": "Upload 1 Style Color",
      "ca": "Puja un producte",
      "ca-ES": "Puja un producte",
      "cs": "Nahrát 1 barvu stylu",
      "cs-CZ": "Nahrát 1 barvu stylu",
      "da": "Upload 1 modelfarve",
      "da-DK": "Upload 1 modelfarve",
      "de": "1 Style-/Farbcode hochladen",
      "de-DE": "1 Style-/Farbcode hochladen",
      "el": "Μεταφόρτωση 1 στιλ-χρώματος",
      "el-GR": "Μεταφόρτωση 1 στιλ-χρώματος",
      "en-GB": "Upload 1 Style Colour",
      "es": "Carga 1 producto",
      "es-ES": "Carga 1 producto",
      "fi-FI": "Lataa 1 StyleColor",
      "fr": "Importer 1 style-coloris",
      "fr-FR": "Importer 1 style-coloris",
      "he-IL": "העלאת סגנון-צבע אחד",
      "hr-HR": "Prenesi 1 parametar Style Color",
      "hu": "1 stílus–szín feltöltése",
      "hu-HU": "1 stílus–szín feltöltése",
      "it": "Upload 1 modello colore",
      "it-IT": "Upload 1 modello colore",
      "ja": "1件のStyle Colorをアップロード",
      "ja-JP": "1件のStyle Colorをアップロード",
      "ko": "스타일 컬러 1개 업로드",
      "ko-KR": "스타일 컬러 1개 업로드",
      "nl": "Upload 1 stijl/kleur",
      "nl-NL": "Upload 1 stijl/kleur",
      "no-NO": "Last opp 1 produktfarge",
      "pl": "Prześlij 1 model/kolor",
      "pl-PL": "Prześlij 1 model/kolor",
      "pt-BR": "Fazer upload de 1 cor de estilo",
      "pt-PT": "Carregar 1 estilo-cor",
      "ru": "Загрузить 1 модель/расцветку",
      "ru-RU": "Загрузить 1 модель/расцветку",
      "sv": "Ladda upp 1 modellfärg",
      "sv-SE": "Ladda upp 1 modellfärg",
      "th": "อัพโหลด 1 สไตล์สี",
      "th-TH": "อัพโหลด 1 สไตล์สี",
      "tr": "1 Stil-Renk Yükle",
      "tr-TR": "1 Stil-Renk Yükle"
    },
    "RL_2UploaStyle": {
      "key": "RL_2UploaStyle",
      "en": "Upload {{1}} Style Colors",
      "ca": "Puja {{1}} productes",
      "ca-ES": "Puja {{1}} productes",
      "cs": "Nahrát {{1}} barev stylů",
      "cs-CZ": "Nahrát {{1}} barev stylů",
      "da": "Upload {{1}} modelfarver",
      "da-DK": "Upload {{1}} modelfarver",
      "de": "{{1}} Style-/Farbcodes hochladen",
      "de-DE": "{{1}} Style-/Farbcodes hochladen",
      "el": "Μεταφόρτωση {{1}} στιλ-χρωμάτων",
      "el-GR": "Μεταφόρτωση {{1}} στιλ-χρωμάτων",
      "en-GB": "Upload {{1}} Style Colours",
      "es": "Carga {{1}} productos",
      "es-ES": "Carga {{1}} productos",
      "fi-FI": "Lataa ((1)) StyleColoria",
      "fr": "Importer {{1}} style-coloris",
      "fr-FR": "Importer {{1}} style-coloris",
      "he-IL": "העלאת {{1}} סגנונות-צבעים",
      "hr-HR": "Prenesi {{1}} parametar Style Colors",
      "hu": "{{1}} stílus–szín feltöltése",
      "hu-HU": "{{1}} stílus–szín feltöltése",
      "it": "Upload {{1}} modelli colore",
      "it-IT": "Upload {{1}} modelli colore",
      "ja": "{{1}}件のStyle Colorをアップロード",
      "ja-JP": "{{1}}件のStyle Colorをアップロード",
      "ko": "스타일 컬러 {{1}}개 업로드",
      "ko-KR": "스타일 컬러 {{1}}개 업로드",
      "nl": "Upload {{1}} stijlen/kleuren",
      "nl-NL": "Upload {{1}} stijlen/kleuren",
      "no-NO": "Last opp {{1}} produktfarger",
      "pl": "Prześlij modele/kolory: {{1}}",
      "pl-PL": "Prześlij modele/kolory: {{1}}",
      "pt-BR": "Fazer upload de {{1}} cores de estilo",
      "pt-PT": "Carregar {{1}} estilos-cores",
      "ru": "Загрузить модели/расцветки ({{1}})",
      "ru-RU": "Загрузить модели/расцветки ({{1}})",
      "sv": "Ladda upp {{1}} modellfärger",
      "sv-SE": "Ladda upp {{1}} modellfärger",
      "th": "อัพโหลด {{1}} สไตล์สี",
      "th-TH": "อัพโหลด {{1}} สไตล์สี",
      "tr": "{{1}} Stil-Renk Yükle",
      "tr-TR": "{{1}} Stil-Renk Yükle"
    },
    "RL_UploadComplete": {
      "key": "RL_UploadComplete",
      "en": "The Upload was complete. {{1}} of {{2}} uploads succeeded",
      "ca": "La pujada s'ha completat. S'han pujat correctament {{1}} dels {{2}} productes",
      "ca-ES": "La pujada s'ha completat. S'han pujat correctament {{1}} dels {{2}} productes",
      "cs": "Nahrávání bylo dokončeno. {{1}} z {{2}} nahrání proběhlo úspěšně",
      "cs-CZ": "Nahrávání bylo dokončeno. {{1}} z {{2}} nahrání proběhlo úspěšně",
      "da": "Overførslen blev gennemført. {{1}} af {{2}} uploads blev gennemført",
      "da-DK": "Overførslen blev gennemført. {{1}} af {{2}} uploads blev gennemført",
      "de": "Der Upload ist abgeschlossen. {{1}} von {{2}} Uploads erfolgreich",
      "de-DE": "Der Upload ist abgeschlossen. {{1}} von {{2}} Uploads erfolgreich",
      "el": "Η μεταφόρτωση ολοκληρώθηκε. {{1}} από {{2}} μεταφορτώσεις ολοκληρώθηκαν με επιτυχία",
      "el-GR": "Η μεταφόρτωση ολοκληρώθηκε. {{1}} από {{2}} μεταφορτώσεις ολοκληρώθηκαν με επιτυχία",
      "en-GB": "The upload is complete. {{1}} of {{2}} uploads succeeded",
      "es": "La carga se ha completado. {{1}} de {{2}} cargas completadas correctamente",
      "es-ES": "La carga se ha completado. {{1}} de {{2}} cargas completadas correctamente",
      "fi-FI": "Lataus onnistui. ((1))/((2)) latausta onnistui.",
      "fr": "Importation terminée. {{1}} importation terminée(s) sur {{2}}",
      "fr-FR": "Importation terminée. {{1}} importation terminée(s) sur {{2}}",
      "he-IL": "ההעלאה הושלמה. {{1}} מתוך {{2}} העלאות הצליחו",
      "hr-HR": "Dovršen je prijenos. {{1}} od {{2}} prijenosa uspješno je dovršen",
      "hu": "A feltöltés befejeződött. {{1}} / {{2}} sikeres feltöltés",
      "hu-HU": "A feltöltés befejeződött. {{1}} / {{2}} sikeres feltöltés",
      "it": "Upload completato. Upload riusciti: {{1}} di {{2}}",
      "it-IT": "Upload completato. Upload riusciti: {{1}} di {{2}}",
      "ja": "アップロードが完了しました。 {{2}}件中{{1}}件のアップロードに成功しました",
      "ja-JP": "アップロードが完了しました。 {{2}}件中{{1}}件のアップロードに成功しました",
      "ko": "업로드가 완료되었습니다. {{2}}건 중 {{1}}건의 업로드가 완료되었습니다",
      "ko-KR": "업로드가 완료되었습니다. {{2}}건 중 {{1}}건의 업로드가 완료되었습니다",
      "nl": "Het uploaden is voltooid. {{1}} van {{2}} uploads zijn gelukt",
      "nl-NL": "Het uploaden is voltooid. {{1}} van {{2}} uploads zijn gelukt",
      "no-NO": "Opplastingen er fullført. {{1}} av {{2}} opplastinger er gjennomført",
      "pl": "Przesyłanie zostało zakończone. {{1}} z {{2}} procesów przesyłania zakończone sukcesem",
      "pl-PL": "Przesyłanie zostało zakończone. {{1}} z {{2}} procesów przesyłania zakończone sukcesem",
      "pt-BR": "Upload concluído. Uploads concluídos: {{1}} de {{2}}",
      "pt-PT": "O carregamento terminou. {{1}} de {{2}} carregamentos efetuados com sucesso",
      "ru": "Загрузка завершена. Выполнено загрузок: {{1}} из {{2}}",
      "ru-RU": "Загрузка завершена. Выполнено загрузок: {{1}} из {{2}}",
      "sv": "Uppladdningen klar. {{1}} av {{2}} uppladdningar genomfördes",
      "sv-SE": "Uppladdningen klar. {{1}} av {{2}} uppladdningar genomfördes",
      "th": "อัพโหลดเสร็จสิ้น อัพโหลดสำเร็จ {{1}} จาก {{2}} รายการ",
      "th-TH": "อัพโหลดเสร็จสิ้น อัพโหลดสำเร็จ {{1}} จาก {{2}} รายการ",
      "tr": "Yükleme tamamlandı. {{1}}/{{2}} yükleme başarılı",
      "tr-TR": "Yükleme tamamlandı. {{1}}/{{2}} yükleme başarılı"
    },
    "RL_AllStyleColours": {
      "key": "RL_AllStyleColours",
      "en": "All StyleColors",
      "ca": "Tots els productes",
      "ca-ES": "Tots els productes",
      "cs": "Všechny barvy stylů",
      "cs-CZ": "Všechny barvy stylů",
      "da": "Alle modelfarver",
      "da-DK": "Alle modelfarver",
      "de": "Alle Style-/Farbcodes",
      "de-DE": "Alle Style-/Farbcodes",
      "el": "Όλα τα στιλ-χρώματα",
      "el-GR": "Όλα τα στιλ-χρώματα",
      "en-GB": "All Style Colours",
      "es": "Todos los productos",
      "es-ES": "Todos los productos",
      "fi-FI": "Kaikki StyleColorit",
      "fr": "Tous les styles-coloris",
      "fr-FR": "Tous les styles-coloris",
      "he-IL": "כל הסגנונות-צבעים",
      "hr-HR": "Svi parametri model-boja",
      "hu": "Összes stílus–szín",
      "hu-HU": "Összes stílus–szín",
      "it": "Tutti i modelli-colore",
      "it-IT": "Tutti i modelli-colore",
      "ja": "すべてのStyleColor",
      "ja-JP": "すべてのStyleColor",
      "ko": "모든 스타일 컬러",
      "ko-KR": "모든 스타일 컬러",
      "nl": "Alle stijlen/kleuren",
      "nl-NL": "Alle stijlen/kleuren",
      "no-NO": "Alle produktfarger",
      "pl": "Wszystkie modele/kolory",
      "pl-PL": "Wszystkie modele/kolory",
      "pt-BR": "Todas as CoresEstilos",
      "pt-PT": "Todos os estilos-cores",
      "ru": "Все модели/расцветки",
      "ru-RU": "Все модели/расцветки",
      "sv": "Alla modellfärger",
      "sv-SE": "Alla modellfärger",
      "th": "สไตล์สีทั้งหมด",
      "th-TH": "สไตล์สีทั้งหมด",
      "tr": "Tüm Stil-Renkler",
      "tr-TR": "Tüm Stil-Renkler"
    },
    "RL_Succeeded": {
      "key": "RL_Succeeded",
      "en": "Succeeded",
      "ca": "Pujada correcta",
      "ca-ES": "Pujada correcta",
      "cs": "Povedlo se",
      "cs-CZ": "Povedlo se",
      "da": "Gennemført",
      "da-DK": "Gennemført",
      "de": "Erfolgreich",
      "de-DE": "Erfolgreich",
      "el": "Επιτυχή",
      "el-GR": "Επιτυχή",
      "en-GB": "Succeeded",
      "es": "Completadas",
      "es-ES": "Completadas",
      "fi-FI": "Onnistui",
      "fr": "Réussie(s)",
      "fr-FR": "Réussie(s)",
      "he-IL": "הצליחו",
      "hr-HR": "Uspješno",
      "hu": "Sikeres",
      "hu-HU": "Sikeres",
      "it": "Riusciti",
      "it-IT": "Riusciti",
      "ja": "成功しました",
      "ja-JP": "成功しました",
      "ko": "완료",
      "ko-KR": "완료",
      "nl": "Gelukt",
      "nl-NL": "Gelukt",
      "no-NO": "Gjennomført",
      "pl": "Zakończone sukcesem",
      "pl-PL": "Zakończone sukcesem",
      "pt-BR": "Concluído",
      "pt-PT": "Com sucesso",
      "ru": "Выполнено",
      "ru-RU": "Выполнено",
      "sv": "Genomfördes",
      "sv-SE": "Genomfördes",
      "th": "สำเร็จ",
      "th-TH": "สำเร็จ",
      "tr": "Başarılı",
      "tr-TR": "Başarılı"
    },
    "RL_Failed": {
      "key": "RL_Failed",
      "en": "Failed",
      "ca": "Error",
      "ca-ES": "Error",
      "cs": "Nepovedlo se",
      "cs-CZ": "Nepovedlo se",
      "da": "Mislykkedes",
      "da-DK": "Mislykkedes",
      "de": "Fehlgeschlagen",
      "de-DE": "Fehlgeschlagen",
      "el": "Μη επιτυχή",
      "el-GR": "Μη επιτυχή",
      "en-GB": "Failed",
      "es": "Error",
      "es-ES": "Error",
      "fi-FI": "Epäonnistui",
      "fr": "Échouée(s)",
      "fr-FR": "Échouée(s)",
      "he-IL": "נכשלו",
      "hr-HR": "Neuspješno",
      "hu": "Sikertelen",
      "hu-HU": "Sikertelen",
      "it": "Non riusciti",
      "it-IT": "Non riusciti",
      "ja": "失敗しました",
      "ja-JP": "失敗しました",
      "ko": "실패",
      "ko-KR": "실패",
      "nl": "Mislukt",
      "nl-NL": "Mislukt",
      "no-NO": "Mislyktes",
      "pl": "Zakończone niepowodzeniem",
      "pl-PL": "Zakończone niepowodzeniem",
      "pt-BR": "Com falha",
      "pt-PT": "Falha",
      "ru": "Не выполнено",
      "ru-RU": "Не выполнено",
      "sv": "Misslyckades",
      "sv-SE": "Misslyckades",
      "th": "ล้มเหลว",
      "th-TH": "ล้มเหลว",
      "tr": "Başarısız",
      "tr-TR": "Başarısız"
    },
    "RL_RowNumber": {
      "key": "RL_RowNumber",
      "en": "Row Number",
      "ca": "Número de fila",
      "ca-ES": "Número de fila",
      "cs": "Číslo řádku",
      "cs-CZ": "Číslo řádku",
      "da": "Rækkenummer",
      "da-DK": "Rækkenummer",
      "de": "Zeilennummer",
      "de-DE": "Zeilennummer",
      "el": "Αριθμός γραμμής",
      "el-GR": "Αριθμός γραμμής",
      "en-GB": "Row number",
      "es": "Número de fila",
      "es-ES": "Número de fila",
      "fi-FI": "Rivin numero",
      "fr": "Numéro de ligne",
      "fr-FR": "Numéro de ligne",
      "he-IL": "מספר שורה",
      "hr-HR": "Broj retka",
      "hu": "Sor száma",
      "hu-HU": "Sor száma",
      "it": "Numero riga",
      "it-IT": "Numero riga",
      "ja": "行番号",
      "ja-JP": "行番号",
      "ko": "행 번호",
      "ko-KR": "행 번호",
      "nl": "Rijnummer",
      "nl-NL": "Rijnummer",
      "no-NO": "Radnummer",
      "pl": "Numer rzędu",
      "pl-PL": "Numer rzędu",
      "pt-BR": "Número da linha",
      "pt-PT": "Número da linha",
      "ru": "Номер строки",
      "ru-RU": "Номер строки",
      "sv": "Radnummer",
      "sv-SE": "Radnummer",
      "th": "หมายเลขแถว",
      "th-TH": "หมายเลขแถว",
      "tr": "Sıra Numarası",
      "tr-TR": "Sıra Numarası"
    },
    "RL_StyleColor1": {
      "key": "RL_StyleColor1",
      "en": "Style-Color",
      "ca": "Producte",
      "ca-ES": "Producte",
      "cs": "Barva stylu",
      "cs-CZ": "Barva stylu",
      "da": "Modelfarve",
      "da-DK": "Modelfarve",
      "de": "Style-/Farbcode",
      "de-DE": "Style-/Farbcode",
      "el": "Στιλ-Χρώμα",
      "el-GR": "Στιλ-Χρώμα",
      "en-GB": "Style colour",
      "es": "Modelo-color",
      "es-ES": "Modelo-color",
      "fi-FI": "StyleColor",
      "fr": "Style-coloris",
      "fr-FR": "Style-coloris",
      "he-IL": "סגנון-צבע",
      "hr-HR": "Style-Color",
      "hu": "Stílus–szín",
      "hu-HU": "Stílus–szín",
      "it": "Modello-colore",
      "it-IT": "Modello-colore",
      "ja": "Style-Color",
      "ja-JP": "Style-Color",
      "ko": "스타일-컬러",
      "ko-KR": "스타일-컬러",
      "nl": "Stijl/kleur",
      "nl-NL": "Stijl/kleur",
      "no-NO": "Produktfarge",
      "pl": "Model/kolor",
      "pl-PL": "Model/kolor",
      "pt-BR": "Cor-Estilo",
      "pt-PT": "Estilo-cor",
      "ru": "Модель/расцветка",
      "ru-RU": "Модель/расцветка",
      "sv": "Modellfärg",
      "sv-SE": "Modellfärg",
      "th": "สไตล์-สี",
      "th-TH": "สไตล์-สี",
      "tr": "Stil-Renk",
      "tr-TR": "Stil-Renk"
    },
    "RL_UploadStatus": {
      "key": "RL_UploadStatus",
      "en": "Upload Status",
      "ca": "Estat de la pujada",
      "ca-ES": "Estat de la pujada",
      "cs": "Stav nahrávání",
      "cs-CZ": "Stav nahrávání",
      "da": "Status på upload",
      "da-DK": "Status på upload",
      "de": "Upload-Status",
      "de-DE": "Upload-Status",
      "el": "Κατάσταση μεταφόρτωσης",
      "el-GR": "Κατάσταση μεταφόρτωσης",
      "en-GB": "Upload status",
      "es": "Estado de la carga",
      "es-ES": "Estado de la carga",
      "fi-FI": "Latauksen tila",
      "fr": "Progression de l'importation",
      "fr-FR": "Progression de l'importation",
      "he-IL": "מצב העלאה",
      "hr-HR": "Status prijenosa",
      "hu": "Feltöltés állapota",
      "hu-HU": "Feltöltés állapota",
      "it": "Stato upload",
      "it-IT": "Stato upload",
      "ja": "アップロード状況",
      "ja-JP": "アップロード状況",
      "ko": "업로드 상태",
      "ko-KR": "업로드 상태",
      "nl": "Uploadstatus",
      "nl-NL": "Uploadstatus",
      "no-NO": "Status for opplasting",
      "pl": "Status przesyłania",
      "pl-PL": "Status przesyłania",
      "pt-BR": "Status do upload",
      "pt-PT": "Estado do carregamento",
      "ru": "Статус загрузки",
      "ru-RU": "Статус загрузки",
      "sv": "Uppladdningsstatus",
      "sv-SE": "Uppladdningsstatus",
      "th": "สถานะการอัพโหลด",
      "th-TH": "สถานะการอัพโหลด",
      "tr": "Yükleme Durumu",
      "tr-TR": "Yükleme Durumu"
    },
    "RL_Errors": {
      "key": "RL_Errors",
      "en": "Errors",
      "ca": "Errors",
      "ca-ES": "Errors",
      "cs": "Chyby",
      "cs-CZ": "Chyby",
      "da": "Fejl",
      "da-DK": "Fejl",
      "de": "Fehler",
      "de-DE": "Fehler",
      "el": "Σφάλματα",
      "el-GR": "Σφάλματα",
      "en-GB": "Errors",
      "es": "Errores",
      "es-ES": "Errores",
      "fi-FI": "Virheet",
      "fr": "Erreurs",
      "fr-FR": "Erreurs",
      "he-IL": "שגיאות",
      "hr-HR": "Pogreške",
      "hu": "Hiba",
      "hu-HU": "Hiba",
      "it": "Errori",
      "it-IT": "Errori",
      "ja": "エラー",
      "ja-JP": "エラー",
      "ko": "오류",
      "ko-KR": "오류",
      "nl": "Foutmeldingen",
      "nl-NL": "Foutmeldingen",
      "no-NO": "Feil",
      "pl": "Błędy",
      "pl-PL": "Błędy",
      "pt-BR": "Erros",
      "pt-PT": "Erros",
      "ru": "Ошибки",
      "ru-RU": "Ошибки",
      "sv": "Fel",
      "sv-SE": "Fel",
      "th": "ข้อผิดพลาด",
      "th-TH": "ข้อผิดพลาด",
      "tr": "Hatalar",
      "tr-TR": "Hatalar"
    },
    "RL_Success": {
      "key": "RL_Success",
      "en": "Success",
      "ca": "Correcte",
      "ca-ES": "Correcte",
      "cs": "Povedlo se",
      "cs-CZ": "Povedlo se",
      "da": "Gennemført",
      "da-DK": "Gennemført",
      "de": "Erfolgreich",
      "de-DE": "Erfolgreich",
      "el": "Επιτυχία",
      "el-GR": "Επιτυχία",
      "en-GB": "Success",
      "es": "Completada",
      "es-ES": "Completada",
      "fi-FI": "Onnistui",
      "fr": "Terminée avec succès",
      "fr-FR": "Terminée avec succès",
      "he-IL": "הצלחה",
      "hr-HR": "Uspješno",
      "hu": "Siker",
      "hu-HU": "Siker",
      "it": "Riuscito",
      "it-IT": "Riuscito",
      "ja": "成功",
      "ja-JP": "成功",
      "ko": "완료",
      "ko-KR": "완료",
      "nl": "Gelukt",
      "nl-NL": "Gelukt",
      "no-NO": "Fullført",
      "pl": "Udało się",
      "pl-PL": "Udało się",
      "pt-BR": "Concluído",
      "pt-PT": "Sucesso",
      "ru": "Успешно",
      "ru-RU": "Успешно",
      "sv": "Klar",
      "sv-SE": "Klar",
      "th": "สำเร็จ",
      "th-TH": "สำเร็จ",
      "tr": "Başarılı",
      "tr-TR": "Başarılı"
    },
    "RL_StyleUpload1": {
      "key": "RL_StyleUpload1",
      "en": "Styles Upload",
      "ca": "Pujada de productes",
      "ca-ES": "Pujada de productes",
      "cs": "Nahrávání stylů",
      "cs-CZ": "Nahrávání stylů",
      "da": "Upload modeller",
      "da-DK": "Upload modeller",
      "de": "Style-Upload",
      "de-DE": "Style-Upload",
      "el": "Μεταφόρτωση στιλ",
      "el-GR": "Μεταφόρτωση στιλ",
      "en-GB": "Styles Upload",
      "es": "Carga de productos",
      "es-ES": "Carga de productos",
      "fi-FI": "Tyylien lataaminen",
      "fr": "Importation de modèles",
      "fr-FR": "Importation de modèles",
      "he-IL": "העלאת סגנונות",
      "hr-HR": "Prijenos modela",
      "hu": "Stílusok feltöltése",
      "hu-HU": "Stílusok feltöltése",
      "it": "Upload modelli",
      "it-IT": "Upload modelli",
      "ja": "スタイルのアップロード",
      "ja-JP": "スタイルのアップロード",
      "ko": "스타일 업로드",
      "ko-KR": "스타일 업로드",
      "nl": "Stijlen uploaden",
      "nl-NL": "Stijlen uploaden",
      "no-NO": "Last opp produkter",
      "pl": "Przesyłanie modeli",
      "pl-PL": "Przesyłanie modeli",
      "pt-BR": "Upload de estilos",
      "pt-PT": "Carregamento de estilos",
      "ru": "Загрузка моделей",
      "ru-RU": "Загрузка моделей",
      "sv": "Ladda upp modeller",
      "sv-SE": "Ladda upp modeller",
      "th": "อัพโหลดสไตล์",
      "th-TH": "อัพโหลดสไตล์",
      "tr": "Stil Yükleme",
      "tr-TR": "Stil Yükleme"
    },
    "RL_Failure": {
      "key": "RL_Failure",
      "en": "Failure",
      "ca": "Error",
      "ca-ES": "Error",
      "cs": "Nepovedlo se",
      "cs-CZ": "Nepovedlo se",
      "da": "Fejl",
      "da-DK": "Fejl",
      "de": "Fehlgeschlagen",
      "de-DE": "Fehlgeschlagen",
      "el": "Αποτυχία",
      "el-GR": "Αποτυχία",
      "en-GB": "Failure",
      "es": "Error",
      "es-ES": "Error",
      "fi-FI": "Epäonnistui",
      "fr": "Échec",
      "fr-FR": "Échec",
      "he-IL": "כשל",
      "hr-HR": "Neuspješno",
      "hu": "Hiba",
      "hu-HU": "Hiba",
      "it": "Non riuscito",
      "it-IT": "Non riuscito",
      "ja": "失敗",
      "ja-JP": "失敗",
      "ko": "실패",
      "ko-KR": "실패",
      "nl": "Mislukt",
      "nl-NL": "Mislukt",
      "no-NO": "Mislyktes",
      "pl": "Niepowodzenie",
      "pl-PL": "Niepowodzenie",
      "pt-BR": "Não concluído",
      "pt-PT": "Falha",
      "ru": "Сбой",
      "ru-RU": "Сбой",
      "sv": "Misslyckades",
      "sv-SE": "Misslyckades",
      "th": "ล้มเหลว",
      "th-TH": "ล้มเหลว",
      "tr": "Başarısız",
      "tr-TR": "Başarısız"
    },
    "RL_Waiting": {
      "key": "RL_Waiting",
      "en": "Waiting",
      "ca": "En espera",
      "ca-ES": "En espera",
      "cs": "Čeká se",
      "cs-CZ": "Čeká se",
      "da": "Venter",
      "da-DK": "Venter",
      "de": "Warten",
      "de-DE": "Warten",
      "el": "Περιμένετε",
      "el-GR": "Περιμένετε",
      "en-GB": "Waiting",
      "es": "Esperando",
      "es-ES": "Esperando",
      "fi-FI": "Odota",
      "fr": "En attente",
      "fr-FR": "En attente",
      "he-IL": "בהמתנה",
      "hr-HR": "Čekanje",
      "hu": "Várakozás",
      "hu-HU": "Várakozás",
      "it": "In corso",
      "it-IT": "In corso",
      "ja": "お待ちください",
      "ja-JP": "お待ちください",
      "ko": "대기 중",
      "ko-KR": "대기 중",
      "nl": "Wachten...",
      "nl-NL": "Wachten...",
      "no-NO": "Venter",
      "pl": "Oczekiwanie",
      "pl-PL": "Oczekiwanie",
      "pt-BR": "Aguardando",
      "pt-PT": "A aguardar",
      "ru": "Ожидание",
      "ru-RU": "Ожидание",
      "sv": "Väntar",
      "sv-SE": "Väntar",
      "th": "กำลังรอ",
      "th-TH": "กำลังรอ",
      "tr": "Bekleniyor",
      "tr-TR": "Bekleniyor"
    },
    "RL_PleaseWait": {
      "key": "RL_PleaseWait",
      "en": "Please wait while the upload is in progress. Progress: {{1}}%",
      "ca": "Espera mentre es completa la pujada. Progrés: {{1}}%",
      "ca-ES": "Espera mentre es completa la pujada. Progrés: {{1}}%",
      "cs": "Vyčkejte, dokud nahrávání není dokončeno. Z {{1}} % dokončeno",
      "cs-CZ": "Vyčkejte, dokud nahrávání není dokončeno. Z {{1}} % dokončeno",
      "da": "Vent, mens overførslen er i gang. Status: {{1}}%",
      "da-DK": "Vent, mens overførslen er i gang. Status: {{1}}%",
      "de": "Bitte warte, während der Upload läuft. Fortschritt: {{1}} %",
      "de-DE": "Bitte warte, während der Upload läuft. Fortschritt: {{1}} %",
      "el": "Περιμένετε όσο η μεταφόρτωση βρίσκεται σε εξέλιξη. Πρόοδος{{1}}%",
      "el-GR": "Περιμένετε όσο η μεταφόρτωση βρίσκεται σε εξέλιξη. Πρόοδος{{1}}%",
      "en-GB": "Please wait while the upload is in progress. Progress{{1}}%",
      "es": "Espera mientras la carga está en curso. Progreso: {{1}} %",
      "es-ES": "Espera mientras la carga está en curso. Progreso: {{1}} %",
      "fi-FI": "Odota latauksen valmistumista. Edistyminen {{1}} %",
      "fr": "Importation en cours. Merci de patienter. Progression : {{1}} %",
      "fr-FR": "Importation en cours. Merci de patienter. Progression : {{1}} %",
      "he-IL": "המתינו בזמן שההעלאה מתבצעת. התקדמות: {{1}}%",
      "hr-HR": "Prijenos je u tijeku. Pričekajte. U tijeku{{1}} %",
      "hu": "Kérjük, várd meg a feltöltési folyamat végét. Folyamat: {{1}}%",
      "hu-HU": "Kérjük, várd meg a feltöltési folyamat végét. Folyamat: {{1}}%",
      "it": "Upload in corso. Attendi il completamento. Avanzamento {{1}}%",
      "it-IT": "Upload in corso. Attendi il completamento. Avanzamento {{1}}%",
      "ja": "アップロード中です。しばらくお待ちください。 進捗：{{1}}％",
      "ja-JP": "アップロード中です。しばらくお待ちください。 進捗：{{1}}％",
      "ko": "업로드가 진행되는 동안 기다려 주세요. 진행률: {{1}}%",
      "ko-KR": "업로드가 진행되는 동안 기다려 주세요. 진행률: {{1}}%",
      "nl": "Even geduld, de upload is bezig. Voortgang: {{1}}%",
      "nl-NL": "Even geduld, de upload is bezig. Voortgang: {{1}}%",
      "no-NO": "Vent mens opplastingen pågår. Fremdrift: {{1}}%",
      "pl": "Poczekaj na zakończenie przesyłania. Przesłano{{1}}%",
      "pl-PL": "Poczekaj na zakończenie przesyłania. Przesłano{{1}}%",
      "pt-BR": "Aguarde enquanto o upload é realizado. Progresso: {{1}}%",
      "pt-PT": "Aguarde enquanto o carregamento está em curso. Progresso: {{1}}%",
      "ru": "Дождитесь завершения загрузки. Прогресс: {{1}}%",
      "ru-RU": "Дождитесь завершения загрузки. Прогресс: {{1}}%",
      "sv": "Vänta medan uppladdningen pågår. Förlopp{{1}}%",
      "sv-SE": "Vänta medan uppladdningen pågår. Förlopp{{1}}%",
      "th": "โปรดรอขณะกำลังดำเนินการอัพโหลด ความคืบหน้า{{1}}%",
      "th-TH": "โปรดรอขณะกำลังดำเนินการอัพโหลด ความคืบหน้า{{1}}%",
      "tr": "Yükleme devam ediyor, lütfen bekle. İlerleme Durumu: %{{1}}",
      "tr-TR": "Yükleme devam ediyor, lütfen bekle. İlerleme Durumu: %{{1}}"
    },
    "RL_DownloadReport": {
      "key": "RL_DownloadReport",
      "en": "'Download Report'",
      "ca": "Descarrega l'informe",
      "ca-ES": "Descarrega l'informe",
      "cs": "Stáhnout přehled",
      "cs-CZ": "Stáhnout přehled",
      "da": "\"Download rapport\"",
      "da-DK": "\"Download rapport\"",
      "de": "\"Bericht herunterladen\"",
      "de-DE": "\"Bericht herunterladen\"",
      "el": "\"Λήψη αναφοράς\"",
      "el-GR": "\"Λήψη αναφοράς\"",
      "en-GB": "Download Report",
      "es": "Descargar informe",
      "es-ES": "Descargar informe",
      "fi-FI": "\"Lataa raportti\"",
      "fr": "« Télécharger le rapport »",
      "fr-FR": "« Télécharger le rapport »",
      "he-IL": "הורדת דוח",
      "hr-HR": "„Izvješće o preuzimanju”",
      "hu": "„Jelentés letöltése”",
      "hu-HU": "„Jelentés letöltése”",
      "it": "\"Scarica il report\"",
      "it-IT": "\"Scarica il report\"",
      "ja": "'レポートをダウンロード'",
      "ja-JP": "'レポートをダウンロード'",
      "ko": "'보고서 다운로드'",
      "ko-KR": "'보고서 다운로드'",
      "nl": "'Download rapport'",
      "nl-NL": "'Download rapport'",
      "no-NO": "«Last ned rapport»",
      "pl": "„Pobierz raport”",
      "pl-PL": "„Pobierz raport”",
      "pt-BR": "\"Baixar relatório\"",
      "pt-PT": "\"Transferir o relatório\"",
      "ru": "'Скачать отчет'",
      "ru-RU": "'Скачать отчет'",
      "sv": "Hämta rapporten",
      "sv-SE": "Hämta rapporten",
      "th": "'ดาวน์โหลดรายงาน'",
      "th-TH": "'ดาวน์โหลดรายงาน'",
      "tr": "\"Raporu İndir\"",
      "tr-TR": "\"Raporu İndir\""
    },
    "RL_UploadMissing": {
      "key": "RL_UploadMissing",
      "en": "The uploaded file is missing the following columns",
      "ca": "Al fitxer que has pujat li falten les columnes següents",
      "ca-ES": "Al fitxer que has pujat li falten les columnes següents",
      "cs": "V nahraném souboru chybí následující sloupce",
      "cs-CZ": "V nahraném souboru chybí následující sloupce",
      "da": "Den fil, du uploadede, mangler følgende kolonner",
      "da-DK": "Den fil, du uploadede, mangler følgende kolonner",
      "de": "In der hochgeladenen Datei fehlen die folgenden Spalten",
      "de-DE": "In der hochgeladenen Datei fehlen die folgenden Spalten",
      "el": "Το μεταφορτωμένο αρχείο δεν έχει τις ακόλουθες στήλες",
      "el-GR": "Το μεταφορτωμένο αρχείο δεν έχει τις ακόλουθες στήλες",
      "en-GB": "The uploaded file is missing the following columns",
      "es": "En el archivo cargado faltan las siguientes columnas",
      "es-ES": "En el archivo cargado faltan las siguientes columnas",
      "fi-FI": "Ladatusta tiedostosta puuttuvat seuraavat sarakkeet",
      "fr": "Le fichier importé ne contient pas les colonnes suivantes",
      "fr-FR": "Le fichier importé ne contient pas les colonnes suivantes",
      "he-IL": "בקובץ שהועלה חסרות העמודות הבאות",
      "hr-HR": "Prenesenoj datoteci nedostaju sljedeći stupci",
      "hu": "A feltöltött fájlból hiányoznak az alábbi oszlopok",
      "hu-HU": "A feltöltött fájlból hiányoznak az alábbi oszlopok",
      "it": "Il file caricato non include le seguenti colonne",
      "it-IT": "Il file caricato non include le seguenti colonne",
      "ja": "アップロードされたファイルには以下の列が不足しています",
      "ja-JP": "アップロードされたファイルには以下の列が不足しています",
      "ko": "업로드된 파일에 다음 열이 누락되어 있습니다",
      "ko-KR": "업로드된 파일에 다음 열이 누락되어 있습니다",
      "nl": "In het geüploade bestand ontbreken de volgende kolommen",
      "nl-NL": "In het geüploade bestand ontbreken de volgende kolommen",
      "no-NO": "Den opplastede filen mangler følgende kolonner",
      "pl": "W przesłanym pliku brakuje następujących kolumn",
      "pl-PL": "W przesłanym pliku brakuje następujących kolumn",
      "pt-BR": "O arquivo enviado não tem as seguintes colunas",
      "pt-PT": "O ficheiro carregado tem as seguintes colunas em falta",
      "ru": "Загруженный файл отсутствует в следующих столбцах",
      "ru-RU": "Загруженный файл отсутствует в следующих столбцах",
      "sv": "Den uppladdade filen saknar följande kolumner",
      "sv-SE": "Den uppladdade filen saknar följande kolumner",
      "th": "ไฟล์ที่อัพโหลดขาดคอลัมน์ต่อไปนี้",
      "th-TH": "ไฟล์ที่อัพโหลดขาดคอลัมน์ต่อไปนี้",
      "tr": "Yüklenen dosyada aşağıdaki sütunlar eksik",
      "tr-TR": "Yüklenen dosyada aşağıdaki sütunlar eksik"
    },
    "RL_Validate": {
      "key": "RL_Validate",
      "en": "The uploaded file has {{1}} rows. Please upload a file with maximum of {{2}} Style Colors.",
      "ca": "El fitxer que has pujat té {{1}} files. Puja un fitxer amb {{2}} productes com a màxim.",
      "ca-ES": "El fitxer que has pujat té {{1}} files. Puja un fitxer amb {{2}} productes com a màxim.",
      "cs": "Nahraný soubor má {{1}} řádků. Nahrajte prosím soubor s maximálně {{2}} barvami stylů.",
      "cs-CZ": "Nahraný soubor má {{1}} řádků. Nahrajte prosím soubor s maximálně {{2}} barvami stylů.",
      "da": "Den fil, du uploadede, har {{1}} rækker. Du kan maksimalt uploade {{2}} modelfarver.",
      "da-DK": "Den fil, du uploadede, har {{1}} rækker. Du kan maksimalt uploade {{2}} modelfarver.",
      "de": "Die hochgeladene Datei hat {{1}} Zeilen. Bitte lade eine Datei mit maximal {{2}} Style-/Farbcodes hoch.",
      "de-DE": "Die hochgeladene Datei hat {{1}} Zeilen. Bitte lade eine Datei mit maximal {{2}} Style-/Farbcodes hoch.",
      "el": "Το μεταφορτωμένο αρχείο έχει {{1}} γραμμές. Ανεβάστε ένα αρχείο με έως {{2}} στιλ-χρώματα.",
      "el-GR": "Το μεταφορτωμένο αρχείο έχει {{1}} γραμμές. Ανεβάστε ένα αρχείο με έως {{2}} στιλ-χρώματα.",
      "en-GB": "The uploaded file has {{1}} rows. Please upload a file with a maximum of {{2}} style colours.",
      "es": "El archivo cargado tiene {{1}} filas. Carga un archivo con un máximo de {{2}} productos.",
      "es-ES": "El archivo cargado tiene {{1}} filas. Carga un archivo con un máximo de {{2}} productos.",
      "fi-FI": "Ladatussa tiedostossa on ((1)) riviä. Lataa tiedosto, jossa on enintään ((2)) StyleColoria.",
      "fr": "Le fichier importé contient {{1}} ligne(s). Le fichier importé doit contenir {{2}} style-coloris maximum.",
      "fr-FR": "Le fichier importé contient {{1}} ligne(s). Le fichier importé doit contenir {{2}} style-coloris maximum.",
      "he-IL": "הקובץ שהועלה מכיל {{1}} שורות. צריך להעלות קובץ עם {{2}} סגנונות-צבעים לכל היותר.",
      "hr-HR": "Prenesena datoteka ima {{1}} redaka. Prenesite datoteku koja sadrži najviše {{2}} parametra Style Colors.",
      "hu": "A feltöltött fájlban {{1}} sor van. Kérjük, legfeljebb {{1}} stílus–színt tartalmazó fájlt tölts fel.",
      "hu-HU": "A feltöltött fájlban {{1}} sor van. Kérjük, legfeljebb {{1}} stílus–színt tartalmazó fájlt tölts fel.",
      "it": "Il file caricato include il seguente numero di righe: {{1}}. Carica un file con un massimo di {{2}} modelli-colore.",
      "it-IT": "Il file caricato include il seguente numero di righe: {{1}}. Carica un file con un massimo di {{2}} modelli-colore.",
      "ja": "アップロードされたファイルには{{1}}件の行があります。 最大{{2}}件のStyle Colorが含まれるファイルをアップロードしてください。",
      "ja-JP": "アップロードされたファイルには{{1}}件の行があります。 最大{{2}}件のStyle Colorが含まれるファイルをアップロードしてください。",
      "ko": "업로드한 파일에 {{1}}개의 행이 있습니다. 최대 {{2}}개의 스타일 컬러가 포함된 파일을 업로드하세요.",
      "ko-KR": "업로드한 파일에 {{1}}개의 행이 있습니다. 최대 {{2}}개의 스타일 컬러가 포함된 파일을 업로드하세요.",
      "nl": "Het geüploade bestand heeft {{1}} rijen. Upload een bestand met maximaal {{2}} stijlen/kleuren.",
      "nl-NL": "Het geüploade bestand heeft {{1}} rijen. Upload een bestand met maximaal {{2}} stijlen/kleuren.",
      "no-NO": "Den opplastede filen har {{1}} rader. Last opp en fil med maksimalt {{2}} produktfarger.",
      "pl": "Rzędy w przesłanym pliku: {{1}}. Maksymalna liczba modeli/kolorów w przesyłany pliku: {{2}}.",
      "pl-PL": "Rzędy w przesłanym pliku: {{1}}. Maksymalna liczba modeli/kolorów w przesyłany pliku: {{2}}.",
      "pt-BR": "O arquivo enviado tem {{1}} linhas. Faça upload de um arquivo com no máximo {{2}} cores de estilo.",
      "pt-PT": "O ficheiro carregado tem {{1}} linhas. Carregue um ficheiro com, no máximo, {{2}} estilos-cores.",
      "ru": "Количество строк в загруженном файле: {{1}}. Загрузите файл с количеством моделей/расцветок, не превышающем {{2}}.",
      "ru-RU": "Количество строк в загруженном файле: {{1}}. Загрузите файл с количеством моделей/расцветок, не превышающем {{2}}.",
      "sv": "Den uppladdade filen har {{1}} rader. Ladda upp en fil med max {{2}} modellfärger.",
      "sv-SE": "Den uppladdade filen har {{1}} rader. Ladda upp en fil med max {{2}} modellfärger.",
      "th": "ไฟล์ที่อัพโหลดมี {{1}} แถว โปรดอัพโหลดไฟล์ที่มีสไตล์สีสูงสุด {{2}} รายการ",
      "th-TH": "ไฟล์ที่อัพโหลดมี {{1}} แถว โปรดอัพโหลดไฟล์ที่มีสไตล์สีสูงสุด {{2}} รายการ",
      "tr": "Yüklenen dosyada {{1}} satır var. Lütfen en fazla {{2}} Stil-Renk bulunan bir dosya yükle.",
      "tr-TR": "Yüklenen dosyada {{1}} satır var. Lütfen en fazla {{2}} Stil-Renk bulunan bir dosya yükle."
    },
    "RL_Error1": {
      "key": "RL_Error1",
      "en": "Only Clearance Styles can be uploaded (i.e. Price = *.97)",
      "ca": "Només es poden pujar productes en liquidació (amb preu = *,97)",
      "ca-ES": "Només es poden pujar productes en liquidació (amb preu = *,97)",
      "cs": "Nahrány mohou být pouze styly z likvidace zásob (např. cena = *.97)",
      "cs-CZ": "Nahrány mohou být pouze styly z likvidace zásob (např. cena = *.97)",
      "da": "Du kan kun uploade udsalgsmodeller (f.eks. Pris = *,97)",
      "da-DK": "Du kan kun uploade udsalgsmodeller (f.eks. Pris = *,97)",
      "de": "Nur reduzierte Styles können hochgeladen werden (d. h. Preis = *,97)",
      "de-DE": "Nur reduzierte Styles können hochgeladen werden (d. h. Preis = *,97)",
      "el": "Μπορείτε να ανεβάσετε μόνο στιλ σε προσφορά (δηλαδή με τιμή = *,97)",
      "el-GR": "Μπορείτε να ανεβάσετε μόνο στιλ σε προσφορά (δηλαδή με τιμή = *,97)",
      "en-GB": "Only sale styles can be uploaded (i.e. price = *.97)",
      "es": "Solo se pueden cargar productos de liquidación (Precio = *,97)",
      "es-ES": "Solo se pueden cargar productos de liquidación (Precio = *,97)",
      "fi-FI": "Voit ladata ainoastaan alennuksessa olevia tyylejä (esim. hinta. =*.97)",
      "fr": "Seuls les articles de déstockage peuvent être importés (par exemple, prix = *,97)",
      "fr-FR": "Seuls les articles de déstockage peuvent être importés (par exemple, prix = *,97)",
      "he-IL": "אפשר להעלות רק סגנונות עודפים (כלומר שהמחיר מסתיים ב-‎*.97)",
      "hr-HR": "Mogu se prenijeti samo „Modeli na rasprodaji” (npr. cijena = *.97)",
      "hu": "Csak a kiárusításban részt vevő stílusok tölthetők fel (azaz ár = *.97)",
      "hu-HU": "Csak a kiárusításban részt vevő stílusok tölthetők fel (azaz ár = *.97)",
      "it": "Solo i modelli scontati possono essere caricati (prezzo = *,97)",
      "it-IT": "Solo i modelli scontati possono essere caricati (prezzo = *,97)",
      "ja": "セール中のスタイルのみアップロード可能です（例：価格= *.97）",
      "ja-JP": "セール中のスタイルのみアップロード可能です（例：価格= *.97）",
      "ko": "가격 인하 스타일만 업로드할 수 있습니다(예: 가격 = *.97)",
      "ko-KR": "가격 인하 스타일만 업로드할 수 있습니다(예: 가격 = *.97)",
      "nl": "Alleen afgeprijsde stijlen kunnen worden geüpload (d.w.z. prijs = *.97)",
      "nl-NL": "Alleen afgeprijsde stijlen kunnen worden geüpload (d.w.z. prijs = *.97)",
      "no-NO": "Bare salgsprodukter kan lastes opp (dvs. pris = *,97)",
      "pl": "Można przesłać tylko modele/kolory z outletów (tj. cena = *.97)",
      "pl-PL": "Można przesłać tylko modele/kolory z outletów (tj. cena = *.97)",
      "pt-BR": "É possível fazer upload apenas de estilos em liquidação (ou seja, preço = *,97)",
      "pt-PT": "Só é possível carregar estilos em liquidação (por exemplo: preço = *,97)",
      "ru": "Можно загрузить только модели из распродажи (то есть по цене с коэффициентом *0,97)",
      "ru-RU": "Можно загрузить только модели из распродажи (то есть по цене с коэффициентом *0,97)",
      "sv": "Endast reamodeller kan laddas upp (dvs. pris = *,97)",
      "sv-SE": "Endast reamodeller kan laddas upp (dvs. pris = *,97)",
      "th": "อัพโหลดได้เฉพาะสไตล์ล้างสต็อกเท่านั้น (เช่น ราคา = *.97)",
      "th-TH": "อัพโหลดได้เฉพาะสไตล์ล้างสต็อกเท่านั้น (เช่น ราคา = *.97)",
      "tr": "Yalnızca Seri Sonu Stilleri yüklenebilir (ör. Fiyat = *.97)",
      "tr-TR": "Yalnızca Seri Sonu Stilleri yüklenebilir (ör. Fiyat = *.97)"
    },
    "RL_Error2": {
      "key": "RL_Error2",
      "en": "The StyleColor is not found in CPA for this country",
      "ca": "Aquest producte no es troba a la CPA d'aquest país/regió",
      "ca-ES": "Aquest producte no es troba a la CPA d'aquest país/regió",
      "cs": "Barva stylu se v CPA pro tuto zemi nenachází",
      "cs-CZ": "Barva stylu se v CPA pro tuto zemi nenachází",
      "da": "Modelfarven findes ikke i CPA for dette land",
      "da-DK": "Modelfarven findes ikke i CPA for dette land",
      "de": "Der Style-/Farbcode konnte nicht in der CPA für dieses Land gefunden werden",
      "de-DE": "Der Style-/Farbcode konnte nicht in der CPA für dieses Land gefunden werden",
      "el": "Το στιλ-χρώμα δεν βρίσκεται στο CPA για αυτή τη χώρα",
      "el-GR": "Το στιλ-χρώμα δεν βρίσκεται στο CPA για αυτή τη χώρα",
      "en-GB": "The style colour is not found in the CPA for this country/region",
      "es": "El modelo-color no se encuentra en CPA para este país/región.",
      "es-ES": "El modelo-color no se encuentra en CPA para este país/región.",
      "fi-FI": "StyleColoria ei löydetty tämän maan CPA:sta",
      "fr": "Le style-coloris est introuvable dans le CPA de ce pays/cette région",
      "fr-FR": "Le style-coloris est introuvable dans le CPA de ce pays/cette région",
      "he-IL": "הסגנון-צבע לא נמצא ב-CPA של המדינה הזו",
      "hr-HR": "Parametar model-boja nije pronađen u CPA za ovu državu",
      "hu": "Ez a stílus–szín nem szerepel a CPA-ban ebben az országban",
      "hu-HU": "Ez a stílus–szín nem szerepel a CPA-ban ebben az országban",
      "it": "Modello-colore non trovato in CPA per questo Paese",
      "it-IT": "Modello-colore non trovato in CPA per questo Paese",
      "ja": "StyleColorがこの国／地域のCPA内に見つかりませんでした",
      "ja-JP": "StyleColorがこの国／地域のCPA内に見つかりませんでした",
      "ko": "이 국가의 소비자 제품 API에서 해당 스타일 컬러를 찾을 수 없습니다",
      "ko-KR": "이 국가의 소비자 제품 API에서 해당 스타일 컬러를 찾을 수 없습니다",
      "nl": "De stijl/kleur is niet gevonden in CPA voor dit land",
      "nl-NL": "De stijl/kleur is niet gevonden in CPA voor dit land",
      "no-NO": "Produktfargen finnes ikke i CPA for dette landet",
      "pl": "Model/kolor nie występuje w CPA dla tego kraju",
      "pl-PL": "Model/kolor nie występuje w CPA dla tego kraju",
      "pt-BR": "A CorEstilo não foi encontrada na CPA deste país",
      "pt-PT": "O estilo-cor não está disponível no CPA deste país",
      "ru": "Модель/расцветка не найдена в CPA для этой страны",
      "ru-RU": "Модель/расцветка не найдена в CPA для этой страны",
      "sv": "Modellfärgen finns inte i CPA för det här landet",
      "sv-SE": "Modellfärgen finns inte i CPA för det här landet",
      "th": "ไม่พบสไตล์สีนี้ใน CPA สำหรับประเทศนี้",
      "th-TH": "ไม่พบสไตล์สีนี้ใน CPA สำหรับประเทศนี้",
      "tr": "Stil-Renk bu ülke için CPA'da bulunamadı",
      "tr-TR": "Stil-Renk bu ülke için CPA'da bulunamadı"
    },
    "RL_Error3": {
      "key": "RL_Error3",
      "en": "Error while adding StyelColors to Promotion",
      "ca": "S'ha produït un error en afegir productes a la promoció",
      "ca-ES": "S'ha produït un error en afegir productes a la promoció",
      "cs": "Chyba při přidávání barev stylů do promoakce",
      "cs-CZ": "Chyba při přidávání barev stylů do promoakce",
      "da": "Fejl under tilføjelse af modelfarver til kampagnen",
      "da-DK": "Fejl under tilføjelse af modelfarver til kampagnen",
      "de": "Fehler beim Hinzufügen der Style-Farben zum Angebot",
      "de-DE": "Fehler beim Hinzufügen der Style-Farben zum Angebot",
      "el": "Σφάλμα κατά την προσθήκη στιλ-χρωμάτων στην προσφορά",
      "el-GR": "Σφάλμα κατά την προσθήκη στιλ-χρωμάτων στην προσφορά",
      "en-GB": "Error while adding style colours to promotion",
      "es": "Se ha producido un error al añadir el modelo-color a la promoción",
      "es-ES": "Se ha producido un error al añadir el modelo-color a la promoción",
      "fi-FI": "Virhe StyleColorien lisäämisessä kampanjaan",
      "fr": "Une erreur est survenue lors de l'importation du style-coloris aux promotions",
      "fr-FR": "Une erreur est survenue lors de l'importation du style-coloris aux promotions",
      "he-IL": "שגיאה בהוספת סגנונות-צבעים למבצע",
      "hr-HR": "Pogreška prilikom dodavanja parametara model-boja u „Promociju”",
      "hu": "Hiba történt a stílus–szín promócióhoz való hozzáadása során",
      "hu-HU": "Hiba történt a stílus–szín promócióhoz való hozzáadása során",
      "it": "Errore durante l'aggiunta di modelli-colore alla promozione",
      "it-IT": "Errore durante l'aggiunta di modelli-colore alla promozione",
      "ja": "StyleColorをプロモーションに追加中にエラーが発生しました",
      "ja-JP": "StyleColorをプロモーションに追加中にエラーが発生しました",
      "ko": "프로모션에 스타일 컬러를 추가하는 과정에서 오류가 발생했습니다",
      "ko-KR": "프로모션에 스타일 컬러를 추가하는 과정에서 오류가 발생했습니다",
      "nl": "Fout bij het toevoegen van stijlen/kleuren aan promotie",
      "nl-NL": "Fout bij het toevoegen van stijlen/kleuren aan promotie",
      "no-NO": "Feil under tilføyelse av produktfarger i kampanjen",
      "pl": "Błąd podczas dodawania modelu/koloru do promocji",
      "pl-PL": "Błąd podczas dodawania modelu/koloru do promocji",
      "pt-BR": "Erro ao adicionar CoresEstilos à promoção",
      "pt-PT": "Erro ao adicionar estilos-cores à promoção",
      "ru": "Ошибка при добавлении моделей/расцветок в промоакцию",
      "ru-RU": "Ошибка при добавлении моделей/расцветок в промоакцию",
      "sv": "Fel uppstod när modellfärger lades till i kampanj",
      "sv-SE": "Fel uppstod när modellfärger lades till i kampanj",
      "th": "เกิดข้อผิดพลาดขณะเพิ่มสไตล์สีไปในโปรโมชัน",
      "th-TH": "เกิดข้อผิดพลาดขณะเพิ่มสไตล์สีไปในโปรโมชัน",
      "tr": "Stil-Renkler Promosyona eklenirken hata oluştu",
      "tr-TR": "Stil-Renkler Promosyona eklenirken hata oluştu"
    },
    "RL_Error4": {
      "key": "RL_Error4",
      "en": "Error while updating the Promotion Status",
      "ca": "S'ha produït un error en actualitzar l'estat de la promoció",
      "ca-ES": "S'ha produït un error en actualitzar l'estat de la promoció",
      "cs": "Chyba při aktualizaci stavu promoakce",
      "cs-CZ": "Chyba při aktualizaci stavu promoakce",
      "da": "Fejl under opdatering af kampagnestatus",
      "da-DK": "Fejl under opdatering af kampagnestatus",
      "de": "Fehler beim Aktualisieren des Angebotsstatus",
      "de-DE": "Fehler beim Aktualisieren des Angebotsstatus",
      "el": "Σφάλμα κατά την ενημέρωση της κατάστασης προσφοράς",
      "el-GR": "Σφάλμα κατά την ενημέρωση της κατάστασης προσφοράς",
      "en-GB": "Error while updating the promotion status",
      "es": "Se ha producido un error al actualizar el estado de la promoción",
      "es-ES": "Se ha producido un error al actualizar el estado de la promoción",
      "fi-FI": "Virhe kampanjan tilan päivittämisen yhteydessä",
      "fr": "Erreur lors de la mise à jour du statut de la promotion",
      "fr-FR": "Erreur lors de la mise à jour du statut de la promotion",
      "he-IL": "שגיאה בעדכון מצב המבצע",
      "hr-HR": "Pogreška prilikom ažuriranja „Statusa promocije”",
      "hu": "Hiba történt a promóció állapotának frissítése közben",
      "hu-HU": "Hiba történt a promóció állapotának frissítése közben",
      "it": "Errore durante l'aggiornamento dello stato della promozione",
      "it-IT": "Errore durante l'aggiornamento dello stato della promozione",
      "ja": "プロモーションのステータスを更新中にエラーが発生しました",
      "ja-JP": "プロモーションのステータスを更新中にエラーが発生しました",
      "ko": "프로모션 상태를 업데이트하는 과정에서 오류가 발생했습니다",
      "ko-KR": "프로모션 상태를 업데이트하는 과정에서 오류가 발생했습니다",
      "nl": "Fout bij het bijwerken van promotiestatus",
      "nl-NL": "Fout bij het bijwerken van promotiestatus",
      "no-NO": "Feil ved oppdatering av kampanjestatus",
      "pl": "Błąd podczas aktualizowania statusu promocji",
      "pl-PL": "Błąd podczas aktualizowania statusu promocji",
      "pt-BR": "Erro ao atualizar o status da promoção",
      "pt-PT": "Erro ao atualizar o estado da promoção",
      "ru": "Ошибка при обновлении статуса промоакции",
      "ru-RU": "Ошибка при обновлении статуса промоакции",
      "sv": "Fel uppstod när kampanjstatus uppdaterades",
      "sv-SE": "Fel uppstod när kampanjstatus uppdaterades",
      "th": "เกิดข้อผิดพลาดขณะอัพเดทสถานะโปรโมชัน",
      "th-TH": "เกิดข้อผิดพลาดขณะอัพเดทสถานะโปรโมชัน",
      "tr": "Promosyon Durumu güncellenirken hata oluştu",
      "tr-TR": "Promosyon Durumu güncellenirken hata oluştu"
    },
    "RL_Error5": {
      "key": "RL_Error5",
      "en": "Default Promotions are unavalable for this store",
      "ca": "No hi ha promocions predeterminades disponibles per a aquesta botiga",
      "ca-ES": "No hi ha promocions predeterminades disponibles per a aquesta botiga",
      "cs": "Výchozí promoakce nejsou pro tuto prodejnu dostupné",
      "cs-CZ": "Výchozí promoakce nejsou pro tuto prodejnu dostupné",
      "da": "Standardkampagner er ikke tilgængelige for denne butik",
      "da-DK": "Standardkampagner er ikke tilgængelige for denne butik",
      "de": "Standardangebote sind für diesen Store nicht verfügbar",
      "de-DE": "Standardangebote sind für diesen Store nicht verfügbar",
      "el": "Οι προεπιλεγμένες προσφορές δεν είναι διαθέσιμες για αυτό το κατάστημα",
      "el-GR": "Οι προεπιλεγμένες προσφορές δεν είναι διαθέσιμες για αυτό το κατάστημα",
      "en-GB": "Default promotions are unavailable for this store",
      "es": "Las promociones predeterminadas no están disponibles para esta tienda",
      "es-ES": "Las promociones predeterminadas no están disponibles para esta tienda",
      "fi-FI": "Oletuskampanjat eivät ole saatavana tässä myymälässä",
      "fr": "Les promotions par défaut ne sont pas disponibles pour ce magasin",
      "fr-FR": "Les promotions par défaut ne sont pas disponibles pour ce magasin",
      "he-IL": "אין מבצעי ברירת מחדל לחנות הזו",
      "hr-HR": "Zadane „Promocije” nisu dostupne za ovo prodajno mjesto",
      "hu": "Az alappromóciók nem elérhetők ehhez az üzlethez",
      "hu-HU": "Az alappromóciók nem elérhetők ehhez az üzlethez",
      "it": "Le promozioni predefinite non sono disponibili per questo punto vendita",
      "it-IT": "Le promozioni predefinite non sono disponibili per questo punto vendita",
      "ja": "このストアではデフォルトのプロモーションを利用できません",
      "ja-JP": "このストアではデフォルトのプロモーションを利用できません",
      "ko": "이 매장에는 기본 프로모션을 사용할 수 없습니다",
      "ko-KR": "이 매장에는 기본 프로모션을 사용할 수 없습니다",
      "nl": "Standaardpromoties zijn niet beschikbaar voor deze store",
      "nl-NL": "Standaardpromoties zijn niet beschikbaar voor deze store",
      "no-NO": "Standardkampanjer er ikke tilgjengelig for denne butikken",
      "pl": "Promocje domyślne nie są dostępne dla tego sklepu",
      "pl-PL": "Promocje domyślne nie są dostępne dla tego sklepu",
      "pt-BR": "Promoções padrão indisponíveis para esta loja",
      "pt-PT": "As promoções predefinidas não estão disponíveis para esta loja",
      "ru": "Промоакции по умолчанию недоступны для этого магазина",
      "ru-RU": "Промоакции по умолчанию недоступны для этого магазина",
      "sv": "Standardkampanjer ej tillgängliga för den här butiken",
      "sv-SE": "Standardkampanjer ej tillgängliga för den här butiken",
      "th": "ไม่มีโปรโมชันเริ่มต้นสำหรับ Store นี้",
      "th-TH": "ไม่มีโปรโมชันเริ่มต้นสำหรับ Store นี้",
      "tr": "Varsayılan Promosyonlar bu mağazada kullanılamıyor",
      "tr-TR": "Varsayılan Promosyonlar bu mağazada kullanılamıyor"
    },
    "RL_Error6": {
      "key": "RL_Error6",
      "en": "The StyleColor is already present in the upload. Please remove the duplicate item.",
      "ca": "El producte ja s'ha pujat. Elimina l'element duplicat.",
      "ca-ES": "El producte ja s'ha pujat. Elimina l'element duplicat.",
      "cs": "Barva stylu je již v nahrávání zahrnuta. Odstraňte prosím duplicitní položku.",
      "cs-CZ": "Barva stylu je již v nahrávání zahrnuta. Odstraňte prosím duplicitní položku.",
      "da": "Modelfarven er allerede med i overførslen. Fjern den duplikerede vare.",
      "da-DK": "Modelfarven er allerede med i overførslen. Fjern den duplikerede vare.",
      "de": "Der Style-/Farbcode ist schon im Upload enthalten. Bitte entferne den doppelten Eintrag.",
      "de-DE": "Der Style-/Farbcode ist schon im Upload enthalten. Bitte entferne den doppelten Eintrag.",
      "el": "Το στιλ-χρώμα υπάρχει ήδη στη μεταφόρτωση. Καταργήστε το διπλότυπο είδος.",
      "el-GR": "Το στιλ-χρώμα υπάρχει ήδη στη μεταφόρτωση. Καταργήστε το διπλότυπο είδος.",
      "en-GB": "The style colour is already present in the upload. Please remove the duplicate item.",
      "es": "El modelo-color ya está presente en la carga. Elimina el elemento duplicado.",
      "es-ES": "El modelo-color ya está presente en la carga. Elimina el elemento duplicado.",
      "fi-FI": "StyleColor on jo latauksessa. Poista kaksoiskappale.",
      "fr": "Le style-coloris a déjà été importé. Merci de supprimer l'article en double.",
      "fr-FR": "Le style-coloris a déjà été importé. Merci de supprimer l'article en double.",
      "he-IL": "הסגנון-צבע כבר נכלל בהעלאה. הסירו את הפריט הכפול.",
      "hr-HR": "Parametar model-boja već je prisutan u postupku prijenosa. Uklonite duplicirani artikl.",
      "hu": "Ez a stílus–szín már szerepel a feltöltésben. Kérjük, távolítsd el a duplikált tételt.",
      "hu-HU": "Ez a stílus–szín már szerepel a feltöltésben. Kérjük, távolítsd el a duplikált tételt.",
      "it": "Il modello-colore è già presente nell'upload. Rimuovi l'elemento duplicato.",
      "it-IT": "Il modello-colore è già presente nell'upload. Rimuovi l'elemento duplicato.",
      "ja": "このStyleColorはすでにアップロード内に存在しています。 重複するアイテムを削除してください。",
      "ja-JP": "このStyleColorはすでにアップロード内に存在しています。 重複するアイテムを削除してください。",
      "ko": "해당 스타일 컬러는 이미 업로드되어 있습니다. 중복된 아이템을 삭제해 주세요.",
      "ko-KR": "해당 스타일 컬러는 이미 업로드되어 있습니다. 중복된 아이템을 삭제해 주세요.",
      "nl": "De stijl/kleur is al aanwezig in de upload. Verwijder het dubbele item.",
      "nl-NL": "De stijl/kleur is al aanwezig in de upload. Verwijder het dubbele item.",
      "no-NO": "Produktfargen finnes allerede i opplastingen. Fjern den dupliserte varen.",
      "pl": "Model/kolor został już dodany do listy elementów do przesłania. Usuń zduplikowaną pozycję.",
      "pl-PL": "Model/kolor został już dodany do listy elementów do przesłania. Usuń zduplikowaną pozycję.",
      "pt-BR": "A CorEstilo já está listada no upload. Remova o item duplicado.",
      "pt-PT": "O estilo-cor já está presente no carregamento. Remova o artigo duplicado.",
      "ru": "Модель/расцветка уже добавлена в эту загрузку. Удалите дублирующийся товар.",
      "ru-RU": "Модель/расцветка уже добавлена в эту загрузку. Удалите дублирующийся товар.",
      "sv": "Modellfärgen finns redan i uppladdningen. Ta bort dubbletten.",
      "sv-SE": "Modellfärgen finns redan i uppladdningen. Ta bort dubbletten.",
      "th": "สไตล์สีนี้มีอยู่แล้วในการอัพโหลด โปรดลบรายการซ้ำออก",
      "th-TH": "สไตล์สีนี้มีอยู่แล้วในการอัพโหลด โปรดลบรายการซ้ำออก",
      "tr": "Stil-Renk, yüklemede zaten mevcut. Yinelenen öğeyi lütfen kaldır.",
      "tr-TR": "Stil-Renk, yüklemede zaten mevcut. Yinelenen öğeyi lütfen kaldır."
    },
    "RL_Error7": {
      "key": "RL_Error7",
      "en": "Invalid value provided for the column Division",
      "ca": "El valor de la columna \"Categoria\" no és vàlid",
      "ca-ES": "El valor de la columna \"Categoria\" no és vàlid",
      "cs": "Neplatná hodnota zadaná pro sloupec Divize",
      "cs-CZ": "Neplatná hodnota zadaná pro sloupec Divize",
      "da": "Ugyldig kode for kolonnen Afdeling",
      "da-DK": "Ugyldig kode for kolonnen Afdeling",
      "de": "Ungültiger Wert für die Spalte \"Bereich\"",
      "de-DE": "Ungültiger Wert für die Spalte \"Bereich\"",
      "el": "Καταχωρίστηκε μη έγκυρη τιμή για τη στήλη Τμήμα",
      "el-GR": "Καταχωρίστηκε μη έγκυρη τιμή για τη στήλη Τμήμα",
      "en-GB": "Invalid value provided for the column \"Division\"",
      "es": "Se ha indicado un valor no válido para la columna División",
      "es-ES": "Se ha indicado un valor no válido para la columna División",
      "fi-FI": "Virheellinen arvo Osasto-sarakkeessa",
      "fr": "Valeur incorrecte indiquée pour la colonne Division",
      "fr-FR": "Valeur incorrecte indiquée pour la colonne Division",
      "he-IL": "צוין ערך לא תקין בעמודת המחלקה",
      "hr-HR": "Neispravna vrijednost navedena u stupcu „Vrsta proizvoda”",
      "hu": "Érvénytelen érték került megadásra a Részleg oszlopban",
      "hu-HU": "Érvénytelen érték került megadásra a Részleg oszlopban",
      "it": "Valore non valido fornito per la colonna divisione",
      "it-IT": "Valore non valido fornito per la colonna divisione",
      "ja": "「部門」列に無効な値が入力されました",
      "ja-JP": "「部門」列に無効な値が入力されました",
      "ko": "부문 열에 입력한 값이 유효하지 않습니다",
      "ko-KR": "부문 열에 입력한 값이 유효하지 않습니다",
      "nl": "Ongeldige waarde opgegeven voor de kolom Divisie",
      "nl-NL": "Ongeldige waarde opgegeven voor de kolom Divisie",
      "no-NO": "Ugyldig verdi for kolonnen Avdeling",
      "pl": "Wartość dla kolumny Dział jest nieprawidłowa",
      "pl-PL": "Wartość dla kolumny Dział jest nieprawidłowa",
      "pt-BR": "Valor inválido inserido na coluna \"Divisão\"",
      "pt-PT": "Valor inválido fornecido para a coluna Divisão",
      "ru": "Для столбца «Отдел» указано недопустимое значение",
      "ru-RU": "Для столбца «Отдел» указано недопустимое значение",
      "sv": "Ogiltigt värde för kolumnen Avdelning",
      "sv-SE": "Ogiltigt värde för kolumnen Avdelning",
      "th": "ป้อนค่าที่ไม่ถูกต้องสำหรับคอลัมน์แผนก",
      "th-TH": "ป้อนค่าที่ไม่ถูกต้องสำหรับคอลัมน์แผนก",
      "tr": "Bölüm sütunu için geçersiz değer sağlandı",
      "tr-TR": "Bölüm sütunu için geçersiz değer sağlandı"
    },
    "RL_Error8": {
      "key": "RL_Error8",
      "en": "Only Unbinned Styles can be uploaded",
      "ca": "Només es poden pujar productes que no estiguin assignats a un bin",
      "ca-ES": "Només es poden pujar productes que no estiguin assignats a un bin",
      "cs": "Nahrány mohou být pouze styly mimo sektor",
      "cs-CZ": "Nahrány mohou být pouze styly mimo sektor",
      "da": "Du kan kun uploade gemte modeller",
      "da-DK": "Du kan kun uploade gemte modeller",
      "de": "Nur nicht gelagerte Styles können hochgeladen werden",
      "de-DE": "Nur nicht gelagerte Styles können hochgeladen werden",
      "el": "Μπορείτε να ανεβάσετε μόνο στιλ εκτός θέσης αποθήκευσης",
      "el-GR": "Μπορείτε να ανεβάσετε μόνο στιλ εκτός θέσης αποθήκευσης",
      "en-GB": "Only unbinned styles can be uploaded",
      "es": "Solo se pueden cargar productos que no estén en bins",
      "es-ES": "Solo se pueden cargar productos que no estén en bins",
      "fi-FI": "Et voit ladata roskakorissa olevia tyylejä",
      "fr": "Seuls les articles sortis du bin peuvent être importés",
      "fr-FR": "Seuls les articles sortis du bin peuvent être importés",
      "he-IL": "אפשר להעלות רק סגנונות לא בשטחי אחסון",
      "hr-HR": "Mogu se prenijeti samo „Modeli izvan spremnika”",
      "hu": "Csak tárolóból kivett stílusokat lehet feltölteni",
      "hu-HU": "Csak tárolóból kivett stílusokat lehet feltölteni",
      "it": "Solo i modelli non assegnati a un bin possono essere caricati",
      "it-IT": "Solo i modelli non assegnati a un bin possono essere caricati",
      "ja": "BINに入っていないスタイルのみがアップロード可能です",
      "ja-JP": "BINに入っていないスタイルのみがアップロード可能です",
      "ko": "수납되지 않은 스타일만 업로드할 수 있습니다",
      "ko-KR": "수납되지 않은 스타일만 업로드할 수 있습니다",
      "nl": "Alleen niet in de bak geplaatste stijlen kunnen worden geüpload",
      "nl-NL": "Alleen niet in de bak geplaatste stijlen kunnen worden geüpload",
      "no-NO": "Du kan kun laste opp produkter som ikke er kategorisert",
      "pl": "Można przesłać tylko modele poza sektorem zasobów",
      "pl-PL": "Można przesłać tylko modele poza sektorem zasobów",
      "pt-BR": "Só é possível fazer upload de estilos que não estão na categoria \"Compartimento\"",
      "pt-PT": "Só é possível carregar estilos sem secção",
      "ru": "Можно загрузить только модели без контейнера",
      "ru-RU": "Можно загрузить только модели без контейнера",
      "sv": "Endast modeller som inte har värdet Bin kan laddas upp.",
      "sv-SE": "Endast modeller som inte har värdet Bin kan laddas upp.",
      "th": "อัพโหลดได้เฉพาะสไตล์ที่ไม่จัดเก็บใน Bin เท่านั้น",
      "th-TH": "อัพโหลดได้เฉพาะสไตล์ที่ไม่จัดเก็บใน Bin เท่านั้น",
      "tr": "Yalnızca Kutuya Koyulmamış Stiller yüklenebilir",
      "tr-TR": "Yalnızca Kutuya Koyulmamış Stiller yüklenebilir"
    }
}
