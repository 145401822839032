const isNull = v => v === undefined || v === null;

const noOp = () => {};

const checkMaybe = (possiblyMaybe) => {
  if (possiblyMaybe instanceof Maybe) {
    return possiblyMaybe;
  }
  else {
    throw new Error('Expected Maybe type');
  }
};

class Maybe {

  constructor(value) {
    this.value = value;
  }

  isNone() {
    return isNull(this.value);
  }

  isSome() {
    return !this.isNone();
  }

  get() {
    if (this.isSome()) {
      return this.value;
    }
    else {
      throw new Error('Expected Some type');
    }
  }

  orElse(anotherValue) {
    return this.isSome()
    ? this.value
    : anotherValue;
  }

  orElseGet(anotherValueSupplier) {
    return this.isSome()
    ? this.value
    : anotherValueSupplier();
  }

  map(f) {
    return this.isSome()
    ? Maybe.of(f(this.value))
    : this;
  }

  flatMap(f) {
    return this.isSome()
    ? checkMaybe(f(this.value))
    : this;
  }

  filter(test) {
    return this.isSome() && test(this.value)
    ? this
    : Maybe.none();
  }

  peek(someConsumer, noneConsumer = noOp) {
    if (this.isSome()) {
      someConsumer(this.value);
    }
    else {
      noneConsumer();
    }
    return this;
  }

  orElseThrow(errorSupplier) {
    if (this.isSome()) {
      return this.value;
    }
    else {
      throw errorSupplier();
    }
  }

  static of(value) {
    return new Maybe(value);
  }

  static maybeOf(value) {
    return Maybe.of(value);
  }

  static none() {
    return Maybe.of();
  }

  static maybeNone() {
    return Maybe.none();
  }

  static ofTry(expr, errorConsumer = noOp) {
    try {
      return Maybe.of(expr());
    }
    catch (err) {
      errorConsumer(err);
      return Maybe.none();
    }
  }

}

module.exports = {
  Maybe
};
