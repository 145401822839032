const receipt_translations = require("../resources/receipt_translations.json");

/*
  Translate word based on locale passed in.
  If locale translation does not exist, split off the language from the locale and translate based on that language.
*/
module.exports = (word, language) => {
  let translation = null;
  try {
    translation = receipt_translations[word][language] || receipt_translations[word][language.split('-')[0]] || null;
    return translation;
  } catch (err) {
    return null;
  }
};