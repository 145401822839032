import { Close } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DROPDOWN_OPTION_ALL, PARTNER_COUNTRY_FETCH_ERR, PARTNER_NO_ASSOCIATED_PROMOTIONS_ERR } from '../../constants';
import useTranslate from '../../hooks/useTranslate';
import { getPartnerStores } from '../../redux/selectors';
import { setPartnerStores } from '../../redux/store';
import { storeViewsByPartner, patchPartnerPromotion } from '../../service-calls';
import {
  formatUniqueString,
  formatPromotionPatchDeleteItems,
  handleErrorMessage,
  handleResponse
} from '../../utils/formatting';
// eslint-disable-next-line import/named
import { getCountriesWherePartnerPresent } from '../../utils/promoteUtils';
import nikeStoreCountries from '../../utils/static/countriesWithNikeStores';
import { translationMappings } from '../../utils/static/id-mappings';
import { translateSets } from '../../utils/translationsUtils';
import {
  Input, CustomPanel, ButtonPopup, Select
} from '../reusable';

import '../reusable/SlideDisplay.css';

const formatSublabel = (p, translate) => translate(p.itemDetails?.length !== 1 ? 'RL_OffCodes' : 'RL_OffCode', p.discountPercentage, p.itemDetails?.length || 0);

// Update the magic 6 if we ever have more divisions (there are 6 groupings, so 'All' is all 6)
const formatDivisions = (p, translate) => (p.divisions.size === 6 ? translate('RL_AllDivisions') : formatUniqueString(translateSets(p.divisions, translate)));
// Update this magic 4 if we ever have more genders (there are 4 groupings, so 'All' is all 4)

const formatGenders = (p, translate) => `${p.genders.size > 1 ? translate('RL_Genders') : translate('RL_Gender')}: ${p.genders.size === 4 ? translate('RL_All') : formatUniqueString(translateSets(p.genders, translate))}`;

const PromotionPreview = ({
  canDelete, promotions, refreshPromotions, setActivePromotion, setClearError, setClearSuccess, partnerName
}) => {
  const partnerStores = useSelector(getPartnerStores);
  const [isClearing, setIsClearing] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [partnerCountries, setPartnerCountries] = useState('');
  const [partnerCountryFilter, setPartnerCountryFilter] = useState(DROPDOWN_OPTION_ALL);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const translate = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the country list for partners, same will be used (if available) in the Add /PromotionAdd.js
    if (isFetching && !Object.keys(partnerStores).length) {
      (async () => storeViewsByPartner(partnerName)
        .then((object) => {
          dispatch(setPartnerStores(object));
          return !Object.keys(object).length
            ? handleResponse(setPartnerCountries, [], setError, PARTNER_COUNTRY_FETCH_ERR)
            : handleResponse(setPartnerCountries, Object.keys(object), setError, '');
        })
        .catch((err) => handleResponse(setPartnerCountries, [], setError, handleErrorMessage(err)))
        .finally(() => {
          setIsFetching(false);
        }))();
    } else {
      setPartnerCountries(Object.keys(partnerStores));
      setIsFetching(false);
    }
  }, [dispatch, isFetching, partnerName, partnerStores]);

  const onDeletePromotionItems = async (promotion) => {
    setIsClearing(true);
    return patchPartnerPromotion(promotion.promotionId, formatPromotionPatchDeleteItems(promotion.itemDetails))
      .then((res) => (res.errors.length
        ? handleResponse(setClearSuccess, '', setClearError, translate('RL_ItemDeleteFail'))
        : handleResponse(setClearSuccess, translate('RL_ClearItemSuccess', promotion.name), setClearError, '')
      ))
      .catch((err) => handleResponse(setClearSuccess, '', setClearError, handleErrorMessage(err)))
      .finally(() => {
        setIsClearing(false);
        refreshPromotions();
      });
  };

  const onArrowClick = (promotionId) => {
    handleResponse(setClearSuccess, '', setClearError, '');
    return setActivePromotion(promotionId);
  };

  const filterPromotions = (promotion) => ((promotion.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 || promotion.itemDetails.some((i) => i.styleColor.indexOf(searchValue.toUpperCase()) !== -1)) && ((!partnerCountryFilter || partnerCountryFilter.value === '') || partnerCountryFilter.value === promotion.country));
  const filteredPromotions = promotions.filter((p) => filterPromotions(p));

  const translateLabel = useCallback(
    ({ label }) => translate(translationMappings[label]) || label,
    [translate]
  );

  const getPartnerCountryFilter = () => [DROPDOWN_OPTION_ALL, ...getCountriesWherePartnerPresent(partnerCountries)];

  return (
    <section className="ncss-col-sm-12 ta-sm-l mt3-sm mb3-sm">
      <Input
        id="Search Promotions"
        label={translate('RL_SearchPromotions')}
        placeholder={translate('RL_PromotionNameorStyleColor')}
        trailingIconContent={<Close />}
        value={searchValue}
        onChange={setSearchValue}
        onIconClick={() => setSearchValue('')}
      />

      <article className="va-sm-t pb4-sm">
        <Select
          errorMessage={error}
          getOptionLabel={translateLabel}
          id="partnerCountry"
          label="Filter Promotions by Country"
          options={getPartnerCountryFilter()}
          values={[partnerCountryFilter]}
          zIndex={2}
          onChange={setPartnerCountryFilter}
        />
      </article>
      {!filteredPromotions.length
        ? <article className="va-sm-t ta-sm-c">{PARTNER_NO_ASSOCIATED_PROMOTIONS_ERR}</article>
        : filteredPromotions
          .map((p) => (
            <article key={p.promotionId} className="va-sm-t ta-sm-l">
              <section className="slide-display">
                <section className="slide-display-info">
                  <CustomPanel
                    key={p.promotionId}
                    button={{ onClick: () => onArrowClick(p.promotionId), to: `/promotions/${p.promotionId}` }}
                    label={`${p.name} - ${nikeStoreCountries.filter((c) => c.value === p.country)[0].label}`}
                    sublabel={formatSublabel(p, translate)}
                  >
                    <>
                      <p className="body-2">{formatGenders(p, translate)}</p>
                      <p className="body-2">{formatDivisions(p, translate)}</p>
                      <p className="body-2">{p.startDate} - {p.endDate}</p>
                    </>
                  </CustomPanel>
                </section>
                {canDelete && (
                <ButtonPopup
                  className="bg-error u-rounded text-color-primary-light flx-jc-sm-c flx-ai-sm-c ml1-sm mb2-sm pr1-sm slide-display-button"
                  isDisabled={isClearing || !p.itemDetails.length}
                  label={translate('RL_Clear')}
                  style={{ height: 'auto', width: '65px' }}
                  subtitle={translate('RL_ProductDeleteConfirm4', p.itemDetails.length, p.name)}
                  onSubmit={async () => onDeletePromotionItems(p)}
                />
                )}
              </section>
            </article>
          ))}
    </section>
  );
};

PromotionPreview.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  partnerName: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({
    discountPercentage: PropTypes.number.isRequired,
    divisions: PropTypes.shape(),
    endDate: PropTypes.string,
    genders: PropTypes.shape(),
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      gtin: PropTypes.string,
      name: PropTypes.string,
      productId: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    promotionId: PropTypes.string.isRequired,
    startDate: PropTypes.string,
  })).isRequired,
  refreshPromotions: PropTypes.func.isRequired,
  setActivePromotion: PropTypes.func.isRequired,
  setClearError: PropTypes.func.isRequired,
  setClearSuccess: PropTypes.func.isRequired,
};

export default PromotionPreview;
