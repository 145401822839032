const defaultUnitsThresholds = {
  '24e5faba-9761-4669-bcc7-05060f0eeccb': '10', // Equipment
  'aaf5eae1-6889-4638-927a-527d511483a5': '30', // Footwear
  'd3169f60-9d03-4ba0-818f-58e1967ce8e3': '10' // Apparel
};

const productTypes = [
  { label: 'Apparel', value: 'd3169f60-9d03-4ba0-818f-58e1967ce8e3' },
  { label: 'Equipment', value: '24e5faba-9761-4669-bcc7-05060f0eeccb' },
  { label: 'Footwear', value: 'aaf5eae1-6889-4638-927a-527d511483a5' }
];

const unitsThresholds = [
  { label: '0', value: '0' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
  { label: '60', value: '60' },
  { label: '70', value: '70' },
  { label: '80', value: '80' },
  { label: '90', value: '90' },
  { label: '100', value: '100' },
];

module.exports = {
  defaultUnitsThresholds,
  productTypes,
  unitsThresholds,
};
