import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useTranslate from '../../hooks/useTranslate';
import {
  formatPromotionPatchAddItems, formatStoreInfo, handleErrorMessage, handleResponse
} from '../../utils/formatting';
import { ButtonSubmit, Creatable } from '../reusable';

const ProductsAdd = ({ promotion, patchPromotion }) => {
  const [styleColors, setStyleColors] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const translate = useTranslate();

  const setStyleColorsWithCreateDate = (values) => setStyleColors(values?.map((x) => (x.date ? x : { ...x, date: new Date().toISOString() })));

  useEffect(() => {
    const isFormComplete = (styleColors?.length);
    setError('');
    setSubmitDisabled(!(isFormComplete));
    setSuccess('');
  }, [styleColors]);

  const onSubmit = () => {
    setIsSubmitting(true);
    const newItems = styleColors.map(({ value, date }) => ({
      createDateTime: date,
      styleColor: value.toUpperCase(),
    }));
    (async () => patchPromotion(promotion.id, { updates: formatPromotionPatchAddItems(newItems), ...formatStoreInfo() })
      .then((res) => {
        if (res.errors?.length) {
          const { errorMessage, successMessage } = handleErrorMessage(res, newItems.map((item) => item.styleColor));
          return handleResponse(setSuccess, successMessage, setError, errorMessage);
        }
        return handleResponse(setSuccess, translate('RL_ProductsAddSuccess'), setError, '');
      })
      .catch((err) => handleResponse(setSuccess, '', setError, handleErrorMessage(err)))
      .finally(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        setIsSubmitting(false);
      }))();
  };

  return (
    <>
      <section className="ncss-col-sm-10 ta-sm-l mt3-sm mb3-sm">
        <Creatable
          isMulti
          isRequired
          id="styleColor"
          isDisabled={isSubmitting}
          label={translate('RL_StyleColors')}
          maxValues={50}
          value={styleColors}
          onChange={setStyleColorsWithCreateDate}
        />
      </section>
      <ButtonSubmit
        isDisabled={submitDisabled}
        isSubmitting={isSubmitting}
        label={translate(styleColors?.length !== 1 ? 'RL_Add2Products' : 'RL_Add1Product', styleColors?.length || 0)}
        messageError={error}
        messageSuccess={success}
        onSubmit={onSubmit}
      />
    </>
  );
};

ProductsAdd.propTypes = {
  patchPromotion: PropTypes.func.isRequired,
  promotion: PropTypes.shape().isRequired,
};

export default ProductsAdd;
