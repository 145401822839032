import { ButtonStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  appearance,
  ariaLabel,
  buttonClassName,
  icon,
  isDisabled,
  label,
  onClick,
  sectionClassName,
  size
}) => (
  <section className={sectionClassName}>
    <ButtonStyled
      appearance={appearance}
      ariaLabel={ariaLabel}
      className={buttonClassName}
      disabled={isDisabled}
      size={size}
      type="button"
      onClick={onClick}
    >
      {icon || label}
    </ButtonStyled>
  </section>
);

Button.defaultProps = {
  appearance: 'secondary',
  ariaLabel: 'Button',
  buttonClassName: 'mb2-sm',
  icon: null,
  isDisabled: false,
  label: 'Button',
  sectionClassName: '',
  size: 'small',
};

Button.propTypes = {
  appearance: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  icon: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  sectionClassName: PropTypes.string,
  size: PropTypes.string,
};

export default Button;
