import PropTypes from 'prop-types';
import React from 'react';

const Delete = ({ style }) => (
  <svg fill="none" height="24" style={style} title="delete icon" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 7.5V19.5" stroke="#111111" strokeMiterlimit="10" strokeWidth="1.5" />
    <path d="M9.75 7.5V19.5" stroke="#111111" strokeMiterlimit="10" strokeWidth="1.5" />
    <path d="M5.25 6V19.5C5.25 20.74 6.26 21.75 7.5 21.75H16.5C17.74 21.75 18.75 20.74 18.75 19.5V5.25H21.5" stroke="#111111" strokeMiterlimit="10" strokeWidth="1.5" />
    <path d="M18.75 5.25H21" stroke="#111111" strokeMiterlimit="10" strokeWidth="1.5" />
    <path d="M9 2.25H14.25C15.08 2.25 15.75 2.92 15.75 3.75C15.75 4.58 15.08 5.25 14.25 5.25H3" stroke="#111111" strokeMiterlimit="10" strokeWidth="1.5" />
  </svg>
);

Delete.defaultProps = {
  style: { margin: '0.1rem -1rem' }
};

Delete.propTypes = {
  style: PropTypes.shape()
};

export default Delete;
