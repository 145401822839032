import LocaleCode from 'locale-code';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_LOCALE } from '../../constants';
import { getLocale, getLocaleForToggleButton } from '../../redux/selectors';
import { toggleLocale } from '../../redux/store';
import { getIsTranslationsAvailable } from '../../utils/translationsUtils';
import { Button } from '../reusable';

const LocaleToggle = ({ location: { pathname } }) => {
  const storeLocale = useSelector(getLocale);
  const localeName = useSelector(getLocaleForToggleButton);
  const isTranslationsAvailable = getIsTranslationsAvailable();

  const dispatch = useDispatch();

  const onToggleLocale = useCallback(
    () => {
      dispatch(toggleLocale());
    },
    [dispatch]
  );

  if (pathname === '/login' || storeLocale === DEFAULT_LOCALE || !isTranslationsAvailable) return '';

  return (
    <div className="ncss-col-sm-6 ncss-col-sm-offset-6 ta-sm-c d-sm-flx flx-jc-sm-fe prl6-sm">
      <Button
        buttonClassName="ncss-btn-primary-dark bg-black mt3-sm btn-primary-dark"
        label={`Translate to ${LocaleCode.getLanguageName(localeName)}`}
        onClick={onToggleLocale}
      />
    </div>
  );
};

LocaleToggle.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default LocaleToggle;
