import PropTypes from 'prop-types';
import React, {
  useEffect
} from 'react';
import { Prompt } from 'react-router-dom/cjs/react-router-dom';

const LeavePageBlocker = ({ when, message }) => {
  useEffect(() => {
    if (!when) return () => {};

    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      // eslint-disable-next-line fp/no-mutation
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    };
  }, [when, message]);

  return <Prompt message={message} when={when} />;
};

LeavePageBlocker.propTypes = {
  message: PropTypes.string.isRequired,
  when: PropTypes.bool.isRequired,
};

export default LeavePageBlocker;
