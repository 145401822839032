import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getIsPartnerLogin } from '../redux/selectors';

const RouteInvalid = () => {
  const isPartnerLogin = useSelector(getIsPartnerLogin);
  return (window.location.pathname === '' || window.location.pathname === '/'
    ? <Redirect to={isPartnerLogin ? '/login' : '/promotions'} />
    : (
      <main className="ncss-col-sm-12 ta-sm-c">
        <h1 className="display-3 mt10-sm mb4-sm text-color-error">This Route is Invalid</h1>
        <p className="headline-4">The Promote App page you are trying to reach does not exist.</p>
        <p className="headline-4">Please navigate through the app via the navigation bar below.</p>
      </main>
    )
  );
};

export default RouteInvalid;
