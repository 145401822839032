const { sort } = require('@nike/rcf-fp');

// promotions including these words should be sorted first, and grouped in the order of these arrays
const prioritizedDivisions = ['BACKWALL', 'CLEARANCE'];
const prioritizedGenders = ['MENS', 'WOMENS', 'KIDS'];

const sortByPriority = (priorities, pieces, originalLeft, originalRight) => {
  const currentPriorities = priorities[0];
  const [left, right] = pieces[0];
  if (currentPriorities.includes(left) && currentPriorities.includes(right)) {
    // compare two strings with prioritized words
    const leftPriority = currentPriorities.findIndex((x) => x === left);
    const rightPriority = currentPriorities.findIndex((x) => x === right);

    const restOfPriorities = priorities.slice(1);
    const restOfPieces = pieces.slice(1);

    // eslint-disable-next-line no-nested-ternary
    return (leftPriority === rightPriority)
      ? (restOfPriorities.length && restOfPieces.length)
        ? sortByPriority(restOfPriorities, restOfPieces, originalLeft, originalRight)
        : originalLeft.localeCompare(originalRight)
      : leftPriority - rightPriority;
  }
  if (currentPriorities.includes(left)) {
    // only left is a prioritized word
    return -1;
  }
  if (currentPriorities.includes(right)) {
    // only right is a prioritized word
    return 1;
  }
  return originalLeft.localeCompare(originalRight);
};

// return a negative integer if 'left' goes before 'right', return a positive integer if 'right' goes before 'left'
const compare = ({ name: left }, { name: right }) => {
  if (!left) { return 1; }
  if (!right) { return -1; }
  const leftWords = left.split(' ');
  const leftFirst = leftWords[0];
  const leftLast = leftWords[leftWords.length - 1];
  const rightWords = right.split(' ');
  const rightFirst = rightWords[0];
  const rightLast = rightWords[rightWords.length - 1];

  return sortByPriority([prioritizedDivisions, prioritizedGenders], [[leftLast, rightLast], [leftFirst, rightFirst]], left, right);
};

/**
 * First, promotions are in 1 of 3 groups sorted by BACKWALL, CLEARANCE, then others.
 * In the first 2 groups, promotions are further sorted by MENS, WOMENS, KIDS, then others alphabetically.
 * Any remaining promotions in the 3rd group are sorted alphabetically.
 */
const sortPromotions = (promotions) => sort(promotions, compare);

module.exports = sortPromotions;
