const { Maybe } = require('./maybe');

const checkEither = (possiblyEither) => {
  if (possiblyEither instanceof Either) {
    return possiblyEither;
  }
  else {
    throw new Error('Expected Either type');
  }
};

const noOp = () => {};

class Either {
  constructor(isRight, value) {
    this.m_isRight = isRight;
    this.m_value = value;
  }

  isRight() {
    return this.m_isRight;
  }

  isLeft() {
    return !this.isRight();
  }

  getRight() {
    if (this.isRight()) {
      return this.m_value;
    }
    else {
      throw new Error('Expected Right type');
    }
  }

  get() {
    return this.getRight();
  }

  getLeft() {
    if (this.isLeft()) {
      return this.m_value;
    }
    else {
      throw new Error('Expected Left type');
    }
  }

  toMaybe() {
    return this.isRight()
      ? Maybe.of(this.m_value)
      : Maybe.none();
  }

  map(f) {
    return this.isRight()
    ? Either.right(f(this.m_value))
    : this;
  }

  mapLeft(f) {
    return this.isLeft()
    ? Either.left(f(this.m_value))
    : this;
  }

  flatMap(f) {
    return this.isRight()
    ? checkEither(f(this.m_value))
    : this;
  }

  flatMapLeft(f) {
    return this.isLeft()
    ? checkEither(f(this.m_value))
    : this;
  }

  peek(rightConsumer, leftConsumer = noOp) {
    if (this.isRight()) {
      rightConsumer(this.m_value);
    }
    else {
      leftConsumer(this.m_value);
    }
    return this;
  }

  peekLeft(leftConsumer) {
    return this.peek(noOp, leftConsumer);
  }

  orElse(anotherValue) {
    return this.isRight()
    ? this.m_value
    : anotherValue;
  }

  orElseGet(anotherValueSupplier) {
    return this.isRight()
    ? this.m_value
    : anotherValueSupplier();
  }

  fold(leftTransformer, rightTransformer) {
    return this.isRight()
    ? rightTransformer(this.m_value)
    : leftTransformer(this.m_value);
  }

  swap() {
    if (this.isRight()) {
      return Either.left(this.m_value);
    }
    else {
      return Either.right(this.m_value);
    }
  }

  orElseThrow(f) {
    if (this.isRight()) {
      return this.m_value;
    }
    else {
      throw f(this.m_value);
    }
  }

  static right(value) {
    return new Either(true, value);
  }

  static eitherRight(value) {
    return Either.right(value);
  }

  static left(value) {
    return new Either(false, value);
  }

  static eitherLeft(value) {
    return Either.left(value);
  }

  static ofTry(expr) {
    try {
      const value = expr();
      return Either.right(value);
    }
    catch (err) {
      return Either.left(err);
    }
  }

}

module.exports = {
  Either
};
