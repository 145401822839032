import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useTranslate from '../../hooks/useTranslate';
import { postMarkdown } from '../../service-calls';
import {
  formatDate, handleErrorMessage, handleResponse, isGTIN, isStyleColor,
} from '../../utils/formatting';
import {
  ButtonSubmit, Creatable, InputDate, CurrencyInput,
} from '../reusable';

const MarkdownForm = ({ gtin }) => {
  const [price, setPrice] = useState(0);
  const [priceInputError, setPriceInputError] = useState('');
  const [startDate, setStartDate] = useState(formatDate(moment().add(1, 'day')));
  const [code, setCode] = useState(gtin ? { label: gtin, value: gtin } : undefined);
  const [codeIsGTIN, setCodeIsGTIN] = useState(!!gtin);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const translate = useTranslate();
  // If we set the code state variable as something not the value passed in as gtin,
  // then we have removed the scanner's GTIN value, so we force the user to enter a Style Color
  useEffect(() => {
    setCodeIsGTIN(code?.value === gtin);
  }, [code, gtin]);

  // every time the property gtin is updated, update the state variable for code
  useEffect(() => {
    setCode(gtin ? { label: gtin, value: gtin } : undefined);
    setCodeIsGTIN(!!gtin);
  }, [gtin]);

  useEffect(() => {
    setSubmitDisabled(!(price && startDate && code?.value) || !!priceInputError);
  }, [price, startDate, code, priceInputError]);

  useEffect(() => {
    setPriceInputError('');
    !price || Number(price) || setPriceInputError(translate('RL_PriceValidation'));
  }, [price, translate]);

  const onSubmit = () => {
    setIsSubmitting(true);
    (async () => postMarkdown({
      gtin: isGTIN(code.value) ? code.value : undefined,
      price: Number(price),
      startDate,
      styleColor: isStyleColor(code.value) ? code.value.toUpperCase() : undefined,
    })
      .then((res) => {
        if (res.errors?.length) {
          const { errorMessage, successMessage } = handleErrorMessage(res, [code.value]);
          return handleResponse(setSuccess, successMessage, setError, errorMessage);
        }
        return handleResponse(setSuccess, translate('RL_MarkdownAddSuccess'), setError, '');
      })
      .catch((err) => {
        const { errorMessage, successMessage } = handleErrorMessage(err, [code.value]);
        return handleResponse(setSuccess, successMessage, setError, errorMessage);
      })
      .finally(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        setIsSubmitting(false);
      }))();
  };

  return (
    <>
      <section className="ncss-col-sm-10 ta-sm-l mt3-sm mb3-sm">
        <Creatable
          isRequired
          id={codeIsGTIN ? 'gtin' : 'styleColor'}
          isDisabled={isSubmitting}
          label={codeIsGTIN ? 'GTIN' : translate('RL_StyleColor')}
          value={code}
          onChange={setCode}
        />
        <CurrencyInput
          setAmount={setPrice}
        />
        <InputDate
          isRequired
          id="startDate"
          label={translate('RL_StartDate')}
          minDate={formatDate(moment().add(1, 'day'))}
          value={startDate}
          onChange={setStartDate}
        />
      </section>
      <ButtonSubmit
        isDisabled={submitDisabled}
        isSubmitting={isSubmitting}
        label={translate('RL_AddMarkdown')}
        messageError={error}
        messageSuccess={success}
        onSubmit={onSubmit}
      />
    </>
  );
};

MarkdownForm.defaultProps = {
  gtin: '',
};

MarkdownForm.propTypes = {
  gtin: PropTypes.string,
};

export default MarkdownForm;
