import { Edit } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import useTranslate from '../../hooks/useTranslate';
import { patchPartnerPromotion, getPartnerPromotion } from '../../service-calls';
import {
  handleResponse,
  handleErrorMessage,
  formatPromotions
} from '../../utils/formatting';
import {
  Back, Button, ButtonAdd, ContentLoads, Messages,
} from '../reusable';

import ProductsDisplay from './ProductsDisplay';
import ProductsForm from './ProductsForm';
import PromotionEdit from './PromotionEdit';

const PromotionDetail = ({ canAdd, canEdit, promotion: propPromotion }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [promotion, setPromotion] = useState({ ...propPromotion });
  const [isFetching, setIsFetching] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState('');
  const [error, setError] = useState('');
  const [successUpdate, setSuccessUpdate] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isScanning, setIsScanning] = useState(false);

  const translate = useTranslate();

  useEffect(() => {
    // getPartnerPromotion (refresh the promotions) every time we set isFetching to true
    if (isFetching && propPromotion) {
      (async () => getPartnerPromotion(propPromotion.promotionId)
        .then((res) => handleResponse(setPromotion, formatPromotions([res])[0], setError, ''))
        .catch((err) => handleResponse(() => { }, undefined, setError, handleErrorMessage(err)))
        .finally(() => setIsFetching(false)))();
    }
  }, [isFetching, propPromotion]);

  const toggleAddProductsPage = async () => {
    isAdding && setIsFetching(true);
    return setIsAdding((wasAdding) => !wasAdding);
  };

  const toggleEditPromotionPage = async () => {
    isEditing && setIsFetching(true);
    return setIsEditing((wasEditing) => !wasEditing);
  };

  const toggleDeleteProductsPage = async () => {
    setIsFetching(true);
  };

  const promotionId = useMemo(
    () => {
      const { status, gppsPromotionId, discountPercentage } = promotion;
      if (status === 'PENDING_SYNC' && gppsPromotionId !== 'NOT_CREATED') {
        return <span className="text-color-accent">{translate('RL_PendingSync')}</span>;
      } else if (!status || discountPercentage === 0 || status === 'NO_ITEMS') {
        return <span className="text-color-accent">{translate('RL_PausedPromotion')}</span>;
      }
      return gppsPromotionId;
    },
    [promotion, translate]
  );

  // eslint-disable-next-line no-nested-ternary
  return (propPromotion
    ? (
      <>
        {(isEditing && (
          <PromotionEdit
            patchPartnerPromotion={patchPartnerPromotion}
            promotion={promotion}
            onClose={async () => {
              await toggleEditPromotionPage();
              handleResponse(setSuccessUpdate, '', setErrorUpdate, '');
              setIsScanning(false);
              setIsEditing(false);
            }}
          />
        ))}
        {(!isEditing && !isScanning) && (
          <>
            <article className="ncss-row">
              <section className="ncss-col-sm-8">
                <Back label={translate('RL_BackToPromotions')} link="/promotions" />
              </section>
              {canAdd && (
                <section className="ncss-col-sm-4">
                  <section className="d-sm-flx flx-jc-sm-fe">
                    {canEdit && (
                      <Button
                        icon={(<Edit style={{ margin: '0.27rem -1.5rem' }} title={translate('RL_EditPromotion')} />)}
                        sectionClassName="pr1-sm"
                        onClick={async () => {
                          await toggleEditPromotionPage();
                        }}
                      />
                    )}
                  </section>
                </section>
              )}
            </article>
            <h2 className="headline-1 text-color-accent mt6-sm mb3-sm" style={{ overflowWrap: 'anywhere' }}>{promotion.name}</h2>
            <h5 className="headline-5 mt3-sm">{translate('RL_PromotionID')}: {promotionId}</h5>
            <h3 className="headline-4 mt3-sm">{`${translate('RL_Discount')}: ${promotion.discountPercentage}%`}</h3>
            <h3 className="headline-4 mt2-sm mb2-sm">{`${translate('RL_Ends')}: ${promotion.endDate}`}</h3>
            <ContentLoads error={error} isLoading={isFetching}>
              <Messages error={errorUpdate} success={successUpdate} />
              {canAdd && <ButtonAdd isAdding={isAdding} onToggle={toggleAddProductsPage} />}
              {isAdding || isScanning
                ? <ProductsForm patchPartnerPromotion={patchPartnerPromotion} promotion={promotion} />
                : (
                  <ProductsDisplay
                    canDelete={canEdit}
                    products={promotion.itemDetails || []}
                    promotion={promotion}
                    setProducts={(itemDetails) => setPromotion((prevPromotion) => ({ ...prevPromotion, itemDetails }))}
                    title={translate('RL_Products')}
                    onDeleteProduct={toggleDeleteProductsPage}
                  />
                )}
            </ContentLoads>
          </>
        )}
      </>
    )
    : <Redirect to="/promotions" />
  );
};

PromotionDetail.defaultProps = {
  promotion: undefined
};

PromotionDetail.propTypes = {
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  promotion: PropTypes.shape({
    discountPercentage: PropTypes.number.isRequired,
    divisions: PropTypes.shape().isRequired,
    endDate: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    genders: PropTypes.shape().isRequired,
    gppsPromotionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    promotionId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};

export default PromotionDetail;
