import { ButtonStyled } from '@nike/nike-design-system-components';
import { Close, Plus } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

const ButtonAdd = ({
  isAdding,
  isDisabled,
  onToggle,
}) => {
  const translate = useTranslate();

  return (
    <nav className="d-sm-flx flx-jc-sm-fe">
      <ButtonStyled
        appearance="secondary"
        ariaLabel={isAdding ? 'Cancel' : 'Add'}
        className="mb2-sm"
        disabled={isDisabled}
        size="small"
        type="button"
        onClick={onToggle}
      >
        {isAdding
          ? <Close style={{ margin: '0.32rem -1rem' }} title={translate('RL_Cancel')} />
          : <Plus size="l" style={{ margin: '-0.47rem -1.5rem' }} title={translate('RL_Add1Product')} />}
      </ButtonStyled>
    </nav>
  );
};

ButtonAdd.defaultProps = {
  isAdding: false,
  isDisabled: false,
};

ButtonAdd.propTypes = {
  isAdding: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default ButtonAdd;
