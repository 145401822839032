/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
const lodash = require('lodash');
const { promotionTranslate } = require('rcffus-sps-translations');

const { CSV_UPLOAD } = require('../constants');
require('../redux/selectors');

const REQUIRED_FIELDS = ['styleColor', 'gender', 'division', 'bin', 'price'];

const ERROR_TRANSLATION_MAP = {
  DDB_EXCEPTION_ITEM_INSERT: 'RL_Error3',
  DDB_EXCEPTION_PROMOTION_UPDATE: 'RL_Error4',
  DEFAULT_PROMOTIONS_NOT_FOUND_IN_DDB: 'RL_Error5',
  DUPLICATE_ITEM: 'RL_Error6',
  VALIDATE_CLEARANCE: 'RL_Error1',
  VALIDATE_DIVISION: 'RL_Error7',
  VALIDATE_STYLECOLOR: 'RL_Error2',
  VALIDATE_UNBINNED: 'RL_Error8',
};

const getErrorTranslation = (key) => ERROR_TRANSLATION_MAP[key] || 'RL_UnknownError';

const getColumns = (localeForTranslation = 'en-US') => ([
  {
    displayName: promotionTranslate('RL_RowNumber', localeForTranslation), id: 'rowNo', isRequiredInUiReport: true, width: 1
  },
  { displayName: 'Upload Id', id: 'uploadId' },
  {
    displayName: promotionTranslate('RL_StyleColor', localeForTranslation), id: 'styleColor', isRequiredInUiReport: true, width: 2
  },
  { displayName: 'Gender', id: 'gender' },
  { displayName: 'Division', id: 'division' },
  { displayName: 'Bin', id: 'bin' },
  { displayName: 'Price', id: 'price' },
  {
    classRenderer: (value) => {
      if (value === CSV_UPLOAD.STATUS_PROCESSING) return 'u-bold text-color-processing';
      return value === CSV_UPLOAD.STATUS_FAILURE ? 'u-bold text-color-error' : 'u-bold text-color-success';
    },
    displayName: promotionTranslate('RL_UploadStatus', localeForTranslation),
    id: 'status',
    isRequiredInUiReport: true,
    translationEnabled: true,
    width: 3
  },
  {
    classRenderer: (value) => (value.length ? 'u-bold text-color-error' : 'u-bold text-color-success'),
    displayName: promotionTranslate('RL_Errors', localeForTranslation),
    id: 'errors',
    isRequiredInUiReport: true,
    isStatusColorRequired: true,
    renderer: (value) => value?.map((error) => getErrorTranslation(error.key)).join(','),
    translationEnabled: true,
    width: 5
  },
]);

const columnsConfig = {
  colsRequiredInUpload: [{ key: 'styleColor', value: 'Style-Color' }, { key: 'gender', value: 'Gender' }, { key: 'division', value: 'Division' }, { key: 'bin', value: 'Bin' }, { key: 'price', value: 'Price' }],
  colsToDisplayInExport: ['styleColor', 'gender', 'division', 'bin', 'price', 'status', 'errors'],
  colsToDisplayInUIReport: ['rowNo', 'styleColor', 'status', 'errors'],
};

const transformHeaders = (headers = []) => headers.map((header) => ({ csvHeader: header, csvKey: lodash.camelCase(header) }));
const getUiColumns = (localeForTranslation) => getColumns(localeForTranslation)?.filter((col) => col.isRequiredInUiReport);
const getExportColumns = () => getColumns()?.filter((col) => columnsConfig.colsToDisplayInExport.includes(col.id));
const filterStyles = (style, filter) => filter === 'All' || style.status === filter;
const isMobile = () => Math.min(window.screen.width, window.screen.height) < 768 && window.navigator.maxTouchPoints > 0;
const isBohApp = () => !isMobile();

const extractRequiredHeaders = (headers) => headers?.map((header, colIdx) => {
  const csvKey = lodash.camelCase(header);
  const isRequired = !!columnsConfig.colsRequiredInUpload.find((item) => item.key === csvKey);
  return {
    colIdx, csvHeader: header, csvKey, isRequired
  };
}).filter((item) => item.isRequired);

const extractNecessaryData = (data, requiredkeys) => data.map((row, idx) => [(idx === 0 ? 'rowNo' : (idx)), ...lodash.at(row, requiredkeys)]);

const getMissingColumns = (transformedHeaders) => columnsConfig.colsRequiredInUpload.map(({ key, value }) => (transformedHeaders.find((item) => item.csvKey === key) ? null : value)).filter((item) => item);

const chunks = (array, chunkSize) => lodash.chunk(array, chunkSize);

const getProgress = (completed, total) => parseInt((completed / total) * 100, 10);

const transformCsvToJsonObj = (csvArr) => {
  const headers = transformHeaders(csvArr[0]);
  const csvArrWithoutHeaders = csvArr.slice(1);
  const itemIds = {};
  const rows = csvArrWithoutHeaders.reduce((agg, arr, idx) => {
    agg.push(arr.reduce((obj, item, colIdx) => {
      if (colIdx === 0) obj.rowNo = idx + 1;
      if (REQUIRED_FIELDS.includes(headers[colIdx].csvKey)) {
        obj[headers[colIdx].csvKey] = item;
      }
      return obj;
    }, {}));
    return agg;
  }, []);
  return { headers, itemIds, rows };
};

const Test = { getColumns, getErrorTranslation };
module.exports = {
  chunks, extractNecessaryData, extractRequiredHeaders, filterStyles, getErrorTranslation, getExportColumns, getMissingColumns, getProgress, getUiColumns, isBohApp, Test, transformCsvToJsonObj
};
