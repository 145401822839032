import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

const Table = ({ columns, data }) => {
  const translate = useTranslate();

  const formatValue = (item, id, renderer, translationEnabled) => {
    const renderedValue = renderer ? renderer(item[id], item) : item[id];

    const multipleValues = renderedValue?.toString().split(',');
    if (multipleValues.length > 1) {
      return translationEnabled ? multipleValues.map((error) => translate(error)).join(', ') : renderedValue;
    }
    return (translationEnabled ? translate(renderedValue) : renderedValue);
  };

  return (
    <div className="ncss-container fixed-fluid">
      <div key="table-headers" className="ncss-row bg-black text-color-primary-light u-bold p2-lg">
        {columns.map(({ displayName, width, id }) => <div key={`table-header-${id}`} className={`ncss-col-sm-${width}`}>{displayName}</div>)}
      </div>
      <div key="table-data" style={{ maxHeight: '60vh', overflowX: 'hidden', overflowY: 'scroll' }}>
        {data.map((item, idx) => (
          <div key={`table-row-${idx + 1}`} className={`ncss-row p2-lg ${idx % 2 !== 0 ? 'bg-primary-grey' : ''}`}>
            {columns.map(({
              id, width, renderer, classRenderer, translationEnabled
            }) => {
              const className = classRenderer ? classRenderer(item[id], item) : '';
              return <div key={`table-row-${idx + 1}-${id}`} className={`ncss-col-sm-${width} ${className}`}>{formatValue(item, id, renderer, translationEnabled) }</div>;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Table;
