import { Close } from '@nike/nike-design-system-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import useTranslate from '../../hooks/useTranslate';
import { patchPartnerPromotion } from '../../service-calls';
import {
  formatDate, formatPromotionPatchUpdate, handleErrorMessage, handleResponse
} from '../../utils/formatting';
import {
  Button, ButtonSubmit, DiscountPercentages, Input, InputDate
} from '../reusable';

const PromotionEdit = ({
  onClose,
  promotion: propPromotion,
}) => {
  const [promotion, setPromotion] = useState({ ...propPromotion });
  const [discountPercentage, setDiscountPercentage] = useState(promotion.discountPercentage);
  const [endDate, setEndDate] = useState(formatDate(moment(promotion.endDateTime)));
  const [name, setName] = useState(promotion.name);

  const [dateError, setDateError] = useState('');
  const [nameError, setNameError] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState('');
  const [successUpdate, setSuccessUpdate] = useState('');

  const translate = useTranslate();
  const attributesChanged = (
    discountPercentage !== promotion.discountPercentage
    || endDate !== formatDate(moment(promotion.endDateTime))
    || name !== promotion.name
  );

  useEffect(() => {
    const isFormComplete = (name && (discountPercentage || discountPercentage === 0) && endDate);
    // If the endDate is set to 'tomorrow' during the window of time between a GPPS sync and 'tomorrow',
    // that will cause the promotion to get skipped during the next scheduled sync to GPPS.
    // Thus, we require the new endDate to be at least 2 days out to prevent a possible desync.
    const isDateValid = (endDate === promotion.endDate) || (moment().add(2, 'd').startOf('day') <= moment(endDate));
    const isNameValid = /^[a-zA-Z0-9 ]*$/.test(name);
    const isPromotionNameValid = !/\s{2,}/.test(name);

    setNameError((!isNameValid || !isPromotionNameValid) ? translate('RL_PromotionNameValidation2') : '');
    setDateError(isDateValid ? '' : translate('RL_EndDateValidation1'));

    setErrorUpdate('');
    setSubmitDisabled(!(isFormComplete && attributesChanged && isDateValid && isNameValid && isPromotionNameValid));
  }, [attributesChanged, discountPercentage, endDate, name, promotion.endDate, translate]);

  const mapUpdates = (updates) => Object.entries(updates).filter(([key, value]) => (
    key === 'endDateTime'
      ? formatDate(moment(value)) !== formatDate(moment(promotion.endDateTime))
      : value !== promotion[key]
  )).map(([key, value]) => formatPromotionPatchUpdate(key, value));

  const onUpdate = () => {
    setErrorUpdate('');
    setSuccessUpdate('');
    setIsUpdating(true);
    const updates = {
      discountPercentage,
      endDateTime: moment(endDate).endOf('day').toISOString(true),
      name,
    };
    (async () => patchPartnerPromotion(promotion.promotionId, mapUpdates(updates))
      .then((updatedPromotion) => {
        setPromotion(updatedPromotion);
        return handleResponse(setSuccessUpdate, translate('RL_PromotionUpdateSuccess'), setErrorUpdate, '');
      })
      .catch((err) => handleResponse(() => {}, null, setErrorUpdate, handleErrorMessage(err)))
      .finally(() => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        setIsUpdating(false);
      }))();
  };

  return (
    <div>
      <nav className="d-sm-flx flx-jc-sm-fe">
        <Button
          icon={<Close style={{ margin: '0.32rem -1rem' }} title="Cancel" />}
          onClick={onClose}
        />
      </nav>
      <section className="ncss-col-sm-10 ta-sm-l mb3-sm">
        {promotion.createdBy === 'DEFAULT_PROMOTION' && (
          <p className="body-3">{translate('RL_DefaultPromotion')}</p>
        )}
        {promotion.createdBy !== 'DEFAULT_PROMOTION' && (
          <Input
            isRequired
            showCharacterCount
            error={!name || !!nameError}
            errorMessage={nameError}
            id="name"
            label={translate('RL_PromotionName')}
            maxLength={21}
            minLength={1}
            value={name}
            onChange={setName}
          />
        )}
        <DiscountPercentages
          isRequired
          discountPercentage={discountPercentage}
          onChange={setDiscountPercentage}
        />
        {promotion.createdBy !== 'DEFAULT_PROMOTION' && (
          <InputDate
            isRequired
            errorMessage={dateError}
            id="endDate"
            label={translate('RL_EndDate')}
            minDate={formatDate(moment().add(2, 'd').startOf('day'))}
            value={endDate}
            onChange={setEndDate}
          />
        )}
      </section>
      <ButtonSubmit
        isDisabled={submitDisabled}
        isSubmitting={isUpdating}
        label={translate('RL_SaveChanges')}
        messageError={errorUpdate}
        messageSuccess={successUpdate}
        sectionClassName="mb3-sm"
        onSubmit={onUpdate}
      />
    </div>
  );
};

PromotionEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion: PropTypes.shape().isRequired,
};

export default PromotionEdit;
