import { DropDownStyled, SwitchStyled, SwitchOptionStyled } from '@nike/nike-design-system-components';
import { Default as _, match } from '@nike/rcf-fp';
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { getStockOnHand } from '../../service-calls';
import {
  formatStockOnHand, sortStockOnHand, handleErrorMessage, handleResponse
} from '../../utils/formatting';
import { translationMappings } from '../../utils/static/id-mappings';
import { defaultUnitsThresholds, productTypes, unitsThresholds } from '../../utils/static/soh-values';
import { ContentLoads } from '../reusable';

import SOHHome from './SOHHome';
import './index.css';

const StockOnHand = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [fetchError, setFetchError] = useState('');

  const [soh, setSOH] = useState([]);
  const [sohSortDirection, setSohSortDirection] = useState('desc');
  const [productType, setProductType] = useState('d3169f60-9d03-4ba0-818f-58e1967ce8e3');
  const [quantityFilter, setQuantityFilter] = useState({ filter: 'minimumQuantity', quantity: '10' });

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');

  const translate = useTranslate();

  const location = useLocation();

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      (async () => Auth.reAuthorize()
        .then(() => true)
        .catch(() => false))();
    }
    if (isFetching) {
      (async () => getStockOnHand(location.state?.storeId, productType, { [quantityFilter.filter]: quantityFilter.quantity })
        .then(({ objects }) => handleResponse(setSOH, formatStockOnHand(objects, sohSortDirection), setFetchError, ''))
        .catch((err) => handleResponse(setSOH, [], setFetchError, handleErrorMessage(err)))
        .finally(() => setIsFetching(false)))();
    }
  }, [isFetching, location.state, quantityFilter, productType, sohSortDirection]);

  const handleDelete = (errors, successes, numberOfPromotionsUpdated, numberOfProductsDeleted) => {
    const errorsDeleting = errors.size > 0;
    const someSuccessesDeleting = successes.size > 0;
    const allSuccessesDeleting = successes.size === numberOfPromotionsUpdated;

    const partialSuccess = () => errorsDeleting || someSuccessesDeleting;
    const allSuccess = () => !errorsDeleting && allSuccessesDeleting && numberOfProductsDeleted !== 1;
    const singleSuccess = () => !errorsDeleting && allSuccessesDeleting && numberOfProductsDeleted === 1;

    const [successMessage, errorMessage] = match()(
      [singleSuccess, [translate('RL_PromotionDeleteSuccess1'), '']],
      [allSuccess, [translate('RL_PromotionDeleteSuccess2'), '']],
      [partialSuccess, ['', translate('RL_ItemDeleteFail')]],
      [_, ['', '']],
    );
    handleResponse(setDeleteSuccess, successMessage, setDeleteError, errorMessage);

    // triggers the above useEffect to getStockOnHand
    setIsFetching(true);
  };

  const handleFilterChange = () => {
    handleResponse(setDeleteSuccess, '', setDeleteError, '');
    setIsFetching(true);
  };

  const toggleSOHSortDirection = () => {
    const toggledDirection = sohSortDirection === 'desc' ? 'asc' : 'desc';
    handleResponse(setSOH, sortStockOnHand(soh, toggledDirection), setFetchError, '');
    setSohSortDirection(toggledDirection);
  };

  const translatedProductTypes = useMemo(
    () => productTypes.map(({ label, value }) => ({ label: translate(translationMappings[label]), value })),
    [translate],
  );

  return (
    <>
      <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_StockOnHand')}</h1>
      <section className="ncss-col-sm-12 full">
        <DropDownStyled
          autoComplete="on"
          disabled={isFetching || isDeleting}
          id="divisionIdInput"
          label={translate('RL_ProductType')}
          name="Product Type"
          options={translatedProductTypes}
          value={productType}
          onChange={({ target: { value } }) => {
            setProductType(value);
            setQuantityFilter({ ...quantityFilter, quantity: defaultUnitsThresholds[value] });
            handleFilterChange();
          }}
        />
      </section>
      <section className="d-sm-flx">
        <SwitchStyled
          classes={{ Container: 'mt4-sm mr4-sm' }}
          disabled={isFetching || isDeleting}
          selectedValue={quantityFilter.filter}
          onClick={() => {
            setQuantityFilter({ ...quantityFilter, filter: (quantityFilter.filter === 'minimumQuantity' ? 'maximumQuantity' : 'minimumQuantity') });
            handleFilterChange();
          }}
        >
          <SwitchOptionStyled
            id="minimumQuantitySwitch"
            label=">="
            value="minimumQuantity"
          />
          <SwitchOptionStyled
            id="maximumQuantitySwitch"
            label="<="
            value="maximumQuantity"
          />
        </SwitchStyled>
        <DropDownStyled
          autoComplete="on"
          disabled={isFetching || isDeleting}
          id="unitsThresholdInput"
          label={translate('RL_Units')}
          name="Units Threshold"
          options={unitsThresholds}
          value={quantityFilter.quantity}
          onChange={({ target: { value } }) => {
            setQuantityFilter({ ...quantityFilter, quantity: value });
            handleFilterChange();
          }}
        />
      </section>
      <ContentLoads error={fetchError} isLoading={isFetching}>
        <SOHHome
          deleteError={deleteError}
          deleteSuccess={deleteSuccess}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
          quantityFilter={quantityFilter}
          setIsDeleting={setIsDeleting}
          soh={soh}
          sohSortDirection={sohSortDirection}
          toggleSOHSortDirection={toggleSOHSortDirection}
        />
      </ContentLoads>
    </>
  );
};

export default StockOnHand;
