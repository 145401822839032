import PropTypes from 'prop-types';
import React from 'react';

import useTranslate from '../../hooks/useTranslate';

const MarkdownStatus = (markdown) => {
  const translate = useTranslate();
  const markdownStatus = (upcomingMarkdown) => {
    const activeStatus = <span key="active-status" className="text-color-success">{translate('RL_Active')}</span>;
    return !upcomingMarkdown ? activeStatus : [activeStatus, <span key="new-price-status" className="body-3 text-color-secondary"> - {translate('RL_NewPrice')} {upcomingMarkdown.markdownPrice} {translate('RL_Starts')} {upcomingMarkdown.startDate}</span>];
  };

  return (
    <p className="body-3">
      {markdown.startDate
        ? markdownStatus(markdown.upcomingMarkdown)
        : <span className="text-color-secondary">{translate('RL_Starts')} {markdown?.upcomingMarkdown?.startDate}</span>}
    </p>
  );
};

MarkdownStatus.propTypes = {
  markdown: PropTypes.shape({
    startDate: PropTypes.string, // Defined if markdown is "Active"
    upcomingMarkdown: PropTypes.shape({ // Defined if markdown is "Upcoming"
      markdownPrice: PropTypes.string,
      startDate: PropTypes.string,
    }),
  }).isRequired,
};

export default MarkdownStatus;
