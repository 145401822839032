import React, { useRef, useState } from 'react';
import './Upload.css';

import UploadHome from './UploadHome';

const Upload = () => {
  const [uploadBatches, setUploadBatches] = useState([]);
  const uploadRef = useRef(null);
  // eslint-disable-next-line fp/no-mutation
  uploadRef.current = uploadBatches;
  return (
    <div><UploadHome setUploadBatches={setUploadBatches} uploadRef={uploadRef} />
    </div>
  );
};

export default Upload;
