import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getPartnerPromotions } from '../../service-calls';
import { handleErrorMessage, handleResponse, formatPromotions } from '../../utils/formatting';
import { ButtonAdd, ContentLoads } from '../reusable';

import PromotionAdd from './PromotionAdd';
import PromotionPreview from './PromotionPreview';

const PromotionHome = ({
  partnerName, promotions, setActivePromotion, setClearError, setClearSuccess, canCreate, setPromotions
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState('');

  const toggleAddPromotionPage = () => {
    isAdding && setIsFetching(true);
    return setIsAdding((wasAdding) => !wasAdding);
  };

  useEffect(() => {
    // getPromotionsPartner (refresh the promotions) every time we set isFetching to true or we come back to the home page
    if (isFetching && partnerName) {
      (async () => getPartnerPromotions(partnerName)
        .then(({ objects }) => (!objects.length
          ? handleResponse(setPromotions, [], setError, 'This partner does not have any promotions.')
          : handleResponse(setPromotions, formatPromotions(objects), setError, '')))
        .catch((err) => handleResponse(setPromotions, [], setError, handleErrorMessage(err)))
        .finally(() => setIsFetching(false)))();
    }
  }, [isFetching, partnerName, setPromotions]);

  return (
    <>
      {canCreate && <ButtonAdd isAdding={isAdding} onToggle={toggleAddPromotionPage} />}
      {isAdding
        ? <PromotionAdd partnerName={partnerName} />
        : (
          <ContentLoads error={error} isLoading={isFetching}>
            <PromotionPreview
              canDelete={canCreate}
              partnerName={partnerName}
              promotions={promotions}
              refreshPromotions={() => setIsFetching(true)}
              setActivePromotion={setActivePromotion}
              setClearError={setClearError}
              setClearSuccess={setClearSuccess}
            />
          </ContentLoads>
        )}
    </>
  );
};
PromotionHome.defaultProps = {
  promotions: []
};

PromotionHome.propTypes = {
  canCreate: PropTypes.bool.isRequired,
  partnerName: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({
    discountPercentage: PropTypes.number,
    divisions: PropTypes.shape(),
    endDate: PropTypes.string,
    genders: PropTypes.shape(),
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      gtin: PropTypes.string,
      name: PropTypes.string,
      productId: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string,
    promotionId: PropTypes.string,
    startDate: PropTypes.string,
  })),
  setActivePromotion: PropTypes.func.isRequired,
  setClearError: PropTypes.func.isRequired,
  setClearSuccess: PropTypes.func.isRequired,
  setPromotions: PropTypes.func.isRequired,
};

export default PromotionHome;
