import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React from 'react';

const Input = ({
  error,
  errorMessage,
  id,
  isDisabled,
  isRequired,
  label,
  maxLength,
  minLength,
  onBlur,
  onChange,
  onFocus,
  onIconClick,
  placeholder,
  showCharacterCount,
  trailingIconContent,
  type,
  value,
}) => (
  <TextFieldStyled
    error={error || (isRequired && !value)}
    errorMessage={errorMessage}
    id={id}
    label={label}
    maxLength={maxLength}
    minLength={minLength}
    name={id}
    placeholder={placeholder}
    readOnly={isDisabled}
    requiredIndicator={isRequired ? '*' : ''}
    showCharacterCount={showCharacterCount}
    trailingIconContent={trailingIconContent}
    type={type}
    value={value}
    onBlur={onBlur}
    onChange={({ target: { value: newValue } }) => onChange(newValue)}
    onFocus={onFocus}
    onIconClick={onIconClick}
  />
);

Input.defaultProps = {
  error: false,
  errorMessage: '',
  isDisabled: false,
  isRequired: false,
  maxLength: undefined,
  minLength: undefined,
  onBlur: () => {},
  onFocus: () => {},
  onIconClick: () => {},
  placeholder: '',
  showCharacterCount: false,
  trailingIconContent: undefined,
  type: 'text',
};

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  showCharacterCount: PropTypes.bool,
  trailingIconContent: PropTypes.element,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Input;
