const { Maybe } = require('../types/maybe');

const reducer = (keyExtractor, valueExtractor) => (acc, value) => {
  const key = keyExtractor(value);
  const newValues = [...(acc[key] || []), valueExtractor(value)];
  return { ...acc, [key]: newValues };
};

const finish = (grouping, finisher) => Object.entries(grouping).reduce((acc, [key, group]) => ({ ...acc, [key]: finisher(group) }), {});

const group = (items, keyExtractor = k => k, valueExtractor = v => v, finisher) => {
  const grouping = items.reduce(reducer(keyExtractor, valueExtractor), {});
  return Maybe.of(finisher)
    .map(() => finish(grouping, finisher))
    .orElse(grouping);
};

module.exports = {
  group
};
