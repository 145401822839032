import development from './development.json';
import production from './production.json';
import standard from './standard.json';
import test from './test.json';

const config = { development, production, test };

module.exports = () => ({
  RESOURCE_AUTH: '/store/athlete_jwts/v1',
  RESOURCE_CSV_UPLOAD: '/store/csv_upload/v1',
  RESOURCE_MARKDOWNS: '/store/markdown_definitions/v1',
  RESOURCE_PARTNER_PROMOTIONS: '/promotions/v1',
  RESOURCE_PROMOTIONS: '/store/promotion_definitions/v1',
  RESOURCE_SCS: '/store/configs/v2/store',
  RESOURCE_STOCK_ON_HAND: '/store/promotion_stock_on_hand/v1',
  RESOURCE_STORE_LOCATIONS: '/store/store_locations/v1',
  RESOURCE_STORE_VIEWS: '/store/store_views/v2',
  ...standard,
  ...config[process.env.NODE_ENV.toLowerCase()],
});
