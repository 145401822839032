import { promotionTranslate } from 'rcffus-sps-translations';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_LOCALE } from '../constants';
import { getLocale, getLocaleToggleStatus } from '../redux/selectors';
import { getIsTranslationsAvailable } from '../utils/translationsUtils';

export default function useTranslate() {
  const locale = useSelector(getLocale);
  const localeToggle = useSelector(getLocaleToggleStatus);
  const isTranslationsAvailable = getIsTranslationsAvailable();

  return useCallback(
    (id, ...args) => (isTranslationsAvailable
      ? promotionTranslate(id, localeToggle ? DEFAULT_LOCALE : locale, ...args)
      : promotionTranslate(id, DEFAULT_LOCALE, ...args)),
    [isTranslationsAvailable, locale, localeToggle]
  );
}
