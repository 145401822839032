import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { setCountry } from '../../redux/store';
import { getMarkdowns } from '../../service-calls';
import { formatMarkdowns, handleErrorMessage, handleResponse } from '../../utils/formatting';
import getUserCountry from '../../utils/getUserCountry';
import { ContentLoads } from '../reusable';

import MarkdownHome from './MarkdownHome';

const Markdowns = ({ country, reduxSetCountry }) => {
  const [markdowns, setMarkdowns] = useState([]);
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const translate = useTranslate();

  !country && reduxSetCountry(getUserCountry());

  const location = useLocation();

  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      (async () => Auth.reAuthorize()
        .then(() => true)
        .catch(() => false))();
    }
    // getMarkdowns (refresh the markdowns) every time we set isFetching to true
    if (country && isFetching) {
      (async () => getMarkdowns(location.state?.storeId)
        .then(({ objects }) => handleResponse(setMarkdowns, formatMarkdowns(objects, country), setError, ''))
        .catch((err) => handleResponse(setMarkdowns, [], setError, handleErrorMessage(err)))
        .finally(() => setIsFetching(false)))();
    }
  }, [country, isFetching, location.state]);

  return (
    <>
      <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_Markdowns')}</h1>
      <ContentLoads error={error} isLoading={isFetching}>
        {!isFetching && (
          <MarkdownHome
            canCreate={Auth.requiresRole40(Auth.getAuth())}
            markdowns={markdowns}
            refreshMarkdowns={() => setIsFetching(true)}
            setProducts={setMarkdowns}
          />
        )}
      </ContentLoads>
    </>
  );
};

Markdowns.propTypes = {
  country: PropTypes.string.isRequired,
  reduxSetCountry: PropTypes.func.isRequired,
};

const mapStateToProps = ({ country }) => ({
  country,
});

const mapDispatchToProps = (dispatch) => ({
  reduxSetCountry: (country) => dispatch(setCountry(country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Markdowns);
