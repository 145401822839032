import { CaretRight } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

const CustomPanel = ({
  button,
  children,
  label,
  sublabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <article className="va-sm-m ta-sm-c pb2-sm">
      <button
        className="u-rounded bg-black u-full-width u-cursor-pointer va-sm-m p2-sm d-sm-flx"
        style={{
          color: 'white',
          flexDirection: 'row',
          outline: 'none',
          verticalAlign: 'middle',
        }}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className={`headline-3 ${button && 'ml12-sm'} flex-child flx-gro-sm-1`} style={{ overflowWrap: 'anywhere' }}>
          {label}
          <p className="body-3">{sublabel}</p>
        </h3>
        {button && (
          <section className="flex-child flx-gro-sm-0 flx-as-sm-c mr2-sm">
            <Link
              className="ncss-cta-primary-light"
              to={button.to}
              onClick={() => {
                button.onClick();
                return <Redirect to={button.to} />;
              }}
            >
              <CaretRight title="More Information" />
            </Link>
          </section>
        )}
      </button>
      {isOpen && children}
    </article>
  );
};

CustomPanel.defaultProps = {
  button: undefined,
  children: null,
  sublabel: '',
};

CustomPanel.propTypes = {
  button: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
};

export default CustomPanel;
