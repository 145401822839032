{
  "Amount": {
    "ar": "المبلغ:",
    "ca": "Import:",
    "comment": "Amount displayed in receipt",
    "cs": "Částka:",
    "da": "Beløb:",
    "de": "Anzahl:",
    "el": "Ποσό:",
    "en": "Amt:",
    "en-GB": "Amt:",
    "en-XA": "[Åɱţ∶ 耐克รื่بُو]",
    "es-419": "Monto:",
    "es-AR": "Importe:",
    "es-ES": "Importe:",
    "es-MX": "Monto:",
    "fr": "Montant :",
    "hu": "Össz.",
    "id": "Jml:",
    "it": "Importo:",
    "ja": "金額",
    "key": "Amount",
    "ko": "금액:",
    "nb": "Beløp:",
    "nl": "Bedr.",
    "pl": "Kwota",
    "pt-BR": "Valor:",
    "pt-PT": "Mont.:",
    "ru": "Сумма:",
    "sv": "Belopp:",
    "th": "จำนวน:",
    "tr": "Tutar:",
    "zh-Hans": "金额：",
    "zh-Hant": "金額："
  },
  "CardNo": {
    "ar": "رقم البطاقة",
    "ca": "N.º tarj",
    "comment": "Gift card number used in transaction",
    "cs": "Karta c",
    "da": "Kort nr",
    "de": "Karten-Nr.",
    "el": "Αριθμός κάρτας",
    "en": "Card No",
    "en-GB": "Card No",
    "en-XA": "[Çåŕð Ñö 耐克รื่بُو]",
    "es-419": "N.º de tarjeta",
    "es-AR": "N.º de tarjeta",
    "es-ES": "N.º tarj",
    "es-MX": "N.º de tarjeta",
    "fr": "N° carte",
    "hu": "Card No",
    "id": "No. Kartu",
    "it": "N. carta",
    "ja": "カード番号",
    "key": "CardNo",
    "ko": "카드 번호",
    "nb": "Kortnr.",
    "nl": "Kaartnr.",
    "pl": "Nr karty",
    "pt-BR": "N° do cartão",
    "pt-PT": "N.º do cartão",
    "ru": "№ карты",
    "sv": "Kortnr.",
    "th": "เลขบัตร",
    "tr": "Kart No",
    "zh-Hans": "卡号",
    "zh-Hant": "卡號"
  },
  "Control Code": {
    "ar": "رمز التحكّم",
    "ca": "Codi de control",
    "comment": "Printed on the receipt which is an encrypted digital signature",
    "cs": "Kontrolní kód",
    "da": "Kontrolkode",
    "de": "Kontrollcode",
    "el": "Κωδικός ελέγχου",
    "en": "Control Code",
    "en-GB": "Control Code",
    "en-XA": "[Çöñţŕöļ Çöðé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Código de control",
    "es-AR": "Código de control",
    "es-ES": "Código de control",
    "es-MX": "Código de control",
    "fr": "Code de contrôle",
    "hu": "Ellenőrzőkód",
    "id": "Kode Kontrol",
    "it": "Codice di controllo",
    "ja": "コントロール コード",
    "key": "Control Code",
    "ko": "컨트롤 코드",
    "nb": "Kontrollkode",
    "nl": "Controlecode",
    "pl": "Kod kontrolny",
    "pt-BR": "Código de controle",
    "pt-PT": "Código de controlo",
    "ru": "Контрольный код",
    "sv": "Kontrollkod",
    "th": "รหัสควบคุม",
    "tr": "Kontrol Kodu",
    "zh-Hans": "防伪码",
    "zh-Hant": "控制碼"
  },
  "DuplicateReceipt": {
    "ca": "REBUT DUPLICAT",
    "comment": "All caps text printed on a receipt to indicate the receipt is a reprint/duplicate",
    "cs": "DUPLICITNÍ PRODEJNÍ DOKLAD",
    "da": "KOPI AF KVITTERING",
    "de": "DOPPELTER BELEG",
    "en": "DUPLICATE RECEIPT",
    "en-XA": "[ÐÛÞĻÎÇÅŢÉ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO DUPLICADO",
    "es-AR": "RECIBO DUPLICADO",
    "es-ES": "RECIBO DUPLICADO",
    "es-MX": "DUPLICAR RECIBO",
    "fr": "DUPLICATA DE REÇU",
    "hu": "SZÁMLA MÁSOLATA",
    "it": "DUPLICATO RICEVUTA",
    "ja": "領収書を複製する",
    "key": "DuplicateReceipt",
    "nb": "DUPLIKATKVITTERING",
    "nl": "DUBBELE BON",
    "pl": "DUPLIKAT PARAGONU",
    "pt-PT": "RECIBO DUPLICADO",
    "ru": "ДУБЛИКАТ ЧЕКА",
    "sv": "KVITTOKOPIA",
    "tr": "MÜKERRER MAKBUZ",
    "zh-Hans": "收据重复",
    "zh-Hant": "收據副本"
  },
  "EnterCode": {
    "ar": "يرجى إدخال الرمز",
    "ca": "Introdueix el codi",
    "comment": "Phrase that comes before the survey code we want the consumer to enter.",
    "cs": "Zadat kód",
    "da": "Indtast koden",
    "de": "Code eingeben",
    "el": "Εισαγωγή κωδικού",
    "en": "Enter the code",
    "en-GB": "Enter the code",
    "en-XA": "[Éñţéŕ ţĥé çöðé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Ingresa el código",
    "es-AR": "Ingresar el código",
    "es-ES": "Introduce el código",
    "es-MX": "Ingresar código",
    "fr": "Saisissez le code",
    "hu": "Kód megadása",
    "id": "Masukkan kode",
    "it": "Inserisci il codice",
    "ja": "コードを入力",
    "key": "EnterCode",
    "ko": "코드를 입력하세요",
    "nb": "Angi kode",
    "nl": "Voer de code in",
    "pl": "Wprowadź kod",
    "pt-PT": "Introduzir o código",
    "ru": "Введите код",
    "sv": "Ange koden",
    "th": "ป้อนรหัส",
    "tr": "Kodu girin",
    "zh-Hans": "请输入验证码",
    "zh-Hant": "輸入代碼"
  },
  "GIFTITEMRECEIPT": {
    "ar": "إيصال بالمنتج الهدية",
    "ca": "REBUT DE PRODUCTE DE REGAL",
    "comment": "Title in the receipt to indicate gift items in the transaction",
    "cs": "POLOŽKA Z DÁRKOVÉ KARTY",
    "da": "GAVEPRODUKTKVITTERING",
    "de": "GESCHENKARTIKELBELEG",
    "el": "ΑΠΟΔΕΙΞΗ ΔΩΡΟY",
    "en": "GIFT ITEM RECEIPT",
    "en-GB": "GIFT ITEM RECEIPT",
    "en-XA": "[ĜÎƑŢ ÎŢÉṀ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO DE PRODUCTO PARA REGALO",
    "es-AR": "RECIBO DEL PRODUCTO DE REGALO",
    "es-ES": "RECIBO DE PRODUCTO DE REGALO",
    "es-MX": "RECIBO DEL PRODUCTO DE REGALO",
    "fr": "REÇU D'ARTICLE OFFERT EN CADEAU",
    "hu": "SZÁMLA – AJÁNDÉKTERMÉK",
    "id": "TANDA TERIMA ITEM HADIAH",
    "it": "RICEVUTA ARTICOLO REGALO",
    "ja": "ギフト商品の領収書",
    "key": "GIFTITEMRECEIPT",
    "ko": "기프트 아이템 영수증",
    "nb": "KVITTERING FOR GAVEARTIKKEL",
    "nl": "BON CADEAU",
    "pl": "UPOMINEK (PARAGON)",
    "pt-BR": "RECIBO DO ITEM DE PRESENTE",
    "pt-PT": "RECIBO DO ARTIGO DE OFERTA",
    "ru": "ЧЕК НА ПОДАРОЧНЫЙ ТОВАР",
    "sv": "PRESENTKVITTO",
    "th": "ใบเสร็จรายการของขวัญ",
    "tr": "HEDİYE ÜRÜN MAKBUZU",
    "zh-Hans": "礼品收据",
    "zh-Hant": "禮品收據"
  },
  "GiftCard": {
    "ar": "بطاقة هدايا",
    "ca": "Tarjeta regalo",
    "comment": "Gift Card item",
    "cs": "Dárková karta",
    "da": "Gavekort",
    "de": "Geschenkgutschein",
    "el": "Δωροκάρτα",
    "en": "Gift Card",
    "en-GB": "Gift Card",
    "en-XA": "[Ĝîƒţ Çåŕð 耐克รื่بُو]",
    "es-419": "Gift Card",
    "es-AR": "Tarjeta de regalo",
    "es-ES": "Tarjeta regalo",
    "es-MX": "Tarjeta de regalo",
    "fr": "Gift Card",
    "hu": "Gift Card",
    "id": "Kartu Hadiah",
    "it": "Carta regalo",
    "ja": "ギフトカード",
    "key": "GiftCard",
    "ko": "기프트 카드",
    "nb": "Gavekort",
    "nl": "Cadeaubon",
    "pl": "Karta upominkowa",
    "pt-BR": "Cartão-presente",
    "pt-PT": "Cartão de oferta",
    "ru": "Подарочная карта",
    "sv": "Presentkort",
    "th": "บัตรของขวัญ",
    "tr": "Hediye Kartı",
    "zh-Hans": "礼品卡",
    "zh-Hant": "禮品卡"
  },
  "GiftCertificateRedeem": {
    "ar": "استرداد الشهادة بالهدية",
    "ca": "Canje cheque regalo",
    "comment": "Gift Certificate that is Redeem",
    "cs": "Vrácení dárk. cert",
    "da": "Gavecertifikat indløs",
    "de": "Einlösung Geschenkgutschein",
    "el": "Εξαργύρωση πιστοποιητικού δώρου",
    "en": "Gift Certificate Redeem",
    "en-GB": "Gift Certificate Redeem",
    "en-XA": "[Ĝîƒţ Çéŕţîƒîçåţé Ŕéðééɱ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Canje de certificado de regalo",
    "es-AR": "Canje de certificado de regalo",
    "es-ES": "Canje cheque regalo",
    "es-MX": "Canje de vale de regalo",
    "fr": "Utilisation du certificat de cadeau",
    "hu": "Gift Certificate Redeem",
    "id": "Penukaran Sertifikat Hadiah",
    "it": "Utilizzo certificato regalo",
    "ja": "商品券使用",
    "key": "GiftCertificateRedeem",
    "ko": "상품권 교환",
    "nb": "Løs inn gavekort",
    "nl": "Cadeaubon inwisselen",
    "pl": "Wykorzystanie bonu upominkowego",
    "pt-BR": "Resgate do vale-presente",
    "pt-PT": "Resgate do certificado de oferta",
    "ru": "Использование подарочного сертификата",
    "sv": "Inlösande av presentkort",
    "th": "แลกใช้บัตรของขวัญ",
    "tr": "Hediye Çeki Kullanma",
    "zh-Hans": "礼品券兑换",
    "zh-Hant": "禮卷兌換"
  },
  "INVALIDRECEIPT": {
    "ar": "إيصال غير صالح",
    "ca": "Tiquet no vàlid",
    "comment": "Invalid Receipt Indicator while in training mode",
    "cs": "NEPLATNÝ DOKLAD",
    "da": "UGYLDIG KVITTERING",
    "de": "Ungültig",
    "el": "ΜΗ ΕΓΚΥΡΗ ΑΠΟΔΕΙΞΗ",
    "en": "INVALID RECEIPT",
    "en-GB": "INVALID RECEIPT",
    "en-XA": "[ÎÑṼÅĻÎÐ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO NO VÁLIDO",
    "es-AR": "RECIBO NO VÁLIDO",
    "es-ES": "RECIBO NO VÁLIDO",
    "es-MX": "RECIBO NO VÁLIDO",
    "fr": "RECU NON VALIDE",
    "hu": "INVALID RECEIPT",
    "id": "TANDA TERIMA TIDAK VALID",
    "it": "RICEVUTA NON VALIDA",
    "ja": "無効レシート",
    "key": "INVALIDRECEIPT",
    "ko": "잘못된 영수증",
    "nb": "UGYLDIG KVITTERING",
    "nl": "ONGELDIGE BON",
    "pl": "NIEPRAWIDŁOWY PARAGON",
    "pt-BR": "RECIBO INVÁLIDO",
    "pt-PT": "RECIBO INVÁLIDO",
    "ru": "НЕДЕЙСТВИТЕЛЬНЫЙ ЧЕК",
    "sv": "OGILTIGT KVITTO",
    "th": "ใบเสร็จไม่ถูกต้อง",
    "tr": "GEÇERSİZ MAKBUZ",
    "zh-Hans": "无效收据",
    "zh-Hant": "無效收據"
  },
  "IN_ShipStore": {
    "ar": "للشحن - المتجر",
    "ca": "Envío - Tienda",
    "comment": "To be shipped - Store",
    "cs": "Chcete-li být dodávány - Store",
    "da": "Klar til afsendelse - Store",
    "de": "Lieferanschrift - Store",
    "el": "Προς αποστολή - Κατάστημα",
    "en": "To be shipped - Store",
    "en-GB": "To be delivered – Store",
    "en-XA": "[Ţö ƀé šĥîþþéð ‐ Šţöŕé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Para enviar: Tienda",
    "es-AR": "Para enviar: Tienda",
    "es-ES": "Envío - Tienda",
    "es-MX": "Para enviar: Tienda",
    "fr": "À expédier - Magasin",
    "hu": "To be shipped - Store",
    "id": "Akan dikirim - Toko",
    "it": "Da spedire - Negozio",
    "ja": "発送準備中 - 店舗",
    "key": "IN_ShipStore",
    "ko": "배송 예정 - 매장",
    "nb": "Skal sendes – butikk",
    "nl": "Wordt verzonden - Store",
    "pl": "Do wysłania – sklep",
    "pt-BR": "A ser enviado - Loja",
    "pt-PT": "A enviar – Loja",
    "ru": "Ожидается отправка — магазин",
    "sv": "Ska levereras – butik",
    "th": "ต้องจัดส่ง - ร้าน",
    "tr": "Kargoya verilecek - Mağaza",
    "zh-Hans": "待发货 - 商店",
    "zh-Hant": "待運送至：門市"
  },
  "IN_TobeshippedNike": {
    "ar": "للشحن - Nike.com",
    "ca": "Envío - Nike.com",
    "comment": "To be shipped - Nike.com",
    "cs": "Chcete-li být dodávány - Nike.com",
    "da": "Klar til afsendelse - Nike.com",
    "de": "Lieferanschrift - Nike.com",
    "el": "Προς αποστολή - Nike.com",
    "en": "To be shipped - Nike.com",
    "en-GB": "To be delivered – Nike.com",
    "en-XA": "[Ţö ƀé šĥîþþéð ‐ Ñîķé·çöɱ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Para enviar: Nike.com",
    "es-AR": "Para enviar: Nike.com",
    "es-ES": "Envío - Nike.com",
    "es-MX": "Para enviar: Nike.com",
    "fr": "À expédier - Nike.com",
    "hu": "To be shipped - Nike.com",
    "id": "Akan dikirim - Nike.com",
    "it": "Da spedire - Nike.com",
    "ja": "発送準備中 - Nike.com",
    "key": "IN_TobeshippedNike",
    "ko": "배송 예정 - NIKE.COM",
    "nb": "Skal sendes – Nike.com",
    "nl": "Wordt verzonden - Nike.com",
    "pl": "Do wysłania – Nike.com",
    "pt-BR": "A ser enviado - Nike.com",
    "pt-PT": "A enviar – Nike.com",
    "ru": "Ожидается отправка — Nike.com",
    "sv": "Ska levereras – Nike.com",
    "th": "ต้องจัดส่ง - Nike.com",
    "tr": "Kargoya verilecek - Nike.com",
    "zh-Hans": "待发货 - Nike.com",
    "zh-Hant": "待運送至：Nike.com"
  },
  "Op": {
    "ar": "المشغل",
    "ca": "Empl",
    "comment": "Abbreviation for Operator Number",
    "cs": "Prov",
    "da": "Op",
    "de": "Vorgang",
    "el": "Χειριστής",
    "en": "Op",
    "en-GB": "Op",
    "en-XA": "[Öþ 耐克รื่بُو]",
    "es-419": "Op.",
    "es-AR": "Op",
    "es-ES": "Empl",
    "es-MX": "Op",
    "fr": "Op",
    "hu": "Op",
    "id": "Op",
    "it": "Op",
    "ja": "担当",
    "key": "Op",
    "ko": "계산원",
    "nb": "Op",
    "nl": "Op",
    "pl": "Op",
    "pt-BR": "Op",
    "pt-PT": "N.º de ope.",
    "ru": "Операция",
    "sv": "Operatörsnr.",
    "th": "เลขดำเนินการ",
    "tr": "Op.",
    "zh-Hans": "操作人员编号",
    "zh-Hant": "收銀員"
  },
  "Operator": {
    "ar": "المشغّل",
    "ca": "Operador",
    "comment": "Operator served the customer",
    "cs": "operátora",
    "da": "Operatør",
    "de": "Bediener",
    "el": "Χειριστής",
    "en": "Operator",
    "en-GB": "Operator",
    "en-XA": "[Öþéŕåţöŕ 耐克รื่بُو]",
    "es-419": "Operador",
    "es-AR": "Operador",
    "es-ES": "Operador",
    "es-MX": "operador",
    "fr": "Opérateur",
    "hu": "Operator",
    "id": "Operator",
    "it": "Operatore",
    "ja": "担当者",
    "key": "Operator",
    "ko": "계산원",
    "nb": "Operatør",
    "nl": "Operator",
    "pl": "Operator",
    "pt-BR": "Operador",
    "pt-PT": "Operador",
    "ru": "Оператор",
    "sv": "Operatör",
    "th": "ผู้ดำเนินการ",
    "tr": "Operatör",
    "zh-Hans": "操作人员",
    "zh-Hant": "收銀員"
  },
  "OrderId": {
    "ca": "ID de comanda",
    "comment": "Order ID to be printed at the top of a receipt when a receipt is for an order",
    "cs": "iD objednávky",
    "da": "Ordre-ID",
    "de": "Bestellnummer",
    "el": "Αριθμός ψηφιακής παραγγελίας",
    "en": "Order ID",
    "en-XA": "[Öŕðéŕ ÎÐ 耐克รื่بُو]",
    "es-419": "Identificación del pedido",
    "es-AR": "ID del pedido",
    "es-ES": "ID de pedido",
    "es-MX": "ID del pedido",
    "fr": "Numéro de commande numérique",
    "hu": "Rendelési azonosító",
    "it": "ID ordine",
    "ja": "ご注文ID",
    "key": "OrderId",
    "ko": "주문 ID",
    "nb": "Bestillings-ID",
    "nl": "Bestelnummer",
    "pl": "ID zamówienia",
    "pt-PT": "ID da encomenda",
    "ru": "ID заказа",
    "sv": "Beställnings-id",
    "tr": "Sipariş ID",
    "zh-Hans": "数字订单编号",
    "zh-Hant": "訂單編號"
  },
  "OriginalTran": {
    "ar": "المعاملة الأساسية",
    "ca": "Trans. orig.",
    "comment": "Abbreviation for Original Transaction",
    "cs": "Pův. tran",
    "da": "Oprindelig trans",
    "de": "Original-Transaktion",
    "el": "Αρχική συναλλαγή",
    "en": "Original Tran",
    "en-GB": "Original Tran",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Trans. original",
    "es-AR": "Trans. original",
    "es-ES": "Trans. orig.",
    "es-MX": "Trans. original",
    "fr": "Tran Originale",
    "hu": "Original Tran",
    "id": "Transaksi Awal",
    "it": "Trans. originale",
    "ja": "販売番号",
    "key": "OriginalTran",
    "ko": "기존 거래",
    "nb": "Opprinnelig trans.",
    "nl": "Originele trans",
    "pl": "Oryginalna transakcja",
    "pt-BR": "Transação original",
    "pt-PT": "Trans. original",
    "ru": "Исходная транзакция",
    "sv": "Ursprunglig trans.",
    "th": "ธุรกรรมเดิม",
    "tr": "Orijinal İşl.",
    "zh-Hans": "原始交易",
    "zh-Hant": "原始交易"
  },
  "OriginalTranDate": {
    "ar": "تاريخ المعاملة الأساسي",
    "ca": "Fecha trans. orig.",
    "comment": "Abbreviation for Original Transaction Date",
    "cs": "Datum pův. tran",
    "da": "Oprindelig trans. dato",
    "de": "Datum der Original-Transaktion",
    "el": "Ημερομηνία αρχικής συναλλαγής",
    "en": "Original Tran Date",
    "en-GB": "Original Tran Date",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñ Ðåţé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Fecha de trans. original",
    "es-AR": "Fecha de trans. original",
    "es-ES": "Fecha trans. orig.",
    "es-MX": "Fecha de trans. original",
    "fr": "Date Tran Originale",
    "hu": "Original Tran Date",
    "id": "Tanggal Transaksi Awal",
    "it": "Data originale transazione",
    "ja": "販売日",
    "key": "OriginalTranDate",
    "ko": "기존 거래 날짜",
    "nb": "Opprinnelig transaksjonsdato",
    "nl": "Originele trans.datum",
    "pl": "Data oryginalnej transakcji",
    "pt-BR": "Data da transação original",
    "pt-PT": "Data da trans. original",
    "ru": "Дата исходной транзакции",
    "sv": "Ursprungligt trans. datum",
    "th": "วันทำธุรกรรมเดิม",
    "tr": "Orijinal İşl. Tarihi",
    "zh-Hans": "原始交易日期",
    "zh-Hant": "原始交易日期"
  },
  "OriginalTranReg": {
    "ar": "تسجيل المعاملة الأساسية",
    "ca": "Caixa de transacció original",
    "comment": "Abbreviation for Original Transaction Register",
    "cs": "Pokladna původní transakce",
    "da": "Oprindelig transaktionskasse",
    "de": "Ursprüngliche Transaktionskasse",
    "el": "Ταμείο αρχικής συναλλαγής",
    "en": "Original Tran Reg",
    "en-GB": "Original Tran Reg",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñ Ŕéĝ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Registro de transacción original",
    "es-AR": "Caja de trans. original",
    "es-ES": "Reg. trans. orig.",
    "es-MX": "Caja de trans. original",
    "fr": "Caisse transaction d'origine",
    "hu": "Eredeti kasszatranzakció",
    "id": "Reg Trans Awal",
    "it": "Cassa transazione originale",
    "ja": "初回取引レジ",
    "key": "OriginalTranReg",
    "ko": "기존 거래 등록",
    "nb": "Kasse for opprinnelig transaksjon",
    "nl": "Kassa originele trans.",
    "pl": "Kasa oryg. trans.",
    "pt-BR": "Reg Trans original",
    "pt-PT": "Caixa reg. da trans. original",
    "ru": "Касса исходной транзакции",
    "sv": "Ursprunglig trans. reg.",
    "th": "เลขทะเบียนธุรกรรมเดิม",
    "tr": "Orijinal İşl. Kaydı",
    "zh-Hans": "原始交易收银机编号",
    "zh-Hant": "原始交易收銀機編號"
  },
  "OriginalTranTime": {
    "ar": "وقت المعاملة الأساسي",
    "ca": "Hora trans. orig.",
    "comment": "Abbreviation for Original Transaction Time",
    "cs": "Čas pův. tran",
    "da": "Oprindelig trans. tidspunkt",
    "de": "Uhrzeit der ursprünglichen Transaktion",
    "el": "Ώρα αρχικής συναλλαγής",
    "en": "Original Tran Time",
    "en-GB": "Original Tran Time",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñ Ţîɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Hora de trans. original",
    "es-AR": "Hora de trans. original",
    "es-ES": "Original Tran Time",
    "es-MX": "Hora de trans. original",
    "fr": "Heure Tram Originale",
    "hu": "Original Tran Time",
    "id": "Waktu Tran Awal",
    "it": "Ora transazione originale",
    "ja": "販売時間",
    "key": "OriginalTranTime",
    "ko": "기존 거래 시간",
    "nb": "Opprinnelig transaksjonstid",
    "nl": "Originele trans.tijd",
    "pl": "Oryginalny czas transakcji",
    "pt-BR": "Tempo de transação original",
    "pt-PT": "Hora da trans. original",
    "ru": "Время исходной транзакции",
    "sv": "Ursprunglig trans. tid",
    "th": "เวลาธุรกรรมเดิม",
    "tr": "Orijinal İşl. Saati",
    "zh-Hans": "原始交易时间",
    "zh-Hant": "原始交易時間"
  },
  "RF_CheckstatusonNikecom": {
    "ar": "التحقق من الحالة على Nike.com",
    "ca": "Compruebe el estado en Nike.com",
    "comment": "phrase of the receipt which says the customer to check the status in nike.com website",
    "cs": "Ověřte stav na webu Nike.com",
    "da": "Tjek status på Nike.com",
    "de": "Status auf Nike.com prüfen:",
    "el": "Έλεγχος κατάστασης στο Nike.com",
    "en": "Check status on Nike.com",
    "en-GB": "Check status on Nike.com",
    "en-XA": "[Çĥéçķ šţåţûš öñ Ñîķé·çöɱ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Verifique el estado en Nike.com",
    "es-AR": "Verifique el estado en Nike.com",
    "es-ES": "Compruebe el estado en Nike.com",
    "es-MX": "Check status on Nike.com",
    "fr": "Consulter le statut sur Nike.com:",
    "hu": "Check status on Nike.com",
    "id": "Periksa status di Nike.com",
    "it": "Controlla lo stato su Nike.com",
    "ja": "Check status on Nike.com",
    "key": "RF_CheckstatusonNikecom",
    "ko": "NIKE.COM에서 상태 확인",
    "nb": "Sjekk statusen på Nike.com",
    "nl": "Status controleren op Nike.com",
    "pl": "Sprawdź status zamówienia na Nike.com",
    "pt-BR": "Verifique o status em Nike.com",
    "pt-PT": "Verificar o estado em Nike.com",
    "ru": "Проверьте статус на сайте Nike.com",
    "sv": "Kontrollera statusen på Nike.com",
    "th": "ตรวจสอบสถานะบน Nike.com",
    "tr": "Durumu Nike.com'dan kontrol et",
    "zh-Hans": "在 Nike.com 上查看状态",
    "zh-Hant": "到 Nike.com 查看狀態"
  },
  "RF_EmployeeVendorNumber": {
    "ar": "رقم الموظف/البائع",
    "ca": "N.º empleado/vendedor",
    "comment": "Employee/ Vendor Number for Employee Discount",
    "cs": "Zaměst./Obchod. číslo",
    "da": "Medarbejder/Sælgernummer",
    "de": "Angestellte / Händler Nummer:",
    "el": "Αριθμός εργαζομένου/προμηθευτή",
    "en": "Employee/Vendor Number",
    "en-GB": "Employee/Vendor Number",
    "en-XA": "[Éɱþļöýéé⁄Ṽéñðöŕ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de empleado/proveedor",
    "es-AR": "Número de empleado/proveedor",
    "es-ES": "N.º empleado/vendedor",
    "es-MX": "Número de empleado/proveedor",
    "fr": "Numéro employé/fournisseur",
    "hu": "Employee/Vendor Number",
    "id": "Nomor Karyawan/Vendor",
    "it": "Numero dipendente/venditore",
    "ja": "社員/ベンダー 番号",
    "key": "RF_EmployeeVendorNumber",
    "ko": "직원/벤더 번호",
    "nb": "Ansatt-/leverandørnummer",
    "nl": "Werknemer/leveranciersnummer",
    "pl": "Numer pracownika/sprzedawcy",
    "pt-BR": "Número do funcionário/fornecedor",
    "pt-PT": "Número de funcionário/fornecedor",
    "ru": "Номер сотрудника/поставщика",
    "sv": "Medarbetar-/Leverantörsnummer",
    "th": "หมายเลขพนักงาน/ผู้จำหน่าย",
    "tr": "Çalışan/Satıcı Numarası",
    "zh-Hans": "员工/供应商编号",
    "zh-Hant": "員工/廠商編號"
  },
  "RF_GIFTITEMRECEIPT": {
    "ar": "إيصال بالمنتج الهدية",
    "ca": "RECIBO ARTÍC. REGALO",
    "comment": "Header of the receipt indicates the recipt of Gift Item",
    "cs": "DARK. POL.-UCET",
    "da": "GAVEVARE KVITTERING",
    "de": "GESCHENKARTIKEL-QUITTUNG",
    "el": "ΑΠΟΔΕΙΞΗ ΔΩΡΟΥ",
    "en": "GIFT ITEM RECEIPT",
    "en-GB": "GIFT ITEM RECEIPT",
    "en-XA": "[ĜÎƑŢ ÎŢÉṀ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO DE ARTÍCULO DE REGALO",
    "es-AR": "RECIBO DE ARTÍCULO DE REGALO",
    "es-ES": "RECIBO ARTÍC. REGALO",
    "es-MX": "RECIBO DE ARTÍCULO DE REGALO",
    "fr": "REÇU ARTICLE CADEAU",
    "hu": "GIFT ITEM RECEIPT",
    "id": "TANDA TERIMA ITEM HADIAH",
    "it": "RICEVUTA ARTICOLO REGALO",
    "ja": "ギフト商品レシート",
    "key": "RF_GIFTITEMRECEIPT",
    "ko": "기프트 아이템 영수증",
    "nb": "KVITTERING FOR GAVEARTIKKEL",
    "nl": "BON CADEAU",
    "pl": "UPOMINEK (PARAGON)",
    "pt-BR": "RECIBO DO ITEM DE PRESENTE",
    "pt-PT": "RECIBO DO ARTIGO DE OFERTA",
    "ru": "ЧЕК НА ПОДАРОЧНЫЙ ТОВАР",
    "sv": "PRESENTKVITTO",
    "th": "ใบเสร็จรายการของขวัญ",
    "tr": "HEDİYE ÜRÜN MAKBUZU",
    "zh-Hans": "礼品收据",
    "zh-Hant": "禮品收據"
  },
  "RF_Telephone": {
    "ar": "الهاتف",
    "ca": "Teléfono.",
    "comment": "Sotre telephone number",
    "cs": "Telefon",
    "da": "Telefon",
    "de": "Telefon",
    "el": "Τηλέφωνο",
    "en": "Telephone",
    "en-GB": "Telephone",
    "en-XA": "[Ţéļéþĥöñé 耐克รื่بُو]",
    "es-419": "Teléfono",
    "es-AR": "Teléfono",
    "es-ES": "Tel.",
    "es-MX": "Teléfono",
    "fr": "Téléphone",
    "hu": "Telephone",
    "id": "Telepon",
    "it": "Telefono",
    "ja": "電話番号",
    "key": "RF_Telephone",
    "ko": "Tel",
    "nb": "Telefon",
    "nl": "Telefoon",
    "pl": "Telefon",
    "pt-BR": "Telefone",
    "pt-PT": "Telefone",
    "ru": "Телефон",
    "sv": "Telefon",
    "th": "โทรศัพท์",
    "tr": "Telefon",
    "zh-Hans": "电话号码",
    "zh-Hant": "電話號碼"
  },
  "RL_ABORTED": {
    "ar": "تمّ إلغاء المعاملة",
    "ca": "TRANS. ANULADA",
    "comment": "A line in receipt indicates the transaction is aborted since it is voided.",
    "cs": "TRANSACTION ABORTED",
    "da": "TRANSAKTION AFBRUDT",
    "de": "TRANSAKTION ABGEBROCHEN",
    "el": "ΜΑΤΑΙΩΣΗ ΣΥΝΑΛΛΑΓΗΣ",
    "en": "TRANSACTION ABORTED",
    "en-GB": "TRANSACTION ABORTED",
    "en-XA": "[ŢŔÅÑŠÅÇŢÎÖÑ ÅƁÖŔŢÉÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "TRANSACCIÓN CANCELADA",
    "es-AR": "TRANSACCIÓN CANCELADA",
    "es-ES": "TRANS. ANULADA",
    "es-MX": "TRANSACCIÓN CANCELADA",
    "fr": "TRANSACTION ANNULEE",
    "hu": "TRANSACTION ABORTED",
    "id": "TRANSAKSI DIBATALKAN",
    "it": "TRANSAZIONE ABBANDONATA",
    "ja": "中止",
    "key": "RL_ABORTED",
    "ko": "거래 중단됨",
    "nb": "AVBRUTT TRANSAKSJON",
    "nl": "TRANSACTIE AFGEBROKEN",
    "pl": "TRANSAKCJA PRZERWANA",
    "pt-BR": "TRANSAÇÃO CANCELADA",
    "pt-PT": "TRANSAÇÃO ABORTADA",
    "ru": "TRANSACTION ABORTED",
    "sv": "TRANSAKTION AVBRUTEN",
    "th": "ธุรกรรมถูกยกเลิก",
    "tr": "İŞLEM İPTAL EDİLDİ",
    "zh-Hans": "交易已中止",
    "zh-Hant": "交易中止"
  },
  "RL_Address": {
    "ar": "العنوان",
    "ca": "Direc",
    "comment": "Address line in receipt",
    "cs": "Adresa",
    "da": "Adresse",
    "de": "Addresse",
    "el": "Διεύθυνση",
    "en": "Address",
    "en-GB": "Address",
    "en-XA": "[Åððŕéšš 耐克รื่بُو]",
    "es-419": "Dirección",
    "es-AR": "Dirección",
    "es-ES": "Dirección",
    "es-MX": "Dirección",
    "fr": "Address",
    "hu": "Address",
    "id": "Alamat",
    "it": "Indirizzo",
    "ja": "住所",
    "key": "RL_Address",
    "ko": "주소",
    "nb": "Adresse",
    "nl": "Adres",
    "pl": "Adres",
    "pt-BR": "Endereço",
    "pt-PT": "Morada",
    "ru": "Адрес",
    "sv": "Adress",
    "th": "ที่อยู่",
    "tr": "Adres",
    "zh-Hans": "配送地址",
    "zh-Hant": "地址"
  },
  "RL_Aid": {
    "ar": "معرّف التطبيق",
    "ca": "Aid",
    "comment": "Aid in receipt",
    "cs": "Aid",
    "da": "Aid",
    "de": "Aid",
    "el": "Aid",
    "en": "Aid",
    "en-GB": "Aid",
    "en-XA": "[Åîð 耐克รื่بُو]",
    "es-419": "Ayuda",
    "es-AR": "Ayuda",
    "es-ES": "Aid",
    "es-MX": "Ayuda",
    "fr": "Aid",
    "hu": "Aid",
    "id": "Bantuan",
    "it": "Aid",
    "ja": "Aid",
    "key": "RL_Aid",
    "ko": "지원",
    "nb": "Hjelp",
    "nl": "Aid",
    "pl": "Aid",
    "pt-BR": "Ajuda",
    "pt-PT": "Ajuda",
    "ru": "Помощь",
    "sv": "Stöd",
    "th": "Aid",
    "tr": "Aid",
    "zh-Hans": "商品识别码",
    "zh-Hant": "應用識別碼"
  },
  "RL_Amount": {
    "ar": "المبلغ",
    "ca": "Ctdad",
    "comment": "Amount of the item in receipt",
    "cs": "Cástka",
    "da": "beløb",
    "de": "Amount",
    "el": "Ποσό",
    "en": "Amount",
    "en-GB": "Amount",
    "en-XA": "[Åɱöûñţ 耐克รื่بُو]",
    "es-419": "Importe",
    "es-AR": "Importe",
    "es-ES": "Ctdad",
    "es-MX": "Importe",
    "fr": "Montant",
    "hu": "Amount",
    "id": "Jumlah",
    "it": "Importo",
    "ja": "金額",
    "key": "RL_Amount",
    "ko": "금액",
    "nb": "Beløp",
    "nl": "Bedrag",
    "pl": "Kwota",
    "pt-BR": "Valor",
    "pt-PT": "Montante",
    "ru": "Сумма",
    "sv": "Belopp",
    "th": "จำนวนเงิน",
    "tr": "Tutar",
    "zh-Hans": "金额",
    "zh-Hant": "數量"
  },
  "RL_AmountPaid": {
    "ar": "المبلغ المدفوع",
    "ca": "Ctdad Pagada",
    "comment": "Paid amount in receipt ",
    "cs": "Zaplac. cástka",
    "da": "Betalt beløb",
    "de": "Summe Paid",
    "el": "Πληρωθέν ποσό",
    "en": "Amount Paid",
    "en-GB": "Amount Paid",
    "en-XA": "[Åɱöûñţ Þåîð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Importe pagado",
    "es-AR": "Importe pagado",
    "es-ES": "Ctdad Pagada",
    "es-MX": "Importe pagado",
    "fr": "Montant payé",
    "hu": "Amount Paid",
    "id": "Jumlah Dibayarkan",
    "it": "Importo pagato",
    "ja": "支払済金額",
    "key": "RL_AmountPaid",
    "ko": "결제 예정 금액",
    "nb": "Beløp betalt",
    "nl": "Betaald bedrag",
    "pl": "Kwota zapłacona",
    "pt-BR": "Valor pago",
    "pt-PT": "Montante pago",
    "ru": "Выплаченная сумма",
    "sv": "Betalat belopp",
    "th": "จำนวนเงินที่จ่าย",
    "tr": "Ödenen Tutar",
    "zh-Hans": "支出金额",
    "zh-Hant": "實付金額"
  },
  "RL_AmountRange": {
    "ar": "نطاق المبلغ",
    "ca": "Interval d'imports",
    "comment": "Amount Range - the minimum and maximum amount to look up transactions.",
    "cs": "Rozpětí částek",
    "da": "Beløbsinterval",
    "de": "Mengenbereich",
    "el": "Εύρος ποσών",
    "en": "Amount Range",
    "en-GB": "Amount Range",
    "en-XA": "[Åɱöûñţ Ŕåñĝé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Intervalo de monto",
    "es-AR": "Rango de importe",
    "es-ES": "Rango de importe",
    "es-MX": "Rango del monto",
    "fr": "Plage de montant",
    "hu": "Összegtartomány",
    "id": "Rentang Jumlah",
    "it": "Intervallo importi",
    "ja": "金額範囲",
    "key": "RL_AmountRange",
    "ko": "금액 범위",
    "nb": "Beløpsområde",
    "nl": "Bedragbereik",
    "pl": "Przedział kwotowy",
    "pt-BR": "Faixa de valores",
    "pt-PT": "Intervalo de valor",
    "ru": "Диапазон суммы",
    "sv": "Beloppsintervall",
    "th": "ช่วงจำนวนเงิน",
    "tr": "Tutar Aralığı",
    "zh-Hans": "金额范围",
    "zh-Hant": "金額範圍"
  },
  "RL_AmountReturned": {
    "ar": "المبلغ الذي تم إرجاعه إلى العميل",
    "ca": "Importe reembolsado al cliente",
    "comment": "Indicates the amount refunded to customer in receipt",
    "cs": "Částka vrácená zákazníkovi",
    "da": "Beløb refunderet til kunde",
    "de": "Amount Refunded To Customer",
    "el": "Ποσό επιστροφής χρημάτων σε πελάτη",
    "en": "Amount Refunded To Customer",
    "en-GB": "Amount Refunded To Customer",
    "en-XA": "[Åɱöûñţ Ŕéƒûñðéð Ţö Çûšţöɱéŕ åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Monto reembolsado al consumidor",
    "es-AR": "Monto reembolsado al cliente",
    "es-ES": "Importe reembolsado al cliente",
    "es-MX": "Monto reembolsado al cliente",
    "fr": "Montant remboursé au client",
    "hu": "Amount Refunded To Customer",
    "id": "Jumlah yang Dikembalikan kepada Pelanggan",
    "it": "Importo rimborsato al cliente",
    "ja": "お客様への返金額",
    "key": "RL_AmountReturned",
    "ko": "고객에게 환불된 금액",
    "nb": "Beløp refundert til kunde",
    "nl": "Bedrag gerestitueerd aan de klant",
    "pl": "Kwota zwrócona klientowi",
    "pt-BR": "Valor reembolsado ao Cliente",
    "pt-PT": "Valor reembolsado ao cliente",
    "ru": "Сумма, возвращенная клиенту",
    "sv": "Belopp återbetalat till kunden",
    "th": "จำนวนเงินที่คืนลูกค้า",
    "tr": "Tüketiciye İade Edilen Tutar",
    "zh-Hans": "顾客收到退款金额",
    "zh-Hant": "顧客退款金額"
  },
  "RL_AppName": {
    "ar": "اسم التطبيق",
    "ca": "Nom de l'aplicació",
    "comment": "Name of the front end application name in the certificate. Certificate is the audit certificate that's gives the approval to trade.",
    "cs": "Název aplikace",
    "da": "Programnavn",
    "de": "Anwendungsname",
    "el": "Όνομα εφαρμογής",
    "en": "Application Name",
    "en-GB": "Application name",
    "en-XA": "[Åþþļîçåţîöñ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre de la app",
    "es-AR": "Nombre de la aplicación",
    "es-ES": "Nombre de la aplicación",
    "es-MX": "Nombre de la app",
    "fr": "Nom de l'application",
    "hu": "Alkalmazás neve",
    "id": "Nama Aplikasi",
    "it": "Nome applicazione",
    "ja": "アプリケーション名",
    "key": "RL_AppName",
    "ko": "애플리케이션 이름",
    "nb": "Applikasjonsnavn",
    "nl": "Applicatienaam",
    "pl": "Nazwa aplikacji",
    "pt-BR": "Nome do aplicativo",
    "pt-PT": "Nome da aplicação",
    "ru": "Название приложения",
    "sv": "Appens namn",
    "th": "ชื่อแอพพลิเคชัน",
    "tr": "Uygulama Adı",
    "zh-Hans": "应用程序名称",
    "zh-Hant": "應用程式名稱"
  },
  "RL_AppVersion": {
    "ar": "إصدار التطبيق",
    "ca": "Versió de l'aplicació",
    "comment": "Version of the application ",
    "cs": "Verze aplikace",
    "da": "Programversion",
    "de": "Anwendungsversion",
    "el": "Έκδοση εφαρμογής",
    "en": "Application Version",
    "en-GB": "Application version",
    "en-XA": "[Åþþļîçåţîöñ Ṽéŕšîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Versión de la app",
    "es-AR": "Versión de la aplicación",
    "es-ES": "Versión de la aplicación",
    "es-MX": "Versión de la app",
    "fr": "Version de l'application",
    "hu": "Alkalmazás verziója",
    "id": "Versi Aplikasi",
    "it": "Versione dell'applicazione",
    "ja": "アプリケーションのバージョン",
    "key": "RL_AppVersion",
    "ko": "애플리케이션 버전",
    "nb": "Applikasjonsversjon",
    "nl": "Versie van app",
    "pl": "Wersja aplikacji",
    "pt-BR": "Versão do aplicativo",
    "pt-PT": "Versão da aplicação",
    "ru": "Версия приложения",
    "sv": "Appversion",
    "th": "เวอร์ชันของแอพพลิเคชัน",
    "tr": "Uygulama Sürümü",
    "zh-Hans": "应用程序版本",
    "zh-Hant": "應用程式版本"
  },
  "RL_ApprovalDateTime": {
    "ar": "تاريخ ووقت الموافقة",
    "ca": "Data i hora d'aprovació",
    "comment": "The date and time when the payment was approved by the payment vendor. ",
    "cs": "Datum čas schválení",
    "da": "Godkendelsesdato og -tidspunkt",
    "de": "Genehmigungsdatum und -uhrzeit",
    "el": "Ημερομηνία και ώρα έγκρισης",
    "en": "Approval Date Time",
    "en-GB": "Approval Date Time",
    "en-XA": "[Åþþŕöṽåļ Ðåţé Ţîɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Fecha y hora de aprobación",
    "es-AR": "Fecha y hora de aprobación",
    "es-ES": "Fecha y hora de la autorización",
    "es-MX": "Hora y fecha de aprobación",
    "fr": "Date et heure de l'approbation",
    "hu": "Jóváhagyás dátuma és időpontja",
    "id": "Tanggal Waktu Persetujuan",
    "it": "Data e ora dell'approvazione",
    "ja": "承認された日時",
    "key": "RL_ApprovalDateTime",
    "ko": "승인 일시",
    "nb": "Godkjenning dato klokkeslett",
    "nl": "Datum en tijd van goedkeuring",
    "pl": "Data i czas akceptacji",
    "pt-BR": "Data e hora da aprovação",
    "pt-PT": "Data e hora de aprovação",
    "ru": "Дата и время утверждения",
    "sv": "Datum och tid för godkännande",
    "th": "เวลาวันที่ที่อนุมัติ",
    "tr": "Onay Tarihi ve Saati",
    "zh-Hans": "批准日期和时间",
    "zh-Hant": "核准日期與時間"
  },
  "RL_ApprovalNumber": {
    "ar": "رقم الموافقة",
    "ca": "Número d'aprovació",
    "comment": "Approval Number received after the payment is complete. ",
    "cs": "Číslo schválení",
    "da": "Godkendelsesnummer",
    "de": "Genehmigungsnummer",
    "el": "Αριθμός έγκρισης",
    "en": "Approval Number",
    "en-GB": "Approval Number",
    "en-XA": "[Åþþŕöṽåļ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de aprobación",
    "es-AR": "Número de aprobación",
    "es-ES": "Número de aprobación",
    "es-MX": "Número de aprobación",
    "fr": "Numéro d'approbation",
    "hu": "Jóváhagyási szám",
    "id": "Nomor Persetujuan",
    "it": "Numero approvazione",
    "ja": "承認番号",
    "key": "RL_ApprovalNumber",
    "ko": "승인 번호",
    "nb": "Godkjenningsnummer",
    "nl": "Goedkeuringsnummer",
    "pl": "Numer zatwierdzenia",
    "pt-BR": "Número de aprovação",
    "pt-PT": "Número de aprovação",
    "ru": "Номер утверждения",
    "sv": "Godkännandenummer",
    "th": "หมายเลขที่อนุมัติ",
    "tr": "Onay Numarası",
    "zh-Hans": "批准号",
    "zh-Hant": "核准編號"
  },
  "RL_ApproverOperatorID": {
    "ar": "تتم الموافقة على معرّف المشغّل",
    "ca": "Codigo Op Aprobador:",
    "comment": "Id of the approving operator in receipt",
    "cs": "ID schval. operátora",
    "da": "Godkendende operator-ID",
    "de": "Genehmigen Bediener-ID",
    "el": "Αναγνωριστικό εξουσιοδοτημένου χειριστή",
    "en": "Approving Operator ID",
    "en-GB": "Approving Operator ID",
    "en-XA": "[Åþþŕöṽîñĝ Öþéŕåţöŕ ÎÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Aprobando Id. de operador:",
    "es-AR": "Aprobando Id. de operador",
    "es-ES": "Codigo Op Aprobador:",
    "es-MX": "Aprobando Id. de operador:",
    "fr": "Manager ID",
    "hu": "Approving Operator ID",
    "id": "ID Operator Pemberi Persetujuan",
    "it": "Approvazione ID operatore",
    "ja": "オペレーターIDを承認中",
    "key": "RL_ApproverOperatorID",
    "ko": "승인 운영자 ID",
    "nb": "Godkjenner operatør-ID",
    "nl": "ID goedkeurende operator",
    "pl": "ID operatora zatwierdzającego",
    "pt-BR": "ID do operador aprovador",
    "pt-PT": "ID do operador autor da aprovação",
    "ru": "Номер подтвердившего оператора",
    "sv": "Godkännares id",
    "th": "ID ผู้ดำเนินการที่อนุมัติ",
    "tr": "Onaylayan Operatör Kimliği",
    "zh-Hans": "审核操作人员 ID",
    "zh-Hant": "核准收銀員 ID"
  },
  "RL_AthleteLoginNumber": {
    "ar": "رقم تسجيل دخول الموظف بدرجة Athlete",
    "ca": "Número d'inici de sessió de l'atleta",
    "comment": "Login Id or number of the athlete ",
    "cs": "Přihlašovací číslo Athlete",
    "da": "Loginnummer for Athlete",
    "de": "Login-Nummer des Athlete",
    "el": "Αριθμός σύνδεσης Athlete",
    "en": "Athlete Login Number",
    "en-GB": "Athlete Login Number",
    "en-XA": "[Åţĥļéţé Ļöĝîñ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de inicio de sesión del Athlete",
    "es-AR": "Número de inicio de sesión del athlete",
    "es-ES": "Número de inicio de sesión del athlete",
    "es-MX": "Número de inicio de sesión del Athlete",
    "fr": "Numéro de connexion de l'Athlete",
    "hu": "Athlete bejelentkezési azonosítója",
    "id": "Nomor Login Athlete",
    "it": "Numero credenziali Athlete",
    "ja": "アスリートのログイン番号",
    "key": "RL_AthleteLoginNumber",
    "ko": "Athlete 로그인 번호",
    "nb": "Athlete innloggingsnummer",
    "nl": "Inlognummer Athlete",
    "pl": "Numer logowania Athlete",
    "pt-BR": "Número de login do Athlete",
    "pt-PT": "Número de início de sessão do Athlete",
    "ru": "Номер атлета для входа",
    "sv": "Inloggningsnummer för Athlete",
    "th": "หมายเลขล็อกอิน Athlete",
    "tr": "Athlete Oturum Açma Numarası",
    "zh-Hans": "Athlete 登录号",
    "zh-Hant": "Athlete 登入號碼"
  },
  "RL_AuthCode": {
    "ar": "رمز الترخيص",
    "ca": "Código Autoriz",
    "comment": "Authentication  code for gift card in receipt",
    "cs": "Autor. kód",
    "da": "Aut.kode",
    "de": "Auth.-Code",
    "el": "Κωδικός έγκρισης",
    "en": "Auth Code",
    "en-GB": "Auth Code",
    "en-XA": "[Åûţĥ Çöðé 耐克รื่بُو]",
    "es-419": "Código de aut",
    "es-AR": "Código de aut",
    "es-ES": "Código Autoriz",
    "es-MX": "Código de aut.",
    "fr": "Code Aut",
    "hu": "Auth Code",
    "id": "Kode Otorisasi",
    "it": "Codice Aut",
    "ja": "認証番号",
    "key": "RL_AuthCode",
    "ko": "승인번호",
    "nb": "Autorisasjonskode",
    "nl": "Aut.code",
    "pl": "Kod autoryzacyjny",
    "pt-BR": "Código de autorização",
    "pt-PT": "Código de autenticação",
    "ru": "Код авторизации",
    "sv": "Aut. kod",
    "th": "รหัสยืนยัน",
    "tr": "Yetk. Kodu",
    "zh-Hans": "认证码",
    "zh-Hant": "識別碼"
  },
  "RL_AuthDate": {
    "ar": "تاريخ الترخيص",
    "ca": "Auth. Date",
    "comment": "Authentication  date for gift card in receipt",
    "cs": " Auth. Date",
    "da": "Auth. Date",
    "de": "Auth. Date",
    "el": "Ημερομηνία έγκρισης",
    "en": "Auth. Date",
    "en-GB": "Auth. Date",
    "en-XA": "[Åûţĥ· Ðåţé å耐克รื่بُو]",
    "es-419": "Aut. Fecha",
    "es-AR": "Aut. Fecha",
    "es-ES": "Auth. Date",
    "es-MX": "Aut. Fecha",
    "fr": "Auth. Date",
    "hu": "Auth. Date",
    "id": "Tanggal Otorisasi",
    "it": "Auth. Date",
    "ja": "Auth. Date",
    "key": "RL_AuthDate",
    "ko": "승인 날짜",
    "nb": "Aut. Dato",
    "nl": "Aut.datum",
    "pl": "Data autoryzacji",
    "pt-BR": "Aut. Data",
    "pt-PT": "Data de autenticação",
    "ru": "Дата авторизации",
    "sv": "Aukt. datum",
    "th": "วันที่ยืนยัน",
    "tr": "Yetk. Tarihi",
    "zh-Hans": "认证日期",
    "zh-Hant": "授權日期"
  },
  "RL_AuthNo": {
    "ar": "رقم الترخيص",
    "ca": "Autoriz. No.",
    "comment": "Authentication  number for gift card in receipt",
    "cs": "C.Autor",
    "da": "Aut. Nej.",
    "de": "Auth. No",
    "el": "Αριθμός έγκρισης",
    "en": "Auth. No",
    "en-GB": "Auth. No",
    "en-XA": "[Åûţĥ· Ñö 耐克รื่بُو]",
    "es-419": "N.º aut",
    "es-AR": "Aut. de tel",
    "es-ES": "AutNo",
    "es-MX": "Aut. N.º",
    "fr": "Auto No",
    "hu": "Auth. No",
    "id": "No. Otorisasi",
    "it": "N. aut",
    "ja": "認証番号.",
    "key": "RL_AuthNo",
    "ko": "승인 번호",
    "nb": "Aut. Nei",
    "nl": "Aut.nr.",
    "pl": "Nr autoryzacji",
    "pt-BR": "N° Aut.",
    "pt-PT": "N.º de autenticação",
    "ru": "№ аутентификации",
    "sv": "Aut. nr.",
    "th": "เลขยืนยัน",
    "tr": "Yetk. No",
    "zh-Hans": "认证码",
    "zh-Hant": "授權碼"
  },
  "RL_AvailablePoints": {
    "ar": "النقاط المتوفرة",
    "ca": "Ptos. Disponibles",
    "comment": "Available points of  membership in receipt",
    "cs": "Dostupné body",
    "da": "tilgængelige point",
    "de": "Available Points",
    "el": "Διαθέσιμοι πόντοι",
    "en": "Available Points",
    "en-GB": "Available Points",
    "en-XA": "[Åṽåîļåƀļé Þöîñţš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Puntos disponibles",
    "es-AR": "Puntos disponibles",
    "es-ES": "Ptos. disponibles",
    "es-MX": "Puntos disponibles",
    "fr": "Points disponibles",
    "hu": "Available Points",
    "id": "Poin Tersedia",
    "it": "Punti disponibili",
    "ja": "Available Points",
    "key": "RL_AvailablePoints",
    "ko": "사용 가능한 포인트",
    "nb": "Tilgjengelige poeng",
    "nl": "Beschikbare punten",
    "pl": "Dostępne punkty",
    "pt-BR": "Pontos disponíveis",
    "pt-PT": "Pontos disponíveis",
    "ru": "Доступные баллы",
    "sv": "Tillgängliga poäng",
    "th": "คะแนนที่ใช้ได้",
    "tr": "Kullanılabilir Puanlar",
    "zh-Hans": "可用积分",
    "zh-Hant": "可使用點數"
  },
  "RL_BagFee": {
    "ar": "رسوم الطرد",
    "ca": "Preu de la bossa",
    "comment": "Bag Fees or the Carrier Bag price printed on the receipts.",
    "cs": "Taška – poplatek",
    "da": "Posepris",
    "de": "Taschengebühr",
    "el": "Χρέωση τσάντας",
    "en": "Bag Fee",
    "en-GB": "Carrier Bags",
    "en-XA": "[Ɓåĝ Ƒéé 耐克รื่بُو]",
    "es-419": "Tarifa de la bolsa de compras",
    "es-AR": "Precio de la bolsa",
    "es-ES": "Precio de la bolsa",
    "es-MX": "Cargo por la bolsa",
    "fr": "Supplément sac",
    "hu": "Táska díja",
    "id": "Biaya Tas",
    "it": "Costo sacchetto",
    "ja": "ショッピングバッグ代金",
    "key": "RL_BagFee",
    "ko": "쇼핑백값",
    "nb": "Handlekurvavgift",
    "nl": "Tasprijs",
    "pl": "Opłata za torbę",
    "pt-BR": "Taxa de sacola",
    "pt-PT": "Preço do saco",
    "ru": "Сбор за упаковку",
    "sv": "Påsavgift",
    "th": "ค่าธรรมเนียมสำหรับถุง",
    "tr": "Sepet Tutarı",
    "zh-Hans": "购物袋费用",
    "zh-Hant": "手提袋費用"
  },
  "RL_Balance": {
    "ar": "الرصيد",
    "ca": "Saldo",
    "comment": "Balance of gift card in receipt",
    "cs": "Zustatek",
    "da": "Saldo",
    "de": "Balance",
    "el": "Υπόλοιπο",
    "en": "Balance",
    "en-GB": "Balance",
    "en-XA": "[Ɓåļåñçé 耐克รื่بُو]",
    "es-419": "Saldo",
    "es-AR": "Saldo",
    "es-ES": "Saldo",
    "es-MX": "Saldo",
    "fr": "Solde",
    "hu": "Balance",
    "id": "Saldo",
    "it": " Saldo",
    "ja": "残額",
    "key": "RL_Balance",
    "ko": "잔액",
    "nb": "Saldo",
    "nl": "Saldo",
    "pl": "Saldo",
    "pt-BR": "Equilíbrio",
    "pt-PT": "Saldo",
    "ru": "Баланс",
    "sv": "Saldo",
    "th": "ยอดคงเหลือ",
    "tr": "Bakiye",
    "zh-Hans": "余额",
    "zh-Hant": "餘額"
  },
  "RL_BalanceDue": {
    "ar": "الرصيد المستحق",
    "ca": "Saldo adeudado",
    "comment": "Balance due for the transaction in receipt",
    "cs": "Splatný Zustatek",
    "da": "Forfalden saldo",
    "de": "Restbetrag",
    "el": "Οφειλόμενο υπόλοιπο",
    "en": "Balance Due",
    "en-GB": "Balance Due",
    "en-XA": "[Ɓåļåñçé Ðûé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Saldo adeudado",
    "es-AR": "Saldo adeudado",
    "es-ES": "Saldo por Cobrar",
    "es-MX": "Saldo adeudado",
    "fr": "Solde dû",
    "hu": "Balance Due",
    "id": "Jatuh Tempo Saldo",
    "it": "Saldo dovuto",
    "ja": "ご請求額",
    "key": "RL_BalanceDue",
    "ko": "미지급액",
    "nb": "Saldo",
    "nl": "Te betalen bedrag",
    "pl": "Saldo należne",
    "pt-BR": "Saldo devedor",
    "pt-PT": "Saldo pendente",
    "ru": "Сумма к оплате",
    "sv": "Saldo att betala",
    "th": "ยอดคงเหลือต้องชำระ",
    "tr": "Ödenecek Bakiye",
    "zh-Hans": "应付余额",
    "zh-Hant": "應付額"
  },
  "RL_BankTransfer": {
    "ar": "حوالة مصرفية",
    "ca": "Transferencia bancaria",
    "comment": "Bank Transfer",
    "cs": "Bankovní převod",
    "da": "Bankoverførsel",
    "de": "Überweisung",
    "el": "Τραπεζικό έμβασμα",
    "en": "Bank Transfer",
    "en-GB": "Bank Transfer",
    "en-XA": "[Ɓåñķ Ţŕåñšƒéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Transferencia bancaria",
    "es-AR": "Transferencia bancaria",
    "es-ES": "Transferencia bancaria",
    "es-MX": "Transferencia bancaria",
    "fr": "Virement bancaire",
    "hu": "Bank Transfer",
    "id": "Transfer Bank",
    "it": "Bonifico bancario",
    "ja": "振込",
    "key": "RL_BankTransfer",
    "ko": "은행 이체",
    "nb": "Bankoverføring",
    "nl": "Bankoverboeking",
    "pl": "Przelew bankowy",
    "pt-BR": "Transferência bancária",
    "pt-PT": "Transf. Bancária",
    "ru": "Банковский перевод",
    "sv": "Banköverföring",
    "th": "โอนเงินผ่านธนาคาร",
    "tr": "Banka Havalesi",
    "zh-Hans": "银行汇款",
    "zh-Hant": "銀行轉帳"
  },
  "RL_BillingAddress": {
    "ar": "عنوان الفوترة",
    "ca": "Dirección de Facturación",
    "comment": "Billing address in receipt",
    "cs": "Fakturační adresa",
    "da": "Faktureringsadresse",
    "de": "Billing Address",
    "el": "Διεύθυνση χρέωσης",
    "en": "Billing Address",
    "en-GB": "Billing Address",
    "en-XA": "[Ɓîļļîñĝ Åððŕéšš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Dirección de facturación",
    "es-AR": "Dirección de facturación",
    "es-ES": "Dirección de Facturación",
    "es-MX": "Dirección de facturación",
    "fr": "Adresse fact",
    "hu": "Billing Address",
    "id": "Alamat Penagihan",
    "it": "Indirizzo fattura",
    "ja": "請求先住所",
    "key": "RL_BillingAddress",
    "ko": "청구서 발송지",
    "nb": "Fakturaadresse",
    "nl": "Factuuradres",
    "pl": "Adres rozliczeniowy",
    "pt-BR": "Endereço de cobrança",
    "pt-PT": "Morada de faturação",
    "ru": "Адрес выставления счета",
    "sv": "Fakturaadress",
    "th": "ที่อยู่เพื่อเรียกเก็บเงิน",
    "tr": "Fatura Adresi",
    "zh-Hans": "帐单地址",
    "zh-Hant": "帳單地址"
  },
  "RL_BuyerTaxIdentificationNumber": {
    "ar": "رقم التعريف الضريبي للمشتري",
    "ca": "Número d'identificació fiscal del comprador/a",
    "comment": "Buyer's Tax Identification Number printed on the receipt. ",
    "cs": "Daňové identifikační číslo kupujícího",
    "da": "Købers skatteidentifikationsnummer",
    "de": "Steueridentifikationsnummer Käufer:in",
    "el": "ΑΦΜ αγοραστή",
    "en": "Buyer's Tax Identification Number",
    "en-GB": "Buyer's Tax Identification Number",
    "en-XA": "[Ɓûýéŕ´š Ţåẋ Îðéñţîƒîçåţîöñ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de identificación fiscal del comprador",
    "es-AR": "Número de identificación fiscal del comprador",
    "es-ES": "Número de identificación fiscal del comprador",
    "es-MX": "Número de identificación fiscal del comprador",
    "fr": "Numéro d'identification fiscale du client ou de la cliente",
    "hu": "Vevő adóazonosító száma",
    "id": "Nomor Identifikasi Pajak Pembeli",
    "it": "Numero identificazione fiscale acquirente",
    "ja": "購入者の納税者番号",
    "key": "RL_BuyerTaxIdentificationNumber",
    "ko": "구매자의 납세자 식별번호",
    "nb": "Kjøpers skattenummer",
    "nl": "Btw-identificatienummer van de koper",
    "pl": "NIP osoby kupującej",
    "pt-BR": "Número de identificação fiscal do comprador",
    "pt-PT": "Número de identificação fiscal do comprador",
    "ru": "Идентификационный налоговый номер покупателя",
    "sv": "Köparens personnummer",
    "th": "หมายเลขประจำตัวผู้เสียภาษีอากรของผู้ซื้อ",
    "tr": "Alıcının Vergi Kimlik Numarası",
    "zh-Hans": "买家税务识别码",
    "zh-Hant": "買家稅金身份證號碼"
  },
  "RL_COMMERCIALINVOICE": {
    "ar": "فاتورة تجارية",
    "ca": "FACTURA COMERC.",
    "comment": "Denotes the Commercial Invoice used in transaction",
    "cs": "KOMERČNÍ POZVÁNKA",
    "da": "Reklame kvit",
    "de": "HANDELSGEWERBE-RECHNUNG",
    "el": "ΕΜΠΟΡΙΚΟ ΤΙΜΟΛΟΓΙΟ",
    "en": "COMMERCIAL INVOICE",
    "en-GB": "COMMERCIAL INVOICE",
    "en-XA": "[ÇÖṀṀÉŔÇÎÅĻ ÎÑṼÖÎÇÉ Пβシ耐克บั้ รื่بُو]",
    "es-419": "FACTURA COMERCIAL",
    "es-AR": "FACTURA COMERCIAL",
    "es-ES": "FACTURA COMERC.",
    "es-MX": "FACTURA COMERCIAL",
    "fr": "FACTURE COMMERCIALE",
    "hu": "COMMERCIAL INVOICE",
    "id": "FAKTUR KOMERSIAL",
    "it": "FATTURA COMMERCIALE",
    "ja": "COMMERCIAL INVOICE",
    "key": "RL_COMMERCIALINVOICE",
    "ko": "상업 송장",
    "nb": "KOMMERSIELL FAKTURA",
    "nl": "COMMERCIAL INVOICE",
    "pl": "FAKTURA HANDLOWA",
    "pt-BR": "FATURA COMERCIAL",
    "pt-PT": "Factura Comercial",
    "ru": "COMMERCIAL INVOICE",
    "sv": "WHANDELSFAKTURA",
    "th": "ใบกำกับสินค้าเชิงพาณิชย์",
    "tr": "TİCARİ FATURA",
    "zh-Hans": "商业发票",
    "zh-Hant": "商業發票"
  },
  "RL_COUPON": {
    "ar": "قسيمة",
    "ca": "CUPÓN",
    "comment": "The coupon used during transaction",
    "cs": "KUPON",
    "da": "KUPON:",
    "de": "GUTSCHEIN",
    "el": "ΚΟΥΠΟΝΙ",
    "en": "COUPON",
    "en-GB": "COUPON",
    "en-XA": "[ÇÖÛÞÖÑ 耐克รื่بُو]",
    "es-419": "CUPÓN",
    "es-AR": "CUPÓN",
    "es-ES": "CUPÓN",
    "es-MX": "CUPÓN",
    "fr": "BON DE RÉDUCTION",
    "hu": "COUPON",
    "id": "KUPON",
    "it": "COUPON",
    "ja": "COUPON",
    "key": "RL_COUPON",
    "ko": "쿠폰",
    "nb": "KUPONG",
    "nl": "KORTINGSBON",
    "pl": "KUPON",
    "pt-BR": "CUPOM",
    "pt-PT": "CUPÃO",
    "ru": "COUPON",
    "sv": "WKUPONG",
    "th": "คูปอง",
    "tr": "KUPON",
    "zh-Hans": "优惠券",
    "zh-Hant": "優惠券"
  },
  "RL_Cancel": {
    "ar": "إلغاء",
    "ca": "Cancel·la",
    "comment": "Prompting user to cancel an action in the UI. ",
    "cs": "Zrušit",
    "da": "Annuller",
    "de": "Abbrechen",
    "el": "Ακύρωση",
    "en": "Cancel",
    "en-GB": "Cancel",
    "en-XA": "[Çåñçéļ 耐克รื่بُو]",
    "es-419": "Cancelar",
    "es-AR": "Cancelar",
    "es-ES": "Cancelar",
    "es-MX": "Cancelar",
    "fr": "Annuler",
    "hu": "Mégse",
    "id": "Batal",
    "it": "Annulla",
    "ja": "キャンセル",
    "key": "RL_Cancel",
    "ko": "취소",
    "nb": "Avbryt",
    "nl": "Annuleer",
    "pl": "Anuluj",
    "pt-BR": "Cancelar",
    "pt-PT": "Cancelar",
    "ru": "Отмена",
    "sv": "Avbryt",
    "th": "ยกเลิก",
    "tr": "İptal",
    "zh-Hans": "取消",
    "zh-Hant": "取消"
  },
  "RL_Card": {
    "ar": "بطاقة",
    "ca": "Targeta",
    "comment": "Card",
    "cs": "Karta",
    "da": "Kort",
    "de": "Karte",
    "el": "Κάρτα",
    "en": "Card",
    "en-GB": "Card",
    "en-XA": "[Çåŕð 耐克รื่بُو]",
    "es-419": "Tarjeta",
    "es-AR": "Tarjeta",
    "es-ES": "Tarjeta",
    "es-MX": "Tarjeta",
    "fr": "Carte",
    "hu": "Kártya",
    "id": "Kartu",
    "it": "Carta",
    "ja": "カード",
    "key": "RL_Card",
    "ko": "카드",
    "nb": "Kort",
    "nl": "Kaart",
    "pl": "Karta",
    "pt-BR": "Cartão",
    "pt-PT": "Cartão",
    "ru": "Карта",
    "sv": "Kort",
    "th": "บัตร",
    "tr": "Kart",
    "zh-Hans": "卡片",
    "zh-Hant": "卡片"
  },
  "RL_CardHolder": {
    "ar": "حامل البطاقة",
    "ca": "Titular",
    "comment": "Cardholder",
    "cs": "DržitelKarty",
    "da": "Kortholder",
    "de": "Karteninhaber",
    "el": "Κάτοχος κάρτας",
    "en": "Cardholder",
    "en-GB": "Cardholder",
    "en-XA": "[Çåŕðĥöļðéŕ å耐克รื่بُو]",
    "es-419": "Titular de la tarjeta",
    "es-AR": "Titular de la tarjeta",
    "es-ES": "Titular",
    "es-MX": "Titular de la tarjeta",
    "fr": "Titulairecarte",
    "hu": "Kártyatulajdonos",
    "id": "Pemilik Kartu",
    "it": "Titolare della carta",
    "ja": "カード名義人",
    "key": "RL_CardHolder",
    "ko": "카드 소지자",
    "nb": "Kortinnehaver",
    "nl": "Kaarthouder",
    "pl": "Właściciel karty",
    "pt-BR": "Titular do cartão",
    "pt-PT": "Titular do cartão",
    "ru": "Владелец карты",
    "sv": "Kortinnehavare",
    "th": "ผู้ถือบัตร",
    "tr": "Kart Sahibi",
    "zh-Hans": "持卡人",
    "zh-Hant": "持卡人"
  },
  "RL_CardName": {
    "ar": "اسم البطاقة",
    "ca": "Nom de la targeta",
    "comment": "Card name used in the payment during checkout ",
    "cs": "Jméno na kartě",
    "da": "Kortnavn",
    "de": "Kartenname",
    "el": "Όνομα κάρτας",
    "en": "Card Name",
    "en-GB": "Card Name",
    "en-XA": "[Çåŕð Ñåɱé 耐克รื่بُو]",
    "es-419": "Nombre en la tarjeta",
    "es-AR": "Nombre de la tarjeta",
    "es-ES": "Nombre de la tarjeta",
    "es-MX": "Nombre en la tarjeta",
    "fr": "Nom de la carte",
    "hu": "Kártya neve",
    "id": "Nama Kartu",
    "it": "Nome sulla card",
    "ja": "カード名",
    "key": "RL_CardName",
    "ko": "카드사명",
    "nb": "Kortnavn",
    "nl": "Kaartnaam",
    "pl": "Nazwa karty",
    "pt-BR": "Nome do cartão",
    "pt-PT": "Nome do cartão",
    "ru": "Название карты",
    "sv": "Namn på kort",
    "th": "ชื่อบัตร",
    "tr": "Kart Adı",
    "zh-Hans": "卡片名称",
    "zh-Hant": "卡片名稱"
  },
  "RL_CardNo": {
    "ar": "رقم البطاقة",
    "ca": "N° Tarjeta",
    "comment": "Gift card number in receipt",
    "cs": "Karta c",
    "da": "Kortnr",
    "de": " Kartennummer ",
    "el": "Αριθμός κάρτας",
    "en": "Card No",
    "en-GB": "Card No",
    "en-XA": "[Çåŕð Ñö 耐克รื่بُو]",
    "es-419": "N.º de tarjeta",
    "es-AR": "Tarjeta N.º",
    "es-ES": "Tarjeta  Cambio",
    "es-MX": "N.º de tarjeta",
    "fr": "No. carte",
    "hu": "Card No",
    "id": "No. Kartu",
    "it": "N. carta",
    "ja": "カード番号",
    "key": "RL_CardNo",
    "ko": "카드 번호",
    "nb": "Kortnr.",
    "nl": "Kaartnr.",
    "pl": "Nr karty",
    "pt-BR": "N° do cartão",
    "pt-PT": "N.º do cartão",
    "ru": "№ карты",
    "sv": "Kortnr.",
    "th": "เลขบัตร",
    "tr": "Kart No",
    "zh-Hans": "卡号",
    "zh-Hant": "卡號"
  },
  "RL_CashRounding": {
    "ar": "تدوير المبلغ النقدي",
    "ca": "Cash rounding",
    "comment": "Line indicating  rounded cash in rceipt line",
    "cs": "Cash rounding",
    "da": "Cash rounding ",
    "de": "Cash rounding",
    "el": "Στρογγυλοποίηση ποσού",
    "en": "Cash rounding",
    "en-GB": "Cash rounding",
    "en-XA": "[Çåšĥ ŕöûñðîñĝ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Redondeo de efectivo",
    "es-AR": "Redondeo de efectivo",
    "es-ES": "Cash rounding",
    "es-MX": "Redondeo de efectivo",
    "fr": "Cash rounding",
    "hu": "Cash rounding",
    "id": "Pembulatan uang tunai",
    "it": "Arrotondamento di denaro contante ",
    "ja": "現金払い",
    "key": "RL_CashRounding",
    "ko": "현금 반올림",
    "nb": "Kontantavrunding",
    "nl": "Bedrag afronden",
    "pl": "Gotówka (kwota zaokrąglona)",
    "pt-BR": "Arredondamento de valor",
    "pt-PT": "Arredondamento do valor",
    "ru": "Округление суммы",
    "sv": "Avrundat belopp",
    "th": "ปัดเศษเงินสด",
    "tr": "Nakit yuvarlama",
    "zh-Hans": "现金取整",
    "zh-Hant": "現金取整"
  },
  "RL_CertificateName": {
    "ar": "اسم الشهادة",
    "ca": "Nom del certificat",
    "comment": " Certificate name provided by the ministry of finance in Portugal. ",
    "cs": "Název certifikátu",
    "da": "Certifikatets navn",
    "de": "Bescheinigungsnummer",
    "el": "Όνομα πιστοποιητικού",
    "en": "Certificate Name",
    "en-GB": "Certificate name",
    "en-XA": "[Çéŕţîƒîçåţé Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del certificado",
    "es-AR": "Nombre del certificado",
    "es-ES": "Nombre del certificado",
    "es-MX": "Nombre del certificado",
    "fr": "Nom de certificat",
    "hu": "Tanúsítvány elnevezése",
    "id": "Nama Sertifikat",
    "it": "Nome certificato",
    "ja": "証明書の名称",
    "key": "RL_CertificateName",
    "ko": "증명서 명칭",
    "nb": "Navn på sertifikat",
    "nl": "Certificaatnaam",
    "pl": "Nazwa certyfikatu",
    "pt-BR": "Nome do certificado",
    "pt-PT": "Nome do certificado",
    "ru": "Название сертификата",
    "sv": "Certifikatets namn",
    "th": "ชื่อใบรับรอง",
    "tr": "Sertifika Adı",
    "zh-Hans": "证书名称",
    "zh-Hant": "禮券名稱"
  },
  "RL_CertificateNumber": {
    "ar": "رقم الشهادة",
    "ca": "Número de certificat",
    "comment": "Certificate Number (fiscal document id) printed on the Austria receipts. ",
    "cs": "Číslo certifikátu",
    "da": "Certifikatets nummer",
    "de": "Bescheinigungsnummer",
    "el": "Αριθμός φορολογικού πιστοποιητικού",
    "en": "Certificate Number",
    "en-GB": "Certificate number",
    "en-XA": "[Çéŕţîƒîçåţé Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de certificado",
    "es-AR": "Número de certificado",
    "es-ES": "Número de certificado",
    "es-MX": "Número de certificado",
    "fr": "Numéro de certificat",
    "hu": "Igazolás száma",
    "id": "Nomor Sertifikat",
    "it": "Numero certificato",
    "ja": "証明書番号",
    "key": "RL_CertificateNumber",
    "ko": "증명서 번호",
    "nb": "Sertifikatnummer",
    "nl": "Certificaatnummer",
    "pl": "Numer certyfikatu",
    "pt-BR": "Número do certificado",
    "pt-PT": "Número do certificado",
    "ru": "Номер сертификата",
    "sv": "Certifikatnummer",
    "th": "หมายเลขใบรับรอง",
    "tr": "Sertifika Numarası",
    "zh-Hans": "礼券号",
    "zh-Hant": "禮券編號"
  },
  "RL_Change": {
    "ar": "المبلغ المتبقي",
    "ca": "Canvi",
    "comment": "Line indicating change after  rounded cash in receipt line",
    "cs": "K vrácení",
    "da": "Byttepenge",
    "de": "Wechselgeld",
    "el": "Ρέστα",
    "en": "Change",
    "en-GB": "Change",
    "en-XA": "[Çĥåñĝé 耐克รื่بُو]",
    "es-419": "Cambio",
    "es-AR": "Cambio",
    "es-ES": "Cambio",
    "es-MX": "Cambio",
    "fr": "Rendu",
    "hu": "Visszajáró",
    "id": "Kembalian",
    "it": "Resto",
    "ja": "釣銭金",
    "key": "RL_Change",
    "ko": "거스름돈",
    "nb": "Endre",
    "nl": "Wisselgeld",
    "pl": "Reszta",
    "pt-BR": "Troco",
    "pt-PT": "Troco",
    "ru": "Сдача",
    "sv": "Växel",
    "th": "เงินทอน",
    "tr": "Para üstü",
    "zh-Hans": "找零",
    "zh-Hant": "找零"
  },
  "RL_City": {
    "ar": "المدينة",
    "ca": "Ciudad",
    "comment": "City in receipt",
    "cs": "Mesto",
    "da": "By",
    "de": "Stadt",
    "el": "Πόλη",
    "en": "City",
    "en-GB": "Town/City",
    "en-XA": "[Çîţý 耐克รื่بُو]",
    "es-419": "Ciudad",
    "es-AR": "Ciudad",
    "es-ES": " Ciudad",
    "es-MX": "Ciudad",
    "fr": "City",
    "hu": "City",
    "id": "Kota",
    "it": "Città",
    "ja": "区/市",
    "key": "RL_City",
    "ko": "시",
    "nb": "Poststed",
    "nl": "Plaats",
    "pl": "Miasto",
    "pt-BR": "Cidade",
    "pt-PT": "Cidade",
    "ru": "Город",
    "sv": "Ort",
    "th": "เมือง",
    "tr": "Şehir",
    "zh-Hans": "城市",
    "zh-Hant": "縣市"
  },
  "RL_ClientID": {
    "ar": "معرِّف العميل",
    "ca": "ID de client",
    "comment": "ClientID or Client Serial Number for TSE  attributes on Germany receipts",
    "cs": "ClientID",
    "da": "KundeID",
    "de": "Client-ID",
    "el": "ClientID",
    "en": "ClientID",
    "en-GB": "ClientID",
    "en-XA": "[ÇļîéñţÎÐ 耐克รื่بُو]",
    "es-419": "iD del cliente",
    "es-AR": "ID de cliente",
    "es-ES": "ID de cliente",
    "es-MX": "ID de cliente",
    "fr": "ClientID",
    "hu": "Ügyfél-azonosító",
    "id": "ClientID",
    "it": "ClientID",
    "ja": "クライアントID",
    "key": "RL_ClientID",
    "ko": "클라이언트 ID",
    "nb": "Kunde-ID",
    "nl": "ClientID",
    "pl": "Identyfikator klienta/klientki",
    "pt-BR": "ID do cliente",
    "pt-PT": "ID de cliente",
    "ru": "ClientID",
    "sv": "Klient-id",
    "th": "ID ลูกค้า",
    "tr": "Tüketici Kimliği",
    "zh-Hans": "客户 ID",
    "zh-Hant": "客戶編號"
  },
  "RL_Color": {
    "ar": "اللون",
    "ca": "Color",
    "comment": "Color of the item in receipt line",
    "cs": "Barva",
    "da": "Farve ",
    "de": "Farbe ",
    "el": "Χρώμα",
    "en": "Color",
    "en-GB": "Colour",
    "en-XA": "[Çöļöŕ 耐克รื่بُو]",
    "es-419": "Color",
    "es-AR": "Color",
    "es-ES": "Color",
    "es-MX": "Color",
    "fr": "Couleur",
    "hu": "Color",
    "id": "Warna",
    "it": "Colore",
    "ja": "Color",
    "key": "RL_Color",
    "ko": "컬러",
    "nb": "Farge",
    "nl": "Kleur",
    "pl": "Kolor",
    "pt-BR": "Cor",
    "pt-PT": "Cor",
    "ru": "Цвет",
    "sv": "Färg",
    "th": "สี",
    "tr": "Renk",
    "zh-Hans": "颜色",
    "zh-Hant": "顏色"
  },
  "RL_CompNo": {
    "ar": "الرقم الإضافيّ",
    "ca": " N° Comp",
    "comment": "Complimentary number in receipt line",
    "cs": "Comp c",
    "da": "Comp nr",
    "de": "Comp No",
    "el": "Αρ. αντ.",
    "en": "Comp No",
    "en-GB": "Comp No.",
    "en-XA": "[Çöɱþ Ñö 耐克รื่بُو]",
    "es-419": "Comp. N.º",
    "es-AR": "Comp. N.º",
    "es-ES": "N° Comp",
    "es-MX": "Comp. N.º",
    "fr": "No. gratuité",
    "hu": "Comp No",
    "id": "No. Gratis",
    "it": "Nu. conto",
    "ja": "商品提供番号",
    "key": "RL_CompNo",
    "ko": "무료 품목 번호",
    "nb": "Gratisnr.",
    "nl": "Comp Nr",
    "pl": "Comp No",
    "pt-BR": "Número da cortesia",
    "pt-PT": "Compra Gratuita N.º",
    "ru": "№ Comp",
    "sv": "Gratiskod",
    "th": "หมายเลขของสมนาคุณ",
    "tr": "Parça No.",
    "zh-Hans": "成本中心",
    "zh-Hant": "贈品編號"
  },
  "RL_CompanyRegistrationNumber": {
    "ar": "رقم تسجيل الشركة",
    "ca": "Número de registre mercantil",
    "comment": "The registered company number with the authorities, It would be different per company, but within a company, stores will have the same value.",
    "cs": "Registrační číslo společnosti",
    "da": "Virksomheds registreringsnummer",
    "de": "Firmenregistrierungsnummer",
    "el": "Αριθμός μητρώου εταιρείας",
    "en": "Company Registration Number",
    "en-GB": "Company Registration Number",
    "en-XA": "[Çöɱþåñý Ŕéĝîšţŕåţîöñ Ñûɱƀéŕ åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Número de registro de la empresa",
    "es-AR": "Número de registro de la empresa",
    "es-ES": "Código de identificación fiscal",
    "es-MX": "Número de registro de la compañía",
    "fr": "Numéro d'enregistrement de l'entreprise",
    "hu": "Cégjegyzékszám",
    "id": "Nomor Registrasi Perusahaan",
    "it": "Numero di registrazione dell'azienda",
    "ja": "登録番号",
    "key": "RL_CompanyRegistrationNumber",
    "ko": "사업자 등록 번호",
    "nb": "Organisasjonsummer",
    "nl": "Registratienummer bedrijf",
    "pl": "Numer ewidencyjny firmy",
    "pt-BR": "Número de registro da empresa",
    "pt-PT": "Número de registo da empresa",
    "ru": "Регистрационный номер компании",
    "sv": "Företagets registreringsnummer",
    "th": "หมายเลขจดทะเบียนบริษัท",
    "tr": "Şirket Kayıt Numarası",
    "zh-Hans": "公司注册号码",
    "zh-Hant": "公司統編"
  },
  "RL_Complimentary": {
    "ar": "مجانًا",
    "ca": "Gratuït",
    "comment": "Complimentary Tender in receipts",
    "cs": "Bezplatně",
    "da": "Uden beregning",
    "de": "Ergänzende Angaben",
    "el": "Συμπληρωματική",
    "en": "Complimentary",
    "en-GB": "Complimentary",
    "en-XA": "[Çöɱþļîɱéñţåŕý Пβシ耐克บั้ รื่بُو]",
    "es-419": "De cortesía",
    "es-AR": "De cortesía",
    "es-ES": "Cortesía",
    "es-MX": "Monto",
    "fr": "Offert",
    "hu": "Jóváhagyás",
    "id": "Pembayaran",
    "it": "Omaggio",
    "ja": "優待商品",
    "key": "RL_Complimentary",
    "ko": "무료",
    "nb": "Gratis",
    "nl": "Gratis",
    "pl": "Towar nieodpłatny",
    "pt-BR": "Cortesia",
    "pt-PT": "Cortesia",
    "ru": "Стоимость",
    "sv": "Utestående belopp",
    "th": "อภินันทนาการ",
    "tr": "Ücretsiz",
    "zh-Hans": "免费赠送",
    "zh-Hant": "優待"
  },
  "RL_ComplimentaryDetail": {
    "ar": "تفاصيل إضافية",
    "ca": "Info Complementaria",
    "comment": "Complimentary Details",
    "cs": "Údaje o bezpl. trans.",
    "da": "Yderligere detaljer",
    "de": "Ergänzende Details",
    "el": "Πρόσθετες λεπτομέρειες",
    "en": "Complimentary Details",
    "en-GB": "Complimentary Details",
    "en-XA": "[Çöɱþļîɱéñţåŕý Ðéţåîļš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Detalles del artículo de cortesía",
    "es-AR": "Detalles del artículo de cortesía",
    "es-ES": "Info Complementaria",
    "es-MX": "Detalles del artículo de cortesía",
    "fr": "Détails gratuité",
    "hu": "Complimentary Details",
    "id": "Detail Gratis",
    "it": "Dettagli omaggio",
    "ja": "商品提供詳細",
    "key": "RL_ComplimentaryDetail",
    "ko": "무료 품목 상세 정보",
    "nb": "Detaljer om gratisvarer",
    "nl": "Aanvullende Details",
    "pl": "Pochwalne Szczegóły",
    "pt-BR": "Detalhes complementares",
    "pt-PT": "Detalhes Complementares",
    "ru": "Сведения о льготах",
    "sv": "Uppgifter gratiskupong",
    "th": "รายละเอียดของสมนาคุณ",
    "tr": "Ücretsiz Detaylar",
    "zh-Hans": "赠品详情",
    "zh-Hant": "贈品詳細資料"
  },
  "RL_ConsumerName": {
    "ar": "اسم العميل",
    "ca": "Consumidor",
    "comment": "Name of the consumer in receipt line",
    "cs": "Jméno zák",
    "da": "Forbrugerens navn",
    "de": "Consumer Name",
    "el": "Όνομα πελάτη",
    "en": "Consumer Name",
    "en-GB": "Consumer Name",
    "en-XA": "[Çöñšûɱéŕ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del consumidor",
    "es-AR": "Nombre del consumidor",
    "es-ES": "Consumidor",
    "es-MX": "Nombre del consumidor",
    "fr": "Nom du client",
    "hu": "Consumer Name",
    "id": "Nama Pelanggan",
    "it": "Nome Cliente",
    "ja": "お客様名",
    "key": "RL_ConsumerName",
    "ko": "고객 이름",
    "nb": "Kundenavn",
    "nl": "Naam klant",
    "pl": "Imię i nazwisko klienta",
    "pt-BR": "Nome do consumidor",
    "pt-PT": "Nome do cliente",
    "ru": "Имя покупателя",
    "sv": "Kundnamn",
    "th": "ชื่อลูกค้า",
    "tr": "Tüketici Adı",
    "zh-Hans": "消费者姓名",
    "zh-Hant": "消費者姓名"
  },
  "RL_Contact": {
    "ar": "الاتصال",
    "ca": "Contacto",
    "comment": "Contact details block  for the shipping  in receipt line",
    "cs": "Contact",
    "da": "Kontakt",
    "de": "Kontakt",
    "el": "Επικοινωνία",
    "en": "Contact",
    "en-GB": "Contact",
    "en-XA": "[Çöñţåçţ 耐克รื่بُو]",
    "es-419": "Contacto",
    "es-AR": "Contacto",
    "es-ES": "Contacto",
    "es-MX": "Contacto",
    "fr": "Contact",
    "hu": "Contact",
    "id": "Kontak",
    "it": "Contatto",
    "ja": "お問い合わせ",
    "key": "RL_Contact",
    "ko": "연락처",
    "nb": "Kontakt",
    "nl": "Contact",
    "pl": "Kontakt",
    "pt-BR": "Contato",
    "pt-PT": "Contacto",
    "ru": "Контактная информация",
    "sv": "Kontakt",
    "th": "ติดต่อ",
    "tr": "İletişim",
    "zh-Hans": "联系方式",
    "zh-Hant": "聯絡資訊"
  },
  "RL_ContactEmailID": {
    "ar": "\nالبريد الإلكتروني لجهة الاتصال",
    "ca": "ID del correu electrònic de contacte",
    "comment": "Email Id of the point of Contact added to a transaction. ",
    "cs": "\nKontaktní EmailID",
    "da": "E-mail-id for kontakt",
    "de": "\nE-Mail-ID des Kontakts",
    "el": "\nΑναγνωριστικό email επαφής",
    "en": "\nContact EmailID",
    "en-GB": "\nContact Email ID",
    "en-XA": "[\nÇöñţåçţ ÉɱåîļÎÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Identificación del correo electrónico de contacto",
    "es-AR": "\nID de correo electróncio del contacto",
    "es-ES": "ID de correo electrónico del contacto",
    "es-MX": "\nCorreo electrónico de contacto",
    "fr": "\nID e-mail du contact",
    "hu": "Kapcsolattartó e-mail-azonosítója",
    "id": "\nEmailID Kontak",
    "it": "\nID e-mail contatto",
    "ja": "\n連絡用EmailID",
    "key": "RL_ContactEmailID",
    "ko": "\n문의 이메일 ID",
    "nb": "\nKontakt EmailID",
    "nl": "E-mailadres ID voor contact",
    "pl": "\nIdentyfikator e-mail osoby do kontaktu",
    "pt-BR": "\nID de e-mail do contato",
    "pt-PT": "\nID de e-mail do ponto de contacto",
    "ru": "\nID эл. почты контакта",
    "sv": "\nKontaktens e-post-id",
    "th": "\nID อีเมลติดต่อ",
    "tr": "\nİletişim Kişisi E-posta Kimliği",
    "zh-Hans": "\n联系人电子邮件帐号",
    "zh-Hant": "\n聯絡人電子郵件 ID"
  },
  "RL_Country": {
    "ar": "البلد/المنطقة",
    "ca": "País/regió",
    "comment": "Country where a store is located. ",
    "cs": "Země/oblast",
    "da": "Land",
    "de": "Land/Region",
    "el": "Χώρα",
    "en": "Country",
    "en-GB": "Country",
    "en-XA": "[Çöûñţŕý 耐克รื่بُو]",
    "es-419": "País",
    "es-AR": "País/Región",
    "es-ES": "País/Región",
    "es-MX": "País o región",
    "fr": "Pays/Région",
    "hu": "Ország/régió",
    "id": "Negara/Wilayah",
    "it": "Paese/regione",
    "ja": "国",
    "key": "RL_Country",
    "ko": "국가",
    "nb": "Land",
    "nl": "Land/regio",
    "pl": "Kraj/region",
    "pt-BR": "País/região",
    "pt-PT": "País",
    "ru": "Страна",
    "sv": "Land/region",
    "th": "ประเทศ",
    "tr": "Ülke",
    "zh-Hans": "国家/地区",
    "zh-Hant": "國家/地區"
  },
  "RL_CouponNumber": {
    "ar": "رقم القسيمة",
    "ca": "Número de Vale",
    "comment": "Coupon number",
    "cs": "Císlo kupónu",
    "da": "Kuponnr",
    "de": "Coupon Number",
    "el": "Αριθμός κουπονιού",
    "en": "Coupon Number",
    "en-GB": "Coupon Number",
    "en-XA": "[Çöûþöñ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de cupón",
    "es-AR": "Número de cupón",
    "es-ES": "Número de Vale  ",
    "es-MX": "Número de cupón",
    "fr": "No bon de réduc.",
    "hu": "Coupon Number",
    "id": "Nomor Kupon",
    "it": "Numero coupon",
    "ja": "クーポン番号",
    "key": "RL_CouponNumber",
    "ko": "쿠폰 번호",
    "nb": "Kupongnummer",
    "nl": "Couponnummer",
    "pl": "Numer kuponu",
    "pt-BR": "Número do cupom",
    "pt-PT": "Número do cupão",
    "ru": "Номер купона",
    "sv": "Kupongnummer",
    "th": "หมายเลขคูปอง",
    "tr": "Kupon Numarası",
    "zh-Hans": "优惠券编号",
    "zh-Hant": "折價券編號"
  },
  "RL_Credit": {
    "ar": "رصيد",
    "ca": "Crèdit",
    "comment": "Credit",
    "cs": "Zůstatek",
    "da": "Kredit",
    "de": "Gutschrift",
    "el": "Πίστωση",
    "en": "Credit",
    "en-GB": "Credit",
    "en-XA": "[Çŕéðîţ 耐克รื่بُو]",
    "es-419": "Crédito",
    "es-AR": "Crédito",
    "es-ES": "Crédito",
    "es-MX": "Crédito",
    "fr": "Carte de crédit",
    "hu": "Jóváírás",
    "id": "Kredit",
    "it": "Carta di credito",
    "ja": "クレジット",
    "key": "RL_Credit",
    "ko": "크레딧",
    "nb": "Kreditt",
    "nl": "Tegoed",
    "pl": "Karta kredytowa",
    "pt-BR": "Crédito",
    "pt-PT": "Crédito",
    "ru": "Кредит",
    "sv": "Kreditkort",
    "th": "เครดิต",
    "tr": "Kredi",
    "zh-Hans": "信用卡",
    "zh-Hant": "信用卡"
  },
  "RL_CreditDebit": {
    "ar": "بطاقة الائتمان/الخصم",
    "ca": "Targeta de crèdit o de dèbit",
    "comment": "Generic name for a Credit/Debit card",
    "cs": "Kreditní/debetní karta",
    "da": "Kreditkort/betalingskort",
    "de": "Kredit-/EC-Karte",
    "el": "Πιστωτική/χρεωστική κάρτα",
    "en": "Credit/Debit Card",
    "en-GB": "Credit/Debit Card",
    "en-XA": "[Çŕéðîţ⁄Ðéƀîţ Çåŕð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tarjeta de crédito o débito",
    "es-AR": "Tarjeta de crédito o débito",
    "es-ES": "Tarjeta de crédito o débito",
    "es-MX": "Tarjeta de débito/crédito",
    "fr": "Carte de paiement",
    "hu": "Hitelkártya/bankkártya",
    "id": "Kartu Kredit/Debit",
    "it": "Carta di credito o debito",
    "ja": "クレジットカードまたはデビットカード",
    "key": "RL_CreditDebit",
    "ko": "신용/직불 카드",
    "nb": "Kreditt-/debetkort",
    "nl": "Creditcard/bankpas",
    "pl": "Karta kredytowa/debetowa",
    "pt-BR": "Cartão de crédito/débito",
    "pt-PT": "Cartão de débito/crédito",
    "ru": "Кредитная/дебетовая карта",
    "sv": "Kredit-/kontokort",
    "th": "บัตรเครดิต/เดบิต",
    "tr": "Kredi/Banka Kartı",
    "zh-Hans": "信用卡/借记卡",
    "zh-Hant": "信用卡/簽帳金融卡"
  },
  "RL_CurrSymbol": {
    "ar": "دولار أمريكي",
    "ca": "$",
    "comment": "Currency Symbol for the country",
    "cs": "USD",
    "da": "USD",
    "de": "$",
    "el": "$",
    "en": "$",
    "en-GB": "$",
    "en-XA": "[€ 耐克รื่بُو]",
    "es-419": "$",
    "es-AR": "$",
    "es-ES": "$",
    "es-MX": "$",
    "fr": "$",
    "hu": "HUF",
    "id": "$",
    "it": "$",
    "ja": "円",
    "key": "RL_CurrSymbol",
    "ko": "$",
    "nb": "$",
    "nl": "€ ",
    "pl": "USD",
    "pt-BR": "$",
    "pt-PT": "$",
    "ru": "$",
    "sv": "$",
    "th": "$",
    "tr": "$",
    "zh-Hans": "¥",
    "zh-Hant": "$"
  },
  "RL_CustIsAttachTran": {
    "ar": "العميل مرتبط بالمعاملة",
    "ca": "CLIENTE VINCULADO A TRANSACCIÓN",
    "comment": "CUSTOMER IS ATTACHED TO TRANSACTION",
    "cs": "ZÁKAZNÍK JE PŘIŘAZEN K TRANSAKCI",
    "da": "KUNDE ER VEDHÆFTET TRANSAKTION",
    "de": "DER KUNDE IST DER TRANSAKTION ZUGEORDNET",
    "el": "Ο ΠΕΛΑΤΗΣ ΠΡΟΣΑΡΤΗΘΗΚΕ ΣΕ ΣΥΝΑΛΛΑΓΗ",
    "en": "CUSTOMER IS ATTACHED TO TRANSACTION",
    "en-GB": "CUSTOMER IS ATTACHED TO TRANSACTION",
    "en-XA": "[ÇÛŠŢÖṀÉŔ ÎŠ ÅŢŢÅÇĤÉÐ ŢÖ ŢŔÅÑŠÅÇŢÎÖÑ Пβシ耐克บั้ รื่بُو]",
    "es-419": "EL CLIENTE ESTÁ ADJUNTO A LA TRANSACCIÓN",
    "es-AR": "EL CLIENTE ESTÁ ADJUNTO A LA TRANSACCIÓN",
    "es-ES": "CLIENTE VINCULADO A TRANSACCIÓN",
    "es-MX": "EL CLIENTE ESTÁ VINCULADO A LA TRANSACCIÓN",
    "fr": "CUSTOMER IS ATTACHED TO TRANSACTION",
    "hu": "CUSTOMER IS ATTACHED TO TRANSACTION",
    "id": "PELANGGAN DILAMPIRKAN PADA TANDA TERIMA",
    "it": "CLIENTE È COLLEGATO ALLA TRANSAZIONE",
    "ja": "お客様は取引に対して関連付けられています",
    "key": "RL_CustIsAttachTran",
    "ko": "고객이 해당 거래에 입력됨",
    "nb": "KUNDE ER TILKNYTTET TRANSASKJON",
    "nl": "CONSUMENT IS GEKOPPELD AAN TRANSACTIE",
    "pl": "KLIENT JEST POŁĄCZONY Z TRANSAKCJĄ",
    "pt-BR": "O CLIENTE ESTÁ VINCULADO À TRANSAÇÃO",
    "pt-PT": "CLIENTE ESTÁ ANEXADO À TRANSAÇÃO",
    "ru": "ПОКУПАТЕЛЬ ПРИКРЕПЛЕН К ОПЕРАЦИИ",
    "sv": "KUND ÄR KNUTEN TILL TRANSAKTION",
    "th": "แนบข้อมูลลูกค้าไปที่ธุรกรรม",
    "tr": "MÜŞTERİ İŞLEME EKLENDİ",
    "zh-Hans": "顾客与交易关联",
    "zh-Hant": "客戶已附加於交易"
  },
  "RL_CustomerAddress": {
    "ar": "عنوان العميل",
    "ca": "Adreça del client/a",
    "comment": "Address of the customer",
    "cs": "Adresa zákazníka",
    "da": "Kundes adresse",
    "de": "Adresse Kund:in",
    "el": "Διεύθυνση πελάτη",
    "en": "Customer Address",
    "en-GB": "Customer address",
    "en-XA": "[Çûšţöɱéŕ Åððŕéšš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Dirección del cliente",
    "es-AR": "Dirección del cliente",
    "es-ES": "Dirección del cliente",
    "es-MX": "Dirección del cliente",
    "fr": "Adresse du client ou de la cliente",
    "hu": "Vásárló címe",
    "id": "Alamat Pelanggan",
    "it": "Indirizzo cliente",
    "ja": "カスタマーの住所",
    "key": "RL_CustomerAddress",
    "ko": "고객 주소",
    "nb": "Kundens adresse",
    "nl": "Adres van klant",
    "pl": "Adres klienta/klientki",
    "pt-BR": "Endereço do cliente",
    "pt-PT": "Morada do cliente",
    "ru": "Адрес клиента",
    "sv": "Kundens adress",
    "th": "ที่อยู่ลูกค้า",
    "tr": "Tüketicinin Adresi",
    "zh-Hans": "客户地址",
    "zh-Hant": "顧客地址"
  },
  "RL_CustomerChangeRequest": {
    "ar": "طلب تغيير العميل",
    "ca": "Sol·licitudCanviClient",
    "comment": "CustomerChangeRequest",
    "cs": "CustomerChangeRequest",
    "da": "CustomerChangeRequest",
    "de": "CustomerChangeRequest",
    "el": "Αίτημα αλλαγής πελάτη",
    "en": "CustomerChangeRequest",
    "en-GB": "CustomerChangeRequest",
    "en-XA": "[ÇûšţöɱéŕÇĥåñĝéŔéǫûéšţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Solicitud de cambio del cliente",
    "es-AR": "SolicitudCambioCliente",
    "es-ES": "Solicitudcambiocliente",
    "es-MX": "Cambio a solicitud del Consumidor",
    "fr": "DemandeModificationClient",
    "hu": "CustomerChangeRequest",
    "id": "PermintaanPerubahanPelanggan",
    "it": "CustomerChangeRequest",
    "ja": "カスタマー変更リクエスト",
    "key": "RL_CustomerChangeRequest",
    "ko": "고객 변경 요청",
    "nb": "CustomerChangeRequest",
    "nl": "Aanvraag klantwissel",
    "pl": "WniosekKlientaOZmianę",
    "pt-BR": "CustomerChangeRequest",
    "pt-PT": "PedidoDeAlteraçãoDoCliente",
    "ru": "CustomerChangeRequest",
    "sv": "CustomerChangeRequest",
    "th": "คำขอเปลี่ยนแปลงลูกค้า",
    "tr": "Müşteri Değişim Talebi",
    "zh-Hans": "CustomerChangeRequest",
    "zh-Hant": "CustomerChangeRequest"
  },
  "RL_CustomerFirstName": {
    "ar": "الاسم الأول للعميل",
    "ca": "Nom del client/a",
    "comment": "First Name of the Customer ",
    "cs": "Křestní jméno zákazníka",
    "da": "Kundes fornavn",
    "de": "Vorname Kund:in",
    "el": "Όνομα πελάτη",
    "en": "Customer First Name",
    "en-GB": "Customer first name",
    "en-XA": "[Çûšţöɱéŕ Ƒîŕšţ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del cliente",
    "es-AR": "Nombre del cliente",
    "es-ES": "Nombre del cliente",
    "es-MX": "Nombre del cliente",
    "fr": "Prénom du client ou de la cliente",
    "hu": "Vásárló keresztneve",
    "id": "Nama Depan Pelanggan",
    "it": "Nome cliente",
    "ja": "カスタマーの名",
    "key": "RL_CustomerFirstName",
    "ko": "고객 이름",
    "nb": "Kundens fornavn",
    "nl": "Voornaam van klant",
    "pl": "Imię klienta/klientki",
    "pt-BR": "Nome do cliente",
    "pt-PT": "Nome próprio do cliente",
    "ru": "Имя клиента",
    "sv": "Kundens förnamn",
    "th": "ชื่อลูกค้า",
    "tr": "Tüketicinin Adı",
    "zh-Hans": "客户名字",
    "zh-Hant": "顧客名字"
  },
  "RL_CustomerLastName": {
    "ar": "اسم عائلة العميل ",
    "ca": "Cognoms del client/a ",
    "comment": "Last name of the customer. ",
    "cs": "Příjmení zákazníka ",
    "da": "Kundes efternavn ",
    "de": "Nachname Kund:in ",
    "el": "Επώνυμο πελάτη ",
    "en": "Customer Last Name ",
    "en-GB": "Customer surname ",
    "en-XA": "[Çûšţöɱéŕ Ļåšţ Ñåɱé  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Apellido del cliente ",
    "es-AR": "Apellido del cliente ",
    "es-ES": "Apellidos del cliente ",
    "es-MX": "Apellido del cliente ",
    "fr": "Nom du client ou de la cliente ",
    "hu": "Vásárló vezetékneve ",
    "id": "Nama Belakang Pelanggan ",
    "it": "Cognome cliente ",
    "ja": "カスタマーの姓 ",
    "key": "RL_CustomerLastName",
    "ko": "고객 성 ",
    "nb": "Kundens etternavn ",
    "nl": "Achternaam van klant ",
    "pl": "Nazwisko klienta/klientki ",
    "pt-BR": "Sobrenome do cliente ",
    "pt-PT": "Apelido do cliente ",
    "ru": "Фамилия клиента ",
    "sv": "Kundens efternamn ",
    "th": "นามสกุลลูกค้า ",
    "tr": "Müşterinin Soyadı ",
    "zh-Hans": "客户姓氏 ",
    "zh-Hant": "顧客姓氏 "
  },
  "RL_DEFECTIVE": {
    "ar": "فيه خلل",
    "ca": "DEFECTUOSO",
    "comment": "Reason code indicates the DEFECTIVE return",
    "cs": "DEFEKTY",
    "da": "DEFEKTE",
    "de": "FEHLERHAFT",
    "el": "ΕΛΑΤΤΩΜΑΤΙΚΟ",
    "en": "DEFECTIVE",
    "en-GB": "DEFECTIVE",
    "en-XA": "[ÐÉƑÉÇŢÎṼÉ 耐克รื่بُو]",
    "es-419": "DEFECTUOSO",
    "es-AR": "DEFECTUOSO",
    "es-ES": "DEFECTUOSO",
    "es-MX": "DEFECTUOSO",
    "fr": "MARCHANDISE DÉFECTUEUSE",
    "hu": "DEFECTIVE",
    "id": "CACAT",
    "it": "DIFETTOSO",
    "ja": "DEFECTIVE",
    "key": "RL_DEFECTIVE",
    "ko": "결함",
    "nb": "ØDELAGT",
    "nl": "DEFECT",
    "pl": "WADLIWY",
    "pt-BR": "COM DEFEITO",
    "pt-PT": "DEFEITUOSO",
    "ru": "DEFECTIVE",
    "sv": "DEFEKT",
    "th": "สินค้ามีตำหนิ",
    "tr": "KUSURLU",
    "zh-Hans": "瑕疵商品",
    "zh-Hant": "瑕疵"
  },
  "RL_DUPLICATERECEIPT": {
    "ar": "إيصال مكرّر",
    "ca": "Duplicado de Recibo",
    "comment": "DUPLICATE RECEIPT",
    "cs": "DUPLIKÁT ÚCTENKY",
    "da": "BON I TO EKSEMPLARER",
    "de": "DUPLIKAT BELEG",
    "el": "ΔΗΜΙΟΥΡΓΙΑ ΑΝΤΙΓΡΑΦΟΥ ΑΠΟΔΕΙΞΗΣ",
    "en": "DUPLICATE RECEIPT",
    "en-GB": "DUPLICATE RECEIPT",
    "en-XA": "[ÐÛÞĻÎÇÅŢÉ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO DUPLICADO",
    "es-AR": "RECIBO DUPLICADO",
    "es-ES": "Duplicado de Recibo",
    "es-MX": "RECIBO DUPLICADO",
    "fr": "DUPLICATE RECEIPT",
    "hu": "DUPLICATE RECEIPT",
    "id": "TANDA TERIMA DUPLIKAT",
    "it": "DUPLICA RICEVUTA",
    "ja": "再印刷",
    "key": "RL_DUPLICATERECEIPT",
    "ko": "영수증 복사",
    "nb": "DUPLIKATKVITTERING",
    "nl": "KOPIE BON",
    "pl": "Duplikat paragonu",
    "pt-BR": "RECIBO DUPLICADO",
    "pt-PT": "DUPLICADO DO RECIBO",
    "ru": "ДУБЛИКАТ ЧЕКА",
    "sv": "DUPLICERA KVITTO",
    "th": "ใบเสร็จซ้ำซ้อน",
    "tr": "YENİDEN YAZDIR",
    "zh-Hans": "收据重复",
    "zh-Hant": "收據副本"
  },
  "RL_Date": {
    "ar": "التاريخ",
    "ca": "Fecha",
    "comment": "Date of transaction",
    "cs": "Datum",
    "da": "Dato",
    "de": "Datum",
    "el": "Ημερομηνία",
    "en": "Date",
    "en-GB": "Date",
    "en-XA": "[Ðåţé 耐克รื่بُو]",
    "es-419": "Fecha",
    "es-AR": "Fecha",
    "es-ES": "Fecha",
    "es-MX": "esperada",
    "fr": "Date",
    "hu": "Date",
    "id": "Tanggal",
    "it": "Data",
    "ja": "日付",
    "key": "RL_Date",
    "ko": "날짜",
    "nb": "Dato",
    "nl": "Datum",
    "pl": "Data",
    "pt-BR": "Data",
    "pt-PT": "Data",
    "ru": "Дата",
    "sv": "Datum",
    "th": "วันที่",
    "tr": "Tarih",
    "zh-Hans": "日期",
    "zh-Hant": "交易日期"
  },
  "RL_DateEJ": {
    "ar": "التاريخ",
    "ca": "Fecha",
    "comment": "Current system date",
    "cs": "Datum",
    "da": "Dato",
    "de": "Datum",
    "el": "Ημερομηνία",
    "en": "Date",
    "en-GB": "Date",
    "en-XA": "[Ðåţé 耐克รื่بُو]",
    "es-419": "Fecha",
    "es-AR": "Fecha",
    "es-ES": "Fecha",
    "es-MX": "Fecha",
    "fr": "Date",
    "hu": "Date",
    "id": "Tanggal",
    "it": "Data",
    "ja": "日付",
    "key": "RL_DateEJ",
    "ko": "날짜",
    "nb": "Dato",
    "nl": "Datum",
    "pl": "Data",
    "pt-BR": "Data",
    "pt-PT": "Data",
    "ru": "Дата",
    "sv": "Datum",
    "th": "วันที่",
    "tr": "Tarih",
    "zh-Hans": "日期",
    "zh-Hant": "日期"
  },
  "RL_DateRange": {
    "ar": "نطاق التاريخ",
    "ca": "Interval de dates",
    "comment": "Date Range - from date and to date",
    "cs": "Rozsah dat",
    "da": "Datointerval",
    "de": "Datumsbereich",
    "el": "Εύρος ημερομηνιών",
    "en": "Date Range",
    "en-GB": "Date Range",
    "en-XA": "[Ðåţé Ŕåñĝé å耐克รื่بُو]",
    "es-419": "Intervalo de fechas",
    "es-AR": "Rango de fechas",
    "es-ES": "Rango de fechas",
    "es-MX": "Rango de fechas",
    "fr": "Période",
    "hu": "Dátumtartomány",
    "id": "Rentang Tanggal",
    "it": "Intervallo di date",
    "ja": "期間",
    "key": "RL_DateRange",
    "ko": "날짜 범위",
    "nb": "Datointervall",
    "nl": "Datumbereik",
    "pl": "Zakres dat",
    "pt-BR": "Período",
    "pt-PT": "Intervalo de datas",
    "ru": "Диапазон дат",
    "sv": "Datumintervall",
    "th": "ช่วงวันที่",
    "tr": "Tarih Aralığı",
    "zh-Hans": "日期范围",
    "zh-Hant": "日期範圍"
  },
  "RL_Debit": {
    "ar": "خصم",
    "ca": "Dèbit",
    "comment": "Debit",
    "cs": "Debetní karta",
    "da": "Debet",
    "de": "Lastschrift",
    "el": "Χρέωση",
    "en": "Debit",
    "en-GB": "Debit",
    "en-XA": "[Ðéƀîţ 耐克รื่بُو]",
    "es-419": "Débito",
    "es-AR": "Débito",
    "es-ES": "Débito",
    "es-MX": "Débito",
    "fr": "Carte de débit",
    "hu": "Terhelés",
    "id": "Debit",
    "it": "Carta di debito",
    "ja": "デビット",
    "key": "RL_Debit",
    "ko": "직불",
    "nb": "Debet",
    "nl": "Bank",
    "pl": "Karta debetowa",
    "pt-BR": "Débito",
    "pt-PT": "Débito",
    "ru": "Дебет",
    "sv": "Betalkort",
    "th": "เดบิต",
    "tr": "Banka kartı",
    "zh-Hans": "借记卡",
    "zh-Hant": "簽帳金融卡"
  },
  "RL_DefectiveReceiptStoreCopy": {
    "ar": "نسخة الإيصال التابعة للمتجر فيها خلل",
    "ca": "Copia tienda recibo defect",
    "comment": "header of the defective return receipt to indicates the store copy of the defective return",
    "cs": "Kopie dokladu o defektech",
    "da": "Defekt kvittering butik kopi",
    "de": "Mangelhafter Beleg Kopie des Shops",
    "el": "Αντίγραφο καταστήματος απόδειξης ελαττωματικού προϊόντος",
    "en": "Defective Receipt Store Copy",
    "en-GB": "Defective Receipt Store Copy",
    "en-XA": "[Ðéƒéçţîṽé Ŕéçéîþţ Šţöŕé Çöþý åƀçðПβシ耐克บั้ รื่بُو]",
    "es-419": "Copia de recibo defectuoso de tienda",
    "es-AR": "Copia de recibo defectuoso de tienda",
    "es-ES": "Copia tienda recibo defect",
    "es-MX": "Copia de recibo defectuoso de tienda",
    "fr": "Exemplaire du magasin du reçu de marchandise défectueuse",
    "hu": "Defective Receipt Store Copy",
    "id": "Salinan Toko Tanda Terima Cacat",
    "it": "Copia ricevuta articolo difettoso punto vendita",
    "ja": "Defective Receipt Store Copy",
    "key": "RL_DefectiveReceiptStoreCopy",
    "ko": "결함 반품 영수증 매장용 사본",
    "nb": "Kvittering for ødelagt retur butikkeksemplar",
    "nl": "Exemplaar bon defect artikel voor store",
    "pl": "Uszkodzona sklepowa kopia zapasowa",
    "pt-BR": "Cópia do recibo da loja com defeito",
    "pt-PT": "Cópia Store Recibo Defeituoso",
    "ru": "Defective Receipt Store Copy",
    "sv": "Defekt kvitto, Storeexemplar",
    "th": "สำเนาร้านสำหรับใบเสร็จสินค้ามีตำหนิ",
    "tr": "Kusurlu Makbuzu Mağaza Kopyası",
    "zh-Hans": "瑕疵商品收据商店副本",
    "zh-Hant": "瑕疵收據商店聯"
  },
  "RL_Delivered": {
    "ar": "تم التسليم",
    "ca": "Lliurades",
    "comment": "Transactions that are completed/Delivered",
    "cs": "Doručená",
    "da": "Leveret",
    "de": "Zugestellt",
    "el": "Παραδομένες",
    "en": "Delivered",
    "en-GB": "Delivered",
    "en-XA": "[Ðéļîṽéŕéð 耐克รื่بُو]",
    "es-419": "Entregada",
    "es-AR": "Entregado",
    "es-ES": "Entregado",
    "es-MX": "Entregado",
    "fr": "Livraison effectuée",
    "hu": "Teljesített",
    "id": "Terkirim",
    "it": "Completate",
    "ja": "配送済み",
    "key": "RL_Delivered",
    "ko": "배송됨",
    "nb": "Levert",
    "nl": "Afgeleverd",
    "pl": "Dostarczone",
    "pt-BR": "Entregue",
    "pt-PT": "Concluída",
    "ru": "Доставлено",
    "sv": "Levererad",
    "th": "ส่งมอบแล้ว",
    "tr": "Teslim Edildi",
    "zh-Hans": "已配送",
    "zh-Hant": "已出貨"
  },
  "RL_Department1": {
    "ar": "القسم:",
    "ca": "Departamento:",
    "comment": "Department:",
    "cs": "Oddělení:",
    "da": "Afdeling:",
    "de": "Department:",
    "el": "Τμήμα:",
    "en": "Department:",
    "en-GB": "Department:",
    "en-XA": "[Ðéþåŕţɱéñţ∶ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Departamento:",
    "es-AR": "Departamento:",
    "es-ES": "Departamento:",
    "es-MX": "Departamento:",
    "fr": "Rayon :",
    "hu": "Department:",
    "id": "Departemen:",
    "it": "Reparto:",
    "ja": "Department:",
    "key": "RL_Department1",
    "ko": "부서:",
    "nb": "Avdeling:",
    "nl": "Afdeling:",
    "pl": "Dział:",
    "pt-BR": "Departamento:",
    "pt-PT": "Secção:",
    "ru": "Отдел:",
    "sv": "Avdelning:",
    "th": "ฝ่าย",
    "tr": "Bölüm:",
    "zh-Hans": "部门:",
    "zh-Hant": "部門:"
  },
  "RL_DeviceID": {
    "ar": "معرّف الجهاز",
    "ca": "Identificador del dispositiu",
    "comment": "Unique ID of the Clean Cash device ( fiscal device) from which the digital signatures are issued for the transaction.",
    "cs": "ID zařízení",
    "da": "Enheds-id",
    "de": "Geräte-ID",
    "el": "Αναγνωριστικό συσκευής",
    "en": "Device ID",
    "en-GB": "Device ID",
    "en-XA": "[Ðéṽîçé ÎÐ 耐克รื่بُو]",
    "es-419": "ID del dispositivo",
    "es-AR": "ID del dispositivo",
    "es-ES": "ID del dispositivo",
    "es-MX": "ID del dispositivo",
    "fr": "ID de l'appareil",
    "hu": "Eszközazonosító",
    "id": "ID Perangkat",
    "it": "ID dispositivo",
    "ja": "デバイスID",
    "key": "RL_DeviceID",
    "ko": "기기 ID",
    "nb": "Enhets-ID",
    "nl": "Device-ID",
    "pl": "Identyfikator urządzenia",
    "pt-BR": "ID do dispositivo",
    "pt-PT": "ID do dispositivo",
    "ru": "ID устройства",
    "sv": "Enhets-id",
    "th": "ID อุปกรณ์",
    "tr": "Cihaz Kimliği",
    "zh-Hans": "设备 ID",
    "zh-Hant": "裝置 ID"
  },
  "RL_Difference": {
    "ar": "الفَرق",
    "ca": "Diferencia",
    "comment": "Difference  after a price override",
    "cs": "Rozdí",
    "da": "Difference",
    "de": "Differenz",
    "el": "Διαφορά",
    "en": "Difference",
    "en-GB": "Difference",
    "en-XA": "[Ðîƒƒéŕéñçé å耐克รื่بُو]",
    "es-419": "Diferencia",
    "es-AR": "Diferencia",
    "es-ES": "Diferencia",
    "es-MX": "Diferencia",
    "fr": "Difference",
    "hu": "Difference",
    "id": "Selisih",
    "it": "Differenza",
    "ja": "差異",
    "key": "RL_Difference",
    "ko": "차액",
    "nb": "Forskjell",
    "nl": "Verschil",
    "pl": "Różnica",
    "pt-BR": "Diferença",
    "pt-PT": "Diferenca",
    "ru": "Разница",
    "sv": "Skillnad",
    "th": "ความแตกต่าง",
    "tr": "Fark",
    "zh-Hans": "差额",
    "zh-Hant": "差異"
  },
  "RL_DigitalOrderNumber": {
    "ar": "رقم الطلبية الرقميّة ",
    "ca": "Número de la comanda digital",
    "comment": "Digital Order Number is the unique identifier of a transaction printed on the receipt. ",
    "cs": "Číslo elektronické objednávky",
    "da": "Digitalt ordrenummer",
    "de": "Digitale Bestellnummer ",
    "el": "Αριθμός ψηφιακής παραγγελίας ",
    "en": "Digital Order Number ",
    "en-GB": "Digital Order Number",
    "en-XA": "[Ðîĝîţåļ Öŕðéŕ Ñûɱƀéŕ  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número del pedido digital",
    "es-AR": "Número de pedido digital",
    "es-ES": "Número de pedido digital",
    "es-MX": "Número de pedido digital ",
    "fr": "Numéro de commande numérique",
    "hu": "Digitális rendelésszám",
    "id": "Nomor Pesanan Digital ",
    "it": "Numero ordine digitale ",
    "ja": "デジタルのご注文番号",
    "key": "RL_DigitalOrderNumber",
    "ko": "주문번호",
    "nb": "Digitalt bestillingsnummer ",
    "nl": "Digitaal bestelnummer",
    "pl": "Numer zamówienia cyfrowego",
    "pt-BR": "Número do pedido digital",
    "pt-PT": "Número da encomenda digital",
    "ru": "Номер транзакции",
    "sv": "Digitalt beställningsnummer ",
    "th": "หมายเลขคำสั่งซื้อดิจิทัล",
    "tr": "Dijital Sipariş Numarası",
    "zh-Hans": "数字订单号 ",
    "zh-Hant": "數位訂單編號"
  },
  "RL_DigitalSignature": {
    "ar": "التوقيع الرقمي",
    "ca": "Signatura digital",
    "comment": "Digital Signature printed on the receipt for ensuring the authenticity and validity of a transaction.",
    "cs": "Elektronický podpis",
    "da": "Digital underskrift",
    "de": "Digitale Unterschrift",
    "el": "Ψηφιακή υπογραφή",
    "en": "Digital Signature",
    "en-GB": "Digital Signature",
    "en-XA": "[Ðîĝîţåļ Šîĝñåţûŕé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Firma digital",
    "es-AR": "Firma digital",
    "es-ES": "Firma digital",
    "es-MX": "Firma digital",
    "fr": "Signature numérique",
    "hu": "Digitális aláírás",
    "id": "Tanda Tangan Digital",
    "it": "Firma digitale",
    "ja": "デジタル署名",
    "key": "RL_DigitalSignature",
    "ko": "디지털 서명",
    "nb": "Digital signatur",
    "nl": "Digitale handtekening",
    "pl": "Podpis elektroniczny",
    "pt-BR": "Assinatura digital",
    "pt-PT": "Assinatura digital",
    "ru": "Цифровая подпись",
    "sv": "Digital signatur",
    "th": "ลายมือชื่อแบบดิจิทัล",
    "tr": "Dijital İmza",
    "zh-Hans": "数字签名",
    "zh-Hant": "數位簽名"
  },
  "RL_DiscountSummary": {
    "ar": "ملخّص الخصم",
    "ca": "Resum del descompte",
    "comment": "Discount summary",
    "cs": "Přehled slevy",
    "da": "Opsummering af rabat",
    "de": "Rabattzusammenfassung",
    "el": "Σύνοψη έκπτωσης",
    "en": "Summary of Discount",
    "en-GB": "Summary of Discount",
    "en-XA": "[Šûɱɱåŕý öƒ Ðîšçöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Resumen del descuento",
    "es-AR": "Resumen del descuento",
    "es-ES": "Resumen de los descuentos",
    "es-MX": "Resumen del descuento",
    "fr": "Récapitulatif de la réduction",
    "hu": "Kedvezmény összegzése",
    "id": "Ringkasan Diskon",
    "it": "Riepilogo dello sconto",
    "ja": "割引の概要",
    "key": "RL_DiscountSummary",
    "ko": "할인 요약",
    "nb": "Sammendrag av rabatt",
    "nl": "Overzicht korting",
    "pl": "Podsumowanie rabatów",
    "pt-BR": "Resumo de desconto",
    "pt-PT": "Resumo do desconto",
    "ru": "Обзор скидок",
    "sv": "Sammanfattning av rabatt",
    "th": "สรุปยอดส่วนลด",
    "tr": "İndirim Özeti",
    "zh-Hans": "折扣摘要",
    "zh-Hant": "折扣摘要"
  },
  "RL_DocumentName": {
    "ar": "اسم المستند",
    "ca": "Nom del document",
    "comment": "The name of the document for which a document number is printed. Example ReceiptNumber",
    "cs": "Číslo dokumentu",
    "da": "Dokumentnavn",
    "de": "Dokumentenname",
    "el": "Όνομα εγγράφου",
    "en": "Document Name",
    "en-GB": "Document Name",
    "en-XA": "[Ðöçûɱéñţ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del documento",
    "es-AR": "Nombre del documento",
    "es-ES": "Tipo de documento",
    "es-MX": "Nombre del documento",
    "fr": "Nom de document",
    "hu": "Dokumentum neve",
    "id": "Nama Dokumen",
    "it": "Nome documento",
    "ja": "ドキュメント名",
    "key": "RL_DocumentName",
    "ko": "문서 이름",
    "nb": "Dokumentnavn",
    "nl": "Documentnaam",
    "pl": "Nazwa dokumentu",
    "pt-BR": "Nome do documento",
    "pt-PT": "Nome do documento",
    "ru": "Название документа",
    "sv": "Dokumentnamn",
    "th": "ชื่อเอกสาร",
    "tr": "Belge Adı",
    "zh-Hans": "单据名称",
    "zh-Hant": "文件名稱"
  },
  "RL_DocumentNumber": {
    "ar": "رقم المستند:",
    "ca": "Núm. de document:",
    "comment": "A unique sequence number generated for every transaction/receipt in the store.",
    "cs": "Dokument č.",
    "da": "Dokument-nr.:",
    "de": "Dokumentennr.: ",
    "el": "Αρ. εγγράφου: ",
    "en": "Document No: ",
    "en-GB": "Document No.: ",
    "en-XA": "[Ðöçûɱéñţ Ñö∶  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número del documento:",
    "es-AR": "N.º de documento",
    "es-ES": "N.º de documento:",
    "es-MX": "N° de documento",
    "fr": "N° de document :",
    "hu": "Dokumentum száma:",
    "id": "No. Dokumen: ",
    "it": "Numero documento:",
    "ja": "ドキュメント番号：",
    "key": "RL_DocumentNumber",
    "ko": "문서 번호:",
    "nb": "Dokumentnr.: ",
    "nl": "Documentnr.",
    "pl": "Nr dokumentu:",
    "pt-BR": "Número do documento:",
    "pt-PT": "Documento n.º:",
    "ru": "Документ №",
    "sv": "Dokumentnr:",
    "th": "เลขเอกสาร",
    "tr": "Belge No:",
    "zh-Hans": "单据编号：",
    "zh-Hant": "文件編號："
  },
  "RL_DocumentType": {
    "ar": "النوع",
    "ca": "Tipus",
    "comment": "The type of document that is printed in the store",
    "cs": "Typ",
    "da": "Type",
    "de": "Typ",
    "el": "Τύπος",
    "en": "Type",
    "en-GB": "Type",
    "en-XA": "[Ţýþé 耐克รื่بُو]",
    "es-419": "Tipo",
    "es-AR": "Tipo",
    "es-ES": "Tipo",
    "es-MX": "Tipo",
    "fr": "Type",
    "hu": "Típus",
    "id": "Jenis",
    "it": "Tipo",
    "ja": "種類",
    "key": "RL_DocumentType",
    "ko": "유형",
    "nb": "Type",
    "nl": "Type",
    "pl": "Typ",
    "pt-BR": "Tipo",
    "pt-PT": "Tipo",
    "ru": "Тип",
    "sv": "Typ",
    "th": "ประเภท",
    "tr": "Tür",
    "zh-Hans": "类型",
    "zh-Hant": "類型"
  },
  "RL_EJExportSuccess": {
    "ar": "نجحت العملية! تم تنزيل أحدث ١٠٠ معاملة مطابقة لمعايير البحث. إذا لم تتمكن من العثور على معاملةٍ ما، فيرجى تضييق نطاق نتائج البحث باستخدام عوامل التصفية الخاصة بالبحث.",
    "ca": "Ho has aconseguit S'han descarregat les 100 transaccions més recents segons els criteris de cerca. Si no trobes alguna transacció, ajusta els filtres de cerca per definir més els resultats.",
    "comment": "Success Message when search results are exported from the Electronic Journal UI. ",
    "cs": "Podařilo se!! Stáhlo se posledních sto transakcí, které odpovídají kritériím hledání. Pokud se ti transakci nedaří najít, zkust zúžit výsledky vyhledávání pomocí filtrů.",
    "da": "Gennemført! Download de seneste 100 transaktioner, der matcher søgekriterierne. Hvis du ikke kan finde en transaktion, kan du indsnævre søgeresultaterne med søgefiltre.",
    "de": "Es hat funktioniert! Die letzten 100 Transaktionen, die den Suchkriterien entsprechen, wurden heruntergeladen. Wenn du eine Transaktion nicht finden kannst, verfeinere das Suchergebnis mit den Suchfiltern.",
    "el": "Επιτυχία! Έγινε λήψη των 100 πιο πρόσφατων συναλλαγών που ταιριάζουν με τα κριτήρια αναζήτησης. Αν δεν μπορείτε να βρείτε μια συναλλαγή, περιορίστε τα κριτήρια αναζήτησης χρησιμοποιώντας φίλτρα αναζήτησης.",
    "en": "Success! Downloaded the most recent 100 transactions matching the search criteria. If you are unable to find a transaction, please narrow down the search result using search filters.",
    "en-GB": "Success! Downloaded the most recent 100 transactions matching the search criteria. If you are unable to find a transaction, please narrow down the search result using search filters.",
    "en-XA": "[Šûççéšš¡ Ðöŵñļöåðéð ţĥé ɱöšţ ŕéçéñţ ①⓪⓪ ţŕåñšåçţîöñš ɱåţçĥîñĝ ţĥé šéåŕçĥ çŕîţéŕîå· Îƒ ýöû åŕé ûñåƀļé ţö ƒîñð å ţŕåñšåçţîöñ، þļéåšé ñåŕŕöŵ ðöŵñ ţĥé šéåŕçĥ ŕéšûļţ ûšîñĝ šéåŕçĥ ƒîļţéŕš· åƀçðéƒĝĥîĵķļɱñöþǫŕšţûṽŵẋýžÅƁÇÐÉƑĜĤÎĴĶĻṀПβシ耐克บั้ รื่بُو]",
    "es-419": "Listo. Se descargaron las 100 transacciones más recientes de acuerdo con los criterios de búsqueda. Si no puedes encontrar una transacción, reduce los resultados de la búsqueda con los filtros.",
    "es-AR": "Hecho Se descargaron las 100 transacciones más recientes según el criterio de búsqueda. Si no podés encontrar una transacción, reducí los resultados de la búsqueda con los filtros.",
    "es-ES": "Hecho Se han descargado las 100 transacciones más recientes según los criterios de búsqueda. Si no encuentras una transacción, ajusta más los filtros de búsqueda.",
    "es-MX": "Listo. Se descargaron las 100 transacciones más recientes según el criterio de búsqueda. Si no encuentras una transacción, reduce los resultados con los filtros de búsqueda.",
    "fr": "C'est un succès ! Les 100 transactions les plus récentes correspondant aux critères de recherche ont été téléchargées. Si vous ne parvenez pas à trouver une transaction, réduisez le nombre de résultats à l'aide des filtres de recherche.",
    "hu": "Siker! Letöltöttük a keresési feltételeknek megfelelő legutóbbi 100 tranzakciót. Ha nem találsz egy tranzakciót, szűkítsd le a keresési találatokat a keresési szűrők segítségével.",
    "id": "Berhasil! 100 transaksi terbaru yang cocok dengan kriteria pencarian telah diunduh. Jika transaksi tidak dapat ditemukan, persempit hasil pencarian menggunakan filter pencarian.",
    "it": "Operazione riuscita! Hai scaricato le 100 transazioni più recenti che soddisfano i criteri di ricerca. Se non riesci a trovare una transazione, restringi i risultati di ricerca usando i filtri.",
    "ja": "成功 検索条件に合った直近100件の取引をダウンロードしました。 取引が見つからない場合は、検索フィルターを使って検索結果を絞り込んでください。",
    "key": "RL_EJExportSuccess",
    "ko": "성공했습니다! 검색 기준에 일치하는 최근 100건의 거래를 다운로드했습니다. 거래를 찾을 수 없는 경우 검색 필터를 사용하여 검색 결과의 범위를 축소해 주세요.",
    "nb": "Vellykket! Den 100 nyeste transaksjonene som matcher søkekriteriene, er lastet ned. Hvis du ikke finner en bestemt transaksjon, må du snevre inn søkeresultatet ved hjelp av søkefiltrene.",
    "nl": "Gelukt! De 100 meest recente transacties die overeenkomen met de zoekcriteria zijn gedownload. Als je een transactie niet kunt vinden, beperk dan het zoekresultaat met de zoekfilters.",
    "pl": "Udało się! Pobrano 100 ostatnich transakcji pasujących do kryteriów wyszukiwania. Jeśli nie możesz znaleźć danej transakcji, zawęź wyniki wyszukiwania przy użyciu filtrów wyszukiwania.",
    "pt-BR": "Concluído! Faça o download das 100 transações mais recentes que correspondem aos critérios de pesquisa. Caso não consiga encontrar uma transação, limite o resultado da pesquisa usando os filtros de pesquisa.",
    "pt-PT": "Êxito! Foram transferidas as 100 transações mais recentes que correspondem aos critérios de pesquisa. Se não conseguir encontrar uma transação, limite o resultado da pesquisa utilizando filtros de pesquisa.",
    "ru": "Успешно! Загружены последние 100 транзакций, соответствующих критериям. Если не удается найти транзакцию, сузьте критерии поиска с помощью фильтров.",
    "sv": "Klart Laddade ned de senaste 100 transaktionerna som matchar sökkriterierna. Om du inte kan hitta en transaktion, begränsa sökresultatet med hjälp av sökfilter.",
    "th": "สำเร็จ ดาวน์โหลดการทำรายการที่ตรงตามเกณฑ์การค้นหา 100 รายการล่าสุด หากคุณไม่พบรายการที่ต้องการ โปรดกำหนดผลการค้นหาให้แคบลงโดยใช้ตัวกรองการค้นหา",
    "tr": "Başarılı! Arama kriterine uyan en güncel 100 işlem indirildi. İşlem bulamadıysan lütfen arama filtrelerini kullanarak arama sonucunu daralt. ",
    "zh-Hans": "成功！ 符合搜索条件的最近 100 笔交易已下载完毕。 如果无法找到交易，请使用搜索筛选条件缩小搜索结果的范围。",
    "zh-Hant": "成功！ 已下載符合搜尋條件的最近 100 筆交易。 如果你找不到交易，請使用篩選條件來縮小搜尋範圍。"
  },
  "RL_EJExportWarning": {
    "ar": "سيؤدي استخدام وظيفة التصدير هذه إلى تنزيل أحدث ١٠٠ معاملة فقط بناءً على تاريخ المعاملة. يرجى الضغط على \"متابعة\" لاستكمال العملية أو \"إلغاء\" لتحسين معايير البحث قبل التنزيل. \n",
    "ca": "Amb la funció d'exportació només es descarregaran les 100 transaccions més recents segons la data actual. Fes clic a Continua per seguir endavant o a Cancel·la per justar els criteris de cerca abans de descarregar les transaccions. \n",
    "comment": "Warning Message to be displayed when user clicks the Export button on the EJ UI. ",
    "cs": "Použití téhle exportní funkce jenom stáhne posledních sto transakcí podle dat transakcí. Klikni na Proceed (Pokračovat), jestli chceš pokračovat, nebo na Cancel (Zrušit), jestli chceš před stažením upravit kritéria hledání. \n",
    "da": "Hvis du bruger denne eksportfunktion, downloader du kun de seneste 100 transaktioner baseret på transaktionsdatoen. Klik på Fortsæt for at fortsætte eller Annuller for at justere dine søgekriterier, før du downloader. \n",
    "de": "Diese Exportfunktion lädt nur die letzten 100 Transaktionen (basierend auf dem Transaktionsdatum) herunter. Klicke auf \"Fortfahren\", um weiterzumachen, oder auf \"Abbrechen\", um deine Suchkriterien vor dem Download zu verfeinern. \n",
    "el": "Με αυτήν τη λειτουργία εξαγωγής γίνεται λήψη μόνο των 100 πιο πρόσφατων συναλλαγών με βάση την ημερομηνία συναλλαγής. Επιλέξτε Συνέχεια για να συνεχίσετε ή Ακύρωση για να περιορίσετε τα κριτήρια αναζήτησης πριν από τη διαδικασία λήψης. \n",
    "en": "Using this export function will only download the most recent 100 transactions based on the transaction date. Click on Proceed to continue or Cancel to refine your search criteria before downloading. \n",
    "en-GB": "Using this export function will only download the most recent 100 transactions based on the transaction date. Click on Proceed to continue or Cancel to refine your search criteria before downloading. \n",
    "en-XA": "[Ûšîñĝ ţĥîš éẋþöŕţ ƒûñçţîöñ ŵîļļ öñļý ðöŵñļöåð ţĥé ɱöšţ ŕéçéñţ ①⓪⓪ ţŕåñšåçţîöñš ƀåšéð öñ ţĥé ţŕåñšåçţîöñ ðåţé· Çļîçķ öñ Þŕöçééð ţö çöñţîñûé öŕ Çåñçéļ ţö ŕéƒîñé ýöûŕ šéåŕçĥ çŕîţéŕîå ƀéƒöŕé ðöŵñļöåðîñĝ· \n åƀçðéƒĝĥîĵķļɱñöþǫŕšţûṽŵẋýžÅƁÇÐÉƑĜĤÎĴĶĻṀÑÖÞǪŔŠПβシ耐克บั้ รื่بُو]",
    "es-419": "Al usar esta función de exportar, solo se descargarán las 100 transacciones más recientes según la fecha de transacción. Haz clic en Continuar para seguir o en Cancelar para ajustar tus criterios de búsqueda antes de descargar. \n",
    "es-AR": "Al usar esta función de exportar, solo se descargarán las 100 transacciones más recientes según la fecha de transacción. Hacé clic en Continuar o en Cancelar para ajustar los criterios de búsqueda antes de seguir con la descarga. \n",
    "es-ES": "Al utilizar esta función de exportación, solo se descargarán las 100 transacciones más recientes en la fecha actual. Haz clic en Continuar o en Cancelar para ajustar los criterios de búsqueda antes de seguir con la descarga. \n",
    "es-MX": "La función de exportar solo descarga las 100 transacciones más recientes según la fecha de transacción. Haz clic en Continuar para proceder o Cancelar para modificar el criterio de búsqueda antes de descargar. \n",
    "fr": "Avec cette fonction d'exportation, il est possible de télécharger uniquement les 100 transactions les plus récentes (selon la date de transaction). Cliquez sur Continuer pour poursuivre ou Annuler pour affiner vos critères de recherche avant le téléchargement. \n",
    "hu": "Ez az exportálási funkció csak a legutóbbi 100 tranzakciót tölti le a tranzakció dátuma alapján. A folytatáshoz kattints a Tovább gombra, vagy a Mégse gombra kattintva pontosíthatod a keresési feltételeket a letöltés előtt. \n",
    "id": "Fungsi ekspor ini hanya akan mengunduh 100 transaksi terbaru dalam data transaksi. Klik Lanjutkan untuk melanjutkan, atau Batal untuk menyaring kriteria pencarian sebelum mengunduh. \n",
    "it": "Questa funzione di esportazione ti permetterà di scaricare solo le 100 transazioni più recenti, in base alla data di transazione. Clicca su Continua o Annulla per rifinire i tuoi criteri di ricerca prima di eseguire il download. \n",
    "ja": "このエクスポート機能を使ってダウンロードできるのは、取引の日付に基づいた直近100件の取引のみです。 「続ける」をクリックして続行するか、「キャンセル」をクリックしてダウンロード前に検索条件を調整します。 \n",
    "key": "RL_EJExportWarning",
    "ko": "이 내보내기 기능을 사용하면 거래 날짜를 기준으로 최근 100건의 거래만 다운로드됩니다. 'Proceed(진행)'를 클릭하여 계속 진행하거나 'Cancel(취소)'을 클릭하여 다운로드 전에 검색 기준을 세분화하세요. \n",
    "nb": "Bruk av denne eksportfunksjonen laster bare ned de 100 nyeste transaksjonene basert på transaksjonsdato. Klikk på Fortsett for å gå videre eller Avbryt for å justere søkekriteriene før du laster ned. \n",
    "nl": "Als je deze exportfunctie gebruikt, download je alleen de 100 meest recente transacties op basis van de transactiedatum. Klik op Verder om door te gaan of op Annuleer om je zoekcriteria te verfijnen voordat je gaat downloaden. \n",
    "pl": "Funkcja eksportowania pozwoli Ci na pobranie tylko 100 ostatnich transakcji (na podstawie daty transakcji). Kliknij „Kontynuuj”, aby pobrać transakcje, lub „Anuluj”, aby najpierw zastosować bardziej szczegółowe kryteria wyszukiwania. \n",
    "pt-BR": "A utilização desta função de exportação fará apenas o download das 100 transações mais recentes com base na data da transação. Clique em Continuar para prosseguir ou em Cancelar para especificar os critérios de pesquisa antes de fazer o download. \n",
    "pt-PT": "A utilização desta função de exportação apenas transfere as 100 transações mais recentes com base na data da transação. Clique em Prosseguir para continuar ou Cancelar para refinar os critérios de pesquisa antes de fazer a transferência. \n",
    "ru": "Функция экспорта позволяет загрузить только последние 100 транзакций по дате транзакции. Нажмите «Продолжить», чтобы продолжить, или «Отмена», чтобы изменить критерии поиска перед загрузкой. \n",
    "sv": "Om du använder den här exportfunktionen laddas endast de senaste 100 transaktionerna ned baserat på transaktionsdatumet. Klicka på Fortsätt för att fortsätta eller Avbryt för att förfina dina sökkriterier innan du laddar ned. \n",
    "th": "การใช้ฟังก์ชันส่งออกนี้จะดาวน์โหลดเฉพาะการทำรายการ 100 รายการล่าสุดโดยอิงจากวันที่ทำรายการ คลิกที่ \"ดำเนินการต่อ\" เพื่อดำเนินการต่อหรือ \"ยกเลิก\" เพื่อปรับเกณฑ์การค้นหาก่อนดาวน์โหลด \n",
    "tr": "Bu dışa aktarma işlevi, işlem tarihine bağlı olarak yalnızca en güncel 100 işlemi indirecektir.  Devam etmek için Devam Et öğesine veya indirmeden önce arama kriterini iyileştirmek için İptal öğesine tıkla. \n",
    "zh-Hans": "使用此导出功能只会根据交易日期下载最近的 100 笔交易。 点击“继续”以继续，或点击“取消”以优化搜索条件，然后再下载。 \n",
    "zh-Hant": "使用此匯出功能只能下載距離交易日期最近的 100 筆交易。 按一下「繼續」以繼續，或按一下「取消」，重設搜尋條件後再下載。 \n"
  },
  "RL_ElectronicJournal": {
    "ar": "دفتر الحسابات الإلكتروني",
    "ca": "Registre electrònic",
    "comment": "Electronic Journal to view the transaction and print receipt",
    "cs": "Elektronické noviny",
    "da": "Elektronisk journal",
    "de": "Elektronische Zeitschrift",
    "el": "Ηλεκτρονική εφημερίδα",
    "en": "Electronic Journal",
    "en-GB": "Electronic Journal",
    "en-XA": "[Éļéçţŕöñîç Ĵöûŕñåļ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Publicación electrónica",
    "es-AR": "Publicación electrónica",
    "es-ES": "Publicación electrónica",
    "es-MX": "Diario electrónico",
    "fr": "Journal électronique",
    "hu": "Elektronikus nyilvántartás",
    "id": "Jurnal Elektronik",
    "it": "Diario elettronico",
    "ja": "電子ジャーナル",
    "key": "RL_ElectronicJournal",
    "ko": "전자 저널",
    "nb": "Elektronisk journal",
    "nl": "Elektronisch kasboek",
    "pl": "Dziennik elektroniczny",
    "pt-BR": "Diário eletrônico",
    "pt-PT": "Registo eletrónico",
    "ru": "Электронный журнал",
    "sv": "Elektronisk journal",
    "th": "บันทึกรายการอิเล็กทรอนิกส์",
    "tr": "Elektronik Günlük Dosyası",
    "zh-Hans": "电子日记账",
    "zh-Hant": "電子日誌"
  },
  "RL_Email": {
    "ca": "Correu electrònic",
    "comment": "Email field for a user to be printed on the receipt",
    "cs": "E-mail",
    "da": "E-mail",
    "de": "E-Mail",
    "en": "Email",
    "en-XA": "[Éɱåîļ 耐克รื่بُو]",
    "es-419": "Correo electrónico",
    "es-AR": "Correo electrónico",
    "es-ES": "Correo electrónico",
    "es-MX": "Correo electrónico",
    "fr": "E-mail",
    "hu": "E-mail",
    "it": "E-mail",
    "ja": "メール",
    "key": "RL_Email",
    "ko": "이메일",
    "nb": "E-post",
    "nl": "E-mailadres",
    "pl": "E-mail",
    "pt-PT": "E-mail",
    "ru": "Эл. почта",
    "sv": "E-post",
    "tr": "E-posta",
    "zh-Hans": "电子邮件",
    "zh-Hant": "電子郵件"
  },
  "RL_EmpNumber": {
    "ar": "رقم الموظف",
    "ca": "Número Empleado",
    "comment": "Employee number given for the employee discount transaction",
    "cs": "C. zamestnance",
    "da": "Medarb.nr.",
    "de": "Mitarbeiternummer",
    "el": "Αριθμός εργαζομένου",
    "en": "Employee Number",
    "en-GB": "Employee Number",
    "en-XA": "[Éɱþļöýéé Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de empleado",
    "es-AR": "Número de empleado",
    "es-ES": "N de Empleado",
    "es-MX": "Número de empleado",
    "fr": "No d’employé",
    "hu": "Employee Number",
    "id": "Nomor Karyawan",
    "it": "Numero dipendente",
    "ja": "社員番号",
    "key": "RL_EmpNumber",
    "ko": "직원 번호",
    "nb": "Ansattnummer",
    "nl": "Werknemernummer",
    "pl": "Numer pracownika",
    "pt-BR": "Número do funcionário",
    "pt-PT": "Número do funcionário",
    "ru": "Номер сотрудника",
    "sv": "Medarbetarnummer",
    "th": "หมายเลขพนักงาน",
    "tr": "Çalışan Numarası",
    "zh-Hans": "员工编号",
    "zh-Hant": "員工編號"
  },
  "RL_EmployeeDiscount": {
    "ar": "خصم للموظفين",
    "ca": "Descompte per a empleats",
    "comment": "Discounts given to the employees",
    "cs": "Zaměstnanecká sleva",
    "da": "Medarbejderrabat",
    "de": "Mitarbeiterrabatt",
    "el": "Έκπτωση για τους εργαζόμενους",
    "en": "Employee Discount",
    "en-GB": "Employee Discount",
    "en-XA": "[Éɱþļöýéé Ðîšçöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Descuento para empleados",
    "es-AR": "Descuento para empleados",
    "es-ES": "Descuento para empleados",
    "es-MX": "Descuento de empleado",
    "fr": "Réduction pour employé",
    "hu": "Alkalmazotti kedvezmény",
    "id": "Diskon Karyawan",
    "it": "Sconto per i dipendenti",
    "ja": "従業員割引",
    "key": "RL_EmployeeDiscount",
    "ko": "직원 할인",
    "nb": "Ansattrabatt",
    "nl": "Werknemerskorting",
    "pl": "Zniżka pracownicza",
    "pt-BR": "Desconto para funcionários",
    "pt-PT": "Desconto de funcionário",
    "ru": "Скидка для сотрудников",
    "sv": "Personalrabatt",
    "th": "ส่วนลดพนักงาน",
    "tr": "Çalışan İndirimi",
    "zh-Hans": "员工折扣",
    "zh-Hant": "員工折扣"
  },
  "RL_EmployeeID": {
    "ar": "معرّف الموظّف",
    "ca": "ID de empleado",
    "comment": "Employee ID",
    "cs": "ID zaměstnance",
    "da": "Medarbejder-id",
    "de": "Employee ID",
    "el": "Αναγνωριστικό εργαζομένου",
    "en": "Employee ID",
    "en-GB": "Employee ID",
    "en-XA": "[Éɱþļöýéé ÎÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Id. de empleado",
    "es-AR": "Id. de empleado",
    "es-ES": "ID de empleado",
    "es-MX": "Id. de empleado",
    "fr": "ID employé",
    "hu": "Employee ID",
    "id": "ID Karyawan",
    "it": "ID dipendente",
    "ja": "社員 ID",
    "key": "RL_EmployeeID",
    "ko": "직원 ID",
    "nb": "Ansatt-ID",
    "nl": "ID werknemer",
    "pl": "ID pracownika",
    "pt-BR": "ID do funcionário",
    "pt-PT": "ID de funcionário",
    "ru": "Номер сотрудника",
    "sv": "Medarbetar-ID",
    "th": "ID พนักงาน",
    "tr": "Çalışan Kimliği",
    "zh-Hans": "员工 ID",
    "zh-Hant": "員工 ID"
  },
  "RL_ExchangeInTender": {
    "ar": "تبادل ضمن المناقصة",
    "ca": "Pago en cambio",
    "comment": "Exchange of multi tender transaction",
    "cs": "Výmena zaplacena",
    "da": "Betalingsmiddeltransaktion - ind",
    "de": "Zahlungsmittelwechsel Ein",
    "el": "Προσφορά εισερχόμενης ανταλλαγής",
    "en": "Exchange In Tender",
    "en-GB": "Exchange In Tender",
    "en-XA": "[Éẋçĥåñĝé Îñ Ţéñðéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Medio de pago de cambio entrante",
    "es-AR": "Cam. form. pago",
    "es-ES": "Pago Cambio Entrante",
    "es-MX": "Cambio de medio de pago entrante",
    "fr": "Echange Mode paiement",
    "hu": "Exchange In Tender",
    "id": "Pertukaran dalam Tender",
    "it": "Mezzo pag. in entrata sost",
    "ja": "交換テンダーイン",
    "key": "RL_ExchangeInTender",
    "ko": "교환 수단 수령",
    "nb": "Veksel inn betalingsmetode",
    "nl": "Ruil In Betaling",
    "pl": "Kurs w ofercie",
    "pt-BR": "Troca de pagamento",
    "pt-PT": "Troca de Modo Pag",
    "ru": "Внесение платеж. средств при обмене",
    "sv": "Utväxling betmed",
    "th": "การแลกเปลี่ยนในการชำระเงิน",
    "tr": "İçe Değişim Kasa İşlemi",
    "zh-Hans": "换入支付方式",
    "zh-Hant": "支付方式交換"
  },
  "RL_ExchangeOutTender": {
    "ar": "تبادل خارج إطار المناقصة",
    "ca": "Pago Cambio Saliente",
    "comment": "Exchnage out given in multi tender",
    "cs": "Výmena vyplacena",
    "da": "Betalingsmiddeltransaktion - ud",
    "de": "Zahlungsmittelwechsel Aus",
    "el": "Προσφορά εξερχόμενης ανταλλαγής",
    "en": "Exchange Out Tender",
    "en-GB": "Exchange Out Tender",
    "en-XA": "[Éẋçĥåñĝé Öûţ Ţéñðéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Cambio de medio de pago saliente",
    "es-AR": "Camb. oferta sali",
    "es-ES": "Pago Cambio Saliente",
    "es-MX": "Cambio de medio de pago saliente",
    "fr": "Mode de paiement pour décaissement",
    "hu": "Exchange Out Tender",
    "id": "Pertukaran di Luar Tender",
    "it": "Mezzo pag. in uscita sost",
    "ja": "交換テンダーアウト",
    "key": "RL_ExchangeOutTender",
    "ko": "교환 수단 지급",
    "nb": "Veksel ut betalingsmetode",
    "nl": "Ruil uit Betaling",
    "pl": "Wymiana środków płatniczych na zewnątrz",
    "pt-BR": "Troca de pagamento",
    "pt-PT": "Modo Pagamento trocado",
    "ru": "Выплата платеж. средств при обмене",
    "sv": "Inväxling betmed",
    "th": "การแลกเปลี่ยนนอกการชำระเงิน",
    "tr": "Dışa Değişim Kasa İşlemi",
    "zh-Hans": "换出支付方式",
    "zh-Hant": "支付方式交換"
  },
  "RL_ExchangeRate": {
    "ar": "سعر الصرف",
    "ca": "Taxa de canvi",
    "comment": "To display foreign currency exchange rate on the receipts. ",
    "cs": "Směnný kurz",
    "da": "Ombytningsrate",
    "de": "Wechselkurs",
    "el": "Ισοτιμία συναλλάγματος",
    "en": "Exchange Rate",
    "en-GB": "Exchange rate",
    "en-XA": "[Éẋçĥåñĝé Ŕåţé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tasa de cambio",
    "es-AR": "Tasa de cambio",
    "es-ES": "Tasa de cambio",
    "es-MX": "Tasa de cambio",
    "fr": "Taux de change",
    "hu": "Árfolyam",
    "id": "Nilai Tukar",
    "it": "Tasso di cambio",
    "ja": "為替レート",
    "key": "RL_ExchangeRate",
    "ko": "환율",
    "nb": "Vekslingskurs",
    "nl": "Wisselkoers",
    "pl": "Kurs wymiany walutowej",
    "pt-BR": "Taxa de câmbio",
    "pt-PT": "Taxa de câmbio",
    "ru": "Курс обмена",
    "sv": "Växlingskurs",
    "th": "อัตราแลกเปลี่ยน",
    "tr": "Döviz Kuru",
    "zh-Hans": "汇率",
    "zh-Hant": "匯率"
  },
  "RL_Export": {
    "ar": "تصدير",
    "ca": "Exporta",
    "comment": "Export the search results from Electronic Journal",
    "cs": "Exportovat",
    "da": "Eksportér",
    "de": "Exportieren",
    "el": "Εξαγωγή",
    "en": "Export",
    "en-GB": "Export",
    "en-XA": "[Éẋþöŕţ 耐克รื่بُو]",
    "es-419": "Exportar",
    "es-AR": "Exportar",
    "es-ES": "Exportar",
    "es-MX": "Exportar",
    "fr": "Exporter",
    "hu": "Exportálás",
    "id": "Ekspor",
    "it": "Esporta",
    "ja": "エクスポート",
    "key": "RL_Export",
    "ko": "내보내기",
    "nb": "Eksporter",
    "nl": "Exporteer",
    "pl": "Eksportuj",
    "pt-BR": "Exportar",
    "pt-PT": "Exportar",
    "ru": "Экспорт",
    "sv": "Exportera",
    "th": "ส่งออก",
    "tr": "Dışa Aktar",
    "zh-Hans": "导出",
    "zh-Hant": "匯出"
  },
  "RL_FinalPrice": {
    "ar": "السعر النهائي",
    "ca": "Precio Final",
    "comment": "Final price of the item after group or package  discount",
    "cs": "Konecná cena",
    "da": "Slutpris",
    "de": "Final Price",
    "el": "Τελική τιμή",
    "en": "Final Price",
    "en-GB": "Final Price",
    "en-XA": "[Ƒîñåļ Þŕîçé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Precio final",
    "es-AR": "Precio final",
    "es-ES": "Precio Final",
    "es-MX": "Precio final",
    "fr": "Prix final",
    "hu": "Final Price",
    "id": "Harga Akhir",
    "it": "Prezzo finale",
    "ja": "最終価格",
    "key": "RL_FinalPrice",
    "ko": "금액",
    "nb": "Endelig pris",
    "nl": "Uiteindelijke Prijs",
    "pl": "Cena końcowa",
    "pt-BR": "Preço final",
    "pt-PT": "Preço final",
    "ru": "Окончательная цена",
    "sv": "Slutligt pris",
    "th": "ราคาสุดท้าย",
    "tr": "Son Fiyat",
    "zh-Hans": "最终价格",
    "zh-Hant": "最終價格"
  },
  "RL_FiscalSequenceNumber": {
    "ar": "رقم التسلسل المالي ",
    "ca": "Número de seqüència fiscal ",
    "comment": "Fiscal Sequence Number printed on the receipts of EMEA fiscal countries. ",
    "cs": "Číslo fiskální sekvence ",
    "da": "Regnskabssekvensnummer: ",
    "de": "Laufende Steuernummer ",
    "el": "ΣΕΙΡΑ ΑΠΟΔΕΙΞΗΣ ΛΙΑΝΙΚΗΣ",
    "en": "Fiscal Sequence Number ",
    "en-GB": "Fiscal sequence number ",
    "en-XA": "[Ƒîšçåļ Šéǫûéñçé Ñûɱƀéŕ  åПβシ耐克บั้ รื่بُو]",
    "es-419": "Número de secuencia fiscal ",
    "es-AR": "Número de secuencia fiscal ",
    "es-ES": "Número de secuencia fiscal ",
    "es-MX": "Registro fiscal de contribuyentes ",
    "fr": "Numéro de séquence fiscale ",
    "hu": "Fiskális sorszám ",
    "id": "Nomor Urutan Fiskal ",
    "it": "Numero progressivo scontrino ",
    "ja": "会計シーケンス番号 ",
    "key": "RL_FiscalSequenceNumber",
    "ko": "회계 시퀀스 번호 ",
    "nb": "Skattemessig sekvensnummer ",
    "nl": "Fiscaal volgnummer ",
    "pl": "Numer sekwencyjny paragonu fiskalnego ",
    "pt-BR": "Número de sequência fiscal ",
    "pt-PT": "Número de sequência fiscal ",
    "ru": "Финансовый идентификационный номер ",
    "sv": "Sekvensnummer för räkenskapsår ",
    "th": "หมายเลขลําดับเอกสารทางการเงิน ",
    "tr": "Mali Sıra Numarası ",
    "zh-Hans": "财务序列号 ",
    "zh-Hant": "財政序號 "
  },
  "RL_FiscalSoftwareName": {
    "ar": "اسم البرنامج المالي",
    "ca": "Nom del programari fiscal",
    "comment": "Name of the EFSTA vendor software used in fiscal countries like France ",
    "cs": "Název fiskálního softwaru",
    "da": "Regnskabssoftwares navn",
    "de": "Steuersoftwarenummer",
    "el": "Όνομα φορολογικού λογισμικού",
    "en": "Fiscal Software Name",
    "en-GB": "Fiscal software name",
    "en-XA": "[Ƒîšçåļ Šöƒţŵåŕé Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del software fiscal",
    "es-AR": "Nombre del software fiscal",
    "es-ES": "Nombre del software fiscal",
    "es-MX": "Nombre del software fiscal",
    "fr": "Nom du logiciel fiscal",
    "hu": "Fiskális szoftver neve",
    "id": "Nama Perangkat Lunak Fiskal",
    "it": "Nome software fiscale",
    "ja": "会計ソフトウェア名",
    "key": "RL_FiscalSoftwareName",
    "ko": "회계 소프트웨어 이름",
    "nb": "Navn på regnskapsprogram",
    "nl": "Nummer fiscale software",
    "pl": "Nazwa oprogramowania fiskalnego",
    "pt-BR": "Nome do software fiscal",
    "pt-PT": "Nome do software fiscal",
    "ru": "Название финансового приложения",
    "sv": "Programvarans namn för räkenskapsår",
    "th": "ชื่อซอฟต์แวร์การเงิน",
    "tr": "Mali Yazılım Adı",
    "zh-Hans": "财务软件名称",
    "zh-Hant": "財政軟體名稱"
  },
  "RL_FiscalSoftwareVersion": {
    "ar": "إصدار البرنامج المالي",
    "ca": "Versió del programari fiscal",
    "comment": "Version of the EFSTA vendors fiscal software used in France",
    "cs": "Verze fiskálního softwaru",
    "da": "Regnskabssoftwares version",
    "de": "Steuersoftwareversion",
    "el": "Έκδοση φορολογικού λογισμικού",
    "en": "Fiscal Software Version",
    "en-GB": "Fiscal software version",
    "en-XA": "[Ƒîšçåļ Šöƒţŵåŕé Ṽéŕšîöñ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Versión del software fiscal",
    "es-AR": "Versión del software fiscal",
    "es-ES": "Versión del software fiscal",
    "es-MX": "Versión del software fiscal",
    "fr": "Version du logiciel fiscal",
    "hu": "Fiskális szoftver verziója",
    "id": "Versi Perangkat Lunak Fiskal",
    "it": "Versione software fiscale",
    "ja": "会計ソフトウェアのバージョン",
    "key": "RL_FiscalSoftwareVersion",
    "ko": "회계 소프트웨어 버전",
    "nb": "Versjon av regnskapsprogram",
    "nl": "Versie fiscale software",
    "pl": "Wersja oprogramowania fiskalnego",
    "pt-BR": "Versão do software fiscal",
    "pt-PT": "Versão do software fiscal",
    "ru": "Версия финансового приложения",
    "sv": "Programvaruversion för räkenskapsår",
    "th": "เวอร์ชันซอฟต์แวร์การเงิน",
    "tr": "Mali Yazılım Sürümü",
    "zh-Hans": "财务软件版本",
    "zh-Hant": "財政軟體版本"
  },
  "RL_GCBalance": {
    "ar": "رصيد بطاقة الهدايا",
    "ca": "Saldo Vale Regalo",
    "comment": "Balance of the gift card",
    "cs": "Zustatek Dárk.karty",
    "da": "Gavekortsaldo",
    "de": "Verbleibendes Geschenkgutschein-Guthaben",
    "el": "Υπόλοιπο δωροκάρτας",
    "en": "Gift Card Balance",
    "en-GB": "Gift Card Balance",
    "en-XA": "[Ĝîƒţ Çåŕð Ɓåļåñçé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Saldo de Gift Card",
    "es-AR": "Saldo de Gift Card",
    "es-ES": "Saldo Vale Regalo",
    "es-MX": "Saldo de la Tarjeta de regalo",
    "fr": "Solde carte cadeau",
    "hu": "Gift Card Balance",
    "id": "Saldo Kartu Hadiah",
    "it": "Saldo Gift Card",
    "ja": "ギフトカード残高",
    "key": "RL_GCBalance",
    "ko": "기프트 카드 잔액",
    "nb": "Saldo på gavekortet",
    "nl": "Saldo cadeaubon",
    "pl": "Saldo na karcie upominkowej",
    "pt-BR": "Saldo do cartão-presente",
    "pt-PT": "Saldo do cartão de oferta",
    "ru": "Баланс подарочной карты",
    "sv": "Presentkortssaldo",
    "th": "ยอดคงเหลือในบัตรของขวัญ",
    "tr": "Hediye Kartı Bakiyesi",
    "zh-Hans": "礼品卡余额",
    "zh-Hant": "禮品卡餘額"
  },
  "RL_GCNumber": {
    "ar": "رقم بطاقة الهدايا",
    "ca": "Número TR",
    "comment": "Gift card number in receipt",
    "cs": "GC císlo",
    "da": "GC nummer",
    "de": "GC Nummer",
    "el": "Αριθμός GC",
    "en": "GC Number",
    "en-GB": "GC Number",
    "en-XA": "[ĜÇ Ñûɱƀéŕ 耐克รื่بُو]",
    "es-419": "Número de GC",
    "es-AR": "Número de GC",
    "es-ES": "Número TR",
    "es-MX": "Número de GC",
    "fr": "NoCC",
    "hu": "GC Number",
    "id": "Nomor GC",
    "it": "Numero GC",
    "ja": "ギフトカード番号",
    "key": "RL_GCNumber",
    "ko": "GC 번호",
    "nb": "GC-nummer",
    "nl": "CB-nummer",
    "pl": "Numer KU",
    "pt-BR": "Número do GC",
    "pt-PT": "Número do CO",
    "ru": "Номер GC",
    "sv": "Presentkortsnummer",
    "th": "หมายเลขบัตรของขวัญ",
    "tr": "GC Numarası",
    "zh-Hans": "礼品卡编号",
    "zh-Hant": "禮品卡編號"
  },
  "RL_GIFTITEMRECEIPT": {
    "ar": "إيصال بالمنتج الهدية",
    "ca": "RECIBO ARTÍC. REGALO",
    "comment": "Header of the receipt indicates the recipt of Gift Item",
    "cs": "DARK. POL.-UCET",
    "da": "GAVEVARE KVITTERING",
    "de": "GESCHENKARTIKEL-QUITTUNG",
    "el": "ΑΠΟΔΕΙΞΗ ΕΙΔΟΥΣ ΔΩΡΟΥ",
    "en": "GIFT ITEM RECEIPT",
    "en-GB": "GIFT ITEM RECEIPT",
    "en-XA": "[ĜÎƑŢ ÎŢÉṀ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO DE ARTÍCULO DE REGALO",
    "es-AR": "RECIBO DE ARTÍCULO DE REGALO",
    "es-ES": "RECIBO ARTÍC. REGALO",
    "es-MX": "RECIBO DE ARTÍCULO DE REGALO",
    "fr": "REÇU ARTICLE CADEAU",
    "hu": "GIFT ITEM RECEIPT",
    "id": "TANDA TERIMA ITEM HADIAH",
    "it": "RICEVUTA ARTICOLO REGALO",
    "ja": "ギフト商品レシート",
    "key": "RL_GIFTITEMRECEIPT",
    "ko": "기프트 품목 영수증",
    "nb": "KVITTERING FOR GAVEARTIKKEL",
    "nl": "BON VOOR GESCHENKARTIKEL",
    "pl": "RACHUNEK PODARUNKOWY",
    "pt-BR": "RECIBO DO ITEM DE PRESENTE",
    "pt-PT": "RECIBO ARTIGO OFERTA",
    "ru": "GIFT ITEM RECEIPT",
    "sv": "PRESENTARTIKEL, KVITTO",
    "th": "ใบเสร็จรายการของขวัญ",
    "tr": "HEDİYE ÜRÜN MAKBUZU",
    "zh-Hans": "礼品商品收据",
    "zh-Hant": "禮品商品收據"
  },
  "RL_General": {
    "ar": "عام",
    "ca": "General",
    "comment": "General information ",
    "cs": "Obecné",
    "da": "Generel",
    "de": "Allgemein",
    "el": "Γενικά",
    "en": "General",
    "en-GB": "General",
    "en-XA": "[Ĝéñéŕåļ 耐克รื่بُو]",
    "es-419": "General",
    "es-AR": "General",
    "es-ES": "General",
    "es-MX": "General",
    "fr": "Général",
    "hu": "Általános",
    "id": "Umum",
    "it": "Generale",
    "ja": "一般情報",
    "key": "RL_General",
    "ko": "일반",
    "nb": "Generell",
    "nl": "Algemeen",
    "pl": "Ogólne",
    "pt-BR": "Disposições gerais",
    "pt-PT": "Geral",
    "ru": "Общие",
    "sv": "Allmänt",
    "th": "ทั่วไป",
    "tr": "Genel",
    "zh-Hans": "一般信息",
    "zh-Hant": "一般"
  },
  "RL_GeneralDiscount": {
    "ar": "خصم عام",
    "ca": "Descompte general",
    "comment": "The general discounts given to the customers. ",
    "cs": "Všeobecná sleva",
    "da": "Generel rabat",
    "de": "Allgemeiner Rabatt",
    "el": "Τυπική έκπτωση πελατών",
    "en": "General Discount",
    "en-GB": "General Discount",
    "en-XA": "[Ĝéñéŕåļ Ðîšçöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Descuento general",
    "es-AR": "Descuento general",
    "es-ES": "Descuento general",
    "es-MX": "Descuento general",
    "fr": "Réduction générale",
    "hu": "Általános kedvezmény",
    "id": "Diskon Umum",
    "it": "Sconto generale",
    "ja": "一般割引",
    "key": "RL_GeneralDiscount",
    "ko": "일반 할인",
    "nb": "Generell rabatt",
    "nl": "Algemene korting",
    "pl": "Ogólny rabat",
    "pt-BR": "Desconto geral",
    "pt-PT": "Desconto geral",
    "ru": "Общая скидка",
    "sv": "Allmän rabatt",
    "th": "ส่วนลดทั่วไป",
    "tr": "Genel İndirim",
    "zh-Hans": "普通折扣",
    "zh-Hant": "一般折扣"
  },
  "RL_GeneralManagerName": {
    "ar": "اسم المدير العام ",
    "ca": "Nom del responsable general",
    "comment": "The Manager name of the store or territory. ",
    "cs": "Jméno generálního ředitele",
    "da": "Daglige leders navn",
    "de": "Name des General Manager ",
    "el": "Όνομα γενικού διευθυντή ",
    "en": "General Manager Name ",
    "en-GB": "General Manager Name ",
    "en-XA": "[Ĝéñéŕåļ Ṁåñåĝéŕ Ñåɱé  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del gerente general",
    "es-AR": "Nombre del gerente general",
    "es-ES": "Nombre del manager o la manager general",
    "es-MX": "Nombre del gerente general",
    "fr": "Nom du directeur général",
    "hu": "Üzlet- vagy régióvezető neve",
    "id": "Nama General Manager",
    "it": "Nome General Manager ",
    "ja": "ゼネラル マネージャーの氏名",
    "key": "RL_GeneralManagerName",
    "ko": "대표",
    "nb": "Navn på daglig leder ",
    "nl": "Naam van algemeen manager",
    "pl": "Imię i nazwisko głównego kierownika",
    "pt-BR": "Nome do Gerente geral",
    "pt-PT": "Nome do gerente geral",
    "ru": "Имя директора",
    "sv": "Verkställande direktör ",
    "th": "ชื่อของ General Manager",
    "tr": "Genel Yönetici Adı",
    "zh-Hans": "总经理姓名",
    "zh-Hant": "總經理姓名"
  },
  "RL_GiftCardNo": {
    "ar": "رقم بطاقة الهدايا",
    "ca": "Número de targeta de regal",
    "comment": "Identifier that proceeds the gift card's number",
    "cs": "Dárková karta č.",
    "da": "Gavekortnummer",
    "de": "Geschenkgutschein-Nr.",
    "el": "Αριθμός δωροκάρτας",
    "en": "Gift Card No",
    "en-GB": "Gift Card No",
    "en-XA": "[Ĝîƒţ Çåŕð Ñö Пβシ耐克บั้ รื่بُو]",
    "es-419": "Identificador de la tarjeta de regalo",
    "es-AR": "N.º de la tarjeta de regalo",
    "es-ES": "N.º de la tarjeta de regalo",
    "es-MX": "Número de tarjeta de regalo",
    "fr": "N° de carte cadeau",
    "hu": "Ajándékutalvány száma",
    "id": "No. Kartu Hadiah",
    "it": "N. gift card",
    "ja": "ギフトカード番号",
    "key": "RL_GiftCardNo",
    "ko": "기프트 카드 번호",
    "nb": "Gavekortnummer",
    "nl": "GC Nummer",
    "pl": "Nr karty upominkowej",
    "pt-BR": "N° do cartão-presente",
    "pt-PT": "N.º do cartão de oferta",
    "ru": "Номер подарочной карты",
    "sv": "Presentkortsnr.",
    "th": "เลขบัตรของขวัญ",
    "tr": "Hediye Kartı Numarası",
    "zh-Hans": "礼品卡编号",
    "zh-Hant": "禮品卡編號"
  },
  "RL_GiftCardRedeemed": {
    "ar": "تم استرداد بطاقة الهدايا",
    "ca": "S'ha bescanviat la targeta de regal",
    "comment": "Phrase that indicates a gift card was redeemed",
    "cs": "Uplatněná dárková karta",
    "da": "Indløst gavekort",
    "de": "Geschenkgutschein eingelöst",
    "el": "Εξαργύρωση δωροκάρτας",
    "en": "Gift Card Redeemed",
    "en-GB": "Gift Card Redeemed",
    "en-XA": "[Ĝîƒţ Çåŕð Ŕéðééɱéð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tarjeta de regalo canjeada",
    "es-AR": "Tarjeta de regalo canjeada",
    "es-ES": "Tarjeta de regalo canjeada",
    "es-MX": "Tarjeta de regalo canjeada",
    "fr": "Carte cadeau utilisée",
    "hu": "Ajándékutalvány beváltva",
    "id": "Kartu Hadiah Ditukarkan",
    "it": "Gift card utilizzata",
    "ja": "ギフトカード利用",
    "key": "RL_GiftCardRedeemed",
    "ko": "기프트 카드 교환",
    "nb": "Gavekortet er løst inn",
    "nl": "Cadeaubon gebruikt",
    "pl": "Wykorzystana karta upominkowa",
    "pt-BR": "Vale-presente resgatado",
    "pt-PT": "Cartão de oferta resgatado",
    "ru": "Подарочная карта использована",
    "sv": "Presentkort inlöst",
    "th": "บัตรของขวัญที่แลกใช้",
    "tr": "Hediye Kartı Kullanıldı",
    "zh-Hans": "礼品卡已兑换",
    "zh-Hant": "禮品卡兌換"
  },
  "RL_GiftCertificate": {
    "ar": "شهادة إهداء",
    "ca": "Cheque regalo",
    "comment": "Gift Certificate",
    "cs": "Dárkový certifikát",
    "da": "Gavebevis ",
    "de": "Geschenkgutschein",
    "el": "Δωροεπιταγή",
    "en": "Gift Certificate",
    "en-GB": "Gift Certificate",
    "en-XA": "[Ĝîƒţ Çéŕţîƒîçåţé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Vale de regalo",
    "es-AR": "Vale de regalo",
    "es-ES": "Cheque regalo",
    "es-MX": "Vale de regalo",
    "fr": "Chèque cadeau",
    "hu": "Gift Certificate",
    "id": "Sertifikat Hadiah",
    "it": "Buono prepagato",
    "ja": "商品券",
    "key": "RL_GiftCertificate",
    "ko": "상품권",
    "nb": "Gavekort",
    "nl": "Cadeaubon",
    "pl": "Karta upominkowa",
    "pt-BR": "Certificado de presente",
    "pt-PT": "Certificado de Oferta",
    "ru": "Подарочный сертификат",
    "sv": "Presentkort",
    "th": "บัตรของขวัญ",
    "tr": "Hediye Çeki",
    "zh-Hans": "礼品券",
    "zh-Hant": "禮券"
  },
  "RL_GiftItem": {
    "ar": "منتج هدية",
    "ca": "Artículo de Regalo",
    "comment": "Line in receipt indicate the item is a gift item",
    "cs": "Dárková pol.",
    "da": "Gavevare",
    "de": "Geschenkartikel",
    "el": "Είδος δώρου",
    "en": "Gift Item",
    "en-GB": "Gift Item",
    "en-XA": "[Ĝîƒţ Îţéɱ 耐克รื่بُو]",
    "es-419": "Artículo de regalo",
    "es-AR": "Artículo de regalo",
    "es-ES": "Artículo de Regalo ",
    "es-MX": "Artículo de regalo",
    "fr": "Cadeau",
    "hu": "Gift Item",
    "id": "Item Hadiah",
    "it": "Articolo regalo",
    "ja": "Gift Item",
    "key": "RL_GiftItem",
    "ko": "기프트 아이템",
    "nb": "Gaveartikkel",
    "nl": "Cadeau",
    "pl": "Upominek",
    "pt-BR": "Item de presente",
    "pt-PT": "Artigo de oferta",
    "ru": "Подарочный товар",
    "sv": "Presentprodukt",
    "th": "รายการของขวัญ",
    "tr": "Hediye Ürün",
    "zh-Hans": "礼品",
    "zh-Hant": "禮品"
  },
  "RL_Hashalgorithms": {
    "ar": "خوارزميات التجزئة",
    "ca": "Algoritmes de hash",
    "comment": "Hashalogrithm - TSE fiskaly data on the Germany receipts",
    "cs": "Hashalgorithms",
    "da": "Hashalgoritmer",
    "de": "Hash-Algorithmen",
    "el": "Αλγόριθμοι κατακερματισμού",
    "en": "Hashalgorithms",
    "en-GB": "Hashalgorithms",
    "en-XA": "[Ĥåšĥåļĝöŕîţĥɱš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Algoritmos hash",
    "es-AR": "Algoritmos hash",
    "es-ES": "Algoritmos hash",
    "es-MX": "Hashalgorithms",
    "fr": "Hashalgorithms",
    "hu": "Kivonatolási algoritmus",
    "id": "Hashalgorithms",
    "it": "Hashalgorithms",
    "ja": "HashAlgorithms",
    "key": "RL_Hashalgorithms",
    "ko": "해시 알고리즘",
    "nb": "Hash-algoritmer",
    "nl": "Hash-algoritmes",
    "pl": "Algorytmy hashujące",
    "pt-BR": "Hashalgorithms",
    "pt-PT": "Algoritmos de dispersão",
    "ru": "Хеш-алгоритмы",
    "sv": "Hashalgoritmer",
    "th": "Hashalgorithms",
    "tr": "Karışık ürün algoritmaları",
    "zh-Hans": "Hash 算法",
    "zh-Hant": "雜湊演算"
  },
  "RL_INVALIDRECEIPT": {
    "ar": "إيصال غير صالح",
    "ca": "Tiquet no válido",
    "comment": "Invalid Receipt Indicator while in training mode",
    "cs": "NEPLATNÝ DOKLAD",
    "da": "UGYLDIG KVITTERING",
    "de": "Ungültig",
    "el": "ΜΗ ΕΓΚΥΡΗ ΑΠΟΔΕΙΞΗ",
    "en": "INVALID RECEIPT",
    "en-GB": "INVALID RECEIPT",
    "en-XA": "[ÎÑṼÅĻÎÐ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO NO VÁLIDO",
    "es-AR": "RECIBO NO VÁLIDO",
    "es-ES": "RECIBO NO VÁLIDO",
    "es-MX": "RECIBO NO VÁLIDO",
    "fr": "RECU NON VALIDE",
    "hu": "INVALID RECEIPT",
    "id": "TANDA TERIMA TIDAK VALID",
    "it": "RICEVUTA NON VALIDA",
    "ja": "無効レシート",
    "key": "RL_INVALIDRECEIPT",
    "ko": "잘못된 영수증",
    "nb": "UGYLDIG KVITTERING",
    "nl": "ONGELDIGE BON",
    "pl": "NIEPRAWIDŁOWY PARAGON",
    "pt-BR": "RECIBO INVÁLIDO",
    "pt-PT": "RECIBO INVÁLIDO",
    "ru": "НЕДЕЙСТВИТЕЛЬНЫЙ ЧЕК",
    "sv": "OGILTIGT KVITTO",
    "th": "ใบเสร็จไม่ถูกต้อง",
    "tr": "GEÇERSİZ MAKBUZ",
    "zh-Hans": "无效收据",
    "zh-Hant": "無效收據"
  },
  "RL_ITEMVOID": {
    "ar": "منتج باطل",
    "ca": "ANULAR ARTÍCULO",
    "comment": "<ITEM VOID>",
    "cs": "POL.ZNEPL.",
    "da": "VARE VOID",
    "de": "Artikel abbrechen",
    "el": "ΑΚΥΡΩΣΗ ΕΙΔΟΥΣ",
    "en": "ITEM VOID",
    "en-GB": "ITEM VOID",
    "en-XA": "[ÎŢÉṀ ṼÖÎÐ 耐克รื่بُو]",
    "es-419": "Anulación de artículo",
    "es-AR": "Anulación de artículo",
    "es-ES": "ANULAR ARTÍCULO",
    "es-MX": "Anulación de artículo",
    "fr": "ANNULER ART.",
    "hu": "ITEM VOID",
    "id": "ITEM BATAL",
    "it": "ARTICOLO NULLO",
    "ja": "Item VOID",
    "key": "RL_ITEMVOID",
    "ko": "품목 취소",
    "nb": "UGYLDIG VARE",
    "nl": "ARTIKEL VERNIETIGEN",
    "pl": "Usuniecie przedmiotu",
    "pt-BR": "ITEM INEXISTENTE",
    "pt-PT": "Artigo ANULADO",
    "ru": "АННУЛИРОВАНИЕ ТОВАРА",
    "sv": "ART. ANUL",
    "th": "การยกเลิกรายการ",
    "tr": "İPTAL ÜRÜN",
    "zh-Hans": "商品撤销",
    "zh-Hant": "ITEM VOID"
  },
  "RL_IdentificationNumber": {
    "ar": "رقم التعريف",
    "ca": "Número d'identificació",
    "comment": "Identification Number of the Customer. It has to be a unique number, a phone number or Tax ID.",
    "cs": "Identifikační číslo",
    "da": "Identifikationsnummer",
    "de": "Identifikationsnummer",
    "el": "Αριθμός αναγνωριστικού",
    "en": "Identification Number",
    "en-GB": "Identification Number",
    "en-XA": "[Îðéñţîƒîçåţîöñ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de identificación",
    "es-AR": "Número de identificación",
    "es-ES": "Número de identificación",
    "es-MX": "Número de identificación",
    "fr": "Numéro d'identification",
    "hu": "Azonosító szám",
    "id": "Nomor Identifikasi",
    "it": "Numero di identificazione",
    "ja": "身分証明書番号",
    "key": "RL_IdentificationNumber",
    "ko": "식별 번호",
    "nb": "Identifikasjonsnummer",
    "nl": "Identificatienummer",
    "pl": "Numer identyfikacyjny",
    "pt-BR": "Número de identificação",
    "pt-PT": "Número de identificação",
    "ru": "Идентификационный номер",
    "sv": "ID-nummer",
    "th": "หมายเลขประจำตัว",
    "tr": "Kimlik Numarası",
    "zh-Hans": "顾客标识号",
    "zh-Hant": "身份證明號碼"
  },
  "RL_InstallmentMonth": {
    "ar": "أشهر التقسيط",
    "ca": "Mes del termini",
    "comment": "Number of installments in months for the card payments. ",
    "cs": "měsíc nákupu",
    "da": "Afdragsmåned",
    "de": "Ratenmonat",
    "el": "Αριθμός μηνιαίων δόσεων",
    "en": "Installment month",
    "en-GB": "Instalment month",
    "en-XA": "[Îñšţåļļɱéñţ ɱöñţĥ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Mes de pago",
    "es-AR": "Cuota mensual",
    "es-ES": "Cuota mensual",
    "es-MX": "Meses sin intereses",
    "fr": "Mois du versement",
    "hu": "Részletfizetési hónap",
    "id": "Bulan cicilan",
    "it": "Mese rata",
    "ja": "分割払い（月）",
    "key": "RL_InstallmentMonth",
    "ko": "할부 개월",
    "nb": "Avdragsmåned",
    "nl": "Termijnmaand",
    "pl": "Miesięczna rata",
    "pt-BR": "Mês da parcela",
    "pt-PT": "Mês de prestação",
    "ru": "Оплата в рассрочку (месяц)",
    "sv": "Delbetalningsmånad",
    "th": "เดือนผ่อนชำระ",
    "tr": "Taksit süresi (ay)",
    "zh-Hans": "分期付款月数",
    "zh-Hant": "分期付款月份"
  },
  "RL_InvoiceNumber": {
    "ar": "رقم الفاتورة",
    "ca": "N.º factura",
    "comment": "Invoice Number",
    "cs": "Č. pozvánky",
    "da": "Fakturanummer",
    "de": "Rechnungsnummer",
    "el": "Αριθμός τιμολογίου",
    "en": "Invoice Number",
    "en-GB": "Invoice Number",
    "en-XA": "[Îñṽöîçé Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de factura",
    "es-AR": "Número de factura",
    "es-ES": "Fra nº",
    "es-MX": "Número de factura",
    "fr": "Num Facture",
    "hu": "Invoice Number",
    "id": "Nomor Faktur",
    "it": "Numero fattura",
    "ja": "Invoice Number",
    "key": "RL_InvoiceNumber",
    "ko": "송장 번호",
    "nb": "Fakturanummer",
    "nl": "Factuurnummer",
    "pl": "Numer faktury",
    "pt-BR": "Número da fatura",
    "pt-PT": "Nº Factura",
    "ru": "Invoice Number",
    "sv": "Faktura nummer",
    "th": "เลขใบกำกับสินค้า",
    "tr": "Fatura Numarası",
    "zh-Hans": "发票编号",
    "zh-Hant": "發票號碼"
  },
  "RL_Item": {
    "ar": "المنتج",
    "ca": "Artículo",
    "comment": "Line in receipt indicates the item",
    "cs": "Položka",
    "da": "Vare",
    "de": "Item",
    "el": "Είδος",
    "en": "Item",
    "en-GB": "Item",
    "en-XA": "[Îţéɱ 耐克รื่بُو]",
    "es-419": "Artículo",
    "es-AR": "Artículo",
    "es-ES": "Artículo",
    "es-MX": "Artículo",
    "fr": "Article",
    "hu": "Item",
    "id": "Item",
    "it": "Articolo",
    "ja": "商品",
    "key": "RL_Item",
    "ko": "아이템",
    "nb": "Vare",
    "nl": "Artikel",
    "pl": "Przedmiot",
    "pt-BR": "Item",
    "pt-PT": "Artigo",
    "ru": "Элемент",
    "sv": "Produkt",
    "th": "รายการ",
    "tr": "Ürün",
    "zh-Hans": "商品",
    "zh-Hant": "品項"
  },
  "RL_ItemTax": {
    "ar": "الضريبة على المنتج",
    "ca": "Impuestos Artículo",
    "comment": "Tax of the item",
    "cs": "Dan-položka",
    "da": "Afgift på vare",
    "de": "Artikel-Steuer",
    "el": "ΦΠΑ είδους",
    "en": "Item Tax",
    "en-GB": "Item Tax",
    "en-XA": "[Îţéɱ Ţåẋ 耐克รื่بُو]",
    "es-419": "Impuestos de artículo",
    "es-AR": "Impuestos de artículo",
    "es-ES": "Impuestos Artículo",
    "es-MX": "Impuestos de artículo",
    "fr": "Taxe art.",
    "hu": "Item Tax",
    "id": "Pajak Item",
    "it": "Tassa Articolo",
    "ja": "Item 消費税",
    "key": "RL_ItemTax",
    "ko": "품목세",
    "nb": "Vareavgift",
    "nl": "Btw items",
    "pl": "Podatek od przedmiotu",
    "pt-BR": "Imposto do item",
    "pt-PT": "Imposto sobre o artigo",
    "ru": "Налог на товар",
    "sv": "Moms produkt",
    "th": "ภาษีสินค้า",
    "tr": "Ürün Vergisi",
    "zh-Hans": "商品税费",
    "zh-Hant": "品項稅金"
  },
  "RL_ItemTaxAmt": {
    "ar": "قيمة الضريبة على المنتج",
    "ca": "Impuesto en artículo",
    "comment": "Item Tax Amount",
    "cs": "Částka daně položky",
    "da": "Varemomsbeløb",
    "de": "Item Tax Amount",
    "el": "Ποσό ΦΠΑ είδους",
    "en": "Item Tax Amount",
    "en-GB": "Item Tax Amount",
    "en-XA": "[Îţéɱ Ţåẋ Åɱöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Monto de impuestos del artículo",
    "es-AR": "Monto de impuestos del artículo",
    "es-ES": "Importe impuestos",
    "es-MX": "Importe del impuesto del artículo",
    "fr": "Montant de la taxe de l’article ",
    "hu": "Item Tax Amount",
    "id": "Jumlah Pajak Item",
    "it": "Importo imposte articolo",
    "ja": "商品税金額",
    "key": "RL_ItemTaxAmt",
    "ko": "품목세액",
    "nb": "Avgiftsbeløp på vare",
    "nl": "Btw-bedrag items",
    "pl": "Kwota podatku od przedmiotu",
    "pt-BR": "Valor fiscal do item",
    "pt-PT": "Valor do imposto sobre o artigo",
    "ru": "Сумма налога на товар",
    "sv": "Momsbelopp produkt",
    "th": "จำนวนภาษีของสินค้า",
    "tr": "Ürün Vergi Tutarı",
    "zh-Hans": "商品税金额",
    "zh-Hant": "稅金金額"
  },
  "RL_Journal": {
    "ar": "عرض دفتر الحسابات",
    "ca": "Mostra el registre",
    "comment": "the view of the journal that has the transaction details.",
    "cs": "Deníkové zobrazení",
    "da": "Journalvisning",
    "de": "Zeitschriftansicht",
    "el": "Προβολή εφημερίδας",
    "en": "Journal View",
    "en-GB": "Journal View",
    "en-XA": "[Ĵöûŕñåļ Ṽîéŵ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Vista de publicación",
    "es-AR": "Vista de publicación",
    "es-ES": "Vista de publicación",
    "es-MX": "Vista de diario",
    "fr": "Affichage du journal",
    "hu": "Nyilvántartási nézet",
    "id": "Tampilan Jurnal",
    "it": "Vista diario",
    "ja": "ジャーナル ビュー",
    "key": "RL_Journal",
    "ko": "저널 뷰",
    "nb": "Journalvisning",
    "nl": "Weergave kasboek",
    "pl": "Widok dziennika",
    "pt-BR": "Visualização do diário",
    "pt-PT": "Vista de registo",
    "ru": "Представление журнала",
    "sv": "Journalvy",
    "th": "มุมมองของบันทึกรายการ",
    "tr": "Günlük Görünümü",
    "zh-Hans": "日记账视图",
    "zh-Hant": "日誌檢視模式"
  },
  "RL_LLCard": {
    "ar": "بطاقة المالك",
    "ca": "Landlord Card",
    "comment": "Landlord Card",
    "cs": "Landlord Card",
    "da": "Landlord Card",
    "de": "Landlord Card",
    "el": "Κάρτα ιδιοκτήτη",
    "en": "Landlord Card",
    "en-GB": "Landlord Card",
    "en-XA": "[Ļåñðļöŕð Çåŕð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Landlord Card",
    "es-AR": "Landlord Card",
    "es-ES": "Landlord Card",
    "es-MX": "Landlord Card",
    "fr": "Landlord Card",
    "hu": "Landlord Card",
    "id": "Kartu Pemilik Tempat",
    "it": "Landlord Card",
    "ja": "家主カード",
    "key": "RL_LLCard",
    "ko": "임대주 카드",
    "nb": "Landlord Card",
    "nl": "Landlord Card",
    "pl": "Landlord Card",
    "pt-BR": "Cartão do proprietário",
    "pt-PT": "Landlord Card",
    "ru": "Карта арендодателя",
    "sv": "Landlord Card",
    "th": "บัตรเจ้าของสถานที่",
    "tr": "Ev Sahibi Kartı",
    "zh-Hans": "Landlord Card",
    "zh-Hant": "Landlord Card"
  },
  "RL_LoginToNikecom": {
    "ar": "سجّل دخولك إلى موقع Nike.com للاطّلاع على حالة طلبيتك",
    "ca": "Inicia la sessió a Nike.com per comprovar l'estat de la teva comanda",
    "comment": "Phrase to instruct customers to login to Nike.com to check their order status",
    "cs": "Stav objednávky zjistíš přihlášením na Nike.com",
    "da": "Log ind på Nike.com for at se din ordrestatus",
    "de": "Melde dich bei Nike.com an, um deinen Bestellstatus zu überprüfen",
    "el": "Συνδέσου στο Nike.com για να ελέγξεις την κατάσταση της παραγγελίας σου",
    "en": "Log into Nike.com to check your order status",
    "en-GB": "Sign in to Nike.com to check your order status",
    "en-XA": "[Ļöĝ îñţö Ñîķé·çöɱ ţö çĥéçķ ýöûŕ öŕðéŕ šţåţûš åƀПβシ耐克บั้ รื่بُو]",
    "es-419": "Inicia sesión en Nike.com para verificar estado del pedido",
    "es-AR": "Iniciá sesión en Nike.com para comprobar el estado de tu pedido",
    "es-ES": "Inicia sesión en Nike.com para comprobar el estado de tu pedido",
    "es-MX": "Inicia sesión en Nike.com para revisar el estado de tu pedido",
    "fr": "Connecte-toi à Nike.com pour voir le statut de ta commande",
    "hu": "A rendelés állapotának ellenőrzéséhez jelentkezzen be a Nike.com oldalra",
    "id": "Masuk ke Nike.com untuk memeriksa status pemesanan Anda",
    "it": "Accedi a Nike.com per verificare lo stato dell'ordine",
    "ja": "Nike.comにログインして、注文履歴をご確認ください",
    "key": "RL_LoginToNikecom",
    "ko": "NIKE.COM에 로그인하여 주문 상태 확인",
    "nb": "Logg på Nike.com for å sjekke bestillingsstatus",
    "nl": "Log in op Nike.com om je bestelstatus te controleren",
    "pl": "Zaloguj się na Nike.com, aby sprawdzić status zamówienia",
    "pt-BR": "Faça login em Nike.com para verificar o status do seu pedido",
    "pt-PT": "Inicia sessão em Nike.com para verificares o estado da encomenda",
    "ru": "Войдите на сайт Nike.com, чтобы проверить статус заказа",
    "sv": "Logga in på Nike.com för att se statusen för din beställning.",
    "th": "เข้าสู่ระบบ Nike.com เพื่อตรวจสอบสถานะคำสั่งซื้อ",
    "tr": "Sipariş durumunu kontrol etmek için Nike.com'da oturum aç",
    "zh-Hans": "登录 Nike.com，查看您的订单状态",
    "zh-Hant": "前往 Nike.com 查看訂單狀態"
  },
  "RL_Lumpsum": {
    "ar": "المبلغ الإجمالي",
    "ca": "pagament únic",
    "comment": "A single payment made at a particular time, as opposed to a number of smaller payments or installments.",
    "cs": "jedna platba",
    "da": "samlet beløb",
    "de": "Pauschalbetrag",
    "el": "πληρωτέο εφάπαξ ποσό",
    "en": "lumpsum",
    "en-GB": "lump sum",
    "en-XA": "[ļûɱþšûɱ 耐克รื่بُو]",
    "es-419": "suma global",
    "es-AR": "pago único",
    "es-ES": "Pago único",
    "es-MX": "sumatotal",
    "fr": "lumpsum",
    "hu": "egy összegben",
    "id": "lumsum",
    "it": "lumpsum",
    "ja": "一括払い",
    "key": "RL_Lumpsum",
    "ko": "일시불",
    "nb": "enkeltutbetaling",
    "nl": "aanbetaling",
    "pl": "ryczałt",
    "pt-BR": "lumpsum",
    "pt-PT": "Pagamento único",
    "ru": "Единовременная выплата",
    "sv": "engångsbetalning",
    "th": "เงินก้อน",
    "tr": "lumpsum",
    "zh-Hans": "总额",
    "zh-Hant": "總金額"
  },
  "RL_MallCertificate": {
    "ar": "شهادة المول",
    "ca": "Cheque c. comercial",
    "comment": "Mall Certificate used in the transation",
    "cs": "Mall Certificate",
    "da": "Indkøbscenter certifikat",
    "de": "Zertifikat Shoppingcenter",
    "el": "Δωροεπιταγή εμπορικού",
    "en": "Mall Certificate",
    "en-GB": "Mall Certificate",
    "en-XA": "[Ṁåļļ Çéŕţîƒîçåţé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Certificado de regalo de centro comercial",
    "es-AR": "Certificado de regalo de centro comercial",
    "es-ES": "Cheque c. comercial",
    "es-MX": "Certificado de centro comercial",
    "fr": "Certificat du centre commercial",
    "hu": "Mall Certificate",
    "id": "Sertifikat Mal",
    "it": "Certificato centro commerciale",
    "ja": "モールギフト券",
    "key": "RL_MallCertificate",
    "ko": "상품권",
    "nb": "Gavekort til kjøpesenter",
    "nl": "Bon winkelcentrum",
    "pl": "Certyfikat sklepowy",
    "pt-BR": "Certificado do correio",
    "pt-PT": "Vale de Centro Comercial",
    "ru": "Mall Certificate",
    "sv": "Köpcenter, certifikat",
    "th": "บัตรห้างฯ",
    "tr": "Alışveriş Merkezi Hediye Çeki",
    "zh-Hans": "商场证书",
    "zh-Hant": "商場禮券"
  },
  "RL_MatchingTransactions": {
    "ar": "المعاملات المتطابقة",
    "ca": "Transaccions coincidents",
    "comment": "Transactions Matching the given search criteria.",
    "cs": "Odpovídající transakce",
    "da": "Matchende transaktioner",
    "de": "Passende Transaktionen",
    "el": "Συναλλαγές που συμφωνούν",
    "en": "Matching Transactions",
    "en-GB": "Matching Transactions",
    "en-XA": "[Ṁåţçĥîñĝ Ţŕåñšåçţîöñš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Transacciones coincidentes",
    "es-AR": "Transacciones coincidentes",
    "es-ES": "Transacciones coincidentes",
    "es-MX": "Transacciones coincidentes",
    "fr": "Transactions correspondantes",
    "hu": "Keresésnek megfelelő tranzakciók",
    "id": "Transaksi yang Sesuai",
    "it": "Transazioni corrispondenti",
    "ja": "条件と一致した取引",
    "key": "RL_MatchingTransactions",
    "ko": "일치하는 거래",
    "nb": "Samsvarende transaksjoner",
    "nl": "Sluitende transacties",
    "pl": "Pasujące transakcje",
    "pt-BR": "Transações correspondentes",
    "pt-PT": "Transações com correspondência",
    "ru": "Совпадающие транзакции",
    "sv": "Matchande transaktioner",
    "th": "รายการธุรกรรมที่ตรงกัน",
    "tr": "Eşleşen İşlemler",
    "zh-Hans": "符合条件的交易",
    "zh-Hant": "交易搜尋結果"
  },
  "RL_Mid": {
    "ar": "معرّف التاجر",
    "ca": "Mid",
    "comment": "Mid of the gift card details",
    "cs": "Mid",
    "da": "Mid",
    "de": "Mid",
    "el": "Mid",
    "en": "Mid",
    "en-GB": "Mid",
    "en-XA": "[Ṁîð 耐克รื่بُو]",
    "es-419": "Mitad del día",
    "es-AR": "Mitad del día",
    "es-ES": "Mid",
    "es-MX": "Mid",
    "fr": "Mid",
    "hu": "Mid",
    "id": "Mid",
    "it": "Mid",
    "ja": "Mid",
    "key": "RL_Mid",
    "ko": "Mid",
    "nb": "Mid",
    "nl": "Mid",
    "pl": "Mid",
    "pt-BR": "Médio",
    "pt-PT": "Meio",
    "ru": "Средн.",
    "sv": "Med",
    "th": "หมายเลขผู้ค้า",
    "tr": "Mid",
    "zh-Hans": "型号识别码",
    "zh-Hant": "特店代號"
  },
  "RL_Modifiers": {
    "ar": "الحسومات/العروض",
    "ca": "Modificadors",
    "comment": "Price override, Promotions, Discounts etc that modifies the item price. ",
    "cs": "Modifikátory",
    "da": "Ændringer",
    "de": "Modifizierer",
    "el": "Τροποποιητές",
    "en": "Modifiers",
    "en-GB": "Modifiers",
    "en-XA": "[Ṁöðîƒîéŕš 耐克รื่بُو]",
    "es-419": "Modificadores",
    "es-AR": "Modificadores",
    "es-ES": "Modificadores",
    "es-MX": "Modificadores",
    "fr": "Modificateurs",
    "hu": "Attribútumok",
    "id": "Pengubah",
    "it": "Modificatori",
    "ja": "価格変更因子",
    "key": "RL_Modifiers",
    "ko": "변경 항목 적용",
    "nb": "Justeringer",
    "nl": "Aanpassingen",
    "pl": "Modyfikatory",
    "pt-BR": "Modificadores",
    "pt-PT": "Modificadores",
    "ru": "Модификаторы",
    "sv": "Modifierare",
    "th": "ตัวแก้ไขราคา",
    "tr": "Değiştiriciler",
    "zh-Hans": "价格更改",
    "zh-Hant": "修改項目"
  },
  "RL_Months": {
    "ar": "شهر",
    "ca": "mesos",
    "comment": "Number of months to complete an installment payment. eg 2 months, 3 months etc.",
    "cs": "měsíce",
    "da": "måneder",
    "de": "Monate",
    "el": "μήνες",
    "en": "months",
    "en-GB": "months",
    "en-XA": "[ɱöñţĥš 耐克รื่بُو]",
    "es-419": "meses",
    "es-AR": "meses",
    "es-ES": "meses",
    "es-MX": "meses",
    "fr": "mois",
    "hu": "hónap",
    "id": "bulan",
    "it": "mesi",
    "ja": "月",
    "key": "RL_Months",
    "ko": "개월",
    "nb": "måneder",
    "nl": "maanden",
    "pl": "mies.",
    "pt-BR": "meses",
    "pt-PT": "meses",
    "ru": "месяцы",
    "sv": "månader",
    "th": "เดือน",
    "tr": "ay",
    "zh-Hans": "个月",
    "zh-Hant": "月"
  },
  "RL_Name": {
    "ar": "الاسم",
    "ca": "Nombre",
    "comment": "Name  in shipping details",
    "cs": "Jméno",
    "da": "Navn",
    "de": "Name",
    "el": "Όνομα",
    "en": "Name",
    "en-GB": "Name",
    "en-XA": "[Ñåɱé 耐克รื่بُو]",
    "es-419": "Nombre",
    "es-AR": "Nombre",
    "es-ES": "Nombre",
    "es-MX": "Nombre",
    "fr": "Name",
    "hu": "Name",
    "id": "Nama",
    "it": "Nome",
    "ja": "お名前",
    "key": "RL_Name",
    "ko": "이름",
    "nb": "Navn",
    "nl": "Naam",
    "pl": "Imię i nazwisko",
    "pt-BR": "Nome",
    "pt-PT": "Nome",
    "ru": "Имя",
    "sv": "Namn",
    "th": "ชื่อ",
    "tr": "Ad",
    "zh-Hans": "名称",
    "zh-Hant": "名稱"
  },
  "RL_No": {
    "ar": "لا.",
    "ca": "NO",
    "comment": "No.",
    "cs": "Ne",
    "da": "Nej",
    "de": "Nr.",
    "el": "Αρ.",
    "en": "No.",
    "en-GB": "No.",
    "en-XA": "[Ñö· 耐克รื่بُو]",
    "es-419": "No",
    "es-AR": "No",
    "es-ES": "No.",
    "es-MX": "No.",
    "fr": "Non",
    "hu": "No.",
    "id": "No.",
    "it": "NO",
    "ja": "No.",
    "key": "RL_No",
    "ko": "번호",
    "nb": "Nr.",
    "nl": "Nee",
    "pl": "Nr.",
    "pt-BR": "Nº",
    "pt-PT": "NÃO",
    "ru": "№",
    "sv": "Nr.",
    "th": "เลขที่",
    "tr": "No.",
    "zh-Hans": "编号",
    "zh-Hant": "NO"
  },
  "RL_NoSale": {
    "ar": "ما من تخفيضات",
    "ca": "Oferta núm.",
    "comment": "No Sale",
    "cs": "Bez slevy",
    "da": "Ikke udsalg",
    "de": "Kein Verkauf",
    "el": "Χωρίς έκπτωση",
    "en": "No Sale",
    "en-GB": "No Sale",
    "en-XA": "[Ñö Šåļé 耐克รื่بُو]",
    "es-419": "Número de rebaja",
    "es-AR": "Sin venta",
    "es-ES": "N.º de oferta",
    "es-MX": "Sin venta",
    "fr": "N° de promotion",
    "hu": "Nem akciós",
    "id": "Tidak Ada Sale",
    "it": "Non in saldo",
    "ja": "セールなし",
    "key": "RL_NoSale",
    "ko": "비할인",
    "nb": "Intet salg",
    "nl": "Geen sale",
    "pl": "Bez sprzedaży",
    "pt-BR": "Número da venda",
    "pt-PT": "Sem saldos",
    "ru": "Нет акции",
    "sv": "Ingen rea",
    "th": "ไม่มีการขาย",
    "tr": "Satış Yok",
    "zh-Hans": "未成交",
    "zh-Hant": "無特惠"
  },
  "RL_Number": {
    "ar": "الرقم",
    "ca": "Número",
    "comment": "Card number",
    "cs": "  Číslo ",
    "da": "Kortnummer",
    "de": "Number",
    "el": "Αριθμός",
    "en": "Number",
    "en-GB": "Number",
    "en-XA": "[Ñûɱƀéŕ 耐克รื่بُو]",
    "es-419": "Nro.",
    "es-AR": "Número",
    "es-ES": "Número",
    "es-MX": "Número",
    "fr": "No carte",
    "hu": "Number",
    "id": "Nomor",
    "it": "Numero",
    "ja": "番号",
    "key": "RL_Number",
    "ko": "번호",
    "nb": "Nummer",
    "nl": "Nummer",
    "pl": "Numer",
    "pt-BR": "Número",
    "pt-PT": "Número",
    "ru": "Номер",
    "sv": "Nummer",
    "th": "หมายเลข",
    "tr": "Numara",
    "zh-Hans": "编号",
    "zh-Hant": "號碼"
  },
  "RL_NumberItemReturned": {
    "ar": "عدد المنتجات التي تم إرجاعها",
    "ca": "Num articulos devueltos",
    "comment": "Number of items returned in transaction",
    "cs": "C. vrácených pol.",
    "da": "Antal af units retuneret",
    "de": "Anzahl der Artikel umgetauscht",
    "el": "Αριθμός ειδών που επιστράφηκαν",
    "en": "Number of Item(s) Returned",
    "en-GB": "Number of Item(s) Returned",
    "en-XA": "[Ñûɱƀéŕ öƒ Îţéɱ(š) Ŕéţûŕñéð åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Cantidad de artículo(s) devuelto(s)",
    "es-AR": "Cantidad de artículo(s) devuelto(s)",
    "es-ES": "Num articulos devueltos",
    "es-MX": "Cantidad de artículo(s) devuelto(s)",
    "fr": "Nb ligne d'art.(s) Retourne",
    "hu": "Number of Item(s) Returned",
    "id": "Jumlah Item Dikembalikan",
    "it": "Numero di articolo(i) resi",
    "ja": "返品商品数",
    "key": "RL_NumberItemReturned",
    "ko": "반품 수량",
    "nb": "Antall varer returnert",
    "nl": "Aantal artikelen geretourneerd",
    "pl": "Liczba zwróconych przedmiotów",
    "pt-BR": "Número de itens retornados",
    "pt-PT": "Número de artigos devolvidos",
    "ru": "Количество возвращенных товаров",
    "sv": "Antal returnerade produkter",
    "th": "จำนวนสินค้าที่ส่งคืน",
    "tr": "İade Edilen Ürün Sayısı",
    "zh-Hans": "退货商品数量",
    "zh-Hant": "退貨數量"
  },
  "RL_NumberItemSold": {
    "ar": "عدد المنتجات التي تم بيعها",
    "ca": "Num articulos vendidos",
    "comment": "Number of items sold in transactions",
    "cs": "C. prodaných pol.",
    "da": "Antal af units solgt: ",
    "de": "Anzahl der Artikel verkauft",
    "el": "Αριθμός πωληθέντων ειδών",
    "en": "Number of Item(s) Sold",
    "en-GB": "Number of Item(s) Sold",
    "en-XA": "[Ñûɱƀéŕ öƒ Îţéɱ(š) Šöļð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Cantidad de artículo(s) vendido(s)",
    "es-AR": "Cantidad de artículo(s) vendido(s)",
    "es-ES": "Num articulos vendidos",
    "es-MX": "Cantidad de artículo(s) vendido(s)",
    "fr": "Nb ligne d’art. (s) vendu",
    "hu": "Number of Item(s) Sold",
    "id": "Jumlah Item Dijual",
    "it": "Numero di articolo(i) venduti",
    "ja": "販売商品数",
    "key": "RL_NumberItemSold",
    "ko": "판매 수량",
    "nb": "Antall solgte varer",
    "nl": "Aantal artikelen verkocht",
    "pl": "Liczba sprzedanych przedmiotów",
    "pt-BR": "Número de itens vendidos",
    "pt-PT": "Número de artigos vendidos",
    "ru": "Количество проданных товаров",
    "sv": "Antal sålda produkter",
    "th": "จำนวนสินค้าที่ขาย",
    "tr": "Satılan Ürün Sayısı",
    "zh-Hans": "售出商品数量",
    "zh-Hant": "銷售數量"
  },
  "RL_NumberOfPrints": {
    "ar": "عدد الطبعات",
    "ca": "Nombre d'impressions",
    "comment": "Number of receipt prints from the printer.",
    "cs": "Počet tisků",
    "da": "Antal print",
    "de": "Anzahl der Ausdrucke",
    "el": "Αριθμός εκτυπώσεων",
    "en": "Number Of Prints",
    "en-GB": "Number of Prints",
    "en-XA": "[Ñûɱƀéŕ Öƒ Þŕîñţš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de impresiones",
    "es-AR": "Número de impresiones",
    "es-ES": "Número de copias",
    "es-MX": "Número de impresiones",
    "fr": "Nombre d'impressions",
    "hu": "Nyomtatott példányok száma",
    "id": "Jumlah Cetakan",
    "it": "Numero di stampe",
    "ja": "印刷数",
    "key": "RL_NumberOfPrints",
    "ko": "인쇄 매수",
    "nb": "Antall utskrifter",
    "nl": "Aantal prints",
    "pl": "Liczba wydruków",
    "pt-BR": "Número de impressões",
    "pt-PT": "Número de impressões",
    "ru": "Количество экземпляров",
    "sv": "Antal utskrifter",
    "th": "จำนวนที่พิมพ์",
    "tr": "Baskı Sayısı",
    "zh-Hans": "打印数量",
    "zh-Hant": "列印數量"
  },
  "RL_O2OEvents": {
    "ar": "فعاليات O2O",
    "ca": "Esdeveniments online i offline",
    "comment": "Online to Offline, O2O events that happens in a store. E.g Buy Online Pick Up in Store. ",
    "cs": "Události O2O",
    "da": "O2O-events",
    "de": "O2O Events",
    "el": "Συμβάντα O2O",
    "en": "O2O Events",
    "en-GB": "O2O Events",
    "en-XA": "[Ö②Ö Éṽéñţš å耐克รื่بُو]",
    "es-419": "Eventos en línea a la tienda",
    "es-AR": "Eventos O2O",
    "es-ES": "Eventos 020",
    "es-MX": "Eventos O2O",
    "fr": "Évènements O2O",
    "hu": "O2O-események",
    "id": "Acara O2O",
    "it": "Eventi O2O",
    "ja": "O2Oによる購入",
    "key": "RL_O2OEvents",
    "ko": "O2O 이벤트",
    "nb": "O2O Events",
    "nl": "O2O evenementen",
    "pl": "Wydarzenia O2O",
    "pt-BR": "Eventos O2O",
    "pt-PT": "Eventos O2O",
    "ru": "События O2O",
    "sv": "O2O Event",
    "th": "กิจกรรม O2O",
    "tr": "O2O Etkinlikleri",
    "zh-Hans": "O2O 活动",
    "zh-Hant": "O2O 活動"
  },
  "RL_OfflineReceipt": {
    "ar": "إيصال من دون اتصال بالإنترنت",
    "ca": "Rebut offline",
    "comment": "Text to be printed on top of  the receipts that are generated in Offline Scenarios /when network is down.",
    "cs": "Offline účtenka",
    "da": "Offline-kvittering",
    "de": "Offline-Beleg",
    "el": "Απόδειξη εκτός σύνδεσης",
    "en": "Offline Receipt",
    "en-GB": "Offline Receipt",
    "en-XA": "[Öƒƒļîñé Ŕéçéîþţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Recibo sin conexión",
    "es-AR": "Recibo sin conexión",
    "es-ES": "Recibo offline",
    "es-MX": "Recibo sin conexión",
    "fr": "Reçu hors ligne",
    "hu": "Offline számla",
    "id": "Tanda Terima Offline",
    "it": "Ricevuta offline",
    "ja": "オフライン用の領収書",
    "key": "RL_OfflineReceipt",
    "ko": "오프라인 영수증",
    "nb": "Offline-kvittering",
    "nl": "Offline bon",
    "pl": "Paragon offline",
    "pt-BR": "Recibos off-line",
    "pt-PT": "Recibo offline",
    "ru": "Физический чек",
    "sv": "Offline-kvitto",
    "th": "ใบเสร็จแบบออฟไลน์",
    "tr": "Çevrimdışı Makbuz",
    "zh-Hans": "离线收据",
    "zh-Hant": "離線收據"
  },
  "RL_OperationType": {
    "ar": "نوع العملية",
    "ca": "Tipus d'operació",
    "comment": "Operation Type is a type of operation - sales or returns ",
    "cs": "Typ operace",
    "da": "Handlingstype",
    "de": "Vorgang",
    "el": "Τύπος λειτουργίας",
    "en": "Operation Type",
    "en-GB": "Operation Type",
    "en-XA": "[Öþéŕåţîöñ Ţýþé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tipo de operación",
    "es-AR": "Tipo de operación",
    "es-ES": "Tipo de operación",
    "es-MX": "Tipo de operación",
    "fr": "Type d'opération",
    "hu": "Művelet típusa",
    "id": "Jenis Operasi",
    "it": "Tipo operazione",
    "ja": "操作の種類",
    "key": "RL_OperationType",
    "ko": "운영 유형",
    "nb": "Operasjonstype",
    "nl": "Type bewerking",
    "pl": "Typ transakcji",
    "pt-BR": "Tipo de operação",
    "pt-PT": "Tipo de operação",
    "ru": "Тип операции",
    "sv": "Åtgärdstyp",
    "th": "ประเภทการดำเนินการ",
    "tr": "İşlem Tipi",
    "zh-Hans": "操作类型",
    "zh-Hant": "作業類型"
  },
  "RL_OperatorChangeRequest": {
    "ar": "طلب تغيير المشغِّل",
    "ca": "Sol·licitudCanviOperador",
    "comment": "OperatorChangeRequest",
    "cs": "OperatorChangeRequest",
    "da": "OperatorChangeRequest",
    "de": "OperatorChangeRequest",
    "el": "Αίτημα αλλαγής χειριστή",
    "en": "OperatorChangeRequest",
    "en-GB": "OperatorChangeRequest",
    "en-XA": "[ÖþéŕåţöŕÇĥåñĝéŔéǫûéšţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Solicitud para cambiar de operador",
    "es-AR": "SolicitudCambioOperador",
    "es-ES": "Solicitudcambiooperador",
    "es-MX": "Cambio a solicitud del Operador",
    "fr": "DemandeModificationOpérateur",
    "hu": "OperatorChangeRequest",
    "id": "PermintaanPerubahanOperator",
    "it": "OperatorChangeRequest",
    "ja": "オペレータ変更リクエスト",
    "key": "RL_OperatorChangeRequest",
    "ko": "운영자 변경 요청",
    "nb": "OperatorChangeRequest",
    "nl": "Aanvraag voor operatorwissel",
    "pl": "WniosekOperatoraOZmianę",
    "pt-BR": "OperatorChangeRequest",
    "pt-PT": "PedidoDeAlteraçãoDoOperador",
    "ru": "OperatorChangeRequest",
    "sv": "OperatorChangeRequest",
    "th": "ผู้ดำเนินการเปลี่ยนคำขอ",
    "tr": "Operatör Değişim Talebi",
    "zh-Hans": "OperatorChangeRequest",
    "zh-Hant": "OperatorChangeRequest"
  },
  "RL_OrgDate": {
    "ar": "التاريخ الأساسي",
    "ca": "Fecha Origen",
    "comment": "Date of roriginal transaction",
    "cs": "Puvodní datum",
    "da": "Original dato",
    "de": "Original Datum",
    "el": "Αρχική ημερομηνία",
    "en": "Original Date",
    "en-GB": "Original Date",
    "en-XA": "[Öŕîĝîñåļ Ðåţé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Fecha original",
    "es-AR": "Fecha original",
    "es-ES": "Fecha Origen",
    "es-MX": "Fecha original",
    "fr": "Date d’origine",
    "hu": "Original Date",
    "id": "Tanggal Awal",
    "it": "Data originale",
    "ja": "販売日",
    "key": "RL_OrgDate",
    "ko": "기존 구매 일자",
    "nb": "Opprinnelig dato",
    "nl": "Originele datum",
    "pl": "Oryginalna data",
    "pt-BR": "Data original",
    "pt-PT": "Data original",
    "ru": "Исходная дата",
    "sv": "Ursprungligt datum",
    "th": "วันทำธุรกรรม",
    "tr": "Orijinal Tarih",
    "zh-Hans": "原始日期",
    "zh-Hant": "原始日期"
  },
  "RL_OrgPrice": {
    "ar": "السعر الأصلي",
    "ca": "Precio orig.",
    "comment": "Original price of the item",
    "cs": "Puvodní cena",
    "da": "Original pris",
    "de": "Original Preis",
    "el": "Αρχική τιμή",
    "en": "Original Price",
    "en-GB": "Original Price",
    "en-XA": "[Öŕîĝîñåļ Þŕîçé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Precio original",
    "es-AR": "Precio original",
    "es-ES": "Precio orig.",
    "es-MX": "Precio original",
    "fr": "Prix Original",
    "hu": "Original Price",
    "id": "Harga Asal",
    "it": "Prezzo originale",
    "ja": "の価格変更　元の価格　　 ",
    "key": "RL_OrgPrice",
    "ko": "정가",
    "nb": "Original pris",
    "nl": "Originele Prijs",
    "pl": "Cena pierwotna",
    "pt-BR": "Preço original",
    "pt-PT": "Preco Original",
    "ru": "Исходная цена",
    "sv": "Originalpris",
    "th": "ราคาเดิม",
    "tr": "Orijinal Fiyat",
    "zh-Hans": "原价",
    "zh-Hant": "原始價格"
  },
  "RL_OrgRegNumber": {
    "ar": "رقم التسجيل الأساسي",
    "ca": "Número Caja Origen",
    "comment": "Original register number used",
    "cs": "Puvodní c. registru",
    "da": "Originalt kasseapparatsnr",
    "de": "Original-Kasse nummer",
    "el": "Αριθμός αρχικού ταμείου",
    "en": "Original Register Number",
    "en-GB": "Original Register Number",
    "en-XA": "[Öŕîĝîñåļ Ŕéĝîšţéŕ Ñûɱƀéŕ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Número original de caja reg.",
    "es-AR": "Número original de caja reg",
    "es-ES": "Número Caja Origen",
    "es-MX": "Número original de caja reg.",
    "fr": "No. caisse d’origine",
    "hu": "Original Register Number",
    "id": "Nomor Registrasi Awal",
    "it": "Numero registrazione originale",
    "ja": "販売レジ番号",
    "key": "RL_OrgRegNumber",
    "ko": "기존 POS No",
    "nb": "Opprinnelig kassenummer",
    "nl": "Origineel Kassa Nummer",
    "pl": "Oryginalny numer kasy",
    "pt-BR": "Número de registro original",
    "pt-PT": "Número de registo original",
    "ru": "Исходный регистрационный номер",
    "sv": "Ursprungligt registreringsnummer",
    "th": "หมายเลขลงทะเบียนเดิม",
    "tr": "Orijinal Kasa Numarası",
    "zh-Hans": "原始注册编号",
    "zh-Hant": "原始註冊編號"
  },
  "RL_OrgStoreNumber": {
    "ar": "رقم المتجر الأساسي",
    "ca": "Número Tienda Origen",
    "comment": "Original store number used",
    "cs": "Puvodní c. prodejny",
    "da": "Originalt butiksnr",
    "de": "Original Store-Nummer",
    "el": "Αριθμός αρχικού καταστήματος",
    "en": "Original Store Number",
    "en-GB": "Original Store Number",
    "en-XA": "[Öŕîĝîñåļ Šţöŕé Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número original de tienda",
    "es-AR": "Número original de tienda",
    "es-ES": "Número Tienda Origen",
    "es-MX": "Número original de tienda",
    "fr": "No. magasin original",
    "hu": "Original Store Number",
    "id": "Nomor Toko Awal",
    "it": "Numero negozio originale",
    "ja": "販売店舗番号",
    "key": "RL_OrgStoreNumber",
    "ko": "원매장 번호",
    "nb": "Opprinnelig butikknummer",
    "nl": "Origineel Winkelnummer",
    "pl": "Oryginalny numer sklepu",
    "pt-BR": "Número da loja original",
    "pt-PT": "Número da loja original",
    "ru": "Исх. номер магазина",
    "sv": "Ursprungligt butiksnummer",
    "th": "หมายเลขร้านเดิม",
    "tr": "Orijinal Mağaza Numarası",
    "zh-Hans": "原始商店编号",
    "zh-Hant": "原始門市編號"
  },
  "RL_OrgTransaction": {
    "ar": "المعاملة الأساسية",
    "ca": "Operación Origen",
    "comment": "Original transation details",
    "cs": "Puvodní transakce",
    "da": "Original transaktion",
    "de": "Original-Transaktion",
    "el": "Αρχική συναλλαγή",
    "en": "Original Transaction",
    "en-GB": "Original Transaction",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñšåçţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Transacción original",
    "es-AR": "Transacción original",
    "es-ES": "Operación Origen",
    "es-MX": "Transacción original",
    "fr": "Trans. d’origine",
    "hu": "Original Transaction",
    "id": "Transaksi Awal",
    "it": "Transazione originale",
    "ja": "販売番号",
    "key": "RL_OrgTransaction",
    "ko": "기존 영수 No",
    "nb": "Opprinnelig transaksjon",
    "nl": "Originele transactie",
    "pl": "Oryginalna transakcja",
    "pt-BR": "Transação original",
    "pt-PT": "Transação original",
    "ru": "Исходная транзакция",
    "sv": "Ursprunglig transaktion",
    "th": "ธุรกรรมเดิม",
    "tr": "Orijinal İşlem",
    "zh-Hans": "原始交易",
    "zh-Hant": "原始交易細節"
  },
  "RL_OrigInvoiceNo": {
    "ar": "رقم الفاتورة الأصلية",
    "ca": "N.º factura orig.",
    "comment": "Abbreviation for Original Transaction Invoice No",
    "cs": "Orig Invoice No",
    "da": "Orig fakturanr",
    "de": "Orig Invoice No",
    "el": "Αρ. αρχικού τιμολογίου",
    "en": "Orig Invoice No",
    "en-GB": "Orig Invoice No.",
    "en-XA": "[Öŕîĝ Îñṽöîçé Ñö Пβシ耐克บั้ รื่بُو]",
    "es-419": "N.º de factura orig",
    "es-AR": "N.º de factura orig",
    "es-ES": "Factura Orig nº",
    "es-MX": "N.º de factura orig",
    "fr": "Orig Invoice No",
    "hu": "Orig Invoice No",
    "id": "No. Faktur Asli",
    "it": "Orig Invoice No",
    "ja": "Orig Invoice No",
    "key": "RL_OrigInvoiceNo",
    "ko": "원본 송장 번호",
    "nb": "Originalt fakturanr.",
    "nl": "Orig Invoice No",
    "pl": "Nr pierwotnej faktury",
    "pt-BR": "Número original da fatura",
    "pt-PT": "Orig Invoice No",
    "ru": "Orig Invoice No",
    "sv": "Urspr. fakturanr",
    "th": "หมายเลขใบกำกับสินค้าเดิม",
    "tr": "Orij Fatura No",
    "zh-Hans": "原始发票号",
    "zh-Hant": "原始發票號碼"
  },
  "RL_OriginalTransactionNumber": {
    "ar": "رقم المعاملة الأساسية",
    "ca": "Número de la transacció original",
    "comment": "Original Transaction Number",
    "cs": "Číslo původní transakce",
    "da": "Oprindeligt transaktionsnummer",
    "de": "Ursprüngliche Transaktionsnummer",
    "el": "Αριθμός αρχικής συναλλαγής",
    "en": "Original Transaction Number",
    "en-GB": "Original Transaction Number",
    "en-XA": "[Öŕîĝîñåļ Ţŕåñšåçţîöñ Ñûɱƀéŕ åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Número de transacción original",
    "es-AR": "Número de transacción original",
    "es-ES": "Número de transacción original",
    "es-MX": "Número de la transacción original",
    "fr": "Numéro de transaction d'origine",
    "hu": "Eredeti tranzakciós szám",
    "id": "Nomor Transaksi Awal",
    "it": "Numero della transazione originale",
    "ja": "初回取引番号",
    "key": "RL_OriginalTransactionNumber",
    "ko": "기존 거래 번호",
    "nb": "Opprinnelig transaksjonsnummer",
    "nl": "Origineel transactienummer",
    "pl": "Oryginalny numer transakcji",
    "pt-BR": "Número da transação original",
    "pt-PT": "Número da transação original",
    "ru": "Номер исходной транзакции",
    "sv": "Ursprungligt transaktionsnummer",
    "th": "หมายเลขธุรกรรมเดิม",
    "tr": "Orijinal İşlem Numarası",
    "zh-Hans": "原始交易编号",
    "zh-Hant": "原始交易編號"
  },
  "RL_Original_CreditNote": {
    "ar": "إشعار بالرصيد الأصليّ",
    "ca": "NOTA D'ABONAMENT ORIGINAL",
    "comment": "ORIGINAL CREDIT NOTE",
    "cs": "Originální dobropis",
    "da": "ORIGINAL KREDITNOTE",
    "de": "ORIGINALE GUTSCHRIFT",
    "el": "ΑΡΧΙΚΟ ΠΙΣΤΩΤΙΚΟ ΣΗΜΕΙΩΜΑ",
    "en": "ORIGINAL CREDIT NOTE",
    "en-GB": "ORIGINAL CREDIT NOTE",
    "en-XA": "[ÖŔÎĜÎÑÅĻ ÇŔÉÐÎŢ ÑÖŢÉ Пβシ耐克บั้ รื่بُو]",
    "es-419": "NOTA DE CRÉDITO ORIGINAL",
    "es-AR": "NOTA DE CRÉDITO ORIGINAL",
    "es-ES": "NOTA DE CRÉDITO ORIGINAL",
    "es-MX": "NOTA DE CRÉDITO ORIGINAL",
    "fr": "NOTE DE CRÉDIT D'ORIGINE",
    "hu": "EREDET JÓVÁÍRÁSI ÉRTESÍTÉS",
    "id": "CATATAN KREDIT ASLI",
    "it": "NOTA DI CREDITO ORIGINALE",
    "ja": "クレジットノートの原本",
    "key": "RL_Original_CreditNote",
    "ko": "신용 전표 원본",
    "nb": "OPPRINNELIG KREDITNOTA",
    "nl": "ORIGINELE TEGOEDBON",
    "pl": "ORYGINALNA NOTA KREDYTOWA",
    "pt-BR": "NOTA DE CRÉDITO ORIGINAL",
    "pt-PT": "NOTA DE CRÉDITO ORIGINAL",
    "ru": "ИСХОДНОЕ КРЕДИТОВОЕ АВИЗО",
    "sv": "URSPRUNGLIG KREDITNOTA",
    "th": "ใบลดหนี้เดิม",
    "tr": "ORİJİNAL KREDİ NOTU",
    "zh-Hans": "原始信用票据",
    "zh-Hant": "折讓單正本"
  },
  "RL_Original_Invoice": {
    "ar": "الفاتورة الأصلية/الإيصال الأصليّ",
    "ca": "REBUT / FACTURA ORIGINAL",
    "comment": "ORIGINAL INVOICE/RECEIPT",
    "cs": "ORIGINÁLNÍ FAKTURA/DOKLAD",
    "da": "ORIGINAL FAKTURA/KVITTERING",
    "de": "ORIGINALRECHNUNG/-BELEG",
    "el": "ΑΡΧΙΚΟ ΤΙΜΟΛΟΓΙΟ/ΑΡΧΙΚΗ ΑΠΟΔΕΙΞΗ",
    "en": "ORIGINAL INVOICE/RECEIPT",
    "en-GB": "ORIGINAL INVOICE/RECEIPT",
    "en-XA": "[ÖŔÎĜÎÑÅĻ ÎÑṼÖÎÇÉ⁄ŔÉÇÉÎÞŢ åПβシ耐克บั้ รื่بُو]",
    "es-419": "RECIBO/FACTURA ORIGINAL",
    "es-AR": "FACTURA/RECIBO ORIGINAL",
    "es-ES": "FACTURA/RECIBO ORIGINAL",
    "es-MX": "FACTURA/RECIBO ORIGINAL",
    "fr": "FACTURE/REÇU D'ORIGINE",
    "hu": "EREDETI SZÁMLA",
    "id": "FAKTUR/TANDA TERIMA ASLI",
    "it": "FATTURA/RICEVUTA ORIGINALE",
    "ja": "インボイス/レシートの原本",
    "key": "RL_Original_Invoice",
    "ko": "송장/영수증 원본",
    "nb": "ORIGINAL FAKTURA/KVITTERING",
    "nl": "ORIGINELE FACTUUR/BON",
    "pl": "ORYG. FAKTURA/PARAGON",
    "pt-BR": "FATURA/RECIBO ORIGINAL",
    "pt-PT": "FATURA/RECIBO ORIGINAL",
    "ru": "ОРИГИНАЛ СЧЕТА/ЧЕКА",
    "sv": "URSPRUNGLIG FAKTURA/KVITTO",
    "th": "ใบกำกับสินค้า/ใบเสร็จดั้งเดิม",
    "tr": "ORİJİNAL FATURA/MAKBUZ",
    "zh-Hans": "原始发票/收据",
    "zh-Hant": "發票/收據正本"
  },
  "RL_Other": {
    "ar": "غير ذلك ",
    "ca": "Altres",
    "comment": "Other ",
    "cs": "Jiné",
    "da": "Andet ",
    "de": "Sonstige",
    "el": "Άλλο ",
    "en": "Other ",
    "en-GB": "Other ",
    "en-XA": "[Öţĥéŕ  耐克รื่بُو]",
    "es-419": "Otro",
    "es-AR": "Otro",
    "es-ES": "Otros",
    "es-MX": "Otro ",
    "fr": "Autre",
    "hu": "Egyéb",
    "id": "Lainnya ",
    "it": "Altro",
    "ja": "その他",
    "key": "RL_Other",
    "ko": "기타",
    "nb": "Annet ",
    "nl": "Anders ",
    "pl": "Inne",
    "pt-BR": "Outros",
    "pt-PT": "Outro",
    "ru": "Другое",
    "sv": "Annat ",
    "th": "อื่นๆ",
    "tr": "Diğer",
    "zh-Hans": "其他",
    "zh-Hant": "其他"
  },
  "RL_OtherCredit": {
    "ar": "رصيد آخر",
    "ca": "Altres tipus de crèdit",
    "comment": "Other Credit",
    "cs": "Jiný zůstatek",
    "da": "Andet tilgodehavende",
    "de": "Andere Gutschrift",
    "el": "Άλλη πίστωση",
    "en": "Other Credit",
    "en-GB": "Other Credit",
    "en-XA": "[Öţĥéŕ Çŕéðîţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Otros créditos",
    "es-AR": "Otros créditos",
    "es-ES": "Otros sistemas de crédito",
    "es-MX": "Crédito",
    "fr": "Autre carte de crédit",
    "hu": "Egyéb jóváírás",
    "id": "Kredit Lainnya",
    "it": "Altra forma di credito",
    "ja": "その他のクレジット",
    "key": "RL_OtherCredit",
    "ko": "기타 크레딧",
    "nb": "Annen kreditt",
    "nl": "Ander tegoed",
    "pl": "Inny rodzaj uznania konta",
    "pt-BR": "Outro crédito",
    "pt-PT": "Outros tipos de pagamento a crédito",
    "ru": "Другой вид кредита",
    "sv": "Annat kreditkort",
    "th": "เครดิตอื่นๆ",
    "tr": "Diğer Kredi",
    "zh-Hans": "其他信用卡",
    "zh-Hant": "其他信用卡"
  },
  "RL_PANSeq": {
    "ar": "تسلسل رقم الحساب الأساسي",
    "ca": "PANSeq",
    "comment": "PAN sequence number",
    "cs": "PANSeq",
    "da": "PANSeq",
    "de": "PANSeq",
    "el": "PANSeq",
    "en": "PANSeq",
    "en-GB": "PANSeq",
    "en-XA": "[ÞÅÑŠéǫ 耐克รื่بُو]",
    "es-419": "PANSeq",
    "es-AR": "PANSeq",
    "es-ES": "PANSeq",
    "es-MX": "PANSeq",
    "fr": "PANSeq",
    "hu": "PANSeq",
    "id": "PANSeq",
    "it": "PANSeq",
    "ja": "PANSeq",
    "key": "RL_PANSeq",
    "ko": "PANSeq",
    "nb": "PANSeq",
    "nl": "PANSeq",
    "pl": "PANSeq",
    "pt-BR": "PANSeq",
    "pt-PT": "Sequência PAN",
    "ru": "PANSeq",
    "sv": "Kontokortsnummer (PAN)",
    "th": "ลำดับ PAN",
    "tr": "PANSeq",
    "zh-Hans": "PAN 序列号",
    "zh-Hant": "主帳號序列"
  },
  "RL_PackagePrice": {
    "ar": "سعر الحزمة",
    "ca": "Preu del paquet",
    "comment": "The price of a fixed price promotion. \nFor e.g  buy N for $60. Here the price of all the items in the package will total to 60. ",
    "cs": "Cena balíčku",
    "da": "Pakkepris",
    "de": "Paketpreis",
    "el": "Τιμή δέματος",
    "en": "Package Price",
    "en-GB": "Package Price",
    "en-XA": "[Þåçķåĝé Þŕîçé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Precio del paquete",
    "es-AR": "Precio del paquete",
    "es-ES": "Precio de paquete",
    "es-MX": "Precio del paquete",
    "fr": "Total de l'offre",
    "hu": "Csomag ára",
    "id": "Harga paket",
    "it": "Prezzo pacchetto",
    "ja": "一括価格",
    "key": "RL_PackagePrice",
    "ko": "패키지 가격",
    "nb": "Pakkepris",
    "nl": "Prijs van pakket",
    "pl": "Cena paczki",
    "pt-BR": "Preço do pacote",
    "pt-PT": "Preço do pacote",
    "ru": "Цена комплекта",
    "sv": "Paketpris",
    "th": "ราคาแพคเกจ",
    "tr": "Ambalaj Fiyatı",
    "zh-Hans": "组合价",
    "zh-Hant": "總價格"
  },
  "RL_Phone": {
    "ar": "الهاتف",
    "ca": "TELÉFONO",
    "comment": "Phone field in receipt",
    "cs": "Tel",
    "da": "Telefon",
    "de": "Telefon",
    "el": "Τηλέφωνο",
    "en": "Phone",
    "en-GB": "Phone",
    "en-XA": "[Þĥöñé 耐克รื่بُو]",
    "es-419": "Teléfono",
    "es-AR": "Teléfono",
    "es-ES": "Telf",
    "es-MX": "Teléfono",
    "fr": "Tél.",
    "hu": "Phone",
    "id": "Telepon",
    "it": "Telefono",
    "ja": "電話番号",
    "key": "RL_Phone",
    "ko": "Tel",
    "nb": "Telefonnummer",
    "nl": "Telefoonnummer",
    "pl": "Nr telefonu",
    "pt-BR": "Telefone",
    "pt-PT": "N.º de telefone",
    "ru": "Телефон",
    "sv": "Telefon",
    "th": "โทรศัพท์",
    "tr": "Telefon",
    "zh-Hans": "电话号码",
    "zh-Hant": "電話號碼"
  },
  "RL_PostalCode": {
    "ar": "الرمز البريدي",
    "ca": "Código Postal",
    "comment": "Postal code field",
    "cs": "PSC",
    "da": "Postnummer",
    "de": "Postleihzahl",
    "el": "Ταχυδρομικός κώδικας",
    "en": "Postal Code",
    "en-GB": "Postcode",
    "en-XA": "[Þöšţåļ Çöðé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Código postal",
    "es-AR": "Código postal",
    "es-ES": "Código Postal",
    "es-MX": "Código postal",
    "fr": "Postal Code",
    "hu": "Postal Code",
    "id": "Kode Pos",
    "it": "Codice Postale",
    "ja": "郵便番号",
    "key": "RL_PostalCode",
    "ko": "우편번호",
    "nb": "Postnummer",
    "nl": "Postcode",
    "pl": "Kod pocztowy",
    "pt-BR": "Código postal",
    "pt-PT": "Código postal",
    "ru": "Почтовый индекс",
    "sv": "Postnummer",
    "th": "รหัสไปรษณีย์",
    "tr": "Posta Kodu",
    "zh-Hans": "邮政编码",
    "zh-Hant": "郵遞區號"
  },
  "RL_PrefName": {
    "ar": "الاسم المفضل",
    "ca": "PrefName",
    "comment": "Preferred name",
    "cs": "PrefName",
    "da": "PrefName",
    "de": "PrefName",
    "el": "PrefName",
    "en": "PrefName",
    "en-GB": "PrefName",
    "en-XA": "[ÞŕéƒÑåɱé 耐克รื่بُو]",
    "es-419": "PrefName",
    "es-AR": "PrefName",
    "es-ES": "PrefName",
    "es-MX": "PrefName",
    "fr": "PrefName",
    "hu": "PrefName",
    "id": "PrefName",
    "it": "PrefName",
    "ja": "PrefName",
    "key": "RL_PrefName",
    "ko": "기본 설정 이름",
    "nb": "PrefName",
    "nl": "PrefName",
    "pl": "PrefName",
    "pt-BR": "PrefName",
    "pt-PT": "Nome preferido",
    "ru": "PrefName",
    "sv": "Önskat namn",
    "th": "ชื่อเรียก",
    "tr": "PrefName",
    "zh-Hans": "首选名称",
    "zh-Hant": "別名"
  },
  "RL_PriceOverride": {
    "ar": "تجاوُز السعر",
    "ca": "Reemplazo de Precio",
    "comment": "Price Override",
    "cs": "Zmena ceny",
    "da": "Pristilsidesættelse",
    "de": "Preis ändern",
    "el": "Παράκαμψη τιμής",
    "en": "Price Override",
    "en-GB": "Price Override",
    "en-XA": "[Þŕîçé Öṽéŕŕîðé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Anulación de precio",
    "es-AR": "Anulación de precio",
    "es-ES": "Reemplazo de Precio",
    "es-MX": "Anulación de precio",
    "fr": "Modif. man. du prix",
    "hu": "Price Override",
    "id": "Penggantian Harga",
    "it": "Modifica Prezzo",
    "ja": "価格上書き",
    "key": "RL_PriceOverride",
    "ko": "가격 재설정",
    "nb": "Prisoverstyring",
    "nl": "Verander prijs",
    "pl": "Zmiana ceny",
    "pt-BR": "Substituição de preço",
    "pt-PT": "Alterar Preço",
    "ru": "Переопределение цены",
    "sv": "Ändra Pris",
    "th": "เขียนทับราคา",
    "tr": "Fiyat Geçersiz Kılma",
    "zh-Hans": "价格重整",
    "zh-Hant": "價格替換"
  },
  "RL_PriceOvr": {
    "ar": "تجاوُز السعر على المنتج رقم",
    "ca": "Cambio de precio de Art. No",
    "comment": "price ovverride applied on item",
    "cs": "Zmena ceny u c. polozky",
    "da": "Overskriv vare",
    "de": "Preisüberschreibung für Item Nr",
    "el": "Παράκαμψη τιμής σε αρ. είδους",
    "en": "Price Override on Item No",
    "en-GB": "Price Override on Item No.",
    "en-XA": "[Þŕîçé Öṽéŕŕîðé öñ Îţéɱ Ñö åƀПβシ耐克บั้ รื่بُو]",
    "es-419": "Anulación de precio en artículo N.º",
    "es-AR": "Anulación de precio en artículo N.º",
    "es-ES": "Cambio de precio de Art. No",
    "es-MX": "Anulación de precio en artículo N.º",
    "fr": "Modif. Prix sur Article N°",
    "hu": "Price Override on Item No",
    "id": "Penggantian Harga pada Nomor Item",
    "it": "Modifcare prezzo articolo No",
    "ja": "アイテム番号",
    "key": "RL_PriceOvr",
    "ko": "가격 재설정 품목 번호",
    "nb": "Prisoverstyring på varenummer",
    "nl": "Prijswiziging op artikel nr",
    "pl": "Nadpisanie ceny produktu nr",
    "pt-BR": "Substituição de preço no número do item",
    "pt-PT": "Alterar Preco no Item Nr",
    "ru": "Переопределение цены товара №",
    "sv": "Prisändring på Art.nr",
    "th": "เขียนทับราคาของรายการหมายเลข",
    "tr": "Ürün No'da Fiyatı Geçersiz Kılma",
    "zh-Hans": "进行价格替换的商品编号",
    "zh-Hant": "商品編號價格替換"
  },
  "RL_Print": {
    "ar": "طباعة",
    "ca": "Imprimeix",
    "comment": "Print the receipt ",
    "cs": "Tisk",
    "da": "Udskriv",
    "de": "Drucken",
    "el": "Εκτύπωση",
    "en": "Print",
    "en-GB": "Print",
    "en-XA": "[Þŕîñţ 耐克รื่بُو]",
    "es-419": "Imprimir",
    "es-AR": "Imprimir",
    "es-ES": "Imprimir",
    "es-MX": "Imprimir",
    "fr": "Imprimer",
    "hu": "Nyomtatás",
    "id": "Cetak",
    "it": "Stampa",
    "ja": "印刷",
    "key": "RL_Print",
    "ko": "프린트",
    "nb": "Skriv ut",
    "nl": "Printen",
    "pl": "Drukuj",
    "pt-BR": "Imprimir",
    "pt-PT": "Imprimir",
    "ru": "Печать",
    "sv": "Skriv ut",
    "th": "พิมพ์",
    "tr": "Yazdır",
    "zh-Hans": "打印",
    "zh-Hant": "列印"
  },
  "RL_PrintEJ": {
    "ar": "طباعة EJ",
    "ca": "Imprimeix l'EJ",
    "comment": "Print ",
    "cs": "Vytisknout EJ",
    "da": "Udskriv EJ",
    "de": "EJ drucken",
    "el": "Εκτύπωση EJ",
    "en": "Print EJ",
    "en-GB": "Print EJ",
    "en-XA": "[Þŕîñţ ÉĴ 耐克รื่بُو]",
    "es-419": "Imprimir EJ",
    "es-AR": "Imprimir EJ",
    "es-ES": "Imprimir EJ",
    "es-MX": "Imprimir EJ",
    "fr": "Imprimer EJ",
    "hu": "Elektronikus napló nyomtatása",
    "id": "Cetak EJ",
    "it": "Stampa EJ",
    "ja": "EJを印刷",
    "key": "RL_PrintEJ",
    "ko": "EJ 인쇄",
    "nb": "Skriv ut EJ",
    "nl": "Print EJ",
    "pl": "Drukuj EJ",
    "pt-BR": "Imprimir EJ",
    "pt-PT": "Imprimir registo eletrónico",
    "ru": "Печать журнала",
    "sv": "Skriv ut EJ",
    "th": "พิมพ์ EJ",
    "tr": "EJ'yi Yazdır",
    "zh-Hans": "打印 EJ",
    "zh-Hant": "列印 EJ"
  },
  "RL_Proceed": {
    "ar": "متابعة",
    "ca": "Continua",
    "comment": "Prompting user to Proceed with an action in the UI. ",
    "cs": "Pokračovat",
    "da": "Fortsæt",
    "de": "Fortfahren",
    "el": "Συνέχεια",
    "en": "Proceed",
    "en-GB": "Proceed",
    "en-XA": "[Þŕöçééð 耐克รื่بُو]",
    "es-419": "Continuar",
    "es-AR": "Continuar",
    "es-ES": "Continuar",
    "es-MX": "Continuar",
    "fr": "Continuer",
    "hu": "Tovább",
    "id": "Lanjutkan",
    "it": "Continua",
    "ja": "続ける",
    "key": "RL_Proceed",
    "ko": "진행",
    "nb": "Fortsett",
    "nl": "Verder",
    "pl": "Kontynuuj",
    "pt-BR": "Continuar",
    "pt-PT": "Prosseguir",
    "ru": "Продолжить",
    "sv": "Fortsätt",
    "th": "ดำเนินการต่อ",
    "tr": "Devam Et",
    "zh-Hans": "继续",
    "zh-Hant": "繼續"
  },
  "RL_Promotion": {
    "ar": "العرض الترويجي",
    "ca": "Promoció",
    "comment": "Identify promotions in EJ",
    "cs": "Promoakce",
    "da": "Kampagne",
    "de": "Angebot",
    "el": "Προώθηση",
    "en": "Promotion",
    "en-GB": "Promotion",
    "en-XA": "[Þŕöɱöţîöñ 耐克รื่بُو]",
    "es-419": "Promoción",
    "es-AR": "Promoción",
    "es-ES": "Promoción",
    "es-MX": "Promoción",
    "fr": "Promotion",
    "hu": "Promóció",
    "id": "Promosi",
    "it": "Promozione",
    "ja": "プロモーション",
    "key": "RL_Promotion",
    "ko": "프로모션",
    "nb": "Kampanje",
    "nl": "Promotie",
    "pl": "Promocja",
    "pt-BR": "Promoção",
    "pt-PT": "Promoção",
    "ru": "Акция",
    "sv": "Kampanj",
    "th": "โปรโมชัน",
    "tr": "Promosyon",
    "zh-Hans": "促销",
    "zh-Hant": "促銷"
  },
  "RL_PromotionBlockEnd": {
    "ar": "نهاية العرض الترويجي للمجموعة",
    "ca": "Promoció de fi de grup",
    "comment": "End of group promotion block",
    "cs": "Konec skupiny promoakcí",
    "da": "Gruppekampagne er overstået",
    "de": "Ende des Gruppenangebots",
    "el": "Τερματισμός ομαδικής προσφοράς",
    "en": "End of Group Promotion",
    "en-GB": "End of Group Promotion",
    "en-XA": "[Éñð öƒ Ĝŕöûþ Þŕöɱöţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Finalización de la promoción grupal",
    "es-AR": "Finalización de la promoción grupal",
    "es-ES": "Promoción de final de grupo",
    "es-MX": "Promoción de fin de grupo",
    "fr": "Promotion fin de groupe",
    "hu": "Csoportos promóció vége",
    "id": "Akhir Promosi Grup",
    "it": "Promozione gruppo terminata",
    "ja": "グループプロモーションはここまで",
    "key": "RL_PromotionBlockEnd",
    "ko": "그룹 프로모션 종료",
    "nb": "Slutt på kampanjegruppe",
    "nl": "Einde van groepspromotie",
    "pl": "Koniec promocji grupowej",
    "pt-BR": "Fim da promoção em grupo",
    "pt-PT": "Promoção de fim de grupo",
    "ru": "Завершение общей акции",
    "sv": "Slut för gruppkampanj",
    "th": "สิ้นสุดโปรโมชันแบบกลุ่ม",
    "tr": "Grup Promosyonu Sonu",
    "zh-Hans": "系列促销结束",
    "zh-Hant": "團體促銷結束"
  },
  "RL_PromotionName": {
    "ar": "اسم العرض الترويجي",
    "ca": "Nombre de Promo",
    "comment": "Promotion name",
    "cs": "Název promoakce",
    "da": "Navn på reklame",
    "de": "Bezeichnung der Promotion",
    "el": "Όνομα προσφοράς",
    "en": "Promotion Name",
    "en-GB": "Promotion Name",
    "en-XA": "[Þŕöɱöţîöñ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre de la promoción",
    "es-AR": "Nombre de la promoción",
    "es-ES": "Nombre de Promo",
    "es-MX": "Nombre de la promoción",
    "fr": "Nom. Promo",
    "hu": "Promotion Name",
    "id": "Nama Promosi",
    "it": "Nome promozione",
    "ja": "プロモーション名 ",
    "key": "RL_PromotionName",
    "ko": "프로모션 이름",
    "nb": "Kampanjenavn",
    "nl": "Naam promotie",
    "pl": "Nazwa promocji",
    "pt-BR": "Nome da promoção",
    "pt-PT": "Nome da promoção",
    "ru": "Название промоакции",
    "sv": "Kampanjnamn",
    "th": "ชื่อโปรโมชัน",
    "tr": "Promosyon Adı",
    "zh-Hans": "促销名称",
    "zh-Hant": "促銷名稱"
  },
  "RL_PublicKey": {
    "ar": "مفتاح عام",
    "ca": "Clau pública",
    "comment": "Public Key for TSE signature on the Germany receipts",
    "cs": "PublicKey",
    "da": "OffentligNøgle",
    "de": "Öffentlicher Schlüssel",
    "el": "Δημόσιο κλειδί",
    "en": "PublicKey",
    "en-GB": "PublicKey",
    "en-XA": "[ÞûƀļîçĶéý 耐克รื่بُو]",
    "es-419": "PublicKey",
    "es-AR": "Clave pública",
    "es-ES": "Clave pública",
    "es-MX": "PublicKey",
    "fr": "PublicKey",
    "hu": "Nyilvános kulcs",
    "id": "PublicKey",
    "it": "PublicKey",
    "ja": "公開キー",
    "key": "RL_PublicKey",
    "ko": "퍼블릭키",
    "nb": "PublicKey",
    "nl": "PublicKey",
    "pl": "Klucz publiczny",
    "pt-BR": "PublicKey",
    "pt-PT": "Chave pública",
    "ru": "Открытый ключ",
    "sv": "Offentlig nyckel",
    "th": "PublicKey",
    "tr": "Ortak Anahtar",
    "zh-Hans": "公共密钥",
    "zh-Hant": "公開金鑰"
  },
  "RL_PurchaserName": {
    "ar": "اسم المشتري",
    "ca": "Nombre del Comprador",
    "comment": "Purchaser name",
    "cs": "Jméno zákazníka",
    "da": "Købers navn",
    "de": "Kundenname / Käufername",
    "el": "Όνομα αγοραστή",
    "en": "Purchaser Name",
    "en-GB": "Purchaser Name",
    "en-XA": "[Þûŕçĥåšéŕ Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del comprador",
    "es-AR": "Nombre del comprador",
    "es-ES": "Nombre del Comprador",
    "es-MX": "Nombre del comprador",
    "fr": "Nom de l’acheteur",
    "hu": "Purchaser Name",
    "id": "Nama Pembeli",
    "it": "Nome acquirente",
    "ja": "お客様名",
    "key": "RL_PurchaserName",
    "ko": "구매자 이름",
    "nb": "Kjøpers navn",
    "nl": "Naam Koper",
    "pl": "Purchaser Name",
    "pt-BR": "Nome do comprador",
    "pt-PT": "Nome do comprador",
    "ru": "Имя покупателя",
    "sv": "Kundnamn",
    "th": "ชื่อผู้ซื้อ",
    "tr": "Alıcı Adı",
    "zh-Hans": "领货人",
    "zh-Hant": "購買者姓名"
  },
  "RL_QRCode": {
    "ar": "رمز QR",
    "ca": "Codi QR",
    "comment": "QR Code on the receipt.",
    "cs": "QR kód",
    "da": "QR-kode",
    "de": "QR-Code",
    "el": "Κωδικός QR",
    "en": "QR Code",
    "en-GB": "QR code",
    "en-XA": "[ǪŔ Çöðé 耐克รื่بُو]",
    "es-419": "Código QR",
    "es-AR": "Código QR",
    "es-ES": "Código QR",
    "es-MX": "Código QR",
    "fr": "Code QR",
    "hu": "QR-kód",
    "id": "Kode QR",
    "it": "Codice QR",
    "ja": "QRコード",
    "key": "RL_QRCode",
    "ko": "QR 코드",
    "nb": "QR-kode",
    "nl": "QR-code",
    "pl": "Kod QR",
    "pt-BR": "Código QR",
    "pt-PT": "Código QR",
    "ru": "QR-код",
    "sv": "QR-kod",
    "th": "คิวอาร์โค้ด",
    "tr": "QR Kodu",
    "zh-Hans": "二维码",
    "zh-Hant": "QR 碼"
  },
  "RL_Quantity": {
    "ar": "الكمية",
    "ca": "Quantitat",
    "comment": "Quantity of items purchased",
    "cs": "Počet",
    "da": "Antal",
    "de": "Menge",
    "el": "Ποσότητα",
    "en": "Quantity",
    "en-GB": "Quantity",
    "en-XA": "[Ǫûåñţîţý 耐克รื่بُو]",
    "es-419": "Cantidad",
    "es-AR": "Cantidad",
    "es-ES": "Cantidad",
    "es-MX": "Cantidad",
    "fr": "Quantité",
    "hu": "Mennyiség",
    "id": "Kuantitas",
    "it": "Quantità",
    "ja": "数量",
    "key": "RL_Quantity",
    "ko": "수량",
    "nb": "Antall",
    "nl": "Aantal",
    "pl": "Ilość",
    "pt-BR": "Quantidade",
    "pt-PT": "Quantidade",
    "ru": "Количество",
    "sv": "Antal",
    "th": "จำนวน",
    "tr": "Adet",
    "zh-Hans": "数量",
    "zh-Hant": "數量"
  },
  "RL_REPRINT": {
    "ar": "إعادة الطباعة",
    "ca": "REIMPRIM",
    "comment": "Header to identify the Reprinted receipt",
    "cs": "PŘETISK",
    "da": "UDSKRIV IGEN",
    "de": "HERPRINT",
    "el": "ΕΠΑΝΕΚΤΥΠΩΣΗ",
    "en": "REPRINT",
    "en-GB": "REPRINT",
    "en-XA": "[ŔÉÞŔÎÑŢ 耐克รื่بُو]",
    "es-419": "VOLVER A IMPRIMIR",
    "es-AR": "VOLVER A IMPRIMIR",
    "es-ES": "REIMPRIM.",
    "es-MX": "VOLVER A IMPRIMIR",
    "fr": "REPRINT",
    "hu": "REPRINT",
    "id": "CETAK ULANG",
    "it": "RISTAMPA",
    "ja": "REPRINT",
    "key": "RL_REPRINT",
    "ko": "재인쇄",
    "nb": "OPPTRYKK",
    "nl": "OPNIEUW AFDRUKKEN",
    "pl": "REPRINT",
    "pt-BR": "REIMPRESSÃO",
    "pt-PT": "REIMPRIMIR",
    "ru": "REPRINT",
    "sv": "REPRINT",
    "th": "พิมพ์อีกครั้ง",
    "tr": "YENİDEN YAZDIR",
    "zh-Hans": "重新打印",
    "zh-Hant": "重新列印"
  },
  "RL_Reason": {
    "ar": "السبب",
    "ca": "Motiu",
    "comment": "Reason",
    "cs": "Důvod",
    "da": "Begrundelse",
    "de": "Grund",
    "el": "Αιτία",
    "en": "Reason",
    "en-GB": "Reason",
    "en-XA": "[Ŕéåšöñ 耐克รื่بُو]",
    "es-419": "Motivo",
    "es-AR": "Motivo",
    "es-ES": "Motivo",
    "es-MX": "Motivo",
    "fr": "Motif",
    "hu": "Indoklás",
    "id": "Alasan",
    "it": "Motivo",
    "ja": "理由",
    "key": "RL_Reason",
    "ko": "사유",
    "nb": "Årsak",
    "nl": "Reden",
    "pl": "Powód",
    "pt-BR": "Motivo",
    "pt-PT": "Motivo",
    "ru": "Причина",
    "sv": "Skäl",
    "th": "เหตุผล",
    "tr": "Neden",
    "zh-Hans": "原因",
    "zh-Hant": "原因"
  },
  "RL_Reason3": {
    "ar": "السبب:",
    "ca": "Motivo:",
    "comment": "Reason:",
    "cs": "Duvod:",
    "da": "Årsag:",
    "de": "Grund:",
    "el": "Αιτία:",
    "en": "Reason:",
    "en-GB": "Reason:",
    "en-XA": "[Ŕéåšöñ∶ 耐克รื่بُو]",
    "es-419": "Motivo:",
    "es-AR": "Motivo:",
    "es-ES": "Motivo:",
    "es-MX": "Motivo:",
    "fr": "Motif :",
    "hu": "Reason:",
    "id": "Alasan:",
    "it": "ragione:",
    "ja": "理由:",
    "key": "RL_Reason3",
    "ko": "사유:",
    "nb": "Årsak:",
    "nl": "Reden:",
    "pl": "Powód:",
    "pt-BR": "Motivo:",
    "pt-PT": "Motivo:",
    "ru": "Причина:",
    "sv": "Orsak:",
    "th": "เหตุผล",
    "tr": "Neden:",
    "zh-Hans": "原因:",
    "zh-Hant": "原因:"
  },
  "RL_ReferenceNumber": {
    "ar": "الرقم المرجعي ",
    "ca": "Número de referència ",
    "comment": "Reference number or the fiscal document number printed on the Austria receipts. ",
    "cs": "Referenční číslo ",
    "da": "Referencenummer ",
    "de": "Referenznummer ",
    "el": "Αριθμός αναφοράς ",
    "en": "Reference Number ",
    "en-GB": "Reference number ",
    "en-XA": "[Ŕéƒéŕéñçé Ñûɱƀéŕ  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de referencia ",
    "es-AR": "Número de referencia ",
    "es-ES": "Número de referencia ",
    "es-MX": "Número de referencia ",
    "fr": "Numéro de référence ",
    "hu": "Hivatkozási szám ",
    "id": "Nomor Referensi ",
    "it": "Numero di riferimento ",
    "ja": "参照番号 ",
    "key": "RL_ReferenceNumber",
    "ko": "참조 번호 ",
    "nb": "Referansenummer ",
    "nl": "Referentienummer ",
    "pl": "Numer referencyjny ",
    "pt-BR": "Número de referência ",
    "pt-PT": "Número de referência ",
    "ru": "Номер для отслеживания ",
    "sv": "Referensnummer ",
    "th": "หมายเลขอ้างอิง ",
    "tr": "Referans Numarası ",
    "zh-Hans": "参考号 ",
    "zh-Hant": "參考編號 "
  },
  "RL_Refund": {
    "ar": "استرداد الأموال",
    "ca": "Reembolso",
    "comment": "Refund",
    "cs": " Vrácení",
    "da": "Refundering",
    "de": "Vergütung",
    "el": "Επιστροφή χρημάτων",
    "en": "Refund",
    "en-GB": "Refund",
    "en-XA": "[Ŕéƒûñð 耐克รื่بُو]",
    "es-419": "Reembolso",
    "es-AR": "Reembolso",
    "es-ES": "Reembolso",
    "es-MX": "Reembolso",
    "fr": "Remboursement",
    "hu": "Refund",
    "id": "Pengembalian Dana",
    "it": "Rimborso",
    "ja": "返金",
    "key": "RL_Refund",
    "ko": "환불",
    "nb": "Refusjon",
    "nl": "Terugbetaling",
    "pl": "Zwrot.",
    "pt-BR": "Reembolso",
    "pt-PT": "Reembolsar",
    "ru": "Возмещение",
    "sv": "Återbet.",
    "th": "การคืนเงิน",
    "tr": "Ödeme İadesi",
    "zh-Hans": "退款",
    "zh-Hant": "退款"
  },
  "RL_Reg": {
    "ar": "التسجيل",
    "ca": "Caja.",
    "comment": "Abbreviation for Register number",
    "cs": "Pokladna",
    "da": "Kasse",
    "de": "RegVerz",
    "el": "Αρ. ταμείου",
    "en": "Reg",
    "en-GB": "Reg",
    "en-XA": "[Ŕéĝ 耐克รื่بُو]",
    "es-419": "Caja",
    "es-AR": "Caja",
    "es-ES": "Caja",
    "es-MX": "Caja",
    "fr": "Caisse",
    "hu": "Kassza",
    "id": "Reg",
    "it": "Cassa",
    "ja": "レジ",
    "key": "RL_Reg",
    "ko": "POS No",
    "nb": "Kasse",
    "nl": "Term",
    "pl": "Kasa",
    "pt-BR": "Reg",
    "pt-PT": "Caixa registadora",
    "ru": "Касса",
    "sv": "kassa",
    "th": "เลขทะเบียน",
    "tr": "Kasa",
    "zh-Hans": "收银机编号",
    "zh-Hant": "收銀機編號"
  },
  "RL_Register": {
    "ar": "التسجيل",
    "ca": "Caixa",
    "comment": "Regsiter being used",
    "cs": "Pokladna",
    "da": "Kasse",
    "de": "Kasse",
    "el": "Ταμείο",
    "en": "Register",
    "en-GB": "Register",
    "en-XA": "[Ŕéĝîšţéŕ 耐克รื่بُو]",
    "es-419": "Caja",
    "es-AR": "Caja",
    "es-ES": "Caja ",
    "es-MX": "Caja",
    "fr": "Caisse",
    "hu": "Kassza",
    "id": "Daftar",
    "it": "Cassa",
    "ja": "レジスター",
    "key": "RL_Register",
    "ko": "POS No",
    "nb": "Kasse",
    "nl": "Kassa",
    "pl": "Kasa",
    "pt-BR": "Cadastrar-se",
    "pt-PT": "Caixa registadora",
    "ru": "Касса",
    "sv": "Registrera mig",
    "th": "ลงทะเบียน",
    "tr": "Kaydol",
    "zh-Hans": "收银机",
    "zh-Hant": "收銀機"
  },
  "RL_Requestedby": {
    "ar": "تم الطلب مِن قِبل",
    "ca": "Solicitado por",
    "comment": "Field in receipt indicates the person requested ",
    "cs": "Vyžádáno kým",
    "da": "Påkrævet af",
    "de": " angefordert",
    "el": "Ζητήθηκε από",
    "en": "Requested by",
    "en-GB": "Requested by",
    "en-XA": "[Ŕéǫûéšţéð ƀý Пβシ耐克บั้ รื่بُو]",
    "es-419": "Solicitado por",
    "es-AR": "Solicitado por",
    "es-ES": "Solicitado por",
    "es-MX": "Solicitado por",
    "fr": "Demandé par",
    "hu": "Requested by",
    "id": "Diminta oleh",
    "it": "Richiesto da",
    "ja": "本社担当者名",
    "key": "RL_Requestedby",
    "ko": "요청인",
    "nb": "Forespurt av",
    "nl": "Aangevraagd door",
    "pl": "Requested by",
    "pt-BR": "Solicitado por",
    "pt-PT": "Pedido por",
    "ru": "Кем запрошено",
    "sv": "Begärd av",
    "th": "ร้องขอโดย",
    "tr": "Talep eden",
    "zh-Hans": "申请人",
    "zh-Hant": "索取人"
  },
  "RL_ResetSearch": {
    "ar": "إعادة ضبط البحث ",
    "ca": "Restableix la cerca",
    "comment": "Reset the transaction search",
    "cs": "Obnovit hledání",
    "da": "Nulstil søgning ",
    "de": "Suche zurücksetzen",
    "el": "Επαναφορά αναζήτησης ",
    "en": "Reset Search ",
    "en-GB": "Reset Search ",
    "en-XA": "[Ŕéšéţ Šéåŕçĥ  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Restablecer búsqueda",
    "es-AR": "Restablecer búsqueda",
    "es-ES": "Restablecer búsqueda",
    "es-MX": "Restablecer búsqueda ",
    "fr": "Réinitialiser la recherche",
    "hu": "Keresés visszaállítása",
    "id": "Reset Pencarian",
    "it": "Reimposta ricerca",
    "ja": "検索条件をリセット",
    "key": "RL_ResetSearch",
    "ko": "검색 초기화",
    "nb": "Tilbakestill søk ",
    "nl": "Reset zoekopdracht ",
    "pl": "Resetuj wyszukiwanie",
    "pt-BR": "Redefinir pesquisa",
    "pt-PT": "Repor a pesquisa",
    "ru": "Сбросить параметры поиска",
    "sv": "Återställ sökning",
    "th": "รีเซ็ตการค้นหา",
    "tr": "Aramayı Sıfırla",
    "zh-Hans": "重置搜索",
    "zh-Hant": "重設搜尋"
  },
  "RL_Restitution": {
    "ar": "استرداد",
    "ca": "Compensación",
    "comment": "Restitution",
    "cs": "Obnovení",
    "da": "Kompensation",
    "de": "Entschädigung",
    "el": "Αποκατάσταση",
    "en": "Restitution",
    "en-GB": "Restitution",
    "en-XA": "[Ŕéšţîţûţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Restitución",
    "es-AR": "Restitución",
    "es-ES": "Compensación",
    "es-MX": "Restitución",
    "fr": "Restitution",
    "hu": "Restitution",
    "id": "Restitusi",
    "it": "Restituzione",
    "ja": "損害賠償",
    "key": "RL_Restitution",
    "ko": "반환",
    "nb": "Kompensasjon",
    "nl": "Teruggave",
    "pl": "Restytucja",
    "pt-BR": "Restituição",
    "pt-PT": "Restituição",
    "ru": "Возмещение",
    "sv": "Återbetalning",
    "th": "การชดใช้ความเสียหาย",
    "tr": "İade Etme",
    "zh-Hans": "恢复",
    "zh-Hant": "賠償"
  },
  "RL_Resume": {
    "ar": "استئناف",
    "ca": "Represes",
    "comment": "Resumed transactions",
    "cs": "Obnovit",
    "da": "Genoptag",
    "de": "Fortsetzen",
    "el": "Συνεχισμένες",
    "en": "Resume",
    "en-GB": "Resume",
    "en-XA": "[Ŕéšûɱé 耐克รื่بُو]",
    "es-419": "Reanudada",
    "es-AR": "Reanudar",
    "es-ES": "Reanudado",
    "es-MX": "Reanudar",
    "fr": "Reprendre",
    "hu": "Folytatás",
    "id": "Lanjutkan",
    "it": "Riprendi",
    "ja": "再開",
    "key": "RL_Resume",
    "ko": "재개",
    "nb": "Fortsett",
    "nl": "Hervat",
    "pl": "Wznów",
    "pt-BR": "Retomar",
    "pt-PT": "Retomada",
    "ru": "Возобновить",
    "sv": "Återuppta",
    "th": "ดำเนินการต่อ",
    "tr": "Sürdür",
    "zh-Hans": "已恢复",
    "zh-Hant": "繼續"
  },
  "RL_ResumeTransaction": {
    "ar": "استئناف المعاملة",
    "ca": "Reprèn la transacció",
    "comment": "Resume Transaction",
    "cs": "Obnovit transakci",
    "da": "Genoptag transaktion",
    "de": "Transaktion fortsetzen",
    "el": "Συνέχιση συναλλαγής",
    "en": "Resume Transaction",
    "en-GB": "Resume Transaction",
    "en-XA": "[Ŕéšûɱé Ţŕåñšåçţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Retomar la transacción",
    "es-AR": "Reanudar transacción",
    "es-ES": "Reanudar transacción",
    "es-MX": "Reanudar transacción",
    "fr": "Reprendre la transaction",
    "hu": "Tranzakció folytatása",
    "id": "Lanjutkan Transaksi",
    "it": "Riprendi transazione",
    "ja": "取引を再開",
    "key": "RL_ResumeTransaction",
    "ko": "거래 재개",
    "nb": "Fortsett transaksjon",
    "nl": "Hervat transactie",
    "pl": "Wznów transakcję",
    "pt-BR": "Retomar transação",
    "pt-PT": "Retomar transação",
    "ru": "Возобновить транзакцию",
    "sv": "Återuppta transaktion",
    "th": "ดำเนินธุรกรรมต่อ",
    "tr": "İşlemi Sürdür",
    "zh-Hans": "恢复交易",
    "zh-Hant": "繼續處理交易"
  },
  "RL_ReturnWOReceipt": {
    "ar": "إرجاع من دون إيصال",
    "ca": "Devolución Sin Tiquet",
    "comment": "RETURN WITHOUT RECEIPT",
    "cs": "VRÁCENÍ BEZ DOKLADU",
    "da": "RETURNERING UDEN KVITTERING",
    "de": "Rückgabe ohne Quittung",
    "el": "ΕΠΙΣΤΡΟΦΗ ΧΩΡΙΣ ΑΠΟΔΕΙΞΗ",
    "en": "RETURN WITHOUT RECEIPT",
    "en-GB": "RETURN WITHOUT RECEIPT",
    "en-XA": "[ŔÉŢÛŔÑ ŴÎŢĤÖÛŢ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "DEVOLUCIÓN SIN RECIBO",
    "es-AR": "DEVOLUCIÓN SIN RECIBO",
    "es-ES": "Devolución Sin Tiquet",
    "es-MX": "DEVOLUCIÓN SIN RECIBO",
    "fr": "RETOUR SANS RECU",
    "hu": "RETURN WITHOUT RECEIPT",
    "id": "PENGEMBALIAN TANPA TANDA TERIMA",
    "it": "RESO SENZA RICEVUTA",
    "ja": "レシートのない返品",
    "key": "RL_ReturnWOReceipt",
    "ko": "영수증 없는 반품",
    "nb": "RETUR UTEN KVITTERING",
    "nl": "RETOUR ZONDER BON",
    "pl": "ZWROT BEZ PARAGONU",
    "pt-BR": "DEVOLUÇÃO SEM RECIBO",
    "pt-PT": "DEVOLUÇÃO SEM RECIBO",
    "ru": "ВОЗВРАТ БЕЗ ЧЕКА",
    "sv": "CRETUR UTAN KVITTO",
    "th": "คืนสินค้าโดยไม่มีใบเสร็จ",
    "tr": "MAKBUZ OLMADAN İADE",
    "zh-Hans": "无收据退货",
    "zh-Hant": "沒有收據的退貨"
  },
  "RL_ReturnedReceipt": {
    "ar": "إيصال المنتجات التي تمت إعادتها",
    "ca": "Tiquet Artículos Devueltos",
    "comment": "Items returned in receipted transations",
    "cs": "Vrácené prijaté položky",
    "da": "Returneret bon varer",
    "de": "Beleg umgetauschter Artikel",
    "el": "Προϊόντα επιστροφής με απόδειξη",
    "en": "Returned Receipt Items",
    "en-GB": "Returned Receipt Items",
    "en-XA": "[Ŕéţûŕñéð Ŕéçéîþţ Îţéɱš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Artículos recibidos devueltos",
    "es-AR": "Artículos recibidos devueltos",
    "es-ES": "Tiquet Artículos Devueltos",
    "es-MX": "Artículos recibidos devueltos",
    "fr": "Reçu art.s retournés",
    "hu": "Returned Receipt Items",
    "id": "Item di Tanda Terima yang Dikembalikan",
    "it": "Ricevuta Articoli resi",
    "ja": "返品商品",
    "key": "RL_ReturnedReceipt",
    "ko": "반품 제품",
    "nb": "Kvittering for returnerte varer",
    "nl": "Bon geretourneerde artikelen",
    "pl": "Zwrócone przedmioty (paragon)",
    "pt-BR": "Itens de Recebimento devolvidos",
    "pt-PT": "Artigos em recibo devolvidos",
    "ru": "Товары, возвращенные по накладной",
    "sv": "Returkvitto",
    "th": "รายการสินค้าส่งคืนเป็นใบเสร็จ",
    "tr": "İade Edilen Makbuz Ürünleri",
    "zh-Hans": "已退货商品收据",
    "zh-Hant": "已退貨品項收據"
  },
  "RL_ReusableToteL": {
    "ar": "كيس قابل لإعادة الاستخدام بمقاس كبير",
    "ca": "Bossa de mà reutilitzable (gran)",
    "comment": "Reusable Tote bag of size L",
    "cs": "Opakovaně použitelná taška L",
    "da": "Genbrugelig mulepose L",
    "de": "Wiederverwendbare Tragetasche L",
    "el": "Επαναχρησιμοποιήσιμη τσάντα ώμου σε μέγεθος Large",
    "en": "Reusable Tote L",
    "en-GB": "Reusable Tote L",
    "en-XA": "[Ŕéûšåƀļé Ţöţé Ļ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Bolsa tipo tote reutilizable L",
    "es-AR": "Bolso de mano reutilizable L",
    "es-ES": "Tote bag reutilizable de talla L",
    "es-MX": "Bolsa tipo tote reutilizable grande",
    "fr": "Cabas réutilisable taille L",
    "hu": "Újrahasználható bevásárlótáska, L-es",
    "id": "Reusable Tote L",
    "it": "Borsa tote riutilizzabile L",
    "ja": "エコートバッグ（L）",
    "key": "RL_ReusableToteL",
    "ko": "리유저블 토트백 L",
    "nb": "Gjenbrukbart bærenett L",
    "nl": "Herbruikbare tas L",
    "pl": "Torba wielokrotnego użytku (rozmiar L)",
    "pt-BR": "Sacola reutilizável G",
    "pt-PT": "Saco reutilizável L",
    "ru": "Многоразовая сумка-тоут (L)",
    "sv": "Tote bag som kan återanvändas (L)",
    "th": "กระเป๋าสะพายแบบใช้ซ้ำได้ ไซส์ L",
    "tr": "Yeniden Kullanılabilir El Çantası L",
    "zh-Hans": "可重复使用托特包 L 码",
    "zh-Hant": "可重複使用托特包，尺寸 L"
  },
  "RL_ReusableToteM": {
    "ar": "كيس قابل لإعادة الاستخدام بمقاس متوسط",
    "ca": "Bossa de mà reutilitzable (mitjana)",
    "comment": "Reusable tote bag of size Medium",
    "cs": "Opakovaně použitelná taška M",
    "da": "Genbrugelig mulepose M",
    "de": "Wiederverwendbare Tragetasche M",
    "el": "Επαναχρησιμοποιήσιμη τσάντα ώμου σε μέγεθος Medium",
    "en": "Reusable Tote M",
    "en-GB": "Reusable Tote M",
    "en-XA": "[Ŕéûšåƀļé Ţöţé Ṁ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Bolsa tipo tote reutilizable M",
    "es-AR": "Bolso de mano reutilizable M",
    "es-ES": "Tote bag reutilizable de talla M",
    "es-MX": "Bolsa tipo tote reutilizable mediana",
    "fr": "Cabas réutilisable taille M",
    "hu": "Újrahasználható bevásárlótáska, M-es",
    "id": "Reusable Tote M",
    "it": "Borsa tote riutilizzabile M",
    "ja": "エコトートバッグ（M）",
    "key": "RL_ReusableToteM",
    "ko": "리유저블 토트백 M",
    "nb": "Gjenbrukbart bærenett M",
    "nl": "Herbruikbare tas M",
    "pl": "Torba wielokrotnego użytku (rozmiar M)",
    "pt-BR": "Sacola reutilizável M",
    "pt-PT": "Saco reutilizável M",
    "ru": "Многоразовая сумка-тоут (M)",
    "sv": "Tote bag som kan återanvändas (M)",
    "th": "กระเป๋าสะพายแบบใช้ซ้ำได้ ไซส์ M",
    "tr": "Yeniden Kullanılabilir El Çantası M",
    "zh-Hans": "可重复使用托特包 M 码",
    "zh-Hant": "可重複使用托特包，尺寸 M"
  },
  "RL_ReusableToteS": {
    "ar": "كيس قابل لإعادة الاستخدام بمقاس صغير",
    "ca": "Bossa de mà reutilitzable (petita)",
    "comment": "Reusable Tote Bag of size Small",
    "cs": "Opakovaně použitelná taška S",
    "da": "Genbrugelig mulepose S",
    "de": "Wiederverwendbare Tragetasche S",
    "el": "Επαναχρησιμοποιήσιμη τσάντα ώμου σε μέγεθος Small",
    "en": "Reusable Tote S",
    "en-GB": "Reusable Tote S",
    "en-XA": "[Ŕéûšåƀļé Ţöţé Š Пβシ耐克บั้ รื่بُو]",
    "es-419": "Bolsa tipo tote reutilizable S",
    "es-AR": "Bolso de mano reutilizable S",
    "es-ES": "Tote bag reutilizable de talla S",
    "es-MX": "Bolsa tipo tote reutilizable chica",
    "fr": "Cabas réutilisable taille S",
    "hu": "Újrahasználható bevásárlótáska, S-es",
    "id": "Reusable Tote S",
    "it": "Borsa tote riutilizzabile S",
    "ja": "エコトートバッグ（S）",
    "key": "RL_ReusableToteS",
    "ko": "리유저블 토트백 S",
    "nb": "Gjenbrukbart bærenett S",
    "nl": "Herbruikbare tas S",
    "pl": "Torba wielokrotnego użytku (rozmiar S)",
    "pt-BR": "Sacola reutilizável P",
    "pt-PT": "Saco reutilizável S",
    "ru": "Многоразовая сумка-тоут (S)",
    "sv": "Tote bag som kan återanvändas (S)",
    "th": "กระเป๋าสะพายแบบใช้ซ้ำได้ ไซส์ S",
    "tr": "Yeniden Kullanılabilir El Çantası S",
    "zh-Hans": "可重复使用托特包 S 码",
    "zh-Hant": "可重複使用托特包，尺寸 S"
  },
  "RL_ReviewTillContents": {
    "ar": "مراجعة محتوى صندوق الدفع",
    "ca": "ComprovaContingutCaixaEnregistradora",
    "comment": "ReviewTillContents",
    "cs": "ReviewTillContents",
    "da": "ReviewTillContents",
    "de": "ReviewTillContents",
    "el": "Έλεγχος περιεχομένων ταμειακής",
    "en": "ReviewTillContents",
    "en-GB": "ReviewTillContents",
    "en-XA": "[ŔéṽîéŵŢîļļÇöñţéñţš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Contenidos para evaluar",
    "es-AR": "Revisarcontenidocajaregistradora",
    "es-ES": "Revisarcontenidocajaregistradora",
    "es-MX": "Revisar contenido del Cajón",
    "fr": "VérificationContenuCaisse",
    "hu": "ReviewTillContents",
    "id": "ReviewTillContents",
    "it": "ReviewTillContents",
    "ja": "コンテンツへ移動",
    "key": "RL_ReviewTillContents",
    "ko": "결제 내역 검토",
    "nb": "ReviewTillContents",
    "nl": "Inhoud kassa controleren",
    "pl": "SprawdzićZawartośćKasy",
    "pt-BR": "ReviewTillContents",
    "pt-PT": "ReverConteúdosDaCaixaRegistadora",
    "ru": "ReviewTillContents",
    "sv": "ReviewTillContents",
    "th": "ตรวจสอบจำนวนในลิ้นชักเก็บเงิน",
    "tr": "Kasa İçeriğini İncele",
    "zh-Hans": "ReviewTillContents",
    "zh-Hant": "ReviewTillContents"
  },
  "RL_STORECOPY": {
    "ar": "نسخة المتجر",
    "ca": "COPIA TIENDA",
    "comment": "Receipt header to indicate that the receipt is a copy for the store",
    "cs": "KOPIE - PRODEJNA",
    "da": "OPBEVAR KOPI",
    "de": "SHOP-KOPIE",
    "el": "ΑΝΤΙΓΡΑΦΟ ΚΑΤΑΣΤΗΜΑΤΟΣ",
    "en": "STORE COPY",
    "en-GB": "STORE COPY",
    "en-XA": "[ŠŢÖŔÉ ÇÖÞÝ å耐克รื่بُو]",
    "es-419": "COPIA DE TIENDA",
    "es-AR": "COPIA DE TIENDA",
    "es-ES": "COPIA TIENDA",
    "es-MX": "COPIA DE TIENDA",
    "fr": "EXEMPLAIRE MAGASIN",
    "hu": "STORE COPY",
    "id": "SALINAN TOKO",
    "it": "COPIA PUNTO VENDITA",
    "ja": "店舗控え",
    "key": "RL_STORECOPY",
    "ko": "매장용 사본",
    "nb": "BUTIKKEKSEMPLAR",
    "nl": "EXEMPLAAR VOOR DE STORE",
    "pl": "KOPIA DLA SKLEPU",
    "pt-BR": "CÓPIA DA LOJA",
    "pt-PT": "CÓPIA DA LOJA",
    "ru": "STORE COPY",
    "sv": "BUTIKSKOPIA",
    "th": "สำเนาร้าน",
    "tr": "MAĞAZA KOPYASI",
    "zh-Hans": "商店副本",
    "zh-Hant": "商店聯"
  },
  "RL_SUSPEND_TRAN": {
    "ar": "تعليق المعاملة",
    "ca": "SUSPENDER TRANS",
    "comment": "The line in receipt to indicate the suspended transaction",
    "cs": "Odložit transakci",
    "da": "SUSPENDER TRANSAKTION",
    "de": "TRANSAKTION AUFHEBEN",
    "el": "ΑΝΑΣΤΟΛΗ ΣΥΝΑΛΛΑΓΗΣ",
    "en": "SUSPEND TRANSACTION",
    "en-GB": "SUSPEND TRANSACTION",
    "en-XA": "[ŠÛŠÞÉÑÐ ŢŔÅÑŠÅÇŢÎÖÑ Пβシ耐克บั้ รื่بُو]",
    "es-419": "SUSPENDER TRANSACCIÓN",
    "es-AR": "SUSPENDER TRANSACCIÓN",
    "es-ES": "SUSPENDER TRANS",
    "es-MX": "SUSPENDER TRANSACCIÓN",
    "fr": "SUSPENDRE LA TRANSACTION",
    "hu": "SUSPEND TRANSACTION",
    "id": "TRANSAKSI YANG DITANGGUHKAN",
    "it": "SOSPENDI TRANSAZIONE",
    "ja": "保留",
    "key": "RL_SUSPEND_TRAN",
    "ko": "거래 정지",
    "nb": "SUSPENDER TRANSAKSJON",
    "nl": "TRANSACTIE ONDERBREKE",
    "pl": "ZAWIEŚ TRANSAKCJ",
    "pt-BR": "TRANSAÇÃO SUSPENSA",
    "pt-PT": "SUSPENDER TRANSAÇÃO",
    "ru": "SUSPEND TRANSACTION",
    "sv": "SKJUT UPP TRANSAKTION",
    "th": "ระงับธุรกรรม",
    "tr": "İŞLEMİ ASKIYA AL",
    "zh-Hans": "暂停交易",
    "zh-Hant": "暫緩交易"
  },
  "RL_Sale": {
    "ar": "البيع",
    "ca": "Venda",
    "comment": "Sale transaction. ",
    "cs": "Výprodej",
    "da": "Udsalg",
    "de": "Sale",
    "el": "Πώληση",
    "en": "Sale",
    "en-GB": "Sale",
    "en-XA": "[Šåļé 耐克รื่بُو]",
    "es-419": "Rebajas",
    "es-AR": "Rebajas",
    "es-ES": "Venta",
    "es-MX": "Venta",
    "fr": "Vente",
    "hu": "Értékesítés",
    "id": "Penjualan",
    "it": "Vendita",
    "ja": "セール",
    "key": "RL_Sale",
    "ko": "할인",
    "nb": "Salg",
    "nl": "Sale",
    "pl": "Sprzedaż",
    "pt-BR": "Venda",
    "pt-PT": "Venda",
    "ru": "Распродажа",
    "sv": "Rea",
    "th": "ลดราคา",
    "tr": "Satış",
    "zh-Hans": "销售",
    "zh-Hant": "特惠"
  },
  "RL_Search": {
    "ar": "البحث",
    "ca": "Cerca",
    "comment": "Search for a transaction",
    "cs": "Hledat",
    "da": "Søg",
    "de": "Suchen",
    "el": "Αναζήτηση",
    "en": "Search",
    "en-GB": "Search",
    "en-XA": "[Šéåŕçĥ 耐克รื่بُو]",
    "es-419": "Buscar",
    "es-AR": "Buscar",
    "es-ES": "Buscar",
    "es-MX": "Buscar",
    "fr": "Rechercher",
    "hu": "Keresés",
    "id": "Cari",
    "it": "Cerca",
    "ja": "検索",
    "key": "RL_Search",
    "ko": "검색",
    "nb": "Søk",
    "nl": "Zoek",
    "pl": "Szukaj",
    "pt-BR": "Pesquisar",
    "pt-PT": "Pesquisar",
    "ru": "Поиск",
    "sv": "Sök",
    "th": "ค้นหา",
    "tr": "Ara",
    "zh-Hans": "搜索",
    "zh-Hant": "搜尋"
  },
  "RL_SerialNum": {
    "ar": "الرقم المتسلسل",
    "ca": "Núm. de sèrie",
    "comment": "Serial number abbreviation",
    "cs": "Sériové číslo",
    "da": "Serienummer",
    "de": "Seriennr.",
    "el": "Σειριακός αρ.",
    "en": "Serial Num",
    "en-GB": "Serial Num",
    "en-XA": "[Šéŕîåļ Ñûɱ å耐克รื่بُو]",
    "es-419": "N.º de serie",
    "es-AR": "Núm. de serie:",
    "es-ES": "N.º de serie",
    "es-MX": "N° de serie",
    "fr": "N° de série",
    "hu": "Sorozatszám",
    "id": "No. Seri",
    "it": "Numero di serie",
    "ja": "シリアル番号",
    "key": "RL_SerialNum",
    "ko": "일련번호",
    "nb": "Serienr.",
    "nl": "Serienr.",
    "pl": "Nr seryjny",
    "pt-BR": "Número de série",
    "pt-PT": "Número de série",
    "ru": "Серийный номер",
    "sv": "Serienummer",
    "th": "หมายเลขซีเรียล",
    "tr": "Seri Num",
    "zh-Hans": "序列号",
    "zh-Hant": "序號"
  },
  "RL_ShipStore": {
    "ar": "للشحن - المتجر",
    "ca": "Envío - Tienda",
    "comment": "To be shipped - Store",
    "cs": "Chcete-li být dodávány - Store",
    "da": "Klar til afsendelse - Store",
    "de": "Lieferanschrift - Store",
    "el": "Προς αποστολή - Κατάστημα",
    "en": "To be shipped - Store",
    "en-GB": "To be delivered – Store",
    "en-XA": "[Ţö ƀé šĥîþþéð ‐ Šţöŕé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Para enviar: Tienda",
    "es-AR": "Para enviar: Tienda",
    "es-ES": "Envío - Tienda",
    "es-MX": "Para enviar: Tienda",
    "fr": "À expédier - Magasin",
    "hu": "To be shipped - Store",
    "id": "Akan dikirim - Toko",
    "it": "Da spedire - Negozio",
    "ja": "発送準備中 - 店舗",
    "key": "RL_ShipStore",
    "ko": "배송 예정 - 매장",
    "nb": "Skal sendes – butikk",
    "nl": "Wordt verzonden - Store",
    "pl": "Do wysyłki - Sklep",
    "pt-BR": "A ser enviado – Loja",
    "pt-PT": "Por enviar - Store",
    "ru": "К доставке — магазин",
    "sv": "Por enviar - Store",
    "th": "ต้องจัดส่ง - ร้าน",
    "tr": "Kargoya verilecek - Mağaza",
    "zh-Hans": "待配送 - 商店",
    "zh-Hant": "待出貨 – 商店"
  },
  "RL_ShipTo": {
    "ar": "الشحن إلى",
    "ca": "Enviar a",
    "comment": "Line in receipt indicating the destination address of native tax exempt transaction",
    "cs": "Preprava do",
    "da": "Send til",
    "de": "Versand an",
    "el": "Αποστολή προς",
    "en": "Ship To",
    "en-GB": "Deliver To",
    "en-XA": "[Šĥîþ Ţö 耐克รื่بُو]",
    "es-419": "Enviar a",
    "es-AR": "ENVIAR A",
    "es-ES": "Enviar a",
    "es-MX": "Enviar a",
    "fr": "SHIP TO",
    "hu": "Ship To",
    "id": "Kirim Ke",
    "it": "Indirizzo spedizione",
    "ja": "配送先",
    "key": "RL_ShipTo",
    "ko": "발송 대상",
    "nb": "Send til",
    "nl": "Verzendadres",
    "pl": "Wysyłka na adres",
    "pt-BR": "Enviar para",
    "pt-PT": "Enviar para",
    "ru": "Получатель",
    "sv": "Leveransadress",
    "th": "จัดส่งไปที่",
    "tr": "Kargo Adresi",
    "zh-Hans": "配送地址",
    "zh-Hant": "寄送至"
  },
  "RL_ShippingAmount": {
    "ar": "قيمة الشحن",
    "ca": "ctdad Envío",
    "comment": "Amount  for shipping",
    "cs": "Částka přepravy",
    "da": "Transportbeløb",
    "de": "Versand Summe",
    "el": "Ποσό αποστολής",
    "en": "Shipping Amount",
    "en-GB": "Shipping Amount",
    "en-XA": "[Šĥîþþîñĝ Åɱöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Monto de envío",
    "es-AR": "Monto de envío",
    "es-ES": "ctdad Envío",
    "es-MX": "Monto de envío",
    "fr": "Montant expédition",
    "hu": "Shipping Amount",
    "id": "Jumlah Pengiriman",
    "it": "Importo spedizione",
    "ja": "配送金額",
    "key": "RL_ShippingAmount",
    "ko": "배송 금액",
    "nb": "Sendingsbeløp",
    "nl": "Verzendkosten",
    "pl": "Koszt wysyłki",
    "pt-BR": "Valor da remessa",
    "pt-PT": "Montante do envio",
    "ru": "Сумма доставки",
    "sv": "Frakt",
    "th": "ราคาค่าจัดส่ง",
    "tr": "Kargo Tutarı",
    "zh-Hans": "运费金额",
    "zh-Hant": "運送金額"
  },
  "RL_ShippingCOPY": {
    "ar": "نسخة الشحن",
    "ca": "COPIA Envío",
    "comment": "Receipt header for the shipping copy receipt",
    "cs": "Preprava KOPIE",
    "da": "Forsendelse af KOPI",
    "de": "Kopie der Lieferung",
    "el": "ΑΝΤΙΓΡΑΦΟ αποστολής",
    "en": "Shipping COPY",
    "en-GB": "Shipping COPY",
    "en-XA": "[Šĥîþþîñĝ ÇÖÞÝ Пβシ耐克บั้ รื่بُو]",
    "es-419": "COPIA para el envío",
    "es-AR": "COPIA para el envío",
    "es-ES": "COPIA Envío",
    "es-MX": "COPIA de envío",
    "fr": "EXEMPLAIRE Expedition",
    "hu": "Shipping COPY",
    "id": "SALINAN Pengiriman",
    "it": "COPIA di spedizione",
    "ja": "配送控え",
    "key": "RL_ShippingCOPY",
    "ko": "배송 사본",
    "nb": "Leveranseeksemplar",
    "nl": "Kopie verzending",
    "pl": "Wysyłka (KOPIA)",
    "pt-BR": "CÓPIA de envio",
    "pt-PT": "CÓPIA de envio",
    "ru": "КОПИЯ отправки",
    "sv": "Leverans KOPIA",
    "th": "สำเนาการจัดส่ง",
    "tr": "Kargo KOPYASI",
    "zh-Hans": "配送副本",
    "zh-Hant": "運送副本"
  },
  "RL_ShippingItems": {
    "ar": "المنتجات للشحن",
    "ca": "Artículos del envío",
    "comment": "Items being shipped",
    "cs": "Preprava položek",
    "da": "Forsendelsesvarer",
    "de": "Versand Artikel",
    "el": "Είδη προς αποστολή",
    "en": "Shipping Items",
    "en-GB": "Shipping Items",
    "en-XA": "[Šĥîþþîñĝ Îţéɱš Пβシ耐克บั้ รื่بُو]",
    "es-419": "Artículos para enviar",
    "es-AR": "Artículos para enviar",
    "es-ES": "Envío de Artículos",
    "es-MX": "Artículos del envío",
    "fr": "Expédition art.s",
    "hu": "Shipping Items",
    "id": "Item yang Dikirim",
    "it": "Articoli di spedizione",
    "ja": "配送商品",
    "key": "RL_ShippingItems",
    "ko": "배송 상품",
    "nb": "Sender varer",
    "nl": "Items verzenden",
    "pl": "Przedmioty do wysyłki",
    "pt-BR": "Itens de Remessa",
    "pt-PT": "Artigos a enviar",
    "ru": "Отправка товаров",
    "sv": "Produkter att leverera",
    "th": "รายการจัดส่ง",
    "tr": "Kargo Ürünleri",
    "zh-Hans": "配送商品",
    "zh-Hant": "運送品項"
  },
  "RL_ShippingMethod": {
    "ar": "طريقة الشحن",
    "ca": "Metodo de Envio",
    "comment": "Method used for shipping",
    "cs": "Způsob dopravy",
    "da": "Forsendelsesmetode",
    "de": "Liefermethode",
    "el": "Τρόπος αποστολής",
    "en": "Shipping Method",
    "en-GB": "Shipping Method",
    "en-XA": "[Šĥîþþîñĝ Ṁéţĥöð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Método de envío",
    "es-AR": "Método de envío",
    "es-ES": "Metodo de Envio",
    "es-MX": "Método de envío",
    "fr": "Mode d'Expe",
    "hu": "Shipping Method",
    "id": "Metode Pengiriman",
    "it": "Metodo di spedizione",
    "ja": "配送方法",
    "key": "RL_ShippingMethod",
    "ko": "배송 방법",
    "nb": "Leveringsmetode",
    "nl": "Verzendmethode",
    "pl": "Metoda dostawy",
    "pt-BR": "Método de envio",
    "pt-PT": "Método de envio",
    "ru": "Способ доставки",
    "sv": "Leveranssätt",
    "th": "วิธีจัดส่ง",
    "tr": "Kargo Yöntemi",
    "zh-Hans": "配送方式",
    "zh-Hant": "運送方式"
  },
  "RL_ShipppingInfo": {
    "ar": "معلومات الشحن",
    "ca": "Informacion de Envio",
    "comment": "Shipping information block",
    "cs": "Info o doprave",
    "da": "Vareflytnings Information",
    "de": "Versandinformationen",
    "el": "Στοιχεία αποστολής",
    "en": "Shipping Information",
    "en-GB": "Shipping Information",
    "en-XA": "[Šĥîþþîñĝ Îñƒöŕɱåţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Información de envío",
    "es-AR": "Información de envío",
    "es-ES": "Informacion de Envio",
    "es-MX": "Información de envío",
    "fr": "Informations d'Expe",
    "hu": "Shipping Information",
    "id": "Informasi Pengiriman",
    "it": "Informazioni Spedizione",
    "ja": "配送情報",
    "key": "RL_ShipppingInfo",
    "ko": "배송 정보",
    "nb": "Leveringsinformasjon",
    "nl": "Verzendgegevens",
    "pl": "Informacje o wysyłce",
    "pt-BR": "Informações de envio",
    "pt-PT": "Informações de envio",
    "ru": "Адрес доставки",
    "sv": "Leveransinformation",
    "th": "ข้อมูลการจัดส่งสินค้า",
    "tr": "Kargo Bilgileri",
    "zh-Hans": "配送信息",
    "zh-Hant": "運送資訊"
  },
  "RL_Signature": {
    "ar": "التوقيع",
    "ca": "Signatura",
    "comment": "Digital Signature generated and printed on the fiscal receipts. ",
    "cs": "Podpis",
    "da": "Underskrift",
    "de": "Unterschrift",
    "el": "Υπογραφή",
    "en": "Signature",
    "en-GB": "Signature",
    "en-XA": "[Šîĝñåţûŕé 耐克รื่بُو]",
    "es-419": "Firma",
    "es-AR": "Firma",
    "es-ES": "Firma",
    "es-MX": "Firma",
    "fr": "Signature",
    "hu": "Aláírás",
    "id": "Tanda Tangan",
    "it": "Firma",
    "ja": "署名",
    "key": "RL_Signature",
    "ko": "서명",
    "nb": "Signatur",
    "nl": "Handtekening",
    "pl": "Podpis",
    "pt-BR": "Assinatura",
    "pt-PT": "Assinatura",
    "ru": "Подпись",
    "sv": "Signatur",
    "th": "ลายมือชื่อ",
    "tr": "İmza",
    "zh-Hans": "签名",
    "zh-Hant": "簽名"
  },
  "RL_SignatureCount": {
    "ar": "تعداد التواقيع",
    "ca": "Recompte de signatures",
    "comment": "Count of Digital Signatures generated for printing on the receipt. ",
    "cs": "Počet podpisů",
    "da": "Antal underskrifter",
    "de": "Anzahl der Unterschriften",
    "el": "Αριθμός υπογραφής",
    "en": "Signature count",
    "en-GB": "Signature count",
    "en-XA": "[Šîĝñåţûŕé çöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Recuento de firmas",
    "es-AR": "Recuento de firmas",
    "es-ES": "Número de firmas",
    "es-MX": "Conteo de firmas",
    "fr": "Décompte signature",
    "hu": "Aláírások száma",
    "id": "Jumlah tanda tangan",
    "it": "Numero firme",
    "ja": "署名の数",
    "key": "RL_SignatureCount",
    "ko": "서명 수",
    "nb": "Gyldig signatur",
    "nl": "Aantal handtekeningen",
    "pl": "Liczba podpisów",
    "pt-BR": "Contagem de assinaturas",
    "pt-PT": "Contagem de assinaturas",
    "ru": "Количество подписей",
    "sv": "Antal signaturer",
    "th": "จำนวนลายมือชื่อ",
    "tr": "İmza sayısı",
    "zh-Hans": "签名数",
    "zh-Hant": "簽名數量"
  },
  "RL_Size": {
    "ar": "القياس",
    "ca": "Talla",
    "comment": "Size of the item",
    "cs": "Velikost",
    "da": "Størrelse ",
    "de": "Größe ",
    "el": "Μέγεθος",
    "en": "Size",
    "en-GB": "Size",
    "en-XA": "[Šîžé 耐克รื่بُو]",
    "es-419": "Tamaño",
    "es-AR": "Tamaño",
    "es-ES": "Talla",
    "es-MX": "Talla",
    "fr": "Taille",
    "hu": "Size",
    "id": "Ukuran",
    "it": " Misura",
    "ja": "Size",
    "key": "RL_Size",
    "ko": "사이즈",
    "nb": "Størrelse",
    "nl": "Maat",
    "pl": "Rozmiar",
    "pt-BR": "Tamanho",
    "pt-PT": "Tamanho",
    "ru": "Размер",
    "sv": "Storlek",
    "th": "ไซส์",
    "tr": "Beden",
    "zh-Hans": "尺码",
    "zh-Hant": "尺寸"
  },
  "RL_SoftwareName": {
    "ar": "اسم البرنامج",
    "ca": "Nom del programari",
    "comment": "Name of the software that converts the transaction into a given format",
    "cs": "Název softwaru",
    "da": "Softwarenavn",
    "de": "Softwarename",
    "el": "Όνομα λογισμικού",
    "en": "Software Name",
    "en-GB": "Software name",
    "en-XA": "[Šöƒţŵåŕé Ñåɱé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Nombre del software",
    "es-AR": "Nombre del software",
    "es-ES": "Nombre del software",
    "es-MX": "Nombre del software",
    "fr": "Nom du logiciel",
    "hu": "Szoftver neve",
    "id": "Nama Perangkat Lunak",
    "it": "Nome software",
    "ja": "ソフトウェア名",
    "key": "RL_SoftwareName",
    "ko": "소프트웨어 이름",
    "nb": "Programvarenavn",
    "nl": "Softwarenaam",
    "pl": "Nazwa oprogramowania",
    "pt-BR": "Nome do software",
    "pt-PT": "Nome do software",
    "ru": "Название ПО",
    "sv": "Programvarans namn",
    "th": "ชื่อซอฟต์แวร์",
    "tr": "Yazılım Adı",
    "zh-Hans": "软件名称",
    "zh-Hant": "軟體名稱"
  },
  "RL_SoftwareVersion": {
    "ar": "إصدار البرنامج",
    "ca": "Versió de programari",
    "comment": "Software Version ",
    "cs": "Verze softwaru",
    "da": "Softwareversion",
    "de": "Software-Version",
    "el": "Έκδοση λογισμικού",
    "en": "Software Version",
    "en-GB": "Software Version",
    "en-XA": "[Šöƒţŵåŕé Ṽéŕšîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Versión de software",
    "es-AR": "Versión de software",
    "es-ES": "Versión de software",
    "es-MX": "Versión del software",
    "fr": "Version du logiciel",
    "hu": "Szoftver verziója",
    "id": "Versi Perangkat Lunak",
    "it": "Versione software",
    "ja": "ソフトウェアのバージョン",
    "key": "RL_SoftwareVersion",
    "ko": "소프트웨어 버전",
    "nb": "Programvareversjon",
    "nl": "Softwareversie",
    "pl": "Wersja oprogramowania",
    "pt-BR": "Versão do software",
    "pt-PT": "Versão do software",
    "ru": "Версия программного обеспечения",
    "sv": "Programversion",
    "th": "เวอร์ชันซอฟต์แวร์",
    "tr": "Yazılım Sürümü",
    "zh-Hans": "软件版本",
    "zh-Hant": "軟體版本"
  },
  "RL_SrlNo_Lft": {
    "ar": "الرقم المتسلسل إلى اليسار",
    "ca": "Núm. de sèrie de la sabatilla esquerra",
    "comment": "Serial Number Left-HyperAdapt",
    "cs": "Sériové č. vlevo",
    "da": "Serie-nr. venstre",
    "de": "Seriennr. links",
    "el": "Σειριακός αρ. αριστερά",
    "en": "Serial No Left",
    "en-GB": "Serial No. Left",
    "en-XA": "[Šéŕîåļ Ñö Ļéƒţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de serie para el calzado izquierdo",
    "es-AR": "N° de serie del calzado izquierdo",
    "es-ES": "N.º de serie de la zapatilla izquierda",
    "es-MX": "N° de serie izquierdo",
    "fr": "N° de série de la chaussure gauche",
    "hu": "Sorozatszám, bal",
    "id": "No. Seri Kiri",
    "it": "Numero di serie mancante",
    "ja": "左側のシリアル番号",
    "key": "RL_SrlNo_Lft",
    "ko": "왼쪽 일련번호",
    "nb": "Serienr. venstre",
    "nl": "Serienr. links",
    "pl": "Nr seryjny – lewy",
    "pt-BR": "Sem número de série",
    "pt-PT": "N.º de série esquerdo",
    "ru": "Серийный номер слева",
    "sv": "Serienummer vänster",
    "th": "หมายเลขซีเรียล (ซ้าย)",
    "tr": "Seri No Sol",
    "zh-Hans": "序列号靠左",
    "zh-Hant": "左側序號"
  },
  "RL_SrlNo_Rght": {
    "ar": "الرقم المتسلسل إلى اليمين",
    "ca": "Núm. de sèrie de la sabatilla dreta",
    "comment": "Serial Number Right-HyperAdapt",
    "cs": "Sériové číslo vpravo",
    "da": "Serie-nr. højre",
    "de": "Seriennr. rechts",
    "el": "Σειριακός αρ. δεξιά",
    "en": "Serial No Right",
    "en-GB": "Serial No. Right",
    "en-XA": "[Šéŕîåļ Ñö Ŕîĝĥţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de serie incorrecto",
    "es-AR": "N.° de serie del calzado derecho",
    "es-ES": "N.º de serie de la zapatilla derecha",
    "es-MX": "N° de serie derecho",
    "fr": "N° de série de la chaussure droite",
    "hu": "Sorozatszám, jobb",
    "id": "No. Seri Kanan",
    "it": "Numero di serie non corretto",
    "ja": "右側のシリアル番号",
    "key": "RL_SrlNo_Rght",
    "ko": "오른쪽 일련번호",
    "nb": "Serienr. høyre",
    "nl": "Serienr. rechts",
    "pl": "Nr seryjny – prawy",
    "pt-BR": "Número de série correto",
    "pt-PT": "N.º de série direito",
    "ru": "Серийный номер справа",
    "sv": "Serienr. höger",
    "th": "หมายเลขซีเรียล (ขวา)",
    "tr": "Seri No. Sağ",
    "zh-Hans": "序列号靠右",
    "zh-Hant": "右側序號"
  },
  "RL_Start": {
    "ar": "البدء ",
    "ca": "Inicia ",
    "comment": "Start Date Time for TSE attributes in Germany receipts. ",
    "cs": "Začít ",
    "da": "Start ",
    "de": "Start ",
    "el": "Έναρξη ",
    "en": "Start ",
    "en-GB": "Start ",
    "en-XA": "[Šţåŕţ  耐克รื่بُو]",
    "es-419": "Comenzar ",
    "es-AR": "Inicio ",
    "es-ES": "Empezar ",
    "es-MX": "Comenzar ",
    "fr": "Start ",
    "hu": "Kezdés ",
    "id": "Mulai ",
    "it": "Inizia ",
    "ja": "開始 ",
    "key": "RL_Start",
    "ko": "시작 ",
    "nb": "Start ",
    "nl": "Start ",
    "pl": "Rozpocznij ",
    "pt-BR": "Iniciar ",
    "pt-PT": "Início ",
    "ru": "Старт ",
    "sv": "Start ",
    "th": "เริ่ม ",
    "tr": "Başlat ",
    "zh-Hans": "开始 ",
    "zh-Hant": "開始 "
  },
  "RL_State": {
    "ar": "الولاية",
    "ca": "Estado",
    "comment": "State where the transactions occurs",
    "cs": "Stát",
    "da": "Kommune",
    "de": "Bundesland",
    "el": "Νομός",
    "en": "State",
    "en-GB": "State",
    "en-XA": "[Šţåţé 耐克รื่بُو]",
    "es-419": "Estado",
    "es-AR": "Estado",
    "es-ES": "Estado",
    "es-MX": "Estado",
    "fr": "State",
    "hu": "State",
    "id": "Negara Bagian",
    "it": "Stato",
    "ja": "都道府県",
    "key": "RL_State",
    "ko": "주",
    "nb": "Delstat",
    "nl": "Staat",
    "pl": "Województwo",
    "pt-BR": "Estado",
    "pt-PT": "Estado",
    "ru": "Регион",
    "sv": "Delstat",
    "th": "รัฐ",
    "tr": "Eyalet",
    "zh-Hans": "省/市/直辖市",
    "zh-Hant": "州"
  },
  "RL_Status": {
    "ar": "الحالة",
    "ca": "Estat",
    "comment": "Status of a transaction like Resume, Delivered, voided etc",
    "cs": "Stav",
    "da": "Status",
    "de": "Status",
    "el": "Κατάσταση",
    "en": "Status",
    "en-GB": "Status",
    "en-XA": "[Šţåţûš 耐克รื่بُو]",
    "es-419": "Estado",
    "es-AR": "Estado",
    "es-ES": "Estado",
    "es-MX": "Estado",
    "fr": "Statut",
    "hu": "Állapot",
    "id": "Status",
    "it": "Stato",
    "ja": "ステータス",
    "key": "RL_Status",
    "ko": "상태",
    "nb": "Status",
    "nl": "Status",
    "pl": "Status",
    "pt-BR": "Status",
    "pt-PT": "Estado",
    "ru": "Статус",
    "sv": "Status",
    "th": "สถานะ",
    "tr": "Durum",
    "zh-Hans": "状态",
    "zh-Hant": "狀態"
  },
  "RL_Stop": {
    "ar": "التوقّف",
    "ca": "Atura",
    "comment": " End date and time for the TSE attributes in German receipts. ",
    "cs": "Zastavit",
    "da": "Stop",
    "de": "Beenden",
    "el": "Διακοπή",
    "en": "Stop",
    "en-GB": "Stop",
    "en-XA": "[Šţöþ 耐克รื่بُو]",
    "es-419": "Detener",
    "es-AR": "Finalización",
    "es-ES": "Detener",
    "es-MX": "Detener",
    "fr": "Stop",
    "hu": "Leállítás",
    "id": "Berhenti",
    "it": "Interrompi",
    "ja": "停止",
    "key": "RL_Stop",
    "ko": "정지",
    "nb": "Stopp",
    "nl": "Stop",
    "pl": "Zatrzymaj",
    "pt-BR": "Parar",
    "pt-PT": "Fim",
    "ru": "Стоп",
    "sv": "Slut",
    "th": "หยุด",
    "tr": "Durdur",
    "zh-Hans": "结束",
    "zh-Hant": "結束"
  },
  "RL_Store": {
    "ar": "المتجر",
    "ca": "Tienda",
    "comment": "Store where the transaction is done",
    "cs": "Obchod",
    "da": "Butik",
    "de": "Shop",
    "el": "Κατάστημα",
    "en": "Store",
    "en-GB": "Store",
    "en-XA": "[Šţöŕé 耐克รื่بُو]",
    "es-419": "Tienda",
    "es-AR": "Tienda",
    "es-ES": "Tienda",
    "es-MX": "Tienda",
    "fr": "Magasin",
    "hu": "Store",
    "id": "Toko",
    "it": "Punto vendita",
    "ja": "店舗",
    "key": "RL_Store",
    "ko": "매장",
    "nb": "Butikk",
    "nl": "Winkel",
    "pl": "Sklep",
    "pt-BR": "Loja",
    "pt-PT": "Loja",
    "ru": "Магазин",
    "sv": "Butik",
    "th": "ร้าน",
    "tr": "Mağaza",
    "zh-Hans": "适用商店",
    "zh-Hant": "門市"
  },
  "RL_StoreCredit": {
    "ar": "رصيد في المتجر",
    "ca": "Crédito tienda",
    "comment": "Indicates the store credit",
    "cs": "Nákupní kredit prodejny",
    "da": "Butik kredit",
    "de": "Shop -Gutschrift",
    "el": "Πίστωση καταστήματος",
    "en": "Store Credit",
    "en-GB": "Store Credit",
    "en-XA": "[Šţöŕé Çŕéðîţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Crédito de la tienda",
    "es-AR": "Crédito de la tienda",
    "es-ES": "Crédito tienda",
    "es-MX": "Crédito de tienda",
    "fr": "Bon d'achat",
    "hu": "Store Credit",
    "id": "Kredit Toko",
    "it": "Credito punto vendita",
    "ja": "ストアクレジット",
    "key": "RL_StoreCredit",
    "ko": "매장 크레딧",
    "nb": "Butikkreditt",
    "nl": "Winkel krediet",
    "pl": "Sklepowa linia kredytowa",
    "pt-BR": "Crédito da loja",
    "pt-PT": "Crédito Store",
    "ru": "Store Credit",
    "sv": "Storekredit",
    "th": "เครดิตในร้าน",
    "tr": "Mağaza Kredisi",
    "zh-Hans": "商店购物券",
    "zh-Hant": "店內現金額度"
  },
  "RL_StoreLevelPromotion": {
    "ar": "عرض ترويجي على مستوى المتجر",
    "ca": "Promoció de la botiga",
    "comment": "String in receipt to identify Store Level Promotion",
    "cs": "Promoakce v obchodě",
    "da": "Kampagne på butiksniveau",
    "de": "Store-Level-Angebot",
    "el": "Προώθηση σε επίπεδο καταστήματος",
    "en": "Store Level Promotion",
    "en-GB": "Store-Level Promotion",
    "en-XA": "[Šţöŕé Ļéṽéļ Þŕöɱöţîöñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Promoción a nivel de tienda",
    "es-AR": "Promoción de la tienda",
    "es-ES": "Promoción de la tienda",
    "es-MX": "Promoción de Tienda",
    "fr": "Promotion au niveau du magasin",
    "hu": "Üzletszintű promóció",
    "id": "Promosi Tingkat Toko",
    "it": "Promozione a livello di negozio",
    "ja": "店舗単位のプロモーション",
    "key": "RL_StoreLevelPromotion",
    "ko": "매장 차원 프로모션",
    "nb": "Butikkampanje",
    "nl": "Promotie in de Store",
    "pl": "Promocja na poziomie sklepu",
    "pt-BR": "Promoção da loja",
    "pt-PT": "Promoção ao nível da loja",
    "ru": "Рекламная акция в магазине",
    "sv": "Kampanj på butiksnivå",
    "th": "โปรโมชันระดับร้าน",
    "tr": "Mağaza Düzeyinde Promosyon",
    "zh-Hans": "商店促销",
    "zh-Hant": "門市等級促銷"
  },
  "RL_StoreNumber": {
    "ar": "رقم المتجر",
    "ca": "Número de tienda",
    "comment": " Store number in receipt",
    "cs": "Číslo prodejny",
    "da": "ButikNummer",
    "de": "StoreNumber",
    "el": "StoreNumber",
    "en": "StoreNumber",
    "en-GB": "StoreNumber",
    "en-XA": "[ŠţöŕéÑûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de tienda",
    "es-AR": "Número de tienda",
    "es-ES": "N.º tienda",
    "es-MX": "Número de tienda",
    "fr": "Nomagasin",
    "hu": "StoreNumber",
    "id": "NomorToko",
    "it": "Numero punto vendita:",
    "ja": "店舗番号",
    "key": "RL_StoreNumber",
    "ko": "매장 번호",
    "nb": "Butikknummer",
    "nl": "StoreNummer",
    "pl": "Numer sklepu",
    "pt-BR": "NúmeroLoja",
    "pt-PT": "Número da loja",
    "ru": "StoreNumber",
    "sv": "Butiksnummer",
    "th": "หมายเลขร้าน",
    "tr": "MağazaNumarası",
    "zh-Hans": "商店编号",
    "zh-Hant": "門市編號"
  },
  "RL_Style": {
    "ar": "الأسلوب",
    "ca": "Estilo",
    "comment": "Style field in receipt",
    "cs": "Styl ",
    "da": "Stil ",
    "de": "Style",
    "el": "Στιλ",
    "en": "Style",
    "en-GB": "Style",
    "en-XA": "[Šţýļé 耐克รื่بُو]",
    "es-419": "Estilo ",
    "es-AR": "Estilo",
    "es-ES": "Estilo",
    "es-MX": "Estilo",
    "fr": "Modèle ",
    "hu": "Style",
    "id": "Gaya",
    "it": "Modello",
    "ja": "Style",
    "key": "RL_Style",
    "ko": "스타일",
    "nb": "Produkt",
    "nl": "Stijl",
    "pl": "Styl",
    "pt-BR": "Estilo",
    "pt-PT": "Estilo",
    "ru": "Артикул",
    "sv": "Modell",
    "th": "สไตล์",
    "tr": "Stil",
    "zh-Hans": "款式",
    "zh-Hant": "款式"
  },
  "RL_SubTenderType": {
    "ar": "نوع صيغة الدفع الفرعية",
    "ca": "Subtipus de mètode de pagament",
    "comment": "Sub Tender under a given Tender Type.  For eg: SubTenderTypes like Alipay, WeChatPay under TenderType =Online Payment",
    "cs": "Služba:",
    "da": "Underbetalingstype",
    "de": "Unterkategorie für Zahlungsmittelart",
    "el": "Υποκατηγορία αντιτίμου",
    "en": "SubTender Type",
    "en-GB": "SubTender type",
    "en-XA": "[ŠûƀŢéñðéŕ Ţýþé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tipo de subforma de pago",
    "es-AR": "Subtipo de forma de pago",
    "es-ES": "Subtipo de forma de pago",
    "es-MX": "Subforma de pago",
    "fr": "Sous-type de règlement",
    "hu": "Fizetési mód altípusa",
    "id": "Jenis SubTender",
    "it": "Sottotipo di pagamento",
    "ja": "予備の決済手段の種類",
    "key": "RL_SubTenderType",
    "ko": "하위 결제 수단 유형",
    "nb": "SubTender-type",
    "nl": "Type SubTender",
    "pl": "Podkategoria formy płatności",
    "pt-BR": "Tipo de subpagamento",
    "pt-PT": "Subtipo de pagamento",
    "ru": "Тип платежного средства второго порядка",
    "sv": "Delanbudstyp",
    "th": "รูปแบบการชำระเงินย่อย",
    "tr": "Alt Kasa İşlemi Türü",
    "zh-Hans": "备选付款方式",
    "zh-Hant": "子付款類型"
  },
  "RL_Subtotal": {
    "ar": "المجموع الفرعي",
    "ca": "Subtotal",
    "comment": "Sub total of the transaction",
    "cs": "Mezisoucet",
    "da": "Subtotal",
    "de": "Zwischensumme",
    "el": "Υποσύνολο",
    "en": "Subtotal",
    "en-GB": "Subtotal",
    "en-XA": "[Šûƀţöţåļ 耐克รื่بُو]",
    "es-419": "Subtotal",
    "es-AR": "Subtotal",
    "es-ES": "Subtotal",
    "es-MX": "Subtotal",
    "fr": "Sous-total",
    "hu": "Subtotal",
    "id": "Subtotal",
    "it": "Subtotale",
    "ja": "小計",
    "key": "RL_Subtotal",
    "ko": "상품 금액",
    "nb": "Subtotal",
    "nl": "Subtotaal",
    "pl": "Suma częściowa",
    "pt-BR": "Subtotal",
    "pt-PT": "Subtotal",
    "ru": "Промежуточный итог",
    "sv": "Delsumma",
    "th": "ยอดรวมย่อย",
    "tr": "Ara Toplam",
    "zh-Hans": "实际商品总额",
    "zh-Hant": "小計"
  },
  "RL_Suspend": {
    "ar": "تعليق",
    "ca": "Suspeses",
    "comment": "Transactions that are suspended",
    "cs": "Pozastavená",
    "da": "Overskriv",
    "de": "Aussetzen",
    "el": "Σε αναστολή",
    "en": "Suspend",
    "en-GB": "Suspend",
    "en-XA": "[Šûšþéñð 耐克รื่بُو]",
    "es-419": "Suspendida",
    "es-AR": "Suspender",
    "es-ES": "Suspendido",
    "es-MX": "Suspender",
    "fr": "Suspendre",
    "hu": "Felfüggesztés",
    "id": "Ditangguhkan",
    "it": "Sospese",
    "ja": "保留にする",
    "key": "RL_Suspend",
    "ko": "사용 중지",
    "nb": "Suspender",
    "nl": "Onderbreek",
    "pl": "Wstrzymaj",
    "pt-BR": "Suspenso",
    "pt-PT": "Suspensa",
    "ru": "Приостановить",
    "sv": "Pausa",
    "th": "ระงับ",
    "tr": "Askıya Al",
    "zh-Hans": "已暂停",
    "zh-Hant": "已停止"
  },
  "RL_SwitchView": {
    "ar": "تبديل العرض",
    "ca": "Canvia la vista",
    "comment": "Switch view between the Transaction and Receipt view on the Electronic Journal.",
    "cs": "Přepnout pohled",
    "da": "Skift visning",
    "de": "Ansicht wechseln",
    "el": "Αλλαγή προβολής",
    "en": "Switch View",
    "en-GB": "Switch View",
    "en-XA": "[Šŵîţçĥ Ṽîéŵ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Cambiar vista",
    "es-AR": "Cambiar vista",
    "es-ES": "Cambiar vista",
    "es-MX": "Cambiar vista",
    "fr": "Changer l'affichage",
    "hu": "Nézetváltás",
    "id": "Beralih Tampilan",
    "it": "Cambia visualizzazione",
    "ja": "ビューを切り替え",
    "key": "RL_SwitchView",
    "ko": "보기 전환",
    "nb": "Bytt visning",
    "nl": "Wissel van weergave",
    "pl": "Przełącz widok",
    "pt-BR": "Alterar visualização",
    "pt-PT": "Mudar de vista",
    "ru": "Сменить вид",
    "sv": "Växla vy",
    "th": "เปลี่ยนมุมมอง",
    "tr": "Görünümü Değiştir",
    "zh-Hans": "切换视图",
    "zh-Hant": "切換檢視模式"
  },
  "RL_TC": {
    "ar": "رمز المعاملة",
    "ca": "TC",
    "comment": "TC term in card transaction",
    "cs": "TC",
    "da": "TC",
    "de": "TC",
    "el": "TC",
    "en": "TC",
    "en-GB": "TC",
    "en-XA": "[ŢÇ 耐克รื่بُو]",
    "es-419": "TC",
    "es-AR": "TC",
    "es-ES": "TC",
    "es-MX": "TC",
    "fr": "TC",
    "hu": "TC",
    "id": "TC",
    "it": "TC",
    "ja": "TC",
    "key": "RL_TC",
    "ko": "TC",
    "nb": "TC",
    "nl": "TC",
    "pl": "TC",
    "pt-BR": "TC",
    "pt-PT": "Cartão de transação",
    "ru": "TC",
    "sv": "TC",
    "th": "TC",
    "tr": "TC",
    "zh-Hans": "交易认证码",
    "zh-Hant": "交易認證碼"
  },
  "RL_TENDEREXCHANGE": {
    "ar": "تبديل المناقصة",
    "ca": "CAMBIO DE PAGO",
    "comment": "Phrase to indicate the TENDER EXCHANGE in the transaction",
    "cs": "VÝMENA - ZPUS. PLATBY",
    "da": "BETALINGSVALUTA",
    "de": "ANGEBOT WECHSELNZAHLUNGSMITTEL WECHSELGELD",
    "el": "ΑΝΤΑΛΛΑΓΗ ΠΡΟΣΦΟΡΩΝ",
    "en": "TENDER EXCHANGE",
    "en-GB": "TENDER EXCHANGE",
    "en-XA": "[ŢÉÑÐÉŔ ÉẊÇĤÅÑĜÉ Пβシ耐克บั้ รื่بُو]",
    "es-419": "CAMBIO DE MEDIO DE PAGO",
    "es-AR": "CAMBIO DE MEDIO DE PAGO",
    "es-ES": "CAMBIO DE PAGO",
    "es-MX": "CAMBIO DE MEDIO DE PAGO",
    "fr": "ÉCHANGE DE MODE DE PAIEMENT",
    "hu": "TENDER EXCHANGE",
    "id": "PERTUKARAN TENDER",
    "it": "CAMBIO MODALITÀ DI PAGAMENTO",
    "ja": "支払い方法変更",
    "key": "RL_TENDEREXCHANGE",
    "ko": "지불 수단 변경 교환",
    "nb": "BETALINGSMETODE FOR VEKSEL",
    "nl": "BETAALWIJZE WISSELEN",
    "pl": "WYMIANA OFERTY",
    "pt-BR": "TROCA DE PAGAMENTO",
    "pt-PT": "TROCA FORMA PAGAMENTO",
    "ru": "TENDER EXCHANGE",
    "sv": "VÄXLA IN BETALNINGSMEDEL",
    "th": "การแลกเปลี่ยนการชำระเงิน",
    "tr": "KASA İŞLEMİ DEĞİŞİMİ",
    "zh-Hans": "支付方式更换",
    "zh-Hant": "支付方式兌換"
  },
  "RL_TRAININGMODE": {
    "ar": "وضع التدريب",
    "ca": "Modo Training",
    "comment": "Training Mode Indicator",
    "cs": "Výukový režim",
    "da": "TRÆNINGSTILSTAND",
    "de": "Ausbildung / Training",
    "el": "ΛΕΙΤΟΥΡΓΙΑ ΕΚΠΑΙΔΕΥΣΗΣ",
    "en": "TRAINING MODE",
    "en-GB": "TRAINING MODE",
    "en-XA": "[ŢŔÅÎÑÎÑĜ ṀÖÐÉ Пβシ耐克บั้ รื่بُو]",
    "es-419": "MODALIDAD DE ENTRENAMIENTO",
    "es-AR": "MODALIDAD DE ENTRENAMIENTO",
    "es-ES": "MODO FORMACIÓN",
    "es-MX": "MODO DE CAPACITACIÓN",
    "fr": "MODE FORMATION",
    "hu": "TRAINING MODE",
    "id": "MODE LATIHAN",
    "it": "MODALITÀ ADDESTRAMENTO",
    "ja": "TRAINING MODE",
    "key": "RL_TRAININGMODE",
    "ko": "트레이닝 모드",
    "nb": "OPPLÆRINGSMODUS",
    "nl": "Training Modus",
    "pl": "TRYB SZKOLENIOWY",
    "pt-BR": "MODO DE TREINO",
    "pt-PT": "Modo Formação",
    "ru": "TRAINING MODE",
    "sv": "Utbildningsläge",
    "th": "โหมดทดลองใช้",
    "tr": "EĞİTİM MODU",
    "zh-Hans": "培训模式",
    "zh-Hant": "培訓模式"
  },
  "RL_TRANSACTION": {
    "ar": "المعاملة",
    "ca": "TRANSACCIÓN",
    "comment": "word to indicates the transaction number",
    "cs": "TRANSACTION",
    "da": "TRANSAKTION",
    "de": "TRANSACTIE",
    "el": "ΣΥΝΑΛΛΑΓΗ",
    "en": "TRANSACTION",
    "en-GB": "TRANSACTION",
    "en-XA": "[ŢŔÅÑŠÅÇŢÎÖÑ Пβシ耐克บั้ รื่بُو]",
    "es-419": "TRANSACCIÓN",
    "es-AR": "TRANSACCIÓN",
    "es-ES": "TRANSACTION",
    "es-MX": "TRANSACCIÓN|",
    "fr": "APRES ACHAT",
    "hu": "TRANSACTION",
    "id": "TRANSAKSI",
    "it": "TRANSACTION",
    "ja": "TRANSACTION",
    "key": "RL_TRANSACTION",
    "ko": "거래",
    "nb": "TRANSAKSJON",
    "nl": "TRANSACTIE",
    "pl": "TRANSAKCJA",
    "pt-BR": "TRANSAÇÃO",
    "pt-PT": "Transacção",
    "ru": "TRANSACTION",
    "sv": "TRANSACTION",
    "th": "ธุรกรรม",
    "tr": "İŞLEM",
    "zh-Hans": "交易",
    "zh-Hant": "交易"
  },
  "RL_TRANSACTION_SUSPENDED": {
    "ar": "المعاملة معلّقة",
    "ca": "TRANSACCIÓ INTERROMPUDA",
    "comment": "TRANSACTION SUSPENDED",
    "cs": "POZASTAVENÁ TRANSAKCE",
    "da": "TRANSAKTION AFBRUDT",
    "de": "TRANSAKTION UNTERBROCHEN",
    "el": "Η ΣΥΝΑΛΛΑΓΗ ΤΕΘΗΚΕ ΣΕ ΑΝΑΣΤΟΛΗ",
    "en": "TRANSACTION SUSPENDED",
    "en-GB": "TRANSACTION SUSPENDED",
    "en-XA": "[ŢŔÅÑŠÅÇŢÎÖÑ ŠÛŠÞÉÑÐÉÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "TRANSACCIÓN SUSPENDIDA",
    "es-AR": "TRANSACCIÓN SUSPENDIDA",
    "es-ES": "TRANSACCIÓN SUSPENDIDA",
    "es-MX": "SUSPENDER TRANSACCIÓN",
    "fr": "TRANSACTION SUSPENDUE",
    "hu": "TRANZAKCIÓ FELFÜGGESZTVE",
    "id": "TRANSAKSI DITANGGUHKAN",
    "it": "TRANSAZIONE SOSPESA",
    "ja": "中断された取引",
    "key": "RL_TRANSACTION_SUSPENDED",
    "ko": "거래 정지됨",
    "nb": "TRANSAKSJON SUSPENDERT",
    "nl": "TRANSACTIE AFGEBROKEN",
    "pl": "TRANSAKCJA ZAWIESZONA",
    "pt-BR": "TRANSAÇÃO SUSPENSA",
    "pt-PT": "TRANSAÇÃO SUSPENSA",
    "ru": "ТРАНЗАКЦИЯ ПРИОСТАНОВЛЕНА",
    "sv": "TRANSAKTIONEN ÄR PAUSAD",
    "th": "ธุรกรรมถูกระงับ",
    "tr": "İŞLEM ASKIYA ALINDI",
    "zh-Hans": "交易暂停",
    "zh-Hant": "交易已停止"
  },
  "RL_TSEOffline": {
    "ar": "لا يمكن الاتصال بنظام الأمن التقنيّ TSE",
    "ca": "No es pot connectar amb el TSE",
    "comment": "Text to print on German receipts when TSE is not reachable",
    "cs": "Nelze se spojit s TSE",
    "da": "TSE kan ikke nås",
    "de": "TSE kann nicht erreicht werden",
    "el": "Δεν είναι δυνατή η επικοινωνία με το TSE",
    "en": "TSE cannot be reached",
    "en-GB": "TSE cannot be reached",
    "en-XA": "[ŢŠÉ çåññöţ ƀé ŕéåçĥéð Пβシ耐克บั้ รื่بُو]",
    "es-419": "El dispositivo técnico de seguridad no se puede conectar",
    "es-AR": "El dispositivo técnico de seguridad no se puede conectar",
    "es-ES": "No se ha podido conectar con el TSE",
    "es-MX": "Hubo un erro al contactar a TSE",
    "fr": "TSE inaccessible",
    "hu": "A TSE nem elérhető",
    "id": "TSE tidak dapat dihubungi",
    "it": "TSE irraggiungibile",
    "ja": "TSEにアクセスできません",
    "key": "RL_TSEOffline",
    "ko": "TSE 접근 불가",
    "nb": "Når ikke TSE",
    "nl": "TSE is niet bereikbaar",
    "pl": "Nie udało się połączyć z systemem TSE",
    "pt-BR": "Não é possível alcançar TSE",
    "pt-PT": "Não foi possível contactar a TSE",
    "ru": "Не удалось установить связь с TSE",
    "sv": "TSE kan inte nås",
    "th": "ไม่สามารถเข้าถึง TSE ได้",
    "tr": "TSE'ye erişilemiyor.",
    "zh-Hans": "无法访问 TSE",
    "zh-Hant": "無法連接 TSE"
  },
  "RL_Tax": {
    "ar": "الضريبة",
    "ca": "Impuestos",
    "comment": "Tax applied",
    "cs": "Daň",
    "da": "Skat",
    "de": "Tax",
    "el": "Φόροι",
    "en": "Tax",
    "en-GB": "VAT",
    "en-XA": "[Ţåẋ 耐克รื่بُو]",
    "es-419": "Impuesto",
    "es-AR": "Impuesto",
    "es-ES": "Impuestos",
    "es-MX": "Impuesto",
    "fr": "Taxes",
    "hu": "Tax",
    "id": "Pajak",
    "it": "Imposta",
    "ja": "Tax",
    "key": "RL_Tax",
    "ko": "부가세",
    "nb": "Skatt",
    "nl": "Btw",
    "pl": "Podatek",
    "pt-BR": "Imposto",
    "pt-PT": "Imposto",
    "ru": "Налог",
    "sv": "Moms",
    "th": "ภาษี",
    "tr": "Vergi",
    "zh-Hans": "税费",
    "zh-Hant": "稅金"
  },
  "RL_TaxExempt": {
    "ar": "معفى من الضرائب",
    "ca": "Exenc. impuestos",
    "comment": "Tax Exempted for the transaction",
    "cs": "Osvobození od daně",
    "da": "skattefritagelse",
    "de": "Steuerbefreit",
    "el": "Φορολογική απαλλαγή",
    "en": "Tax Exempt",
    "en-GB": "Tax Exempt",
    "en-XA": "[Ţåẋ Éẋéɱþţ å耐克รื่بُو]",
    "es-419": "Exento de impuestos",
    "es-AR": "Exenc. de imp. de trans",
    "es-ES": "Exenc. impuestos",
    "es-MX": "Exención de impuestos",
    "fr": "Exonération fiscale",
    "hu": "Tax Exempt",
    "id": "Bebas Pajak",
    "it": "Esente da tasse",
    "ja": "免税対象者",
    "key": "RL_TaxExempt",
    "ko": "면세",
    "nb": "Skattefri",
    "nl": "Vrijstelling van belasting",
    "pl": "zwolnienie podatkowe",
    "pt-BR": "Isenção de taxa",
    "pt-PT": "Isento Imposto",
    "ru": "Tax Exempt",
    "sv": "Skatteundantag",
    "th": "การยกเว้นภาษี",
    "tr": "Vergi Muafiyeti",
    "zh-Hans": "免税",
    "zh-Hant": "免稅"
  },
  "RL_TaxExemptNo": {
    "ar": "الرقم المعفى من الضرائب",
    "ca": "Tax Exempt No",
    "comment": "Tax Exempt Number for exemption used in the transaction",
    "cs": "Tax Exempt No",
    "da": "Tax Exempt No",
    "de": "Tax Exempt No",
    "el": "Αρ. φορολογικής απαλλαγής",
    "en": "Tax Exempt No",
    "en-GB": "Tax Exempt No.",
    "en-XA": "[Ţåẋ Éẋéɱþţ Ñö Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número exento de impuestos",
    "es-AR": "Número exento de impuestos",
    "es-ES": "Tax Exempt No",
    "es-MX": "Tax Exempt No",
    "fr": "Tax Exempt No",
    "hu": "Tax Exempt No",
    "id": "No. Bebas Pajak",
    "it": "Tax Exempt No",
    "ja": "Tax Exempt No",
    "key": "RL_TaxExemptNo",
    "ko": "면세 번호",
    "nb": "Skattefritaksnr.",
    "nl": "Tax Exempt No",
    "pl": "Tax Exempt No",
    "pt-BR": "Número da isenção de taxa",
    "pt-PT": "Tax Exempt No",
    "ru": "Tax Exempt No",
    "sv": "Tax Exempt No",
    "th": "เลขการยกเว้นภาษี",
    "tr": "Vergi Muafiyeti No",
    "zh-Hans": "免税编号",
    "zh-Hant": "Tax Exempt No"
  },
  "RL_TenderType": {
    "ar": "نوع المناقصة",
    "ca": "Tipus de mètode de pagament",
    "comment": "Type of tender - Cash, Credit Card etc",
    "cs": "Typ platby",
    "da": "Betalingstype",
    "de": "Zahlungsmittelart",
    "el": "Τύπος προσφοράς",
    "en": "Tender Type",
    "en-GB": "Tender Type",
    "en-XA": "[Ţéñðéŕ Ţýþé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tipo de forma de pago",
    "es-AR": "Tipo de forma de pago",
    "es-ES": "Tipo de forma de pago",
    "es-MX": "Tipo de forma de pago",
    "fr": "Type de règlement",
    "hu": "Tender típusa",
    "id": "Jenis Tender",
    "it": "Tipo di pagamento",
    "ja": "支払方法",
    "key": "RL_TenderType",
    "ko": "수단 유형",
    "nb": "Type betalingsmetode",
    "nl": "Type offerte",
    "pl": "Typ oferty",
    "pt-BR": "Tipo de pagamento",
    "pt-PT": "Tipo de pagamento",
    "ru": "Тип тендера",
    "sv": "Anbudstyp",
    "th": "ประเภทการชำระเงิน",
    "tr": "Kasa İşlemi Türü",
    "zh-Hans": "付款方式",
    "zh-Hant": "付款類型"
  },
  "RL_TenderVoid": {
    "ar": "صيغة الدفع غير صالحة",
    "ca": "Mètode de pagament no vàlid",
    "comment": "To indicate that the tender amount (Cash, Credit etc) is voided in the transaction. ",
    "cs": "Platba zrušena",
    "da": "Betaling annulleret",
    "de": "Zahlungsmittel ungültig",
    "el": "Ακύρωση προσφοράς",
    "en": "Tender Void",
    "en-GB": "Tender void",
    "en-XA": "[Ţéñðéŕ Ṽöîð Пβシ耐克บั้ รื่بُو]",
    "es-419": "Forma de pago anulada",
    "es-AR": "Forma de pago anulada",
    "es-ES": "Forma de pago anulada",
    "es-MX": "Forma de pago anulada",
    "fr": "Montant du règlement nul",
    "hu": "Fizetési mód érvénytelenítve",
    "id": "Pembatalan Tender",
    "it": "Pagamento non valido",
    "ja": "無効な代金",
    "key": "RL_TenderVoid",
    "ko": "결제 무효",
    "nb": "Ugyldig betalingsmetode",
    "nl": "Bedrag gewist",
    "pl": "Nieważna forma płatności",
    "pt-BR": "Pagamento cancelado",
    "pt-PT": "Pagamento anulado",
    "ru": "Платеж аннулирован",
    "sv": "Betalningsmedel annullerat",
    "th": "จำนวนเงินเป็นโมฆะ",
    "tr": "Kasa İşlemi İptal",
    "zh-Hans": "付款方式无效",
    "zh-Hant": "付款作廢"
  },
  "RL_TextSearch": {
    "ar": "البحث في النص ",
    "ca": "Cerca de text",
    "comment": "A free text search field. ",
    "cs": "Hledat text",
    "da": "Tekstsøgning ",
    "de": "Textsuche",
    "el": "Κείμενο αναζήτησης ",
    "en": "Text Search ",
    "en-GB": "Text Search ",
    "en-XA": "[Ţéẋţ Šéåŕçĥ  Пβシ耐克บั้ รื่بُو]",
    "es-419": "Texto de búsqueda",
    "es-AR": "Búsqueda de texto",
    "es-ES": "Búsqueda de texto",
    "es-MX": "Búsqueda de texto ",
    "fr": "Recherche textuelle",
    "hu": "Szöveges keresés",
    "id": "Pencarian Teks",
    "it": "Ricerca testo",
    "ja": "テキスト検索",
    "key": "RL_TextSearch",
    "ko": "텍스트 검색",
    "nb": "Tekstsøk ",
    "nl": "Tekst zoekopdracht ",
    "pl": "Wyszukiwanie tekstowe",
    "pt-BR": "Pesquisa de texto",
    "pt-PT": "Pesquisa de texto",
    "ru": "Текстовый поиск",
    "sv": "Söktext",
    "th": "ค้นหาข้อความ",
    "tr": "Metin Araması",
    "zh-Hans": "文本搜索",
    "zh-Hant": "文字搜尋"
  },
  "RL_Tid": {
    "ar": "رقم تعريف المعاملة",
    "ca": "Tid",
    "comment": " Tid of the  card details",
    "cs": "Tid",
    "da": "Tid",
    "de": "Tid",
    "el": "Tid",
    "en": "Tid",
    "en-GB": "Tid",
    "en-XA": "[Ţîð 耐克รื่بُو]",
    "es-419": "Tid",
    "es-AR": "Tid",
    "es-ES": "Tid",
    "es-MX": "Tid",
    "fr": "Tid",
    "hu": "Tid",
    "id": "Tid",
    "it": "Tid",
    "ja": "Tid",
    "key": "RL_Tid",
    "ko": "Tid",
    "nb": "Tid",
    "nl": "Tid",
    "pl": "Tid",
    "pt-BR": "Tid",
    "pt-PT": "ID da transação",
    "ru": "ИНН",
    "sv": "Tid",
    "th": "หมายเลขเครื่อง",
    "tr": "Tid",
    "zh-Hans": "终端机代码",
    "zh-Hant": "端末機代號"
  },
  "RL_Till": {
    "ar": "رقم صندوق الدفع",
    "ca": "Número de la caixa",
    "comment": "Number assigned to the Till to accept payments",
    "cs": "Číslo pokladny",
    "da": "Kasseapparatsnummer",
    "de": "Geldkassettennummer",
    "el": "Αριθμός ταμείου",
    "en": "Till Number",
    "en-GB": "Till number",
    "en-XA": "[Ţîļļ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de caja registradora",
    "es-AR": "Número de caja registradora",
    "es-ES": "Número de caja",
    "es-MX": "Número de caja registradora",
    "fr": "Numéro de la caisse",
    "hu": "Pénztár száma",
    "id": "Nomor Mesin Kasir",
    "it": "Numero di cassa",
    "ja": "現金トレイの番号",
    "key": "RL_Till",
    "ko": "출납기 번호",
    "nb": "Kasseskuffnummer",
    "nl": "Kassanummer",
    "pl": "Numer kasy",
    "pt-BR": "Número da cédula",
    "pt-PT": "Número de caixa",
    "ru": "Номер кассового ящика",
    "sv": "Kassalådans nummer",
    "th": "หมายเลขลิ้นชักเก็บเงิน",
    "tr": "Para Çekmecesi Numarası",
    "zh-Hans": "收银机编号",
    "zh-Hant": "收銀機編號"
  },
  "RL_Time": {
    "ar": "الوقت",
    "ca": "Hora",
    "comment": "Time of the transaction",
    "cs": "Cas",
    "da": "Tid",
    "de": "Uhrzeit",
    "el": "Ώρα",
    "en": "Time",
    "en-GB": "Time",
    "en-XA": "[Ţîɱé 耐克รื่بُو]",
    "es-419": "Hora",
    "es-AR": "Hora",
    "es-ES": "Hora",
    "es-MX": "Hora",
    "fr": "Heure",
    "hu": "Time",
    "id": "Waktu",
    "it": "Ora",
    "ja": "時間",
    "key": "RL_Time",
    "ko": "시간",
    "nb": "Tidspunkt",
    "nl": "Tijd",
    "pl": "Godzina",
    "pt-BR": "Duração",
    "pt-PT": "Data",
    "ru": "Время",
    "sv": "Tid",
    "th": "เวลา",
    "tr": "Zaman",
    "zh-Hans": "时间",
    "zh-Hant": "時間"
  },
  "RL_TimeFormat": {
    "ar": "تنسيق الوقت",
    "ca": "Format de l'hora",
    "comment": "The Time Format, eg UTC time of the transaction printed on the receipt. ",
    "cs": "Formát času",
    "da": "Tidsformat",
    "de": "Zeitformat",
    "el": "Μορφή ώρας",
    "en": "Time Format",
    "en-GB": "Time Format",
    "en-XA": "[Ţîɱé Ƒöŕɱåţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Formato de tiempo",
    "es-AR": "Formato de hora",
    "es-ES": "Formato de hora",
    "es-MX": "Formato de tiempo",
    "fr": "Format horaire",
    "hu": "Idő formátuma",
    "id": "Format Waktu",
    "it": "Formato ora",
    "ja": "時間の形式",
    "key": "RL_TimeFormat",
    "ko": "시간 형식",
    "nb": "Tidsformat",
    "nl": "Tijdnotatie",
    "pl": "Format czasu",
    "pt-BR": "Formato de hora",
    "pt-PT": "Formato da hora",
    "ru": "Формат времени",
    "sv": "Tidsformat",
    "th": "รูปแบบเวลา",
    "tr": "Saat Formatı",
    "zh-Hans": "时间格式",
    "zh-Hant": "時間格式"
  },
  "RL_TobeshippedNIKEId": {
    "ar": "للشحن - Nike By You",
    "ca": "Envío - Nike By You",
    "comment": "To be shipped - NIKEiD",
    "cs": "Chcete-li být dodávány  - Nike By You",
    "da": "Klar til afsendelse - Nike By You",
    "de": "Lieferanschrift - Nike By You",
    "el": "Προς αποστολή - Nike By You",
    "en": "To be shipped - Nike By You",
    "en-GB": "To be delivered – Nike By You",
    "en-XA": "[Ţö ƀé šĥîþþéð ‐ Ñîķé Ɓý Ýöû åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Para enviar: Nike By You",
    "es-AR": "Para enviar: Nike By You",
    "es-ES": "Envío - Nike By You",
    "es-MX": "Para enviar: Nike By You",
    "fr": "À expédier - Nike By You",
    "hu": "To be shipped - Nike By You",
    "id": "Akan dikirim - Nike By You",
    "it": "Da spedire - Nike By You",
    "ja": "発送準備中 - Nike By You",
    "key": "RL_TobeshippedNIKEId",
    "ko": "배송 예정 - Nike By You",
    "nb": "Skal sendes – Nike By You",
    "nl": "Wordt verzonden - Nike By You",
    "pl": "Do wysyłki - Nike By You",
    "pt-BR": "A ser enviado – Nike By You",
    "pt-PT": "Por enviar - Nike By You",
    "ru": "К доставке — Nike By You",
    "sv": "Por enviar - Nike By You",
    "th": "ต้องจัดส่ง - Nike By You",
    "tr": "Kargoya verilecek - Senin Nike Tasarımın",
    "zh-Hans": "待配送 - Nike By You",
    "zh-Hant": "待出貨 – Nike By You"
  },
  "RL_TobeshippedNike": {
    "ar": "للشحن - Nike.com",
    "ca": "Envío - Nike.com",
    "comment": "To be shipped - Nike.com",
    "cs": "Chcete-li být dodávány - Nike.com",
    "da": "Klar til afsendelse - Nike.com",
    "de": "Lieferanschrift - Nike.com",
    "el": "Προς αποστολή - Nike.com",
    "en": "To be shipped - Nike.com",
    "en-GB": "To be delivered – Nike.com",
    "en-XA": "[Ţö ƀé šĥîþþéð ‐ Ñîķé·çöɱ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Para enviar: Nike.com",
    "es-AR": "Para enviar: Nike.com",
    "es-ES": "Envío - Nike.com",
    "es-MX": "Para enviar: Nike.com",
    "fr": "À expédier - Nike.com",
    "hu": "To be shipped - Nike.com",
    "id": "Akan dikirim - Nike.com",
    "it": "Da spedire - Nike.com",
    "ja": "発送準備中 - Nike.com",
    "key": "RL_TobeshippedNike",
    "ko": "배송 예정 - Nike.com",
    "nb": "Skal sendes – Nike.com",
    "nl": "Wordt verzonden - Nike.com",
    "pl": "Do wysyłki - Nike.com",
    "pt-BR": "A ser enviado — Nike.com",
    "pt-PT": "Por enviar - Nike.com",
    "ru": "К доставке — Nike.com",
    "sv": "Por enviar - Nike.com",
    "th": "ต้องจัดส่ง - Nike.com",
    "tr": "Kargoya verilecek - Nike.com",
    "zh-Hans": "待配送 - Nike.com",
    "zh-Hant": "待出貨 – Nike.com"
  },
  "RL_Total": {
    "ar": "المجموع",
    "ca": "Total",
    "comment": "Total of the transaction",
    "cs": "Celkem",
    "da": "Total",
    "de": "Summe",
    "el": "Σύνολο",
    "en": "Total",
    "en-GB": "Total",
    "en-XA": "[Ţöţåļ 耐克รื่بُو]",
    "es-419": "Total",
    "es-AR": "Total",
    "es-ES": "Total",
    "es-MX": "Total",
    "fr": "Total",
    "hu": "Total",
    "id": "Total",
    "it": "Totale",
    "ja": "合計 ",
    "key": "RL_Total",
    "ko": "합계",
    "nb": "Totalt",
    "nl": "Totaal",
    "pl": "Suma",
    "pt-BR": "Total",
    "pt-PT": "Total",
    "ru": "Всего",
    "sv": "Totalt",
    "th": "ยอดรวม",
    "tr": "Toplam",
    "zh-Hans": "总计",
    "zh-Hant": "總計"
  },
  "RL_Tran": {
    "ar": "المعاملة",
    "ca": "Tran",
    "comment": "Transaction number abbreviation",
    "cs": "Tran",
    "da": "Tran",
    "de": "Tran",
    "el": "Συναλ.",
    "en": "Tran",
    "en-GB": "Tran",
    "en-XA": "[Ţŕåñ 耐克รื่بُو]",
    "es-419": "Trans.",
    "es-AR": "Trans",
    "es-ES": "Tran",
    "es-MX": "Trans.",
    "fr": "Tran",
    "hu": "Tran",
    "id": "Tran",
    "it": "Tran",
    "ja": "Tran",
    "key": "RL_Tran",
    "ko": "영수 No",
    "nb": "Tran",
    "nl": "Trans",
    "pl": "Transakcja",
    "pt-BR": "Transação",
    "pt-PT": "Trans.",
    "ru": "Транзакция",
    "sv": "Trans. nr.",
    "th": "เลขธุรกรรม",
    "tr": "İşl.",
    "zh-Hans": "交易编号",
    "zh-Hant": "交易編號"
  },
  "RL_TransactionAmount": {
    "ar": "قيمة المعاملة",
    "ca": "Transaction Amount",
    "comment": "Transaction amount",
    "cs": "Transaction Amount",
    "da": "Beløb",
    "de": "Kundenbetrag",
    "el": "Ποσό συναλλαγής",
    "en": "Transaction Amount",
    "en-GB": "Transaction Amount",
    "en-XA": "[Ţŕåñšåçţîöñ Åɱöûñţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Importe de transacción",
    "es-AR": "Importe de transacción",
    "es-ES": "Importe de la transacción",
    "es-MX": "Importe de transacción",
    "fr": "Transaction Amount",
    "hu": "Transaction Amount",
    "id": "Jumlah Transaksi",
    "it": "Transaction Amount",
    "ja": "Transaction Amount",
    "key": "RL_TransactionAmount",
    "ko": "거래 금액",
    "nb": "Transaksjonsbeløp",
    "nl": "Transactiebedrag",
    "pl": "Kwota transakcji",
    "pt-BR": "Valor da transação",
    "pt-PT": "Montante da transação",
    "ru": "Сумма транзакции",
    "sv": "Transaktionsbelopp",
    "th": "จำนวนเงินของธุรกรรม",
    "tr": "İşlem Tutarı",
    "zh-Hans": "交易金额",
    "zh-Hant": "交易金額"
  },
  "RL_TransactionInformation": {
    "ar": "معلومات حول المعاملة",
    "ca": "Informació de transacció",
    "comment": "Transaction Information that includes date time, transaction number etc",
    "cs": "Informace o transakci",
    "da": "Transaktionsoplysninger",
    "de": "Transaktionsinformation",
    "el": "Στοιχεία συναλλαγής",
    "en": "Transaction Information",
    "en-GB": "Transaction Information",
    "en-XA": "[Ţŕåñšåçţîöñ Îñƒöŕɱåţîöñ åПβシ耐克บั้ รื่بُو]",
    "es-419": "Información de transacción",
    "es-AR": "Información de la transacción",
    "es-ES": "Información de la transacción",
    "es-MX": "Información de la transacción",
    "fr": "Informations sur la transaction",
    "hu": "Tranzakció adatai",
    "id": "Informasi Transaksi",
    "it": "Informazioni sulla transazione",
    "ja": "取引情報",
    "key": "RL_TransactionInformation",
    "ko": "거래 정보",
    "nb": "Transaksjonsopplysninger",
    "nl": "Transactiegegevens",
    "pl": "Dane transakcji",
    "pt-BR": "Informações da transação",
    "pt-PT": "Informações da transação",
    "ru": "Сведения о транзакции",
    "sv": "Transaktionsinformation",
    "th": "ข้อมูลธุรกรรม",
    "tr": "İşlem Bilgileri",
    "zh-Hans": "交易信息",
    "zh-Hant": "交易資訊"
  },
  "RL_TransactionNo": {
    "ar": "رقم المعاملة",
    "ca": "N.º transacción",
    "comment": "Transaction number generated ",
    "cs": "Transaction No",
    "da": "Transaktionsnr",
    "de": "Transaktions-Nr",
    "el": "Αριθμός συναλλαγής",
    "en": "Transaction No",
    "en-GB": "Transaction No",
    "en-XA": "[Ţŕåñšåçţîöñ Ñö Пβシ耐克บั้ รื่بُو]",
    "es-419": "Numero De Transacción",
    "es-AR": "Numero De Transacción",
    "es-ES": "N.º transacción",
    "es-MX": "Transacción N.º",
    "fr": "No. de transaction",
    "hu": "Transaction No",
    "id": "No. Transaksi",
    "it": "Transaction No",
    "ja": "取引番号",
    "key": "RL_TransactionNo",
    "ko": "거래 번호",
    "nb": "Transaksjonsnr.",
    "nl": "Transactienummer",
    "pl": "Nr transakcji",
    "pt-BR": "N° da transação",
    "pt-PT": "N.º da transação",
    "ru": "Номер транзакции",
    "sv": "Transaktionsnr.",
    "th": "เลขธุรกรรม",
    "tr": "İşlem No",
    "zh-Hans": "交易编号",
    "zh-Hant": "交易編號"
  },
  "RL_TransactionRepresentation": {
    "ar": "تمثيل المعاملات",
    "ca": "Representació de la transacció",
    "comment": "Representation of a transaction in the Electronic Journal",
    "cs": "Zobrazení transakce",
    "da": "Transaktionsrepræsentation",
    "de": "Transaktionsdarstellung",
    "el": "Αναπαράσταση συναλλαγής",
    "en": "Transaction Representation",
    "en-GB": "Transaction Representation",
    "en-XA": "[Ţŕåñšåçţîöñ Ŕéþŕéšéñţåţîöñ åƀçПβシ耐克บั้ รื่بُو]",
    "es-419": "Representación de la transacción",
    "es-AR": "Representación de la transacción",
    "es-ES": "Representación de la transacción",
    "es-MX": "Representación de la transacción",
    "fr": "Représentation de la transaction",
    "hu": "Tranzakció reprezentációja",
    "id": "Representasi Transaksi",
    "it": "Rappresentazione transazione",
    "ja": "取引表示",
    "key": "RL_TransactionRepresentation",
    "ko": "거래 대표",
    "nb": "Transaksjonsrepresentasjon",
    "nl": "Representatie van transactie",
    "pl": "Przedstawienie transakcji",
    "pt-BR": "Representação da transação",
    "pt-PT": "Representação da transação",
    "ru": "Представление транзакций",
    "sv": "Transaktionsrepresentation",
    "th": "คำรับรองการทำธุรกรรม",
    "tr": "İşlem Temsili",
    "zh-Hans": "交易标示符",
    "zh-Hant": "交易代表"
  },
  "RL_TransactionType": {
    "ar": "نوع المعاملة",
    "ca": "Tipus de transacció",
    "comment": "Type of transaction - sales, returns etc.",
    "cs": "Typ transakce",
    "da": "Transaktionstype",
    "de": "Transaktionsart",
    "el": "Τύπος συναλλαγής",
    "en": "Transaction Type",
    "en-GB": "Transaction Type",
    "en-XA": "[Ţŕåñšåçţîöñ Ţýþé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Tipo de transacción",
    "es-AR": "Tipo de transacción",
    "es-ES": "Tipo de transacción",
    "es-MX": "Tipo de transacción",
    "fr": "Type de transaction",
    "hu": "Tranzakció típusa",
    "id": "Jenis Transaksi",
    "it": "Tipo di transazione",
    "ja": "取引の種類",
    "key": "RL_TransactionType",
    "ko": "거래 유형",
    "nb": "Transaksjonstype",
    "nl": "Type transactie",
    "pl": "Typ transakcji",
    "pt-BR": "Tipo de transação",
    "pt-PT": "Tipo de transação",
    "ru": "Тип транзакции",
    "sv": "Transaktionstyp",
    "th": "ประเภทธุรกรรม",
    "tr": "İşlem Türü",
    "zh-Hans": "交易类型",
    "zh-Hant": "交易類型"
  },
  "RL_TransactionUUID": {
    "ar": "المعرِّف الفريد العالمي UUID للمعاملة",
    "ca": "UUID de la transacció",
    "comment": "UUID or unique Identifier  to identify a transaction ",
    "cs": "UUID transakce",
    "da": "UUID for transaktion",
    "de": "UUID-Transaktion",
    "el": "UUID συναλλαγής",
    "en": "Transaction UUID",
    "en-GB": "Transaction UUID",
    "en-XA": "[Ţŕåñšåçţîöñ ÛÛÎÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Identificador único de la transacción",
    "es-AR": "UUID de la transacción",
    "es-ES": "UUID de la transacción",
    "es-MX": "UUID de la transacción",
    "fr": "UUID de la transaction",
    "hu": "Tranzakciós UUID",
    "id": "UUID Transaksi",
    "it": "UUID transazione",
    "ja": "取引UUID",
    "key": "RL_TransactionUUID",
    "ko": "거래 UUID",
    "nb": "Transaksjons-UUID",
    "nl": "Transactie UUID",
    "pl": "Identyfikator UUID transakcji",
    "pt-BR": "UUID da transação",
    "pt-PT": "UUID da transação",
    "ru": "UUID транзакции",
    "sv": "Transaktions-UUID",
    "th": "UUID ของธุรกรรม",
    "tr": "İşlem UUID'si",
    "zh-Hans": "交易 UUID",
    "zh-Hant": "交易 UUID"
  },
  "RL_UPCcode": {
    "ar": "رمز UPC",
    "ca": "Codi Universal de Producte",
    "comment": "The UPC code of the item printed on the receipt. ",
    "cs": "Kód UPC",
    "da": "Stregkode",
    "de": "UPC Code",
    "el": "Κωδικός UPC",
    "en": "UPC Code",
    "en-GB": "UPC",
    "en-XA": "[ÛÞÇ Çöðé 耐克รื่بُو]",
    "es-419": "Código UPC",
    "es-AR": "Código de barras",
    "es-ES": "Código UPC",
    "es-MX": "Código de barra",
    "fr": "Code UPC",
    "hu": "UPC-kód",
    "id": "Kode UPC",
    "it": "Codice UPC",
    "ja": "UPCコード",
    "key": "RL_UPCcode",
    "ko": "UPC 코드",
    "nb": "Strekkode",
    "nl": "UPC-code",
    "pl": "Kod UPC",
    "pt-BR": "Código UPC",
    "pt-PT": "Código UPC",
    "ru": "Код UPC",
    "sv": "UPC-kod",
    "th": "รหัส UPC",
    "tr": "UPC Kodu",
    "zh-Hans": "UPC 码",
    "zh-Hant": "UPC 代碼"
  },
  "RL_UnnamedItem": {
    "ar": "منتج لا يحمل اسمًا",
    "ca": "PRODUCTE SENSE NOM",
    "comment": "All caps placeholder text for an item that didn't have a name on a receipt",
    "cs": "POLOŽKA BEZ NÁZVU",
    "da": "IKKE ANGIVET VARE",
    "de": "UNBENANNTES PRODUKT",
    "el": "ΕΙΔΟΣ ΧΩΡΙΣ ΟΝΟΜΑ",
    "en": "UNNAMED ITEM",
    "en-GB": "UNNAMED ITEM",
    "en-XA": "[ÛÑÑÅṀÉÐ ÎŢÉṀ Пβシ耐克บั้ รื่بُو]",
    "es-419": "PRODUCTO SIN NOMBRE",
    "es-AR": "PRODUCTO SIN NOMBRE",
    "es-ES": "PRODUCTO SIN NOMBRE",
    "es-MX": "PRODUCTO SIN NOMBRE",
    "fr": "ARTICLE SANS NOM",
    "hu": "NÉVTELEN TERMÉK",
    "id": "ITEM TANPA NAMA",
    "it": "ARTICOLO SENZA NOME",
    "ja": "無名の商品",
    "key": "RL_UnnamedItem",
    "ko": "이름 없는 아이템",
    "nb": "VARE UTEN NAVN",
    "nl": "NAAMLOOS ITEM",
    "pl": "PRZEDMIOT BEZ NAZWY",
    "pt-BR": "ITEM SEM NOME",
    "pt-PT": "ARTIGO SEM NOME",
    "ru": "ТОВАР БЕЗ НАЗВАНИЯ",
    "sv": "PRODUKT UTAN NAMN",
    "th": "รายการไม่มีชื่อ",
    "tr": "ADSIZ ÜRÜN",
    "zh-Hans": "未命名商品",
    "zh-Hant": "無名稱品項"
  },
  "RL_VOIDED": {
    "ca": "ANULADO",
    "comment": "phrase to indicate the voided transaction",
    "cs": "ZNEPLAT",
    "da": "UGYLDIGGJORT",
    "de": "ENTWERTET",
    "el": "ΑΚΥΡΩΘΗΚΕ",
    "en": "VOIDED",
    "en-GB": "VOIDED",
    "en-XA": "[ṼÖÎÐÉÐ 耐克รื่بُو]",
    "es-419": "ANULADO",
    "es-AR": "ANULADO",
    "es-ES": "ANULADO",
    "es-MX": "ANULADO",
    "fr": "ANNULÉ",
    "hu": "VOIDED",
    "id": "BATAL",
    "it": "ANNULLATO",
    "ja": "VOIDED",
    "key": "RL_VOIDED",
    "ko": "취소됨",
    "nb": "ANNULLERT",
    "nl": "GEANNULEERD",
    "pl": "ANULOWANY",
    "pt-BR": "ANULADO",
    "pt-PT": "ANULADO",
    "ru": "VOIDED",
    "sv": "MAKULERAD",
    "th": "ที่เป็นโมฆะ",
    "tr": "İPTAL EDİLDİ",
    "zh-Hans": "已撤销",
    "zh-Hant": "已作廢"
  },
  "RL_VOIDTRANSACTION": {
    "ca": "ANULAR TRANSACCIÓN",
    "comment": "A line in receipt indicating that the particular transaction is voided.",
    "cs": "VOID TRANSACTION",
    "da": "ANNULLERET TRANSAKTION",
    "de": "ENTWERTETE TRANSAKTION",
    "el": "ΑΚΥΡΗ ΣΥΝΑΛΛΑΓΗ",
    "en": "VOID TRANSACTION",
    "en-GB": "VOID TRANSACTION",
    "en-XA": "[ṼÖÎÐ ŢŔÅÑŠÅÇŢÎÖÑ Пβシ耐克บั้ รื่بُو]",
    "es-419": "ANULAR TRANSACCIÓN",
    "es-AR": "ANULAR TRANSACCIÓN",
    "es-ES": "ANULAR TRANSACCIÓN",
    "es-MX": "ANULAR TRANSACCIÓN",
    "fr": "ANNULER LA TRANSACTION",
    "hu": "VOID TRANSACTION",
    "id": "TRANSAKSI BATAL",
    "it": "ANNULLA TRANSAZIONE",
    "ja": "VOID TRANSACTION",
    "key": "RL_VOIDTRANSACTION",
    "ko": "취소된 거래",
    "nb": "UGYLDIG TRANSAKSJON",
    "nl": "GEANNULEERDE TRANSACTIE",
    "pl": "ANULOWANIE TRANSAKCJI",
    "pt-BR": "TRANSAÇÃO ANULADA",
    "pt-PT": "ANULAR TRANSAÇÃO",
    "ru": "АННУЛИРОВАТЬ ТРАНЗАКЦИЮ",
    "sv": "MAKULERA TRANSAKTION",
    "th": "ธุรกรรมที่เป็นโมฆะ",
    "tr": "GEÇERSİZ İŞLEM",
    "zh-Hans": "撤销交易",
    "zh-Hant": "作廢交易"
  },
  "RL_Void": {
    "ar": "ملغاة",
    "ca": "Anul·lades",
    "comment": "Transactions that of voided status.",
    "cs": "Prázdná",
    "da": "Annulleret",
    "de": "Ungültig",
    "el": "Άκυρες",
    "en": "Void",
    "en-GB": "Void",
    "en-XA": "[Ṽöîð 耐克รื่بُو]",
    "es-419": "Anulada",
    "es-AR": "Anular",
    "es-ES": "Anulado",
    "es-MX": "Invalidar",
    "fr": "Vide",
    "hu": "Sztornózás",
    "id": "Batal",
    "it": "Non valide",
    "ja": "無効化",
    "key": "RL_Void",
    "ko": "비우기",
    "nb": "Ugyldig",
    "nl": "Ongeldig",
    "pl": "Pusty",
    "pt-BR": "Anulado",
    "pt-PT": "Anulada",
    "ru": "Аннулировать",
    "sv": "Annullera",
    "th": "ยกเลิก",
    "tr": "İptal",
    "zh-Hans": "无效",
    "zh-Hant": "作廢"
  },
  "RL_Voucher": {
    "ar": "قسيمة",
    "ca": "Vale",
    "comment": "Voucher",
    "cs": "Poukaz",
    "da": "Værdibevis",
    "de": "Gutschein",
    "el": "Κουπόνι",
    "en": "Voucher",
    "en-GB": "Voucher",
    "en-XA": "[Ṽöûçĥéŕ 耐克รื่بُو]",
    "es-419": "Vale",
    "es-AR": "Vale",
    "es-ES": "Vale",
    "es-MX": "Vale",
    "fr": "Bon",
    "hu": "Voucher",
    "id": "Voucher",
    "it": "Voucher",
    "ja": "引換券",
    "key": "RL_Voucher",
    "ko": "바우처",
    "nb": "Kupong",
    "nl": "Voucher",
    "pl": "Voucher",
    "pt-BR": "Voucher",
    "pt-PT": "Voucher",
    "ru": "Ваучер",
    "sv": "Kupong",
    "th": "บัตรกำนัล",
    "tr": "Hediye Kuponu",
    "zh-Hans": "代金券",
    "zh-Hant": "優惠券"
  },
  "RL_YouSaved": {
    "ar": "لقد وفّرت {amount} اليوم",
    "ca": "Avui has estalviat {amount}",
    "comment": "Phrase showing the savings a customer had on a receipt due to discounts. {amount} to be filled programmatically and not translated",
    "cs": "Dnes ušetřeno celkem {amount}",
    "da": "Du sparede {amount} i dag",
    "de": "Sie haben heute {amount} gespart",
    "el": "Κερδίσατε {amount} σήμερα",
    "en": "You saved {amount} today",
    "en-GB": "You saved {amount} today",
    "en-XA": "[Ýöû šåṽéð {amount} ţöðåý åПβシ耐克บั้ รื่بُو]",
    "es-419": "Hoy ahorraste {amount}",
    "es-AR": "Hoy ahorraste {amount}",
    "es-ES": "Hoy has ahorrado {amount}",
    "es-MX": "Ahorraste {amount} el día de hoy",
    "fr": "Vous avez économisé {amount} aujourd'hui",
    "hu": "Mai megtakarítás: {amount}",
    "id": "Anda menghemat {amount} hari ini",
    "it": "Oggi hai risparmiato {amount}",
    "ja": "{amount}円分の割引が適用されました",
    "key": "RL_YouSaved",
    "ko": "{amount} 원 할인 되었습니다.",
    "nb": "Du sparte {amount} i dag",
    "nl": "U heeft vandaag {amount} bespaard",
    "pl": "Dzisiejsza oszczędność: {amount}",
    "pt-BR": "Você economizou {amount} hoje",
    "pt-PT": "Poupaste {amount} hoje",
    "ru": "Сегодня вы сэконономили {amount}",
    "sv": "Du har sparat {amount} idag",
    "th": "วันนี้ประหยัดได้ {amount}",
    "tr": "Bugün {amount} tutarında tasarruf ettin",
    "zh-Hans": "本次购物您节约了 {amount}",
    "zh-Hant": "你今天省下了 {amount}"
  },
  "RL_eCommerceID": {
    "ar": "معرّف التجارة الإلكترونية",
    "ca": "E-commerce ID",
    "comment": "eCommerce ID field in receipt line",
    "cs": "eCommerce ID",
    "da": "eHandel-id",
    "de": "eCommerce ID",
    "el": "Αναγνωριστικό eCommerce",
    "en": "eCommerce ID",
    "en-GB": "eCommerce ID",
    "en-XA": "[éÇöɱɱéŕçé ÎÐ Пβシ耐克บั้ รื่بُو]",
    "es-419": "ID de comercio electrónico",
    "es-AR": "ID de comercio electrónico",
    "es-ES": "ID eCommerce",
    "es-MX": "ID de comercio electrónico",
    "fr": "id eCommerce",
    "hu": "eCommerce ID",
    "id": "ID eCommerce",
    "it": "ID eCommerce",
    "ja": "eコマースID",
    "key": "RL_eCommerceID",
    "ko": "e커머스 ID",
    "nb": "e-handel-ID",
    "nl": "eCommerce ID",
    "pl": "ID eCommerce",
    "pt-BR": "ID de eCommerce",
    "pt-PT": "ID de comércio eletrónico",
    "ru": "Номер eCommerce",
    "sv": "e-handels-id",
    "th": "eCommerce ID",
    "tr": "E-Ticaret Kimliği",
    "zh-Hans": "电子商务 ID",
    "zh-Hant": "電商 ID"
  },
  "RL_eMoney": {
    "ar": "eMoney",
    "ca": "eMoney",
    "comment": "eMoney",
    "cs": "eMoney",
    "da": "eMoney",
    "de": "eGeld",
    "el": "eMoney",
    "en": "eMoney",
    "en-GB": "eMoney",
    "en-XA": "[éṀöñéý 耐克รื่بُو]",
    "es-419": "eMoney",
    "es-AR": "eMoney",
    "es-ES": "eMoney",
    "es-MX": "eMoney",
    "fr": "Argent électronique",
    "hu": "eMoney",
    "id": "eMoney",
    "it": "eMoney",
    "ja": "eMoney",
    "key": "RL_eMoney",
    "ko": "전자 화폐",
    "nb": "eMoney",
    "nl": "eMoney",
    "pl": "eMoney",
    "pt-BR": "eMoney",
    "pt-PT": "eMoney",
    "ru": "eMoney",
    "sv": "eMoney",
    "th": "eMoney",
    "tr": "E-Para",
    "zh-Hans": "eMoney",
    "zh-Hant": "eMoney"
  },
  "RL_eWallet": {
    "ar": "eWallet",
    "ca": "eWallet",
    "comment": "eWallet",
    "cs": "eWallet",
    "da": "eWallet",
    "de": "eWallet",
    "el": "eWallet",
    "en": "eWallet",
    "en-GB": "eWallet",
    "en-XA": "[éŴåļļéţ 耐克รื่بُو]",
    "es-419": "eWallet",
    "es-AR": "eWallet",
    "es-ES": "eWallet",
    "es-MX": "eWallet",
    "fr": "eWallet",
    "hu": "eWallet",
    "id": "eWallet",
    "it": "eWallet",
    "ja": "eWallet",
    "key": "RL_eWallet",
    "ko": "전자 지갑",
    "nb": "eWallet",
    "nl": "eWallet",
    "pl": "eWallet",
    "pt-BR": "eWallet",
    "pt-PT": "eWallet",
    "ru": "Эл. кошелек",
    "sv": "eWallet",
    "th": "eWallet",
    "tr": "E-Cüzdan",
    "zh-Hans": "电子钱包",
    "zh-Hant": "eWallet"
  },
  "RL_returnRcpt": {
    "ar": "إيصال الإرجاع",
    "ca": "Tiquet de Dev",
    "comment": "Return Receipt",
    "cs": "Príjem Vrácení",
    "da": "Returner bon",
    "de": "Reklamationsbeleg",
    "el": "Απόδειξη επιστροφής",
    "en": "Return Receipt",
    "en-GB": "Return Receipt",
    "en-XA": "[Ŕéţûŕñ Ŕéçéîþţ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Recibo para devoluciones",
    "es-AR": "Recibo para devoluciones",
    "es-ES": "Tiquet de Dev",
    "es-MX": "Recibo para devoluciones",
    "fr": "Reçu de retour.",
    "hu": "Return Receipt",
    "id": "Tanda Terima Pengembalian",
    "it": "Ricevuta reso",
    "ja": "返品レシート",
    "key": "RL_returnRcpt",
    "ko": "반품 영수증",
    "nb": "Returkvittering",
    "nl": "Bon Retour",
    "pl": "Dowod zwrotu",
    "pt-BR": "Recibo de devolução",
    "pt-PT": "Recibo Devolucao",
    "ru": "Уведомление о доставке",
    "sv": "Returkvitto",
    "th": "ใบเสร็จการคืนสินค้า",
    "tr": "İade Makbuzu",
    "zh-Hans": "返回收据",
    "zh-Hant": "退貨收據"
  },
  "RL_“stringCash_Change”": {
    "ar": "باقي النقود",
    "ca": "Canvi en efectiu",
    "comment": " To change \"Wijzig\" into \"Wisselgeld\" local language?",
    "cs": "Vrátit",
    "da": "Kontant byttepenge",
    "de": "Wechselgeld",
    "el": "Μεταβολή μετρητών",
    "en": "Cash Change",
    "en-GB": "Cash Change",
    "en-XA": "[Çåšĥ Çĥåñĝé Пβシ耐克บั้ รื่بُو]",
    "es-419": "Cambio de efectivo",
    "es-AR": "Cambio en efectivo",
    "es-ES": "Cambio en efectivo",
    "es-MX": "Cambio de efectivo",
    "fr": "Rendu monnaie",
    "hu": "Készpénz-visszajáró",
    "id": "Kembalian",
    "it": "Resto contanti",
    "ja": "お釣り",
    "key": "RL_“stringCash_Change”",
    "ko": "현금 잔액",
    "nb": "Vekselpenger",
    "nl": "Contant wisselgeld",
    "pl": "Reszta w gotówce",
    "pt-BR": "Troco",
    "pt-PT": "Troco",
    "ru": "Сдача наличными",
    "sv": "Kontant växel",
    "th": "เงินทอน",
    "tr": "Nakit Para Üstü",
    "zh-Hans": "现金找零",
    "zh-Hant": "現金變化"
  },
  "RegisterNumber": {
    "ar": "رقم التسجيل",
    "ca": "Número de caixa",
    "comment": "Register Number",
    "cs": "Číslo pokladny",
    "da": "Kassenummer",
    "de": "Kassennummer",
    "el": "Αριθμός ταμείου",
    "en": "Register Number",
    "en-GB": "Register Number",
    "en-XA": "[Ŕéĝîšţéŕ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Número de caja",
    "es-AR": "Número de caja",
    "es-ES": "Número de caja",
    "es-MX": "Número de caja",
    "fr": "Numéro de caisse",
    "hu": "Kassza száma",
    "id": "Nomor Registrasi",
    "it": "Numero di cassa",
    "ja": "レジスター番号",
    "key": "RegisterNumber",
    "ko": "등록 번호",
    "nb": "Kassenummer",
    "nl": "Kassanummer",
    "pl": "Numer kasy",
    "pt-BR": "Número do registro",
    "pt-PT": "Número de caixa reg.",
    "ru": "Регистрационный номер кассы",
    "sv": "Kassanummer",
    "th": "หมายเลขลงทะเบียน",
    "tr": "Kasa Numarası",
    "zh-Hans": "收银机编号",
    "zh-Hant": "收銀機編號"
  },
  "ReturnWithoutReceipt": {
    "ar": "إرجاع من دون إيصال",
    "ca": "DEVOLUCIÓ SENSE REBUT",
    "comment": "Prints in all caps above returned items on a receipt where the original receipt was not provided",
    "cs": "VRÁCENÍ BEZ DOKLADU",
    "da": "RETURNERING UDEN KVITTERING",
    "de": "RÜCKGABE OHNE BELEG",
    "el": "ΕΠΙΣΤΡΟΦΗ ΧΩΡΙΣ ΑΠΟΔΕΙΞΗ",
    "en": "RETURN WITHOUT RECEIPT",
    "en-GB": "RETURN WITHOUT RECEIPT",
    "en-XA": "[ŔÉŢÛŔÑ ŴÎŢĤÖÛŢ ŔÉÇÉÎÞŢ Пβシ耐克บั้ รื่بُو]",
    "es-419": "DEVOLUCIÓN SIN RECIBO",
    "es-AR": "DEVOLUCIÓN SIN RECIBO",
    "es-ES": "DEVOLUCIÓN EN TIENDA SIN RECIBO",
    "es-MX": "DEVOLUCIÓN SIN RECIBO",
    "fr": "RETOUR SANS REÇU",
    "hu": "VISSZAKÜLDÉS SZÁMLA NÉLKÜL",
    "id": "PENGEMBALIAN TANPA TANDA TERIMA",
    "it": "RESO SENZA RICEVUTA",
    "ja": "レシートなしの返品",
    "key": "ReturnWithoutReceipt",
    "ko": "영수증 없는 반품",
    "nb": "RETUR UTEN KVITTERING",
    "nl": "RETOUR ZONDER BON",
    "pl": "ZWROT BEZ PARAGONU",
    "pt-BR": "DEVOLUÇÃO SEM RECIBO",
    "pt-PT": "DEVOLUÇÃO SEM RECIBO",
    "ru": "ВОЗВРАТ БЕЗ ЧЕКА",
    "sv": "RETUR UTAN KVITTO",
    "th": "คืนสินค้าโดยไม่มีใบเสร็จ",
    "tr": "MAKBUZ OLMADAN İADE",
    "zh-Hans": "无收据退换货",
    "zh-Hant": "無收據退貨"
  },
  "ReturnedItemsEnd": {
    "ca": "Fi de la devolució",
    "comment": "Line in receipt stating the end of a list of items that were returned to the store",
    "cs": "Konec vrácení",
    "da": "Slut på returvarer",
    "de": "Umtausch beendet",
    "en": "End of Return",
    "en-XA": "[Éñð öƒ Ŕéţûŕñ Пβシ耐克บั้ รื่بُو]",
    "es-419": "Fin de devolución",
    "es-AR": "Fin de la devolución",
    "es-ES": "Término Devolución",
    "es-MX": "Final de la devolución",
    "fr": "Fin retour",
    "hu": "Visszaküldés vége",
    "it": "Fine del reso",
    "ja": "返品された商品はここまで",
    "key": "ReturnedItemsEnd",
    "ko": "반품 목록 끝",
    "nb": "Slutt på retur",
    "nl": "Einde Retour",
    "pl": "Koniec listy zwrotów",
    "pt-PT": "Fim da devolução",
    "ru": "Конец списка товаров",
    "sv": "Inga fler returer",
    "tr": "İade Sonu",
    "zh-Hans": "退换货商品清单末尾",
    "zh-Hant": "退貨結束"
  },
  "TRAININGMODE": {
    "ar": "وضع التدريب",
    "ca": "Modo Training",
    "comment": "Training Mode Indicator",
    "cs": "Výukový režim",
    "da": "TRÆNINGSTILSTAND",
    "de": "Ausbildung / Training",
    "el": "ΛΕΙΤΟΥΡΓΙΑ ΕΚΠΑΙΔΕΥΣΗΣ",
    "en": "TRAINING MODE",
    "en-GB": "TRAINING MODE",
    "en-XA": "[ŢŔÅÎÑÎÑĜ ṀÖÐÉ Пβシ耐克บั้ รื่بُو]",
    "es-419": "MODALIDAD DE ENTRENAMIENTO",
    "es-AR": "MODALIDAD DE ENTRENAMIENTO",
    "es-ES": "MODO FORMACIÓN",
    "es-MX": "MODO DE CAPACITACIÓN",
    "fr": "MODE FORMATION",
    "hu": "TRAINING MODE",
    "id": "MODE LATIHAN",
    "it": "MODALITÀ ADDESTRAMENTO",
    "ja": "TRAINING MODE",
    "key": "TRAININGMODE",
    "ko": "트레이닝 모드",
    "nb": "OPPLÆRINGSMODUS",
    "nl": "TRAININGSMODUS",
    "pl": "TRYB TRENINGOWY",
    "pt-BR": "MODO DE TREINO",
    "pt-PT": "MODO DE TREINO",
    "ru": "РЕЖИМ ОБУЧЕНИЯ",
    "sv": "UTBILDNINGSLÄGE",
    "th": "โหมดทดลองใช้",
    "tr": "EĞİTİM MODU",
    "zh-Hans": "培训模式",
    "zh-Hant": "訓練模式"
  },
  "Test": {
    "ar": "اختبار",
    "ca": "Prova",
    "comment": "Testing bodega!!",
    "cs": "Test",
    "da": "Test",
    "de": "Test",
    "el": "Δοκιμή",
    "en": "Test",
    "en-GB": "Test",
    "en-XA": "[Ţéšţ 耐克รื่بُو]",
    "es-419": "Prueba",
    "es-AR": "Prueba",
    "es-ES": "Prueba",
    "es-MX": "Prueba",
    "fr": "Test",
    "hu": "Teszt",
    "id": "Tes",
    "it": "Test",
    "ja": "テスト",
    "key": "Test",
    "ko": "테스트",
    "nb": "Test",
    "nl": "Test",
    "pl": "Test",
    "pt-BR": "Teste",
    "pt-PT": "Teste",
    "ru": "Test",
    "sv": "Test",
    "th": "การทดสอบ",
    "tr": "Test",
    "zh-Hans": "测试",
    "zh-Hant": "測試"
  },
  "TxRef": {
    "ar": "مرجع المعاملة",
    "ca": "TxRef",
    "comment": "Tax reference for the card based transaction",
    "cs": "TxRef",
    "da": "TxRef",
    "de": "TxRef",
    "el": "TxRef",
    "en": "TxRef",
    "en-GB": "TxRef",
    "en-XA": "[ŢẋŔéƒ 耐克รื่بُو]",
    "es-419": "TxRef",
    "es-AR": "TxRef",
    "es-ES": "TxRef",
    "es-MX": "TxRef",
    "fr": "TxRef",
    "hu": "TxRef",
    "id": "TxRef",
    "it": "TxRef",
    "ja": "TxRef",
    "key": "TxRef",
    "ko": "세금 환불",
    "nb": "TxRef",
    "nl": "TxRef",
    "pl": "Nr rejestracji podatkowej",
    "pt-BR": "RefTx",
    "pt-PT": "Ref. fiscal",
    "ru": "TxRef",
    "sv": "Momsref.",
    "th": "TxRef",
    "tr": "TxRef",
    "zh-Hans": "税费参考",
    "zh-Hant": "稅金參考"
  },
  "TxType": {
    "ar": "نوع المعاملة",
    "ca": "TxType",
    "comment": "Card type for the card based transaction",
    "cs": "TxType",
    "da": "TxType",
    "de": "TxType",
    "el": "TxType",
    "en": "TxType",
    "en-GB": "TxType",
    "en-XA": "[ŢẋŢýþé 耐克รื่بُو]",
    "es-419": "TxType",
    "es-AR": "TxType",
    "es-ES": "TxType",
    "es-MX": "TxType",
    "fr": "TxType",
    "hu": "TxType",
    "id": "TxType",
    "it": "TxType",
    "ja": "TxType",
    "key": "TxType",
    "ko": "세금 유형",
    "nb": "Avgiftstype",
    "nl": "TxType",
    "pl": "TxType",
    "pt-BR": "TipoTx",
    "pt-PT": "Tipo de cartão",
    "ru": "TxType",
    "sv": "Korttyp",
    "th": "TxType",
    "tr": "TxTip",
    "zh-Hans": "卡种",
    "zh-Hant": "卡別"
  },
  "UI_Cash": {
    "ar": "المبلغ النقدي",
    "ca": "Efectivo",
    "comment": "Cash",
    "cs": "Hotovost",
    "da": "KR.",
    "de": "Bar",
    "el": "Μετρητά",
    "en": "Cash",
    "en-GB": "Cash",
    "en-XA": "[Çåšĥ 耐克รื่بُو]",
    "es-419": "Efectivo",
    "es-AR": "Efectivo",
    "es-ES": "Efectivo",
    "es-MX": "Efectivo",
    "fr": "Cash",
    "hu": "Cash",
    "id": "Uang Tunai",
    "it": "Contanti",
    "ja": "現金",
    "key": "UI_Cash",
    "ko": "현금",
    "nb": "Kontanter",
    "nl": "Contant",
    "pl": "Gotówka",
    "pt-BR": "Dinheiro",
    "pt-PT": "Dinheiro",
    "ru": "Наличные",
    "sv": "Kontant",
    "th": "เงินสด",
    "tr": "Nakit",
    "zh-Hans": "现金",
    "zh-Hant": "現金"
  },
  "WeChatPay": {
    "ca": "Pagament amb WeChat",
    "comment": "Denotes a payment done through WeChat Pay",
    "cs": "Platit přes WeChat",
    "da": "WeChat Pay",
    "de": "WeChat Pay",
    "en": "WeChat Pay",
    "en-XA": "[ŴéÇĥåţ Þåý å耐克รื่بُو]",
    "es-419": "Pago mediante WeChat",
    "es-AR": "WeChat Pay",
    "es-ES": "WeChat Pay",
    "es-MX": "WeChat Pay",
    "fr": "WeChat Pay",
    "hu": "WeChat Pay",
    "it": "WeChat Pay",
    "ja": "WeChat Pay",
    "key": "WeChatPay",
    "nb": "WeChat Pay",
    "nl": "WeChat Pay",
    "pl": "Płatność w systemie WeChat Pay",
    "pt-PT": "WeChat Pay",
    "ru": "WeChat Pay",
    "sv": "WeChat Pay",
    "tr": "WeChat Pay",
    "zh-Hans": "微信支付",
    "zh-Hant": "WeChat Pay"
  }
}
