{
    "IL_Color": {
        "ca": "Color",
        "comment": "Text for the Color code attribute of the product to print on the labels. ",
        "cs": "Barva",
        "da": "Farve",
        "de": "Farbe",
        "el": "Χρώμα",
        "en": "Color",
        "en-GB": "Colour",
        "en-XA": "[Çöļöŕ 耐克รื่بُو]",
        "es-AR": "Color",
        "es-ES": "Color",
        "es-MX": "Color",
        "fi": "Väri",
        "fr": "Couleur",
        "he": "צבע",
        "hr": "Boja",
        "hu": "Szín",
        "it": "Colore",
        "ja": "カラー",
        "key": "IL_Color",
        "ko": "컬러",
        "nb": "Farge",
        "nl": "Kleur",
        "pl": "Kolor",
        "pt-BR": "Cor",
        "pt-PT": "Cor",
        "ru": "Расцветка",
        "sv": "Färg",
        "th": "สี",
        "tr": "Renk",
        "zh-Hans": "颜色",
        "zh-Hant": "顏色"
    },
    "IL_Created": {
        "ca": "Creat el",
        "comment": "The string \"Created\" that indicate the date on which the label was printed.",
        "cs": "Vytvořeno",
        "da": "Oprettet",
        "de": "Erstellt",
        "el": "Δημιουργήθηκε",
        "en": "Created",
        "en-GB": "Created",
        "en-XA": "[Çŕéåţéð 耐克รื่بُو]",
        "es-AR": "Fecha de creación",
        "es-ES": "Creado el",
        "es-MX": "Creado",
        "fi": "Luotu",
        "fr": "Créée",
        "he": "נוצר",
        "hr": "Izrađeno",
        "hu": "Készült",
        "it": "Data creazione",
        "ja": "作成済み",
        "key": "IL_Created",
        "ko": "생성됨",
        "nb": "Opprettet",
        "nl": "Gemaakt",
        "pl": "Utworzono",
        "pt-BR": "Criado",
        "pt-PT": "Data de criação",
        "ru": "Создано",
        "sv": "Skapad",
        "th": "สร้างเมื่อ",
        "tr": "FİYAT TARİHİ",
        "zh-Hans": "创建日期",
        "zh-Hant": "建立日期"
    },
    "IL_CurrencySymbol": {
        "ca": "$",
        "comment": "Currency symbol on the label.",
        "cs": "USD",
        "da": "$",
        "de": "$",
        "el": "$",
        "en": "$",
        "en-GB": "$",
        "en-XA": "[€ 耐克รื่بُو]",
        "es-AR": "$",
        "es-ES": "$",
        "es-MX": "$",
        "fi": "$",
        "fr": "$",
        "he": "$",
        "hr": "$",
        "hu": "HUF",
        "it": "$",
        "ja": "$",
        "key": "IL_CurrencySymbol",
        "ko": "달러($)",
        "nb": "$",
        "nl": "$",
        "pl": "USD",
        "pt-BR": "$",
        "pt-PT": "$",
        "ru": "$",
        "sv": "$",
        "th": "$",
        "tr": "$",
        "zh-Hans": "￥",
        "zh-Hant": "$"
    },
    "IL_CurrentPrice": {
        "ca": "Preu actual",
        "comment": "Current Price of an item on the Item price and Heel Tab Label.",
        "cs": "Aktuální cena",
        "da": "Nuværende pris",
        "de": "Aktueller Preis",
        "el": "Τρέχουσα τιμή",
        "en": "Current Price",
        "en-GB": "Current Price",
        "en-XA": "[Çûŕŕéñţ Þŕîçé Пβシ耐克บั้ รื่بُو]",
        "es-AR": "Precio actual",
        "es-ES": "Precio actual",
        "es-MX": "Precio actual",
        "fi": "Nykyinen hinta",
        "fr": "Prix actuel",
        "he": "מחיר נוכחי",
        "hr": "Trenutačna cijena",
        "hu": "Jelenlegi ár",
        "it": "Prezzo attuale",
        "ja": "現在の価格",
        "key": "IL_CurrentPrice",
        "ko": "현재 가격",
        "nb": "Nåværende pris",
        "nl": "Huidige prijs",
        "pl": "Aktualna cena",
        "pt-BR": "Preço atual",
        "pt-PT": "Preço atual",
        "ru": "Текущая цена",
        "sv": "Nuvarande pris",
        "th": "ราคาปัจจุบัน",
        "tr": "Mevcut Fiyat",
        "zh-Hans": "当前价格",
        "zh-Hant": "目前價格"
    },
    "IL_CurrentPrice1": {
        "ca": "Preu",
        "comment": "The verbiage for the Current Selling Price",
        "cs": "Cena",
        "da": "Pris",
        "de": "Preis",
        "el": "Τιμή",
        "en": "Price",
        "en-GB": "Price",
        "en-XA": "[Þŕîçé 耐克รื่بُو]",
        "es-AR": "Precio",
        "es-ES": "Precio",
        "es-MX": "Precio",
        "fi": "Hinta",
        "fr": "Prix",
        "he": "מחיר",
        "hr": "Cijena",
        "hu": "Ár",
        "it": "Prezzo",
        "ja": "価格",
        "key": "IL_CurrentPrice1",
        "ko": "가격",
        "nb": "Pris",
        "nl": "Prijs",
        "pl": "Cena",
        "pt-BR": "Preço",
        "pt-PT": "Preço",
        "ru": "Цена",
        "sv": "Pris",
        "th": "ราคา",
        "tr": "Fiyat",
        "zh-Hans": "价格",
        "zh-Hant": "價格"
    },
    "IL_CurrentPrice2": {
        "ca": "Preu actual de venda",
        "comment": "Current selling price verbiage on the second line.",
        "cs": "CSP",
        "da": "CSP",
        "de": "CSP",
        "el": "CSP",
        "en": "CSP",
        "en-GB": "CSP",
        "en-XA": "[ÇŠÞ 耐克รื่بُو]",
        "es-AR": "Precio actual de venta",
        "es-ES": "Precio actual de venta",
        "es-MX": "CSP",
        "fi": "CSP",
        "fr": "PVA",
        "he": "CSP",
        "hr": "CSP",
        "hu": "CSP",
        "it": "PVA",
        "ja": "CSP",
        "key": "IL_CurrentPrice2",
        "ko": "카스피안(CSP)",
        "nb": "CSP",
        "nl": "CSP",
        "pl": "Aktualna cena sprzedaży",
        "pt-BR": "Preço de venda atual",
        "pt-PT": "Preço de venda atual",
        "ru": "CSP",
        "sv": "Nuvarande pris",
        "th": "ราคาขายปัจจุบัน",
        "tr": "Mevcut Satış Fiyatı",
        "zh-Hans": "当前价格",
        "zh-Hant": "CSP"
    },
    "IL_FactoryPrice": {
        "ca": "Preu de fàbrica",
        "comment": "The price of the items in a factory store. This price will be printed on the Item price and Heel Tab labels of a Nike Factory store.",
        "cs": "Výrobní cena",
        "da": "Fabrikspris",
        "de": "Fabrikpreis",
        "el": "Τιμή εργοστασίου",
        "en": "Factory Price",
        "en-GB": "Factory Price",
        "en-XA": "[Ƒåçţöŕý Þŕîçé Пβシ耐克บั้ รื่بُو]",
        "es-AR": "Previo de fábrica",
        "es-ES": "Precio de fábrica",
        "es-MX": "Precio de fábrica",
        "fi": "Tehdashinta",
        "fr": "Prix usine",
        "he": "מחיר מפעל",
        "hr": "Tvornička cijena",
        "hu": "Előállítási ár",
        "it": "Prezzo di fabbrica",
        "ja": "ファクトリーストア価格",
        "key": "IL_FactoryPrice",
        "ko": "공장 가격",
        "nb": "Fabrikkpris",
        "nl": "Factoryprijs",
        "pl": "Cena fabryczna",
        "pt-BR": "Preço de fábrica",
        "pt-PT": "Preço de fábrica",
        "ru": "Фабричная цена",
        "sv": "Factory-pris",
        "th": "ราคาโรงงาน",
        "tr": "Fabrika Fiyatı",
        "zh-Hans": "出厂价格",
        "zh-Hant": "Factory 價格"
    },
    "IL_ShipmentNumber": {
        "comment": "Shipment Number is a unique identifier assigned to each shipment/transfer",
        "en": "Shipment Number",
        "en-XA": "[Šĥîþɱéñţ Ñûɱƀéŕ Пβシ耐克บั้ รื่بُو]",
        "key": "IL_ShipmentNumber"
    },
    "IL_Size": {
        "ca": "Talla",
        "comment": "Size of the item",
        "cs": "Velikost",
        "da": "Størrelse",
        "de": "Größe",
        "el": "Μέγεθος",
        "en": "Size",
        "en-GB": "Size",
        "en-XA": "[Šîžé 耐克รื่بُو]",
        "es-AR": "Talle",
        "es-ES": "Talla",
        "es-MX": "Talla",
        "fi": "Koko",
        "fr": "Taille/Pointure",
        "he": "מידה",
        "hr": "Veličina",
        "hu": "Méret",
        "it": "Taglia/misura",
        "ja": "サイズ",
        "key": "IL_Size",
        "ko": "사이즈",
        "nb": "Størrelse",
        "nl": "Maat",
        "pl": "Rozmiar",
        "pt-BR": "Tamanho",
        "pt-PT": "Tamanho",
        "ru": "Размер",
        "sv": "Storlek",
        "th": "ไซส์",
        "tr": "Numara/Beden",
        "zh-Hans": "尺码",
        "zh-Hant": "尺寸"
    },
    "IL_Style": {
        "ca": "Model",
        "comment": "Style of the item ",
        "cs": "Styl",
        "da": "Style",
        "de": "Style",
        "el": "Στιλ",
        "en": "Style",
        "en-GB": "Style",
        "en-XA": "[Šţýļé 耐克รื่بُو]",
        "es-AR": "Estilo",
        "es-ES": "Modelo",
        "es-MX": "Estilo",
        "fi": "Tyyli",
        "fr": "Modèle",
        "he": "סגנון",
        "hr": "Model",
        "hu": "Stílus",
        "it": "Modello",
        "ja": "スタイル",
        "key": "IL_Style",
        "ko": "스타일",
        "nb": "Produkt",
        "nl": "Stijl",
        "pl": "Model",
        "pt-BR": "Estilo",
        "pt-PT": "Estilo",
        "ru": "Модель",
        "sv": "Modell",
        "th": "สไตล์",
        "tr": "Stil",
        "zh-Hans": "款式",
        "zh-Hant": "款式"
    },
    "IL_SuggestedPrice": {
        "ca": "Preu suggerit",
        "comment": "Suggested retail price of a product is the price at which its manufacturer nominally recommends that a retailer sell the product.",
        "cs": "Doporučená cena",
        "da": "Vejledende pris",
        "de": "Vorgeschlagener Preis",
        "el": "Προτεινόμενη τιμή",
        "en": "Suggested Price",
        "en-GB": "Suggested Price",
        "en-XA": "[Šûĝĝéšţéð Þŕîçé Пβシ耐克บั้ รื่بُو]",
        "es-AR": "Precio sugerido",
        "es-ES": "Precio recomendado",
        "es-MX": "Precio sugerido",
        "fi": "Suositeltu hinta",
        "fr": "Prix suggéré",
        "he": "מחיר מוצע",
        "hr": "Preporučena cijena",
        "hu": "Javasolt ár",
        "it": "Prezzo consigliato",
        "ja": "希望小売価格",
        "key": "IL_SuggestedPrice",
        "ko": "권장 가격",
        "nb": "Foreslått pris",
        "nl": "Adviesprijs",
        "pl": "Sugerowana cena",
        "pt-BR": "Preço sugerido",
        "pt-PT": "Preço sugerido",
        "ru": "Рекомендованная цена",
        "sv": "Rekommenderat pris",
        "th": "ราคาแนะนำ",
        "tr": "Önerilen Fiyat",
        "zh-Hans": "建议价格",
        "zh-Hant": "建議售價"
    }
}