import { Loading } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';

import './LoadingIndicator.css';

const LoadingIndicator = ({ className }) => (
  <div className={`rcf-loader-container ${className}`}>
    <Loading className="rcf-loader mt12-sm mb12-sm" size="l" title="loading indicator" />
  </div>
);

LoadingIndicator.defaultProps = {
  className: '',
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export default LoadingIndicator;
