import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useTranslate from '../../hooks/useTranslate';

import Popup from './Popup';

const ButtonPopup = ({
  className,
  isDisabled,
  isSubmitting,
  label,
  onSubmit,
  showPopup,
  style,
  subtitle,
  textConfirm,
  title,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const translate = useTranslate();

  const openModal = () => setIsPopupOpen(true);

  return (
    <>
      <span id="modal-root" />
      <button
        className={className}
        disabled={isDisabled || isSubmitting}
        style={{ filter: `brightness(${isDisabled ? 72 : 100}%)`, ...style }}
        type="button"
        onClick={showPopup ? openModal : () => ({})}
      >
        {label}
      </button>
      <Popup
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        subtitle={subtitle}
        textConfirm={textConfirm || translate('RL_Yes')}
        title={title || translate('RL_Confirm')}
        onSubmit={onSubmit}
      />
    </>
  );
};

ButtonPopup.defaultProps = {
  className: '',
  isDisabled: false,
  isSubmitting: false,
  showPopup: true,
  style: {},
  subtitle: '',
  textConfirm: null,
  title: null,
};

ButtonPopup.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  showPopup: PropTypes.bool,
  style: PropTypes.shape(),
  subtitle: PropTypes.string,
  textConfirm: PropTypes.string,
  title: PropTypes.string,
};

export default ButtonPopup;
