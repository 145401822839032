// createSlice wraps the reducer function with 'produce' from the Immer library
// which prevents us from actually mutating the passed in state
/* eslint-disable fp/no-mutation */
/* eslint-disable no-param-reassign */
import { configureStore, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_LOCALE } from '../constants';
// eslint-disable-next-line import/named
import { checkIsPartnerLogin } from '../utils/promoteUtils';

const localeSlice = createSlice({
  initialState: {
    country: localStorage.country,
    createPromotionEnabled: false,
    isPartnerLogin: checkIsPartnerLogin(),
    localeToggle: false,
    markdownEnabled: false,
    partnerCountries: [],
    partnerStores: {},
    storeLocale: localStorage.storeLocale || DEFAULT_LOCALE
  },
  name: 'locale',
  reducers: {
    setCountry(state, action) {
      state.country = action.payload;
    },
    setIsCreatePromotionEnabled(state, action) {
      state.createPromotionEnabled = action.payload;
    },
    setIsCsvUploadEnabled(state, action) {
      state.isStyleUploadEnabled = action.payload;
    },
    setIsMarkdownEnabled(state, action) {
      state.markdownEnabled = action.payload;
    },
    setLocale(state, action) {
      state.storeLocale = action.payload;
    },
    setLoginType(state, action) {
      state.loginType = action.payload;
    },
    setPartnerStores(state, action) {
      state.partnerStores = action.payload;
    },
    toggleLocale(state) {
      state.localeToggle = !state.localeToggle;
    },
  }
});
const store = configureStore({
  reducer: localeSlice.reducer
});

export const {
  setCountry, setLocale, setLoginType, toggleLocale, setPartnerStores, setPartnerCountries, setIsMarkdownEnabled, setIsCreatePromotionEnabled, setIsCsvUploadEnabled
} = localeSlice.actions;
export const promoReducer = localeSlice.reducer;
export default store;
