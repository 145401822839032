import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ButtonAdd, ProductsDisplay, Scanner } from '../reusable';

import MarkdownForm from './MarkdownForm';

const MarkdownHome = ({
  canCreate, markdowns, refreshMarkdowns, setProducts
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [gtin, setGTIN] = useState('');
  const toggleAddMarkdownPage = async () => {
    // Ignore your editor if it says these awaits do not do anything. They do. Without it, the DialogStyled will extend as tall as its background display, not as tall as the screen.
    await setIsAdding((prevIsAdding) => !prevIsAdding);
    await setIsScanning((prevIsScanning) => !prevIsScanning);
    if (isAdding) {
      refreshMarkdowns();
    }
  };
  // Close the popup when we find a gtin
  useEffect(() => {
    if (gtin) {
      (async () => {
        await setIsAdding(true);
        await setIsScanning(false);
      })();
    }
  }, [gtin]);

  return (
    <>
      {isScanning
        ? <Scanner onClose={() => setIsScanning(false)} onDetected={(barcode) => setGTIN(barcode.padStart(14, '0'))} />
        : (
          <>
            {canCreate && <ButtonAdd isAdding={isAdding} onToggle={toggleAddMarkdownPage} />}
            {isAdding
              ? <MarkdownForm gtin={gtin} />
              : <ProductsDisplay isMarkdowns products={markdowns} setProducts={setProducts} />}
          </>
        )}
    </>
  );
};

MarkdownHome.propTypes = {
  canCreate: PropTypes.bool.isRequired,
  markdowns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      createDateTime: PropTypes.string,
      gtin: PropTypes.string,
      markdownPrice: PropTypes.number,
      name: PropTypes.string,
      productId: PropTypes.string,
      startDateTime: PropTypes.string,
      styleColor: PropTypes.string,
      typeId: PropTypes.string,
    })),
    startDate: PropTypes.string.isRequired,
  })).isRequired,
  refreshMarkdowns: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default MarkdownHome;
