/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Auth from '../../authorization/Auth';

const PrivateRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={() => (Auth.isAuthenticated()
      ? (children)
      : (<Redirect to="/login" />))}
  />
);

PrivateRoute.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default PrivateRoute;
