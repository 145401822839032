/* eslint-disable fp/no-mutating-methods */
import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import Auth from '../authorization/Auth';

import { ButtonSubmit } from './reusable';

const LoggedOut = () => {
  const history = useHistory();
  const onClick = () => {
    history.push('/login');
  };

  return Auth.isAuthenticated()
    ? <Redirect to="/login" />
    : (
      <main className="ncss-col-sm-12 ta-sm-c">
        <h1 className="display-3 mt10-sm mb4-sm text-color-error">You are logged out</h1>
        <p className="headline-4 p2-sm">Please use the Sign In option below to login.</p>
        <section className="ncss-col-sm-5 ncss-col-lg-2 ta-sm-c mb3-sm pt4-sm">
          <ButtonSubmit
            label="Sign In"
            onSubmit={onClick}
          />
        </section>
      </main>
    );
};
export default LoggedOut;
