import { DropDownStyled } from '@nike/nike-design-system-components';
import { NikeApp } from '@nike/nike-design-system-icons';
import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import React from 'react';

import nikeStoreCountries from '../../utils/static/countriesWithNikeStores';
import { ButtonSubmit, Input } from '../reusable';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const countryToFlag = (isoCode) => (typeof String.fromCodePoint !== 'undefined'
  // eslint-disable-next-line no-magic-numbers
  ? countries.alpha3ToAlpha2(isoCode).replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
  : isoCode);

const convertCountryCodes = (nikeCountries) => nikeCountries.map((country) => ({ label: `${country.label} ${countryToFlag(country.value)} `, value: country.value }));

const LoginForm = (props) => (
  <>
    <section className="ncss-col-sm-12 full ncss-col-md-6 ta-sm-l mt3-sm mb3-sm">
      <section className="ncss-col-sm-12 ta-sm-c mb3-sm">
        <NikeApp
          aria-hidden
          className="flx-as-sm-c"
          size="l"
        />
        <h1 className="display-3">Promote App</h1>
      </section>
      <Input
        isRequired
        id="athleteNumberInput"
        label="Athlete Number"
        maxLength={40}
        minLength={1}
        value={props.loginInformation.athleteNumber}
        onChange={props.setAthleteNumber}
      />
      <Input
        isRequired
        id="athletePasswordInput"
        label="Athlete Password"
        type="password"
        value={props.loginInformation.athletePassword}
        onChange={props.setAthletePassword}
      />
      <DropDownStyled
        autoComplete="on"
        id="storeCountryInput"
        label="Store Country"
        name="Store Country"
        options={convertCountryCodes(nikeStoreCountries)}
        requiredIndicator="*"
        value={props.loginInformation.selectedCountry}
        onChange={({ target: { value: newValue } }) => props.setSelectedCountry(newValue)}
      />
      <Input
        isRequired
        id="selectedStoreInput"
        label="Store Number"
        value={props.loginInformation.selectedStore}
        onChange={props.setSelectedStore}
      />
      <ButtonSubmit
        isDisabled={props.disableSubmit}
        isSubmitting={props.fetching}
        label="Sign In"
        messageError={props.errorMessage}
        onSubmit={props.onSubmit}
      />
    </section>
  </>
);

LoginForm.propTypes = {
  disableSubmit: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  loginInformation: PropTypes.shape({
    athleteNumber: PropTypes.string.isRequired,
    athletePassword: PropTypes.string.isRequired,
    selectedCountry: PropTypes.string.isRequired,
    selectedStore: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  setAthleteNumber: PropTypes.func.isRequired,
  setAthletePassword: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedStore: PropTypes.func.isRequired

};

export default LoginForm;
