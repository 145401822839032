const { Maybe } = require('../types/maybe');

const isSome = (o) => Maybe.of(o).isSome();

const isNot = (predicate) => (o) => !predicate(o);

const isNone = isNot(isSome);

module.exports = {
  isSome, isNone, isNot
};