{
  "HOST": "https://multiregion-promote-service.rcf-test.nikecloud.com",
  "HOST_PARTNER": "https://multiregion-partner-promote-service.rcf-test.nikecloud.com",
  "HOST_DELEDGE": "https://deledge.test.commerce.nikecloud.com",
  "HOST_STORE_LOCATIONS": "https://api-test.nikecloud.com",
  "oktaAuthorizeResource" : "/v1/qa/authorize",
  "oktaRedirectURI": "https://promote-partner-ui.rcf-test.nikecloud.com/auth",
  "oktaTokenResource": "/v1/qa/token",
  "HOST_STORE_VIEWS": "https://storeviews-regional-us-east-1.risedomain-test.nikecloud.com"
}
