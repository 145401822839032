[
  "RL_Active",
  "RL_Add",
  "RL_AddProduct",
  "RL_Add1Product",
  "RL_Add2Products",
  "RL_AddMarkdown",
  "RL_AddPromotion",
  "RL_AdultUnisex",
  "RL_All",
  "RL_AllDivisions",
  "RL_Apparel",
  "RL_ApparelDivision",
  "RL_Athlete",
  "RL_AthleteRoles",
  "RL_BackToPromotions",
  "RL_Browser",
  "RL_Clear",
  "RL_Code",
  "RL_Codes",
  "RL_Confirm",
  "RL_CrossDivision",
  "RL_DefaultPromotion",
  "RL_Delete",
  "RL_Discount",
  "RL_DiscountPercentage",
  "RL_Divisions",
  "RL_EditPromotion",
  "RL_EndDate",
  "RL_EndDateValidation1",
  "RL_EndDateValidation2",
  "RL_Ends",
  "RL_Equipment",
  "RL_EquipmentDivision",
  "RL_Footwear",
  "RL_For",
  "RL_Gender",
  "RL_Genders",
  "RL_InTransit",
  "RL_ItemDeleteFail",
  "RL_Kids",
  "RL_LogOut",
  "RL_MarkdownAddSuccess",
  "RL_Markdowns",
  "RL_Men",
  "RL_NewPrice",
  "RL_NoAssociatedProducts",
  "RL_Off",
  "RL_OffCode",
  "RL_OffCodes",
  "RL_PausedPromotion",
  "RL_PendingSync",
  "RL_PriceValidation",
  "RL_ProductDeleteConfirm1",
  "RL_ProductDeleteConfirm2",
  "RL_ProductDeleteConfirm3",
  "RL_ProductDivision",
  "RL_ProductNameOrStyleColor",
  "RL_ProductType",
  "RL_Products",
  "RL_ProductsAddSuccess",
  "RL_Profile",
  "RL_PromotionDeleteSuccess1",
  "RL_PromotionDeleteSuccess2",
  "RL_PromotionID",
  "RL_PromotionName",
  "RL_PromotionNameValidation1",
  "RL_PromotionNameValidation2",
  "RL_PromotionUpdateSuccess",
  "RL_PromotionalProductsWith",
  "RL_Promotions",
  "RL_Quantity",
  "RL_RemoveConfirmation",
  "RL_Roles",
  "RL_STOCK_ON_HANDS",
  "RL_SaveChanges",
  "RL_ScanBarcode",
  "RL_SearchMarkdowns",
  "RL_SearchProductOrStyleColor",
  "RL_Select",
  "RL_SignIn",
  "RL_StartDate",
  "RL_StartScanning",
  "RL_Starts",
  "RL_StockOnHand",
  "RL_StyleColor",
  "RL_StyleColors",
  "RL_Submit",
  "RL_Today",
  "RL_Units",
  "RL_Version",
  "RL_VisualMerchandising",
  "RL_Women",
  "RL_Yes",
  "RL_ClearItemSuccess",
  "RL_PromotionNameorStyleColor",
  "RL_FootwearDivision",
  "RL_Mens",
  "RL_Womens",
  "RL_SearchPromotions",
  "RL_ProductDeleteConfirm4",
  "RL_PromotionValidation1",
  "RL_ProductScanSuccess",
  "RL_ProductScanCancel1",
  "RL_ProductScanCancel2",
  "RL_ProductAddSuccess1",
  "RL_CodesValidation",
  "RL_GTINValidation",
  "RL_StyleColorValidation",
  "RL_MoreInformation",
  "RL_ScannerError",
  "RL_ProductAddSuccess3",
  "RL_ProductAddSuccess4",
  "RL_ProductAddError1",
  "RL_ProductAddError2",
  "RL_ProductAddError3",
  "RL_ProductAddError4",
  "RL_ProductAddError5",
  "RL_ScanError1",
  "RL_ScanError2"
]
