import { Input } from '@nike/nike-design-system-components';
import { Default, match } from '@nike/rcf-fp';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, {
  useRef, useState
} from 'react';

import { CSV_UPLOAD } from '../../constants';
import useTranslate from '../../hooks/useTranslate';
import { postCsvUpload } from '../../service-calls';
import {
  chunks,
  extractNecessaryData, getMissingColumns, extractRequiredHeaders, getProgress,
  transformCsvToJsonObj
} from '../../utils/uploadUtils';
import { ButtonSubmit } from '../reusable';
import LeavePageBlocker from '../reusable/LeavePageBlocker';

import UploadReport from './UploadReport';

const UploadHome = ({ setUploadBatches, uploadRef }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [translationAttributes, setTranslationAttributes] = useState({}); // used by translate method for parameters

  const currentBatchNoRef = useRef(null);
  const translate = useTranslate();

  const [uploadStatus, setUploadStatus] = useState({
    failureCount: 0, isError: false, isUploadCompleted: false, status: [], statusText: null, successCount: 0, totalCompleted: 0, totalUploads: 0, uploadId: null
  });
  const csvRef = useRef(null);
  const uploadStatusRef = useRef(null);
  const statusRef = useRef(null);

  // eslint-disable-next-line fp/no-mutation
  uploadStatusRef.current = uploadStatus;

  const btnRef = useRef(null);
  const [csvHeaders, setCsvHeaders] = useState([]);

  const executeScroll = () => statusRef.current?.scrollIntoView();

  const getNextBatchForUpload = (lastBatchNo = 0) => {
    const thisUploadBatches = uploadRef.current;
    const nextBatch = thisUploadBatches.find((item) => item.batchNo === (lastBatchNo + 1));
    if (!nextBatch) return null;
    return { ...nextBatch, chunk: [csvHeaders, ...nextBatch.chunk] };
  };

  const [inputCsvJsonData, setInputCsvJsonData] = useState([]);

  const onBatchSuccess = async (batchResponse) => {
    const { results: batchResults, uploadId: responseUploadId } = batchResponse;

    const batchCount = batchResults.length;
    const batchSuccessCount = batchResults.filter((item) => !item.errors.length).length;
    const batchFailureCount = batchCount - batchSuccessCount;

    const processedRows = batchResults.map((item) => item.rowNo);

    setUploadStatus((prevState) => {
      const totalCompleted = prevState.totalCompleted + batchCount;
      const newStatus = prevState.status.map((currentItem) => {
        const updatedItem = processedRows.includes(currentItem.rowNo) ? batchResults.find((item) => item.rowNo === currentItem.rowNo) : null;
        return updatedItem ? ({ ...updatedItem, status: updatedItem.errors.length ? CSV_UPLOAD.STATUS_FAILURE : CSV_UPLOAD.STATUS_SUCCESS }) : currentItem;
      });

      return {
        ...prevState,
        failureCount: prevState.failureCount + batchFailureCount,
        isUploadCompleted: totalCompleted === prevState.totalUploads,
        status: newStatus,
        statusText: 'RL_PleaseWait',
        successCount: prevState.successCount + batchSuccessCount,
        totalCompleted,
        uploadId: prevState.uploadId || responseUploadId,
      };
    });
  };

  const onBatchFailure = () => {
    const lastBatchNo = currentBatchNoRef.current;

    const lastBatch = uploadRef.current.find((item) => item.batchNo === lastBatchNo);

    const batchCount = lastBatch.chunk.length;
    const batchSuccessCount = 0;
    const batchFailureCount = batchCount;

    const processedRows = lastBatch.chunk.map((item) => (item[0]));

    setUploadStatus((prevState) => {
      const totalCompleted = prevState.totalCompleted + batchCount;
      const newStatus = prevState.status.map((currentItem) => {
        const updatedItem = processedRows.includes(currentItem.rowNo) ? { ...currentItem, errors: [{ errorCode: 'UNEXPECTED_FAILURE', errorMessage: 'Unexpected Failure' }] } : null;
        return updatedItem ? ({ ...updatedItem, status: updatedItem.errors.length ? CSV_UPLOAD.STATUS_FAILURE : CSV_UPLOAD.STATUS_SUCCESS }) : currentItem;
      });

      return {
        ...prevState,
        failureCount: prevState.failureCount + batchFailureCount,
        isUploadCompleted: totalCompleted === prevState.totalUploads,
        status: newStatus,
        statusText: 'RL_PleaseWait',
        successCount: prevState.successCount + batchSuccessCount,
        totalCompleted
      };
    });
  };

  const getLastBatch = (prevState, lastBatchNo) => {
    const updatedBatches = prevState.map((item) => (item.batchNo === lastBatchNo ? { ...item, isProcessed: true } : item));
    return updatedBatches;
  };

  const updateLastBatchStatus = (lastBatchNo) => {
    setUploadBatches((prevState) => [...getLastBatch(prevState, lastBatchNo)]);
  };

  const allBatchesCompleted = () => {
    setIsSubmitting(false);
    setIsUploadInProgress(false);

    setUploadStatus((prevState) => ({
      ...prevState,
      statusText: 'RL_UploadComplete',
    }));
    setTimeout(() => {
      executeScroll();
    }, 10);
  };
  const onBatchComplete = (response, isSuccess = true) => {
    const batchNo = isSuccess ? response.batchNo : currentBatchNoRef.current;
    if (isSuccess) {
      onBatchSuccess(response);
    } else {
      onBatchFailure(response);
    }
    const lastBatchNo = parseInt(batchNo, 10);
    updateLastBatchStatus(lastBatchNo);
    // eslint-disable-next-line no-use-before-define
    setTimeout(() => onUpload(lastBatchNo), CSV_UPLOAD.BATCH_INTERVAL_MS);
  };

  const uploadFail = () => {
    setIsSubmitDisabled(true);
    setIsUploadInProgress(false);
    setIsSubmitting(false);
    setUploadStatus({
      isError: true, statusText: `Error: API Validation failed.`
    });
    setInputCsvJsonData({});
  };

  const uploadNextBatch = async (nextBatchJson, batchNo) => {
    const isLastBatch = batchNo === uploadStatusRef.current.totalUploads;
    postCsvUpload(nextBatchJson, uploadStatusRef.current.uploadId, batchNo, isLastBatch)
      .then((response) => {
        const apiContractError = JSON.parse(response?.value?.body || null)?.errors;
        if (apiContractError) return uploadFail();

        onBatchComplete(response);
        return true;
      })
      .catch((error) => {
        onBatchComplete(error, false);
        return true;
      });
  };

  const onUpload = (lastBatchNo) => {
    const nextBatch = getNextBatchForUpload(lastBatchNo);

    if (!nextBatch) {
      allBatchesCompleted();
      return;
    }
    // eslint-disable-next-line fp/no-mutation
    currentBatchNoRef.current = nextBatch.batchNo;
    const nextBatchJson = nextBatch.chunk;
    uploadNextBatch(nextBatchJson, nextBatch.batchNo);
  };

  const onUploadClick = () => {
    setIsSubmitDisabled(true);
    setIsSubmitting(true);
    setIsUploadInProgress(true);
    onUpload();
  };

  const prepareStatusObject = (transformeCsvdData) => {
    const { rows } = transformCsvToJsonObj(transformeCsvdData);

    const status = rows.map((item) => ({
      ...item, errors: [], status: CSV_UPLOAD.STATUS_PROCESSING
    }));

    setUploadStatus((prevState) => ({ ...prevState, status }));
  };

  const prepareUploadBatches = (transformeCsvdData) => {
    setIsSubmitting(false);
    const dataWithoutHeader = transformeCsvdData.slice(1);
    const uploadChunks = chunks(dataWithoutHeader, CSV_UPLOAD.BATCH_SIZE);
    const batches = uploadChunks.map((chunk, idx) => ({ batchNo: (idx + 1), chunk, isProcessed: false }));
    setUploadStatus((prevState) => ({
      ...prevState,
      failureCount: 0,
      isError: false,
      isUploadCompleted: false,
      statusText: '',
      successCount: 0,
      totalCompleted: 0,
      totalUploads: (dataWithoutHeader.length),
      uploadId: null
    }));
    setUploadBatches(batches);

    prepareStatusObject(transformeCsvdData);
  };

  const validateAndtransformUploadedData = (data) => {
    const headers = data[0];
    const transformedHeaders = extractRequiredHeaders(headers);
    const requiredkeys = (transformedHeaders.map((header) => header.colIdx));
    const missingColumns = getMissingColumns(transformedHeaders);

    if (missingColumns.length) {
      setIsSubmitDisabled(true);
      setUploadStatus({
        isError: true, statusText: 'RL_UploadMissing'
      });
      setTranslationAttributes({ missingColumns });
      setInputCsvJsonData({});
      return;
    }

    if (data.length < 2) {
      setIsSubmitDisabled(true);
      setInputCsvJsonData({});
      return;
    }

    if (data.length <= (CSV_UPLOAD.MAX_ALLOWED_ROWS + 1)) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
      setUploadStatus({
        isError: true, statusText: 'RL_Validate'
      });
      setTranslationAttributes({ rowsInUploadedCsv: data.length });
      setInputCsvJsonData({});
      return;
    }

    const extractedData = extractNecessaryData(data, requiredkeys);

    const requiredHeaders = extractedData[0];
    const transformeCsvdData = extractedData;
    setInputCsvJsonData(transformeCsvdData);
    setCsvHeaders(requiredHeaders);
    prepareUploadBatches(transformeCsvdData);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete(results) {
          validateAndtransformUploadedData(results.data);
        }
      });
    }
  };

  const showUploadReport = isUploadInProgress || uploadStatus.isUploadCompleted;

  const getUploadStatusText = () => {
    const { statusText } = uploadStatus;
    return match(statusText)(
      ['RL_UploadComplete', translate(statusText, uploadStatus.successCount, uploadStatus.totalUploads)],
      ['RL_PleaseWait', translate(statusText, getProgress(uploadStatus.totalCompleted, uploadStatus.totalUploads))],
      ['RL_UploadMissing', `${translate(statusText)}: ${translationAttributes.missingColumns?.join(', ')}`],
      ['RL_Validate', `${translate(statusText, translationAttributes.rowsInUploadedCsv, CSV_UPLOAD.MAX_ALLOWED_ROWS)}`],
      [() => !statusText, statusText],
      [Default, '']
    );
  };

  return (
    <>
      <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_StyleUpload')}</h1>
      <br />
      <section className="ncss-col-sm-12 ta-sm-c mt3-sm mb3-sm">
        <section className="ncss-col-sm-12 ta-sm-c mt3-sm mb3-sm">
          <Input
            key="csv-upload"
            ref={csvRef}
            accept=".csv"
            className="file-upload"
            datatype="text"
            id="fileEditStore"
            label="File Upload"
            type="file"
            onChange={handleFileChange}
          />
        </section>
        <section className="ncss-col-sm-6 ta-sm-c mt3-sm mb3-sm">
          <ButtonSubmit
            ref={btnRef}
            buttonClassName="mb2-sm"
            isDisabled={isSubmitDisabled}
            isSubmitting={isSubmitting}
            label={translate('RL_2UploaStyle', inputCsvJsonData.length > 1 ? inputCsvJsonData.length - 1 : '')}
            sectionClassName="ncss-col-sm-4 full"
            onSubmit={onUploadClick}
          />
        </section>
      </section>
      {uploadStatus.statusText && <section ref={statusRef} className={`text-color-${uploadStatus.isError ? 'error' : 'success'} u-bold`} id="status-placeholder">{getUploadStatusText()}</section>}
      <br />
      {showUploadReport && <UploadReport uploadStatus={uploadStatus} />}
      <LeavePageBlocker message={CSV_UPLOAD.WARNING_PAGE_LEAVE} when={isUploadInProgress} />
    </>
  );
};

UploadHome.propTypes = {
  setUploadBatches: PropTypes.func.isRequired,
  uploadRef: PropTypes.shape().isRequired
};

export default UploadHome;
