const promotion_translations = require("../resources/promotion_translations.json");

/*
  Translate word based on locale passed in.
  If locale translation does not exist, split off the language from the locale and translate based on that language.
*/
module.exports = (word, language, ...args) => {
  let translation = null;

  try {
    translation = promotion_translations[word][language] || promotion_translations[word][language.split('-')[0]] || null;

    // Replacing dynamic strings
    args.forEach((arg, i) => {
      if (['string', 'number'].includes(typeof arg)) {
        translation = translation.replace(`{{${i+1}}}`, arg);
      }
    });
    return translation;
  } catch (err) {
    return null;
  }
};
