import { DropDownStyled } from '@nike/nike-design-system-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Auth from '../../authorization/Auth';
import initializePKCEFlow from '../../authorization/OktaAuth/PKCEFlow';
import { PARTNER_COUNTRY_FETCH_ERR } from '../../constants';
import useTranslate from '../../hooks/useTranslate';
import { getPartnerStores } from '../../redux/selectors';
import { setPartnerStores } from '../../redux/store';
import { postPartnerPromotion, storeViewsByPartner } from '../../service-calls';
import { formatDate, handleErrorMessage, handleResponse } from '../../utils/formatting';
// eslint-disable-next-line import/named
import { getCountriesWherePartnerPresent } from '../../utils/promoteUtils';
import { divisionValues, genderValues } from '../../utils/static/id-mappings';
import {
  ButtonSubmit,
  DiscountPercentages,
  Input,
  InputDate,
  ContentLoads
} from '../reusable';

const PromotionAdd = ({ partnerName }) => {
  const partnerStores = useSelector(getPartnerStores);
  const [name, setName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [partnerCountries, setPartnerCountries] = useState('');
  const [divisions, setDivisions] = useState([]);
  const [genders, setGenders] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [startDate, setStartDate] = useState(formatDate(moment().add(1, 'd')));
  const [endDate, setEndDate] = useState(formatDate(moment().add(1, 'd').add(1, 'w')));
  const [dateError, setDateError] = useState('');
  const [nameError, setNameError] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const translate = useTranslate();

  useEffect(() => {
    const isFormComplete = (name && (discountPercentage || discountPercentage === 0) && selectedCountry && startDate && endDate);
    const areDatesValid = startDate < endDate;
    const isNameValid = /^[a-zA-Z0-9 ]*$/.test(name);
    const isPromotionNameValid = !/\s{2,}/.test(name);

    setNameError((!isNameValid || !isPromotionNameValid) ? translate('RL_PromotionNameValidation2') : '');
    setDateError(areDatesValid ? '' : 'End Date must be after Start Date.');
    setError('');
    setDivisions([divisionValues[0]]);
    setGenders([genderValues[0]]);
    setSubmitDisabled(!(isFormComplete && areDatesValid && isNameValid && isPromotionNameValid));
    setSuccess('');
  }, [discountPercentage, endDate, name, selectedCountry, startDate, translate]);

  useEffect(() => {
    if (isFetching && !Object.keys(partnerStores).length) {
      (async () => storeViewsByPartner(partnerName)
        .then((object) => {
          dispatch(setPartnerStores(object));
          return !Object.keys(object).length
            ? handleResponse(setPartnerCountries, [], setError, PARTNER_COUNTRY_FETCH_ERR)
            : handleResponse(setPartnerCountries, Object.keys(object), setError, '');
        })
        .catch((err) => handleResponse(setPartnerCountries, [], setError, handleErrorMessage(err)))
        .finally(() => {
          setIsFetching(false);
        }))();
    } else {
      setPartnerCountries(Object.keys(partnerStores));
      setIsFetching(false);
    }
  }, [isFetching, partnerName, dispatch, partnerStores]);

  const onSubmit = () => {
    const storesList = partnerStores[selectedCountry];
    if (Auth.isAuthenticated()) {
      setIsSubmitting(true);
      (async () => postPartnerPromotion({
        country: selectedCountry,
        discountPercentage,
        divisions,
        endDate,
        genders,
        name,
        partnerName,
        startDate,
        storesList
      })
        .then(() => handleResponse(setSuccess, translate('RL_ProductAddSuccess1', name), setError, ''))
        .catch((err) => handleResponse(setSuccess, '', setError, handleErrorMessage(err)))
        .finally(() => {
          window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
          setIsSubmitting(false);
        }))();
    } else initializePKCEFlow();
  };

  return (
    <>
      <ContentLoads error={error} isLoading={isFetching}>
        <section className="ncss-col-sm-10 ta-sm-l mt3-sm mb3-sm">
          <Input
            isRequired
            showCharacterCount
            error={!name || !!nameError}
            errorMessage={nameError}
            id="name"
            label={translate('RL_PromotionName')}
            maxLength={21}
            minLength={1}
            value={name}
            onChange={setName}
          />
          <DropDownStyled
            autoComplete="on"
            error={!selectedCountry}
            id="countryInput"
            label="Country"
            name="Country"
            options={getCountriesWherePartnerPresent(partnerCountries)}
            requiredIndicator="*"
            value={selectedCountry}
            onChange={({ target: { value: newValue } }) => setSelectedCountry(newValue)}
          />
          <DiscountPercentages
            isRequired
            discountPercentage={discountPercentage}
            onChange={setDiscountPercentage}
          />
          <InputDate
            isRequired
            id="startDate"
            label={translate('RL_StartDate')}
            minDate={formatDate(moment().add(1, 'd').startOf('day'))}
            value={startDate}
            onChange={setStartDate}
          />
          <InputDate
            isRequired
            errorMessage={dateError}
            id="endDate"
            label={translate('RL_EndDate')}
            minDate={formatDate(moment(startDate).add(1, 'd').startOf('day'))}
            value={endDate}
            onChange={setEndDate}
          />
        </section>
        <ButtonSubmit
          isDisabled={submitDisabled}
          isSubmitting={isSubmitting}
          label={translate('RL_AddPromotion')}
          messageError={error}
          messageSuccess={success}
          onSubmit={onSubmit}
        />
      </ContentLoads>

    </>
  );
};

PromotionAdd.propTypes = {
  partnerName: PropTypes.string.isRequired,
};

export default PromotionAdd;
