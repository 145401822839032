import { TextFieldStyled } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import useTranslate from '../../hooks/useTranslate';
import { setCountry } from '../../redux/store';
import { formatCurrency } from '../../utils/formatting';
import getUserCountry from '../../utils/getUserCountry';
import countryInfo from '../../utils/static/country-info';

const getCurrencyFormatRules = (country) => {
  const defaultCurrency = {
    decimal: '.', digitsAfterDecimal: 2, postfix: '', prefix: '$', thousands: ','
  };
  return { ...defaultCurrency, ...countryInfo[country]?.currency };
};

const CurrencyInput = ({ country, reduxSetCountry, setAmount }) => {
  const [rawInput, setRawInput] = useState(0);
  const [formattedPrice, setFormattedPrice] = useState('');

  const translate = useTranslate();

  !country && reduxSetCountry(getUserCountry());
  const priceFormatOptions = getCurrencyFormatRules(country);

  const handleKeyDown = (event) => {
    const { key } = event;
    if (!Number.isNaN(Number(key))) {
      const newNumber = rawInput === 0 ? key : `${rawInput}${key}`;
      setRawInput(Number(newNumber));
      setAmount(Number(newNumber) / (10 ** priceFormatOptions.digitsAfterDecimal));
      setFormattedPrice(formatCurrency(newNumber, priceFormatOptions).slice(0, -1));
    } else if (['Backspace', 'Delete'].includes(key)) {
      const priceString = `${rawInput}`;
      const newNumber = priceString.slice(0, priceString.length - 1);
      setRawInput(Number(newNumber));
      setAmount(Number(newNumber) / (10 ** priceFormatOptions.digitsAfterDecimal));
      setFormattedPrice(formatCurrency(newNumber, priceFormatOptions));
    }
  };

  return (
    <TextFieldStyled
      error={!rawInput}
      inputmode="numeric"
      label={translate('RL_NewPrice')}
      requiredIndicator="*"
      unitPrefix={priceFormatOptions.prefix}
      unitSuffix={priceFormatOptions.postfix}
      value={formattedPrice}
      onChange={() => {}}
      onKeyDown={handleKeyDown}
    />
  );
};

CurrencyInput.propTypes = {
  country: PropTypes.string.isRequired,
  reduxSetCountry: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
};

const mapStateToProps = ({ country }) => ({
  country,
});

const mapDispatchToProps = (dispatch) => ({
  reduxSetCountry: (country) => dispatch(setCountry(country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyInput);
