import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CSV_UPLOAD } from '../../constants';
import useTranslate from '../../hooks/useTranslate';
import { getLocaleForTranslation } from '../../redux/selectors';
import { filterStyles, getUiColumns } from '../../utils/uploadUtils';
import { Button } from '../reusable';
import Table from '../reusable/Table';

import DownloadCsvButton from './DownloadCsvButton';

const UploadReport = ({ uploadStatus }) => {
  const [filter, setFilter] = useState('All');
  const translate = useTranslate();

  const localeForTranslation = useSelector(getLocaleForTranslation);

  const uiColumns = getUiColumns(localeForTranslation);

  return (
    <>
      <hr />
      <section className="ncss-col-sm-12 ta-sm-c mt3-sm mb3-sm u-bold">
        <div className="ncss-container d-sm-flx" style={{ justifyContent: 'center' }}>
          <Button
            buttonClassName={`ncss-btn-${filter === 'All' ? 'primary' : 'secondary'}-dark mr2-sm`}
            label={`${translate('RL_AllStyleColours')}: ${uploadStatus.totalUploads}`}
            onClick={() => setFilter('All')}
          />
          <Button
            buttonClassName={`ncss-btn-${filter === CSV_UPLOAD.STATUS_SUCCESS ? 'primary' : 'secondary'}-dark mr2-sm`}
            label={`${translate('RL_Succeeded')}: ${uploadStatus.successCount}`}
            onClick={() => setFilter(CSV_UPLOAD.STATUS_SUCCESS)}
          />
          <Button
            buttonClassName={`ncss-btn-${filter === CSV_UPLOAD.STATUS_FAILURE ? 'primary' : 'secondary'}-dark mr2-sm`}
            label={`${translate('RL_Failed')}: ${uploadStatus.failureCount}`}
            onClick={() => setFilter(CSV_UPLOAD.STATUS_FAILURE)}
          />
        </div>
      </section>
      <section className="ncss-col-sm-10 ta-sm-c mt3-sm mb3-sm">
        <Table columns={uiColumns} data={uploadStatus.status.filter((style) => filterStyles(style, filter))} />
      </section>
      {uploadStatus.isUploadCompleted && (
      <section className="ncss-col-sm-4 ta-sm-c mt3-sm mb3-sm">
        <DownloadCsvButton uploadStatus={uploadStatus} />
      </section>
      )}
    </>
  );
};

UploadReport.propTypes = {
  uploadStatus: PropTypes.shape().isRequired,
};

export default UploadReport;
