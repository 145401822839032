// All data fetched from child pages of https://confluence.nike.com/display/G11N/Nike+Locale+Quick+Guide

const countryInfo = {
  ARE: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'AED',
      thousands: ',',
    },
    dateFormat: 'YYYY/M/D',
  },
  ARG: {
    dateFormat: 'DD/MM/YYYY',
  },
  AUS: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  AUT: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  BEL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    dateFormat: 'YYYY-MM-DD',
  },
  BGR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: 'лв.',
      prefix: '',
      thousands: ',',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  BRA: {
    dateFormat: 'DD/MM/YYYY',
  },
  CAN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'MM/DD/YYYY',
  },
  CHE: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'CHF',
      thousands: '’',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  CHL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 0,
      prefix: '$',
      thousands: '.',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  CHN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '￥',
      thousands: ',',
    },
    dateFormat: 'YYYY/MM/DD',
  },
  CZE: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Kč',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'D. M. YYYY',
  },
  DEU: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  DNK: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'kr.',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD-MM-YYYY',
  },
  EGY: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'EGP',
      thousands: ',',
    },
    dateFormat: 'YYYY/MM/DD',
  },
  ESP: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  FIN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ',',
    },
    dateFormat: 'D.M.YYYY',
  },
  FRA: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  GBR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '£',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  GRC: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  'Hong Kong': {
  },
  HRV: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      postfix: 'kn',
      prefix: '',
      thousands: ',',
    },
    dateFormat: 'D. M. YYYY',
  },
  HUN: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Ft',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'YYYY. MM. DD.',
  },
  IDN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'Rp',
      thousands: ','
    },
    dateFormat: 'DD/MM/YYYY',
  },
  IND: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₹',
      thousands: ',',
    },
    dateFormat: 'DD-MM-YY',
  },
  IRL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  ISR: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₪',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  ITA: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  JPN: {
    currency: {
      digitsAfterDecimal: 0,
      prefix: '￥',
      thousands: ',',
    },
    dateFormat: 'YYYY/MM/DD',
  },
  KOR: {
    currency: {
      digitsAfterDecimal: 0,
      postfix: '￦',
      prefix: '',
      thousands: ',',
    },
    dateFormat: 'YYYY/MM/DD',
  },
  LUX: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  MAR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'Dh',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'YYYY/M/D',
  },
  MEX: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  MYS: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'RM',
      thousands: ',',
    },
    dateFormat: 'D/M/YY',
  },
  NLD: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: '.',
    },
    dateFormat: 'DD-MM-YYYY',
  },
  NOR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: 'kr',
      thousands: ' ',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  NZL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  PHL: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '₱',
      thousands: ',',
    },
    dateFormat: 'MM/DD/YYYY',
  },
  POL: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'zł',
      prefix: '',
      thousands: ' ', // Should only be used if there are 5 or more digits... I'll... figure that out later...
    },
    dateFormat: 'D.MM.YYYY',
  },
  PRI: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  PRT: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '€',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  ROU: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'lei',
      prefix: '',
      thousands: '.',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  RUS: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: '₽',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  SAU: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'SAR',
      thousands: ',',
    },
    dateFormat: 'YYYY/M/D',
  },
  SGP: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: 'S$',
      thousands: ',',
    },
    dateFormat: 'DD-MM-YYYY',
  },
  SVK: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    dateFormat: 'DD. MM. YYYY',
  },
  SVN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '€',
      thousands: ',',
    },
    dateFormat: 'D. M. YYYY',
  },
  SWE: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      postfix: 'kr',
      prefix: '',
      thousands: ' ',
    },
    dateFormat: 'DD-MM-YY',
  },
  THA: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '฿',
      thousands: ',',
    },
    dateFormat: 'DD/MM/YYYY',
  },
  TUR: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: '₺',
      thousands: '.',
    },
    dateFormat: 'DD.MM.YYYY',
  },
  TWN: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'YYYY/MM/DD',
  },
  URY: {
  },
  USA: {
    currency: {
      decimal: '.',
      digitsAfterDecimal: 2,
      prefix: '$',
      thousands: ',',
    },
    dateFormat: 'MM/DD/YYYY',
  },
  VNM: {
    currency: {
      digitsAfterDecimal: 0,
      postfix: '₫',
      prefix: '',
      thousands: ',',
    },
    dateFormat: 'D/M/YY',
  },
  ZAF: {
    currency: {
      decimal: ',',
      digitsAfterDecimal: 2,
      prefix: 'R',
      thousands: ' ',
    },
    dateFormat: 'DD-MM-YYYY',
  }
};

export default countryInfo;
