import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useTranslate from '../../hooks/useTranslate';
import {
  formatPromotionPatchAddItems, formatStoreInfo, handleErrorMessage, handleResponse
} from '../../utils/formatting';
import { ButtonSubmit, Popup, Scanner } from '../reusable';

const ProductsScan = ({
  onClose,
  promotion,
  setIsFetching,
  patchPromotion
}) => {
  const translate = useTranslate();

  const [gtins, setGTINS] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [label, setLabel] = useState(translate('RL_Submit'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancellationConfirmationOpen, setIsCancellationConfirmationOpen] = useState(false);
  const [success, setSuccess] = useState(translate('RL_StartScanning'));
  const [error, setError] = useState('');

  const onDetected = (barcode) => {
    if (gtins.length >= 50) {
      setError(translate('RL_CodesValidation', 50));
    } else {
      setError('');
      const gtin = barcode.padStart(14, '0');
      if (!gtins.some((x) => x.gtin === gtin)) {
        const gtinsCopy = [...gtins, { date: new Date().toISOString(), gtin }];
        setGTINS(gtinsCopy);
        setLabel(gtinsCopy.length > 0 ? translate(gtinsCopy.length > 1 ? 'RL_Add2Products' : 'RL_Add1Product', gtinsCopy.length) : '');
      }
    }
    setSuccess('');
    setIsSubmitted(false);
  };

  const onPopupSubmit = () => {
    onClose();
    setGTINS([]);
    setLabel(translate('RL_Submit'));
    handleResponse(setSuccess, '', setError, '');
    setIsFetching(true);
  };

  const onPopupClose = () => (gtins.length > 0 && !isSubmitted
    ? setIsCancellationConfirmationOpen(true)
    : onPopupSubmit());

  const onSubmit = () => {
    setIsSubmitting(true);
    const newItemDetails = Array.from(gtins).map((x) => ({
      createDateTime: x.date,
      gtin: x.gtin,
    }));
    (async () => patchPromotion(promotion.id, { updates: formatPromotionPatchAddItems(newItemDetails), ...formatStoreInfo() })
      .then((res) => {
        if (res.errors?.length) {
          const { errorMessage, successMessage } = handleErrorMessage(res, gtins.map((item) => item.gtin));
          return handleResponse(setSuccess, successMessage, setError, errorMessage);
        }
        return handleResponse(setSuccess, translate('RL_ProductScanSuccess'), setError, '');
      })
      .catch((err) => handleResponse(setSuccess, '', setError, handleErrorMessage(err)))
      .finally(() => {
        setGTINS([]);
        setIsSubmitting(false);
        setIsSubmitted(true);
      }))();
  };

  return (
    <Scanner
      onClose={onPopupClose}
      onDetected={onDetected}
    >
      <ButtonSubmit
        isDisabled={!gtins?.length || isSubmitted}
        isSubmitting={isSubmitting}
        label={label}
        messageError={error}
        messageSuccess={success}
        onSubmit={onSubmit}
      />
      <Popup
        isOpen={isCancellationConfirmationOpen}
        setIsOpen={setIsCancellationConfirmationOpen}
        subtitle={gtins.length === 1 ? translate('RL_ProductScanCancel1') : translate('RL_ProductScanCancel2', gtins.length)}
        onSubmit={onPopupSubmit}
      />
    </Scanner>
  );
};

ProductsScan.propTypes = {
  onClose: PropTypes.func.isRequired,
  patchPromotion: PropTypes.func.isRequired,
  promotion: PropTypes.shape().isRequired,
  setIsFetching: PropTypes.func.isRequired,
};

export default ProductsScan;
