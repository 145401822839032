/* eslint-disable sort-keys-fix/sort-keys-fix */
const divisionIDs = {
  'd3169f60-9d03-4ba0-818f-58e1967ce8e3': 'Apparel Division',
  '5150459a-7ef9-46a8-b201-613fa841d871': 'Cross Division',
  '24e5faba-9761-4669-bcc7-05060f0eeccb': 'Equipment Division',
  'aaf5eae1-6889-4638-927a-527d511483a5': 'Footwear Division',
  '79a3d559-ebcb-487c-8a6f-0ea13bfcdf74': 'Product Division',
  '7e3ff92f-7caa-4587-b9b4-86dfcb6df576': 'Visual Merchandising',
};

/*  Update the magic 6 in PromotionPreview.js if we ever have more divisionValues than below (there are 6 groupings, so 'All' is all 6) */
const divisionValues = [
  {
    label: 'All',
    value: [
      'd3169f60-9d03-4ba0-818f-58e1967ce8e3',
      '5150459a-7ef9-46a8-b201-613fa841d871',
      '24e5faba-9761-4669-bcc7-05060f0eeccb',
      'aaf5eae1-6889-4638-927a-527d511483a5',
      '79a3d559-ebcb-487c-8a6f-0ea13bfcdf74',
      '7e3ff92f-7caa-4587-b9b4-86dfcb6df576'
    ]
  },
  { label: 'Apparel Division', value: ['d3169f60-9d03-4ba0-818f-58e1967ce8e3'] },
  { label: 'Cross Division', value: ['5150459a-7ef9-46a8-b201-613fa841d871'] },
  { label: 'Equipment Division', value: ['24e5faba-9761-4669-bcc7-05060f0eeccb'] },
  { label: 'Footwear Division', value: ['aaf5eae1-6889-4638-927a-527d511483a5'] },
  { label: 'Product Division', value: ['79a3d559-ebcb-487c-8a6f-0ea13bfcdf74'] },
  { label: 'Visual Merchandising', value: ['7e3ff92f-7caa-4587-b9b4-86dfcb6df576'] },
];

const genderAgeIDs = {
  'aea0eb4b-9e97-4052-bb0b-fa8f77641852': 'Adult Unisex',
  '92cae888-59c1-404b-ab13-0c94fe20b1f6': 'Mens',
  '71ab6e32-16b2-4e27-acf9-ea4cc09b73f0': 'Womens',
  'bdab8de7-4d22-472c-8330-59e25be9faa6': 'Unisex Big Kid',
  'a5ddf53a-f1c4-4563-a700-fbe35fff7799': 'Unisex Little Kid',
  'a6515dc4-23cd-4d31-9a3e-214e31d4ef63': 'Unisex Infant/Toddler',
  '72a66f34-43ad-4e8c-8796-c81047d27b7b': 'Boys Big Kid',
  'c9653f79-6e18-4a05-bc31-faa39bca223e': 'Boys Little Kid',
  'dba41ab9-5b16-44d9-bf44-fc16bacee6ba': 'Boys Infant/Toddler',
  '37d86509-85cf-410a-8c3c-509a7c5633a7': 'Girls Big Kid',
  '355b35ac-3af5-436b-86e8-2a3079d251dd': 'Girls Little Kid',
  '6312357c-aceb-448d-9447-7f40c6d4eb01': 'Girls Infant/Toddler',
};

const genderIDs = {
  '355b35ac-3af5-436b-86e8-2a3079d251dd': 'Kids',
  '37d86509-85cf-410a-8c3c-509a7c5633a7': 'Kids',
  '6312357c-aceb-448d-9447-7f40c6d4eb01': 'Kids',
  '71ab6e32-16b2-4e27-acf9-ea4cc09b73f0': 'Womens',
  '72a66f34-43ad-4e8c-8796-c81047d27b7b': 'Kids',
  '92cae888-59c1-404b-ab13-0c94fe20b1f6': 'Mens',
  'a5ddf53a-f1c4-4563-a700-fbe35fff7799': 'Kids',
  'a6515dc4-23cd-4d31-9a3e-214e31d4ef63': 'Kids',
  'aea0eb4b-9e97-4052-bb0b-fa8f77641852': 'Adult Unisex',
  'bdab8de7-4d22-472c-8330-59e25be9faa6': 'Kids',
  'c9653f79-6e18-4a05-bc31-faa39bca223e': 'Kids',
  'dba41ab9-5b16-44d9-bf44-fc16bacee6ba': 'Kids',
};

/*  Update the magic 4 in PromotionPreview.js if we ever have more genderValues than below(there are 4 groupings, so 'All' is all 4) */
const genderValues = [
  {
    label: 'All',
    value: [
      '355b35ac-3af5-436b-86e8-2a3079d251dd',
      '37d86509-85cf-410a-8c3c-509a7c5633a7',
      '6312357c-aceb-448d-9447-7f40c6d4eb01',
      '71ab6e32-16b2-4e27-acf9-ea4cc09b73f0',
      '72a66f34-43ad-4e8c-8796-c81047d27b7b',
      '92cae888-59c1-404b-ab13-0c94fe20b1f6',
      'a5ddf53a-f1c4-4563-a700-fbe35fff7799',
      'a6515dc4-23cd-4d31-9a3e-214e31d4ef63',
      'aea0eb4b-9e97-4052-bb0b-fa8f77641852',
      'bdab8de7-4d22-472c-8330-59e25be9faa6',
      'c9653f79-6e18-4a05-bc31-faa39bca223e',
      'dba41ab9-5b16-44d9-bf44-fc16bacee6ba',
    ]
  },
  {
    label: 'Mens',
    value: [
      '92cae888-59c1-404b-ab13-0c94fe20b1f6',
      'aea0eb4b-9e97-4052-bb0b-fa8f77641852',
    ]
  },
  {
    label: 'Womens',
    value: [
      '71ab6e32-16b2-4e27-acf9-ea4cc09b73f0',
      'aea0eb4b-9e97-4052-bb0b-fa8f77641852',
    ]
  },
  { label: 'Adult Unisex', value: ['aea0eb4b-9e97-4052-bb0b-fa8f77641852'] },
  {
    label: 'Kids',
    value: [
      '72a66f34-43ad-4e8c-8796-c81047d27b7b',
      'a5ddf53a-f1c4-4563-a700-fbe35fff7799',
      'a6515dc4-23cd-4d31-9a3e-214e31d4ef63',
      'bdab8de7-4d22-472c-8330-59e25be9faa6',
      'c9653f79-6e18-4a05-bc31-faa39bca223e',
      'dba41ab9-5b16-44d9-bf44-fc16bacee6ba',
      '355b35ac-3af5-436b-86e8-2a3079d251dd',
      '37d86509-85cf-410a-8c3c-509a7c5633a7',
      '6312357c-aceb-448d-9447-7f40c6d4eb01',
    ]
  },
];

const translationMappings = {
  All: 'RL_All',
  'Apparel Division': 'RL_ApparelDivision',
  'Cross Division': 'RL_CrossDivision',
  'Equipment Division': 'RL_EquipmentDivision',
  'Footwear Division': 'RL_FootwearDivision',
  'Product Division': 'RL_ProductDivision',
  'Visual Merchandising': 'RL_VisualMerchandising',
  Mens: 'RL_Mens',
  Womens: 'RL_Womens',
  'Adult Unisex': 'RL_AdultUnisex',
  Kids: 'RL_Kids',
  Apparel: 'RL_Apparel',
  Equipment: 'RL_Equipment',
  Footwear: 'RL_Footwear',
};

module.exports = {
  divisionIDs,
  divisionValues,
  genderAgeIDs,
  genderIDs,
  genderValues,
  translationMappings,
};
