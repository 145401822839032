[
  { "label": "Albania", "value": "ALB" },
  { "label": "Algeria", "value": "DZA" },
  { "label": "Andorra", "value": "AND" },
  { "label": "Argentina", "value": "ARG" },
  { "label": "Armenia", "value": "ARM" },
  { "label": "Australia", "value": "AUS" },
  { "label": "Austria", "value": "AUT" },
  { "label": "Azerbaijan", "value": "AZE" },
  { "label": "Bahrain", "value": "BHR" },
  { "label": "Belarus", "value": "BLR" },
  { "label": "Belgium", "value": "BEL" },
  { "label": "Bosnia and Herzegovina", "value": "BIH" },
  { "label": "Brazil", "value": "BRA" },
  { "label": "Bulgaria", "value": "BGR" },
  { "label": "Canada", "value": "CAN" },
  { "label": "Chile", "value": "CHL" },
  { "label": "China", "value": "CHN" },
  { "label": "Croatia", "value": "HRV" },
  { "label": "Cyprus", "value": "CYP" },
  { "label": "Czech Republic", "value": "CZE" },
  { "label": "Denmark", "value": "DNK" },
  { "label": "Egypt", "value": "EGY" },
  { "label": "Estonia", "value": "EST" },
  { "label": "Finland", "value": "FIN" },
  { "label": "France", "value": "FRA" },
  { "label": "Georgia", "value": "GEO" },
  { "label": "Germany", "value": "DEU" },
  { "label": "Greece", "value": "GRC" },
  { "label": "Hong Kong", "value": "HKG" },
  { "label": "Hungary", "value": "HUN" },
  { "label": "India", "value": "IND" },
  { "label": "Indonesia", "value": "IDN" },
  { "label": "Ireland", "value": "IRL" },
  { "label": "Israel", "value": "ISR" },
  { "label": "Italy", "value": "ITA" },
  { "label": "Japan", "value": "JPN" },
  { "label": "Jordan", "value": "JOR" },
  { "label": "Kazakhstan", "value": "KAZ" },
  { "label": "Kenya", "value": "KEN" },
  { "label": "Kuwait", "value": "KWT" },
  { "label": "Kyrgyzstan", "value": "KGZ" },
  { "label": "Latvia", "value": "LVA" },
  { "label": "Lebanon", "value": "LBN" },
  { "label": "Lithuania", "value": "LTU" },
  { "label": "Luxembourg", "value": "LUX" },
  { "label": "Macao", "value": "MAC" },
  { "label": "Malaysia", "value": "MYS" },
  { "label": "Mexico", "value": "MEX" },
  { "label": "Moldova, Republic of", "value": "MDA" },
  { "label": "Montenegro", "value": "MNE" },
  { "label": "Morocco", "value": "MAR" },
  { "label": "Netherlands", "value": "NLD" },
  { "label": "New Zealand", "value": "NZL" },
  { "label": "North Macedonia, Republic of", "value": "MKD" },
  { "label": "Norway", "value": "NOR" },
  { "label": "Oman", "value": "OMN" },
  { "label": "Philippines", "value": "PHL" },
  { "label": "Poland", "value": "POL" },
  { "label": "Portugal", "value": "PRT" },
  { "label": "Puerto Rico", "value": "PRI" },
  { "label": "Qatar", "value": "QAT" },
  { "label": "Romania", "value": "ROU" },
  { "label": "Russian Federation", "value": "RUS" },
  { "label": "Saudi Arabia", "value": "SAU" },
  { "label": "Serbia", "value": "SRB" },
  { "label": "Singapore", "value": "SGP" },
  { "label": "Slovakia", "value": "SVK" },
  { "label": "Slovenia", "value": "SVN" },
  { "label": "South Africa", "value": "ZAF" },
  { "label": "South Korea", "value": "KOR" },
  { "label": "Spain", "value": "ESP" },
  { "label": "Sweden", "value": "SWE" },
  { "label": "Switzerland", "value": "CHE" },
  { "label": "Taiwan", "value": "TWN" },
  { "label": "Thailand", "value": "THA" },
  { "label": "Turkey", "value": "TUR" },
  { "label": "Turkmenistan", "value": "TKM" },
  { "label": "Ukraine", "value": "UKR" },
  { "label": "United Arab Emirates", "value": "ARE" },
  { "label": "United Kingdom", "value": "GBR" },
  { "label": "United States of America", "value": "USA" },
  { "label": "Uruguay", "value": "URY" },
  { "label": "Uzbekistan", "value": "UZB" },
  { "label": "Viet Nam", "value": "VNM" }
]
