/* eslint-disable import/prefer-default-export */
import { promotionTranslate } from 'rcffus-sps-translations';

import bodegaKeys from './static/bodega-keys.json';
import { translationMappings } from './static/id-mappings';

export const translateSets = (set, translate) => new Set([...set].map((element) => translate(translationMappings[element]) || element));

/* Check if translation for all the keys present in bodegaKeys are available for a perticular locale */
export const isAllTranslationsPresent = (locale) => !bodegaKeys.map((key) => promotionTranslate(key, locale)).some((val) => !val);

export const getIsTranslationsAvailable = () => localStorage.getItem('isTranslationsAvailable') === 'true';
