import PropTypes from 'prop-types';
import React from 'react';
import CsvDownloader from 'react-csv-downloader';

import useTranslate from '../../hooks/useTranslate';
import { getErrorTranslation, getExportColumns } from '../../utils/uploadUtils';
import { ButtonSubmit } from '../reusable';

const getExportFileName = () => {
  const country = localStorage.getItem('country');
  const store = localStorage.getItem('storeNumber');
  return `Styles-upload-report-${country}-${store}-${new Date().getTime()}`;
};
const getData = async (data, translate) => data.map((exportRow) => ({
  ...exportRow,
  errors: exportRow.errors.map(({ key }) => translate(getErrorTranslation(key))).join(', '),
  status: translate(exportRow.status)
}));

const DownloadCsvButton = ({ uploadStatus }) => {
  const translate = useTranslate(); // Moved inside the component body, outside the return statement.
  return (
    <CsvDownloader
      columns={getExportColumns()}
      datas={getData(uploadStatus.status, translate)}
      extension=".csv"
      filename={getExportFileName()}
      separator=","
    >
      <ButtonSubmit
        buttonClassName="mb2-sm"
        label={translate('RL_DownloadReport')} // TODO: Value in double quotes, in bodega for few translations
        sectionClassName="ncss-col-sm-4 full"
      />
    </CsvDownloader>
  );
};

DownloadCsvButton.propTypes = {
  uploadStatus: PropTypes.shape().isRequired,
};

export default DownloadCsvButton;
