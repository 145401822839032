import { sort } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import adapter from 'webrtc-adapter';

import Auth from '../../authorization/Auth';
import useTranslate from '../../hooks/useTranslate';
import { getIsPartnerLogin } from '../../redux/selectors';
import { Button } from '../reusable';

const Profile = () => {
  const [auth, setAuth] = useState(Auth.getAuth());
  const authInfo = useMemo(() => auth && JSON.parse((Buffer.from(auth.split('.')[1], 'base64')).toString('utf-8')), [auth]);

  const translate = useTranslate();
  const isPartnerLogin = useSelector(getIsPartnerLogin);

  return (auth
    ? (
      <>
        <h1 className="display-3 text-color-accent mb3-sm">{translate('RL_Profile')}</h1>
        <ProfileInformation authInfo={authInfo} isPartnerLogin={isPartnerLogin} translate={translate} />
        <Button
          label={translate('RL_LogOut')}
          sectionClassName="d-sm-flx flx-jc-sm-c mt6-sm"
          onClick={() => {
            Auth.logout();
            setAuth('');
          }}
        />
        <p className="body-4 mt3-sm">{translate('RL_Version')} 1.5.2</p>
        <p className="body-4">{adapter.browserDetails.browser} {translate('RL_Browser')}</p>
      </>
    )
    : <Redirect to={isPartnerLogin ? '/logged-out' : '/login'} />
  );
};

const ProfileInformation = ({ isPartnerLogin, authInfo, translate }) => (
  isPartnerLogin
    ? (
      <><h3 className="headline-3">{translate('RL_Athlete')} {authInfo.sub}</h3>
      </>
    )
    : (
      <><h3 className="headline-3">{translate('RL_Athlete')} {authInfo.nike_athleteLogin}</h3>
        <h3 className="headline-3">{localStorage.getItem('country')} - {localStorage.getItem('storeNumber')}</h3>
        <p className="body-2 mt3-sm">{translate('RL_AthleteRoles')}:</p>
        {sort(authInfo.nike_storeRoles).map((role) => <p key={role} className="body-4">{role}</p>)}
      </>
    )
);

ProfileInformation.propTypes = {
  authInfo: PropTypes.shape.isRequired,
  isPartnerLogin: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

export default Profile;
