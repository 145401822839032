const isFunction = o => o instanceof Function;

const isEqual = l => r => l === r;

const predicate = literalOrPredicate => (isFunction(literalOrPredicate)
  ? literalOrPredicate
  : isEqual(literalOrPredicate));

const testPredicate = (arg, valueOrPredicate) => predicate(valueOrPredicate)(arg);

const expression = literalOrExpression => (isFunction(literalOrExpression)
  ? literalOrExpression
  : () => literalOrExpression);

const applyExpression = (arg, literalOrExpression) => expression(literalOrExpression)(arg);

const defaultAccumulator = { isDefault: true };

const reducer = arg => (acc, caseTuple) => (acc === defaultAccumulator && testPredicate(arg, caseTuple[0])
  ? { isDefault: false, transformerValue: applyExpression(arg, caseTuple[1]) }
  : acc);

const of = arg => (...caseTuples) => caseTuples.reduce(reducer(arg), defaultAccumulator).transformerValue;

const match = arg => of(arg);

const Default = () => true;

module.exports = {
  match,
  Default
};
