import PropTypes from 'prop-types';
import React from 'react';

const Messages = ({
  className,
  success,
  error
}) => (
  <div className={className}>
    <p className="ta-sm-c text-color-success body-4">{success}</p>
    {error.split(' ** ').map((err) => <p key={err} className="ta-sm-c text-color-error body-4">{err}</p>)}
  </div>
);
Messages.defaultProps = {
  className: '',
  error: '',
  success: '',
};

Messages.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
};

export default Messages;
