import { NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Authorize from './authorization/OktaAuth/Authorize';
import Unauthorized from './authorization/OktaAuth/Unauthorized';
import LocaleToggle from './components/locale-toggle';
import LogIn from './components/log-in';
import LoggedOut from './components/LoggedOut';
import Markdowns from './components/markdowns';
import NavBar from './components/NavBar';
import PartnerPromotions from './components/partner-promotions';
import PrivateRoute from './components/private-route';
import Profile from './components/profile';
import Promotions from './components/promotions';
import RouteInvalid from './components/RouteInvalid';
import StockOnHand from './components/stock-on-hand';
import Upload from './components/upload';
import { scsKeys } from './constants';
import { getIsCsvUploadEnabled, getIsMarkdownEnabled } from './redux/selectors';
import store, { setIsCreatePromotionEnabled, setIsCsvUploadEnabled, setIsMarkdownEnabled } from './redux/store';
// eslint-disable-next-line import/named
import { checkIsPartnerLogin } from './utils/promoteUtils';
import { isBohApp } from './utils/uploadUtils';

const isPartnerLogin = checkIsPartnerLogin();

const App = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
);

const Routes = () => {
  const dispatch = useDispatch();

  // Set the markdown and promotion flags from local storage on load
  useEffect(() => {
    dispatch(setIsMarkdownEnabled(localStorage.getItem(scsKeys.createMarkdowns) === 'true'));
    dispatch(setIsCreatePromotionEnabled(localStorage.getItem(scsKeys.createPromotions) === 'true'));
    dispatch(setIsCsvUploadEnabled(localStorage.getItem(scsKeys.isStyleUploadEnabled) === 'true'));
  }, [dispatch]);

  const isMarkdownEnabled = useSelector(getIsMarkdownEnabled);
  const isCsvUploadEnabled = useSelector(getIsCsvUploadEnabled) && !isPartnerLogin && isBohApp();

  return (
    <NikeDesignSystemProvider>
      <Router>
        <Route component={({ location }) => (
          <>
            <NavBar location={location} />
            <LocaleToggle location={location} />
          </>
        )}
        />
        <main className="ncss-col-sm-12 ta-sm-c p6-sm mb12-sm">
          <Switch>
            <Route exact component={LogIn} path="/login" />
            <Route exact component={Profile} path="/profile" />
            <Route exact component={Authorize} path="/auth" />
            <PrivateRoute exact path="/promotions*">
              {isPartnerLogin ? <PartnerPromotions /> : <Promotions />}
            </PrivateRoute>
            {isCsvUploadEnabled && (
              <PrivateRoute exact path="/upload">
                <Upload />
              </PrivateRoute>
            )}
            {!isPartnerLogin && isMarkdownEnabled && (
            <PrivateRoute exact path="/markdowns*">
              <Markdowns />
            </PrivateRoute>
            ) }
            {!isPartnerLogin && (
            <PrivateRoute exact path="/stock-on-hand*">
              <StockOnHand />
            </PrivateRoute>
            ) }
            {isPartnerLogin && <Route component={LoggedOut} path="/logged-out" />}
            <Route exact component={Unauthorized} path="/unauthorized" />
            <Route component={RouteInvalid} />
          </Switch>
        </main>
      </Router>
    </NikeDesignSystemProvider>
  );
};

export default App;
