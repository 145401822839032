import { LabelStyled } from '@nike/nike-design-system-components';
import { Default, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactSelectCreatable from 'react-select/creatable';

import useTranslate from '../../hooks/useTranslate';
import { isGTIN, isStyleColor } from '../../utils/formatting';

const checkRegex = (label, value, translate) => {
  // if isMulti, only validate the last value (the new one)
  const code = Array.isArray(value) ? value[value?.length - 1] : value;
  // allow for a clearable form (isRequired will still mark an error)
  if (!code) return '';
  return match(label)(
    ['GTIN', isGTIN(code.value) ? '' : translate('RL_GTINValidation')],
    ['Style Color', isStyleColor(code.value) ? '' : translate('RL_StyleColorValidation')],
    [Default, '']
  );
};

const isTooManyCodes = (value, maxValues) => {
  // if not isMulti, we're fine because we'll only ever have 0 or 1 code
  // we are also fine if there are undefined or 0 values
  if (!Array.isArray(value) || !value || !value.length) return false;
  return value.length > maxValues;
};

const Creatable = ({
  errorMessage,
  isDisabled,
  isMulti,
  isRequired,
  label,
  maxValues,
  onChange,
  value,
  zIndex,
}) => {
  const [regexMessage, setRegexMessage] = useState('');
  const translate = useTranslate();

  useEffect(() => {
    const errorRegex = checkRegex(label, value, translate);
    const isTooMany = isMulti && maxValues && isTooManyCodes(value, maxValues);
    setRegexMessage((errorRegex || isTooMany ? translate('RL_CodesValidation', maxValues) : ''));
  }, [value, isMulti, maxValues, label, translate]);

  return (
    <article className="nds-input-layout-control">
      <LabelStyled
        className={`nds-input-label ${(isMulti ? value?.length : value) ? 'z2' : 'z0'} ml1-sm pl1-sm mr1-sm pr1-sm pb2-sm bg-white`}
        error={((isRequired && (isMulti ? !value?.length : !value?.value)) || regexMessage || errorMessage)}
        htmlFor={label}
        id={label}
        isActive={isMulti ? value?.length : value}
        requiredIndicator={isRequired ? '*' : undefined}
        value={`${label}`}
      />
      <ReactSelectCreatable
        isClearable
        arrowRenderer={null}
        className="nds-input-text-field"
        components={{ DropdownIndicator: null }}
        id={label}
        isActive={isMulti ? value?.length : value}
        isDisabled={isDisabled}
        isMulti={isMulti}
        noOptionsMessage={() => null}
        placeholder={`${label}${isRequired ? '*' : ''}`}
        styles={{
          container: (styles) => ({ ...styles, minHeight: '60px', zIndex }),
          control: (styles) => ({
            ...styles,
            borderColor: ((isRequired && (isMulti ? !value?.length : !value?.value)) || regexMessage || errorMessage) ? '#D43F21' : 'lightgrey',
            minHeight: '60px',
            padding: '0px 0px 0px 3px',
            zIndex,
          }),
        }}
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
      <p className="text-color-error body-4 mb2-sm">{regexMessage || errorMessage}</p>
    </article>
  );
};

Creatable.defaultProps = {
  errorMessage: '',
  isDisabled: false,
  isMulti: false,
  isRequired: false,
  // only use this if isMulti (therefore also do not set this to 1)
  maxValues: 0,
  value: null,
  zIndex: 1,
};

Creatable.propTypes = {
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxValues: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  zIndex: PropTypes.number,
};

export default Creatable;
