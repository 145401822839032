import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Auth from '../../authorization/Auth';
import { ButtonAdd } from '../reusable';

import PromotionAdd from './PromotionAdd';
import PromotionPreview from './PromotionPreview';

const PromotionHome = ({
  promotions, refreshPromotions, setActivePromotion, setClearError, setClearSuccess, canCreate
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const toggleAddPromotionPage = () => {
    isAdding && refreshPromotions();
    return setIsAdding((wasAdding) => !wasAdding);
  };

  return (
    <>
      {canCreate && (<ButtonAdd isAdding={isAdding} onToggle={toggleAddPromotionPage} />)}
      {isAdding
        ? <PromotionAdd />
        : (
          <PromotionPreview
            canDelete={Auth.requiresRole40(Auth.getAuth())}
            promotions={promotions}
            refreshPromotions={refreshPromotions}
            setActivePromotion={setActivePromotion}
            setClearError={setClearError}
            setClearSuccess={setClearSuccess}
          />
        )}
    </>
  );
};

PromotionHome.propTypes = {
  canCreate: PropTypes.bool.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({
    discountPercentage: PropTypes.number.isRequired,
    divisions: PropTypes.shape().isRequired,
    endDate: PropTypes.string.isRequired,
    genders: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    itemDetails: PropTypes.arrayOf(PropTypes.shape({
      gtin: PropTypes.string,
      name: PropTypes.string,
      productId: PropTypes.string,
      styleColor: PropTypes.string,
    })),
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  })).isRequired,
  refreshPromotions: PropTypes.func.isRequired,
  setActivePromotion: PropTypes.func.isRequired,
  setClearError: PropTypes.func.isRequired,
  setClearSuccess: PropTypes.func.isRequired,
};

export default PromotionHome;
