export const get = async (token, host, resource, queryString) => fetch(
  `${host}${resource}${queryString ?? ''}`,
  {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization',
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

export const getPartner = async (token, host, resource, queryString) => fetch(
  `${host}${resource}${queryString ?? ''}`,
  {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, authtype',
      Authorization: `Bearer ${token}`,
      authtype: 'okta',
    },
    method: 'GET',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

export const patch = async (token, host, resource, body) => fetch(
  `${host}${resource}`,
  {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

export const patchPartner = async (token, host, resource, body) => fetch(
  `${host}${resource}`,
  {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type',
      Authorization: `Bearer ${token}`,
      authtype: 'okta',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

export const post = async (token, host, resource, body) => fetch(
  `${host}${resource}`,
  {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

// eslint-disable-next-line no-unused-vars
export const postCsv = async (token, host, resource, data, uploadId, batchNo, isLastBatch) => {
  const queryUploadId = uploadId ? `&uploadId=${uploadId}&` : '';
  const query = `?${queryUploadId}batchNo=${batchNo}&isLastBatch=${isLastBatch}`;

  return fetch(
    `${host}${resource}${query}`,
    {
      body: JSON.stringify(data),
      headers: {
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  )
    .then(async (res) => {
      if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
        throw await res.json();
      }
      return res.json();
    });
};

export const postPartner = async (token, host, resource, body) => fetch(
  `${host}${resource}`,
  {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type, authtype',
      Authorization: `Bearer ${token}`,
      authtype: 'okta',
      'Content-Type': 'application/json'
    },
    method: 'POST',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });

export const put = async (token, host, resource, body) => fetch(
  `${host}${resource}`,
  {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Headers': 'Accept, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Authorization, Content-Type',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  },
)
  .then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return res.json();
  });
