import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

import useTranslate from '../../hooks/useTranslate';

const Select = ({
  errorMessage,
  id,
  isDisabled,
  isRequired,
  label,
  onChange,
  options,
  values,
  zIndex,
  getOptionLabel,
  isMulti
}) => {
  const translate = useTranslate();

  const getBorderColor = (state) => {
    if (state.menuIsOpen || state.isFocused) { return '#000'; }
    return ((isRequired && !values.length) || errorMessage) ? '#D43F21' : 'lightgrey';
  };

  return (
    <>
      <p className="body-4 text-color-secondary pb1-sm">{label}{isRequired && '*'}</p>
      <ReactSelect
        getOptionLabel={getOptionLabel}
        id={id}
        isDisabled={isDisabled}
        isMulti={isMulti}
        options={options}
        placeholder={translate('RL_Select')}
        styles={{
          container: (styles) => ({ ...styles, minHeight: '`60px', zIndex }),
          control: (styles, state) => ({
            ...styles,
            borderColor: getBorderColor(state),
            boxShadow: '#000',
            minHeight: '60px',
            padding: '0px 0px 0px 3px',
            zIndex,
          }),
        }}
        value={values}
        onChange={(newValues) => onChange(newValues || [])}
      />
      <p className="text-color-error body-4 mt2-sm">{errorMessage}</p>
    </>
  );
};

Select.defaultProps = {
  errorMessage: '',
  getOptionLabel: ({ label }) => label,
  isDisabled: false,
  isMulti: false,
  isRequired: false,
  zIndex: 1
};

Select.propTypes = {
  errorMessage: PropTypes.string,
  getOptionLabel: PropTypes.func,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()])).isRequired,
  zIndex: PropTypes.number,
};

export default Select;
