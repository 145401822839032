import { Loading } from '@nike/frame-component-library';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { postCodeToOkta } from '../../service-calls/index';

const Authorize = () => {
  const [accessTokenError, setAccessTokenError] = useState(false);
  const [accessTokenSuccess, setAccessTokenSuccess] = useState(false);
  const [authFlowError, setAuthFlowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line fp/no-let
    let unmounted = false;

    postCodeToOkta()
      .then((body) => {
        const exp = (JSON.parse(window.atob(body.access_token.split('.')[1], 'base64')).exp * 1000) - 600000;
        localStorage.setItem('expiresAt', exp);
        localStorage.setItem('accessToken', body.access_token);

        if (!unmounted) { setAccessTokenSuccess(true); }
        return localStorage.removeItem('code_verifier');
      })
      .catch((err) => {
        if (!unmounted) {
          return (err.name === 'OktaException')
            ? setAuthFlowError(true)
            : setAccessTokenError(true);
        }
        return localStorage.removeItem('code_verifier');
      });

    // eslint-disable-next-line fp/no-mutation
    return () => { unmounted = true; };
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [accessTokenError, accessTokenSuccess, authFlowError]);

  return (
    <main className="ncss-col-sm-12 mt12-sm pt2-sm ta-sm-c">
      <p className="headline-4">Validating credentials...</p>
      {isLoading && <Loading />}
      {accessTokenSuccess && !isLoading && <Redirect to={{ pathname: 'promotions' }} />}
      {accessTokenError && !isLoading && <Redirect to={{ pathname: 'error' }} />}
      {authFlowError && !isLoading && <Redirect to={{ pathname: 'unauthorized' }} />}
    </main>
  );
};

export default Authorize;
